import React, { useCallback, useState } from 'react';
import Popper from '../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../@Future/Component/Generic/Input/Input/Input';
import Checkbox from '../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import { makeStyles } from '@material-ui/styles';
import { LogLevel } from '../Model/LogLevel';

const useStyles = makeStyles(
    {
        levelFilter:
        {
            marginTop: 9
        },
        levelFilterLabel:
        {
            cursor: 'pointer'
        },
    }
);

export interface ConnectorLogLevelSelectorProps
{
    value: LogLevel[];
    onChange: (value: LogLevel[]) => void;
}

export const LogEventsLevelSelector: React.FC<ConnectorLogLevelSelectorProps> =
    (
        {
            value,
            onChange
        }
    ) =>
    {
        const classes = useStyles();
        const [ showLevelFilter, setShowLevelFilter ] = useState<boolean>(false);
        const updateLevel =
            useCallback(
                (level: LogLevel, enabled: boolean) =>
                {
                    const newValue = value.slice();

                    if (newValue.includes(level) && !enabled)
                    {
                        newValue.splice(newValue.indexOf(level), 1);
                    }
                    else if (!newValue.includes(level) && enabled)
                    {
                        newValue.push(level);
                    }

                    onChange(newValue);
                },
                [
                    value,
                    onChange
                ]
            );

        return <Popper
            reference={
                <span
                    className={classes.levelFilterLabel}
                    onClick={() => setShowLevelFilter(true)}
                >
                    Level
                </span>
            }
            popper={
                <Card
                    classes={{ root: classes.levelFilter}}
                >
                    <CardInset>
                        <InputGroup>
                            <Input
                                label="Info"
                                labelPosition="left"
                            >
                                <Checkbox
                                    checked={value.includes('Info')}
                                    onToggle={enabled => updateLevel('Info', enabled)}
                                />
                            </Input>
                            <Input
                                label="Warning"
                                labelPosition="left"
                                inline
                            >
                                <Checkbox
                                    checked={value.includes('Warning')}
                                    onToggle={enabled => updateLevel('Warning', enabled)}
                                />
                            </Input>
                            <Input
                                label="Error"
                                labelPosition="left"
                                inline
                            >
                                <Checkbox
                                    checked={value.includes('Error')}
                                    onToggle={enabled => updateLevel('Error', enabled)}
                                />
                            </Input>
                        </InputGroup>
                    </CardInset>
                </Card>
            }
            open={showLevelFilter}
            onClose={() => setShowLevelFilter(false)}
            placement="bottom"
        />
    }