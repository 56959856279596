import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { CurrencyView } from './CurrencyView';
import { CurrencyEditor } from './CurrencyEditor';
import { CurrencySpecification } from './CurrencySpecification';
import { NumberType } from '../NumberType';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { Comparator } from '../../../Model/Comparator';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import numbro from 'numbro';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { Currency } from '../../../../../../@Api/Localization/Currency';

export class CurrencyType extends NumberType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Currency';
    }

    name()
    {
        return localizeText('DataObject.Type.Currency', 'Valuta');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return super.supportedComparators()
            .filter(comparator => (comparator.comparator !== Comparator.In))
            .concat([
                {
                    comparator: Comparator.In,
                    compareTypeId: 'CurrencyRange'
                }
            ]);
    }

    view(): DataObjectViewerType
    {
        return CurrencyView;
    }

    editor(): DataObjectEditorType
    {
        return CurrencyEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return CurrencySpecification;
    }

    getString(value: any, representation: DataObjectRepresentation, context: DataObjectContext, dataObject: DataObject): string
    {
        let format: any;

        if (representation.data.isCompact)
        {
            const absValue = Math.abs(value);

            if (representation.data.granularity === 'high')
            {
                format = {
                    average: absValue >= 100000,
                    thousandSeparated: true,
                    mantissa: 2,
                    trimMantissa: absValue >= 10000
                };
            }
            else
            {
                format = {
                    average: absValue >= 1000,
                    thousandSeparated: true,
                    mantissa: 0,
                    trimMantissa: false
                };

                if (absValue >= 1000 && absValue < 1_000_000_000)
                {
                    format.totalLength = 3;
                }
            }
        }

        if (representation.data.currency
            && this.localizationStore.localizer)
        {
            const localizer = this.localizationStore.localizer;
            const currency = localizer.currenciesByCode.get(representation.data.currency);

            return Currency.format(
                value,
                localizer.languageCode,
                currency
            );
        }
        else
        {
            return numbro(value).formatCurrency(format);
        }
    }

    intervalTypeId(): string
    {
        return 'Currency';
    }

    rangeTypeId(): string
    {
        return 'CurrencyRange';
    }

    isValid(): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }
}
