import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityController } from '../../../../../@Api/Controller/Directory/EntityController';
import { EntityConstructorStore } from '../EntityConstructorStore';
import { StoreState } from '../../../../../@Framework/Store/@Model/StoreState';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { RouterStore } from '../../../../../@Service/Router/RouterStore';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { createTransactionalModel, getModel, TransactionalModel } from '../../../../../@Util/TransactionalModelV2';
import { EntityRelationshipConstructorStore } from '../Relationship/EntityRelationshipConstructorStore';
import { DrawerStore } from '../../../../Generic/Drawer/DrawerStore';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { Type } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';

export class RelatedEntityConstructorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityController') entityController: EntityController;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LeftDrawerStore') drawerStore: DrawerStore;

    // ------------------------- Properties -------------------------

    @observable rootEntity: Entity;
    @observable path: EntityPath;
    @observable entity: Entity;
    @observable transactionalEntity: TransactionalModel<Entity>;
    @observable constructorStore: EntityConstructorStore;
    @observable relationshipConstructorStore: EntityRelationshipConstructorStore;
    @observable onSave: (entity: Entity) => void;
    @observable onClose: () => void;
    @observable ignoreSourceRelationshipDefinition: boolean;
    @observable isFinalType: boolean;

    // ------------------------ Constructor -------------------------

    constructor(rootEntity: Entity,
                path: EntityPath,
                onSave?: (entity: Entity) => void,
                onClose?: () => void,
                ignoreSourceRelationshipDefinition: boolean = false,
                isFinalType?: boolean)
    {
        super();

        this.rootEntity = rootEntity;
        this.path = path;
        this.onSave = onSave;
        this.onClose = onClose;
        this.ignoreSourceRelationshipDefinition = ignoreSourceRelationshipDefinition;
        this.isFinalType = isFinalType;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.path.lastJoinNode
            // Ensure that only 1 join is made
            && this.path.firstJoinNode === this.path.lastJoinNode
            // And that the relationship definition is a reference
            && this.path.lastJoinNode.relationshipDefinition.type === Type.Reference
            // TODO [DD]: for now make an exception for activities, always show the constructor for this - refactor this
            && this.path.lastJoinNode.relationshipDefinition !== this.entityTypeStore.bespoke.types.Activity.RelationshipDefinition.LinkedActivities
            && this.path.lastJoinNode.relationshipDefinition !== this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities)
        {
            this.transactionalEntity = createTransactionalModel(this.rootEntity);

            this.relationshipConstructorStore =
                new EntityRelationshipConstructorStore(
                    this.rootEntity,
                    this.path.lastJoinNode.relationshipDefinition,
                    this.path.lastJoinNode.isParent,
                    this.path.entityType);
        }
        else
        {
            const entity =
                createTransactionalModel(
                    this.path.constructEntity(
                        this.rootEntity,
                        false));

            // Copy parent relationships for any activity from root entity
            if (this.rootEntity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type)
                && entity.entityType.isA(this.entityTypeStore.bespoke.types.Activity.Type))
            {
                const relationship =
                    this.rootEntity.getRelatedEntityByDefinition(
                        true,
                        this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities);

                if (relationship)
                {
                    entity.updateRelationship(
                        true,
                        this.entityTypeStore.bespoke.types.Relationship.RelationshipDefinition.Activities,
                        relationship);
                }

                const contact =
                    this.rootEntity.getRelatedEntityByDefinition(
                        true,
                        this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities);

                if (contact)
                {
                    entity.updateRelationship(
                        true,
                        this.entityTypeStore.bespoke.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                        contact);
                }
            }

            this.entity = entity;

            this.constructorStore =
                new EntityConstructorStore({
                    entityType: this.path.entityType,
                    entity: this.entity,
                    isFinalType: this.isFinalType,
                    sourceRelationshipDefinition:
                        this.ignoreSourceRelationshipDefinition
                            ?
                                undefined
                            :
                                this.path.lastJoinNode && this.path.lastJoinNode.relationshipDefinition
                });
        }

        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get isValid(): boolean
    {
        if (this.constructorStore)
        {
            return this.constructorStore.isValid;
        }
        else if (this.relationshipConstructorStore)
        {
            return this.relationshipConstructorStore.isValid;
        }
        else
        {
            return false;
        }
    }

    @computed
    get relatedFromEntityPath(): RelatedEntityPath
    {
        return new RelatedEntityPath(
            this.rootEntity,
            this.path);
    }

    @computed
    get relatedEntity(): Entity
    {
        if (this.constructorStore)
        {
            return this.constructorStore.entity;
        }
        else if (this.relationshipConstructorStore)
        {
            return this.relationshipConstructorStore.relatedEntity;
        }
        else
        {
            return undefined;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    touchAll()
    {
        if (this.constructorStore)
        {
            this.constructorStore.touchAll();
        }

        if (this.relationshipConstructorStore)
        {
            this.relationshipConstructorStore.touch();
        }
    }

    @action.bound
    save(doOpen: boolean = false): Promise<Entity>
    {
        this.touchAll();

        if (this.isValid)
        {
            this.setState(StoreState.Loading);

            if (this.relationshipConstructorStore)
            {
                const relationship =
                    this.relationshipConstructorStore.createRelationship();

                const relatedEntity =
                    createTransactionalModel(
                        relationship.getEntity(
                            this.relationshipConstructorStore.isParent));

                this.transactionalEntity.addRelationship(
                    relationship,
                    this.relationshipConstructorStore.isParent);

                return this.transactionalEntity.checkAndDoCommit()
                    .then(() =>
                    {
                        this.setState(StoreState.Loaded);
                        this.close();

                        if (this.onSave)
                        {
                            this.onSave(relatedEntity);
                        }

                        return Promise.resolve(getModel(relatedEntity));
                    });
            }
            else
            {
                return this.constructorStore.entity.checkAndDoCommit()
                    .then(entity =>
                    {
                        this.setState(StoreState.Loaded);

                        this.close();

                        if (this.onSave)
                        {
                            this.onSave(entity);
                        }

                        return Promise.resolve(getModel(entity));
                    });
            }
        }
        else
        {
            return Promise.reject();
        }
    }

    @action.bound
    close()
    {
        if (this.onClose)
        {
            this.onClose();
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
