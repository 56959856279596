import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TypeName from '../TypeName/TypeName';
import ParentActivity from '../Activity/ParentActivity/ParentActivity';
import { ContentProps } from '../Content';
import Options from '../../Options/Options';
import InputGroup from '../../../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import styles from './Base.module.scss';
import equalsEntity from '../../../../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import ViewerEntityContext from '../../../../../../../../Context/ViewerEntity/ViewerEntityContext';
import useTypes from '../../../../../../../../../Type/Api/useTypes';
import useIsMobile from '../../../../../../../../../../../../@Util/Responsiveness/useIsMobile';
import Header from '../../Header/Header';
import Author from '../../Author/Author';
import Input from '../../../../../../../../../Input/Input';
import useMetadataSetting from '../../../../../../../../../../../../@Api/Metadata/useMetadataSetting';
import { Setting } from '../../../../../../../../../../../../@Api/Settings/Setting';
import EntityLayout from '../../../../../../../../../Layout/EntityLayout';
import Contact from '../Activity/Contact/Contact';
import IconButton from '../../../../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../../../../@Resource/Theme/Theme';
import useEntityValue from '../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import useRelatedEntity from '../../../../../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import CurrentUserContext from '../../../../../../../../../../User/CurrentUserContext';
import { CommitBuilder } from '../../../../../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface BaseProps extends ContentProps
{
    buttons?: React.ReactNode;
    suffix?: React.ReactNode;
    open?: boolean;
    expand?: boolean;
    noInputGroup?: boolean;
}

const Base: React.FC<BaseProps> =
    props =>
    {
        const types = useTypes();
        const viewerEntity = useContext(ViewerEntityContext);
        const currentUserStore = useContext(CurrentUserContext);

        const name =
            useComputed(
                () =>
                    (props.entity.entityType.switchableType || props.entity.entityType).getName(false, props.entity),
                [
                    props.entity
                ]);

        const parentActivity =
            useComputed(
                () =>
                {
                    const parentActivity =
                        props.entity.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.LinkedActivities,
                            props.commitContext
                        );

                    if (equalsEntity(parentActivity, viewerEntity))
                    {
                        return undefined;
                    }
                    else
                    {
                        return parentActivity;
                    }
                },
                [
                    props.entity,
                    types,
                    props.commitContext,
                    viewerEntity,
                ]);

        const hasContact =
            useComputed(
                () =>
                    props.entity.hasRelationshipsByDefinition(
                        true,
                        types.Activity.RelationshipDefinition.Contact,
                        props.commitContext
                    ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const hasNumber =
            useComputed(
                () =>
                    props.entity.hasValueForField(
                        types.Activity.Field.Number,
                        props.commitContext
                    ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const isMobile = useIsMobile();

        const isPinnedField =
            useComputed(
                () => {
                    if (props.entity.entityType.isA(types.Note.Type))
                    {
                        return types.Note.Field.IsPinned;
                    }
                    else
                    {
                        return undefined
                    }
                },
                [
                    types,
                    props.entity
                ]
            )

        const creator =
            useRelatedEntity(
                props.entity,
                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                true);

        const canBePinned =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Note.Type) &&
                    isPinnedField &&
                    (
                        (creator !== undefined && equalsEntity(creator, currentUserStore.employeeEntity)) ||
                            currentUserStore.isAdministrator
                    ),
                [
                    isPinnedField,
                    creator,
                    currentUserStore,
                    props.entity,
                    types
                ]
            );

        const isPinned =
            useEntityValue<Boolean>(
                props.entity,
                isPinnedField
            );

        const pinUnpin =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            props.entity,
                            isPinnedField,
                            !isPinned
                        )
                        .commit(),
                [
                    types,
                    props.entity,
                    isPinned,
                    isPinnedField
                ]
            )

        const [ layout, setLayout ] =
            useMetadataSetting(
                props.entity.entityType,
                Setting.Metadata.ActivityListItemLayout,
                props.isLayoutInEditMode);
        const closeLayout =
            useCallback(
                () =>
                    props.onChangeLayoutEditMode(false),
                [
                    props.onChangeLayoutEditMode
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                >
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={10}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <Header
                                            name={name}
                                            entity={props.entity}
                                            includeAuthor={!isMobile}
                                        />
                                    </ViewGroupItem>
                                    {
                                        props.suffix &&
                                            <ViewGroupItem
                                                className={styles.suffix}
                                            >
                                                {props.suffix}
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                            </ViewGroupItem>
                            {
                                canBePinned &&
                                <ViewGroupItem>
                                    <IconButton
                                        icon="favorite"
                                        tooltip={
                                            <LocalizedText
                                                code="ActvityListNote.PinUnpin"
                                                value="Favoriet"
                                            />
                                        }
                                        color={isPinned ? primaryColor : textSecondaryColor}
                                        onClick={pinUnpin}
                                    />
                                </ViewGroupItem>
                            }
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={0}
                                    alignment="center"
                                >
                                    {props.buttons}
                                </ViewGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <Options
                                    {...props}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                {
                                    (layout || props.isLayoutInEditMode)
                                        ?
                                            <EntityLayout
                                                entity={props.entity}
                                                isInEditMode={props.isLayoutInEditMode}
                                                onCloseEditMode={closeLayout}
                                                layout={layout}
                                                onChangeLayout={setLayout}
                                                commitContext={props.commitContext}
                                            />
                                        :
                                            props.noInputGroup
                                                ?
                                                    props.children
                                                :
                                                    <div
                                                        className={styles.inputGroup}
                                                    >
                                                        <InputGroup
                                                            inline
                                                            disabled={isMobile}
                                                        >
                                                            <TypeName
                                                                {...props}
                                                                disabled={isMobile}
                                                            />
                                                            {props.children}
                                                            {
                                                                hasContact &&
                                                                <Contact
                                                                    entity={props.entity}
                                                                />
                                                            }
                                                            {
                                                                parentActivity &&
                                                                    <ParentActivity
                                                                        {...props}
                                                                        parentActivity={parentActivity}
                                                                    />
                                                            }
                                                            {
                                                                hasNumber &&
                                                                    <Input
                                                                        entity={props.entity}
                                                                        field={types.Activity.Field.Number}
                                                                        labelPosition={props.labelPosition}
                                                                        inline={props.inlineLabel}
                                                                        disabled
                                                                        commitContext={props.commitContext}
                                                                    />
                                                            }
                                                        </InputGroup>
                                                    </div>
                                }
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            {
                isMobile &&
                    <ViewGroupItem>
                        <Author
                            entity={props.entity}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Base);
