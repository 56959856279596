import Predicate from './Predicate';
import { observable } from 'mobx';
import { LogicalOperator } from '../../../../../@Component/Domain/DataObject/Model/LogicalOperator';
import getPredicateFromDescriptor from '../../../Api/getPredicateFromDescriptor';
import Dependency from '../../../Parameter/Dependency';
import AutomationDependencyContext from '../../../AutomationDependencyContext';
import Validation from '../../../Validation/Validation';
import { OldCompositePredicate } from '../../../../../@Component/Domain/Predicate/Type/Composite/OldCompositePredicate';
import FunctionContext from '../../FunctionContext';
import { ComputationSubstitution } from '../Util/ComputationSubstitution';
import Parameter from '../../../Parameter/Parameter';

export default class CompositePredicate extends Predicate
{
    // ------------------------- Properties -------------------------

    @observable.ref operator: LogicalOperator;
    @observable.shallow predicates: Predicate[];

    // ------------------------ Constructor -------------------------

    constructor(operator: LogicalOperator,
                predicates: Predicate[])
    {
        super();

        this.operator = operator;
        this.predicates = predicates;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        switch (this.operator)
        {
            case LogicalOperator.Or:
                return '... en ...';

            case LogicalOperator.And:
                return '... of ...';
        }

        return '';
    }

    isAsync(): boolean
    {
        return this.predicates.some(
            predicate =>
                predicate.isAsync()
        );
    }

    async evaluate(context: FunctionContext): Promise<boolean>
    {
        switch (this.operator)
        {
            case LogicalOperator.And:
                for (const predicate of this.predicates)
                {
                    const isTrue = await predicate.evaluate(context);

                    if (!isTrue)
                    {
                        return false;
                    }
                }

                return true;

            case LogicalOperator.Or:
                for (const predicate of this.predicates)
                {
                    const isTrue = await predicate.evaluate(context);

                    if (isTrue)
                    {
                        return true;
                    }
                }

                return false;
        }

        throw new Error('Unknown operator');
    }

    synchronouslyEvaluate(context: FunctionContext): boolean
    {
        switch (this.operator)
        {
            case LogicalOperator.And:
                for (const predicate of this.predicates)
                {
                    const isTrue = predicate.synchronouslyEvaluate(context);

                    if (!isTrue)
                    {
                        return false;
                    }
                }

                return true;

            case LogicalOperator.Or:
                for (const predicate of this.predicates)
                {
                    const isTrue = predicate.synchronouslyEvaluate(context);

                    if (isTrue)
                    {
                        return true;
                    }
                }

                return false;
        }

        throw new Error('Unknown operator');
    }

    validate(): Validation[]
    {
        return this.predicates
            .map(
                predicate =>
                    predicate.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Composite';
        descriptor.operator = LogicalOperator[this.operator];
        descriptor.predicates =
            this.predicates.map(
                predicate =>
                    predicate.toDescriptor());
    }

    getDependencies(): Dependency[]
    {
        return this.predicates
            .map(
                predicate =>
                    predicate.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    getTrueStatements(
        context: FunctionContext,
        parameter?: Parameter<any>
    ): Predicate[]
    {
        switch (this.operator)
        {
            case LogicalOperator.And:
                return this.predicates
                    .map(
                        predicate =>
                            predicate.getTrueStatements(
                                context,
                                parameter
                            )
                    )
                    .reduce((a, b) => a.concat(b), []);

            case LogicalOperator.Or:
                const truePredicates =
                    this.predicates.filter(
                        predicate =>
                        {
                            const isStatementAboutParameter =
                                parameter === undefined
                                || predicate.getDependencies().some(
                                    dependency =>
                                        dependency.parameter === parameter
                                );

                            if (isStatementAboutParameter)
                            {
                                return true;
                            }
                            else
                            {
                                return predicate.synchronouslyEvaluate(context);
                            }
                        }
                    );

                if (truePredicates.length === 1)
                {
                    return truePredicates[0].getTrueStatements(
                        context,
                        parameter
                    );
                }
                else
                {
                    return [];
                }
        }

        return [ this ];
    }

    normalize(): Predicate
    {
        const normalizedPredicates: Predicate[] = [];

        for (const predicate of this.predicates)
        {
            const normalizedPredicate = predicate.normalize();

            if (normalizedPredicate instanceof CompositePredicate
                && this.operator === normalizedPredicate.operator)
            {
                normalizedPredicates.push(...normalizedPredicate.predicates.slice());
            }
            else
            {
                normalizedPredicates.push(normalizedPredicate);
            }
        }

        return new CompositePredicate(
            this.operator,
            normalizedPredicates);
    }

    substitute(substitution: ComputationSubstitution): Predicate
    {
        return new CompositePredicate(
            this.operator,
            this.predicates.map(
                predicate =>
                    predicate.substitute(substitution)));
    }

    toOldPredicate()
    {
        return new OldCompositePredicate(
            this.operator,
            this.predicates.map(
                predicate =>
                    predicate.toOldPredicate()));
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CompositePredicate(
            (LogicalOperator as any)[descriptor.operator],
            await Promise.all(
                descriptor.predicates.map(
                    predicate =>
                        getPredicateFromDescriptor(
                            predicate,
                            dependencyContext))));
    }

    // ----------------------- Private logic ------------------------
}
