import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import KeyEditor from './KeyEditor';
import { MappingEditorProps } from '../MappingEditor';
import HoverCardBottom from '../../../../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { runInAction } from 'mobx';
import Key from '../../../../../../../../../../../../@Api/Automation/Mapping/Key/Key';
import uuid from '../../../../../../../../../../../../@Util/Id/uuid';
import CompositePredicate from '../../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../../../../../DataObject/Model/LogicalOperator';
import ComparisonPredicate from '../../../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../../../../../../../DataObject/Model/Comparator';
import FunctionContext from '../../../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import LocalizedText from '../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface KeysEditorProps extends MappingEditorProps
{

}

const KeysEditor: React.FC<KeysEditorProps> =
    props =>
    {
        const dividerGlue = useDividerGlue();
        const addKey =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            props.mapping.keys.push(
                                new Key(
                                    uuid(),
                                    props.mapping.parameter,
                                    new CompositePredicate(
                                        LogicalOperator.And,
                                        [
                                            new ComparisonPredicate(
                                                new ValueFromEntityComputation(
                                                    props.mapping.parameter,
                                                    undefined),
                                                Comparator.Equals,
                                                undefined),
                                        ])))),
                [
                    props.mapping
                ]);

        const keyContext =
            useMemo(
                () =>
                    new FunctionContext(
                        props.context.parameterDictionary
                            .getNewDictionaryWithParameter(props.mapping.parameter),
                        props.context.parameterAssignment,
                        props.context.commitContext
                    ),
                [
                    props.context,
                    props.mapping
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            {
                props.mapping.keys.map(
                    (key, idx) =>
                        <ViewGroupItem
                            key={key.id}
                        >
                            <KeyEditor
                                {...props}
                                context={keyContext}
                                keyModel={key}
                                idx={idx}
                            />
                        </ViewGroupItem>)
            }
            <ViewGroupItem>
                <HoverCardBottom
                    onClick={addKey}
                >
                    + <LocalizedText
                        code="DetectDuplicates"
                        value="Duplicatendetectie"
                      />
                </HoverCardBottom>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(KeysEditor);
