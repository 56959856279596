import performAction from '../../../Entity/performAction';
import AutomationDependencyContext from '../../../Automation/AutomationDependencyContext';
import isInPortal from '../../Api/isInPortal';
import PortalDataSourceAggregateQuery from '../PortalDataSourceAggregateQuery';
import Value from '../../../Automation/Value/Value';
import getValueFromDescriptor from '../../../Automation/Api/getValueFromDescriptor';
import EmptyValue from '../../../Automation/Value/EmptyValue';

export async function aggregateQueryPortalDataSource(
    query: PortalDataSourceAggregateQuery
): Promise<Value<any, any>>
{
    if (isInPortal())
    {
        return queryDataSourceInPortal(query);
    }
    else
    {
        return EmptyValue.instance;
    }
}

async function queryDataSourceInPortal(
    query: PortalDataSourceAggregateQuery
): Promise<Value<any, any>>
{
    return performAction(
        undefined,
        {
            code: 'Portal.AggregateQueryPortalDataSource',
            parameters: {
                query: query.toDescriptor()
            }
        })
        .then(
            result =>
                getValueFromDescriptor(
                    result.result,
                    new AutomationDependencyContext(
                        query.dataSourceSignature.resultParameters
                    )
                )
        );
}
