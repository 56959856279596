import React from 'react';
import { RecurrencePatternEditorProps } from '../../../RecurrencePatternEditor';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { NumberedRecurrencePatternEnd } from '../../../../Model/End/RecurrencePatternEnd';
import NumberEditor from '../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';

export const NumberedRecurrencePatternEndEditor: React.FC<RecurrencePatternEditorProps<NumberedRecurrencePatternEnd>> =
    ({
        value,
        onChange,
     }) =>
    {
        return <Input
            label={
                <LocalizedText
                    code="NumberOfTimes"
                    value="Aantal keer"
                />
            }
            labelPosition="left"
        >
            <NumberEditor
                value={value.numberOfOccurrences}
                onChange={
                    numberOfOccurrences =>
                        onChange({
                            ...value,
                            numberOfOccurrences,
                        })
                }
            />
        </Input>;
    };
