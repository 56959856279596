import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import FtpUploadAction from '../../../../../../../../../../@Api/Automation/Function/Action/FtpUploadAction';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';

export interface FtpUploadActionEditorProps extends ActionEditorProps<FtpUploadAction>
{

}

const FtpUploadActionEditor: React.FC<FtpUploadActionEditorProps> =
    props =>
    {
        const fileType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('File')
                    ),
                []
            );
        const textType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Text')
                    ),
                []
            );
        const numberType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('Number')
                    ),
                []
            );
        const setFile =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.file = value
                    ),
                [
                    props.action
                ]
            );
        const setHost =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.host = value
                    ),
                [
                    props.action
                ]
            );
        const setPort =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.port = value
                    ),
                [
                    props.action
                ]
            );
        const setUsername =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.username = value
                    ),
                [
                    props.action
                ]
            );
        const setPassword =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.password = value
                    ),
                [
                    props.action
                ]
            );
        const setUploadPath =
            useCallback(
                (value: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.uploadPath = value
                    ),
                [
                    props.action
                ]
            );

        return <CardInset
            top={false}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.File"
                                value="Bestand"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={fileType}
                            value={props.action.file}
                            onChange={setFile}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Host"
                                value="Host"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={textType}
                            value={props.action.host}
                            onChange={setHost}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Port"
                                value="Port"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={numberType}
                            value={props.action.port}
                            onChange={setPort}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Username"
                                value="Gebruikersnaam"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={textType}
                            value={props.action.username}
                            onChange={setUsername}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Password"
                                value="Wachtwoord"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={textType}
                            value={props.action.password}
                            onChange={setPassword}
                        />
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.UploadPath"
                                value="Upload pad"
                            />
                        }
                        labelPosition="left"
                    >
                        <ComputationEditor
                            context={props.context}
                            type={textType}
                            value={props.action.uploadPath}
                            onChange={setUploadPath}
                        />
                    </Input>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(FtpUploadActionEditor);
