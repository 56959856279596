import React from 'react';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import PrimaryTextButton, { PrimaryTextButtonProps } from '../PrimaryTextButton/PrimaryTextButton';
import { Optional } from '../../../../../Util/TypeScript/Optional';

export interface CancelButtonProps extends Optional<PrimaryTextButtonProps, 'label'>
{

}

const CancelButton: React.FC<CancelButtonProps> =
    props =>
    {
        return <PrimaryTextButton
            label={
                <LocalizedText
                    code="Generic.Cancel"
                    value="Annuleren"
                />
            }
            {...props}
        />;
    };

export default CancelButton;
