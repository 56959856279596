import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import MapWithValueComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/MapWithValueComputation';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';

export interface MapWithValueComputationEditorProps extends ComputationEditorProps<ValueType<any>, MapWithValueComputation>
{

}

const MapWithValueComputationEditor: React.FC<MapWithValueComputationEditorProps> =
    props =>
    {
        const { value, context } = props;

        const setMap =
            useCallback(
                (map?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            value.map = map;
                        }),
                [
                    value
                ]);
        const setKey =
            useCallback(
                (key?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.key = key),
                [
                    value
                ]);
        const setValue =
            useCallback(
                (mapValue?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.value = mapValue),
                [
                    value
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Map"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.map}
                        onChange={setMap}
                    />
                </ComputationBox>
            </Input>
            <Input
                label="Sleutel"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.key}
                        onChange={setKey}
                    />
                </ComputationBox>
            </Input>
            <Input
                label="Waarde"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.value}
                        onChange={setValue}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(MapWithValueComputationEditor);
