import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import Link from '../Link/Link';
import LinkList from '../LinkList/LinkList';
import HeaderContext from '../../Context/HeaderContext';
import { CaptionClasses } from '../Caption';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface ActivityProps
{
    entity: Entity;
    classes?: CaptionClasses;
}

//      Bij {parent relationship}
//      voor {linked parent activities}

//      Rules
//      (1) If {viewer entity} is {parent relationship}, then omit {parent relationship}
//      (2) If {viewer entity} is one of {linked parent activities}, then omit that specific {linked parent activity}
//      (3) If {viewer entity's parent relationship} is {parent relationship}, then don't show by...

const Activity: React.FC<ActivityProps> =
    props =>
    {
        const types = useTypes();
        const viewerEntity = useContext(ViewerEntityContext);
        const isInHeader = useContext(HeaderContext);

        const parentRelationship =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities),
                [
                    props.entity,
                    types
                ]);

        const viewerEntityParentRelationship =
            useComputed(
                () =>
                    viewerEntity && viewerEntity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities),
                [
                    viewerEntity,
                    types
                ]);

        const linkedParentActivities =
            useComputed(
                () =>
                    props.entity.getRelatedEntitiesByDefinition(
                        true,
                        types.Activity.RelationshipDefinition.LinkedActivities),
                [
                    props.entity,
                    types
                ]);

        const showBy =
            useMemo(
                () =>
                {
                    if (viewerEntity)
                    {
                        return viewerEntity.entityType.isA(types.Activity.Type)
                            && !equalsEntity(viewerEntity, parentRelationship)
                            && !isInHeader
                            && !equalsEntity(parentRelationship, viewerEntityParentRelationship);
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    viewerEntity,
                    parentRelationship,
                    viewerEntityParentRelationship,
                    isInHeader,
                    types
                ]);

        let byPart;

        if (showBy && parentRelationship)
        {
            byPart =
                <span>
                    {localizeText('Generic.By', 'bij')} <Link entity={parentRelationship} classes={props.classes && props.classes.link} />
                </span>;
        }

        const linkedActivities =
            useComputed(
                () =>
                    linkedParentActivities.filter(
                        linkedParentActivity =>
                            !equalsEntity(linkedParentActivity, viewerEntity)),
                [
                    linkedParentActivities,
                    viewerEntity
                ]);

        let forPart;

        if (linkedActivities.length > 0)
        {
            forPart = <span>{byPart ? localizeText('Generic.For', 'voor') : ''} <LinkList entities={linkedActivities} classes={props.classes && props.classes.link} /></span>;
        }

        if (byPart || forPart)
        {
            return <span>
                {byPart} {forPart}
            </span>;
        }
        else
        {
            return null;
        }
    };

export default observer(Activity);
