export class Easing
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    public static library =
    {
        linear: (t: number) => t,
        easeInQuad: (t: number) => t * t,
        easeOutQuad: (t: number) => t * (2 - t),
        easeInOutQuad: (t: number) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
        easeInCubic: (t: number) => t * t * t,
        easeOutCubic: (t: number) => (--t) * t * t + 1,
        easeInOutCubic: (t: number) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
        easeInQuart: (t: number) => t * t * t * t,
        easeOutQuart: (t: number) => 1 - (--t) * t * t * t,
        easeInOutQuart: (t: number) => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
        easeInQuint: (t: number) => t * t * t * t * t,
        easeOutQuint: (t: number) => 1 + (--t) * t * t * t * t,
        easeInOutQuint: (t: number) => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
    };

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public static linear(t: number)
    {
        return Easing.library.linear(t);
    }

    public static easeInQuad(t: number)
    {
        return Easing.library.easeInQuad(t);
    }

    public static easeOutQuad(t: number)
    {
        return Easing.library.easeOutQuad(t);
    }

    public static easeInOutQuad(t: number)
    {
        return Easing.library.easeInOutQuad(t);
    }

    public static easeInCubic(t: number)
    {
        return Easing.library.easeInCubic(t);
    }

    public static easeOutCubic(t: number)
    {
        return Easing.library.easeOutCubic(t);
    }

    public static easeInOutCubic(t: number)
    {
        return Easing.library.easeInOutCubic(t);
    }

    public static easeInQuart(t: number)
    {
        return Easing.library.easeInQuart(t);
    }

    public static easeOutQuart(t: number)
    {
        return Easing.library.easeOutQuart(t);
    }

    public static easeInOutQuart(t: number)
    {
        return Easing.library.easeInOutQuart(t);
    }

    public static easeInQuint(t: number)
    {
        return Easing.library.easeInQuint(t);
    }

    public static easeOutQuint(t: number)
    {
        return Easing.library.easeOutQuint(t);
    }

    public static easeInOutQuint(t: number)
    {
        return Easing.library.easeInOutQuint(t);
    }

    // ----------------------- Private logic ------------------------
}
