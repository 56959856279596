import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import Popper from '../../../../@Future/Component/Generic/Popper/Popper';
import Input, { LabelPosition } from '../../../../@Future/Component/Generic/Input/Input/Input';
import useSwitch from '../../../../@Util/Switch/useSwitch';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import InputGroup from '../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { EntityPath } from '../Path/@Model/EntityPath';
import { Editor } from './Editor/Editor'
import Link from '../../../../@Future/Component/Generic/Link/Link';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { UnderlineMode } from '../Input/UnderlineMode';
import useEntityValue from '../../../../@Api/Entity/Hooks/useEntityValue';
import TextEditor from '../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface PlannerProps
{
    entity: Entity;
    startDateField: EntityField;
    endDateField: EntityField;
    employeePath?: EntityPath;
    autoCommit?: boolean;
    disabled?: boolean;
    labelPosition?: LabelPosition;
    underline?: UnderlineMode;
    commitContext?: CommitContext;
}

const Planner: React.FC<PlannerProps> =
    props =>
    {
        const [ isOpen, open, cancel ] = useSwitch(false);
        const startDate =
            useEntityValue(
                props.entity,
                props.startDateField,
                undefined,
                props.commitContext
            );
        const endDate =
            useEntityValue(
                props.entity,
                props.endDateField,
                undefined,
                props.commitContext
            );
        const formattedDateTime =
            useMemo(
                () =>
                {
                    // 01-01-2020 0:00 - 02-01-2020 0:00 => 01-01-2020
                    // 01-01-2020 0:00 - 03-01-2020 0:00 => 01-01-2020 - 03-01-2020

                    if (!startDate || !endDate)
                    {
                        return localizeText('ChooseDate', 'Kies een datum');
                    }

                    const start = moment(startDate);
                    const end = moment(endDate);
                    const isStartDateOnStartOfDay = start.format('HH:mm') === '00:00';
                    const isEndDateOnEndOfDay = end.format('HH:mm') === '23:59' || end.format('HH:mm') === '00:00';
                    const isCoveringFullDay = isStartDateOnStartOfDay && isEndDateOnEndOfDay;
                    const isStartAndEndOnSameDay = start.format('YYYYMMDD') === end.format('YYYYMMDD');

                    if (isCoveringFullDay)
                    {
                        if (isStartAndEndOnSameDay)
                        {
                            return `${start.format('DD-MM-YYYY')}`;
                        }
                        else
                        {
                            return `${start.format('DD-MM-YYYY')} - ${end.format('DD-MM-YYYY')}`;
                        }
                    }
                    else if (isStartAndEndOnSameDay)
                    {
                        return `${start.format('DD-MM-YYYY HH:mm')} - ${end.format('HH:mm')}`;
                    }
                    else
                    {
                        return `${start.format('DD-MM-YYYY HH:mm')} - ${end.format('DD-MM-YYYY HH:mm')}`;
                    }
                },
                [
                    startDate,
                    endDate
                ]);

        const close =
            useCallback(
                (startDateField?: EntityField,
                 date?: Date,
                 endDateField?: EntityField,
                 endDate?: Date,
                 employeePath?: EntityPath,
                 employee?: Entity) =>
                {
                    cancel();

                    if (startDateField)
                    {
                        setValueByFieldInEntity(
                            props.entity,
                            startDateField,
                            date,
                            props.commitContext
                        );
                    }

                    if (endDateField)
                    {
                        setValueByFieldInEntity(
                            props.entity,
                            endDateField,
                            endDate,
                            props.commitContext
                        );
                    }

                    if (employeePath && employee)
                    {
                        employeePath.field()
                            .setValue(
                                props.entity,
                                employee,
                                undefined,
                                props.commitContext
                            );
                    }

                    if (props.autoCommit)
                    {
                        return commitEntityWithContext(
                            props.entity,
                            props.commitContext
                        );
                    }
                },
                [
                    cancel,
                    props.entity,
                    props.autoCommit,
                    props.commitContext,
                ]);

        return <div
            onClick={open}
        >
            <Popper
                open={isOpen && !props.disabled}
                onClose={cancel}
                fullWidth
                reference={
                    props.children
                        ?
                        <Link
                            onClick={open}
                        >
                            {props.children}
                        </Link>
                        :
                        <InputGroup>
                            <Input
                                labelPosition={props.labelPosition}
                                label={
                                    <LocalizedText
                                        code="Generic.Date"
                                        value="Datum"
                                    />
                                }
                            >
                                {
                                    props.disabled
                                        ?
                                        formattedDateTime
                                        :
                                        <TextEditor
                                            value={formattedDateTime}
                                            disableUnderline
                                        />
                                }
                            </Input>
                        </InputGroup>
                }
                popper={
                    <Editor
                        entity={props.entity}
                        onClose={close}
                        startDateField={props.startDateField}
                        endDateField={props.endDateField}
                        employeePath={props.employeePath}
                        commitContext={props.commitContext}
                    />
                }
            />
        </div>;
    };

Planner.defaultProps = {
    labelPosition: 'left'
};

export default observer(Planner);
