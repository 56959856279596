import { EntityType } from '../Model/Implementation/EntityType';
import { useCallback, useContext, useMemo } from 'react';
import useMetadataBaseSetting from './useMetadataBaseSetting';
import useMetadataSettingOverride from './useMetadataSettingOverride';
import { useComputed } from 'mobx-react-lite';
import CurrentUserContext from '../../@Component/Domain/User/CurrentUserContext';
import useTypes from '../../@Component/Domain/Entity/Type/Api/useTypes';
import { useExpansion } from '../../@Component/Domain/Entity/Selection/Api/useExpansion';

export default function useMetadataSetting<T>(entityType: EntityType,
                                              code: string,
                                              isInEditMode: boolean): [ T, (value?: T) => void, boolean, boolean ]
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);
    const [ baseSetting, setBaseSetting ] = useMetadataBaseSetting<T>(entityType, code, isInEditMode);
    const [ settingOverride, setSettingOverride ] = useMetadataSettingOverride<T>(entityType, code);

    useExpansion(
        types.EntityType.Type,
        rootPath =>
            isInEditMode
                ? [
                    rootPath
                        .joinTo(
                            types.Pack.RelationshipDefinition.Entities,
                            true)
                ]
                : [],
        () => isInEditMode ? [ entityType.entity ] : [],
        [
            types,
            isInEditMode,
            entityType
        ]);

    const isAllowedToSetBaseSetting =
        useComputed(
            () =>
                currentUserStore.rightProfile.isAllowedToMutate(entityType.entity),
            [
                currentUserStore
            ]);

    const setting =
        useMemo(
            () =>
                settingOverride || baseSetting,
            [
                baseSetting,
                settingOverride
            ]);

    const isBaseSetting =
        useMemo(
            () =>
                baseSetting !== undefined && settingOverride === undefined,
            [
                baseSetting,
                settingOverride
            ]);

    const isSetterForBaseSetting =
        useMemo(
            () =>
                isAllowedToSetBaseSetting && settingOverride === undefined,
            [
                isAllowedToSetBaseSetting,
                settingOverride
            ]);

    const setSetting =
        useCallback(
            (value?: T) =>
            {
                if (isSetterForBaseSetting)
                {
                    setBaseSetting(value);
                }
                else
                {
                    setSettingOverride(value);
                }
            },
            [
                isSetterForBaseSetting,
                setBaseSetting,
                setSettingOverride
            ]);

    return [ setting, setSetting, isBaseSetting, isSetterForBaseSetting ];
}
