import { Entity } from '../../../Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useTypes from '../../../../@Component/Domain/Entity/Type/Api/useTypes';
import { CommitContext } from '../../Commit/Context/CommitContext';

export default function useChildRelation(
    entity: Entity,
    commitContext?: CommitContext
)
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            if (entity)
            {
                return entity
                    .getRelatedEntityByDefinition(
                        false,
                        types.Relationship.Person.RelationshipDefinition.Person,
                        commitContext
                    )
                    ||
                    entity
                        .getRelatedEntityByDefinition(
                            false,
                            types.Relationship.Organization.RelationshipDefinition.Organization,
                            commitContext
                        );
                }
            else
            {
                return undefined;
            }
        },
        [
            entity,
            types,
            commitContext,
        ]
    );
}
