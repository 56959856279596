import * as React from 'react';
import { Typography } from '@material-ui/core';
import { DataObjectBespokeViewer } from '../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { Alignment } from '../../Model/DataObject';
import { TextViewerStore } from './TextViewerStore';
import RichText from '../../../../Generic/RichText/RichText';

@observer
export class TextView extends DataObjectBespokeViewer<TextViewerStore>
{
    renderComponent(store: DataObjectViewerStore<TextViewerStore>)
    {
        if (store.bespokeStore.isRichText)
        {
            return <RichText
                value={store.dataObject.value}
            />;
        }
        else
        {
            return <Typography
                variant="body2"
                style={{
                    ...store.style,
                    textAlign:
                        store.alignment === Alignment.Left
                            ?
                                'left'
                            :
                                store.alignment === Alignment.Center
                                    ?
                                        'center'
                                    :
                                        'right'
                }}
            >
                {store.dataObject.toString(store.dataObjectRepresentation)}
            </Typography>;
        }
    }
}
