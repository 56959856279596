import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import SelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import FormInputPlaceholderEditor from '../Shared/FormInputPlaceholderEditor/FormInputPlaceholderEditor';
import FormInputIsDisabledEditor from '../Shared/FormInputIsDisabledEditor/FormInputIsDisabledEditor';
import SelectionFormInputLayoutDataSourceEditor from './SelectionFormInputLayoutDataSourceEditor';
import FormInputParameterViewer from '../Shared/FormInputParameterViewer/FormInputParameterViewer';

export interface SelectionFormInputControlsProps extends LayoutEditorProps<SelectionFormInputLayout>
{

}

const SelectionFormInputLayoutControls: React.FC<SelectionFormInputControlsProps> =
    props =>
    {
        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <FormInputParameterViewer
                    {...props}
                />
                <SelectionFormInputLayoutDataSourceEditor
                    {...props}
                />
                <FormInputPlaceholderEditor
                    {...props}
                />
                <FormInputIsDisabledEditor
                    {...props}
                />
            </InputGroup>
        </CardInset>;
    };

export default observer(SelectionFormInputLayoutControls);
