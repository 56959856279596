import { reference } from '../../../@Util/Serialization/Serialization';
import { CompositeConstraintNode } from './CompositeConstraintNode';
import { observable } from 'mobx';
import { SelectionNode } from './SelectionNode';
import { EntityNode } from './EntityNode';

export class ConstraintNode extends SelectionNode
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'CompositeConstraintNode') @observable.ref parentNode: CompositeConstraintNode;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [];
    }

    descriptor()
    {
        return {
            type: 'Constraint'
        };
    }

    // ----------------------- Private logic ------------------------
}
