import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../Editor/LayoutEditor';
import InputGroup from '../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import NumberEditor from '../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Switch from '../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import CollectionLayout from '../../../../../../../@Api/Layout/Type/CollectionLayout';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ComputationPopperEditor from '../../../../../Entity/Viewer/Content/Automation/Editor/Computation/Dialog/ComputationDialogEditor';
import FunctionContext from '../../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import Computation from '../../../../../../../@Api/Automation/Function/Computation/Computation';
import uuid from '../../../../../../../@Util/Id/uuid';

export interface CollectionLayoutControlsProps extends LayoutEditorProps<CollectionLayout>
{

}

const CollectionLayoutControls: React.FC<CollectionLayoutControlsProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;

        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const setCollection =
            useCallback(
                (collection: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            if (layout.collection !== collection)
                            {
                                layout.collection = collection;
                                layout.elementParameter =
                                    CollectionLayout.getParameterFromCollection(
                                        layout.elementParameter?.id || uuid(),
                                        collection);
                            }
                        }),
                [
                    layout
                ]);
        const itemContext =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary.getNewDictionaryWithParameter(layout.elementParameter),
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    layout,
                    parameterAssignment,
                    commitContext,
                ]);
        const setItemRatio =
            useCallback(
                (itemRatio: Computation<any, any>) =>
                    runInAction(
                        () =>
                            layout.itemRatio = itemRatio),
                [
                    layout
                ]);

        const setSpacing =
            useCallback(
                (value: number) =>
                    runInAction(
                        () =>
                            props.layout.spacing = value === undefined ? 0 : value),
                [
                    props.layout
                ]);

        const setHasDivider =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                            props.layout.hasDivider = value),
                [
                    props.layout
                ]);

        const setWrapped =
            useCallback(
                (value: boolean) =>
                    runInAction(
                        () =>
                            props.layout.isWrapped = value),
                [
                    props.layout
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.Collection"
                            value="Collectie"
                        />
                    }
                >
                    <ComputationPopperEditor
                        context={context}
                        value={layout.collection}
                        onChange={setCollection}
                    />
                </Input>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.Ratio"
                            value="Ratio"
                        />
                    }
                >
                    <ComputationPopperEditor
                        context={itemContext}
                        value={layout.itemRatio}
                        onChange={setItemRatio}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label="Spacing"
                >
                    <NumberEditor
                        value={props.layout.spacing}
                        onChange={setSpacing}
                        min={0}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label="Scheidingsbalken"
                >
                    <Switch
                        checked={props.layout.hasDivider}
                        onToggle={setHasDivider}
                    />
                </Input>
                {
                    props.layout.orientation === 'Horizontal' &&
                        <Input
                            labelPosition="left"
                            label="Doorgaan op volgende regel bij te weinig ruimte"
                        >
                            <Switch
                                checked={props.layout.isWrapped}
                                onToggle={setWrapped}
                            />
                        </Input>
                }
                </InputGroup>
        </CardInset>;
    };

export default observer(CollectionLayoutControls);
