import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { default as GenericInput } from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizerContext from '../../../../../../../../../../../../../@Service/Localization/LocalizerContext';
import { ContentProps } from '../../Content';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import useEntityValue from '../../../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface InvoiceProps extends ContentProps
{

}

const Invoice: React.FC<InvoiceProps> =
    props =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);

        const period =
            useComputed(
                () =>
                {
                    if (props.entity.hasValueForField(types.Activity.Invoice.Field.PeriodStartDate, props.commitContext)
                        && props.entity.hasValueForField(types.Activity.Invoice.Field.PeriodEndDate, props.commitContext))
                    {
                        return `${localizer.formatDate(props.entity.getObjectValueByField(types.Activity.Invoice.Field.PeriodStartDate, props.commitContext))} - ${localizer.formatDate(props.entity.getObjectValueByField(types.Activity.Invoice.Field.PeriodEndDate, props.commitContext))}`;
                    }
                },
                [
                    props.entity,
                    types,
                    localizer,
                    props.commitContext,
                ]);

        const currency =
            useEntityValue<string>(
                props.entity,
                types.Activity.Field.Currency);

        const fields =
            [
                <Input
                    key={0}
                    entity={props.entity}
                    field={
                        currency
                            ? types.Activity.Field.AmountInCurrency
                            : types.Activity.Field.Amount
                    }
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />,
                <Input
                    key={1}
                    entity={props.entity}
                    field={types.Activity.Invoice.RelationshipDefinition.Phase}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />,
                period &&
                    <GenericInput
                        key={2}
                        label={
                            <LocalizedText
                                code="Generic.Period"
                                value="Periode"
                            />
                        }
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                    >
                        {period}
                    </GenericInput>,
                props.entity.hasValueForField(types.Activity.Invoice.Field.Date) &&
                    <Input
                        key={3}
                        entity={props.entity}
                        field={types.Activity.Invoice.Field.Date}
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                        disabled
                        commitContext={props.commitContext}
                    />
            ];

        return <Base
            {...props}
            expand
        >
            {fields}
        </Base>;
    };

export default observer(Invoice);
