import React from 'react';
import { observer } from 'mobx-react-lite';
import BodyLayout from '../../../../../../@Api/Layout/Type/BodyLayout';

export interface CenteredContentViewProps
{
    layout: BodyLayout;
}

const BodyView: React.FC<CenteredContentViewProps> =
    props =>
    {
        return <div
            style={{
                height: '100%',
                width: '100%',
                backgroundColor: props.layout.backgroundColor?.toCss()
            }}
        >
            {props.children}
        </div>;
    };

export default observer(BodyView);
