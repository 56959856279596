import { action, observable } from 'mobx';
import { OrganizationEnvironmentContext } from './Context/OrganizationEnvironmentContext';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { BodyStore } from './Body/BodyStore';
import { HeaderStore } from './Header/HeaderStore';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { DrawerStore } from '../../../../Generic/Drawer/DrawerStore';
import { FeedbackStore } from './Feedback/FeedbackStore';
import { initializeOutlookAddIn, initializeWordAddIn } from '../Public/AddIn/OfficeAddin';

export class OrganizationEnvironmentStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LeftDrawerStore') leftDrawerStore: DrawerStore;
    @injectWithQualifier('RightDrawerStore') rightDrawerStore: DrawerStore;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    @observable.ref context: OrganizationEnvironmentContext;
    @observable.ref headerStore: HeaderStore;
    @observable.ref bodyStore: BodyStore;

    // ------------------------ Constructor -------------------------

    constructor(moduleManager: ModuleManager)
    {
        super();

        this.context = new OrganizationEnvironmentContext(moduleManager);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.context.initialize()
            .then(() => this.postInitialize());
    }

    @action.bound
    postInitialize()
    {
        this.headerStore = new HeaderStore();
        this.bodyStore = new BodyStore(this.headerStore);

        return Promise.all(
            [
                initializeOutlookAddIn(),
                initializeWordAddIn()
            ]
        )
        .then();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
