import React from 'react';

export interface DatabaseStatusCheckerProps
{

}

export const DatabaseStatusChecker: React.FC<DatabaseStatusCheckerProps> =
    ({
        children,
     }) =>
    {
        return <>{children}</>;

        // const apiClient = useApiClient();
        // const [ databases, isLoading ] =
        //     useAsyncResult(
        //         () =>
        //             apiClient.request(
        //                 new ApiRequest<Database[]>(
        //                     '/databases',
        //                     Method.Get
        //                 )
        //             ),
        //         [
        //             apiClient
        //         ]
        //     );
        // const isOnline =
        //     useMemo(
        //         () =>
        //             (databases ?? []).some(
        //                 database =>
        //                     database.status === 'Online'
        //             ),
        //         [
        //             databases
        //         ]
        //     );
        // const isInitializing =
        //     useMemo(
        //         () =>
        //             (databases ?? []).some(
        //                 database =>
        //                     database.status === 'Created'
        //                     || database.status === 'Initializing'
        //                     || database.status === 'CatchingUp'
        //             ),
        //         [
        //             databases
        //         ]
        //     );
        //
        // if (isLoading)
        // {
        //     return null;
        // }
        // else if (isOnline)
        // {
        //     return <>{children}</>;
        // }
        // else if (isInitializing)
        // {
        //     return <div
        //         className={styles.root}
        //     >
        //         <div
        //             className={styles.button}
        //         >
        //             <EnvironmentButton />
        //         </div>
        //         <Centered
        //             horizontal
        //             vertical
        //         >
        //             <div
        //                 className={styles.container}
        //             >
        //                 <ViewGroup
        //                     orientation="vertical"
        //                     spacing={30}
        //                 >
        //                     <ViewGroupItem>
        //                         <ViewGroup
        //                             orientation="vertical"
        //                             spacing={20}
        //                         >
        //                             <ViewGroupItem>
        //                                 <Centered
        //                                     horizontal
        //                                 >
        //                                     <Logo />
        //                                 </Centered>
        //                             </ViewGroupItem>
        //                             <ViewGroupItem>
        //                                 <Centered
        //                                     horizontal
        //                                 >
        //                                     <img className={styles.loadingIcon} src={LoadingIcon} />
        //                                 </Centered>
        //                             </ViewGroupItem>
        //                             <ViewGroupItem>
        //                                 <ViewGroup
        //                                     orientation="vertical"
        //                                     spacing={5}
        //                                 >
        //                                     <ViewGroupItem
        //                                         className={styles.tryForFree}
        //                                     >
        //                                         <LocalizedText
        //                                             code="DatabaseStatusChecking.CheckingStatus"
        //                                             value="Je database wordt geïnitializeerd..."
        //                                         />
        //                                     </ViewGroupItem>
        //                                     {/*<ViewGroupItem*/}
        //                                     {/*    className={styles.tryForFreeDetails}*/}
        //                                     {/*>*/}
        //                                     {/*    <LocalizedText*/}
        //                                     {/*        code="EnvironmentRegistration.EmailReceivalNotice"*/}
        //                                     {/*        value="Zometeen ontvang je een bevestigingsemail in je mailbox."*/}
        //                                     {/*    />*/}
        //                                     {/*</ViewGroupItem>*/}
        //                                 </ViewGroup>
        //                             </ViewGroupItem>
        //                         </ViewGroup>
        //                     </ViewGroupItem>
        //                 </ViewGroup>
        //             </div>
        //         </Centered>
        //     </div>;
        // }
        // else
        // {
        //     return <span>
        //         Er is een probleem met uw omgeving. Neem contact op met support om het op te lossen.
        //     </span>;
        // }
    };
