import { DataObject } from './DataObject';

export enum MathematicalOperator
{
    Add,
    Subtract,
    Divide,
    Multiply
}

export interface MathematicalOperation
{
    add: (dataObject: DataObject) => DataObject;
    subtract: (dataObject: DataObject) => DataObject;
    divide: (dataObject: DataObject) => DataObject;
    multiply: (dataObject: DataObject) => DataObject;
}
