import { observable } from 'mobx';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export default class FilterOption
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref fieldPath: EntityFieldPath;
    @observable isRange: boolean;
    @observable isLabelHidden: boolean;
    @observable.ref value?: DataObject | Entity;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                fieldPath: EntityFieldPath,
                isRange: boolean,
                isLabelHidden: boolean)
    {
        this.id = id;
        this.fieldPath = fieldPath;
        this.isRange = isRange;
        this.isLabelHidden = isLabelHidden;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getFilterOptionSettingId(filterOptionId: string)
    {
        return `Dataset.FilterOption.${filterOptionId}`;
    }

    static fromDescriptor(descriptor: any)
    {
        return new FilterOption(
            descriptor.id,
            EntityFieldPath.construct(descriptor.fieldPath),
            descriptor.isRange,
            descriptor.isLabelHidden);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            fieldPath: this.fieldPath.descriptor,
            isRange: this.isRange,
            isLabelHidden: this.isLabelHidden
        };
    }

    // ----------------------- Private logic ------------------------
}
