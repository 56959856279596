import { useContext } from 'react';
import NavigatorContext from '../Navigator/NavigatorContext/NavigatorContext';

export default function useUniqueItemId()
{
    const context = useContext(NavigatorContext);

    if (context)
    {
        return context.id;
    }
    else
    {
        return undefined;
    }
}