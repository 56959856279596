import { observable } from 'mobx';
import { OldPredicate } from '../OldPredicate';
import NotPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/NotPredicate';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export class OldNotPredicate extends OldPredicate
{
    // ------------------------- Properties -------------------------

    @observable predicate: OldPredicate;

    // ------------------------ Constructor -------------------------

    constructor(predicate: OldPredicate)
    {
        super('Not');

        this.predicate = predicate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTrueStatements(): OldPredicate[]
    {
        return [ this ];
    }

    getLeafs(): OldPredicate[]
    {
        return this.predicate.getLeafs();
    }

    async initialize(): Promise<any>
    {
        return this.predicate?.initialize();
    }

    async toNewPredicate(context: AutomationDependencyContext)
    {
        return new NotPredicate(
            await this.predicate?.toNewPredicate(context)
        );
    }

    // ----------------------- Private logic ------------------------
}
