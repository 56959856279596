import { BaseTypeStore } from '../../../../@Framework/Store/BaseTypeStore';
import { EntityEventType } from './Model/EntityEventType';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { EntityEvent } from '../../../../@Api/Model/Implementation/EntityEvent';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EventValueUpdate } from './Type/EventValueUpdate';
import { EntityCreate } from './Type/EventCreate';
import { IObservableArray, observable } from 'mobx';
import { EntityEventListener } from './EntityEventListener';
import { EventValueCreate } from './Type/EventValueCreate';
import { EventValueDelete } from './Type/EventValueDelete';
import { EventRelationshipCreate } from './Type/EventRelationshipCreate';
import { EventRelationshipDelete } from './Type/EventRelationshipDelete';
import { EventAction } from './Type/EventAction';
import { EventRead } from './Type/EventRead';
import { EventReadPublic } from './Type/EventReadPublic';
import { EventRelationshipUpdate } from './Type/EventRelationshipUpdate';
import { EntityCacheReferrer } from '../../../Service/Entity/EntityCacheReferrer';

const UnknownEvent: EntityCacheReferrer = 'UnknownEvent';

export class EntityEventTypeStore extends BaseTypeStore<EntityEventType>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    @observable listeners = observable.map<string, IObservableArray<EntityEventListener>>();

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super(
            [
                new EntityCreate(),
                new EventValueCreate(),
                new EventValueUpdate(),
                new EventValueDelete(),
                new EventRelationshipCreate(),
                new EventRelationshipUpdate(),
                new EventRelationshipDelete(),
                new EventAction(),
                new EventRead(),
                new EventReadPublic()
            ]);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    initializeEvents(events: EntityEvent[],
                     doMergeEditableValues: boolean = true,
                     cacheReferrer: EntityCacheReferrer = UnknownEvent)
    {
        const entitiesToInitialize: Entity[] = [];

        events.forEach(
            event =>
            {
                event.type = this.getTypeById(event.type as any);
                event.initialize(this.entityTypeStore);

                entitiesToInitialize.push(
                    ...event.getEntitiesToInitialize());
            });

        // Initialize entities without merging with cache
        entitiesToInitialize.forEach(
            entity =>
                entity.initialize(
                    this.entityTypeStore,
                    undefined,
                    undefined,
                    undefined,
                    false));

        // Merge entities into cache in one go
        // Simultaneously, build a map containing the cached entity instances such that they can be
        // overwritten in the events
        const initializedEntityById = new Map<number, Entity>();

        this.entityTypeStore.entityCacheService
            .mergeEntityNetworkForEntities(
                entitiesToInitialize,
                doMergeEditableValues,
                cacheReferrer)
            .forEach(
                entity =>
                    initializedEntityById.set(
                        entity.id,
                        entity));

        events.forEach(
            event =>
                event.postInitialize(initializedEntityById));
    }

    // ----------------------- Private logic ------------------------
}
