import React, { useMemo } from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { EmployeeAllocationEditor } from '../../../../../../EmployeeAllocationEditor/EmployeeAllocationEditor';
import useTypes from '../../../../../../Type/Api/useTypes';
import { getProjectEmployeeAllocationSpecification } from '../../../../../../EmployeeAllocationEditor/Api/getProjectEmployeeAllocationSpecification';

export interface EmployeeAllocationProps
{
    project: Entity;
    milestone?: Entity;
    settingStorageKey?: string;
}

const EmployeeAllocation: React.FC<EmployeeAllocationProps> =
    ({
        project,
        milestone,
        settingStorageKey,
     }) =>
    {
        const types = useTypes();
        const specification =
            useMemo(
                () =>
                    getProjectEmployeeAllocationSpecification(
                        project,
                        milestone
                    ),
                [
                    project,
                    milestone,
                ]
            );

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <EmployeeAllocationEditor
                    specification={specification}
                    minDate={project.getObjectValueByField(types.Activity.Project.Field.StartDate)}
                    maxDate={project.getObjectValueByField(types.Activity.Project.Field.EndDate)}
                    settingStorageKey={settingStorageKey}
                />
            </CardInset>
        </Card>;
    };

export default observer(EmployeeAllocation);
