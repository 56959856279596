import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';
import { EntityPath } from '../../../../../../../../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../../../../../../../../Selection/Builder/EntityExpansionBuilder';

export interface ActivitySigningContentProps extends ContentProps
{

}

const ActivitySigningContent: React.FC<ActivitySigningContentProps> =
    props =>
    {
        const types = useTypes();

        useEffect(
            () =>
            {
                const rootPath = EntityPath.fromEntity(props.entity);

                new EntityExpansionBuilder(
                    props.entity.entityType,
                    [
                        props.entity
                    ],
                    [
                        rootPath
                            .joinTo(
                                types.Activity.DigitalSigning.RelationshipDefinition.Phase,
                                false
                            )
                    ]
                )
                .expand()
            },
            [
                props.entity,
                types
            ]);

        const fields =
            [
                <Input
                    key={0}
                    entity={props.entity}
                    field={types.Activity.DigitalSigning.RelationshipDefinition.Phase}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    disabled
                    commitContext={props.commitContext}
                />
            ];

        return <Base
            {...props}
            expand
        >
            {fields}
        </Base>;
    };

export default observer(ActivitySigningContent);
