import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import WebhookEditor from '../../../../Configuration/Page/Api/Webhooks/Webhook/WebhookEditor';
import { useExpansion } from '../../../Selection/Api/useExpansion';
import useTypes from '../../../Type/Api/useTypes';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { WebhookEventsTable } from './EventsTable/WebhookEventsTable';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface WebhookProps
{
    entity: Entity;
    commitContext?: CommitContext;
}

const Webhook: React.FC<WebhookProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();

        const [ isLoading ] =
            useExpansion(
                types.Webhook.Type,
                rootPath => [
                    rootPath.joinTo(
                        types.EntityType.RelationshipDefinition.Webhooks,
                        true)
                ],
                () => [ entity ],
                [
                    types
                ]);

        if (isLoading)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <BaseLayout>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <WebhookEditor
                                    webhook={entity}
                                    commitContext={commitContext}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Card>
                            <CardInset>
                                <CardHeader>
                                    <LocalizedText
                                        code="Webhook.NotYetSucceededEvents"
                                        value="Nog niet geslaagde webhook events"
                                    />
                                </CardHeader>
                            </CardInset>
                            <Divider />
                            <WebhookEventsTable
                                webhookId={entity.uuid}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </BaseLayout>;
        }
    };

export default observer(Webhook);
