import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { ApiControllerStore } from '../../../../../../../../@Api/Controller/ApiControllerStore';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';

export function renderEmail(emailEntity: Entity)
{
    return loadModuleDirectly(ApiControllerStore)
        .entityBespokeEmailController
        .renderEmail(emailEntity.id);
}

export function renderConceptEmail(subject: string, content: any, templateEntity: Entity, relationshipEntity: Entity, contactEntity: Entity)
{
    return loadModuleDirectly(ApiControllerStore)
        .entityBespokeEmailController
        .renderConceptEmail(subject, content, templateEntity.id, relationshipEntity.uuid, contactEntity?.uuid);
}