import { Selection } from '../Model/Selection';
import { RootNode } from '../../Model/Implementation/RootNode';

export function getRootNodeFromSelection(selection: Selection): RootNode
{
    for (let node of selection.nodes)
    {
        if (node instanceof RootNode)
        {
            return node;
        }
    }

    throw new Error('Selection has no root node');
}