import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { DateTimeEditorStore } from './DateTimeEditorStore';
import DateEditor from '../../../../../../@Future/Component/Generic/Input/DateEditor/DateEditor';
import { default as InternalDateTimeEditor } from '../../../../../../@Future/Component/Generic/Input/DateTimeEditor/DateTimeEditor';

@observer
export class DateTimeEditor extends DataObjectBespokeEditor<DateTimeEditorStore>
{
    componentDidUpdate()
    {
        let domNode = ReactDOM.findDOMNode(this) as Element;

        if (domNode)
        {
            let rect = domNode.getBoundingClientRect();
            let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

            if (rect.top + 350 > height)
            {
                this.store.setRenderDirectionUp();
            }
            else
            {
                this.store.setRenderDirectionDown();
            }
        }
    }

    renderComponent(store: DataObjectEditorStore<DateTimeEditorStore>): any
    {
        if (store.bespokeStore.disableTime)
        {
            return <DateEditor
                value={store.dataObject.value}
                onChange={store.changeValue}
                fitContent={store.representation?.data?.fitContent}
                disableUnderline={!store.hasUnderline}
                placeholder={store.placeholder}
            />;
        }
        else
        {
            return <InternalDateTimeEditor
                value={store.dataObject.value}
                onChange={store.changeValue}
                fitContent={store.representation?.data?.fitContent}
                disableUnderline={!store.hasUnderline}
                placeholder={store.placeholder}
            />;
        }
    }
}
