import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Action from '../../../../../@Api/Automation/Function/Action/Action';
import CreateEntityAction from '../../../../../@Api/Automation/Function/Action/CreateEntityAction';
import Mapping from '../../../../../@Api/Automation/Mapping/Mapping';
import { AssistantCreateEntityAction, AssistantCreateEntityActionFieldMapping } from '../../Model/Automation/Action/AssistantCreateEntityAction';
import { findAssistantEntityTypeOrThrow } from '../findAssistantEntityTypeOrThrow';
import ValueFieldMapping from '../../../../../@Api/Automation/Mapping/Field/ValueFieldMapping';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { findAssistantFieldPath } from '../findAssistantFieldPath';
import FieldInput from '../../../Multiplayer/Model/Input/FieldInput';
import RelationshipInput from '../../../Multiplayer/Model/Input/RelationshipInput';
import { findAssistantComputation } from '../Computation/findAssistantComputation';

export function findAssistantCreateEntityAction(
    action: AssistantCreateEntityAction,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Action<any, any>
{
    const entityType = findAssistantEntityTypeOrThrow(action.entityType);
    const parameter = Mapping.buildParameter(entityType);

    return new CreateEntityAction(
        new Mapping(
            entityType,
            parameter,
            action.fieldMappings.map(
                fieldMapping =>
                    findFieldMapping(
                        entityType,
                        rootParameter,
                        parameters,
                        fieldMapping
                    )
            ),
            []
        )
    );
}

function findFieldMapping(
    entityType: EntityType,
    rootParameter: Parameter<EntityValueType>,
    parameters: ParameterDictionary,
    fieldMapping: AssistantCreateEntityActionFieldMapping
)
{
    const fieldPath =
        findAssistantFieldPath(
            entityType.path(),
            fieldMapping.field
        );
    const input =
        fieldPath.field
            ? new FieldInput(
                entityType,
                fieldPath.field
            )
            : new RelationshipInput(
                entityType,
                fieldPath.relationshipDefinition,
                fieldPath.isParentRelationship
            );
    const value =
        findAssistantComputation(
            fieldMapping.value,
            parameters,
            rootParameter
        );

    return new ValueFieldMapping(
        input,
        value
    );
}
