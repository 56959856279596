import React, { useCallback, useContext, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ResourceSelection from '../../Model/ResourceSelection';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import useTransactionalEntity from '../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';
import EntityInput from '../../../Input/Input';
import TimeDurationPicker from '../../../../../../@Future/Component/Generic/Input/TimeDurationPicker/TimeDurationPicker';
import useTimeDurationFromPeriod from '../../../../../../@Future/Component/Generic/Input/TimeDurationPicker/Api/useTimeDurationFromPeriod';
import EditIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import constructEntity from '../../../../../../@Api/Entity/constructEntity';
import SaveIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveIconButton/SaveIconButton';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import styles from './TimesheetItem.module.scss';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import NoteIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/NoteIconButton/NoteIconButton';
import useTypes from '../../../Type/Api/useTypes';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import useToggle from '../../../../../../@Util/Toggle/useToggle';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import TimeRegistrationProductLinesEditor from './TimeRegistrationProductLinesEditor/TimeRegistrationProductLinesEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as GenericInput } from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import Checkbox from '../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import MenuButton from '../../../Item/MenuButton/MenuButton';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import EntityLayout from '../../../Layout/EntityLayout';
import TimeRegistrationMileageRegistrationsEditor from './TimeRegistrationMileageRegistrationsEditor/TimeRegistrationMileageRegistrationsEditor';

export interface TimesheetItemProps
{
    resourceSelection: ResourceSelection;
    entity: Entity;
    constructorFieldPaths: EntityFieldPath[];
}

const TimesheetItem: React.FC<TimesheetItemProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const entity = useTransactionalEntity(props.entity);
        const startDate =
            useComputed(
                () =>
                    props.resourceSelection.startDateFieldPath.getObjectValue(entity),
                [
                    props.resourceSelection,
                    entity
                ]);
        const endDate =
            useComputed(
                () =>
                    props.resourceSelection.endDateFieldPath.getObjectValue(entity),
                [
                    props.resourceSelection,
                    entity
                ]);

        const save =
            useCallback(
                () =>
                    entity.checkAndDoCommit(),
                [
                    entity
                ]);

        const edit =
            useCallback(
                () =>
                    constructEntity(entity.entityType, undefined, entity),
                [
                    entity
                ]);

        const [ hours, minutes, onChange ] =
            useTimeDurationFromPeriod(
                startDate,
                endDate,
                newEndDate =>
                {
                    props.resourceSelection.endDateFieldPath
                        .setValue(entity, newEndDate);

                    if (!entity.isNew())
                    {
                        save();
                    }
                },
                [
                    props.resourceSelection,
                    entity,
                    save
                ]);

        const noteField =
            useComputed(
                () =>
                {
                    if (entity.entityType.isA(types.TimeRegistration.Type))
                    {
                        return types.TimeRegistration.Field.Description;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity,
                    types
                ]);

        const note = useEntityValue(entity, noteField);
        const [ isNoteOpen, toggleNote ] = useToggle(note !== undefined);

        const productLinespath =
            useComputed(
                () =>
                {
                    if (entity.entityType.isA(types.TimeRegistration.Type))
                    {
                        return EntityPath.fromEntity(entity)
                            .joinTo(
                                types.TimeRegistration.RelationshipDefinition.ProductLines,
                                false);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity,
                    types
                ]);

        const [ isSpecificationLayoutInEditMode, setSpecificationLayoutEditMode ] = useState(false);
        const closeSpecificationLayout =
            useCallback(
                () =>
                    setSpecificationLayoutEditMode(false),
                [
                    setSpecificationLayoutEditMode
                ]);
        const [ specificationLayout, setSpecificationLayout ] =
            useSetting(
                SettingSource.Organization,
                Setting.Metadata.TimeRegistration.SpecificationLayout,
                true);

        const [ isProductLinesOpen, toggleProductLines ] = useToggle(false);
        const [ isMileageRegistrationsOpen, toggleMileageRegistrations ] = useToggle(false);

        const doAutoCommit = !entity.isNew();

        return <>
            <TableRow
                className={classNames(entity.isNew() && styles.new, (isNoteOpen || isProductLinesOpen) && styles.open)}
            >
                <TableCell>
                    <TimeDurationPicker
                        onChange={onChange}
                        hours={hours}
                        minutes={minutes}
                        autoFocus={entity.isNew()}
                    />
                </TableCell>
                {
                    props.constructorFieldPaths.map(
                        constructorFieldPath =>
                            <TableCell
                                key={constructorFieldPath.id}
                            >
                                <EntityInput
                                    entity={entity}
                                    field={constructorFieldPath}
                                    labelPosition="none"
                                    doAutocommit={doAutoCommit}
                                    compact
                                />
                            </TableCell>)
                }
                <TableCell
                    align="right"
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        alignment="center"
                    >
                        {
                            noteField &&
                                <ViewGroupItem>
                                    <NoteIconButton
                                        onClick={toggleNote}
                                        color={isNoteOpen ? primaryColor : textSecondaryColor}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            productLinespath &&
                                <ViewGroupItem>
                                    <IconButton
                                        onClick={toggleProductLines}
                                        color={isProductLinesOpen ? primaryColor : textSecondaryColor}
                                        icon="attach_money"
                                        tooltip={types.TimeRegistration.RelationshipDefinition.ProductLines.getName(false)}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            types.TimeRegistration.RelationshipDefinition.MileageRegistrations &&
                            !props.entity.isNew() &&
                            <IconButton
                                onClick={toggleMileageRegistrations}
                                color={isMileageRegistrationsOpen ? primaryColor : textSecondaryColor}
                                icon="directions_car"
                                tooltip={types.TimeRegistration.RelationshipDefinition.MileageRegistrations.getName(false)}
                            />
                        }
                        <ViewGroupItem>
                            {
                                entity.isNew()
                                    ?
                                        <SaveIconButton
                                            onClick={save}
                                        />
                                    :
                                        <EditIconButton
                                            onClick={edit}
                                        />
                            }
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <MenuButton
                                entity={entity}
                            >
                                {
                                    currentUserStore.isAdministrator &&
                                        <GenericInput
                                            labelPosition="right"
                                            label={
                                                <LocalizedText
                                                    code="ProductLine.EditSpecificationLayout"
                                                    value="Specificatie layout wijzigen"
                                                />
                                            }
                                        >
                                            <Checkbox
                                                checked={isSpecificationLayoutInEditMode}
                                                onToggle={setSpecificationLayoutEditMode}
                                            />
                                        </GenericInput>
                                }
                            </MenuButton>
                        </ViewGroupItem>
                    </ViewGroup>
                </TableCell>
            </TableRow>
            {
                isNoteOpen &&
                    <TableRow
                        className={classNames(entity.isNew() && styles.new)}
                    >
                        <TableCell
                            colSpan={props.constructorFieldPaths.length + 2}
                        >
                            <EntityInput
                                entity={entity}
                                field={noteField}
                                labelPosition="none"
                                doAutocommit={doAutoCommit}
                                autoFocus={note === undefined}
                            />
                        </TableCell>
                    </TableRow>
            }
            {
                isProductLinesOpen &&
                    <TableRow
                        className={classNames(entity.isNew() && styles.new)}
                    >
                        <TableCell
                            colSpan={props.constructorFieldPaths.length + 2}
                        >
                            <TimeRegistrationProductLinesEditor
                                entity={entity}
                                noScroll
                            />
                        </TableCell>
                    </TableRow>
            }
            {
                isMileageRegistrationsOpen &&
                    <TableRow>
                        <TableCell
                            colSpan={props.constructorFieldPaths.length + 2}
                        >
                            <TimeRegistrationMileageRegistrationsEditor
                                entity={props.entity}
                                noScroll
                            />
                        </TableCell>
                    </TableRow>
            }
            {
                (isSpecificationLayoutInEditMode || specificationLayout) &&
                    <TableRow
                        className={classNames(entity.isNew() && styles.new)}
                    >
                        <TableCell
                            colSpan={props.constructorFieldPaths.length + 2}
                        >
                            <EntityLayout
                                entity={entity}
                                isInEditMode={isSpecificationLayoutInEditMode}
                                onCloseEditMode={closeSpecificationLayout}
                                layout={specificationLayout}
                                onChangeLayout={setSpecificationLayout}
                            />
                        </TableCell>
                    </TableRow>
            }
        </>
    };

export default observer(TimesheetItem);
