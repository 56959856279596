import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { observer } from 'mobx-react';
import { ToolbarButtonStore } from './ToolbarButtonStore';
import Button from '../../../../Button/Button';

@observer
export class ToolbarButton extends BaseComponent<ToolbarButtonStore>
{
    renderComponent(store: ToolbarButtonStore)
    {
        return <Button
            key={store.id}
            store={store.buttonStore}
        />;
    }
}
