import PeriodSpecification from './PeriodSpecification';
import moment from 'moment';

export const dayResolution: PeriodSpecification = {
    headerCellDuration: moment.duration(1, 'day'),
    headerCellFormat: 'ddd DD-MM',
    secondaryHeaderCellDuration: moment.duration(1, 'hour'),
    secondaryHeaderCellFormat: 'H:mm',
    dataCellDuration: moment.duration(15, 'minute'),
    activeToInactivePeriodRatio: 4
};

export const weekResolution: PeriodSpecification = {
    headerCellDuration: moment.duration(1, 'day'),
    headerCellFormat: 'ddd DD-MM',
    secondaryHeaderCellDuration: moment.duration(6, 'hour'),
    secondaryHeaderCellFormat: 'H:mm',
    dataCellDuration: moment.duration(30, 'minute'),
    activeToInactivePeriodRatio: 3
};

export const twoWeekResolution: PeriodSpecification = {
    headerCellDuration: moment.duration(1, 'day'),
    headerCellFormat: 'DD-MM',
    secondaryHeaderCellDuration: moment.duration(1, 'day'),
    secondaryHeaderCellFormat: 'ddd',
    dataCellDuration: moment.duration(6, 'hour'),
    activeToInactivePeriodRatio: 1
};

export const monthResolution: PeriodSpecification = {
    headerCellDuration: moment.duration(1, 'month'),
    headerCellFormat: 'MMMM',
    secondaryHeaderCellDuration: moment.duration(1, 'day'),
    secondaryHeaderCellFormat: 'dd-D',
    dataCellDuration: moment.duration(6, 'hour'),
    activeToInactivePeriodRatio: 1
};

export const quarterResolution: PeriodSpecification = {
    headerCellDuration: moment.duration(1, 'week'),
    headerCellFormat: 'w',
    secondaryHeaderCellDuration: moment.duration(1, 'week'),
    secondaryHeaderCellFormat: 'DD-MM',
    dataCellDuration: moment.duration(1, 'day'),
    activeToInactivePeriodRatio: 1
};

export const yearResolution: PeriodSpecification = {
    headerCellDuration: moment.duration(1, 'month'),
    headerCellFormat: 'MMM',
    secondaryHeaderCellDuration: moment.duration(1, 'week'),
    secondaryHeaderCellFormat: 'w',
    dataCellDuration: moment.duration(1, 'week'),
    activeToInactivePeriodRatio: 1
};

