import React from 'react';
import { observer } from 'mobx-react-lite';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import CompositeActionEditor, { CompositeActionEditorProps } from './Type/Composite/CompositeActionEditor';
import RouteActionEditor, { RouteActionEditorProps } from './Type/Route/RouteActionEditor';
import ActionConstructor from './Constructor/ActionConstructor';
import LayoutAction from '../../../../@Api/Layout/Action/LayoutAction';
import RouteAction from '../../../../@Api/Layout/Action/Type/RouteAction';
import CompositeAction from '../../../../@Api/Layout/Action/Type/CompositeAction';
import PortalActionInvocationAction from '../../../../@Api/Layout/Action/Type/PortalActionInvocationAction';
import PortalActionInvocationActionEditor, { PortalActionInvocationActionEditorProps } from './Type/PortalActionInvocationActionEditor/PortalActionInvocationActionEditor';
import SetFormParameterActionEditor, { SetFormParameterActionEditorProps } from './Type/SetFormParameter/SetFormParameterActionEditor';
import SetFormParameterAction from '../../../../@Api/Layout/Action/Type/SetFormParameterAction';
import ActionTemplateInvocationAction from '../../../../@Api/Layout/Action/Type/ActionTemplateInvocationAction';
import ActionTemplateInvocationActionEditor, { ActionTemplateInvocationActionEditorProps } from './Type/ActionTemplateInvocation/ActionTemplateInvocationActionEditor';
import DownloadFileAction from '../../../../@Api/Layout/Action/Type/DownloadFileAction';
import DownloadFileActionEditor, { DownloadFileActionEditorProps } from './Type/DownloadFile/DownloadFileActionEditor';
import RefreshAction from '../../../../@Api/Layout/Action/Type/RefreshAction';
import RefreshActionEditor, { RefreshActionEditorProps } from './Type/Refresh/RefreshActionEditor';

export interface LayoutActionEditorProps<T extends LayoutAction = LayoutAction>
{
    context: FunctionContext;
    action?: T;
    onChange?: (action: LayoutAction) => void;
}

const LayoutActionEditor: React.FC<LayoutActionEditorProps> =
    props =>
    {
        if (!props.action && props.onChange)
        {
            return <ActionConstructor
                context={props.context}
                onConstruct={props.onChange}
                mode="Button"
            />;
        }
        else if (props.action instanceof CompositeAction)
        {
            return <CompositeActionEditor
                {...props as CompositeActionEditorProps}
            />;
        }
        else if (props.action instanceof RouteAction)
        {
            return <RouteActionEditor
                {...props as RouteActionEditorProps}
            />;
        }
        else if (props.action instanceof RefreshAction)
        {
            return <RefreshActionEditor
                {...props as RefreshActionEditorProps}
            />;
        }
        else if (props.action instanceof SetFormParameterAction)
        {
            return <SetFormParameterActionEditor
                {...props as SetFormParameterActionEditorProps}
            />;
        }
        else if (props.action instanceof DownloadFileAction)
        {
            return <DownloadFileActionEditor
                {...props as DownloadFileActionEditorProps}
            />;
        }
        else if (props.action instanceof ActionTemplateInvocationAction)
        {
            return <ActionTemplateInvocationActionEditor
                {...props as ActionTemplateInvocationActionEditorProps}
            />;
        }
        else if (props.action instanceof PortalActionInvocationAction)
        {
            return <PortalActionInvocationActionEditor
                {...props as PortalActionInvocationActionEditorProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(LayoutActionEditor);
