import React from 'react';

export interface CenteredProps
{
    horizontal?: boolean;
    vertical?: boolean;
}

const Centered: React.FC<CenteredProps> =
    props =>
    {
        if (props.horizontal && !props.vertical)
        {
            return <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {props.children}
            </div>;
        }
        else if (props.vertical || props.horizontal)
        {
            return <div
                style={{
                    position: 'absolute',
                    left: props.horizontal ? 0 : undefined,
                    right: props.horizontal ? 0 : undefined,
                    top: props.vertical ? 0 : undefined,
                    bottom: props.vertical ? 0 : undefined,
                    display: 'flex',
                    alignItems:
                        props.vertical
                            ?
                                'center'
                            :
                                undefined,
                    justifyContent:
                        props.horizontal
                            ?
                                'center'
                            :
                                undefined
                }}
            >
                {props.children}
            </div>;
        }
        else
        {
            return <>{props.children}</>;
        }
    };

export default Centered;
