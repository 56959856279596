import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import ValueType from '../../../Automation/Value/Type/ValueType';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import localizeText from '../../../Localization/localizeText';
import LayoutContext from '../../LayoutContext';

export default class RefreshAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        window.location.reload();

        return EmptyValue.instance;
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return localizeText(
            'LayoutAction.Refresh',
            'Verversen'
        );
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    validate(): Validation[]
    {
        return [];
    }

    toDescriptor()
    {
        return {
            type: 'Refresh',
        };
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: LayoutDependencyContext
    )
    {
        return new RefreshAction();
    }

    // ----------------------- Private logic ------------------------
}
