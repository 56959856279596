import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { Aggregate } from '../../../Model/Aggregate';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { ComputationView } from './ComputationView';
import { ComputationEditor } from './ComputationEditor';
import { ComputationSpecification } from './ComputationSpecification';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { ComputationEditorStore } from './ComputationEditorStore';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { ComputationTypeStore } from '../../../../Computation/ComputationTypeStore';
import { Computation } from '../../../../Computation/Type/Computation';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { toJS } from 'mobx';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class ComputationType implements DataObjectType<Computation>
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Computation';
    }

    name()
    {
        return localizeText('DataObject.Type.Computation', 'Berekening');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return ComputationView;
    }

    editor(): DataObjectEditorType
    {
        return ComputationEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new ComputationEditorStore(editorStore);
    }

    hasLargeEditor(specification: DataObjectSpecification): boolean
    {
        return true;
    }

    specification(): DataObjectSpecificationType
    {
        return ComputationSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: Computation): boolean
    {
        return false;
    }

    getDataFromValue(value: Computation, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.complex = this.computationTypeStore.toSpecification(value);

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): Computation
    {
        if (data.complex)
        {
            return this.computationTypeStore.fromSpecification(data.complex);
        }
        else
        {
            return null;
        }
    }

    valueId(value: Computation): string
    {
        return JSON.stringify(toJS(this.computationTypeStore.toSpecification(value)));
    }

    getString(value: Computation,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return this.localizationStore.translate('Computation.Calculation');
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
