import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import FormInterfaceEditor from './Interface/FormInterfaceEditor';
import MenuButton from '../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import TextEditor from '../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import { GlobalEnvironment } from '../../../../../@Global/GlobalEnvironment';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { FeedbackStore } from '../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import styles from './FormEditor.module.scss';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import localizeText from '../../../../../@Api/Localization/localizeText';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';

const copy = require('copy-to-clipboard');

export interface FormEditorProps
{
    entityType: EntityType;
}

const FormEditor: React.FC<FormEditorProps> =
    props =>
    {
        const [ form, setForm ] = useState('formLayout');
        const formPageUrl =
            useMemo(
                () =>
                    `${GlobalEnvironment.APP_ENDPOINT}/form/${props.entityType.entity.uuid}`,
                [
                    props.entityType
                ]);
        const formEmbedUrl =
            useMemo(
                () =>
                    `${formPageUrl}/embedded`,
                [
                    formPageUrl
                ]);

        const copyFormPageUrl =
            useCallback(
                () =>
                {
                    copy(formPageUrl);

                    loadModuleDirectly(FeedbackStore)
                        .enqueueSnackbar(
                            localizeText(
                                'Generic.CopiedToClipboard',
                                'Gekopieerd naar clipboard'));
                },
                [
                    formPageUrl
                ]);

        const copyFormEmbedUrl =
            useCallback(
                () =>
                {
                    copy(formEmbedUrl);

                    loadModuleDirectly(FeedbackStore)
                        .enqueueSnackbar(
                            localizeText(
                                'Generic.CopiedToClipboard',
                                'Gekopieerd naar clipboard'));
                },
                [
                    formEmbedUrl
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <TabBar
                    value={form}
                    appendix={
                        <MenuButton
                            icon="share"
                        >
                            <CardInset>
                                <div
                                    className={styles.urlContainer}
                                >
                                    <InputGroup>
                                        <Input
                                            labelPosition="top"
                                            label={
                                                <LocalizedText
                                                    code="Form.LinkToForm.Page"
                                                    value="Link naar formulier (pagina)"
                                                />
                                            }
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <TextEditor
                                                        value={formPageUrl}
                                                        onChange={() => {}}
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <IconButton
                                                        icon="content_copy"
                                                        onClick={copyFormPageUrl}
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Generic.CopyToClipboard"
                                                                value="Kopieren naar klembord"
                                                            />
                                                        }
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </Input>
                                        <Input
                                            labelPosition="top"
                                            label={
                                                <LocalizedText
                                                    code="Form.LinkToForm.Iframe"
                                                    value="Link naar formulier (iframe)"
                                                />
                                            }
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <TextEditor
                                                        value={formEmbedUrl}
                                                        onChange={() => {}}
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <IconButton
                                                        icon="content_copy"
                                                        onClick={copyFormEmbedUrl}
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Generic.CopyToClipboard"
                                                                value="Kopieren naar klembord"
                                                            />
                                                        }
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </Input>
                                    </InputGroup>
                                </div>
                            </CardInset>
                        </MenuButton>
                    }
                >
                    <Tab
                        value="formLayout"
                        onClick={setForm}
                    >
                        <LocalizedText
                            code="Generic.Form"
                            value="Formulier"
                        />
                    </Tab>
                    <Tab
                        value="submittedFormLayout"
                        onClick={setForm}
                    >
                        <LocalizedText
                            code="Generic.SendConfirmation"
                            value="Verzendbevestiging"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            <ViewGroupItem>
                <FormInterfaceEditor
                    key={form}
                    entityType={props.entityType}
                    metadataKey={form}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(FormEditor);
