import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../../Entity/Type/Api/getTypes';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import cronstrue from 'cronstrue/i18n';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { Localizer } from '../../../../../../../@Service/Localization/Localizer';
import { getTriggerIcon } from './getTriggerIcon';
import CronTrigger from '../../../../../../../@Api/Automation/Trigger/CronTrigger';
import getMutationTriggerFromDescriptor from '../../../../../../../@Api/Automation/Trigger/Api/getMutationTriggerFromDescriptor';

async function getNameFromTriggerDescriptor(
    triggerDescriptor: any
): Promise<string>
{
    const dependencyContext = new AutomationDependencyContext(new ParameterDictionary([]));

    if (triggerDescriptor.type === 'Cron')
    {
        const cronTrigger = await CronTrigger.fromDescriptor(
            triggerDescriptor,
            dependencyContext
        );

        const localizer = loadModuleDirectly(Localizer)

        return localizeText( 'Periodic', 'Periodiek') +
            ': ' +
            cronstrue.toString(
                cronTrigger.cron,
                {
                    locale: localizer.languageCode
                }
            );
    }
    else
    {
        const mutationTrigger = await getMutationTriggerFromDescriptor(
                triggerDescriptor,
                dependencyContext
            );

        return mutationTrigger.getName();
    }
}

export interface TriggerInfo
{
    icon: string;
    name: string;
}

export async function getTriggerInfo(
    automationEntity: Entity
): Promise<TriggerInfo>
{
    const types = getTypes();

    const triggerDescriptor =
        automationEntity
            .getObjectValueByField(
                types.Automation.Field.Specification
            )?.trigger;

    if (!triggerDescriptor)
    {
        return undefined;
    }

    const triggerName = await getNameFromTriggerDescriptor(triggerDescriptor);
    const triggerIcon = getTriggerIcon(triggerDescriptor.type);

    return {
        icon: triggerIcon,
        name: triggerName
    };
}