import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { CommitBuilder } from '../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import useEntityValue from '../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import localizeText from '../../../../@Api/Localization/localizeText';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { useBaseCurrency } from '../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/useBaseCurrency';
import CurrencySelector from '../../../../@Future/Component/Generic/Input/CurrencySelector/CurrencySelector';
import { FeedbackStore } from '../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { useCurrencies } from '../../Configuration/Page/Settings/OrganizationSettings/CurrencySettings/Api/useCurrencies';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Input from '../../../../@Future/Component/Generic/Input/Input/Input';

export interface CurrencyEditorProps
{
    entity: Entity;
    field: EntityField;
    disabled?: boolean;
    priceListRelationshipDefinition?: EntityRelationshipDefinition;
    commitContext?: CommitContext;
    withLabel?: boolean;
    withCardInset?: boolean;
}

const CurrencyEditor: React.FC<CurrencyEditorProps> =
    ({
         entity,
         field,
         disabled,
         priceListRelationshipDefinition,
         commitContext,
         withLabel,
         withCardInset,
     }) =>
    {
        const baseCurrency = useBaseCurrency();
        const currencies = useCurrencies();
        const currency: string = useEntityValue<string>(
            entity,
            field,
            baseCurrency.code
        );

        const saveCurrency =
            useCallback(
                (currency: string) =>
                {
                    const priceList = priceListRelationshipDefinition
                        ? entity.getRelatedEntityByDefinition(
                            false,
                            priceListRelationshipDefinition,
                            commitContext
                        )
                        : undefined;

                    const commitBuilder =
                        new CommitBuilder(commitContext)
                            .setObjectValueInEntity(
                                entity,
                                field,
                                baseCurrency.code === currency
                                    ? undefined
                                    : currency
                            );
                    if (priceListRelationshipDefinition)
                    {
                        commitBuilder
                            .relateEntityTo(
                                entity,
                                false,
                                priceListRelationshipDefinition,
                                undefined
                            );
                    }

                    commitBuilder.commit().then(
                        () =>
                            priceList !== undefined
                                ? loadModuleDirectly(FeedbackStore)
                                    .enqueueSnackbar(
                                        localizeText(
                                            'Activity.PriceList.Deleted',
                                            'Prijslijst ${priceList} was verwijderd uit de ${activity}.',
                                            {
                                                priceList: priceList.getName(),
                                                activity: entity.entityType.getName()
                                            }),
                                        {
                                            variant: 'warning',
                                            autoHideDuration: 3000
                                        })
                                : undefined
                    );
                },
                [
                    entity,
                    field,
                    priceListRelationshipDefinition,
                    commitContext,
                ]
            );

        const onCurrencyChanged =
            useCallback(
                (currency: string) =>
                {
                    if (!entity.isNew())
                    {
                        saveCurrency(currency);
                    }
                    else
                    {
                        setValueByFieldInEntity(
                            entity,
                            field,
                            baseCurrency.code === currency
                                ? undefined
                                : currency,
                            commitContext
                        );

                        if (commitContext?.getOptions().allowAutoCommit)
                        {
                            return commitContext.commit();
                        }
                    }
                },
                [
                    saveCurrency,
                    baseCurrency,
                    entity,
                    field,
                    priceListRelationshipDefinition,
                    commitContext,
                ]
            );

        const currencySelector =
            useMemo(
                () =>
                    <CurrencySelector
                        currencies={currencies}
                        value={currency}
                        onChange={onCurrencyChanged}
                        disabled={disabled}
                    />,
                [
                    currencies,
                    currency,
                    onCurrencyChanged,
                    disabled
                ]
            );

        const currencySelectorWithLabel =
            useMemo(
                () =>
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.Currency"
                                value="Valuta"
                            />
                        }
                        labelPosition="left"
                    >
                        {currencySelector}
                    </Input>,
                [
                    currencySelector
                ]
            );

        if (currencies?.length < 2)
        {
            return  null;
        }

        if (withLabel)
        {
            if (withCardInset)
            {
                return <CardInset>
                    {currencySelectorWithLabel}
                </CardInset>;
            }
            else
            {
                return currencySelectorWithLabel;
            }
        }
        else
        {
            return currencySelector;
        }
    };

export default observer(CurrencyEditor);