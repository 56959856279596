import { DependencyList, useEffect, useMemo } from 'react';
import { CommitContext } from '../CommitContext';
import { CommitContextOptions } from '../Model/CommitContextOptions';
import { CommitContextImpl } from '../CommitContextImpl';

export function useNewCommitContext(
    existingContext?: CommitContext,
    options?: Partial<CommitContextOptions>,
    deps: DependencyList = []
)
{
    const memoizedOptions = useMemo(() => options, deps);

    const commitContext =
        useMemo(
            () =>
                existingContext ?? new CommitContextImpl(memoizedOptions),
            [
                existingContext,
                memoizedOptions,
            ]
        );

    useEffect(
        () =>
        {
            if (existingContext !== commitContext)
            {
                return () =>
                    commitContext.dispose();
            }
        },
        [
            existingContext,
            commitContext,
        ]
    );

    return commitContext;
}
