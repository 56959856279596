import Widget from './Widget';
import { observable } from 'mobx';
import fromDescriptor from './Util/fromDescriptor';

export default class Column
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable widgets: Widget[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                widgets: Widget[])
    {
        this.id = id;
        this.widgets = widgets;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new Column(
            descriptor.id,
            descriptor.widgets
                .map(
                    widget =>
                        fromDescriptor(widget)));
    }

    toDescriptor()
    {
        return {
            id: this.id,
            widgets:
                this.widgets.map(
                    widget =>
                        widget.toDescriptor())
        };
    }

    // ----------------------- Private logic ------------------------
}
