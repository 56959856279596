import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Table, TableRow } from '@material-ui/core';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import TableBody from '@material-ui/core/TableBody';
import useTypes from '../../Type/Api/useTypes';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { default as ViewModel } from '../Model/View';
import FilterContext from '../../Dataset/Segment/FilterContext/FilterContext';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import usePaginatedSelection from '../Api/usePaginatedSelection';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';
import HoverCardMiddle from '../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import { useGoogleMapsPinsFromPagedResult } from '../Api/useGoogleMapsPinsFromPagedResult';
import { GoogleMap } from '../../../../Generic/GoogleMaps/GoogleMap';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import DividedList from '../../List/V2/Divided/DividedList';
import { makeStyles } from '@material-ui/styles';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import { MapViewItem } from './MapViewItem';
import { getMapViewDivId } from '../Api/getMapViewDivId';
import { openEntity } from '../../@Util/openEntity';

export type BaseListSelectionType = 'none' | 'all';

const useStyles =
    makeStyles({
        sidebar: {
            height: '700px',
            overflowY: 'scroll !important' as any,
            overflowX: 'hidden !important' as any,
        }
    });

export interface ListSelection
{
    base: BaseListSelectionType;
    mutations: string[];
    size: number;
}

export interface MapViewProps
{
    view: ViewModel;
    hideCard?: boolean;
    selectable?: boolean;
    onSelectionChange?: (selection: ListSelection) => void;
    defaultSelectionType?: BaseListSelectionType;
    selection?: ListSelection;
    readonly?: boolean;
    disableSelection?: (entity: Entity) => boolean;
    onClickMarker?: (entity: Entity) => void;
    onOpen?: (entity: Entity) => void;
    searchQuery?: string;
    saveViewState?: boolean;
}

const MapView: React.FC<MapViewProps> =
    props =>
    {
        const types = useTypes();
        const list = props.view.specification.list;
        const filter = useContext(FilterContext);
        const classes = useStyles();
        const [selectedEntity, setSelectedEntity] = useState<Entity>(undefined);
        const [ mapBounds, setMapBounds ] = useState<google.maps.LatLngBounds>(undefined);
        const [ loadingAll, setLoadingAll ] = useState<boolean>(false);

        const columnFilters =
            useComputed(
                () =>
                    list.columns
                        .filter(
                            column =>
                                column.filter !== undefined)
                        .map(
                            column =>
                                column.filter),
                [
                    list
                ]);

        const [ pages, hasMore, loadMore, isLoading ] =
            usePaginatedSelection(
                props.view.entityType,
                builder =>
                {
                    list.ordering.forEach(
                        ordering =>
                            builder.orderBy(
                                ordering.column.fieldPath,
                                ordering.isAscending)
                    );

                    if (filter && filter.isValid())
                    {
                        builder.where(
                            cb =>
                                cb.filter(
                                    filter,
                                    {
                                        parameter: props.view.parameter,
                                    }
                                )
                        );
                    }
                    else if (props.view.filter && props.view.filter.isValid())
                    {
                        builder
                            .where(
                                cb =>
                                    cb.filter(
                                        props.view.filter,
                                        {
                                            parameter: props.view.parameter,
                                        }
                                    )
                            );
                    }

                    for (const filter of columnFilters)
                    {
                        builder.where(
                            cb =>
                                cb.filter(
                                    filter,
                                    {
                                        parameter: props.view.parameter,
                                    }
                                )
                        );
                    }
                },
                [
                    list,
                    props.view,
                    types,
                    filter,
                    columnFilters
                ],
                200
            );

        const [ googleMapsLocations, isLoadingPins ] =
            useGoogleMapsPinsFromPagedResult(pages);

        const visibleEntities =
            useComputed(
                () =>
                     googleMapsLocations
                        .sort(createStringComparator(loc => loc.label))
                        .filter(
                            loc =>
                                loc === undefined
                                || loc.lat === undefined
                                || loc.lng === undefined
                                || (mapBounds && mapBounds.contains({lat: loc.lat, lng: loc.lng}))
                        )
                        .map(loc => loc.entity),

            [
                googleMapsLocations,
                mapBounds
            ]
        );

        const pins =
            useComputed(
                () => googleMapsLocations
                    .filter(
                        location =>
                            location.lng && location.lat
                    ),
                [
                    googleMapsLocations
                ]
            );

        const onClickMarker =
            useCallback(
                (location) =>
                {
                    setSelectedEntity(location.entity);
                    const section = document.querySelector( '#' + getMapViewDivId(location.entity));
                    if (section)
                    {
                        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                    }
                },
                [
                    setSelectedEntity
                ]
            );

        const onClickEntity =
            useCallback(
                (entity: Entity) =>
                    openEntity(entity)
                         .then(),
                []
            );

        const onBoundsChange =
            useCallback(
                (bounds) =>
                {
                    try
                    {
                        const b =
                            new google.maps.LatLngBounds({
                                north: bounds[0],
                                west: bounds[1],
                                south: bounds[2],
                                east: bounds[3]
                            });
                        setMapBounds(b);
                    }
                    catch (e)
                    {
                        // catch a "google is not defined exception"
                    }
                },
                [
                    setMapBounds
                ]
            );

        const body =
            useComputed(
                () =>
                {
                    if (pins?.length > 0)
                    {
                        return <GoogleMap
                            height={700}
                            locations={pins}
                            zoomLevel={10}
                            viewProperties={
                                {
                                    zoomControl: true,
                                    streetviewControl: true
                                }
                            }
                            useClusterer
                            onClickMarker={onClickMarker}
                            onBoundsChange={onBoundsChange}
                        />;
                    }
                    else
                    {
                      return <LocalizedText
                            code="Mapsview.NoCoordinatesFound"
                            value="Geen coordinaten gevonden"
                        />
                    }
                },

                [
                    googleMapsLocations
                ]
            );

        const loadAll =
            useCallback(
                async () =>
                {
                    setLoadingAll(true);
                },
                [
                    setLoadingAll
                ]
            );

        useEffect(
            () => {
                if (loadingAll && !isLoading)
                {
                    loadMore().then()
                }
                if (loadingAll && !hasMore)
                {
                    setLoadingAll(false);
                }
            },
            [
                loadingAll,
                isLoading,
                loadMore,
                setLoadingAll,
                hasMore
            ]
        );

        const renderEntity =
            useCallback(
                (entity: Entity) =>
                {
                    const locationPin = googleMapsLocations.find(pin => equalsEntity(pin.entity, entity));

                    // Only needed for non-organization entities to add line with address
                    const summaryField =
                        locationPin?.address
                             ? {
                                    id: `location.${locationPin.address.uuid}`,
                                    icon: 'location_on',
                                    link: locationPin.googleMapsLink,
                                    title: types.Address.Functions.getAddressLine(locationPin.address),
                                    tooltip: '',
                                    value: undefined,
                                    entity: locationPin.address,
                                }
                             : undefined;

                    return <MapViewItem
                        entity={entity}
                        relationship={locationPin.addressProvider}
                        selectedEntity={selectedEntity}
                        summaryField={summaryField}
                        onClick={onClickEntity}
                    />
                },
                [
                    googleMapsLocations,
                    types,
                    selectedEntity,
                    onClickEntity
                ]
            );

        if (pages.length === 0 || isLoading || isLoadingPins || loadingAll)
        {
            return <CardInset>
                <Centered
                    horizontal
                >
                    <Loader />
                </Centered>
            </CardInset>
        }

        return <ViewGroup
            orientation="horizontal"
            spacing={25}
        >
            <ViewGroupItem
                ratio={3}
            >
                {
                    !isLoading && !isLoadingPins && !loadingAll &&
                    <Table>
                        <TableBody>
                            <TableRow>
                                {body}
                            </TableRow>
                        </TableBody>
                    </Table>
                }
                {
                    (pages.length === 0 || isLoading || isLoadingPins || loadingAll) &&
                    <CardInset>
                        <Centered
                            horizontal
                        >
                            <Loader />
                        </Centered>
                    </CardInset>
                }
            </ViewGroupItem>
            <ViewGroupItem
                ratio={1}
            >
                <Card
                    classes={{
                        root: classes.sidebar
                    }}
                >
                    {
                        !isLoading && !isLoadingPins && hasMore &&
                        <>
                            <HoverCardMiddle
                                onClick={loadAll}
                                disabled={isLoading}
                            >
                                <LocalizedText
                                    code="Generic.LoadAll"
                                    value="Alles laden"
                                />...
                            </HoverCardMiddle>
                            <Divider />
                        </>
                    }
                    <DividedList
                        entities={visibleEntities}
                        renderItem={renderEntity}
                    />
                    {
                        !isLoading && !isLoadingPins && hasMore &&
                        <>
                            <Divider />
                            <HoverCardMiddle
                                onClick={loadMore}
                                disabled={isLoading}
                            >
                                <LocalizedText
                                    code="Generic.LoadMore"
                                    value="Meer laden"
                                />...
                            </HoverCardMiddle>
                        </>
                    }
                </Card>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MapView);
