import Validation from '../../../Automation/Validation/Validation';
import Dependency from '../../../Automation/Parameter/Dependency';
import Value from '../../../Automation/Value/Value';
import LayoutAction from '../LayoutAction';
import EmptyValue from '../../../Automation/Value/EmptyValue';
import LayoutDependencyContext from '../../LayoutDependencyContext';
import { observable, runInAction } from 'mobx';
import Computation from '../../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../../Automation/Api/getComputationFromDescriptor';
import ValueType from '../../../Automation/Value/Type/ValueType';
import EmptyValueType from '../../../Automation/Value/Type/EmptyValueType';
import safelyApplyFunction from '../../../Automation/Api/safelyApplyFunction';
import Parameter from '../../../Automation/Parameter/Parameter';
import LayoutContext from '../../LayoutContext';

export default class SetFormParameterAction extends LayoutAction
{
    // ------------------------- Properties -------------------------

    @observable formId: string;
    @observable.ref parameter: Parameter<any>;
    @observable.ref value?: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(formId: string,
                parameter: Parameter<any>,
                value?: Computation<any, any>)
    {
        super();

        this.formId = formId;
        this.parameter = parameter;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async apply(context: LayoutContext): Promise<Value<any, any>>
    {
        const formContext = context.contextByFormId.get(this.formId);

        if (formContext)
        {
            const value = await safelyApplyFunction(this.value, context);

            runInAction(
                () =>
                    formContext.parameterAssignment
                        .setValue(
                            this.parameter,
                            value
                        )
            );
        }

        return EmptyValue.instance;
    }

    getReturnType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName(): string
    {
        return `${this.parameter.name} = ${this.value?.getName() || '...'}`;

        // return localizeText(
        //     'LayoutAction.SetFormParameter.SetParameterTo',
        //     '${parameter} = Navigeren naar ${route}',
        //     {
        //         route: this.route.getName()
        //     });
    }

    getDependencies(): Dependency[]
    {
        return this.value?.getDependencies() || [];
    }

    validate(): Validation[]
    {
        return this.value?.validate() || [];
    }

    toDescriptor()
    {
        return {
            type: 'SetFormParameter',
            formId: this.formId,
            parameterId: this.parameter.id,
            value: this.value?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new SetFormParameterAction(
            descriptor.formId,
            dependencyContext.parameterDictionary.getParameterById(descriptor.parameterId),
            descriptor.value
                ?
                    await getComputationFromDescriptor(
                        descriptor.value,
                        dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
