import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationFieldMapping from '../../../../Model/FieldMapping/Computation/ComputationFieldMapping';
import FileSpecificationContext from '../../../../Context/FileSpecificationContext';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import ComputationEditor from '../../../../../Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import Computation from '../../../../../../../../@Api/Automation/Function/Computation/Computation';
import { runInAction } from 'mobx';

export interface ComputationFieldMappingEditorProps
{
    fieldMapping: ComputationFieldMapping;
}

export const ComputationFieldMappingEditor: React.FC<ComputationFieldMappingEditorProps> =
    observer(
        ({
            fieldMapping,
         }) =>
        {
            const fileSpecification = useContext(FileSpecificationContext);
            const context =
                useMemo(
                    () =>
                        new FunctionContext(
                            fileSpecification.parameters
                        ),
                    []
                );
            const setComputation =
                useCallback(
                    (computation: Computation<any, any>) =>
                        runInAction(
                            () =>
                                fieldMapping.computation = computation
                        ),
                    [
                        fieldMapping,
                    ]
                );

            return <ComputationEditor
                context={context}
                value={fieldMapping.computation}
                onChange={setComputation}
            />;
        }
    );
