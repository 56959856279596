import ParameterAssignment from '../Parameter/ParameterAssignment';
import { AsyncResult } from '../../../@Util/Async/useAsyncResult';
import { initializeParameterAssignment } from './initializeParameterAssignment';
import Dependency from '../Parameter/Dependency';
import { useDisposableAsyncResult } from '../../../@Util/Async/useDisposableAsyncResult';

export function useInitializedParameterAssignment(
    parameterAssignment: ParameterAssignment,
    dependencies: Dependency[]
): AsyncResult<ParameterAssignment>
{
    return useDisposableAsyncResult(
        async () =>
        {
            const disposer =
                await initializeParameterAssignment(
                    parameterAssignment,
                    dependencies
                );

            return {
                value: parameterAssignment,
                disposer,
            };
        },
        [
            parameterAssignment,
            dependencies,
        ]
    );
}
