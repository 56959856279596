import { observable } from 'mobx';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { DataObject } from '../../../DataObject/Model/DataObject';

export type ValuePosition = 'Last' | 'Current' | 'Next';


export default class ComputedSeriesComponent
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable operator: MathematicalOperator;
    @observable seriesId?: string;
    @observable seriesValuePositionOffset?: number;
    @observable.ref value?: DataObject;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                operator: MathematicalOperator,
                seriesId?: string,
                seriesValuePositionOffset?: number,
                value?: DataObject)
    {
        this.id = id;
        this.operator = operator;
        this.seriesId = seriesId;
        this.seriesValuePositionOffset = seriesValuePositionOffset;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new ComputedSeriesComponent(
            descriptor.id,
            descriptor.operator,
            descriptor.seriesId,
            descriptor.seriesValuePositionOffset,
            descriptor.value
                ?
                    DataObject.constructFromDescriptor(descriptor.value)
                :
                    undefined);
    }

    toDescriptor()
    {
        return {
            id: this.id,
            operator: this.operator,
            seriesId: this.seriesId,
            seriesValuePositionOffset: this.seriesValuePositionOffset,
            value:
                this.value
                    ?
                        this.value.descriptor()
                    :
                        undefined
        };
    }

    // ----------------------- Private logic ------------------------
}
