import EntityInputLayout from '../Type/EntityInputLayout';
import CompositeLayout from '../Type/CompositeLayout';
import InsetLayout from '../Type/InsetLayout';
import ExpansionLayout from '../Type/ExpansionLayout';
import ComputationLayout from '../Type/ComputationLayout';
import ConditionalLayout from '../Type/ConditionalLayout';
import OptionalRelatedEntityLayout from '../Type/OptionalRelatedEntityLayout';
import Layout from '../Layout';
import LayoutDependencyContext from '../LayoutDependencyContext';
import CardLayout from '../Type/CardLayout';
import EmptyLayout from '../Type/EmptyLayout';
import PortalListLayout from '../Type/PortalListLayout';
import ClickLayout from '../Type/ClickLayout';
import FormLayout from '../Type/Form/FormLayout';
import PrimitiveFormInputLayout from '../Type/Form/Input/PrimitiveFormInputLayout';
import LabelButtonLayout from '../Type/LabelButtonLayout';
import SelectionFormInputLayout from '../Type/Form/Input/SelectionFormInputLayout';
import LabelLayout from '../Type/LabelLayout';
import PortalImageLayout from '../Type/PortalImageLayout';
import AppBarLayout from '../Type/AppBarLayout';
import CenteredBodyLayout from '../Type/CenteredBodyLayout';
import PortalRouterLayout from '../Type/PortalRouterLayout';
import PortalUserButtonLayout from '../Type/PortalUserButtonLayout';
import HeaderWithScrollBodyLayout from '../Type/HeaderWithScrollBodyLayout';
import PortalSwitcherTabBarLayout from '../Type/PortalSwitcherTabBarLayout';
import LocalizedLayout from '../Type/LocalizedLayout';
import BodyLayout from '../Type/BodyLayout';
import ResourceImageLayout from '../Type/ResourceImageLayout';
import TemplateLayout from '../Type/TemplateLayout';
import ExpansionGroupLayout from '../Type/ExpansionGroupLayout';
import DrawingFormInputLayout from '../Type/Form/Input/DrawingFormInputLayout';
import ComputedImageLayout from '../Type/ComputedImageLayout';
import FilesFormInputLayout from '../Type/Form/Input/FilesFormInputLayout';
import TabBarLayout from '../Type/TabBar/TabBarLayout';
import TableLayout from '../Type/Table/TableLayout';
import EntitySelectionFormInputLayout from '../Type/Form/Input/EntitySelectionFormInputLayout';
import PortalRouteLayout from '../Type/PortalRouteLayout';
import CollectionLayout from '../Type/CollectionLayout';
import EffectLayout from '../Type/EffectLayout';
import AddressLookupLayout from '../Type/AddressLookupLayout';
import InvalidLayout from '../Type/InvalidLayout';
import PortalTableLayout from '../Type/PortalTable/PortalTableLayout';

export default async function getLayoutFromDescriptor(
    descriptor: any,
    dependencyContext: LayoutDependencyContext
): Promise<Layout>
{
    try
    {
        return await innerGetLayoutFromDescriptor(
            descriptor,
            dependencyContext
        );
    }
    catch (e)
    {
        console.log(e.message);

        return new InvalidLayout(
            (e as Error).message,
            (e as Error).stack,
            descriptor
        );
    }
}

async function innerGetLayoutFromDescriptor(
    descriptor: any,
    dependencyContext: LayoutDependencyContext
): Promise<Layout>
{
    switch (descriptor.type)
    {
        case 'Empty':
            return EmptyLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Composite':
            return CompositeLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Collection':
            return CollectionLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Localized':
            return LocalizedLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Inset':
            return InsetLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Card':
            return CardLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'ExpansionGroup':
            return ExpansionGroupLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Expansion':
            return ExpansionLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Click':
            return ClickLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'LabelButton':
            return LabelButtonLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Label':
            return LabelLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Computation':
            return ComputationLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Conditional':
            return ConditionalLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'EntityInput':
            return EntityInputLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'OptionalRelatedEntity':
            return OptionalRelatedEntityLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Table':
            return TableLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalList':
            return PortalListLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalTable':
            return PortalTableLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalRoute':
            return PortalRouteLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalImage':
            return PortalImageLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'ResourceImage':
            return ResourceImageLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'ComputedImage':
            return ComputedImageLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Form':
            return FormLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PrimitiveFormInput':
            return PrimitiveFormInputLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'EntitySelectionFormInput':
            return EntitySelectionFormInputLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'SelectionFormInput':
            return SelectionFormInputLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'DrawingFormInput':
            return DrawingFormInputLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'FilesFormInput':
            return FilesFormInputLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'AppBar':
            return AppBarLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'HeaderWithScrollBody':
            return HeaderWithScrollBodyLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Body':
            return BodyLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'CenteredBody':
            return CenteredBodyLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'TabBar':
            return TabBarLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalRouter':
            return PortalRouterLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalUserButton':
            return PortalUserButtonLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'PortalSwitcherTabBar':
            return PortalSwitcherTabBarLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Template':
            return TemplateLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Effect':
            return EffectLayout.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'AddressLookup':
            return AddressLookupLayout.fromDescriptor(
                descriptor,
                dependencyContext);
    }

    throw new Error('Layout type not found: ' + descriptor.type);
}
