import useLinkExternalEmailTrigger from '../../Entity/Bespoke/Activity/Email/ExternalEmail/LinkExternalEmail/LinkExternalEmailTrigger';
import useComposeExternalEmailTrigger from '../../Entity/Bespoke/Activity/Email/ExternalEmail/ComposeExternalEmail/ComposeExternalEmailTrigger';
import Trigger from './Trigger';
import { useComputed } from 'mobx-react-lite';
import useLinkExternalAppointmentTrigger from '../../Entity/Bespoke/Activity/Appointment/ExternalAppointment/LinkExternalAppointment/LinkExternalAppointmentTrigger';

export default function useTriggers(additionalTriggers: Trigger<any>[])
{
    const linkExternalEmailTrigger = useLinkExternalEmailTrigger();
    const composeExternalEmailTrigger = useComposeExternalEmailTrigger();

    const linkExternalAppointmentTrigger = useLinkExternalAppointmentTrigger();

    return useComputed(
        () =>
            [
                linkExternalEmailTrigger,
                composeExternalEmailTrigger,
                linkExternalAppointmentTrigger,
                ...additionalTriggers
            ],
        [
            linkExternalEmailTrigger,
            composeExternalEmailTrigger,
            additionalTriggers
        ]);
}
