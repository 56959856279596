import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Click from '../Click/Click';
import Inset from '../Inset/Inset';
import useApsisCampaignSendCount from '../../../Viewer/Content/Activity/ApsisCampaign/Hooks/useApsisCampaignSendCount';
import { ApsisCampaignChartDelivered } from '../../../Viewer/Content/Activity/ApsisCampaign/Charts/ApsisCampaignChartDelivered';
import { ApsisCampaignChartViewed } from '../../../Viewer/Content/Activity/ApsisCampaign/Charts/ApsisCampaignChartViewed';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface ApsisCampaignProps
{
    apsisCampaign: Entity;
}

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
    labelName: {
        fontWeight: 'bold'
    }
});

export const ApsisCampaign: React.FC<ApsisCampaignProps> =
    observer(
        (
            {
                apsisCampaign
            }
        ) =>
        {
            const classes = useStyles();

            const sendCount = useApsisCampaignSendCount(apsisCampaign);

            return <Click
                open
            >
                <Inset>
                    <ViewGroup
                        className={classes.root}
                        orientation="horizontal"
                        spacing={10}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <ApsisCampaignChartDelivered
                                size={80}
                                apsisCampaign={apsisCampaign}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ApsisCampaignChartViewed
                                size={80}
                                apsisCampaign={apsisCampaign}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem
                            ratio={1}
                        >
                            <ViewGroup orientation="vertical" spacing={5}>
                                <ViewGroupItem>
                                    <span className={classes.labelName}>
                                        {apsisCampaign.name}
                                    </span>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <span>
                                        <LocalizedText
                                            code="Campaign.SendItemCount"
                                            value="${sendCount} verzonden items"
                                            sendCount={sendCount}
                                        />
                                    </span>
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </Inset>
            </Click>;
        }
    );