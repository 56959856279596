import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { Account } from '../../Model/Implementation/Account';
import { AccountSession } from '../../Model/Implementation/AccountSession';
import { User } from '../../Model/Implementation/User';

export class AccountUserController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    getAccount(): Promise<Account>
    {
            return new Promise<Account>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account', Method.Get, {  }))
                    .then(result =>
                    {
                        resolve(fromJson(result, Account));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    getUser(): Promise<User>
    {
            return new Promise<User>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account/user', Method.Get, {  }))
                    .then(result =>
                    {
                        resolve(fromJson(result, User));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    getUsersInAccount(page?: number, pageSize?: number, query?: string): Promise<User[]>
    {
            return new Promise<User[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account/users', Method.Get,
                    {
                        page: page,
                        pageSize: pageSize,
                        query: query
                    }))
                    .then(result =>
                    {
                        resolve((fromJson(result, User as any) as User[]));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    logout(): Promise<boolean>
    {
            return new Promise<boolean>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account/logout', Method.Post, {  }))
                    .then(result =>
                    {
                        resolve(fromJson(result));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    switchUser(userId: number): Promise<AccountSession>
    {
            return new Promise<AccountSession>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account/switch-user', Method.Post, { user_id: userId }))
                    .then(result =>
                    {
                        resolve(fromJson(result, AccountSession));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
    switchTeam(teamId: string): Promise<AccountSession>
    {
        return new Promise<AccountSession>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account/switch-team', Method.Post, { team_id: teamId }))
                    .then(result =>
                    {
                        resolve(fromJson(result, AccountSession));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };
    getRole(): Promise<any>
    {
        return new Promise<any>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/account/role', Method.Get, {}))
                    .then(result =>
                    {
                        resolve(result);
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
            });
    };

}
