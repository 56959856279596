import Period from '../Model/Period';
import isInPeriod from './isInPeriod';

export default function isPeriodInPeriod(period: Period,
                                         inPeriod: Period,
                                         isEndInclusive: boolean = false)
{
    return isInPeriod(period.from, inPeriod, isEndInclusive)
        && isInPeriod(period.to, inPeriod, isEndInclusive);
}
