import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import useSpotlerCampaignBouncedCount from './useSpotlerCampaignBouncedCount';
import useCount from '../../../../../Selection/Hooks/useCount';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { useMemo } from 'react';

export default function useSpotlerCampaignDeliveredCount(spotlerCampaign: Entity, selectedStep: Entity)
{
    const types = useTypes();

    const bounced = useSpotlerCampaignBouncedCount(spotlerCampaign, selectedStep);

    const totalCount = useCount(
        types.Activity.SpotlerCampaignResult.Type,
        (builder, rootpath) =>
            builder
                .where(
                    cb =>
                        cb.relatedToEntity(
                            EntityPath
                                .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                .joinTo(
                                    types.Activity.SpotlerCampaign.RelationshipDefinition.Results,
                                    true
                                ),
                            spotlerCampaign
                        )
                )
                .if(
                    () => selectedStep !== undefined,
                    cb =>
                        cb.where(
                            cb =>
                                cb.relatedToEntity(
                                    EntityPath
                                        .fromEntityType(types.Activity.SpotlerCampaignResult.Type)
                                        .joinTo(
                                            types.SpotlerCampaignStep.RelationshipDefinition.Results,
                                            true
                                        ),
                                    selectedStep
                                )
                        )
                ),
        [
            types,
            spotlerCampaign,
            selectedStep
        ]
    );

    return useMemo(
        () =>
            totalCount - bounced,
        [
            totalCount,
            bounced
        ]
    )
}