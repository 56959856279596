import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { EntityContext } from '../../../../@Model/EntityContext';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import getTypes from '../../../../Type/Api/getTypes';
import { EntityField } from '../../../../../../../@Api/Model/Implementation/EntityField';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import getConnectorActivationByCode from '../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';

export default function getExpressionContextForTemplate(
    rootType: EntityType,
    templateType: EntityType,
    templateField: EntityField,
    rootEntity?: Entity,
    entityByParameterId: Map<string, Entity> = new Map(),
    commitContext?: CommitContext
)
{
    const types = getTypes();
    let entityContext =
        new EntityContext(
            rootEntity ? [ rootEntity ] : [],
            EntityPath.fromEntityType(rootType))
            .setContext(
                'Environment',
                new EntityContext(
                    [],
                    EntityPath.fromEntityType(types.Relation.Organization.Environment.Type),
                    undefined,
                    undefined,
                    localizeText('Template.MyOrganization', 'Mijn organisatie')))
            .setContext(
                'Employee',
                new EntityContext(
                    [],
                    EntityPath.fromEntityType(types.Relationship.Person.Contact.Employee.Type)));

    if (templateType.isA(types.Template.Document.Type)
        && (templateField === types.Template.Document.Field.Header
            || templateField === types.Template.Document.Field.Footer))
    {
        entityContext =
            entityContext.setVariable({
                name: 'Paginanummer',
                variable: 'Page.Number'
            });
    }

    if (templateType.isA(types.Template.Document.Type)
        && (templateField === types.Template.Document.Field.Header
            || templateField === types.Template.Document.Field.Footer))
    {
        entityContext =
            entityContext.setVariable({
                name: 'Aantal pagina\'s',
                variable: 'Page.Count'
            });
    }

    if (templateType.isA(types.Template.Email.Offer.Type)
        || templateType.isA(types.Template.Email.WorkOrderSign.Type))
    {
        entityContext =
            entityContext.setVariable({
                name: localizeText('SignLink', 'Accorderingslink'),
                variable: 'Document.SignLink',
                isLink: true
            });
    }

    if (templateType.isA(types.Template.Email.Offer.Type))
    {
        entityContext =
            entityContext.setContext(
                'Offer',
                new EntityContext(
                    entityByParameterId.has('Offer')
                        ?
                            [
                                entityByParameterId.get('Offer')
                            ]
                        :
                            [],
                    EntityPath.fromEntityType(types.Activity.Offer.Type)));
    }

    if (templateType.isA(types.Template.Email.Invoice.Type))
    {
        entityContext =
            entityContext.setContext(
                'Invoice',
                new EntityContext(
                    entityByParameterId.has('Invoice')
                        ?
                            [
                                entityByParameterId.get('Invoice')
                            ]
                        :
                            [],
                    EntityPath.fromEntityType(types.Activity.Invoice.Type)));

        getConnectorActivationByCode('Mollie').then(
            activation =>
            {
                if (activation)
                {
                    const configuration: any = activation.getObjectValueByField(types.ConnectorActivation.Field.Configuration);

                    if (configuration?.isConfigured)
                    {
                        entityContext = entityContext.setVariable({
                            name: localizeText('PaymentLink', 'Betalingslink'),
                            variable: 'Document.PayLink',
                            isLink: true
                        });
                    }
                }
            }
        );
    }

    if (templateType.isA(types.Template.Email.WorkOrder.Type)
        || templateType.isA(types.Template.Email.WorkOrderSign.Type))
    {
        entityContext =
            entityContext.setContext(
                'WorkOrder',
                new EntityContext(
                    entityByParameterId.has('WorkOrder')
                        ?
                            [
                                entityByParameterId.get('WorkOrder')
                            ]
                        :
                            [],
                    EntityPath.fromEntityType(types.Activity.WorkOrder.Type)));
    }

    if (templateType.isA(types.Template.Email.Type))
    {
        entityContext =
            entityContext.setVariable({
                name: 'Naam afzender',
                variable: 'Sender.Name'
            });

        entityContext =
            entityContext.setVariable({
                name: 'Naam ontvanger',
                variable: 'Receiver.Name'
            });
    }

    return {
        entityContext,
        commitContext,
    };
}
