import { Entity } from '../../../../../Model/Implementation/Entity';
import { CommitContext } from '../../CommitContext';
import commitEntity, { CommitOptions } from '../../../commitEntity';

export function commitEntityWithContext(
    entity: Entity,
    context?: CommitContext,
    options?: Partial<CommitOptions>
)
{
    return commitEntity(
        entity,
        {
            context,
            ...options
        }
    );
}
