import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityMetadataRepository } from './EntityMetadataRepository';
import { observable } from 'mobx';
import { EntityType } from './EntityType';
import { EntityField } from './EntityField';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';

@type('EntityMetadata')
export class EntityMetadata
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityMetadataRepository') @observable.ref typeRepository: EntityMetadataRepository;
    @reference(undefined, 'EntityMetadataRepository') @observable.ref fieldRepository: EntityMetadataRepository;
    @reference(undefined, 'EntityMetadataRepository') @observable.ref relationshipDefinitionRepository: EntityMetadataRepository;
    @reference(undefined, 'EntityType') @observable.shallow types: EntityType[];
    @reference(undefined, 'EntityField') @observable.shallow fields: EntityField[];
    @reference(undefined, 'EntityRelationshipDefinition') @observable.shallow relationshipDefinitions: EntityRelationshipDefinition[];
    @observable.ref entityByTypeId: any;
    @observable.ref entityByFieldId: any;
    @observable.ref entityByRelationshipDefinitionId: any;
    @observable state: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
