import * as React from 'react';
import { useEffect } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { EntityAvatarEditorStore } from './EntityAvatarEditorStore';
import { Button, Slider } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import { useFetchedApiFile } from '../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

const ReactAvatarEditor = require('react-avatar-editor');

export const EntityAvatarEditor: React.FC<BaseComponentProps<EntityAvatarEditorStore>> =
    observer(
        ({
            store,
         }) =>
        {
            useAsyncResult(
                () =>
                    store.reinitializeStore(),
                [store]
            );

            const [ initializedImageUrl ] =
                useFetchedApiFile(
                    store.image.type === 'ApiUrl'
                        ? store.image.url
                        : undefined
                );

            const localFileUrl =
                useComputed(
                    () =>
                        store.image.type === 'File'
                            ? URL.createObjectURL(store.image.file)
                            : undefined,
                    [store]
                );

            useEffect(
                () =>
                {
                    if (localFileUrl)
                    {
                        return () =>
                            URL.revokeObjectURL(localFileUrl)
                    }
                },
                [localFileUrl]
            );

            return <div
                key={store.image && store.zoomLevel} // Force redraw when these variables change
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Dropzone
                    ref={(node: any) => { store.setDropZoneReference(node); }}
                    noClick
                    onDrop={store.onFileUpload}
                    accept="image/jpeg, image/png"
                    multiple={false}
                >
                    {
                        ({getRootProps, getInputProps, isDragActive}) => (
                            <div
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div
                                    onWheel={store.zoomAvatar}
                                >
                                    {
                                        store.isChange || !store.image
                                            ? <ReactAvatarEditor
                                                ref={store.setEditorReference}
                                                image={
                                                    store.image.type === 'ApiUrl'
                                                        ? initializedImageUrl
                                                        : store.image.type === 'File'
                                                            ? localFileUrl
                                                            : store.image.url
                                                }
                                                width={store.size}
                                                height={store.size}
                                                border={16}
                                                color={[0, 0, 0, 0.6]}
                                                scale={store.zoomLevel}
                                                rotate={0}
                                                borderRadius={150}
                                                crossOrigin="anonymous"
                                                onImageReady={store.onCanvasImageUpdate}
                                                onPositionChange={store.onCanvasImageUpdate}
                                            />
                                            : <img
                                                alt=""
                                                src={
                                                    store.image.type === 'ProxyUrl'
                                                        ? store.image.url
                                                        : initializedImageUrl
                                                }
                                                width={store.size}
                                                height={store.size}
                                                style={{
                                                    backgroundColor: store.selectedFile ? undefined : 'whitesmoke',
                                                    borderRadius: store.selectedFile ? undefined : '50%'
                                                }}
                                            />
                                    }
                                </div>
                            </div>
                        )
                    }
                </Dropzone>
                <div
                    style={{
                        flex: '1 1 auto'
                    }}
                >
                    <Slider
                        value={store.zoomLevel}
                        min={0.1}
                        max={3.0}
                        step={0.2}
                        onChangeCommitted={
                            (e, value) =>
                                Array.isArray(value)
                                    ? store.setZoomLevel(value[0])
                                    : store.setZoomLevel(value)
                        }
                        aria-labelledby="continuous-slider"
                    />
                    <Button
                        onClick={store.openUploadDialog}
                        color="primary"
                    >
                        {
                            store.localizationStore.translate('Generic.Upload') // Upload
                        }
                    </Button>
                    <Button
                        onClick={store.deleteAvatar}
                        color="primary"
                    >
                        {
                            store.localizationStore.translate('Generic.Delete') // Delete
                        }
                    </Button>
                </div>
            </div>;
        }
    );