import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DragAndDropStore } from '../../../Generic/DragAndDrop/DragAndDropStore';
import { useEffect } from 'react';
import { DragStartListener } from './DragStartListener';

export default function useOnDragStart(listener: DragStartListener)
{
    useEffect(
        () =>
        {
            const dragAndDropStore = loadModuleDirectly(DragAndDropStore);
            dragAndDropStore.listenToDragStart(listener);

            return () =>
            {
                dragAndDropStore.unlistenToDragStart(listener);
            };
        },
        [
            listener
        ]);
}