import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { TableCell } from '@material-ui/core';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import styles from './Cell.module.scss';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import List from '../../../Model/Specification/List';
import Column from '../../../Model/Specification/Column';
import { IObservableArray, runInAction } from 'mobx';
import Ordering from '../../../Model/Ordering';
import { primaryColor, textSecondaryColor } from '../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ColumnFilterButton from './ColumnFilter/ColumnFilterButton/ColumnFilterButton';
import View from '../../../Model/View';
import useSwitch from '../../../../../../../@Util/Switch/useSwitch';
import { isFieldPathSearchable } from '../../../Api/isFieldPathSearchable';
import { getPotentiallySearchableFieldPath } from '../../../Api/getPotentiallySearchableFieldPath';
import SortIconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/SortIconButton/SortIconButton';
import { Grouping } from '../../../Model/Grouping';
import { EntityTypeType } from '../../../../../DataObject/Type/Api/EntityType/EntityTypeType';
import { ColumnAggregateEditor } from './ColumnAggregateEditor';

export interface CellProps
{
    view: View;
    list: List;
    column: Column;
    onDelete: (column: Column) => void;
    onShift: (column: Column, isLeft: boolean) => void;
    isInEditMode: boolean;
    first?: boolean;
    last?: boolean;
    appendix?: React.ReactNode;
}

const Cell: React.FC<CellProps> =
    props =>
    {
        const { view, list, column, onDelete, onShift } = props;

        const entityTypeStore = useContext(EntityTypeContext);
        const label =
            useComputed(
                () =>
                    column.fieldPath.getName(entityTypeStore),
                [
                    column,
                    entityTypeStore
                ]);
        const doDelete =
            useCallback(
                () =>
                    onDelete(column),
                [
                    onDelete,
                    column
                ]);
        const shiftToLeft =
            useCallback(
                () =>
                    onShift(column, true),
                [
                    onShift,
                    column
                ]);
        const shiftToRight =
            useCallback(
                () =>
                    onShift(column, false),
                [
                    onShift,
                    column
                ]);

        const ordering =
            useComputed(
                () =>
                    list.ordering
                        .find(
                            ordering =>
                                ordering.column === column),
                [
                    list,
                    column
                ]);

        const toggleOrdering =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (ordering)
                            {
                                if (ordering.isAscending)
                                {
                                    list.ordering = [
                                        new Ordering(
                                            column,
                                            false)
                                    ];
                                }
                                else
                                {
                                    list.ordering = [];
                                }
                            }
                            else
                            {
                                list.ordering = [
                                    new Ordering(
                                        column,
                                        true)
                                ];
                            }
                        }),
                [
                    list,
                    column,
                    ordering
                ]);

        const grouping =
            useComputed(
                () =>
                    list.groupings
                        .find(
                            grouping =>
                                grouping.column === column
                        ),
                [
                    list,
                    column
                ]
            );

        const toggleGrouping =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (grouping)
                            {
                                (list.groupings as IObservableArray).remove(grouping);
                            }
                            else
                            {
                                list.groupings.push(
                                    new Grouping(column)
                                );
                            }
                        }),
                [grouping, list, column]
            );

        const isSearchable =
            useComputed(
                () =>
                    isFieldPathSearchable(
                        getPotentiallySearchableFieldPath(
                            column.fieldPath
                        )
                    ),
                [
                    column
                ]
            );
        const toggleColumnSearchability =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            column.isSearchable = !column.isSearchable),
                [
                    column
                ]);

        const toggleColumnVisibility =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            column.isHidden = !column.isHidden),
                [
                    column
                ]);

        const [ isFilterOpen, openFilter, closeFilter ] = useSwitch(false);

        const isGroupable =
            useComputed(
                () =>
                    !column.fieldPath.isField
                    || column.fieldPath.field.dataObjectSpecification.type instanceof EntityTypeType,
                [column]
            );

        return <TableCell
            className={classNames(styles.root, props.first && styles.first, props.isInEditMode && styles.editMode, column.isHidden && styles.hidden)}
            onClick={toggleOrdering}
        >
            <div
                className={styles.content}
            >
                <div
                    className={styles.data}
                >
                    {label}
                </div>
                <div
                    className={styles.appendix}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        alignment="center"
                    >
                        {
                            !props.isInEditMode &&
                                <ViewGroupItem
                                    className={classNames(styles.filterButton, (isFilterOpen || column.filter !== undefined) && styles.showFilter)}
                                >
                                    <ColumnFilterButton
                                        view={view}
                                        list={list}
                                        column={column}
                                        open={isFilterOpen}
                                        onOpen={openFilter}
                                        onClose={closeFilter}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            props.isInEditMode &&
                                <ViewGroupItem>
                                    <div
                                        className={styles.controls}
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={5}
                                            alignment="center"
                                        >
                                            {
                                                isSearchable &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="search"
                                                            onClick={toggleColumnSearchability}
                                                            tooltip={
                                                                column.isSearchable
                                                                    ?
                                                                        <LocalizedText
                                                                            code="View.Column.SetNotSearchable"
                                                                            value="Kolom niet doorzoekbaar maken"
                                                                        />
                                                                    :
                                                                        <LocalizedText
                                                                            code="View.Column.SetSearchable"
                                                                            value="Kolom doorzoekbaar maken"
                                                                        />
                                                            }
                                                            color={column.isSearchable ? primaryColor : textSecondaryColor}
                                                        />
                                                    </ViewGroupItem>
                                            }
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon="visibility_off"
                                                    onClick={toggleColumnVisibility}
                                                    tooltip={
                                                        column.isHidden
                                                            ?
                                                                <LocalizedText
                                                                    code="View.Column.SetVisible"
                                                                    value="Kolom zichtbaar maken"
                                                                />
                                                            :
                                                                <LocalizedText
                                                                    code="View.Column.SetInvisible"
                                                                    value="Kolom onzichtbaar maken"
                                                                />
                                                    }
                                                    color={column.isHidden ? primaryColor : textSecondaryColor}
                                                />
                                            </ViewGroupItem>
                                            {
                                                !props.first &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="keyboard_arrow_left"
                                                            onClick={shiftToLeft}
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="View.Column.ShiftToLeft"
                                                                    value="Kolom naar links opschuiven"
                                                                />
                                                            }
                                                            small
                                                        />
                                                    </ViewGroupItem>
                                            }
                                            {
                                                !props.last &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="keyboard_arrow_right"
                                                            onClick={shiftToRight}
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="View.Column.ShiftToRight"
                                                                    value="Kolom naar rechts opschuiven"
                                                                />
                                                            }
                                                            small
                                                        />
                                                    </ViewGroupItem>
                                            }
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon="close"
                                                    onClick={doDelete}
                                                    tooltip={
                                                        <LocalizedText
                                                            code="Generic.Delete"
                                                            value="Verwijderen"
                                                        />
                                                    }
                                                    small
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </div>
                                </ViewGroupItem>
                        }
                        {
                            ordering &&
                                <ViewGroupItem>
                                    <SortIconButton
                                        isAscendingSort={ordering.isAscending}
                                        onClick={() => {}}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            isGroupable &&
                            <ViewGroupItem
                                className={classNames(styles.filterButton, grouping !== undefined && styles.showFilter)}
                            >
                                <IconButton
                                    onClick={toggleGrouping}
                                    icon="subdirectory_arrow_left"
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.GroupBy"
                                            value="Groeperen op"
                                        />
                                    }
                                    color={
                                        grouping
                                            ? primaryColor
                                            : textSecondaryColor
                                    }
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem
                            className={classNames(styles.filterButton, column.aggregate !== undefined && styles.showFilter)}
                        >
                            <ColumnAggregateEditor
                                column={column}
                            />
                        </ViewGroupItem>
                        {
                            props.appendix &&
                                <ViewGroupItem>
                                    {props.appendix}
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </div>
            </div>
        </TableCell>;
    };

export default observer(Cell);
