/**
 * @name BaseStore
 * @description Base functionality for a store, all stores should extend from this one
 * @copyright PerfectView
 *
 * @since 13-9-2017
 * @author Gertjan Centen
 * @version Initial version
 */

import { v4 as uuid } from 'uuid';
import { action, computed, IComputedValue, observable } from 'mobx';
import { StoreState } from './@Model/StoreState';
import { consoleLog } from '../../@Future/Util/Logging/consoleLog';

export type CallbackType<S extends BaseStore<P>, P, T> = ((store: S) => T);
export type PropType<S extends BaseStore<P>, P, T> = CallbackType<S, P, T> | T;

export function getOrComputeProperty<P>(
    store: any,
    key: keyof P,
    defaultValue?: any
): any
{
    if (typeof store.props[key] === 'function')
    {
        return (store.props[key] as any)(store) || defaultValue;
    }
    else
    {
        const value = store.props[key];

        if (value == null)
        {
            return defaultValue;
        }
        else
        {
            return value;
        }
    }
}

export function getOrCompute<P, T extends BaseStore<P>>(store: T,
                                                        value: PropType<T, P, any>,
                                                        defaultValue?: any): any
{
    if (typeof value === 'function')
    {
        if (defaultValue === undefined)
        {
            return value(store);
        }
        else
        {
            return value(store) || defaultValue;
        }
    }
    else
    {
        if (value == null)
        {
            return defaultValue;
        }
        else
        {
            return value;
        }
    }
}

export const longTermDisposableTimeout = 1000;

export abstract class BaseStore<P = {}>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable uuid: string;
    @observable props: P;
    @observable defaultProps: Partial<P>;
    @observable isInitialized: boolean;
    @observable state: StoreState;
    @observable error: string = '';
    @observable _debug: boolean = false;
    @observable disposables = observable.array<() => void>();
    @observable computedProps = observable.map<string, IComputedValue<any>>();
    @observable initializationPromise: Promise<any>;

    // ------------------------ Constructor -------------------------

    constructor(props?: P,
                defaultProps?: Partial<P>,
                requiresInitialization?: () => boolean)
    {
        this.uuid = uuid();
        this.props = observable(Object.assign({}, defaultProps as any, props as any));
        this.defaultProps = defaultProps || {};
        this.state = StoreState.Undefined;

        if (this.initialize)
        {
            // In case of initialization, we might want to check whether the initialization function should be called
            // i.e. sometimes we do not want to initialize, because it happens async and completely removes the component
            // from the DOM tree. This is the case when using DataObjectEditorStores for instance.

            if (requiresInitialization)
            {
                this.isInitialized = !requiresInitialization();

                if (this.isInitialized)
                {
                    this.state = StoreState.Loaded;
                }
            }
            else
            {
                this.isInitialized = false;
            }
        }
        else
        {
            this.isInitialized = true;
            this.state = StoreState.Loaded;
        }
    }

    // ----------------------- Initialization -----------------------

    // requiresInitialization?(): boolean;

    initialize?(): Promise<any>;

    // -------------------------- Computed --------------------------

    @computed
    get isLoading()
    {
        return this.state === StoreState.Loading;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    /**
     * Action called from constructing component to initialize store.
     *
     * @returns {Promise<any>}
     */
    @action.bound
    initializeStore(): Promise<any>
    {
        if (this.isInitialized || this.isLoading)
        {
            return this.initializationPromise || Promise.resolve();
        }
        else
        {
            return this.reinitializeStore();
        }
    }

    @action.bound
    reinitializeStore(inLoadState: boolean = true): Promise<any>
    {
        if (this.isInitialized)
        {
            // this.exitsUI(false);
            this.entersUI(false);
        }

        if (inLoadState)
        {
            this.setState(StoreState.Loading);
        }

        if (this.initialize)
        {
            const initializationPromise =
                this.doInternalInitialize()
                    .then(
                        () =>
                        {
                            this.setInitialized(true);
                            this.setState(StoreState.Loaded);
                            this.clearInitializationPromise();
                        })
                    .catch(
                        reason =>
                        {
                            this.setError(reason);
                            this.clearInitializationPromise();

                            return Promise.reject(reason);
                        });

            this.initializationPromise = initializationPromise;

            return initializationPromise;
        }
        else
        {
            return Promise.resolve();
        }
    }

    @action.bound
    doInternalInitialize(): Promise<any>
    {
        return this.initialize();
    }

    @action.bound
    setError(error: any)
    {
        this.error = error;
        this.state = StoreState.Error;
        console.error(error);
    }

    @action.bound
    setState(state: StoreState)
    {
        this.state = state;
    }

    @action.bound
    setInitialized(isInitialized: boolean)
    {
        this.isInitialized = isInitialized;
    }

    @action.bound
    clearInitializationPromise()
    {
        this.initializationPromise = undefined;
    }

    @action.bound
    registerDisposable(disposable: () => void)
    {
        this.disposables.push(disposable);
    }

    entersUI(isMounted: boolean)
    {
        // Starts any autoruns/observables (may be implemented by implementing store)
        // created during initialization
    }

    exitsUI(isUnmounted: boolean)
    {
        // Disposes of any autoruns/observables (may be implemented by implementing store)
        // created during initialization
        this.clearDisposables();
    }

    @action.bound
    clearDisposables()
    {
        this.disposables.forEach(disposable => disposable());
        this.disposables.clear();
    }

    // ------------------------ Public logic ------------------------

    get debug()
    {
        if (this._debug)
        {
            return consoleLog.bind(console);
        }
        else
        {
            return () => {  };
        }
    }

    get log()
    {
        if (this._debug)
        {
            return consoleLog.bind(console);
        }
        else
        {
            return () => {  };
        }
    }

    // ----------------------- Private logic ------------------------
}
