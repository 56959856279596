import { useCallback, useContext, useState } from 'react';
import RouterContext from '../../RouterContext';
import { useComputed } from 'mobx-react-lite';

export function useRoutingState<S>(
    key: string | undefined,
    initialState?: S | (() => S)
): [S, (value: S) => void]
{
    const isDisabled = key === undefined;
    const routerStore = useContext(RouterContext);
    const routingState =
        useComputed(
            () =>
                routerStore.routingState[key],
            [
                routerStore,
                key,
            ]
        );
    const [value, setValue] =
        useState<S>(
            () =>
            {
                if (!isDisabled && routingState !== undefined)
                {
                    return routingState;
                }
                else if (initialState !== undefined)
                {
                    if (typeof initialState === 'function')
                    {
                        return (initialState as () => S)();
                    }
                    else
                    {
                        return initialState as S;
                    }
                }
                else
                {
                    return undefined;
                }
            }
        );
    const setValueAndRoutingState =
        useCallback(
            (newValue: S) =>
            {
                setValue(newValue);

                if (!isDisabled)
                {
                    if (newValue)
                    {
                        routerStore.setRoutingState({
                            ...routerStore.routingState,
                            [key]: newValue,
                        });
                    }
                    else
                    {
                        const newState = { ...routerStore.routingState };
                        delete newState[key];
                        routerStore.setRoutingState(newState);
                    }
                }
            },
            [
                isDisabled,
                key,
                routerStore
            ]
        );

    return [
        isDisabled
            ? value
            : routingState ?? value,
        setValueAndRoutingState
    ];
}