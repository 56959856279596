import { action, observable } from 'mobx';
import { ComponentClass } from 'react';
import { ToolbarElementStore } from './ToolbarElementStore';

export class ToolbarElementCommandStore extends ToolbarElementStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable executeCallback: (command: string, isActive: boolean) => void;
    @observable setFormat: (command: string, value: any) => void;
    @observable command: string;
    @observable isActive: boolean = false;
    @observable receiveAllEvents: boolean = false;
    @observable lastEvent: any;

    // ------------------------ Constructor -------------------------

    constructor(id: number | string,
                layout: ComponentClass<any>,
                command: string,
                allEvents: boolean = false)
    {
        super(id, layout);

        this.command = command;
        this.receiveAllEvents = allEvents;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    setActive(isActive: boolean)
    {
        this.isActive = isActive;
    }

    @action.bound
    executeCommand()
    {
        if (this.executeCallback)
        {
            this.executeCallback(this.command, this.isActive);
        }
    }

    @action.bound
    passEvent(event: any)
    {
        this.passEventInternal(event);
    }

    passEventInternal(event: any)
    {
        this.lastEvent = event;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
