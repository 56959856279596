import React, { useCallback, useMemo } from 'react';
import styles from './IconToggleButton.module.scss';
import Icon from '../Icon/Icon';
import { classNames } from '../../../Util/Class/classNames';
import Tooltip from '../Tooltip/Tooltip';
import useIsXs from '../../../../@Api/Media/useIsXs';

export interface IconToggleButtonClasses
{
    root?: string;
}


export interface IconToggleButtonProps
{
    onClick?: () => void;
    icon: string;
    iconOutlined?: boolean;
    count?: number | string;
    showAddWhenEmptyCount?: boolean;
    active?: boolean;
    disabled?: boolean;
    tooltip?: string;
    size?: 'sm' | 'md';
    overline?: string;
    underline?: string;
    reserveUnderlineOverlineSpacing?: boolean;
    classes?: IconToggleButtonClasses;
}

const IconToggleButton: React.FC<IconToggleButtonProps> =
    props =>
    {
        const { onClick } = props;
        const onClickCallback =
            useCallback(
                () =>
                {
                    if (onClick)
                    {
                        onClick();
                    }
            },
            [
                onClick
            ]);

        const isEmpty = props.count === undefined || props.count === 0;

        const button =
            <Tooltip
                title={props.tooltip}
            >
                <div
                    className={
                        classNames(
                            styles.root,
                            props.classes && props.classes.root,
                            props.size && props.size === 'sm' && styles.small,
                            props.active && styles.active,
                            props.disabled && styles.disabled,
                            !props.showAddWhenEmptyCount && styles.noTopPadding,
                            isEmpty && styles.empty
                        )}
                    onClick={onClickCallback}
                >
                    <Icon
                        icon={props.icon}
                        outlined={props.iconOutlined}
                        className={styles.icon}
                        size={
                            props.size === 'sm'
                                ?
                                    18
                                :
                                    20}
                    />
                    {
                        props.size !== 'sm' && props.showAddWhenEmptyCount &&
                            <span
                                className={styles.count}
                            >
                                {
                                    props.count === undefined || props.count === 0
                                        ?
                                            <span
                                                className={props.showAddWhenEmptyCount ? styles.emptyLabel : undefined}
                                            >
                                                &nbsp;
                                            </span>
                                        :
                                            props.count.toString()
                                }
                            </span>
                    }
                </div>
            </Tooltip>;

        const isXs = useIsXs();
        const overlineClassNames =
            useMemo(
                () =>
                    classNames(styles.overline, isXs && styles.xs),
                [
                    isXs
                ]);

        const underlineClassNames =
            useMemo(
                () =>
                    classNames(styles.underline, isXs && styles.xs),
                [
                    isXs
                ]);

        if (props.overline || props.underline || props.reserveUnderlineOverlineSpacing)
        {
            return <div className={styles.buttonWrapper}>
                {
                    (props.overline || props.reserveUnderlineOverlineSpacing) &&
                        <div
                            className={overlineClassNames}
                        >
                            {props.overline ? props.overline : <span>&nbsp;</span>}
                        </div>
                }
                {
                    button
                }
                {
                    (props.underline || props.reserveUnderlineOverlineSpacing) &&
                        <div
                            className={underlineClassNames}
                        >
                            {props.underline ? props.underline : <span>&nbsp;</span>}
                        </div>
                }
            </div>;
        }
        else
        {
            return button;
        }
    };

IconToggleButton.defaultProps =
{
    size: 'md',
    showAddWhenEmptyCount: true,
    reserveUnderlineOverlineSpacing: false
};

export default IconToggleButton;
