import React, { useCallback, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import FileDropZone from '../../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import styles from './AttachmentDropZone.module.scss';
import AttachmentDialog from '../Dialog/AttachmentDialog';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface AttachmentDropZoneProps
{
    entity: Entity;
    onAttachmentsAdded?: (attachments: Entity[]) => void;
    classes?: DropZoneClasses;
}

export interface DropZoneClasses
{
    dropContainer?: string;
    dragOverlay?: string;
}

const AttachmentDropZone: React.FC<AttachmentDropZoneProps> =
    props =>
    {
        const [ files, setFiles] = useState<File[]>(undefined);

        const onReset =
            useCallback(
                () =>
                {
                    setFiles(undefined);
                },
                [
                    setFiles
                ]);

        const addFilesCallback =
            useCallback(
                (files: File[]) =>
                {
                    setFiles(files);
                },
                [
                    setFiles,
                    props.onAttachmentsAdded
                ]);

        const onAttachmentAdded =
            useCallback(
                (attachment: Entity) =>
                {
                    if (props.onAttachmentsAdded)
                    {
                        props.onAttachmentsAdded([attachment]);
                    }
                },
                [
                    props.onAttachmentsAdded
                ]);

        return <>
            {
                files &&
                <AttachmentDialog
                    files={files}
                    entity={props.entity}
                    onReset={onReset}
                    onAttachmentAdded={onAttachmentAdded}
                />
            }
            <FileDropZone
                onDrop={addFilesCallback}
                classes={{
                    dragOverlay: classNames(styles.dragOverlay, props.classes?.dragOverlay),
                    dropContainer: props.classes?.dropContainer
                }}
                dragOverlayLabel="Toevoegen als bijlage"
                // noClick
            >
                {props.children}
            </FileDropZone>
        </>;
    };

export default observer(AttachmentDropZone);
