import { CSSProperties } from 'react';
import { primaryFontFamily, textSecondaryColor, weightRegular } from './Theme';

export const typographyBase: CSSProperties =
    {
        fontFamily: primaryFontFamily,
        fontWeight: weightRegular
    };

export const typographyRichText: CSSProperties =
    {
        ...typographyBase,
        wordBreak: 'break-word',
        lineHeight: 1.5
    };

export const typographyUnderline: CSSProperties =
{
    ...typographyBase,
    fontSize: '13px'
};

export const typographyLargerUnderline: CSSProperties =
{
    ...typographyBase,
    fontSize: '14px'
};

export const typographyOverline: CSSProperties =
{
    ...typographyBase,
    fontSize: '11px',
    textTransform: 'uppercase',
    color: '#80868b',
    fontWeight: 500,
    letterSpacing: '0.8px',
    lineHeight: '16px'
};

export const typographyLabel: CSSProperties =
{
    ...typographyBase,
    color: textSecondaryColor,
    fontSize: '14px',
    fontWeight: 400,
};

export const typographyTab: CSSProperties =
{
    ...typographyBase,
    color: textSecondaryColor,
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase'
};

export const typographyChip: CSSProperties =
    {
        ...typographyBase,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: '0.47px'
    };

export const typographyCardHeader: CSSProperties =
    {
        ...typographyBase,
        color: textSecondaryColor,
        fontSize: '11px',
        fontWeight: 500,
        textTransform: 'uppercase'
    };

export const typographyExpansionPanelHeader: CSSProperties =
{
    ...typographyBase,
    color: textSecondaryColor,
    fontSize: '15px',
    fontWeight: 300
};

export const typographyExpansionPanelHeaderRoot: CSSProperties =
{
    ...typographyExpansionPanelHeader,
    fontWeight: 400
};

export const typographyExpansionPanelHeaderInBackground: CSSProperties =
{
    ...typographyExpansionPanelHeader,
    fontWeight: 400
};

export const typographyBucketName: CSSProperties =
{
    ...typographyBase,
    fontSize: '11px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
};

export const typographyTimelineDate: CSSProperties =
{
    ...typographyBase,
    fontSize: '11px',
};

export const typographyTimelineLabel: CSSProperties =
{
    ...typographyBase,
    fontSize: '12px',
    color: textSecondaryColor,
    lineHeight: 1.5
};

export const typographyEntityName: CSSProperties =
{
    ...typographyBase,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.47px',
    lineHeight: '24px'
};

export const typographyEntityNameHeader: CSSProperties =
{
    ...typographyBase,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.47px',
    // lineHeight: '24px'
};

export const entitySummary: CSSProperties =
{
    ...typographyBase,
    fontSize: '13px',
    fontWeight: 500
};
