import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { ApiStatus } from '../../Model/Implementation/ApiStatus';

export class StatusController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    getStatus(): Promise<ApiStatus>
    {
        return new Promise<ApiStatus>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/status', Method.Get, {  }))
                    .then(result =>
                    {
                        resolve(fromJson(result, ApiStatus));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
