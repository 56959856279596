import { ApiBaseType } from '../ApiBaseType';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObject } from '../../../Model/DataObject';
import Dictionary from 'typescript-collections/dist/lib/Dictionary';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class EntityFieldType extends ApiBaseType<EntityField>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'EntityField';
    }

    name()
    {
        return localizeText('DataObject.Type.EntityField', 'Veld');
    }

    getInstanceId(instance: EntityField): number
    {
        return instance.id;
    }

    getInstanceName(instance: EntityField): string
    {
        return (instance.name as any);
    }

    getInstanceDescription(instance: EntityField): string
    {
        return null;
    }

    getInstanceAvatarUrl(instance: EntityField, moduleManager: ModuleManager): string
    {
        return null;
    }

    getInstances(ids: number[],
                 dataObjectById: Dictionary<number, DataObject>,
                 moduleManager: ModuleManager): Promise<EntityField[]>
    {
        let factory: EntityTypeStore = moduleManager.getInstance(EntityTypeStore);

        return new Promise<EntityField[]>(
            (resolve, reject) =>
            {
                let entityFields: EntityField[] = [];

                for (let id of ids)
                {
                    entityFields.push(factory.getFieldById(id));
                }
                resolve(entityFields);
            });
    }

    searchInstances(query: string, limit: number, dataObject: DataObject, moduleManager: ModuleManager): Promise<EntityField[]>
    {
        let factory: EntityTypeStore = moduleManager.getInstance(EntityTypeStore);
        let entityType: EntityType = this.getEntityTypeFromSpecification(dataObject.specification, factory);

        return new Promise<EntityField[]>(
            (resolve, reject) =>
            {
                let entityFields: EntityField[] = [];

                for (let entityField of entityType.getInheritedFields())
                {
                    if (this.getInstanceName(entityField).toLowerCase().indexOf(query) !== -1)
                    {
                        entityFields.push(entityField);
                    }
                }
                resolve(entityFields);
            });
    }

    getAllInstances(specification: DataObjectSpecification, moduleManager: ModuleManager): Promise<EntityField[]>
    {
        let factory: EntityTypeStore = moduleManager.getInstance(EntityTypeStore);
        let entityType = this.getEntityTypeFromSpecification(specification, factory);

        if (entityType)
        {
            return Promise.resolve(entityType.getInheritedFields());
        }
        else
        {
            return Promise.reject('No entity type found.');
        }
    }

    getEntityTypeFromSpecification(specification: DataObjectSpecification,
                                   entityTypeStore: EntityTypeStore): EntityType
    {
        let entityType: EntityType = entityTypeStore.getEntityTypeById(specification.data.data.entityType);

        return entityType;
    }
}
