import uuid from '../../../@Util/Id/uuid';

export type ValidationType = 'Error' | 'Warning';

export default class Validation
{
    // ------------------------- Properties -------------------------

    id: string;
    type: ValidationType;
    message: string;

    // ------------------------ Constructor -------------------------

    constructor(type: ValidationType,
                message: string)
    {
        this.id = uuid();
        this.type = type;
        this.message = message;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
