import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray, runInAction } from 'mobx';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteIconButton from '../../../../../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import { ListQueryJoinPathsEditorProps } from './ListQueryJoinPathsEditor';
import { EntityPath } from '../../../../../../../../../../Path/@Model/EntityPath';

export interface ListQueryJoinPathEditorProps extends ListQueryJoinPathsEditorProps
{
    joinPath: EntityPath;
}

const ListQueryJoinPathEditor: React.FC<ListQueryJoinPathEditorProps> =
    props =>
    {
        const { query, joinPath } = props;

        const deleteJoinPath =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (query.joinPaths as IObservableArray).remove(joinPath)),
                [
                    query,
                    joinPath
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                {joinPath.getName()}
            </ViewGroupItem>
            <ViewGroupItem>
                <DeleteIconButton
                    onClick={deleteJoinPath}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ListQueryJoinPathEditor);
