import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { runInAction } from 'mobx';
import CardInset from '../../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import UpdateEntityAction from '../../../../../../../../../../@Api/Automation/Function/Action/UpdateEntityAction';
import Menu from '../../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import EntityValueType from '../../../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Item from '../../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import Parameter from '../../../../../../../../../../@Api/Automation/Parameter/Parameter';
import Divider from '../../../../../../../../../../@Future/Component/Generic/Divider/Divider';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationEditor from '../../../Computation/ComputationEditor';
import FieldPathSelector from '../../../../../../../Path/FieldPathEditor/FieldPathSelector';
import { EntityContext } from '../../../../../../../@Model/EntityContext';
import { EntityFieldPath } from '../../../../../../../Path/@Model/EntityFieldPath';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface UpdateEntityActionEditorProps extends ActionEditorProps<UpdateEntityAction>
{

}

const UpdateEntityActionEditor: React.FC<UpdateEntityActionEditorProps> =
    props =>
    {
        const parameters =
            useComputed(
                () =>
                    Array.from(props.context.parameterDictionary.parameterById.values())
                        .filter(
                            parameter =>
                                parameter.type instanceof EntityValueType),
                [
                    props.context
                ]);

        const setParameter =
            useCallback(
                (parameter: Parameter<EntityValueType>) =>
                    runInAction(
                        () =>
                            props.action.parameter = parameter),
                [
                    props.action
                ]);

        const context =
            useComputed(
                () =>
                    props.action.parameter &&
                        EntityContext.fromEntityType(props.action.parameter.type.type),
                [
                    props.action
                ]);

        const setFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                            props.action.fieldPath = fieldPath),
                [
                    props.action
                ]);

        const setValue =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.action.value = value),
                [
                    props.action
                ]);

        const valueType =
            useComputed(
                () =>
                {
                    if (props.action.fieldPath)
                    {
                        if (props.action.fieldPath.isField)
                        {
                            return new PrimitiveValueType(props.action.fieldPath.field.dataObjectSpecification.type);
                        }
                        else
                        {
                            return new EntityValueType(props.action.fieldPath.path.entityType);
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.action
                ]);

        if (!props.action.parameter)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <CardInset
                        vertical={false}
                    >
                        <LocalizedText
                            code="AutomationEditor.MutateWhichRecord"
                            value="Welk record wil je muteren?"
                        />
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Divider />
                    <Menu>
                        {
                            parameters.map(
                                parameter =>
                                    <Item
                                        key={parameter.id}
                                        name={parameter.name}
                                        onClick={() => setParameter(parameter)}
                                    />)
                        }
                    </Menu>
                </ViewGroupItem>
            </ViewGroup>;
        }
        else if (!props.action.fieldPath)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <CardInset
                        vertical={false}
                    >
                        <LocalizedText
                            code="AutomationEditor.MutateWhichField"
                            value="Welk veld wil je muteren?"
                        />
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Divider />
                    <FieldPathSelector
                        context={context}
                        onSelect={setFieldPath}
                    />
                </ViewGroupItem>
            </ViewGroup>;
        }
        else
        {
            return <CardInset
                top={false}
            >
                <Input
                    label={`${props.action.fieldPath.path.nodes.length > 1 ? props.action.fieldPath.path.getName(undefined, false, true) : ''} ${props.action.fieldPath.getName()}`}
                    labelPosition="left"
                >
                    <ComputationEditor
                        context={props.context}
                        type={valueType}
                        value={props.action.value}
                        onChange={setValue}
                    />
                </Input>
            </CardInset>;
        }
    };

export default observer(UpdateEntityActionEditor);
