import React from 'react';
import { observer } from 'mobx-react-lite';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import Popper from '../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import Index from './Index';

export interface IndicesLink
{
    count: number;
    indices: number[];
    errorSourceInstanceIds?: string[];
    onSelect: (index: number) => void;
}

const ImportSummary: React.FC<IndicesLink> =
    props =>
    {
        const [ isOpen, open, close ] = useSwitch(false);

        if (props.indices && props.indices.length > 0)
        {
            return <Popper
                reference={
                    <Link
                        onClick={open}
                        highlighted
                    >
                        {props.count}
                    </Link>
                }
                popper={
                    <Card>
                        <Menu>
                            {
                                props.indices.map(
                                    (index, idx)  =>
                                        <Index
                                            key={idx}
                                            index={props.indices[idx]}
                                            sourceInstanceId={props.errorSourceInstanceIds[idx]}
                                            onSelect={props.onSelect}
                                        />)
                            }
                        </Menu>
                    </Card>
                }
                open={isOpen}
                onClose={close}
            />;
        }
        else
        {
            return <>{props.count}</>;
        }
    };

export default observer(ImportSummary);
