import React, { useContext, useMemo } from 'react';
import useTypes from '../../../../../Domain/Entity/Type/Api/useTypes';
import CurrentUserContext from '../../../../../Domain/User/CurrentUserContext';
import { observer } from 'mobx-react';
import LocalizedText from '../../../../../Domain/Localization/LocalizedText/LocalizedText';
import useSwitch from '../../../../../../@Util/Switch/useSwitch';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import CloseIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/CloseIconButton/CloseIconButton';
import EnvironmentButton from '../Header/EnvironmentButton/EnvironmentButton';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';

export interface BlockCheckerProps
{

}

const BlockChecker: React.FC<BlockCheckerProps> =
    ({
        children,
     }) =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();
        const blockDate =
            useEntityValue<Date>(
                currentUserStore.contractEntity,
                types.LicenseContract.Field.BlockDate
            );
        const endDate =
            useEntityValue<Date>(
                currentUserStore.contractEntity,
                types.LicenseContract.Field.EndDate
            );
        const cooldownPeriodEndDate =
            useEntityValue<Date>(
                currentUserStore.contractEntity,
                types.LicenseContract.Field.CooldownPeriodEndDate
            );
        const now =
            useMemo(
                () =>
                    new Date(),
                []
            );
        const isEnvironmentBlocked =
            useMemo(
                () =>
                    (blockDate ? blockDate <= now : false)
                    || (cooldownPeriodEndDate ? cooldownPeriodEndDate <= now : false),
                [
                    now,
                    blockDate,
                    cooldownPeriodEndDate,
                ]
            );
        const isUserBlocked =
            useMemo(
                () =>
                    currentUserStore.currentUser.isBlocked,
                [
                    currentUserStore,
                ]
            );
        const [ isMuted, mute ] = useSwitch(false);

        if (isEnvironmentBlocked && !isMuted)
        {
            return <>
                <Centered
                    horizontal
                    vertical
                >
                    <Card>
                        <CardInset>
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <CardHeader>
                                                <LocalizedText
                                                    code="Contract.EnvironmentBlocked"
                                                    value="Deze omgeving is geblokkeerd"
                                                />
                                            </CardHeader>
                                        </ViewGroupItem>
                                        {
                                            currentUserStore.isSupport &&
                                            <ViewGroupItem>
                                                <CloseIconButton
                                                    onClick={mute}
                                                />
                                            </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="Contract.ContactUsToUnblock"
                                        value="Neem contact met ons op om de blokkade op te heffen."
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </Card>
                </Centered>
                <CardInset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem
                            ratio={1}
                        />
                        <ViewGroupItem>
                            <EnvironmentButton />
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </>;
        }
        else if (isUserBlocked && !isMuted)
        {
            return <>
                <Centered
                    horizontal
                    vertical
                >
                    <Card>
                        <CardInset>
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                        alignment="center"
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <CardHeader>
                                                <LocalizedText
                                                    code="User.Blocked"
                                                    value="De toegang tot de omgeving is (tijdelijk) geblokkeerd"
                                                />
                                            </CardHeader>
                                        </ViewGroupItem>
                                        {
                                            currentUserStore.isAdministrator &&
                                            <ViewGroupItem>
                                                <CloseIconButton
                                                    onClick={mute}
                                                />
                                            </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="User.ContactAdminToUnblock"
                                        value="Neem contact op met de beheerder van de omgeving om weer toegang te krijgen."
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </Card>
                </Centered>
                <CardInset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem
                            ratio={1}
                        />
                        <ViewGroupItem>
                            <EnvironmentButton />
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </>;
        }
        else
        {
            return <>
                {children}
            </>;
        }
    };

export default observer(BlockChecker);
