import React from 'react';
import { observer } from 'mobx-react-lite';

export interface ToolbarButtonWrapperProps
{
}

const ToolbarButtonWrapper: React.FC<ToolbarButtonWrapperProps> =
    props =>
    {
        return <div
            style={{
                display: 'inline-block'
            }}
        >
            {props.children}
        </div>;
    };

export default observer(ToolbarButtonWrapper);
