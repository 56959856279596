import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ActivityListItem from '../../Model/ActivityListItem';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from '../../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { runInAction } from 'mobx';
import { Draggable as DraggableDnd } from 'react-beautiful-dnd';
import DragHandlePropsContext from '../../../../../../../DragAndDrop/Context/DragHandlePropsContext';
import DraggableStateContext from '../../../../../../../DragAndDrop/Context/DraggableStateContext';
import DragHandle from '../../../../../../../DragAndDrop/DragHandle/DragHandle';

export interface ActivityListEditorProps
{
    item: ActivityListItem;
    idx: number;
}

const ActivityListItemEditor: React.FC<ActivityListEditorProps> =
    props =>
    {
        const { item, idx } = props;

        const setConstructable =
            useCallback(
                (isConstructable: boolean) =>
                    runInAction(
                        () =>
                            item.isConstructable = isConstructable),
                [
                    item
                ]);

        const setConstructorInMoreMenu =
            useCallback(
                (isConstructorInMoreMenu: boolean) =>
                    runInAction(
                        () =>
                            item.isConstructorInMoreMenu = isConstructorInMoreMenu),
                [
                    item
                ]);

        const { path, isConstructable, isConstructorInMoreMenu } = item;

        return <DraggableDnd
            draggableId={item.id}
            index={idx}
        >
            {
                (provided, snapshot) =>
                    <DragHandlePropsContext.Provider
                        value={provided.dragHandleProps}
                    >
                        <DraggableStateContext.Provider
                            value={snapshot}
                        >
                            <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={provided.draggableProps.style}
                            >
                                <TableCell>
                                    <DragHandle />
                                </TableCell>
                                <TableCell>
                                    {path.getName()}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={isConstructable}
                                        onToggle={setConstructable}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={isConstructorInMoreMenu}
                                        onToggle={setConstructorInMoreMenu}
                                        disabled={!isConstructable}
                                    />
                                </TableCell>
                            </TableRow>
                        </DraggableStateContext.Provider>
                    </DragHandlePropsContext.Provider>
            }
        </DraggableDnd>;
    };

export default observer(ActivityListItemEditor);
