import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import Parameter from '../../Parameter/Parameter';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import EmptyValue from '../../Value/EmptyValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';
import DataSourceValue from '../../Value/DataSourceValue';
import DataSourceValueType from '../../Value/Type/DataSourceValueType';

export default class ValueFromDataSourceValueComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;
    @observable.ref parameter: Parameter<any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>,
                parameter: Parameter<any>)
    {
        super();

        this.value = value;
        this.parameter = parameter;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.parameter.getType();
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const dataSourceValue = await safelyApplyFunction(this.value, context);

        return this.getValueFromDataSourceValue(dataSourceValue);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const dataSourceValue = safelySynchronousApplyFunction(this.value, context);

        return this.getValueFromDataSourceValue(dataSourceValue);
    }

    getValueFromDataSourceValue(value: Value<any, any>)
    {
        if (value instanceof DataSourceValue)
        {
            return value.value.valueParameterAssignment.getValue(this.parameter) ?? EmptyValue.instance;
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    getName(): string
    {
        return `${this.value?.getName() || '...'}: ${this.parameter?.name || localizeText('Computation.ChooseField', 'kies een veld')}`;
    }

    validate(): Validation[]
    {
        if (!(this.value?.getType() instanceof DataSourceValueType))
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.ValueFromDataSourceValue.InvalidType', 'Er is geen geldige databronwaarde geselecteerd.'))
            ];
        }
        else if (!this.parameter)
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.ValueFromDataSourceValue.MissingParameter', 'Er is geen veld geselecteerd.'))
            ];
        }
        else
        {
            return [];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'ValueFromDataSourceValue';
        descriptor.value = this.value.toDescriptor();
        descriptor.parameterId = this.parameter.id;
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const value =
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext);
        const valueType = value.getType();

        if (valueType instanceof DataSourceValueType)
        {
            const parameter =
                valueType.dataSourceSignature.resultParameters.getParameterById(
                    descriptor.parameterId);

            return new ValueFromDataSourceValueComputation(
                value,
                parameter);
        }
        else
        {
            throw new Error(`Expected data source value type, but got: ${valueType.id()}`)
        }
    }

    // ----------------------- Private logic ------------------------
}
