// https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
export default function areSetsEqual(as: Set<any>, bs: Set<any>)
{
    if (as.size !== bs.size)
    {
        return false;
    }

    return Array.from(as).every(a => bs.has(a));
}
