import { EntityType } from '../../Model/Implementation/EntityType';
import { Entity } from '../../Model/Implementation/Entity';
import { DisposableValue } from '../../../@Util/Disposable/DisposableValue';
import { internallyGetEntitiesByIds } from './internallyGetEntitiesByIds';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import { EntitySelectionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';

export default async function getEntitiesByUuids(
    entityType: EntityType,
    ids: string[],
    callback?: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void
): Promise<DisposableValue<Entity[]>>
{
    return internallyGetEntitiesByIds(
        entityType,
        ids,
        getTypes().Entity.Field.Uuid,
        callback
    );
}
