import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import equalsEntity from '../../../../@Api/Entity/Bespoke/equalsEntity';
import { EntityTypeStore } from '../../Entity/Type/EntityTypeStore';

export default function isOwnEntity(entity: Entity)
{
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return equalsEntity(
        entity.getRelatedEntityByDefinition(
            true,
            types.Pack.RelationshipDefinition.Entities),
        currentUserStore.environmentPackEntity);
}
