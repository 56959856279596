import convertBlobToFile from './convertBlobToFile';

export default function convertDataUrlToFile(dataUrl: string,
                                             fileName: string)
{
    let splittedUrl = dataUrl.split(',');
    let mimeType = splittedUrl[0].match(/:(.*?);/)[1];
    let binaryData = atob(splittedUrl[1]);
    let fileSize = binaryData.length;
    let uint8array = new Uint8Array(fileSize);

    while (fileSize--)
    {
        uint8array[fileSize] = binaryData.charCodeAt(fileSize);
    }

    return convertBlobToFile(
        new Blob([uint8array], { type: mimeType }),
        fileName);
}
