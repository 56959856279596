import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Type/Api/getTypes';
import { constructEntityOfType } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { getStateByNameAndCountry } from './getStateByNameAndCountry';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export async function getOrCreateStateByNameAndCountry(
    name: string,
    country: Entity,
    commitContext: CommitContext
): Promise<Entity>
{
    const types = getTypes();
    const foundState =
        await getStateByNameAndCountry(
            name,
            country
        );

    if (foundState)
    {
        return foundState;
    }

    const newState =
        constructEntityOfType(
            types.Datastore.State.Type,
            commitContext
        );

    updateRelationship(
        newState,
        true,
        types.Datastore.Country.RelationshipDefinition.States,
        country,
        commitContext,
    );

    newState
        .setName(
            name,
            commitContext
        );

    return newState;
}