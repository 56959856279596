import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import DeleteIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import uuid from '../../../../../../../../@Util/Id/uuid';
import { PortalTableOrdering } from '../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableOrdering';
import PortalTableLayout from '../../../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import { PortalTableColumn } from '../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';
import { mapBy } from '../../../../../../../../@Util/MapUtils/mapBy';

export interface PortalTableOrderingsEditorProps
{
    layout: PortalTableLayout;
}

const PortalTableOrderingsEditor: React.FC<PortalTableOrderingsEditorProps> =
    ({
        layout,
     }) =>
    {
        const addOrdering =
            useCallback(
                (column: PortalTableColumn) =>
                    runInAction(
                        () =>
                            layout.orderings = [
                                ...layout.orderings,
                                new PortalTableOrdering(
                                    uuid(),
                                    column.id,
                                    'Descending'
                                )
                            ]
                    ),
                [
                    layout,
                ]
            );
        const removeOrdering =
            useCallback(
                (ordering: PortalTableOrdering) =>
                    runInAction(
                        () =>
                            layout.orderings =
                                layout.orderings.filter(
                                    checkOrdering =>
                                        checkOrdering !== ordering
                                )
                    ),
                [
                    layout,
                ]
            );
        const columnById =
            useComputed(
                () =>
                    mapBy(
                        layout.columns,
                        column =>
                            column.id
                    ),
                [
                    layout,
                ]
            );

        return <ol>
            {
                layout.orderings
                    .map(
                        ordering =>
                            <li
                                key={ordering.id}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    alignment="center"
                                    spacing={15}
                                >
                                    <ViewGroupItem
                                        ratio={1}
                                    >
                                        {columnById.get(ordering.columnId).name.getName()}
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            labelPosition="right"
                                            label={
                                                <LocalizedText
                                                    code="View.Column.SortDescending"
                                                    value="Sorteer aflopend"
                                                />
                                            }
                                        >
                                            <Switch
                                                checked={ordering.direction === 'Descending'}
                                                onToggle={
                                                    isDescending =>
                                                        runInAction(
                                                            () =>
                                                                ordering.direction = isDescending ? 'Descending' : 'Ascending')
                                                }
                                            />
                                        </Input>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <DeleteIconButton
                                            onClick={
                                                () =>
                                                    removeOrdering(ordering)
                                            }
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </li>)
            }
            <li>
                <MenuButton
                    icon="add"
                    tooltip={
                        <LocalizedText
                            code="Generic.Add"
                            value="Toevoegen"
                        />
                    }
                    small
                >
                    <Menu>
                        {
                            layout.columns.map(
                                column =>
                                    <Item
                                        key={column.id}
                                        name={column.name.getName()}
                                        onClick={
                                            () =>
                                                addOrdering(column)
                                        }
                                    />)
                        }
                    </Menu>
                </MenuButton>
            </li>
        </ol>;
    };

export default observer(PortalTableOrderingsEditor);
