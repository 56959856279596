import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { ViewComponent } from './Model/ViewComponent';
import { ComponentClass } from 'react';

interface ViewComponentProps
{
    attemptToClose?: (view: ViewComponent) => boolean;
}

type ViewComponentPropsType = any & ViewComponentProps;
type ViewComponentType = ViewComponent<any, ViewComponentPropsType>;

export class ViewStackStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable viewStack = observable.array<ViewComponentType>();

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get currentView(): ViewComponentType
    {
        if (this.hasViews)
        {
            return this.viewStack[this.viewStack.length - 1];
        }
        else
        {
            return null;
        }
    }

    @computed
    get hasViews()
    {
        return this.viewStack.length > 0;
    }

    @computed
    get isVisible()
    {
        return this.hasViews;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action
    pushView(view: ViewComponentType)
    {
        this.viewStack.push(view);
    }

    @action
    popView(view: ViewComponentType = this.currentView)
    {
        if (!view.props || !view.props.attemptToClose || view.props.attemptToClose(view))
        {
            this.viewStack.remove(view);
        }
    }

    @action
    popViewByStore(store: BaseStore)
    {
        const components =
            this.viewStack.filter(
                item =>
                    item.store === store);

        components.forEach(
            component =>
                this.popView(component));
    }

    @action
    clear(component?: ComponentClass,
          filter?: (view: ViewComponentType) => boolean)
    {
        if (component)
        {
            this.viewStack
                .filter(
                    view =>
                        view.component !== component)
                .forEach(
                    view =>
                        this.popView(view));
        }
        else if (filter)
        {
            this.viewStack
                .filter(
                    view =>
                        filter(view))
                .forEach(
                    view =>
                        this.popView(view));
        }
        else
        {
            this.viewStack.forEach(
                view =>
                    this.popView(view));
        }
    }

    // ------------------------ Public logic ------------------------

    hasViewStore(store: any)
    {
        return this.viewStack.some(view => view.store === store);
    }

    // ----------------------- Private logic ------------------------
}
