import { observable } from 'mobx';

const failedImages = observable.map<string, boolean>();

export function isUnloadableSrc(src?: string)
{
    if (src)
    {
        return failedImages.has(src);
    }
    else
    {
        return true;
    }
}

export function reportUnloadableSrc(src: string)
{
    failedImages.set(src, true);
}
