import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import LocalizedLayoutItem from './LocalizedLayoutItem';
import localizeText from '../../Localization/localizeText';
import Computation from '../../Automation/Function/Computation/Computation';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';

export default class LocalizedLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.shallow items: LocalizedLayoutItem[];

    @observable.ref language?: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(
        items: LocalizedLayoutItem[],
        language?: Computation<any, any>
    )
    {
        super();

        this.items = items;
        this.language = language;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Localized', 'Gelokaliseerde layout');
    }

    validate(): Validation[]
    {
        return this.items
            .map(
                item =>
                    item.validate()
            )
            .concat(
                this.language
                    ? this.language.validate()
                    : []
            )
            .reduce((a, b) => a.concat(b), []);
    }

    getDependencies(): Dependency[]
    {
        const dependencies = this.items
            .map(
                item =>
                    item.getDependencies())
            .reduce((a, b) => a.concat(b), []);

        if (this.language)
        {
            return dependencies.concat(this.language.getDependencies());
        }
        else
        {
            return dependencies;
        }
    }

    toDescriptor()
    {
        return {
            type: 'Localized',
            items: this.items.map(item => item.toDescriptor()),
            language: this.language?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new LocalizedLayout(
            await Promise.all(
                descriptor.items.map(
                    item =>
                        LocalizedLayoutItem.fromDescriptor(
                            item,
                            dependencyContext)
                )
            ),
            descriptor.language
                ? await getComputationFromDescriptor(
                    descriptor.language,
                    dependencyContext
                )
                : undefined
        );
    }

    // ----------------------- Private logic ------------------------
}
