import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import { default as AutomationModel } from '../../../../../../@Api/Automation/Automation';
import AutomationDependencyContext from '../../../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import useFetchedRelatedEntity from '../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import SuccessButton from '../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import CronTrigger from '../../../../../../@Api/Automation/Trigger/CronTrigger';
import performAction from '../../../../../../@Api/Entity/performAction';
import useResult from '../../../Selection/Hooks/useResult';
import AutomationActivationSwitch from '../../../../Configuration/Page/Automations/AutomationManager/AutomationActivationToggle/AutomationActivationSwitch';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { usePackSelector } from '../../../../Configuration/Page/Packs/MySharedPackManager/Api/usePackSelector';
import { SharePackEditor } from '../../../../Configuration/Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';
import { default as EntityInput } from '../../../Input/Input';
import { LogViewer } from '../../../../LogViewer/LogViewer';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import uuid from '../../../../../../@Util/Id/uuid';
import ActivityList from '../Activity/RelatedActivityList/Model/ActivityList';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import ActivityListItem from '../Activity/RelatedActivityList/Model/ActivityListItem';
import RelatedActivityListRoot from '../Activity/RelatedActivityList/RelatedActivityListRoot';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { AutomationDesignTab } from './Tabs/AutomationDesignTab';
import { AutomationMutationLogTab } from './Tabs/AutomationMutationLogTab';
import { useParameterDictionary } from './Api/useParameterDictionary';

export interface AutomationProps
{
    entity: Entity;
    commitContext: CommitContext;
    isReadOnly?: boolean;
}

const Automation: React.FC<AutomationProps> =
    ({
        entity,
        commitContext,
        isReadOnly
     }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const [ automation, setAutomation ] = useState<AutomationModel>();
        const  parameterDictionary  = useParameterDictionary(entity);
        const [ isLoadingAutomation, setLoadingAutomation ] = useState(true);

        const [ entityTypeEntity, isLoadingEntityType ] =
            useFetchedRelatedEntity(
                entity,
                types.EntityType.RelationshipDefinition.Automations,
                true
            );

        useEffect(
            () =>
            {
                const specification = entity.getObjectValueByField(types.Automation.Field.Specification);

                if (specification)
                {
                    setLoadingAutomation(true);

                    AutomationModel.fromDescriptor(
                        specification,
                        new AutomationDependencyContext(
                            new ParameterDictionary(
                                Array.from(parameterDictionary.parameters))))
                        .then(setAutomation)
                        .finally(() => setLoadingAutomation(false));
                }
                else
                {
                    setLoadingAutomation(false);
                }

            },
            [
                entity,
                types,
                parameterDictionary,
                setLoadingAutomation
            ]);

        const validations =
            useComputed(
                () =>
                {
                    if (automation)
                    {
                        return automation.validate();
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    automation
                ]);

        const [ activation, isLoadingActivation ] =
            useResult(
                types.AutomationActivation.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.Automation.RelationshipDefinition.Activations,
                                            true),
                                    entity))
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        ob
                                            .isNotDefined(
                                                rootPath
                                                    .joinTo(
                                                        types.Automation.RelationshipDefinition.Activations,
                                                        true)
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Automations,
                                                        true)
                                                    .field(types.Entity.Field.Id),
                                                undefined)
                                            .relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Automation.RelationshipDefinition.Activations,
                                                        true)
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Automations,
                                                        true),
                                                currentUserStore.employeeEntity)))
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        ob
                                            .relatedToEntity(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.AutomationActivations,
                                                        true),
                                                currentUserStore.employeeEntity)
                                            .isNotDefined(
                                                rootPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.AutomationActivations,
                                                        true)
                                                    .field(types.Entity.Field.Id)))),
                [
                    types,
                    currentUserStore,
                    entity,
                ]);

        const isDeactivated =
            useEntityValue(
                activation,
                types.AutomationActivation.Field.IsDeactivated,
                false
            );

        const test =
            useCallback(
                () =>
                    performAction(
                        entity,
                        {
                            code: 'Automation.Test',
                            name: localizeText('AutomationTestAction', 'Automation test')
                        }),
                [
                    entity
                ]);

        const save =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            types.Automation.Field.Specification,
                            automation.toDescriptor()
                        )
                        .commit(),
                [
                    entity,
                    types,
                    automation,
                ]);

        const isEditable =
            useComputed(
                () =>
                    currentUserStore.rightProfile.isAllowedToMutate(entity),
                [
                    currentUserStore,
                    entity
                ]);

        const [ tab, setTab ] = useState<string>('design');
        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(entity);

        const relatedActivityPaths =
            useComputed(
                () => [
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Attachments,
                            false),
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Notes,
                            false)
                ],
                [
                    entity,
                    types
                ]);

        const activityList =
            useMemo(
                () =>
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(entity.entityType),
                            true,
                            entity.entityType.getName()),
                        relatedActivityPaths.map(
                            path =>
                                new ActivityListItem(
                                    new Parameter(
                                        uuid(),
                                        new EntityValueType(path.entityType),
                                        true,
                                        path.entityType.getName()),
                                    path,
                                    true,
                                    false))),
                [
                    entity,
                    relatedActivityPaths
                ]);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <TypeAndName
                                                        entity={entity}
                                                    />
                                                </ViewGroupItem>
                                                {
                                                    !isLoadingActivation &&
                                                    <ViewGroupItem>
                                                        <Input
                                                            labelPosition="right"
                                                            label={
                                                                <LocalizedText
                                                                    code="Generic.Activated"
                                                                    value="Geactiveerd"
                                                                />
                                                            }
                                                        >
                                                            <AutomationActivationSwitch
                                                                automation={entity}
                                                                activation={activation}
                                                            />
                                                        </Input>
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    automation?.trigger instanceof CronTrigger &&
                                                    <ViewGroupItem>
                                                        <SuccessButton
                                                            label={
                                                                <LocalizedText
                                                                    code="Generic.Testing"
                                                                    value="Testen"
                                                                />
                                                            }
                                                            onClick={test}
                                                            disabled={isLoadingEntityType || isLoadingAutomation || validations.length > 0}
                                                        />
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    isEditable &&
                                                        <ViewGroupItem>
                                                            <SaveButton
                                                                onClick={save}
                                                                disabled={!automation || isLoadingEntityType || isLoadingAutomation || validations.length > 0}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                <ViewGroupItem>
                                                    <MenuButton
                                                        entity={entity}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <EntityInput
                                                entity={entity}
                                                field={types.Automation.Field.Description}
                                                labelPosition="top"
                                                commitContext={commitContext}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                {
                                    isPackSelectorVisible &&
                                    <CardInset>
                                        <ViewGroup
                                            orientation="vertical"
                                            spacing={15}
                                            alignment="start"
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <SharePackEditor
                                                    entity={entity}
                                                    ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <TabBar
                            value={tab}
                        >
                            <Tab
                                value="design"
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Design"
                                    value="Ontwerp"
                                />
                            </Tab>
                            <Tab
                                value="timeline"
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Timeline"
                                    value="Tijdlijn"
                                />
                            </Tab>
                            <Tab
                                value="logs"
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Generic.Logs"
                                    value="Logs"
                                />
                            </Tab>
                            <Tab
                                value="mutations"
                                onClick={setTab}
                            >
                                <LocalizedText
                                    code="Automation.MutationHistory"
                                    value="Wijzigingshistorie"
                                />
                            </Tab>
                        </TabBar>
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        {
                            tab === 'design' &&
                            <AutomationDesignTab
                                entity={entity}
                                entityTypeEntity={entityTypeEntity}
                                automation={automation}
                                setAutomation={setAutomation}
                                parameterDictionary={parameterDictionary}
                                validations={validations}
                                isEditable={isEditable}
                                isReadOnly={isReadOnly}
                                isLoading={isLoadingAutomation || isLoadingEntityType}
                            />

                        }
                        {
                            tab === 'timeline' &&
                            <ViewGroupItem>
                                <RelatedActivityListRoot
                                    entity={entity}
                                    list={activityList}
                                    isInEditMode={false}
                                    onChangeEditMode={() => {}}
                                    showCreationItem
                                />
                            </ViewGroupItem>
                        }
                        {
                            activation && tab === 'logs' &&
                            <ViewGroupItem>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Card>
                                        <LogViewer
                                            name={`automationActivation/${activation.uuid}`}
                                            isNotLive={isDeactivated}
                                        />
                                    </Card>
                                </Grid>
                            </ViewGroupItem>
                        }
                        {
                            tab === 'mutations' &&
                            <ViewGroupItem>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Card>
                                        <AutomationMutationLogTab
                                            entity={entity}
                                        />
                                    </Card>
                                </Grid>
                            </ViewGroupItem>
                        }
                    </ViewGroup>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Automation);
