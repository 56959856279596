import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { Aggregate } from '../../../Model/Aggregate';
import { Comparator } from '../../../Model/Comparator';
import { DataObject } from '../../../Model/DataObject';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DateRangeEditor } from './DateRangeEditor';
import { DateRangeSpecification } from './DateRangeSpecification';
import { DateRangeValue } from './DateRangeValue';
import { DateRangeView } from './DateRangeView';
import moment from 'moment';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { computed, toJS } from 'mobx';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { DataObjectOverloadType } from '../../../Model/DataObjectOverloadType';
import { DateType } from '../DateType';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { DataObjectStore } from '../../../DataObjectStore';
import { DateRangeEditorStore } from './DateRangeEditorStore';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface DateRangeValueType
{
    value: string;
    label: string;
    interval: (fromDate?: Date, toDate?: Date) => string;
}

export function types(): DateRangeValueType[]
{
    return [
        { value: 'Month', label: localizeText('DatePeriod.Singular.Month', 'Maand'), interval: () => 'Daily' },
        { value: 'Quarter', label: localizeText('DatePeriod.Singular.Quarter', 'Kwartaal'), interval: () => 'Monthly' },
        { value: 'Year', label: localizeText('DatePeriod.Singular.Year', 'Jaar'), interval: () => 'Monthly' },
        { value: 'Today', label: localizeText('DateRange.Today', 'Vandaag'), interval: () => 'Daily' },
        { value: 'TodayOrEarlier', label: localizeText('DateRange.TodayOrEarlier', 'Vandaag of eerder'), interval: () => 'Monthly' },
        { value: 'CurrentWeek', label: localizeText('DateRange.ThisWeek', 'Deze week'), interval: () => 'Daily'},
        { value: 'CurrentWeekOrEarlier', label: localizeText('DateRange.ThisWeekOrEarlier', 'Deze week of eerder'), interval: () => 'Weekly' },
        { value: 'LastWeek', label: localizeText('DateRange.LastWeek', 'Vorige week'), interval: () => 'Daily' },
        { value: 'NextWeek', label: localizeText('DateRange.NextWeek', 'Volgende week'), interval: () => 'Daily' },
        { value: 'CurrentMonth', label: localizeText('DateRange.ThisMonth', 'Deze maand'), interval: () => 'Daily' },
        { value: 'LastMonth', label: localizeText('DateRange.LastMonth', 'Vorige maand'), interval: () => 'Daily' },
        { value: 'NextMonth', label: localizeText('DateRange.NextMonth', 'Volgende maand'), interval: () => 'Daily' },
        { value: 'CurrentQuarter', label: localizeText('DateRange.ThisQuarter', 'Dit kwartaal'), interval: () => 'Monthly' },
        { value: 'LastQuarter', label: localizeText('DateRange.LastQuarter', 'Vorig kwartaal'), interval: () => 'Monthly' },
        { value: 'NextQuarter', label: localizeText('DateRange.NextQuarter', 'Volgend kwartaal'), interval: () => 'Monthly' },
        { value: 'CurrentYear', label: localizeText('DateRange.ThisYear', 'Dit jaar'), interval: () => 'Monthly' },
        { value: 'LastYear', label: localizeText('DateRange.LastYear', 'Vorig jaar'), interval: () => 'Monthly' },
        { value: 'NextYear', label: localizeText('DateRange.NextYear', 'Volgend jaar'), interval: () => 'Monthly' },
        { value: 'DateRange', label: localizeText('Generic.DatePeriod', 'Aangepast...'), interval: () => 'Monthly' },
        { value: 'DateTimeRange', label: localizeText('Generic.DateTimePeriod', 'Aangepast (met tijd)...'), interval: () => 'Monthly' },
    ];
}

export class DateRangeType implements DataObjectType<DateRangeValue>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    @computed
    get typeById(): Map<string, DateRangeValueType>
    {
        let typeById = new Map<string, DateRangeValueType>();

        types().forEach(
            type => typeById.set(type.value, type));

        return typeById;
    }

    id(): string
    {
        return 'DateRange';
    }

    name()
    {
        return localizeText('DataObject.Type.DateRange', 'Datumbereik');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.In
            },
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return DateRangeView;
    }

    editor(): DataObjectEditorType
    {
        return DateRangeEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new DateRangeEditorStore(editorStore);
    }

    specification(): DataObjectSpecificationType
    {
        return DateRangeSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: DateRangeValue): boolean
    {
        return false;
    }

    getDataFromValue(value: DateRangeValue, specification: DataObjectSpecification): DataDescriptor
    {
        const dateRangeType = this.typeById.get(value.type);
        const isDateTimeRange = (dateRangeType?.value === 'DateTimeRange');

        let data = new DataDescriptor();
        data.complex = value;

        if (isDateTimeRange)
        {
            data.dateTime1 = value.from ? value.from : undefined;
            data.dateTime2 = value.to ? value.to : undefined;
        }
        else
        {
            data.date1 = value.from ? value.from : undefined;
            data.date2 = value.to ? value.to : undefined;
        }

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): DateRangeValue
    {
        return {
            type: data.complex && data.complex.type,
            from: data.date1 || data.dateTime1,
            to: data.date2 || data.dateTime2
        };
    }

    valueId(value: DateRangeValue): string
    {
        return JSON.stringify(toJS(value));
    }

    getString(value: DateRangeValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        if (representation.data.interval)
        {
            let parentInterval: DataObject = representation.data.parentInterval;
            let intervalValue = representation.data.interval.getValue();

            if (value.from && intervalValue)
            {
                if (parentInterval && !parentInterval.isEmpty)
                {
                    switch (intervalValue.type)
                    {
                        case 'Yearly':
                            return moment(value.from).format('YYYY');

                        case 'HalfYearly':
                            return `${moment(value.from).get('month') < 6 ? 'H1' : 'H2'}`;

                        case 'Quarterly':
                            return `Q${moment(value.from).format('Q')}`;

                        case 'Monthly':
                            return `${moment(value.from).format('MMMM')}`;

                        case 'FourWeekly':
                        case 'Weekly':
                            return `Week ${moment(value.from).week().toString()}`;

                        case 'Daily':
                            return `${moment(value.from).format('Do')} (${moment(value.from).format('dd').toLowerCase()})`;

                        default:
                            break;
                    }
                }
                else
                {
                    switch (intervalValue.type)
                    {
                        case 'Yearly':
                            return moment(value.from).format('YYYY');

                        case 'HalfYearly':
                            return `${moment(value.from).format('YYYY')} - ${moment(value.from).get('month') < 6 ? 'H1' : 'H2'}`;

                        case 'Quarterly':
                            return `${moment(value.from).format('YYYY')} - Q${moment(value.from).format('Q')}`;

                        case 'Monthly':

                            if (representation.data.isCompact)
                            {
                                return moment(value.from).format('MMM');
                            }
                            else
                            {
                                return `${moment(value.from).format('YYYY')} - ${moment(value.from).format('MMMM')}`;
                            }

                        case 'FourWeekly':
                        case 'Weekly':
                            return `${moment(value.from).format('YYYY')} - Week ${moment(value.from).week().toString()}`;

                        case 'Daily':
                            return `${moment(value.from).format('L')}`;

                        default:
                            break;
                    }
                }
            }
        }

        if (value.type)
        {
            const dateRangeType = this.typeById.get(value.type);

            if (dateRangeType)
            {
                if (dateRangeType.value === 'Month')
                {
                    return `${moment(value.from).format('MMMM YYYY')}`;
                }
                if (dateRangeType.value === 'Quarter')
                {
                    return `Q${moment(value.from).format('Q')} ${moment(value.from).format('YYYY')}`;
                }
                else if (dateRangeType.value === 'Year')
                {
                    return `${moment(value.from).format('YYYY')}`;
                }
                else if (dateRangeType.value === 'DateTimeRange')
                {
                    return `${moment(value.from).format('LT')} - ${moment(value.to).format('LT')}`;
                }
                else if (dateRangeType.value !== 'DateRange')
                {
                    return dateRangeType.label;
                }
            }
        }

        return `${value.from ? moment(value.from).format('L') : '∞'} tot ${value.to ? moment(value.to).format('L') : '∞'}`;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [
            new DataObjectComparatorOverload(
                this,
                Comparator.LessThan,
                DataObjectOverloadType.Lhs,
                type =>
                    type instanceof DateRangeType,
                (thisValue, otherValue, isThisLhs) =>
                {
                    if (isThisLhs)
                    {
                        return (thisValue?.value as DateRangeValue)?.from < (otherValue?.value as DateRangeValue)?.from;
                    }
                    else
                    {
                        return (thisValue?.value as DateRangeValue)?.from > (otherValue?.value as DateRangeValue)?.from;
                    }
                }),
            new DataObjectComparatorOverload(
                this,
                Comparator.GreaterThan,
                DataObjectOverloadType.Lhs,
                type =>
                    type instanceof DateRangeType,
                (thisValue, otherValue, isThisLhs) =>
                {
                    if (isThisLhs)
                    {
                        return (thisValue?.value as DateRangeValue)?.from > (otherValue?.value as DateRangeValue)?.from;
                    }
                    else
                    {
                        return (thisValue?.value as DateRangeValue)?.from < (otherValue?.value as DateRangeValue)?.from;
                    }
                }),
            new DataObjectComparatorOverload(
                this,
                Comparator.In,
                DataObjectOverloadType.Rhs,
                type =>
                    type instanceof DateType,
                (dateRange, date) =>
                {
                    const dateValue = date?.value;
                    const rangeValue: DateRangeValue = dateRange?.value;

                    if (rangeValue)
                    {
                        const c1 = rangeValue.from == null ? true : dateValue >= rangeValue.from;
                        const c2 = rangeValue.to == null ? true : dateValue < rangeValue.to;

                        return c1 && c2;
                    }
                    else
                    {
                        return true;
                    }
                })
        ];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }

}
