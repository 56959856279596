import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Default from '../Default';
import { ContentProps } from '../Content';
import CourseTemplateLessonCronPatternEditor from '../../../Viewer/Content/CourseTemplate/LessonCronPatternEditor/CourseTemplateLessonCronPatternEditor';

export interface CourseTemplateProps extends ContentProps
{

}

const CourseTemplate: React.FC<CourseTemplateProps> =
    props =>
    {
        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Default
                    {...props}
                    entity={props.entity}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <CourseTemplateLessonCronPatternEditor
                    entity={props.entity}
                    commitContext={props.commitContext}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(CourseTemplate);
