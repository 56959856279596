import { reference, type } from '../../../@Util/Serialization/Serialization';
import { observable } from 'mobx';
import { EntityType } from './EntityType';

@type('EntityTypeParameter')
export class EntityTypeParameter
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'EntityType') @observable.ref entityType: EntityType;
    @observable code: string;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
