import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../DataObject/Model/DataObject';
import Value from '../../../../../@Api/Automation/Value/Value';
import { AssistantBooleanValue } from '../../Model/Value/AssistantBooleanValue';

export function findAssistantBooleanValue(
    value: AssistantBooleanValue
): Value<any, any>
{
    return new PrimitiveValue(
        DataObject.constructFromTypeIdAndValue(
            'Boolean',
            value.value
        )
    );
}
