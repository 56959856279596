import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import DailyCronEditor from './DailyCronEditor';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import NumberEditor from '../../NumberEditor/NumberEditor';
import StaticSelectbox, { StaticOption } from '../../Selectbox/Static/StaticSelectbox';
import moment from 'moment';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface YearlyCronEditorProps extends CronEditorProps
{

}

const YearlyCronEditor: React.FC<YearlyCronEditorProps> =
    props =>
    {
        const { onChange } = props;

        const day =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[3]),
                [
                    props.value
                ]);

        const onChangeDay =
            useCallback(
                (day: number) =>
                {
                    const split = props.value.split(' ');
                    split.splice(
                        3,
                        1,
                        day.toString());

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        const month =
            useMemo(
                () =>
                    parseInt(props.value.split(' ')[4]),
                [
                    props.value
                ]);

        const onChangeMonth =
            useCallback(
                (month: number) =>
                {
                    const split = props.value.split(' ');
                    split.splice(
                        4,
                        1,
                        month.toString());

                    onChange(split.join(' '));
                },
                [
                    onChange,
                    props.value
                ]);

        const months =
            useMemo<StaticOption<number>[]>(
                () =>
                    new Array(12)
                        .fill(0)
                        .map((v, idx) => ({
                            id: idx.toString(),
                            label: moment().month(idx).format('MMMM'),
                            value: idx + 1
                        })),
                []);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <ViewGroup
                    orientation="horizontal"
                    spacing={5}
                    alignment="center"
                >
                    <ViewGroupItem>
                        <LocalizedText
                            code="CronEditor.Day"
                            value="Dag"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <NumberEditor
                            value={day}
                            onChange={onChangeDay}
                            min={1}
                            max={31}
                            fitContent
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <LocalizedText
                            code="CronEditor.Of"
                            value="van"
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <StaticSelectbox
                            options={months}
                            value={month}
                            onChange={onChangeMonth}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </ViewGroupItem>
            <ViewGroupItem>
                <DailyCronEditor
                    {...props}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(YearlyCronEditor);
