import useApiClient from './useApiClient';
import useAsyncResult, { AsyncResult } from '../../../@Util/Async/useAsyncResult';
import { useCallback, useEffect } from 'react';
import { fetchApiFile, FetchApiFileResult } from './fetchApiFile';

export function useFetchedApiFile(
    url: string | undefined,
    isCached?: boolean
): AsyncResult<string | undefined>
{
    const apiClient = useApiClient();
    const [ result, isLoading ] =
        useAsyncResult<FetchApiFileResult | undefined>(
            async () =>
            {
                if (url)
                {
                    return fetchApiFile(
                        apiClient,
                        url,
                        isCached
                    );
                }
                else
                {
                    return undefined;
                }
            },
            [
                url,
                apiClient,
                isCached
            ]
        );
    const illegalSetter =
        useCallback(
            () =>
            {
                throw new Error(`Setter not supported`);
            },
            []
        );

    useEffect(
        () =>
            () =>
            {
                if (result)
                {
                    result.dispose();
                }
            },
        [
            result,
        ]
    );

    return [
        result?.url,
        isLoading,
        illegalSetter,
    ];
}