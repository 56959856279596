import * as React from 'react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { RichTextToolbarStore } from './RichTextToolbarStore';
import { observer } from 'mobx-react';
import { ToolbarElementStore } from './Types/ToolbarElementStore';
import Button from '../../Button/Button';
import styles from './RichTextToolbar.module.scss';
import { classNames } from '../../../../@Future/Util/Class/classNames';

@observer
export class RichTextToolbar extends BaseComponent<RichTextToolbarStore>
{
    renderComponent(store: RichTextToolbarStore)
    {
        return <div
            className={classNames(store.className, styles.root)}
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center'
            }}
        >
            {
                store.isVisible() && store.hasCollapsibleControls &&
                    <div
                        style={{
                            position: 'absolute',
                            right: 11,
                            top: 11,
                            height: 16
                        }}
                    >
                        <Button store={store.collapseButtonStore} />
                    </div>
            }
            {
                store.isVisible() &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    >
                        {
                            this.drawLines(store, store.controls)
                        }
                    </div>
            }
        </div>;
    }

    drawLines(store: RichTextToolbarStore, lines: Array<Array<ToolbarElementStore | ToolbarElementStore[]>>): JSX.Element[]
    {
        let result: JSX.Element[] = [];

        lines.forEach((element, idx) =>
            result.push(
                <div
                    key={idx}
                    style={{
                        paddingTop: store.isVisible() ? 7 : 0
                    }}
                >
                    {
                        this.drawControls(store, element)
                    }
                </div>));

        return result;
    }

    drawControls(store: RichTextToolbarStore, controls: Array<ToolbarElementStore | ToolbarElementStore[]>): JSX.Element[]
    {
        let result: JSX.Element[] = [];

        controls.forEach((element, idx) =>
            element instanceof ToolbarElementStore
                ?
                    result.push(
                        React.createElement(
                            element.layout,
                            {
                                key: idx,
                                store: element
                            })
                        )
                :
                    result.push(
                        <div
                            key={idx}
                            style={{
                                paddingRight: 10,
                                display: 'inline',
                                alignItems: 'center'
                            }}
                        >
                            {
                                this.drawControls(store, element)
                            }
                        </div>));

        return result;
    }
}
