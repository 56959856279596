import TransactionalApi from '../../@Util/TransactionalModelV2/Api/TransactionalApi';
import { injectWithQualifier } from '../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../@Component/Domain/Entity/Type/EntityTypeStore';
import { getModel, TransactionalModel } from '../../@Util/TransactionalModelV2';
import { EntityType } from '../Model/Implementation/EntityType';
import { EntityTypeController } from '../Controller/Directory/EntityTypeController';
import { action } from 'mobx';
import { EntityTypeMutation } from '../Model/Implementation/EntityTypeMutation';
import { EntityTypeDeletionMutation } from '../Model/Implementation/EntityTypeDeletionMutation';
import { EntityCacheService } from '../../@Component/Service/Entity/EntityCacheService';
import { getCommitFromEntityAndRegisterIfValid } from '../Entity/Commit/commitEntity';

export class EntityTypeApi extends TransactionalApi<EntityType, void, EntityTypeMutation, EntityTypeDeletionMutation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeController') entityTypeController: EntityTypeController;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    protected initialize(model: EntityType): Promise<any>
    {
        return Promise.resolve();
    }

    protected merge(transactional: TransactionalModel<EntityType>, source: EntityType): Promise<any>
    {
        return Promise.resolve();
    }

    protected saveInApi(transactional: TransactionalModel<EntityType>): Promise<EntityTypeMutation>
    {
        const commit =
            transactional.entity
                ?
                    getCommitFromEntityAndRegisterIfValid(transactional.entity)
                :
                    undefined;

        return this.entityTypeController.saveEntityType(
            transactional.id ? transactional.id : undefined,
            transactional.code,
            undefined,
            undefined,
            transactional.parentType ? transactional.parentType.id : null,
            transactional.nameField ? transactional.nameField.id : null,
            transactional.avatarField ? transactional.avatarField.id : null,
            transactional.compactNameField ? transactional.compactNameField.id : null,
            transactional.sortNameField ? transactional.sortNameField.id : null,
            transactional.descriptionField ? transactional.descriptionField.id : null,
            transactional.isInstantiable ? transactional.isInstantiable : false,
            transactional.isIdentity ? transactional.isIdentity : false,
            transactional.isQueueable ? transactional.isQueueable : false,
            transactional.isRoot ? transactional.isRoot : false,
            transactional.isEmbedded ? transactional.isEmbedded : false,
            transactional.isOwnable ? transactional.isOwnable : false,
            transactional.isAssignable ? transactional.isAssignable : false,
            transactional.isOrganizationIdentifiable ? transactional.isOrganizationIdentifiable : false,
            transactional.isUserIdentifiable ? transactional.isUserIdentifiable : false,
            transactional.isUserInternalIdentifiable ? transactional.isUserInternalIdentifiable : false,
            transactional.isUserExternalIdentifiable ? transactional.isUserExternalIdentifiable : false,
            transactional.showInModuleSwitcher ? transactional.showInModuleSwitcher : false,
            transactional.hasPicture ? transactional.hasPicture : false,
            transactional.hasFollowUp ? transactional.hasFollowUp : false,
            transactional.hasDuplicatePrevention ? transactional.hasDuplicatePrevention : false,
            transactional.form ? transactional.form : {},
            transactional.color ? transactional.color : null,
            transactional.icon ? transactional.icon : null,
            commit?.descriptor);
    }

    @action.bound
    protected postSave(transactional: TransactionalModel<EntityType>,
                       resultFromApi: EntityTypeMutation,
                       result: void): Promise<any>
    {
        return this.entityTypeStore.applyMetadataMutation(
            resultFromApi,
            getModel(transactional));
    }

    protected deleteInApi(transactional: TransactionalModel<EntityType>): Promise<EntityTypeDeletionMutation>
    {
        return this.entityTypeController.deleteEntityType(transactional.id);
    }

    protected postDelete(transactional: TransactionalModel<EntityType>,
                         result: EntityTypeDeletionMutation): Promise<any>
    {
        return this.entityTypeStore.applyMetadataMutation(
            result,
            getModel(transactional));
    }
}
