import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../Api/getTypes';
import getExpressionContextForTemplate from '../../../../Viewer/Content/Template/Api/getExpressionContextForTemplate';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import safelyApplyFunction from '../../../../../../../@Api/Automation/Api/safelyApplyFunction';
import { getEmailSubjectComputationFromDescriptor } from './getEmailSubjectComputationFromDescriptor';

export default async function updateSubjectFromTemplate(
    entity: Entity,
    template: Entity,
    entityByParameterId?: Map<string, Entity>,
    commitContext?: CommitContext
)
{
    const types = getTypes();

    if (template.hasValueForField(types.Template.Email.Field.SubjectTemplate, commitContext))
    {
        const context =
            getExpressionContextForTemplate(
                entity.entityType,
                template.entityType,
                types.Template.Email.Field.SubjectTemplate,
                entity,
                entityByParameterId,
                commitContext
            ).entityContext.toFunctionContext();
        const descriptor =
            template.getObjectValueByField(
                types.Template.Email.Field.SubjectTemplate,
                commitContext
            );
        const computation =
            await getEmailSubjectComputationFromDescriptor(
                descriptor,
                context
            );
        const subjectValue = await safelyApplyFunction(computation, context);
        const subject = subjectValue.getName();

        if (subject)
        {
            setValueByFieldInEntity(
                entity,
                types.Activity.Field.Subject,
                subject,
                commitContext
            );
        }
    }
}
