import { ViewStackStore } from '../ViewStack/ViewStackStore';
import { action, observable } from 'mobx';
import { SidebarPanelStore } from '../SidebarPanel/SidebarPanelStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { SidebarPanel } from '../SidebarPanel/SidebarPanel';

export class SidebarStore extends ViewStackStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable showLeftSidebar: boolean;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super();

        this.showLeftSidebar = true;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    pushSidebarPanel(sidebarPanelStore: SidebarPanelStore)
    {
        const viewComponent = new ViewComponent(
            SidebarPanel,
            sidebarPanelStore);

        this.pushView(viewComponent);

        return viewComponent;
    }

    @action.bound
    popSidebarPanel(sidebarPanelStore: SidebarPanelStore)
    {
        this.popViewByStore(sidebarPanelStore);
    }

    @action.bound
    setShowLeftSidebar(showLeftSidebar: boolean)
    {
        this.showLeftSidebar = showLeftSidebar;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
