import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityRelationshipDefinition } from '../../Model/Implementation/EntityRelationshipDefinition';
import { CommitContext } from '../Commit/Context/CommitContext';

export default function useRelatedEntity(entity: Entity,
                                            relationshipDefinition: EntityRelationshipDefinition,
                                            isParent: boolean,
                                            commitContext?: CommitContext): Entity | undefined
{
    return useComputed(
        () =>
        {
            if (entity && relationshipDefinition)
            {
                return entity.getRelatedEntityByDefinition(
                    isParent,
                    relationshipDefinition,
                    commitContext
                );
            }
            else
            {
                return undefined;
            }
        },
        [
            entity,
            relationshipDefinition,
            isParent,
            commitContext,
        ]);
}
