import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import ExpressionEditor from '../Expression/ExpressionEditor';
import BlockOptionsPanel from '../Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../Settings/ColorOptionsPanel';
import GutenbergBlockType from '../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockExpressionId } from '../../DocumentEditor';
import StyledBlock from '../StyledBlock/StyledBlock';
import Icon from '../../../../Icon/Icon';

const GutenbergBlockExpression: GutenbergBlockType =
{
    name: GutenbergBlockExpressionId,
    configuration:
        {
           title: {
                code: 'GutenbergBlock.Expression',
                value: 'Tekst'
            },
            icon: <Icon icon="text_format" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'p',
                    query: undefined,
                },
            },

            edit: props =>
            {
                const {
                    content
                } = props.attributes as any;

                return (
                    <Fragment>
                        <InspectorControls>
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <ExpressionEditor
                                expression={content}
                                onChange={content => props.setAttributes({ content: content })}
                            />
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: props =>
            {
                return <p>
                    {(props.attributes as any).content}
                </p>;
            }
        },

    view: block =>
    {
        return <StyledBlock
            block={block}
        />;
    }
};

export default GutenbergBlockExpression;
