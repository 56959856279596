import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import { runInAction } from 'mobx';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntitySelectionFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import ListQuery from '../../../../../../@Api/Automation/Query/ListQuery';
import { AddEntityPath } from '../../../../Entity/Path/@Model/AddEntityPath';
import QueryEditor from '../../../../Entity/Viewer/Content/Automation/Editor/Computation/Type/Query/Query/QueryEditor';
import EntityConstructorPathSelector from '../../../../Entity/Button/EntityConstructorButton/EntityConstructorPathSelector';
import useDialog from '../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';
import Query from '../../../../../../@Api/Automation/Query/Query';
import uuid from '../../../../../../@Util/Id/uuid';

export interface EntitySelectionFormInputLayoutQueryEditorProps extends LayoutEditorProps<EntitySelectionFormInputLayout>
{

}

const EntitySelectionFormInputLayoutQueryEditor: React.FC<EntitySelectionFormInputLayoutQueryEditorProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;

        const onCreateQuery =
            useCallback(
                (path: AddEntityPath) =>
                    runInAction(
                        () =>
                            layout.query =
                                new ListQuery(
                                    path.fullPath.path.entityType,
                                    Query.getResultParameter(
                                        path.fullPath.path.entityType,
                                        uuid()
                                    ),
                                    undefined,
                                    [],
                                    [],
                                    undefined,
                                    undefined)),
                [
                    layout
                ]);

        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);

        const [ openDialog ] =
            useDialog(
                close =>
                    <Dialog
                        open
                        onClose={close}
                    >
                        <QueryEditor
                            query={layout.query}
                            context={context}
                        />
                    </Dialog>);

        return <Input
            labelPosition={layout.query ? 'left' : 'top'}
            label={
                <LocalizedText
                    code="Generic.Query"
                    value="Query"
                />
            }
        >
            {
                layout.query
                    ?
                        <Link
                            highlighted
                            onClick={() => openDialog()}
                        >
                            {layout.query.entityType.getName(true)}
                        </Link>
                    :
                        <EntityConstructorPathSelector
                            onSelect={onCreateQuery}
                            ignoreHidden
                        />
            }
        </Input>;
    };

export default observer(EntitySelectionFormInputLayoutQueryEditor);
