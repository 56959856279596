import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { getInheritedNameFieldWithFallback } from '../../../../../@Api/Metadata/Field/getInheritedNameFieldWithFallback';

export function getPotentiallySearchableFieldPath(fieldPath: EntityFieldPath)
{
    if (fieldPath.field)
    {
        return fieldPath;
    }
    else
    {
        return fieldPath.path.field(
            getInheritedNameFieldWithFallback(
                fieldPath.path.entityType
            )
        );
    }
}
