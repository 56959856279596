import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ResourceSelection from './ResourceSelection';
import Period from './Period';
import { observable } from 'mobx';

export default class Item
{
    // ------------------------- Properties -------------------------

    @observable.ref selection: ResourceSelection;
    @observable.ref entity: Entity;
    @observable.ref resource: Entity;
    @observable.shallow period: Period;
    @observable.ref dragItem?: Item;

    // ------------------------ Constructor -------------------------

    constructor(selection: ResourceSelection,
                entity: Entity,
                resource: Entity,
                period: Period)
    {
        this.selection = selection;
        this.entity = entity;
        this.resource = resource;
        this.period = period;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
