import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor from '../../../../../../../../@Component/Domain/Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import { EntityFieldPath } from '../../../../../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { default as GenericInput } from '../../../../Input/Input';
import ExpressionEditorContext from '../../Expression/ExpressionEditorContext';
import ComputationBox from '../../../../../../../../@Component/Domain/Entity/Viewer/Content/Automation/Editor/Computation/Box/ComputationBox';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';

export interface FormLinkFieldProps
{
    fieldPath: EntityFieldPath;
    computationValue: any;
    onChange?: (p: any) => void;
}

export const FormLinkField: React.FC<FormLinkFieldProps> =
    observer(
        ({
            fieldPath,
            computationValue,
            onChange
        }) =>
        {

            const expressionContext = useContext(ExpressionEditorContext);
            const functionContext =
                useComputed(
                    () => expressionContext.entityContext.toFunctionContext(),
                    [
                        expressionContext
                    ]
                );

            const type =
                useComputed(
                    () =>
                    {
                        if (fieldPath.isField)
                        {
                            return new PrimitiveValueType(fieldPath.field.dataObjectSpecification.type);
                        }
                        else
                        {
                            // Support for CollectionType for multivalue relationships is future extension
                           return new EntityValueType(fieldPath.relationshipDefinition.getEntityType(fieldPath.isParentRelationship));
                        }
                    },
                    [
                        fieldPath
                    ]
                );

            return <GenericInput
                    label={fieldPath.getName()}
                >
                    <ComputationBox>
                        <ComputationEditor
                            value={computationValue}
                            onChange={onChange}
                            context={functionContext}
                            type={type}
                        />
                    </ComputationBox>
                </GenericInput>
        }
    );
