import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DialogTitle from '../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogActions from '../../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import LabelButton from '../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import DialogContent from '../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';
import ConvertToActivity from './ConvertToActivity';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2';
import { openEntity } from '../../../@Util/openEntity';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface ConvertActivityDialogProps
{
    entity: Entity;
    saveCallback: (success: boolean) => void;
    closeCallback: () => void;
}

const ConvertActivityDialog: React.FC<ConvertActivityDialogProps> =
    props =>
    {
        const types = useTypes();

        const [ convertedActivity, setConvertedActivity ] = useState<Entity>();
        const [ doValidate, setDoValidate ] = useState(false);

        const saveCallback =
            useCallback(
                (openNewEntity: boolean) =>
                {
                    setDoValidate(true);

                    if (convertedActivity && convertedActivity.isValid)
                    {
                        convertedActivity.updateRelationship(
                            false,
                            types.Activity.RelationshipDefinition.LinkedActivities,
                            createTransactionalModel(props.entity));

                        return convertedActivity.checkAndDoCommit(undefined, true)
                            .then(
                                () =>
                                {
                                    props.saveCallback(true);

                                    if (openNewEntity)
                                    {
                                        openEntity(convertedActivity);
                                    }
                                });
                    }
                },
                [
                    setDoValidate,
                    convertedActivity,
                    types,
                    props.entity
                ]);

        return <Dialog
            onClose={props.closeCallback}
            open={true}
        >
            <DialogTitle>
                <LocalizedText
                    code='ConvertEntityType'
                    value='${entityTypeName} converteren'
                    entityTypeName={props.entity.entityType.nameSingular}
                />
            </DialogTitle>
            <DialogContent>
                <ConvertToActivity
                    subject={props.entity.name}
                    relationship={props.entity.getRelatedEntityByDefinition(true, types.Relationship.RelationshipDefinition.Activities)}
                    contact={props.entity.getRelatedEntityByDefinition(true, types.Relationship.Person.Contact.RelationshipDefinition.Activities)}
                    onParentActivity={setConvertedActivity}
                    validate={doValidate}
                />
            </DialogContent>
            <DialogActions>
                <LabelButton
                    label={
                        <LocalizedText
                            code='Convert'
                            value='Converteren'
                        />
                    }
                    onClick={() => saveCallback(false)}
                    color={primaryColor}
                />
                <LabelButton
                    label={
                        <LocalizedText
                            code='ConvertAndOpen'
                            value='Converteren en openen'
                        />
                    }
                    filled={true}
                    onClick={() => saveCallback(true)}
                    color={primaryColor}
                />
            </DialogActions>
        </Dialog>;
    };

export default observer(ConvertActivityDialog);
