import React from 'react';
import { observer } from 'mobx-react-lite';
import DynamicFunctionInvocationEditor from '../../../Dynamic/DynamicFunctionInvocationEditor';
import { PredicateEditorProps } from '../../PredicateEditor';
import DynamicPredicate from '../../../../../../../../../../@Api/Automation/Function/Computation/Predicate/DynamicPredicate';

export interface DynamicPredicateEditorProps extends PredicateEditorProps<DynamicPredicate>
{

}

const DynamicPredicateEditor: React.FC<DynamicPredicateEditorProps> =
    props =>
    {
        return <DynamicFunctionInvocationEditor
            context={props.context}
            invocation={props.value.invocation}
            disabled={props.disabled}
        />;
    };

export default observer(DynamicPredicateEditor);
