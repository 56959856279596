import React from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react';
import { primaryColor } from '../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { asyncOpenWindow } from '../../../../../../../../@Util/Window/asyncOpenWindow';
import sendDocument from '../../ProductLinesEditor/Api/sendDocument';
import copy from 'copy-to-clipboard';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import useTypes from '../../../../../Type/Api/useTypes';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import createSigningActivity from '../../Signing/createSigningActivity';
import { downloadApiFile } from '../../../../../../../../@Service/ApiClient/Hooks/downloadApiFile';
import useIsConnectorActivationActivated from '../../../../../../Marketplace/Api/useIsConnectorActivationActivated';

export interface OfferButtonMenuProps
{
    entity: Entity;
    commitContext: CommitContext 
}

export const OfferButtonMenu: React.FC<OfferButtonMenuProps> =
    observer(
        ({
            entity,
            commitContext
         }) =>
        {
            const types = useTypes();
            const dividerGlue = useDividerGlue();

            const parentOfDocument =
                useRelatedEntity(
                    entity,
                    types.Activity.RelationshipDefinition.LinkedActivities,
                    true,
                    commitContext
                );

            const [ validSignConnectorActivation] =
                useAsyncResult(
                    () => getConnectorActivationByCode('ValidSign'),
                    []
                );

            const validSignConnectorActive = useIsConnectorActivationActivated(validSignConnectorActivation);

            return <MenuButton
                color={primaryColor}
                label={
                    <LocalizedText
                        code="Activity.Offer.Submit"
                        value="Offerte uitbrengen"
                    />
                }
                filled
                fullWidth
                size="sm"
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.Download"
                                        value="Downloaden"
                                    />
                                }
                                onClick={
                                    () =>
                                        performAction(
                                            entity,
                                            {
                                                code: 'Activity.Offer.Finalize',
                                                name: localizeText('Generic.Finalize', 'Definitief maken')
                                            })
                                            .then(
                                                () =>
                                                    downloadApiFile(`/entity/bespoke/offer/${entity.id}/pdf`)
                                            )
                                }
                            />
                        </Menu>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CardInset>
                            <strong>
                                <LocalizedText
                                    code="Activity.Offer.SignDigitally"
                                    value="Digitaal ondertekenen"
                                />
                            </strong>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <Menu>
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.Direct"
                                        value="Direct"
                                    />
                                }
                                onClick={
                                    () =>
                                        asyncOpenWindow(
                                            () =>
                                                performAction(
                                                    entity,
                                                    {
                                                        code: 'Activity.Offer.GenerateSignLink',
                                                    }
                                                )
                                                    .then(
                                                        result =>
                                                            result.result
                                                    )
                                        )
                                }
                            />
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.ViaEmail"
                                        value="Via e-mail"
                                    />
                                }
                                onClick={
                                    () =>
                                        sendDocument(
                                            entity,
                                            types.Template.Email.Offer.Type,
                                            'Activity.Offer.Send',
                                            undefined,
                                            parentOfDocument,
                                            new Map([
                                                [ 'Offer', entity ]
                                            ]),
                                            " (link)"
                                        )
                                }
                            />
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.ViaEmailPdf"
                                        value="Via e-mail (pdf)"
                                    />
                                }
                                onClick={
                                    () =>
                                        sendDocument(
                                            entity,
                                            types.Template.Email.Offer.Type,
                                            'Activity.Offer.SendPdf',
                                            undefined,
                                            parentOfDocument,
                                            new Map([
                                                [ 'Offer', entity ]
                                            ]),
                                            ' (PDF attachment)',
                                            localizeText('Generic.PDFAddedWhenSending', 'PDF wordt toegevoegd bij verzenden')
                                        )
                                }
                            />
                            <Item
                                name={
                                    <LocalizedText
                                        code="Generic.ViaLink"
                                        value="Via link"
                                    />
                                }
                                onClick={
                                    () =>
                                        performAction(
                                            entity,
                                            {
                                                code: 'Activity.Offer.GenerateSignLink',
                                                name:
                                                    localizeText(
                                                        'Generic.CopyToClipboard',
                                                        'Link kopiëren naar clipboard')
                                            })
                                            .then(
                                                result =>
                                                    copy(result.result))
                                }
                            />
                            {
                                validSignConnectorActive &&
                                <Item
                                    name={
                                        <LocalizedText
                                            code="SendToValidSign"
                                            value="Via ValidSign"
                                        />
                                    }
                                    onClick={
                                        () => {
                                            createSigningActivity(
                                                entity,
                                                parentOfDocument,
                                                'ActivityDigitalSigning.GenerateOfferAndStart',
                                                localizeText('Generic.PDFAddedWhenSending', 'PDF wordt toegevoegd bij verzenden')

                                            )
                                        }
                                    }
                                />
                            }
                        </Menu>
                    </ViewGroupItem>
                </ViewGroup>
            </MenuButton>
        }
    );
