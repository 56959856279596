import React from 'react';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import useProjectEmployeePlanner from '../../../../../../ResourcePlanner/Type/useProjectEmployeePlanner';
import ResourcePlanner from '../../../../../../ResourcePlanner/ResourcePlanner';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';

export interface PlannerProps
{
    project: Entity;
    milestone?: Entity;
}

const EmployeePlanner: React.FC<PlannerProps> =
    props =>
    {
        const employeePlanner = useProjectEmployeePlanner(props.project, props.milestone);

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <ResourcePlanner
                    resourcePlanner={employeePlanner}
                />
            </CardInset>
        </Card>;
    };

export default observer(EmployeePlanner);
