import localizeText from '../../../../../../../@Api/Localization/localizeText';

export interface IntervalOption
{
    id: string;
    value: string;
    label: string;
}

export default function getDateIntervalOptions(): IntervalOption[]
{
    return [
        { id: 'Daily', value: 'Daily', label: localizeText('Generic.Daily', 'Dagelijks') },
        { id: 'Weekly', value: 'Weekly', label: localizeText('Generic.Weekly', 'Wekelijks') },
        { id: 'FourWeekly', value: 'FourWeekly', label: localizeText('Generic.FourWeekly', 'Vierwekelijks') },
        { id: 'Monthly', value: 'Monthly', label: localizeText('Generic.Monthly', 'Maandelijks') },
        { id: 'TwoMonthly', value: 'TwoMonthly', label: localizeText('Generic.TwoMonthly', 'Tweemaandelijks')},
        { id: 'FourMonthly', value: 'FourMonthly', label: localizeText('Generic.FourMonthly', 'Viermaandelijks')},
        { id: 'Quarterly', value: 'Quarterly', label: localizeText('Generic.Quarterly', 'Per kwartaal') },
        { id: 'HalfYearly', value: 'HalfYearly', label: localizeText('Generic.HalfYearly', 'Halfjaarlijks') },
        { id: 'Yearly', value: 'Yearly', label: localizeText('Generic.Yearly', 'Jaarlijks') },
        { id: 'TwoYearly', value: 'TwoYearly', label: localizeText('Generic.TwoYearly', 'Tweejaarlijks')}
    ];
};
