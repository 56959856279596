import { useCallback, useContext } from 'react';
import ExpansionPanelContainerContext from '../ExpansionPanelContainerContext';
import { Id } from '../../../../Util/Id/Id';
import { useComputed } from 'mobx-react-lite';

export default function useExpansion(id: Id): [ boolean, (isExpanded: boolean) => void ]
{
    const expansionPanelContainer = useContext(ExpansionPanelContainerContext);

    const isExpanded =
        useComputed(
            () => expansionPanelContainer.expandedItemId === id,
            [
                expansionPanelContainer.expandedItemId,
                id
            ]);

    // const isExpanded = expansionPanelContainer.expandedItemId === id;

    const setExpansion =
        useCallback(
            (isExpanded: boolean) =>
            {
                if (isExpanded)
                {
                    expansionPanelContainer.setExpandedItemId(id);
                }
                else
                {
                    if (expansionPanelContainer.expandedItemId === id)
                    {
                        expansionPanelContainer.setExpandedItemId(undefined);
                    }
                }
            },
            [
                id,
                expansionPanelContainer
            ]);

    return [
        isExpanded,
        setExpansion
    ];
}
