import { useContext, useMemo } from 'react';
import CurrentUserContext from '../../User/CurrentUserContext';
import { useTwoFactorAuthenticationConfiguration } from './useTwoFactorAuthenticationConfiguration';


export function useHasToEnableTwoFactorAuthentication(): [ boolean, boolean ]
{
    const currentUserStore = useContext(CurrentUserContext);

    const [ twoFactorAuthenticationConfiguration, isLoading] =
        useTwoFactorAuthenticationConfiguration(
            currentUserStore.currentUser
        );

    return useMemo(
        () =>
            [
                twoFactorAuthenticationConfiguration?.isRequired
                && !twoFactorAuthenticationConfiguration?.isEnabled,
                isLoading
            ],
        [
            twoFactorAuthenticationConfiguration,
            isLoading,
            currentUserStore.currentUser
        ]
    );

}