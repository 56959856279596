import { enumerated, reference, registerType, type } from '../../../@Util/Serialization/Serialization';
import { DataObjectStore } from '../../../@Component/Domain/DataObject/DataObjectStore';
import { DataObjectSpecification } from '../../../@Component/Domain/DataObject/Model/DataObjectSpecification';
import { EntityType } from './EntityType';
import { LanguageEntry } from './LanguageEntry';
import { NumberGenerator } from './NumberGenerator';
import { EntityFieldDependency } from './EntityFieldDependency';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { action, computed, observable } from 'mobx';
import { Computation } from '../../../@Component/Domain/Computation/Type/Computation';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../@Component/Domain/Entity/@Model/EntityContext';
import { registerBuilder } from '../../../@Util/TransactionalModelV2/Shared/TransactionalBuilder';
import { v4 as uuid } from 'uuid';
import { Entity } from './Entity';
import { EntityFieldCondition } from './EntityFieldCondition';
import { DataObjectRepresentation, DataObjectRepresentationProps } from '../../../@Component/Domain/DataObject/Model/DataObjectRepresentation';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import getTypes from '../../../@Component/Domain/Entity/Type/Api/getTypes';
import { DataObject } from '../../../@Component/Domain/DataObject/Model/DataObject';
import { safelyGetOrUndefined } from '../../../@Util/Exception/safelyGetOrUndefined';

export enum DataObjectType { Boolean, Color, Text, RichText, LocalizedText, Number, NumberRange, Currency, CurrencyRange, Percentage, PercentageRange, Date, DateTime, DateRange, DateInterval, DatePeriod, Time, Address, EmailAddress, TelephoneNumber, Url, User, Me, Entity, EntityType, EntityField, Language, Organization, File, Complex, EntityPath, EntityFieldPath, Computation, Predicate }

export enum EntityFieldType { Default, Address }

export enum ImportanceLevel { None, Low, Normal, High, Critical }

@type('EntityField')
export class EntityField
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @reference(undefined, 'EntityType') @observable.ref entityType: EntityType;
    @observable code: string;
    @observable mergeCode: string;
    @observable @enumerated(DataObjectType, 'DataObjectType') type: DataObjectType;
    @observable.ref specification: any;
    @observable.ref handlers: any;
    @observable isRequired: boolean;
    @observable isDefining: boolean;
    @observable isDefault: boolean;
    @observable isCompact: boolean;
    @observable isReadonly: boolean;
    @observable isHiddenFromTimeline: boolean;
    @observable.ref computation: any;
    @observable.ref defaultValueComputation: any;
    @reference(undefined, 'NumberGenerator') @observable.ref numberGenerator: NumberGenerator;
    @observable @enumerated(EntityFieldType, 'EntityFieldType') fieldType: EntityFieldType;
    @observable @enumerated(ImportanceLevel, 'ImportanceLevel') importanceLevel: ImportanceLevel;
    @observable fieldTypeCode: string;
    @observable sortIndex: number;
    @observable _name: string; // for static fields
    @observable _description: string; // for static fields
    @reference(undefined, 'Entity') @observable.ref entity: Entity;
    @reference(undefined, 'EntityFieldCondition') @observable.shallow conditions: EntityFieldCondition[];

    // ------------------------- Properties -------------------------

    @observable uuid: string;
    @observable.ref dataObjectSpecification: DataObjectSpecification;
    @observable.ref initializedComputation: Computation;
    @observable.ref initializedDefaultValueComputation: Computation;

    // ------------------------ Constructor -------------------------

    constructor(id?: number,
                entityType?: EntityType,
                code?: string,
                mergeCode?: string,
                name?: string,
                nameLanguageEntry?: LanguageEntry,
                description?: string,
                descriptionLanguageEntry?: LanguageEntry,
                dataObjectType?: DataObjectType,
                specification?: any,
                handlers?: any,
                isDefining?: boolean,
                isDefault?: boolean,
                isCompact?: boolean,
                isReadonly?: boolean,
                isHiddenFromTimeline?: boolean,
                computation?: any,
                numberGenerator?: NumberGenerator,
                fieldType?: EntityFieldType,
                fieldTypeCode?: string,
                sortIndex?: number,
                incomingDependencies?: EntityFieldDependency[],
                outgoingDependencies?: EntityFieldDependency[],
                importanceLevel?: ImportanceLevel)
    {
        this.id = id;
        this.entityType = entityType;
        this.code = code;
        this.mergeCode = mergeCode;
        this.type = dataObjectType;
        this.specification = specification;
        this.handlers = handlers;
        this.isDefining = isDefining;
        this.isDefault = isDefault;
        this.isCompact = isCompact;
        this.isReadonly = isReadonly;
        this.isHiddenFromTimeline = isHiddenFromTimeline;
        this.computation = computation;
        this.numberGenerator = numberGenerator;
        this.fieldType = fieldType;
        this.fieldTypeCode = fieldTypeCode;
        this.sortIndex = sortIndex;
        this.importanceLevel = importanceLevel ? importanceLevel : ImportanceLevel.None;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isComputedField()
    {
        return this.computation != null;
    }

    // -------------------------- Actions ---------------------------

    @action
    initialize(entityType: EntityType,
               entityTypeStore: EntityTypeStore,
               doInitializeEntity: boolean = true)
    {
        if (!this.uuid)
        {
            this.uuid = uuid();
        }

        this.entityType = entityType;
        this.dataObjectSpecification =
            entityTypeStore.dataObjectStore.constructSpecification(
                DataObjectType[this.type as any],
                this.specification as any,
                this.isRequired);

        if (this.entity && doInitializeEntity)
        {
            this.entity.initialize(entityTypeStore);
            this.entity = entityTypeStore.entityCacheService.getEntityById(this.entity.id);
        }
    }

    @action
    postInitialize(entityTypeStore: EntityTypeStore,
                   doInitializeEntity: boolean = true)
    {
        this.initializedComputation =
            this.computation
                ?
                    safelyGetOrUndefined(
                        () =>
                            entityTypeStore.computationTypeStore.fromSpecification(this.computation)
                    )
                :
                    undefined;

        this.initializedDefaultValueComputation =
            this.defaultValueComputation
                ?
                    safelyGetOrUndefined(
                        () =>
                            entityTypeStore.computationTypeStore.fromSpecification(this.defaultValueComputation)
                    )
                :
                    undefined;

        if (!this.conditions)
        {
            this.conditions = observable.array();
        }

        this.conditions =
            this.conditions.filter(
                condition =>
                {
                    try
                    {
                        condition.initialize(
                            this,
                            entityTypeStore
                        );

                        return true;
                    }
                    catch (e)
                    {
                        return false;
                    }
                }
            );

        if (this.entity && doInitializeEntity)
        {
            if (typeof this.entity.initialize !== 'function')
            {
                console.log(this, this.entity);
            }

            this.entity.initialize(
                entityTypeStore,
                undefined,
                undefined,
                undefined,
                false);
        }
    }

    // ------------------------ Public logic ------------------------

    get name(): string
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityField.Field.LocalizedName).toString() || this._name;
    }

    set name(value: string)
    {
        this._name = value;
    }

    get nameDataObject(): DataObject
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityField.Field.LocalizedName);
    }

    get description(): string
    {
        return this.entity?.getDataObjectValueByField(getTypes().EntityField.Field.LocalizedDescription).toString() || this._description;
    }

    set description(value: string)
    {
        this._description = value;
    }

    isStaticField(): boolean
    {
        return (this as any).isStatic;
    }

    getSpecification(dataObjectStore: DataObjectStore): DataObjectSpecification
    {
        return dataObjectStore.constructSpecification(DataObjectType[this.type as any], this.specification as any, this.isRequired);
    }

    getName(entityType: EntityType = this.entityType,
            entityTypeStore: EntityTypeStore = loadModuleDirectly(EntityTypeStore))
    {
        return this.name;
    }

    getRepresentation(
        entity: Entity
    ): DataObjectRepresentation
    {
        return new DataObjectRepresentation<DataObjectRepresentationProps>(
            entity.entityType.bespoke.getRepresentation(
                entity,
                this
            )
        );
    }

    isA(entityField: EntityField)
    {
        return this.code === entityField.code;
    }

    getDependencies(path: EntityPath): EntityFieldPath[]
    {
        const paths: EntityFieldPath[] = [];
        const context = new EntityContext([], path);

        if (this.initializedComputation)
        {
            paths.push(
                ...this.initializedComputation.type.entityFieldPaths(
                    this.computation,
                    context));
        }

        if (this.initializedDefaultValueComputation)
        {
            paths.push(
                ...this.initializedDefaultValueComputation.type.entityFieldPaths(
                    this.defaultValueComputation,
                    context));
        }

        this.conditions.forEach(
            condition =>
            {
                paths.push(
                    ...condition.getDependencies(path));
            });

        return paths;
    }

    toString(): string
    {
        return `EntityField(${this.isStaticField() ? this.code : this.id})`;
    }

    // ----------------------- Private logic ------------------------
}

registerBuilder(EntityField)
    .includeAll()
    .deep('entity');

registerType(EntityFieldCondition, 'EntityFieldCondition');
