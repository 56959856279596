import React, { useMemo } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

export default function useStartAdornment(adornment: React.ReactNode)
{
    return useMemo(
        () =>
        {
            if (adornment)
            {
                return <InputAdornment
                    position="start"
                >
                    {adornment}
                </InputAdornment>;
            }
            else
            {
                return undefined;
            }
        },
        [
            adornment
        ]);
}