import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import ExpansionSelector from './Expansion/ExpansionSelector';
import BucketSelector from './Bucket/BucketSelector';
import ChartSelector from './Chart/ChartSelector';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import useAugmentedResult from '../Api/useAugmentedResult';
import FilterContext from '../FilterContext/FilterContext';
import { default as SegmentModel } from '../../Model/Segment';
import DatasetContext from '../../Context/DatasetContext';

export interface SelectorProps
{
    entityType: EntityType;
    segment: SegmentModel;
    nextSegments: SegmentModel[];
    result?: EntitySelectionAggregateResult;
}

const Selector: React.FC<SelectorProps> =
    props =>
    {
        const filter = useContext(FilterContext);
        const dataset = useContext(DatasetContext);
        const result =
            useAugmentedResult(
                props.entityType,
                props.segment,
                dataset.parameter,
                props.result,
                filter);

        if (result || props.segment.chart)
        {
            switch (props.segment.type)
            {
                case 'List':
                    return <ExpansionSelector
                        entityType={props.entityType}
                        segment={props.segment}
                        nextSegments={props.nextSegments}
                        result={result}
                    />;
                case 'Tile':
                    return <BucketSelector
                        entityType={props.entityType}
                        segment={props.segment}
                        nextSegments={props.nextSegments}
                        result={result}
                    />;
                case 'Chart':
                case 'Bar':
                case 'Line':
                case 'Pie':
                case 'Funnel':
                    return <ChartSelector
                        entityType={props.entityType}
                        segment={props.segment}
                        nextSegments={props.nextSegments}
                        result={result}
                    />;
            }
        }
        else
        {
            return null;
        }
    };

export default observer(Selector);
