import { useEffect, useMemo } from 'react';
import { Entity } from '../../Model/Implementation/Entity';
import { createTransactionalModel, destroyTransactionalModel, isTransactionalModel } from '../../../@Util/TransactionalModelV2/Model/TransactionalModel';

/**
 * @deprecated either use context = useCommitContext() with commitEntity(entity, { context }) or new CommitBuilder()...commit()
 * @param entity
 */
export default function useTransactionalEntity(entity?: Entity)
{
    const transactionalEntity =
        useMemo(
            () =>
                entity &&
                createTransactionalModel(entity),
            [
                entity
            ]);


    useEffect(
        () =>
        {
            return () =>
            {
                // Only destroy the model if the original was not transactional (so it is not shared by other
                // components)
                if (entity && !isTransactionalModel(entity))
                {
                    destroyTransactionalModel(transactionalEntity);
                }
            };
        },
        [
            entity,
            transactionalEntity
        ]);

    return transactionalEntity;
}
