import { EntityType } from '../../Model/Implementation/EntityType';
import { DisposableValue } from '../../../@Util/Disposable/DisposableValue';
import { Entity } from '../../Model/Implementation/Entity';
import { EmptyDisposer } from '../../../@Util/Disposable/EmptyDisposer';
import { Disposer } from '../../../@Util/Disposable/Disposer';
import { splitArrayIntoArraysOfUpToN } from '../../../@Util/Array/splitArrayIntoArraysOfUpToN';
import { combineDisposers } from '../../../@Util/Disposable/combineDisposers';
import { EntitySelectionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityField } from '../../Model/Implementation/EntityField';

export async function internallyGetEntitiesByIds<ID>(
    entityType: EntityType,
    ids: ID[],
    idField: EntityField,
    callback?: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void
): Promise<DisposableValue<Entity[]>>
{
    if (ids.length === 0)
    {
        return {
            value: [],
            dispose: EmptyDisposer,
        };
    }
    else
    {
        const entities: Entity[] = [];
        const disposers: Disposer[] = [];

        // Fetch pages sequentially to reduce load on API
        for (const idsToFetch of splitArrayIntoArraysOfUpToN(ids, 100))
        {
            const result =
                await fetchEntitiesByUuids(
                    entityType,
                    idsToFetch,
                    idField,
                    callback
                );
            entities.push(
                ...result.value
            );
            disposers.push(result.dispose);
        }

        return {
            value: entities,
            dispose: combineDisposers(disposers),
        };
    }
}

async function fetchEntitiesByUuids<ID>(
    entityType: EntityType,
    ids: ID[],
    idField: EntityField,
    callback?: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void
): Promise<DisposableValue<Entity[]>>
{
    const builder =
        callback
            ? EntitySelectionBuilder.builder(entityType, callback)
            :  new EntitySelectionBuilder(entityType);

    const selectionBuilder =
        builder
            .where(
                cb =>
                    cb.or(
                        ob =>
                            ids.forEach(
                                id =>
                                    ob.eq(
                                        EntityPath.fromEntityType(entityType)
                                            .field(idField),
                                        undefined,
                                        id
                                    )
                            )
                    )
            );

    const results = await selectionBuilder.select(undefined, ids.length);

    return {
        value:
            results
                .map(
                    result =>
                        result.entity
                ),
        dispose:
            () =>
                selectionBuilder.dispose()
    };
}