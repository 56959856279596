import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import Content from './Content/Content';
import { NavigatorClasses } from './Navigator/Navigator';
import { RelatedEntityPath } from '../Path/@Model/RelatedEntityPath';
import styles from './Item.module.scss';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import FavoriteButton from '../Button/FavoriteButton/FavoriteButton';
import useIsFavoritable from './Navigator/Api/useIsFavoritable';
import NavigatorContext from './Navigator/NavigatorContext/NavigatorContext';
import { NavigatorContextStore } from './Navigator/NavigatorContext/NavigatorContextStore';
import HeaderContext from './Context/HeaderContext';
import HorizontalInsetContext from './Context/HorizontalInsetContext';
import EntityContext from './Context/EntityContext';
import EditableContext from './Context/EditableContext';
import HideCreationDateContext from './Context/HideCreationDateContext';
import DisplayTimeContext from './Context/DisplayTimeContext';
import DisplayDateContext from './Context/DisplayDateContext';
import PathFromRelatedEntityContext from './Context/PathFromRelatedEntityContext';
import CompactContext from './Context/CompactContext';
import { v4 as uuid } from 'uuid';
import VerticalInsetContext from './Context/VerticalInsetContext';
import ShowTypeContext from './Context/ShowTypeContext';
import OnOpenContext from './Context/OnOpenContext';

export interface ItemClasses
{
    root?: string;
    content?: string;
    navigator?: NavigatorClasses;
    favoriteButton?: string;
}

export interface ItemProps
{
    entity: Entity;
    pathFromRelatedEntity?: RelatedEntityPath;
    horizontalInset?: boolean;
    verticalInset?: boolean;
    header?: boolean;
    classes?: ItemClasses;
    hideCreationDate?: boolean;
    displayTime?: boolean;
    editable?: boolean;
    hideFavoriteButton?: boolean;
    hideParentDescription?: boolean;
    showMore?: boolean;
    showMenuButton?: boolean;
    compact?: boolean;
    showType?: boolean;
    highlighted?: boolean;
    onOpen?: (entity: Entity) => void;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const entity = props.entity;
        const [ id ] = useState(() => uuid());
        const navigatorContext =
            useMemo(
                () =>
                    new NavigatorContextStore({
                        id: id
                    }),
                [
                    id
                ]);
        const isFavoritable = useIsFavoritable(props.entity);
        const onOpen = useContext(OnOpenContext);
        const showFavoriteButton =
            useMemo(
                () => isFavoritable && !props.hideFavoriteButton,
                [
                    isFavoritable,
                    props.hideFavoriteButton
                ]);

        // Always scroll the highlighted item into viewport
        const highlightedRef = useRef(null);
        useEffect(
            () =>
            {
                if (props.highlighted && highlightedRef.current)
                {
                    highlightedRef.current.scrollIntoView(
                        {
                            block: 'center'
                        }
                    );
                }
            },
            [
                highlightedRef,
                props.highlighted
            ]);

        return <EntityContext.Provider
            value={entity}
        >
            <HeaderContext.Provider
                value={props.header}
            >
                <EditableContext.Provider
                    value={props.editable}
                >
                    <HorizontalInsetContext.Provider
                        value={props.horizontalInset}
                    >
                        <VerticalInsetContext.Provider
                            value={props.verticalInset}
                        >
                            <CompactContext.Provider
                                value={props.compact}
                            >
                                <ShowTypeContext.Provider
                                    value={props.showType}
                                >
                                    <HideCreationDateContext.Provider
                                        value={props.hideCreationDate}
                                    >
                                        <DisplayDateContext.Provider
                                            value={true}
                                        >
                                            <DisplayTimeContext.Provider
                                                value={props.displayTime}
                                            >
                                                <PathFromRelatedEntityContext.Provider
                                                    value={props.pathFromRelatedEntity}
                                                >
                                                    <NavigatorContext.Provider
                                                        value={navigatorContext}
                                                    >
                                                        <OnOpenContext.Provider
                                                            value={props.onOpen ?? onOpen}
                                                        >
                                                            <div
                                                                className={
                                                                    classNames(
                                                                        styles.root,
                                                                        props.editable && styles.editable,
                                                                        props.highlighted && styles.highlighted,
                                                                        props.classes && props.classes.root
                                                                    )}
                                                                ref={highlightedRef}
                                                            >
                                                                <Content
                                                                    {...props}
                                                                    entity={entity}
                                                                />
                                                                {
                                                                    showFavoriteButton &&
                                                                    <div
                                                                        className={
                                                                            classNames(
                                                                                styles.favoriteButton,
                                                                                props.classes && props.classes.favoriteButton)
                                                                        }
                                                                    >
                                                                        <FavoriteButton
                                                                            entity={entity}
                                                                            size="lg"
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </OnOpenContext.Provider>
                                                    </NavigatorContext.Provider>
                                                </PathFromRelatedEntityContext.Provider>
                                            </DisplayTimeContext.Provider>
                                        </DisplayDateContext.Provider>
                                    </HideCreationDateContext.Provider>
                                </ShowTypeContext.Provider>
                            </CompactContext.Provider>
                        </VerticalInsetContext.Provider>
                    </HorizontalInsetContext.Provider>
                </EditableContext.Provider>
            </HeaderContext.Provider>
        </EntityContext.Provider>;
    };

Item.defaultProps = {
    header: false,
    horizontalInset: false,
    verticalInset: false
};

export default observer(Item);
