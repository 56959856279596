import Widget from '../../Widget';
import { observable, runInAction } from 'mobx';
import collectAndInitializeByEntityIds from '../../../Api/collectAndInitializeByEntityIds';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { augmentDatasetSelectionBuilderWithEmployeeFilter } from '../../../../Dataset/Api/augmentDatasetSelectionBuilder';

export default class DatasetWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable datasetId: string;
    @observable.ref dataset: Entity;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                datasetId: string,
                dataset?: Entity)
    {
        super(id);

        this.datasetId = datasetId;
        this.dataset = dataset;

        if (dataset)
        {
            this.isInitialized = true;
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: DatasetWidget[])
    {
        return collectAndInitializeByEntityIds(
            widgets,
            widget => widget.datasetId,
            async (widget, entity) =>
                runInAction(
                    () =>
                        widget.dataset = entity
                ),
            loadModuleDirectly(EntityTypeStore).bespoke.types.Dataset.Type,
            (builder: EntitySelectionBuilder, ) =>
                augmentDatasetSelectionBuilderWithEmployeeFilter(
                    builder,
                    this.currentUserStore.employeeEntity,
                    false
                )
        );
    }

    static fromDescriptor(descriptor: any)
    {
        return new DatasetWidget(
            descriptor.id,
            descriptor.datasetId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Dataset',
            datasetId: this.datasetId
        }
    }

    // ----------------------- Private logic ------------------------
}
