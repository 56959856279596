import { createContext } from 'react';


interface EditorState
{
    editor: any;
    allowTextStyling: boolean;
    showCollapsibleToolbar: boolean;
    performCommand: (command: string, isActive: boolean) => void;
    updateFormat: (command: string, value: any) => void;
    onFocusToolbar: () => void;
    onBlurToolbar: () => void;
}

export default createContext<EditorState>(undefined);
