import React, { useCallback } from 'react';
import NumberEditor from '../NumberEditor/NumberEditor';
import TextEditor from '../TextEditor/TextEditor';
import AsyncComponent from '../../../../../@Util/AsyncComponent/AsyncComponent';
import { JsonEditorProps } from './JsonEditor';

export interface JsonRichEditorProps extends JsonEditorProps
{

}

const reactJsonLoader =
    () =>
        import('react-json-view');

const JsonRichEditor: React.FC<JsonRichEditorProps> =
    props =>
    {
        const { onChange } = props;

        const handleChange =
            useCallback(
                (change: any) =>
                {
                    if (!props.disabled)
                    {
                        onChange(change.updated_src);
                    }
                },
                [
                    onChange,
                    props.disabled
                ]);

        if (typeof props.json === 'object')
        {
            return <AsyncComponent
                _loader={reactJsonLoader}
                _loadKey="default"
                src={props.json}
                theme="shapeshifter:inverted"
                iconStyle="triangle"
                onEdit={handleChange}
                onDelete={handleChange}
                onAdd={handleChange}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={true}
                indentWidth={8}
                collapsed={false}
                collapseStringsAfterLength={false}
            />;
        }
        else if (typeof props.json === 'number')
        {
            return <NumberEditor
                onChange={props.onChange}
                value={props.json}
                disabled={props.disabled}
            />;
        }
        else if (typeof props.json === 'string')
        {
            return <TextEditor
                onChange={props.onChange}
                value={props.json}
                disabled={props.disabled}
            />;
        }
        else
        {
            return null;
        }
    };

export default JsonRichEditor;
