import * as React from 'react';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface ToggleFieldsIconButtonProps
{
    isFieldsOpen: boolean;
    toggleFieldsOpen: () => void;
}

const ToggleFieldsIconButton: React.FC<ToggleFieldsIconButtonProps> =
    props =>
    {
        return <IconButton
            icon="info"
            onClick={props.toggleFieldsOpen}
            color={
                props.isFieldsOpen
                    ? primaryColor
                    : textSecondaryColor
            }
            tooltip={
                props.isFieldsOpen
                ? localizeText('FieldsHide', 'Velden verbergen')
                : localizeText('FieldsShow', 'Velden tonen')
            }
        />
    };

export default ToggleFieldsIconButton;
