import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import EffectLayout from '../../../../../@Api/Layout/Type/EffectLayout';
import EffectLayoutDependenciesEditor from './LayoutDependencies/EffectLayoutDependenciesEditor';
import EffectLayoutDependency from '../../../../../@Api/Layout/Type/EffectLayoutDependency';
import { runInAction } from 'mobx';
import LayoutActionEditor from '../../Action/LayoutActionEditor';
import LayoutAction from '../../../../../@Api/Layout/Action/LayoutAction';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface EffectLayoutControlsProps extends LayoutEditorProps<EffectLayout>
{

}

const EffectLayoutControls: React.FC<EffectLayoutControlsProps> =
    ({
        layout,
        parameterDictionary,
        parameterAssignment,
        commitContext,
     }) =>
    {
        const setAction =
            useCallback(
                (value: LayoutAction) =>
                    runInAction(
                        () =>
                            layout.action = value
                    ),
                [
                    layout
                ]
            );
        const setDependencies =
            useCallback(
                (value: EffectLayoutDependency[]) =>
                    runInAction(
                        () =>
                            layout.dependencies = value
                    ),
                [
                    layout
                ]
            );
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment,
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]
            );

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <LayoutActionEditor
                        context={context}
                        action={layout.action}
                        onChange={setAction}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <EffectLayoutDependenciesEditor
                        value={layout.dependencies}
                        onChange={setDependencies}
                        context={context}
                    />
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(EffectLayoutControls);
