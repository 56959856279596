import { BaseStore, getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { computed } from 'mobx';
import { EntityEvent } from '../../../../@Api/Model/Implementation/EntityEvent';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { TextStore } from '../../../Generic/Text/TextStore';

export interface SideEffectProps
{
    entity: PropType<SideEffectStore, SideEffectProps, Entity>;
    events: PropType<SideEffectStore, SideEffectProps, EntityEvent[]>;
}

const defaultProps: Partial<SideEffectProps> =
{

};

export abstract class SideEffectStore<P extends SideEffectProps = SideEffectProps> extends BaseStore<P>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: P)
    {
        super(props, defaultProps as any);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    @computed
    get events(): EntityEvent[]
    {
        return getOrCompute(this, this.props.events);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract isActivated(): boolean;

    abstract description(): TextStore;

    // ----------------------- Private logic ------------------------
}
