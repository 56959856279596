import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { BespokeEntityType } from '../../../BespokeEntityType';
import { RelatedEntityPath } from '../../../../Path/@Model/RelatedEntityPath';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import moment from 'moment';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import hasPack from '../../../../../../../@Api/Pack/hasPack';
import { colorToCss } from '../../../../../DataObject/Type/Color/ColorType';

export class BespokeActivityAppointment extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Appointment')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasRelationshipsByDefinition(
            false,
            this.entityTypeStore.bespoke.types.Activity.Appointment.RelationshipDefinition.Owner,
            commitContext))
        {
            updateRelationship(
                entity,
                false,
                this.entityTypeStore.bespoke.types.Activity.Appointment.RelationshipDefinition.Owner,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }

        if (!entity.hasValueForField(this.types.Activity.Appointment.Field.StartDate, commitContext)
            && !entity.hasValueForField(this.types.Activity.Appointment.Field.EndDate, commitContext))
        {
            setValueByFieldInEntity(
                entity,
                this.types.Activity.Appointment.Field.StartDate,
                moment(new Date()).startOf('hour').add(1, 'hour').toDate(),
                commitContext
            );

            setValueByFieldInEntity(
                entity,
                this.types.Activity.Appointment.Field.EndDate,
                moment(new Date()).startOf('hour').add(2, 'hour').toDate(),
                commitContext
            );
        }
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext)
            || (isInConstructor
                && ((fieldPath.field === this.types.Activity.Appointment.Field.StartDate
                    || fieldPath.field === this.types.Activity.Appointment.Field.EndDate)))
            || (hasPack('TimeRegistration')
                && fieldPath.relationshipDefinition === this.types.Activity.Appointment.RelationshipDefinition.TimeRegistrationActivity
                && !entity.hasValueForField(this.types.Activity.Appointment.Field.HasTimeRegistration))
            || (hasPack('TimeRegistration')
                && fieldPath.relationshipDefinition === this.types.Activity.Appointment.RelationshipDefinition.TimeRegistrationActivity
                &&  entity.getObjectValueByField(this.types.Activity.Appointment.Field.HasTimeRegistration) === false)
            || (hasPack('TimeRegistration')
                && fieldPath.field && fieldPath.field === this.types.Activity.Appointment.Field.HasTimeRegistration
                && !entity.hasRelationshipsByDefinition(true, this.types.Activity.RelationshipDefinition.Relationship));
    }

    isDisabled(entity: Entity,
               fieldPath: EntityFieldPath): boolean
    {
        if (super.isDisabled(entity, fieldPath))
            return true;

        return fieldPath.field && fieldPath.field === this.types.Activity.Appointment.Field.HasTimeRegistration
            && !entity.hasRelationshipsByDefinition(true, this.types.Activity.RelationshipDefinition.Relationship);
    }

    orderByField(entity?: Entity,
                 pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.fromEntityType(this.type).field(this.entityTypeStore.bespoke.types.Activity.Appointment.Field.StartDate);
    }

    isOpenable(entity: Entity,
               pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        if (pathFromRelatedEntity)
        {
            const parentActivity =
                entity.getRelatedEntityByDefinition(
                    true,
                    this.types.Activity.RelationshipDefinition.LinkedActivities);

            return !equalsEntity(
                parentActivity,
                pathFromRelatedEntity.entity);
        }
        else
        {
            return true;
        }
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Activity.Appointment.RelationshipDefinition.AppointmentType,
                    false
                ),
        ];
    }

    getTimelineDateField(isOpen: boolean): EntityFieldPath
    {
        return EntityPath
            .fromEntityType(this.type)
            .field(
                isOpen
                    ? this.types.Activity.Appointment.Field.StartDate
                    : this.types.Entity.Field.CloseDate
            );
    }

    getEntityColorForCalendar(entity: Entity): string
    {
        if (entity.hasRelationshipsByDefinition(false, this.types.Activity.Appointment.RelationshipDefinition.AppointmentType))
        {
            const type = entity.getRelatedEntityByDefinition(false, this.types.Activity.Appointment.RelationshipDefinition.AppointmentType);
            if (type.hasValueForField(this.types.Datastore.Field.Color))
            {
                return colorToCss(type.getObjectValueByField(this.types.Datastore.Field.Color));
            }
        }

        return super.getEntityColorForCalendar(entity);
    }

    // ----------------------- Private logic ------------------------
}
