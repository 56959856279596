import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import TextEditor from '../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';
import useDelayedCallback from '../../../../../@Future/Util/Hook/useDelayedCallback';
import FilterContext from '../../Dataset/Segment/FilterContext/FilterContext';
import constructEntity from '../../../../../@Api/Entity/constructEntity';
import AddButton from '../../../../../@Future/Component/Generic/Button/Variant/AddButton/AddButton';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../@Resource/Theme/Theme';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import View from '../Model/View';
import { ViewSelector } from '../ViewSelector/ViewSelector';
import Segment from '../../Dataset/Model/Segment';
import useDialog from '../../../../../@Service/Navigation/Page/Hooks/useDialog';
import ExportDialog from '../../Export/ExportDialog';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import PackSelector from '../PackSelector/PackSelector';
import RouterContext from '../../../../../@Service/Router/RouterContext';
import CurrentUserContext from '../../../User/CurrentUserContext';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import SelectionOptions from '../../SelectionOptions/SelectionOptions';
import PipelineSelector from '../../Dataset/Segment/PipelineSelector/PipelineSelector';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../@Api/Localization/localizeText';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import Tooltip from '../../../../../@Future/Component/Generic/Tooltip/Tooltip';
import PrimaryButton from '../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import useResizeObserver from 'use-resize-observer/polyfilled';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import FilterEditor from '../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/FilterEditor/FilterEditor';
import { SelectionOptionsValue } from '../../SelectionOptions/Model/SelectionOptionsValue';
import useTypes from '../../Type/Api/useTypes';
import useCount from '../../Selection/Hooks/useCount';
import Chip from '@material-ui/core/Chip';
import { Collapse } from '@material-ui/core';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import { ViewType } from '../Model/ViewType';

export interface HeaderProps
{
    entityType: EntityType;
    viewType: ViewType;
    onChangeViewType: (viewType: ViewType) => void;

    view: View;
    filter?: Predicate;
    onChangeView: (view: View) => void;

    onFilter: (category: Segment, predicate?: Predicate) => void;
    predicateByCategory: Map<Segment, Predicate>;

    additionalFilter: Predicate;
    search?: string;
    onSearch: (query: string) => void;
    hideAddButton?: boolean;

    globalFilter: Filter;
    onGlobalFilter: (filter: Filter) => void;
    isInEditMode: boolean;
    onChangeEditMode: (isInEditMode: boolean) => void;

    pack: Entity;
    onChangePack: (pack: Entity) => void;
    showPackSelector: boolean;

    externalFilter?: Filter;
    isExternalFilterEnabled: boolean;
    toggleExternalFilter: () => void;

    pipeline?: Entity;
    onChangePipeline: (pipeline?: Entity) => void;
    showPipelineSelector?: boolean;
    headerAppendix?: React.ReactNode;

    selectionOptionsValue?: SelectionOptionsValue;
    onToggleSelectionOptionsFilter: (value: boolean) => void;

    showMap?: boolean;
    showDashboard?: boolean;
}

export interface Filter
{
    name: string;
    predicate?: Predicate;
}

const useStyles = makeStyles({
    root: {
        minHeight: 40,
    },
    customTooltip: {
        maxWidth: 'none',
        background: '#fdfdfd',
        padding: 15,
        border: ' #aaa 1px solid',
        borderRadius: 8,
        color: '#444',
        fontWeight: 'normal',
        fontSize: 14,
        boxShadow: '5px 5px 5px 0px #B7B7B7'
    },
    tooltipFilterHeader: {
        fontSize: 14,
        fontWeight: 500
    },
    typeButtons: {
        transition: 'all 1s',
    },
    typeButtonsInDashboard: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: 10,
        borderRadius: 10,
        minWidth: 'fit-content',
    },
});

const Header: React.FC<HeaderProps> =
    props =>
    {
        const classes = useStyles();
        const types = useTypes();
        const { ref, width } = useResizeObserver<HTMLDivElement>();
        const isMobile = useIsMobile();
        const isCompact =
            useMemo(
                () => isMobile || width < 600,
                [
                    isMobile,
                    width
                ]
            );

        const routerStore = useContext(RouterContext);

        const { onChangeEditMode } = props;
        const [ search, setSearch ] = useState<string>(props.search);

        useDelayedCallback(
            search,
            props.onSearch);

        const filter = useContext(FilterContext);
        const columnFilters =
            useComputed(
                () =>
                    (props.view.specification.list?.columns || [])
                        .filter(
                            column =>
                                column.filter !== undefined)
                        .map(
                            column =>
                                column.filter),
                [
                    props.view
                ]);
        const filterWithColumnFilters =
            useComputed(
                () =>
                    filter !== undefined || columnFilters.length > 0
                        ?
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    ...filter ? [ filter ] : [],
                                    ...columnFilters
                                ])
                        :
                            undefined,
                [
                    filter,
                    columnFilters
                ]);

        const onAdd =
            useCallback(
                () =>
                    constructEntity(
                        props.entityType,
                        filterWithColumnFilters),
                [
                    props.entityType,
                    filterWithColumnFilters
                ]);

        const toggleEditMode =
            useCallback(
                () =>
                    onChangeEditMode(!props.isInEditMode),
                [
                    onChangeEditMode,
                    props.isInEditMode
                ]);

        const openImport =
            useCallback(
                () =>
                    routerStore.route(`/type/${props.entityType.id}/import`),
                [
                    props.entityType,
                    routerStore
                ]);

        const [ openExportDialog ] =
            useDialog(
                close =>
                    <ExportDialog
                        entityType={props.entityType}
                        filter={filterWithColumnFilters}
                        fieldPaths={
                            props.view.specification.list
                                ?
                                    props.view.specification
                                        .list
                                        .columns
                                        .map(
                                            column =>
                                                column.fieldPath)
                                :
                                    []
                        }
                        close={close}
                    />,
                [
                    props.entityType,
                    props.view,
                    filterWithColumnFilters
                ]);

        const currentUserStore = useContext(CurrentUserContext);

        const searchUi =
            <TextEditor
                value={search}
                onChange={setSearch}
                placeholder={`${localizeText('Generic.Search', 'Zoeken')}...`}
                outlined
            />;
        const viewFilterOptions =
            useMemo(
                () => ({
                    parameter: props.view.parameter
                }),
                [
                    props.view,
                ]
            );

        const canCreate =
            useMemo(
                () =>
                    currentUserStore.rightProfile.canCreateType(props.entityType)
                    && !props.hideAddButton,
                [
                    props.entityType,
                    currentUserStore.rightProfile,
                    props.hideAddButton,
                ]
            );

        const addButton: React.ReactNode =
            useMemo(
                () =>
                    props.headerAppendix
                        ? <Tooltip
                            title={
                                <LocalizedText
                                    code="Generic.AddEntityType"
                                    value="${entityTypeName} toevoegen"
                                    entityTypeName={props.entityType.getName()}
                                />
                            }
                        >
                            <PrimaryButton
                                label={
                                    <Icon
                                        icon="add"
                                        size="20px"
                                    />
                                }
                                onClick={onAdd}
                                size="sm"
                            />
                        </Tooltip>
                        : <AddButton
                            label={props.entityType.getName()}
                            onClick={onAdd}
                        />,
                [
                    props.headerAppendix,
                    props.entityType,
                    onAdd,
                ]
            );

        const hasSelectionOptionsFilter =
            useMemo(
                () =>
                {
                    const hasFilter = props.selectionOptionsValue !== undefined
                        && props.selectionOptionsValue.filter !== undefined;

                    if (hasFilter && props.selectionOptionsValue.filter instanceof CompositePredicate)
                    {
                        return (props.selectionOptionsValue.filter as CompositePredicate).predicates?.length > 0;
                    }
                    else
                    {
                        return hasFilter;
                    }
                },
                [
                    props.selectionOptionsValue
                ]);
        const [ isSelectionOptionsFilterEnabled, toggleSelectionOptionsFilter ] = useToggle(hasSelectionOptionsFilter);
        const onToggleSelectionOptionsFilter =
            useCallback(
                (value: boolean) =>
                {
                    toggleSelectionOptionsFilter(value);
                    if (props.onToggleSelectionOptionsFilter)
                    {
                        props.onToggleSelectionOptionsFilter(value);
                    }
                },
                [
                    toggleSelectionOptionsFilter,
                    props.onToggleSelectionOptionsFilter
                ]
            );

        const count =
            useCount(
                props.entityType,
                (builder) =>
                    builder
                        .if(
                            () =>
                                filterWithColumnFilters !== undefined,
                            () =>
                            builder.where(
                                cb =>
                                    cb.filter(
                                        filterWithColumnFilters,
                                        viewFilterOptions
                                    )
                            )
                        ),
                [
                    filterWithColumnFilters,
                    viewFilterOptions
                ]
            );

        return <div
            className={classes.root}
            ref={ref}
        >
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        alignment="center"
                    >
                        {
                            !props.isInEditMode &&
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        <div
                                            className={
                                                classNames(
                                                    classes.typeButtons,
                                                    props.viewType === 'Dashboard' && classes.typeButtonsInDashboard
                                                )
                                            }
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                {
                                                    props.view.specification.list &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="view_list"
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="View.ShowAsListView"
                                                                    value="Tonen als lijstweergave"
                                                                />
                                                            }
                                                            onClick={() => props.onChangeViewType('List')}
                                                            color={props.viewType === 'List' ? primaryColor : textSecondaryColor}
                                                        />
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    props.view.specification.column &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="view_column"
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="View.ShowAsColumnView"
                                                                    value="Tonen als kolomweergave"
                                                                />
                                                            }
                                                            onClick={() => props.onChangeViewType('Column')}
                                                            color={props.viewType === 'Column' ? primaryColor : textSecondaryColor}
                                                        />
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    props.showMap &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="pin_drop"
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="View.ShowAsMapView"
                                                                    value="Tonen als kaartweergave"
                                                                />
                                                            }
                                                            onClick={() => props.onChangeViewType('Map')}
                                                            color={props.viewType === 'Map' ? primaryColor : textSecondaryColor}
                                                        />
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    props.showDashboard &&
                                                    <ViewGroupItem>
                                                        <IconButton
                                                            icon="bar_chart"
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="View.ShowAsDashboard"
                                                                    value="Tonen als dashboard"
                                                                />
                                                            }
                                                            onClick={() => props.onChangeViewType('Dashboard')}
                                                            color={props.viewType === 'Dashboard' ? primaryColor : textSecondaryColor}
                                                        />
                                                    </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                        </div>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Collapse
                                            in={props.viewType !== 'Dashboard'}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                                alignment="center"
                                            >
                                                <ViewGroupItem>
                                                    <ViewSelector
                                                        entityType={props.entityType}
                                                        view={props.view}
                                                        onChange={props.onChangeView}
                                                        outlined
                                                    />
                                                </ViewGroupItem>
                                                {
                                                    props.selectionOptionsValue !== undefined &&
                                                    <ViewGroupItem>
                                                        <Tooltip
                                                            placement="bottom"
                                                            customTooltipStyle={classes.customTooltip}
                                                            title={
                                                                <ViewGroup
                                                                    orientation="vertical"
                                                                    spacing={15}
                                                                >
                                                                    {
                                                                        props.view.filter &&
                                                                        <ViewGroupItem
                                                                            className={classes.tooltipFilterHeader}
                                                                        >
                                                                            {`${types.View.Type.getName()}: ${props.view.name}`}
                                                                        </ViewGroupItem>
                                                                    }
                                                                    {
                                                                        props.view.filter &&
                                                                        <ViewGroupItem
                                                                            alignment="center"
                                                                        >
                                                                            <FilterEditor
                                                                                filterParameter={undefined}
                                                                                parameterDictionary={props.view.parameters}
                                                                                value={props.view.filter}
                                                                                onChange={() => {}}
                                                                                disabled
                                                                            />
                                                                        </ViewGroupItem>
                                                                    }
                                                                    {
                                                                        isSelectionOptionsFilterEnabled &&
                                                                        <ViewGroupItem
                                                                            className={classes.tooltipFilterHeader}
                                                                        >
                                                                            <LocalizedText
                                                                                code="Generic.Widget"
                                                                                value="Widget"
                                                                            />: {props.selectionOptionsValue?.view?.name}
                                                                        </ViewGroupItem>
                                                                    }
                                                                    {
                                                                        isSelectionOptionsFilterEnabled &&
                                                                        <ViewGroupItem>
                                                                            <FilterEditor
                                                                                filterParameter={undefined}
                                                                                parameterDictionary={props.view.parameters}
                                                                                value={props.selectionOptionsValue?.filter}
                                                                                onChange={() => {}}
                                                                                disabled
                                                                            />
                                                                        </ViewGroupItem>
                                                                    }
                                                                    {
                                                                        (!props.view.filter && !isSelectionOptionsFilterEnabled) &&
                                                                        <ViewGroupItem
                                                                            className={classes.tooltipFilterHeader}
                                                                        >
                                                                            <LocalizedText
                                                                                code="Generic.NoFilter"
                                                                                value="Geen filter"
                                                                            />
                                                                        </ViewGroupItem>
                                                                    }
                                                                </ViewGroup>
                                                            }
                                                        >
                                                            <IconButton
                                                                icon="filter_list"
                                                                iconSize={20}
                                                                disabled={!hasSelectionOptionsFilter}
                                                                tooltip=""
                                                                onClick={
                                                                    () =>
                                                                        onToggleSelectionOptionsFilter(!isSelectionOptionsFilterEnabled)
                                                                }
                                                                color={
                                                                    isSelectionOptionsFilterEnabled
                                                                        ? primaryColor
                                                                        : textSecondaryColor
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    !isCompact &&
                                                    <ViewGroupItem>
                                                        {searchUi}
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    !isCompact && canCreate &&
                                                    <ViewGroupItem>
                                                        {addButton}
                                                    </ViewGroupItem>
                                                }
                                                {
                                                    !isCompact && props.headerAppendix &&
                                                    <ViewGroupItem>
                                                        {props.headerAppendix}
                                                    </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                        </Collapse>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        }
                        {
                            props.isInEditMode &&
                            <ViewGroupItem>
                                <CardHeader>
                                    {props.entityType.getName(true)}
                                </CardHeader>
                            </ViewGroupItem>
                        }
                        <ViewGroupItem
                            ratio={1}
                        />
                        <ViewGroupItem>
                            <Collapse
                                in={props.viewType !== 'Dashboard'}
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={15}
                                    alignment="center"
                                >
                                    {
                                        props.showPipelineSelector &&
                                        <ViewGroupItem>
                                            <PipelineSelector
                                                entityType={props.entityType}
                                                value={props.pipeline}
                                                onChange={props.onChangePipeline}
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        !props.isInEditMode &&
                                        <ViewGroupItem
                                            ratio={1}
                                        />
                                    }
                                    {
                                        !isCompact &&
                                        <ViewGroupItem>
                                            <IconButton
                                                icon="file_upload"
                                                onClick={openImport}
                                                tooltip={
                                                    <LocalizedText
                                                        code="Generic.Import"
                                                        value="Importeren"
                                                    />
                                                }
                                                color={textSecondaryColor}
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        !isCompact &&
                                        <ViewGroupItem>
                                            <IconButton
                                                icon="file_download"
                                                onClick={openExportDialog}
                                                tooltip={
                                                    <LocalizedText
                                                        code="Generic.Export"
                                                        value="Export"
                                                    />
                                                }
                                                color={textSecondaryColor}
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        props.showPackSelector &&
                                        <ViewGroupItem>
                                            <PackSelector
                                                entityType={props.entityType}
                                                pack={props.pack}
                                                onChange={props.onChangePack}
                                                filter={props.view.filter}
                                                filterOptions={viewFilterOptions}
                                            />
                                        </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <SelectionOptions
                                            entityType={props.entityType}
                                            parameters={props.view.parameters}
                                            name={props.view.name}
                                            filter={filterWithColumnFilters}
                                            itemLayout={props.view.specification.column?.itemLayout}
                                            disableOpenAsList
                                            disableOpenAsMap
                                        />
                                    </ViewGroupItem>
                                    {
                                        currentUserStore.isAllowedInViewConfiguration &&
                                        <ViewGroupItem>
                                            <IconButton
                                                icon="settings"
                                                onClick={toggleEditMode}
                                                tooltip={
                                                    props.isInEditMode
                                                        ?
                                                        <LocalizedText
                                                            code="View.DisableConfigurationMode"
                                                            value="Beheermodus uitschakelen"
                                                        />
                                                        :
                                                        <LocalizedText
                                                            code="View.EnableConfigurationMode"
                                                            value="Beheermodus inschakelen"
                                                        />
                                                }
                                                color={props.isInEditMode ? primaryColor : textSecondaryColor}
                                            />
                                        </ViewGroupItem>
                                    }
                                    {
                                        count !== undefined &&
                                        <ViewGroupItem>
                                            <Chip
                                                size="small"
                                                label={count}
                                            />
                                        </ViewGroupItem>
                                    }
                                </ViewGroup>
                            </Collapse>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                {
                    isCompact &&
                    <ViewGroupItem>
                        <Collapse
                            in={props.viewType !== 'Dashboard'}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    {searchUi}
                                </ViewGroupItem>
                                {
                                    canCreate &&
                                    <ViewGroupItem>
                                        {addButton}
                                    </ViewGroupItem>
                                }
                                {
                                    props.headerAppendix &&
                                    <ViewGroupItem>
                                        {props.headerAppendix}
                                    </ViewGroupItem>
                                }
                            </ViewGroup>
                        </Collapse>
                    </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    };

export default observer(Header);
