import * as React from 'react';
import { BaseComponent } from '../../../../../../@Framework/Component/BaseComponent';
import { Icon, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import { ToolbarIconCommandButtonStore } from './ToolbarIconCommandButtonStore';

@observer
export class ToolbarIconCommandButton extends BaseComponent<ToolbarIconCommandButtonStore>
{

    renderComponent(store: ToolbarIconCommandButtonStore)
    {
        return <IconButton
            key={store.id}
            color={store.isActive ? 'primary' : undefined}
            onClick={() => store.executeCommand()}
            style={{width: '20px', height: '20px', margin: '0px 0px', padding: 0}}
        >
            <Icon
                style={{fontSize: '17px'}}
            >
                {store.icon}
            </Icon>
        </IconButton>;
    }
}
