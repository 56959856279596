import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, observable } from 'mobx';
import { LocalizationStore } from '../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import processImageFile from '../../../@Future/Component/Generic/Input/FileDropZone/ProcessImageFile';

export class FileUploaderStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable isDropZoneActive: boolean = false;
    @observable onFileSelected: (files: File[]) => void;

    // ------------------------ Constructor -------------------------

    constructor(onFileSelected: (files: File[]) => void)
    {
        super();

        this.onFileSelected = onFileSelected;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    onDrop(files: File[])
    {
        return Promise.all(
            files
                .map(
                    file =>
                        processImageFile(file)))
            .then(
                processedFiles =>
                {
                    if (processedFiles.length > 0)
                    {
                        if (this.onFileSelected)
                        {
                            this.onFileSelected(processedFiles);
                        }

                        this.onDropZoneDragLeave();
                    }
                });
    }

    @action.bound
    onDropZoneDragEnter()
    {
        if (!this.isDropZoneActive)
        {
            this.isDropZoneActive = true;
        }
    }

    @action.bound
    onDropZoneDragLeave()
    {
        this.isDropZoneActive = false;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
