import { observable } from 'mobx';
import * as minimatch from 'minimatch';
import TransactionalContext from './TransactionalContext';

export type TransactionalPredicate<T = any> = (context: TransactionalContext<T>) => boolean;

export default class TransactionalConfiguration
{
    // ------------------------- Properties -------------------------

    @observable paths: RegExp[];
    @observable isInclusive: boolean;
    @observable predicate?: TransactionalPredicate;

    // ------------------------ Constructor -------------------------

    constructor(paths: RegExp[] = [],
                isInclusive: boolean = true,
                predicate?: TransactionalPredicate)
    {
        this.paths = paths;
        this.isInclusive = isInclusive;
        this.predicate = predicate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromPaths(paths: string[],
                     isInclusive: boolean = true,
                     predicate?: TransactionalPredicate): TransactionalConfiguration
    {
        return new TransactionalConfiguration(
            paths.map(
                path =>
                    minimatch.makeRe(path)),
            isInclusive,
            predicate);
    }

    includes(context: TransactionalContext<any>): boolean
    {
        if (this.predicate && !this.predicate(context))
        {
            return false;
        }

        if (this.isInclusive)
        {
            return this.paths.some(
                path =>
                    path.test(context.formattedKeyPath));
        }
        else
        {
            return this.paths.every(
                path =>
                    !path.test(context.formattedKeyPath));
        }
    }

    // ----------------------- Private logic ------------------------
}
