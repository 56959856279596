import React, { Dispatch, SetStateAction, useState } from 'react';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import Activity from './Content/Activity/Activity';
import Relationship from './Content/Relationship/Relationship';
import Relation from './Content/Relation/Relation';
import useTypes from '../Type/Api/useTypes';
import ViewerEntityContext from './Context/ViewerEntity/ViewerEntityContext';
import OpenedEntityContext from './Context/OpenedEntity/OpenedEntityContext';
import AttachmentPaster from '../Bespoke/Attachment/Paster/AttachmentPaster';
import HasInsetContext from './Context/HasInsetContext';
import IsCompactContext from './Context/IsCompactContext';
import Template from './Content/Template/Template';
import CustomEntity from './Content/CustomEntity/CustomEntity';
import Product from './Content/Product/Product';
import Datastore from './Content/Datastore/Datastore';
import Import from './Content/Import/Import';
import TimeRegistrationActivity from './Content/TimeRegistrationActivity/TimeRegistrationActivity';
import Team from './Content/Team/Team';
import Automation from './Content/Automation/Automation';
import Form from './Content/Form/Form';
import { Role } from './Content/Role/Role';
import System from './Content/System/System';
import Default from './Content/Default/Default';
import Mailbox from './Content/Mailbox/Mailbox';
import Resource from './Content/Resource/Resource';
import BulkDeletion from './Content/BulkDeletion/BulkDeletion';
import Portal from './Content/Portal/Portal';
import PortalPage from './Content/PortalPage/PortalPage';
import EventParticipation from './Content/EventParticipation/EventParticipation';
import PortalUser from './Content/PortalUser/PortalUser';
import PriceList from './Content/PriceList/PriceList';
import Price from './Content/Price/Price';
import Webhook from './Content/Webhook/Webhook';
import CustomWidget from './Content/CustomWidget/CustomWidget';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import CalendarItem from './Content/CalendarItem/CalendarItem';
import CourseTemplate from './Content/CourseTemplate/CourseTemplate';
import CourseLesson from './Content/CourseLesson/CourseLesson';
import InternalEnvironment from './Content/InternalEnvironment/InternalEnvironment';
import { LeexiCall } from './Content/LeexiCall/LeexiCall';
import { LeexiCallCompletion } from './Content/LeexiCompletion/LeexiCallCompletion';

export type EntityViewerCommand = 'system';

export interface EntityViewerContentProps
{
    entity: Entity;
    commitContext: CommitContext;
    inset?: boolean;
    compact?: boolean;
    openedEntity?: [Entity | undefined, Dispatch<SetStateAction<Entity | undefined>>];
    command?: EntityViewerCommand;
}

const EntityViewerContent: React.FC<EntityViewerContentProps> =
    ({
        entity,
        commitContext,
        inset,
        compact,
        openedEntity,
        command
     }) =>
    {
        const types = useTypes();
        let content: JSX.Element;

        if (command === 'system')
        {
            content =
                <System
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Relation.Type))
        {
            content =
                <Relation
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.Relationship.Type))
        {
            content =
                <Relationship
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.Activity.Type))
        {
            content =
                <Activity
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.CustomEntity.Type))
        {
            content =
                <CustomEntity
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.Product.Type))
        {
            content =
                <Product
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.PriceList.Type))
        {
            content =
                <PriceList
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Price.Type))
        {
            content =
                <Price
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Datastore.Type))
        {
            content =
                <Datastore
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.TimeRegistrationActivity.Type))
        {
            content =
                <TimeRegistrationActivity
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.Template.Type))
        {
            content =
                <Template
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.Import.Type))
        {
            content =
                <Import
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.BulkDeletion.Type))
        {
            content =
                <BulkDeletion
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Team.Type))
        {
            content =
                <Team
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Role.Type))
        {
            content =
                <Role
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Automation.Type))
        {
            content =
                <Automation
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.Form.Type))
        {
            content =
                <Form
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Mailbox.Type))
        {
            content =
                <Mailbox
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Resource.Type))
        {
            content =
                <Resource
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Portal.Type))
        {
            content =
                <Portal
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.PortalPage.Type))
        {
            content =
                <PortalPage
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.PortalUser.Type))
        {
            content =
                <PortalUser
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.EventParticipation.Type))
        {
            content =
                <EventParticipation
                    entity={entity}
                />;
        }
        else if (entity.entityType.isA(types.Webhook.Type))
        {
            content =
                <Webhook
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.CustomWidget.Type))
        {
            content =
                <CustomWidget
                    entity={entity}
                />
        }
        else if (entity.entityType.isA(types.CalendarItem.Type))
        {
            content =
                <CalendarItem
                    entity={entity}
                />
        }
        else if (entity.entityType.isA(types.CourseTemplate.Type))
        {
            content =
                <CourseTemplate
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.CourseLesson.Type))
        {
            content =
                <CourseLesson
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.InternalEnvironment.Type))
        {
            content =
                <InternalEnvironment
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.LeexiCall.Type))
        {
            content =
                <LeexiCall
                    entity={entity}
                    commitContext={commitContext}
                />;
        }
        else if (entity.entityType.isA(types.LeexiCallCompletion.Type))
        {
            content =
                <LeexiCallCompletion
                    entity={entity}
                />;
        }
        else
        {
            content =
                <Default
                    entity={entity}
                />;
        }

        let finalOpenedEntity = useState<Entity>();

        if (openedEntity)
        {
            finalOpenedEntity = openedEntity;
        }

        return <ViewerEntityContext.Provider
            value={entity}
        >
            <OpenedEntityContext.Provider
                value={finalOpenedEntity}
            >
                <HasInsetContext.Provider
                    value={inset}
                >
                    <IsCompactContext.Provider
                        value={compact}
                    >
                        <AttachmentPaster
                            entity={entity}
                        >
                            {content}
                        </AttachmentPaster>
                    </IsCompactContext.Provider>
                </HasInsetContext.Provider>
            </OpenedEntityContext.Provider>
        </ViewerEntityContext.Provider>;
    };

export default observer(EntityViewerContent);
