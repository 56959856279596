import { useContext, useEffect, useState } from 'react';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import EntityTypeContext from '../../Type/EntityTypeContext';
import { DataObject } from '../../../DataObject/Model/DataObject';
import AutoReloadContext from '../Context/AutoReloadContext';
import { AutoReloadInterval } from '../Context/AutoReloadConstants';
import { SelectionHint } from '../../../../../@Api/Model/Implementation/SelectionHint';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';
import { EntityConstraintBuilderFilterOptions } from '../../Selection/Builder/Predicate/Model/EntityConstraintBuilderFilterOptions';

export default function useCount(entityType: EntityType,
                                 filter?: Predicate,
                                 filterOptions?: EntityConstraintBuilderFilterOptions,
                                 autoReload?: boolean,
                                 selectionHints: SelectionHint[] = [])
{
    const [ count, setCount ] = useState<DataObject>(undefined);
    const entityTypeStore = useContext(EntityTypeContext);
    const doAutoReload = useContext(AutoReloadContext) || autoReload;
    const isMounted = useIsMounted();

    useEffect(
        () =>
        {
            const selectionBuilder =
                new EntitySelectionBuilder(entityType)
                    .if(
                        () =>
                            filter !== undefined,
                        builder =>
                            builder.where(
                                cb =>
                                    cb.filter(
                                        filter,
                                        filterOptions
                                    )
                            )
                    )
                    .if(
                        () => true,
                        builder =>
                            selectionHints.forEach(
                                hint =>
                                    builder.hint(hint)));

            function loadCount()
            {
                selectionBuilder
                    .count(doAutoReload)
                    .then(
                        count =>
                        {
                            if (isMounted())
                            {
                                setCount(count);
                            }
                        });
            }

            loadCount();

            if (doAutoReload)
            {
                const interval =
                    setInterval(
                        () =>
                            loadCount(),
                        AutoReloadInterval);

                return () =>
                {
                    selectionBuilder.dispose();
                    clearInterval(interval);
                }
            }
            else
            {
                return () =>
                    selectionBuilder.dispose();
            }
        },
        [
            entityType,
            entityTypeStore,
            filter,
            filterOptions,
            setCount,
            doAutoReload,
            isMounted
        ]);

    return count;
}
