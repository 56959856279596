import AutomationDependencyContext from '../AutomationDependencyContext';
import Parameter from '../Parameter/Parameter';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import MutationTrigger from './MutationTrigger';
import localizeText from '../../Localization/localizeText';

export default class DeletionTrigger extends MutationTrigger
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameters(): Parameter<any>[]
    {
        return [
            this.getEntityParameter(),
            this.getUserParameter(),
            this.getDateParameter()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        super.augmentDescriptor(descriptor);

        descriptor.type = 'Deletion';
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new DeletionTrigger(
            loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId),
            undefined);
    }

    getName(): string
    {
        return localizeText(
            'DeletionTriggerName',
            'Na het verwijderen van een ${type}',
            {
                type: this.entityType?.getName()?.toLowerCase() ?? '[ ? ]',
            }
        );
    }

    // ----------------------- Private logic ------------------------
}
