import React from 'react';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Default from '../Default';
import { observer } from 'mobx-react-lite';
import { ContentProps } from '../Content';
import useTypes from '../../../Type/Api/useTypes';
import CurrencyEditor from '../../../Currency/CurrencyEditor';

export interface PriceListProps extends ContentProps
{

}
const PriceList: React.FC<PriceListProps> =
    props =>
    {
        const types = useTypes();

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Default
                    {...props}
                    entity={props.entity}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <CurrencyEditor
                    entity={props.entity}
                    field={types.PriceList.Field.Currency}
                    commitContext={props.commitContext}
                    withLabel
                />
            </ViewGroupItem>
        </ViewGroup>
    };

export default observer(PriceList);