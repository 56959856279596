import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { successColor } from '../../../../../../@Resource/Theme/Theme';

export interface SuccessTextButtonProps extends LabelButtonProps
{

}

const SuccessTextButton: React.FC<SuccessTextButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

SuccessTextButton.defaultProps = {
    color: successColor,
    hoverColor: successColor
};

export default SuccessTextButton;
