import { EntityType } from '../Model/Implementation/EntityType';
import useSetting from '../../@Component/Domain/Setting/Api/useSetting';
import { SettingSource } from '../../@Component/Domain/Setting/SettingStore';
import { useComputed } from 'mobx-react-lite';
import { useCallback } from 'react';

export default function useMetadataSettingFlag(entityType: EntityType,
                                               code: string): [ boolean, (isChecked: boolean) => void ]
{
    const [ setting, setSetting ] = useSetting<number[]>(SettingSource.Organization, code, true);
    const isEnabled =
        useComputed(
            () =>
                setting.indexOf(entityType.id) >= 0,
            [
                setting,
                entityType
            ]);
    const setEnabled =
        useCallback(
            (isChecked: boolean) =>
            {
                if (isChecked)
                {
                    setSetting(
                        [
                            ...setting,
                            entityType.id
                        ]);
                }
                else
                {
                    setSetting(
                        setting
                            .filter(
                                id =>
                                    entityType.id !== id));
                }
            },
            [
                setting,
                setSetting,
                entityType
            ]);

    return [ isEnabled, setEnabled ];
}
