import Addressee from '../Model/Addressee';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getPersonNameFromAddressee from './getPersonNameFromAddressee';
import getOrganizationNameFromAddressee from './getOrganizationNameFromAddressee';
import { loadModuleDirectly } from '../../../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../../Type/EntityTypeStore';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';

export default function augmentRelationshipWithAddressee(
    relationship: Entity,
    addressee: Addressee,
    commitContext?: CommitContext
)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    if (relationship.entityType.isA(types.Relationship.Person.Type))
    {
        const person =
            relationship.getRelatedEntityByDefinition(
                false,
                types.Relationship.Person.RelationshipDefinition.Person
            );

        if (!person.hasValueForField(types.Relation.Person.Field.FirstName, commitContext)
            && !person.hasValueForField(types.Relation.Person.Field.MiddleName, commitContext)
            && !person.hasValueForField(types.Relation.Person.Field.LastName, commitContext))
        {
            person.setName(
                getPersonNameFromAddressee(addressee),
                commitContext
            );
        }

        if (relationship.entityType.isA(types.Relationship.Person.Contact.Type))
        {
            setValueByFieldInEntity(
                relationship,
                types.Relationship.Person.Contact.Field.EmailAddress,
                addressee.email,
                commitContext
            );
            setValueByFieldInEntity(
                person,
                types.Relation.Person.Field.EmailAddress,
                undefined,
                commitContext
            );
        }
        else
        {
            setValueByFieldInEntity(
                relationship,
                types.Relationship.Person.Contact.Field.EmailAddress,
                undefined,
                commitContext
            );
            setValueByFieldInEntity(
                person,
                types.Relation.Person.Field.EmailAddress,
                addressee.email,
                commitContext
            );
        }
    }
    else if (relationship.entityType.isA(types.Relationship.Organization.Type))
    {
        const organization =
            relationship.getRelatedEntityByDefinition(
                false,
                types.Relationship.Organization.RelationshipDefinition.Organization,
                commitContext
            );

        if (!organization.hasValueForField(types.Relation.Organization.Field.Name))
        {
            organization.setName(
                getOrganizationNameFromAddressee(addressee),
                commitContext
            );
        }

        setValueByFieldInEntity(
            organization,
            types.Relation.Organization.Field.EmailAddress,
            addressee.email,
            commitContext
        );
    }
}
