import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ApiControllerContext from '../../../../../../../@Api/Controller/ApiControllerContext';
import { User } from '../../../../../../../@Api/Model/Implementation/User';
import AuthenticationManagerContext from '../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import PortalUserContext from '../../User/PortalUserContext';
import { runInAction } from 'mobx';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';

export interface PortalSwitcherTabBarProps
{

}

const PortalSwitcherTabBar: React.FC<PortalSwitcherTabBarProps> =
    props =>
    {
        const apiControllerContext = useContext(ApiControllerContext);
        const authenticationManagerContext = useContext(AuthenticationManagerContext);
        const portalUser = useContext(PortalUserContext);
        const currentUser =
            useComputed(
                () =>
                    portalUser.get(),
                [
                    portalUser
                ]);
        const setCurrentUser =
            useCallback(
                (user: User) =>
                    runInAction(
                        () =>
                            portalUser.set(user)),
                [
                    portalUser
                ]);
        const [ users ] =
            useAsyncResult(
                () =>
                    apiControllerContext.accountUserController.getUsersInAccount(),
            [
                apiControllerContext
            ]);
        const switchEnvironmentCallback =
            useCallback(
                (user: User) =>
                {
                    authenticationManagerContext
                        .switchToUser(user)
                        .then(
                            () =>
                                setCurrentUser(user));
                },
                [
                    setCurrentUser,
                    authenticationManagerContext
                ]);
        const portalUsersInSameOrganization =
            useComputed(
                () =>
                    (users || [])
                        .filter(
                            user =>
                                user.organization.id === currentUser?.organization.id
                                && user.portal !== undefined),
                [
                    users
                ]);

        return <Card
            square
        >
            <TabBar
                value={currentUser?.id}
            >
                {
                    portalUsersInSameOrganization.map(
                        user =>
                            <Tab
                                key={user.id}
                                onClick={() => switchEnvironmentCallback(user)}
                                value={user.id}
                            >
                                {user.portal?._name} {user.description ? `(${user.description})` : ''}
                            </Tab>
                    )
                }
            </TabBar>
        </Card>;
    };

export default observer(PortalSwitcherTabBar);
