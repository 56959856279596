import React, { Fragment } from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import BlockOptionsPanel from '../Settings/BlockOptionsPanel';
import ColorOptionsPanel from '../Settings/ColorOptionsPanel';
import GutenbergBlockType from '../../Gutenberg/GutenbergBlockType';
import { GutenbergBlockHtmlId } from '../../DocumentEditor';
import HtmlEditor from '../../../HtmlEditor/HtmlEditor';
import StyledBlock from '../StyledBlock/StyledBlock';
import Icon from '../../../../Icon/Icon';

const GutenbergBlockHtml: GutenbergBlockType =
{
    name: GutenbergBlockHtmlId,
    configuration:
        {
            title: {
                code: 'GutenbergBlock.HTML',
                value: 'HTML'
            },
            icon: <Icon icon="code" />,
            category: 'common',

            attributes:
            {
                content:
                {
                    type: 'string',
                    source: 'html',
                    selector: 'div',
                    query: undefined,
                },
            },

            edit: props =>
            {
                return (
                    <Fragment>
                        <InspectorControls>
                            <BlockOptionsPanel props={props} />
                            <ColorOptionsPanel props={props} />
                        </InspectorControls>
                        <StyledBlock
                            block={props}
                        >
                            <HtmlEditor
                                value={(props.attributes as any).html}
                                onChange={html => props.setAttributes({ html: html })}
                            />
                        </StyledBlock>
                    </Fragment>
                );
            },

            save: props =>
            {
                return <p>
                    {(props.attributes as any).html}
                </p>;
            }
        },

    view: block =>
    {
        return <StyledBlock
            block={block}
        />;
    }
};

export default GutenbergBlockHtml;
