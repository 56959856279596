import { observable } from 'mobx';
import { BespokeEntityType } from '../../BespokeEntityType';
import { EntityTypeStore } from '../../EntityTypeStore';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { EntityRelationshipDefinition } from '../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { RelatedEntityPath } from '../../../Path/@Model/RelatedEntityPath';
import { EntityRelationshipDefinitionTuple } from '../../../@Model/EntityRelationshipDefinitionTuple';
import { BespokeActivityEmail } from './Email/BespokeActivityEmail';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { BespokeActivityTask } from './Task/BespokeActivityTask';
import { BespokeActivityOffer } from './Offer/BespokeActivityOffer';
import { BespokeActivitySalesOpportunity } from './SalesOpportunity/BespokeActivitySalesOpportunity';
import { BespokeActivityInvoice } from './Invoice/BespokeActivityInvoice';
import { BespokeActivityDocument } from './Document/BespokeActivityDocument';
import { BespokeActivityProject } from './Project/BespokeActivityProject';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import { BespokeActivitySubscription } from './Subscription/BespokeActivitySubscription';
import { BespokeActivityAppointment } from './Appointment/BespokeActivityAppointment';
import { isAttachedType } from '../../../Item/Navigator/Api/getIsAttachedType';
import { BespokeActivityCampaign } from './Campaign/BespokeActivityCampaign';
import { BespokeActivityWorkOrder } from './WorkOrder/BespokeActivityWorkOrder';
import { BespokeActivitySalesOrder } from './SalesOrder/BespokeActivitySalesOrder';
import { BespokeActivityEvent } from './Event/BespokeActivityEvent';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { getModel } from '../../../../../../@Util/TransactionalModelV2/index';
import getMetadataSettingFlag from '../../../../../../@Api/Metadata/getMetadataSettingFlag';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import { BespokeActivityCourse } from './Course/BespokeActivityCourse';
import { BespokeActivityCourseAttendance } from './CourseAttendance/BespokeActivityCourseAttendance';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import { DataObjectRepresentationProps } from '../../../../DataObject/Model/DataObjectRepresentation';
import { getRelationshipAndContactInitializationPathsInViewer } from '../../Api/getRelationshipAndContactInitializationPathsInViewer';
import { extendContactRelationshipSelection } from '../../Api/extendContactRelationshipSelection';
import { onRelationshipUpdateCheckAndSetContact } from '../../Api/onRelationshipUpdateCheckAndSetContact';

export class BespokeActivity extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable appointment: BespokeActivityAppointment;
    @observable task: BespokeActivityTask;
    @observable email: BespokeActivityEmail;
    @observable salesOpportunity: BespokeActivitySalesOpportunity;
    @observable offer: BespokeActivityOffer;
    @observable invoice: BespokeActivityInvoice;
    @observable project: BespokeActivityProject;
    @observable subscription: BespokeActivitySubscription;
    @observable document: BespokeActivityDocument;
    @observable campaign: BespokeActivityCampaign;
    @observable workOrder: BespokeActivityWorkOrder;
    @observable salesOrder: BespokeActivitySalesOrder;
    @observable event: BespokeActivityEvent;
    @observable course: BespokeActivityCourse;
    @observable courseAttendance: BespokeActivityCourseAttendance;

    currencyFields: EntityField[];

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity')
    {
        super(entityTypeStore, code);

        this.appointment = new BespokeActivityAppointment(this.entityTypeStore);
        this.task = new BespokeActivityTask(this.entityTypeStore);
        this.email = new BespokeActivityEmail(this.entityTypeStore);
        this.salesOpportunity = new BespokeActivitySalesOpportunity(this.entityTypeStore);
        this.offer = new BespokeActivityOffer(this.entityTypeStore);
        this.invoice = new BespokeActivityInvoice(this.entityTypeStore);
        this.project = new BespokeActivityProject(this.entityTypeStore);
        this.subscription = new BespokeActivitySubscription(this.entityTypeStore);
        this.document = new BespokeActivityDocument(this.entityTypeStore);
        this.campaign = new BespokeActivityCampaign(this.entityTypeStore);
        this.workOrder = new BespokeActivityWorkOrder(this.entityTypeStore);
        this.salesOrder = new BespokeActivitySalesOrder(this.entityTypeStore);
        this.event = new BespokeActivityEvent(this.entityTypeStore);
        this.course = new BespokeActivityCourse(this.entityTypeStore);
        this.courseAttendance = new BespokeActivityCourseAttendance(this.entityTypeStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getInitializationPathsInViewer(rootPath: EntityPath): EntityPath[]
    {
        // If entity is an activity, fetch the organization, contact and the activities to which it is linked
        const paths = super.getInitializationPathsInViewer(rootPath);

        const pathToParentActivity =
            rootPath.joinTo(
                this.types.Activity.RelationshipDefinition.LinkedActivities,
                true);
        paths.push(pathToParentActivity);

        const relationshipAndContactPaths =
            getRelationshipAndContactInitializationPathsInViewer(
                rootPath,
                this.types.Relationship.RelationshipDefinition.Activities,
                this.types.Relationship.Person.Contact.RelationshipDefinition.Activities
            );

        paths.push(
            ...relationshipAndContactPaths
        );

        return paths;
    }

    extendRelationshipSelection(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                builder: EntitySelectionBuilder,
                                commitContext?: CommitContext)
    {
        super.extendRelationshipSelection(
            entity,
            relationshipDefinition,
            isParent,
            builder,
            commitContext
        );

        const types = this.types;

        if (relationshipDefinition === types.Relationship.Person.Contact.RelationshipDefinition.Activities
            && isParent)
        {
            extendContactRelationshipSelection(
                types.Relationship.RelationshipDefinition.Activities,
                true,
                entity,
                builder,
                commitContext
            );
        }
        else if (relationshipDefinition === types.Activity.RelationshipDefinition.LinkedActivities
            && isParent)
        {
            builder.where(
                cb =>
                    types.Activity.Type.getAllInstantiableTypes()
                        .filter(
                            type =>
                                isAttachedType(this.entityTypeStore, type))
                        .forEach(
                            type =>
                                cb.isNotOfType(
                                    builder.rootPath,
                                    type,
                                    false,
                                    false)));

            const relationship =
                entity.getRelatedEntityByDefinition(
                    true,
                    types.Relationship.RelationshipDefinition.Activities,
                    commitContext
                );

            if (relationship)
            {
                builder.where(
                    cb =>
                        cb.relatedToEntity(
                            builder.rootPath
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true),
                            relationship));
            }
        }
        else if (relationshipDefinition === types.Milestone.RelationshipDefinition.Activities
            && isParent)
        {
            const activity =
                entity.getRelatedEntityByDefinition(
                    true,
                    types.Activity.RelationshipDefinition.LinkedActivities,
                    commitContext
                );

            if (activity?.entityType.isA(types.Activity.Project.Type))
            {
                builder.where(
                    cb =>
                        cb.relatedToEntity(
                            builder.rootPath
                                .joinTo(
                                    types.Activity.Project.RelationshipDefinition.Milestones,
                                    true),
                            activity));
            }
        }
    }

    isPluralInInterface(entity: Entity,
                        relationshipDefinition: EntityRelationshipDefinition,
                        isParent: boolean): boolean
    {
        if (relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && isParent)
        {
            return false;
        }
        else
        {
            return super.isPluralInInterface(entity, relationshipDefinition, isParent);
        }
    }

    hideCaptionInSelectboxValue(entity: Entity,
                                relationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean): boolean
    {
        const types = this.types;

        return super.hideCaptionInSelectboxValue(entity, relationshipDefinition, isParent)
            || (relationshipDefinition === types.Relationship.Person.Contact.RelationshipDefinition.Activities
                && isParent)
            || (relationshipDefinition === types.Activity.RelationshipDefinition.LinkedActivities
                && isParent);
    }

    getLabelInInterface(entity: Entity, fieldPath: EntityFieldPath): string
    {
        if (fieldPath.relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && fieldPath.isParentRelationship)
        {
            return this.types.Activity.Type.getName();
        }
        else
        {
            return super.getLabelInInterface(entity, fieldPath);
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        const types = this.types;

        // In case of an update of the relationship field, then check if it is a contact and set the contact field
        if (relationshipDefinition === types.Relationship.RelationshipDefinition.Activities
            && isParent)
        {
            await onRelationshipUpdateCheckAndSetContact(
                true,
                types.Relationship.RelationshipDefinition.Activities,
                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                entity,
                relatedEntity,
                commitContext
            );
        }
        // In case of linking a new activity to another activity, copy its relationship and contact (if not yet present)
        else if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            [
                this.types.Relationship.RelationshipDefinition.Activities,
                this.types.Relationship.Person.Contact.RelationshipDefinition.Activities
            ]
                .filter(
                    relationshipDefinition =>
                        !entity.hasRelationshipsByDefinition(true, relationshipDefinition, commitContext))
                .forEach(
                    relationshipDefinition =>
                        updateRelationship(
                            entity,
                            true,
                            relationshipDefinition,
                            relatedEntity.getRelatedEntityByDefinition(
                                true,
                                relationshipDefinition),
                            commitContext
                        )
                );
        }
    }

    onConstructFromRelationship(entity: Entity,
                                fromEntity: Entity,
                                fromRelationshipDefinition: EntityRelationshipDefinition,
                                isParent: boolean,
                                commitContext?: CommitContext)
    {
        super.onConstructFromRelationship(entity, fromEntity, fromRelationshipDefinition, isParent, commitContext);

        if (fromRelationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && isParent)
        {
            if (!entity.hasRelationshipsByDefinition(
                true,
                this.types.Relationship.RelationshipDefinition.Activities,
                commitContext))
            {
                updateRelationship(
                    entity,
                    true,
                    this.types.Relationship.RelationshipDefinition.Activities,
                    getModel(
                        fromEntity.getRelatedEntityByDefinition(
                            true,
                            this.types.Relationship.RelationshipDefinition.Activities)
                    ), // ensure fresh transactional model
                    commitContext
                );
            }

            if (!entity.hasRelationshipsByDefinition(
                true,
                this.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                commitContext))
            {
                updateRelationship(
                    entity,
                    true,
                    this.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                    getModel(
                        fromEntity.getRelatedEntityByDefinition(
                            true,
                            this.types.Relationship.Person.Contact.RelationshipDefinition.Activities)
                    ), // ensure fresh transactional model
                    commitContext
                );
            }
        }
    }

    includeFieldPathDuringConstruction(entity: Entity, fieldPath: EntityFieldPath): boolean
    {
        return super.includeFieldPathDuringConstruction(entity, fieldPath)
            || (fieldPath.relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
                && fieldPath.isParentRelationship
                && isAttachedType(this.entityTypeStore, entity.entityType));
    }

    showAssigneeOrOwnerInPrimaryAvatar(entity: Entity,
                                       pathFromRelatedEntity?: RelatedEntityPath): boolean
    {
        return pathFromRelatedEntity !== undefined;
    }

    hasAvatarInCard(entity: Entity): boolean
    {
        return false;
    }

    getRelationshipDefinitionsToDisplayInCards(entity: Entity): EntityRelationshipDefinitionTuple[]
    {
        return [
            new EntityRelationshipDefinitionTuple(
                this.types.Relationship.RelationshipDefinition.Activities,
                true),
            new EntityRelationshipDefinitionTuple(
                this.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                true)
        ];
    }

    getAvatarFromEntity(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Relationship.RelationshipDefinition.Activities);
    }

    isOwnerInListCaption(entity: Entity,
                         owner: Entity,
                         relationshipDefinition: EntityRelationshipDefinition,
                         pathFromRelatedEntity?: RelatedEntityPath)
    {
        // In case of displaying this activity in the related section
        if (pathFromRelatedEntity)
        {
            // In case of an organization: only show the activity to which this activity is linked
            // and the contact to which this activity is linked
            if (pathFromRelatedEntity.entity.entityType.isA(this.types.Relation.Organization.Type))
            {
                return relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
                    || relationshipDefinition === this.types.Relationship.Person.Contact.RelationshipDefinition.Activities;
            }
            // In case of a person: only show the activity to which this activity is linked to
            else if (pathFromRelatedEntity.entity.entityType.isA(this.types.Relation.Person.Type))
            {
                return relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities;
            }
            // In case the caption is shown on the card itself, then only return linked activities
            else if (pathFromRelatedEntity.entity === entity)
            {
                return relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities;
            }
            else
            {
                return false;
            }
        }
        else
        {
            return relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
                || relationshipDefinition === this.types.Relationship.RelationshipDefinition.Activities;
        }
    }

    allowAddingNoteOrAttachments(entity: Entity): boolean
    {
        return true;
    }

    hideFieldPath(entity: Entity,
                  fieldPath: EntityFieldPath,
                  fromFieldPath?: EntityFieldPath,
                  isInConstructor?: boolean,
                  commitContext?: CommitContext): boolean
    {
        if (super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor, commitContext))
            return true;

        const types = this.types;

        if (fieldPath.relationshipDefinition === types.Relationship.RelationshipDefinition.Activities
            && fieldPath.isParentRelationship)
        {
            if (fromFieldPath)
            {
                return fromFieldPath.relationshipDefinition === types.Activity.RelationshipDefinition.LinkedActivities
                    && !fromFieldPath.isParentRelationship;
            }
            else
            {
                return false;
            }
        }
        else if (fieldPath.relationshipDefinition === types.Relationship.Person.Contact.RelationshipDefinition.Activities
            && fieldPath.isParentRelationship)
        {
            if (fromFieldPath
                && fromFieldPath.relationshipDefinition === types.Activity.RelationshipDefinition.LinkedActivities
                && !fromFieldPath.isParentRelationship)
            {
                return true;
            }

            const relationship =
                entity.getRelatedEntityByDefinition(
                    true,
                    types.Relationship.RelationshipDefinition.Activities,
                    commitContext
                );

            if (relationship)
            {
                return !relationship.entityType.isA(types.Relationship.Organization.Type);
            }
            else
            {
                return true;
            }
        }
        else if (fieldPath.relationshipDefinition === types.Activity.RelationshipDefinition.LinkedActivities
            && fieldPath.isParentRelationship)
        {
            if (fromFieldPath)
            {
                return fromFieldPath.relationshipDefinition === types.Activity.RelationshipDefinition.LinkedActivities
                    && !fromFieldPath.isParentRelationship;
            }
            else
            {
                return false;
            }
        }
        else if (types.Activity.RelationshipDefinition.PriceList
            && fieldPath.relationshipDefinition === types.Activity.RelationshipDefinition.PriceList
            && !fieldPath.isParentRelationship)
        {
            return !getMetadataSettingFlag(
                entity.entityType,
                Setting.Metadata.HasProducts
            );
        }
        else
        {
            return false;
        }
    }

    isAssignableToTeam(): boolean
    {
        return true;
    }

    inheritTeamFrom(): EntityPath
    {
        return EntityPath.root(this.type)
            .joinTo(
                this.types.Relationship.RelationshipDefinition.Activities,
                true);
    }

    getEntityToOpen(entity: Entity): Entity
    {
        return entity; // this.getLinkedAction(entity) || entity;
    }

    getEntityToOpenInSidebar(entity: Entity): Entity
    {
        // Open first linked activity in sidebar
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Activity.RelationshipDefinition.LinkedActivities);
    }

    getEntityToShowTypeAvatarOf(entity: Entity): Entity
    {
        return entity.getRelatedEntityByDefinition(
            true,
            this.types.Activity.RelationshipDefinition.LinkedActivities);
    }

    getInitializationPathsInTimeline(entity: Entity, rootPath: EntityPath): EntityPath[]
    {
        return [
            ...super.getInitializationPathsInTimeline(entity, rootPath),
            EntityPath.fromEntity(entity)
                .joinTo(
                    this.types.Activity.RelationshipDefinition.LinkedActivities,
                    false)
                .joinTo(
                    this.types.Entity.RelationshipDefinition.Notes,
                    false),
        ];
    }

    getAddPathsInTimeline(entity: Entity, rootPath: EntityPath): RelatedEntityPath[]
    {
        const pathToActivities =
            rootPath
                .joinTo(
                    this.types.Activity.RelationshipDefinition.LinkedActivities,
                    false);

        return [
            ...super.getAddPathsInTimeline(entity, rootPath),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.types.Activity.Task.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.types.Activity.Email.Type)),
            new RelatedEntityPath(
                entity,
                pathToActivities.castTo(this.types.Activity.Project.Type))
        ];
    }

    getSearchFieldPaths(rootPath: EntityPath): EntityFieldPath[]
    {
        return [
            ...super.getSearchFieldPaths(rootPath),
            rootPath
                .joinTo(
                    this.types.Relationship.RelationshipDefinition.Activities,
                    true)
                .field(this.types.Entity.Field.Name),
            rootPath
                .joinTo(
                    this.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                    true)
                .field(this.types.Entity.Field.Name)
        ];
    }

    getEventTitle(entity: Entity): string
    {
        const relationship =
            entity.getRelatedEntityByDefinition(
                true,
                this.types.Relationship.RelationshipDefinition.Activities);

        if (relationship)
        {
            return `${relationship.name} - ${super.getEventTitle(entity)}`;
        }
        else
        {
            return super.getEventTitle(entity);
        }
    }

    isOpenableFromSelectbox(): boolean
    {
        return true;
    }

    getListDependencies(): EntityPath[]
    {
        return [
            ...super.getListDependencies(),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Relationship.RelationshipDefinition.Activities,
                    true
                ),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                    true
                ),
            EntityPath.root(this.type)
                .joinTo(
                    this.types.Activity.RelationshipDefinition.LinkedActivities,
                    true
                ),
        ];
    }

    getTimelineDateField(isOpen: boolean): EntityFieldPath
    {
        return EntityPath
            .fromEntityType(this.type)
            .field(
                isOpen
                    ? this.types.Entity.Field.CreationDate
                    : this.types.Entity.Field.CloseDate
            );
    }

    onValueSet(entity: Entity,
               field: EntityField,
               value: any,
               commitContext?: CommitContext)
    {
        super.onValueSet(entity, field, value, commitContext);

        if (field === this.types.Activity.Field.AmountInCurrency)
        {
            this.onSetValueFromCurrency(
                entity,
                this.types.Activity.Field.Currency,
                this.types.Activity.Field.AmountInCurrency,
                value,
                this.types.Activity.Field.Amount,
                commitContext
            );
        }
    }

    getRepresentation(
        entity: Entity,
        field: EntityField
    ): DataObjectRepresentationProps
    {
        const representation =
            super.getRepresentation(
                entity,
                field
            );

        if (this.getCurrencyFields().includes(field))
        {
            return {
                ...representation,
                currency: entity.getObjectValueByField(this.types.Activity.Field.Currency),
            };
        }
        else
        {
            return representation;
        }
    }

    getCurrencyFields(): EntityField[]
    {
        if (!this.currencyFields)
        {
            this.currencyFields = [
                this.types.Activity.Field.AmountInCurrency,
                this.types.Activity.Field.BillingAmountInCurrency,
                this.types.Activity.Field.TotalDiscountExcludingVatInCurrency,
                this.types.Activity.Field.TotalSalesExcludingVatInCurrency,
                this.types.Activity.Field.TotalSalesIncludingVatInCurrency,
                this.types.Activity.Field.VatAmountIncludingDiscountInCurrency,
                this.types.Activity.Field.VatAmountInCurrency,

                this.types.Activity.Field.TotalNonRecurringSalesExcludingVatInCurrency,
                this.types.Activity.Field.TotalNonRecurringSalesIncludingVatInCurrency,
                this.types.Activity.Field.TotalNonRecurringDiscountExcludingVatInCurrency,
                this.types.Activity.Field.TotalNonRecurringVatAmountInCurrency,
                this.types.Activity.Field.TotalRecurringSalesExcludingVatInCurrency,
                this.types.Activity.Field.TotalRecurringSalesIncludingVatInCurrency,
                this.types.Activity.Field.TotalRecurringDiscountExcludingVatInCurrency,
                this.types.Activity.Field.TotalRecurringVatAmountInCurrency,
            ];
        }
        return this.currencyFields;
    }

    attachmentEntitySelectionBuilder(
        sb: EntitySelectionBuilder,
        entity: Entity,
        commitContext?: CommitContext
    ) : EntitySelectionBuilder
    {
        const parentRelationship =
            entity.getRelatedEntityByDefinition(
                true,
                this.types.Relationship.RelationshipDefinition.Activities,
                commitContext
            );

        if (parentRelationship)
        {
            return parentRelationship.entityType.bespoke.attachmentEntitySelectionBuilder(
                sb,
                parentRelationship,
                commitContext
            );
        }
        else
        {
            return sb;
        }
    }

// ----------------------- Private logic ------------------------
}
