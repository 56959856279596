import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../@Component/Domain/Entity/Type/EntityTypeStore';
import localizeText from '../../../Localization/localizeText';

export default class CreateRelationshipAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref relationshipDefinition: EntityRelationshipDefinition;
    @observable.ref parentEntity: Computation<any, any>;
    @observable.ref childEntity: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(relationshipDefinition: EntityRelationshipDefinition,
                parentEntity: Computation<any, any>,
                childEntity: Computation<any, any>)
    {
        super();

        this.relationshipDefinition = relationshipDefinition;
        this.parentEntity = parentEntity;
        this.childEntity = childEntity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        return localizeText(
            'Action.CreateRelationship',
            'Records koppelen'
        );
    }

    validate(): Validation[]
    {
        if (!this.parentEntity)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'MissingParentRecord',
                        'Je hebt nog geen parent record gekozen.'
                        )
                )
            ];
        }
        else if (!this.childEntity)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'MissingChildRecord',
                        'Je hebt nog geen child record gekozen.'
                    )
                )
            ];
        }
        else if (!this.relationshipDefinition)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'MissingField',
                        'Je hebt nog geen veld gekozen.'
                    )
                )
            ];
        }
        else
        {
            return [];
        }
    }

    isAsync(): boolean
    {
        return this.parentEntity.isAsync()
            || this.childEntity.isAsync();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'CreateRelationship';
        descriptor.relationshipDefinitionId = this.relationshipDefinition.id;
        descriptor.parentEntity = this.parentEntity.toDescriptor();
        descriptor.childEntity = this.childEntity.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.parentEntity.getDependencies(),
            ...this.childEntity.getDependencies(),
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CreateRelationshipAction(
            loadModuleDirectly(EntityTypeStore).getRelationshipDefinitionById(
                descriptor.relationshipDefinitionId
            ),
            await getComputationFromDescriptor(
                descriptor.parentEntity,
                dependencyContext
            ),
            await getComputationFromDescriptor(
                descriptor.childEntity,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
