import { findAssistantCreateEntityAction } from './findAssistantCreateEntityAction';
import { findAssistantUpdateEntityAction } from './findAssistantUpdateEntityAction';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import { AssistantAction } from '../../Model/Automation/Action/AssistantAction';
import Action from '../../../../../@Api/Automation/Function/Action/Action';
import { findAssistantForEachAction } from './findAssistantForEachAction';
import { findAssistantIfThenAction } from './findAssistantIfThenAction';

export function findAssistantAction(
    action: AssistantAction,
    parameters: ParameterDictionary,
    rootParameter: Parameter<EntityValueType>
): Action<any, any>
{
    switch (action.type)
    {
        case 'ForEach':
            return findAssistantForEachAction(
                action,
                parameters,
                rootParameter
            );

        case 'IfThen':
            return findAssistantIfThenAction(
                action,
                parameters,
                rootParameter
            );

        case 'CreateEntity':
            return findAssistantCreateEntityAction(
                action,
                parameters,
                rootParameter
            );

        case 'UpdateEntity':
            return findAssistantUpdateEntityAction(
                action,
                parameters,
                rootParameter
            );

        default:
            throw new Error(`Could not resolve action: ${JSON.stringify(action)}`);
    }
}
