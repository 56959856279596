import { Action, BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityValue } from '../../../../../../../@Api/Model/Implementation/EntityValue';
import { RelatedEntityPath } from '../../../../Path/@Model/RelatedEntityPath';
import { catchImport } from '../../../../../../../@Util/Import/catchImport';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/index';
import { consoleLog } from '../../../../../../../@Future/Util/Logging/consoleLog';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export class BespokeActivityEmail extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.Email')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        if (relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && isParent
            && relatedEntity?.entityType.isA(this.types.Activity.SupportTicket.Type)
            && relatedEntity.hasValueForField(this.types.Activity.Field.Number)
            && !entity.hasValueForField(this.types.Activity.Field.Subject))
        {
            entity.setValueByField(
                this.types.Activity.Field.Subject,
                `[${relatedEntity.getObjectValueByField(this.types.Activity.Field.Number)}] `,
                undefined,
                undefined,
                commitContext
            );
        }
    }

    orderByField(entity?: Entity,
                 pathFromRelatedEntity?: RelatedEntityPath): EntityFieldPath
    {
        return EntityPath.fromEntityType(this.type).field(this.types.Entity.Field.CloseDate);
    }

    openEmailComposerForAttachment(attachment: Entity): Promise<any>
    {
        const emailEntity = createTransactionalModel(new Entity(this.types.Activity.Email.Type)
            .initialize(this.entityTypeStore));

        emailEntity.updateRelationship(
            false,
            this.types.Entity.RelationshipDefinition.Attachments,
            attachment
        );

        const attachmentOwner = attachment.getRelatedEntityByDefinition(true, this.types.Entity.RelationshipDefinition.Attachments);

        if (attachmentOwner.entityType.isA(this.types.Activity.Type))
        {
            emailEntity.updateRelationship(true, this.types.Activity.RelationshipDefinition.LinkedActivities, attachmentOwner);
        }
        else if (attachmentOwner.entityType.isA(this.types.Relationship.Type))
        {
            emailEntity.updateRelationship(true, this.types.Relationship.RelationshipDefinition.Activities, attachmentOwner);
        }
        else
        {
            consoleLog('Attachment owner of type ', attachmentOwner.entityType.code, ' not supported');
            return Promise.resolve();
        }

        return this.showEmailDialog(emailEntity);
    }

    openEmailComposerForRecipient(recipientValue: EntityValue): Promise<any>
    {
        let recipient = recipientValue?.entity;

        if (recipient.entityType.isA(this.types.Relation.Organization.Type))
        {
            recipient = recipient.getRelatedEntityByDefinition(true, this.types.Relationship.Organization.RelationshipDefinition.Organization);
        }
        else if (recipient.entityType.isA(this.types.Relation.Person.Type))
        {
            recipient = recipient.getRelatedEntityByDefinition(true, this.types.Relationship.Person.RelationshipDefinition.Person);
        }
        else if (recipient.entityType.isA(this.types.Activity.Type))
        {
            recipient = recipient.getRelatedEntityByDefinition(true, this.types.Relationship.RelationshipDefinition.Activities);
        }
        else if (recipient.entityType.isA(this.types.ProjectMember.Type))
        {
            recipient = recipient.getRelatedEntityByDefinition(false, this.types.ProjectMember.RelationshipDefinition.Employee);
        }
        else if (recipient.entityType.isA(this.types.Involved.Type))
        {
            recipient = recipient.getRelatedEntityByDefinition(false, this.types.Involved.RelationshipDefinition.Contact) ??
                recipient.getRelatedEntityByDefinition(false, this.types.Involved.RelationshipDefinition.Relationship);
        }

        if (!recipient.entityType.isA(this.types.Relationship.Type))
        {
            consoleLog('recipient of type', recipient.entityType.code, 'cannot be used in an e-mail activity');

            return Promise.resolve();
        }
        recipient = createTransactionalModel(recipient);

        const emailEntity =
            createTransactionalModel(
                new Entity(this.types.Activity.Email.Type)
                    .initialize(this.entityTypeStore));

        emailEntity.updateRelationship(true, this.types.Relationship.RelationshipDefinition.Activities, recipient);

        const recipientEmailAddress = recipientValue?.dataObject.value;
        if (recipientEmailAddress)
        {
            const recipientEntity = this.types.Recipient.Email.Functions
                .RecipientFromEntity(recipient, recipientEmailAddress);

            if (recipientEntity)
            {
                emailEntity.updateRelationship(false, this.types.Activity.Email.RelationshipDefinition.To, recipientEntity);
            }
        }

        return this.showEmailDialog(emailEntity);
    }

    private showEmailDialog(emailEntity: Entity)
    {
        return import('../../../../Constructor/Modal/EntityConstructorModalStore')
            .then(dialogIndex => import('../../../../Constructor/EntityConstructorStore')
                .then(constructorIndex =>
                {
                    const feedbackStore = loadModuleDirectly(FeedbackStore);
                    const closeDialog = feedbackStore.openDialog({
                        store: new dialogIndex.EntityConstructorModalStore({
                            constructorStore: new constructorIndex.EntityConstructorStore({
                                entityType: emailEntity.entityType,
                                entity: emailEntity,
                            }),
                            onClose: () => closeDialog(),
                        }).modalDialogStore,
                    });
                })
                .catch(catchImport))
            .catch(catchImport);
    }

    allowWorkflowInteraction(): boolean
    {
        return false;
    }

    getActions(entity: Entity): Action[]
    {
        return [
            ...super.getActions(entity),
            {
                id: 'Activity.Email.Resend',
                code: 'Activity.Email.Resend',
                name: localizeText('Activity.Email.Resend', 'E-mail opnieuw versturen'),
            }
        ];
    }

    getTimelineDateField(isOpen: boolean): EntityFieldPath
    {
        return EntityPath
            .fromEntityType(this.type)
            .field(
                isOpen
                    ? this.types.Entity.Field.CreationDate
                    : this.types.Activity.Email.Field.SendDate
            );
    }

    // ----------------------- Private logic ------------------------
}
