import { action, computed, observable } from 'mobx';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import { TextStore } from '../../../../../../Generic/Text/TextStore';
import { ButtonStore } from '../../../../../../Generic/Button/ButtonStore';
import { RelatedEntityPath } from '../../../../Path/@Model/RelatedEntityPath';

export class RelatedEntityGroup
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable name: string;
    @observable showHeader: boolean;
    @observable childGroups: RelatedEntityGroup[];
    @observable rootEntity: Entity;
    @observable paths: EntityPath[];
    @observable selections: EntitySelectionBuilder[];
    @observable entities = observable.array<Entity>();
    @observable relativeAddPath: RelatedEntityPath;
    @observable ignoreSourceRelationshipDefinitionDuringAdding: boolean;
    @observable caption: (entity: Entity) => TextStore;
    @observable buttons: (entity: Entity) => ButtonStore[];
    @observable splitGroupOnPaths: boolean;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                name: string,
                showHeader: boolean,
                childGroups: RelatedEntityGroup[],
                rootEntity: Entity,
                paths: EntityPath[],
                selections: EntitySelectionBuilder[],
                relativeAddPath?: RelatedEntityPath,
                ignoreSourceRelationshipDefinitionDuringAdding?: boolean,
                caption?: (entity: Entity) => TextStore,
                buttons?: (entity: Entity) => ButtonStore[],
                splitGroupOnPaths: boolean = true)
    {
        this.id = id;
        this.name = name;
        this.showHeader = showHeader;
        this.childGroups = childGroups;
        this.rootEntity = rootEntity;
        this.paths = paths;
        this.selections = selections;
        this.relativeAddPath = relativeAddPath;
        this.ignoreSourceRelationshipDefinitionDuringAdding = ignoreSourceRelationshipDefinitionDuringAdding;
        this.caption = caption;
        this.buttons = buttons;
        this.splitGroupOnPaths = splitGroupOnPaths;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get relativePaths(): RelatedEntityPath[]
    {
        return this.paths
            .map(
                path =>
                    new RelatedEntityPath(
                        this.rootEntity,
                        path));
    }

    // -------------------------- Actions ---------------------------

    @action
    addEntities(entities: Entity[])
    {
        this.entities.push(...entities);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
