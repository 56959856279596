import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import AppBarLayout from '../../../../../@Api/Layout/Type/AppBarLayout';
import AppBarLayoutView from './View/AppBarLayoutView';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface AppBarLayoutEditorProps extends LayoutEditorProps<AppBarLayout>
{

}

const AppBarLayoutEditor: React.FC<AppBarLayoutEditorProps> =
    props =>
    {
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.layout = layout),
                [
                    props.layout
                ]);

        return <AppBarLayoutView
            layout={props.layout}
            editable
        >
            <ChildLayoutEditor
                {...props}
                childLayout={props.layout.layout}
                onChange={setLayout}
            />
        </AppBarLayoutView>;
    };

export default observer(AppBarLayoutEditor);
