import React from 'react';
import { observer } from 'mobx-react-lite';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import CollectionValue from '../../../../../../../../@Api/Automation/Value/CollectionValue';
import PrimitiveValue from '../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import EntityViewer from './Type/Entity/EntityViewer';
import PrimitiveViewer from './Type/Primitive/PrimitiveViewer';
import DataSourceValue from '../../../../../../../../@Api/Automation/Value/DataSourceValue';
import DataSourceViewer from './Type/DataSource/DataSourceViewer';
import DeferredValue from '../../../../../../../../@Api/Automation/Value/DeferredValue';

export interface ValueViewerProps<V extends Value<any, any> = Value<any, any>>
{
    value: V;
}

const ValueViewer: React.FC<ValueViewerProps> =
    observer(
        props =>
        {
            if (props.value instanceof DeferredValue)
            {
                if (props.value.initializedValue)
                {
                    return <ValueViewer
                        {...props}
                        value={props.value.initializedValue}
                    />;
                }
                else
                {
                    return null;
                }
            }
            else if (props.value instanceof CollectionValue)
            {
                return null;
            }
            else if (props.value instanceof EntityValue)
            {
                return <EntityViewer
                    {...props}
                />;
            }
            else if (props.value instanceof PrimitiveValue)
            {
                return <PrimitiveViewer
                    {...props}
                />;
            }
            else if (props.value instanceof DataSourceValue)
            {
                return <DataSourceViewer
                    {...props}
                />;
            }
            else
            {
                return null;
            }
        }
    );

export default ValueViewer;
