import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Mutator from '../Mutator/Mutator';
import { EntityMutation } from '../../../../../../../@Api/Model/Implementation/EntityMutation';
import { EntityCreationMutation } from '../../../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { EntityDeletionMutation } from '../../../../../../../@Api/Model/Implementation/EntityDeletionMutation';
import localizeText, { nounToLocalizedLowerCase } from '../../../../../../../@Api/Localization/localizeText';

export interface EntityProps
{
    event: EntityMutation;
}

const Entity: React.FC<EntityProps> =
    props =>
    {
        const isCreation =
            useMemo(
                () =>
                    props.event instanceof EntityCreationMutation,
                [
                    props.event
                ]);

        const isDeletion =
            useMemo(
                () =>
                    props.event instanceof EntityDeletionMutation,
                [
                    props.event
                ]);

        const actionName =
            useMemo(
                () =>
                {
                    if (isCreation)
                    {
                        return localizeText('TimeLine.AuditTrail.Added', 'toegevoegd');
                    }
                    else if (isDeletion)
                    {
                        return localizeText('TimeLine.AuditTrail.Deleted', 'verwijderd');
                    }
                    else
                    {
                        return localizeText('TimeLine.AuditTrail.Edited', 'aangepast')
                    }
                },
                [
                    isCreation,
                    isDeletion
                ]);

        return <>
            <Mutator event={props.event} /> {
                localizeText(
                    'Timeline.AuditTrail.HasChangedEntity',
                    'heeft ${entityName} ${actionName}',
                    {
                        entityName: nounToLocalizedLowerCase(props.event.entity.entityType.getName(false, props.event.entity)),
                        actionName: actionName
                    }
                )
            }
            </>
    };

export default observer(Entity);
