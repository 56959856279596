import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObjectBespokeViewerStore } from '../../Viewer/DataObjectBespokeViewerStore';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { Comparator } from '../../Model/Comparator';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { ComplexViewStore } from './ComplexViewStore';
import { ComplexView } from './ComplexView';
import { ComplexEditor } from './ComplexEditor';
import { ComplexEditorStore } from './ComplexEditorStore';
import { ComplexSpecification } from './ComplexSpecification';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import localizeText from '../../../../../@Api/Localization/localizeText';

export class ComplexType implements DataObjectType<any>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Complex';
    }

    name(): string
    {
        return localizeText('DataObject.Type.Complex', 'Complex');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [];
    }

    supportedAggregates(): Aggregate[]
    {
         return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return ComplexView;
    }

    viewStore(baseStore: DataObjectViewerStore): DataObjectBespokeViewerStore
    {
        return new ComplexViewStore(baseStore);
    }

    editor(): DataObjectEditorType
    {
        return ComplexEditor;
    }

    editorStore(baseStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new ComplexEditorStore(baseStore);
    }

    specification(): DataObjectSpecificationType
    {
        return ComplexSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return Promise.resolve(true);
    }

    requiresInitialization(value: any): boolean
    {
        return false;
    }

    getDataFromValue(value: any, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.complex = value;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        // This first condition is because of old data
        if (data.text1 && (data.text1.startsWith('{') || data.text1.startsWith('[')))
        {
            return JSON.parse(data.text1);
        }
        else if (data.complex)
        {
            return data.complex;
        }
        else
        {
            return undefined;
        }
    }

    valueId(value: any): string
    {
        return JSON.stringify(value);
    }

    getString(value: any, representation: DataObjectRepresentation, context: DataObjectContext, dataObject: DataObject): string
    {
        return JSON.stringify(value);
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }

}
