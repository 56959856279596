import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

export default function useAnimation(
    easing: (t: number) => number,
    duration = 500,
    delay = 0,
    onAnimationComplete?: () => void,
    deps: DependencyList = []
)
{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedOnAnimationComplete = useCallback(() => onAnimationComplete ? onAnimationComplete() : undefined, deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const restartTrigger = useMemo(() => deps.length > 0 ? uuid() : undefined, deps);
    const elapsed = useAnimationTimer(duration, delay, memoizedOnAnimationComplete, restartTrigger);
    const n = Math.min(1, elapsed / duration);

    return easing(n);
}

function useAnimationTimer(
    duration = 1000,
    delay = 0,
    onAnimationComplete?: () => void,
    restartTrigger?: string)
{
    const [elapsed, setTime] = useState(0);

    useEffect(
        () =>
        {
            let animationFrame, timerStop, start;

            // Function to be executed on each animation frame
            function onFrame()
            {
                setTime(Date.now() - start);
                loop();
            }

            // Call onFrame() on next animation frame
            function loop()
            {
                animationFrame = requestAnimationFrame(onFrame);
            }

            function onStart()
            {
                // Set a timeout to stop things when duration time elapses
                timerStop = setTimeout(
                    () =>
                    {
                        cancelAnimationFrame(animationFrame);
                        setTime(Date.now() - start);

                        if (onAnimationComplete)
                        {
                            onAnimationComplete();
                        }
                    },
                    duration);

                // Start the loop
                start = Date.now();
                loop();
            }

            // Start after specified delay (defaults to 0)
            const timerDelay = setTimeout(onStart, delay);

            // Clean things up
            return () =>
            {
                clearTimeout(timerStop);
                clearTimeout(timerDelay);
                cancelAnimationFrame(animationFrame);
            };
        },
        [
            // Only re-run effect if duration or delay changes
            duration,
            delay,
            onAnimationComplete,
            restartTrigger
        ]
    );

    return elapsed;
}
