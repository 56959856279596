import React from 'react';
import { EntityFieldPath } from '../../../@Model/EntityFieldPath';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { SimpleFieldPathSelector } from '../Selector/SimpleFieldPathSelector';
import Link from '../../../../../../../@Future/Component/Generic/Link/Link';

export interface FieldPathEditorProps
{
    entityType: EntityType;
    value?: EntityFieldPath;
    onChange: (value: EntityFieldPath) => void;
}

export const SimpleFieldPathEditor: React.FC<FieldPathEditorProps> =
    ({
        entityType,
        value,
        onChange,
     }) =>
    {
        return <SimpleFieldPathSelector
            entityType={entityType}
            onSelect={onChange}
            view={
                ({ open }) =>
                    <Link
                        onClick={open}
                        highlighted
                    >
                        {
                            value ? value.getPathName(true) : '...'
                        }
                    </Link>
            }
        />;
    };
