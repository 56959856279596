import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import getResultName from './getResultName';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../DataObject/DataObjectStore';
import Segment from '../../Model/Segment';

export default function getResultGroup(segment: Segment,
                                       result: EntitySelectionAggregateResult,
                                       idx: number)
{
    const dataObjectStore = loadModuleDirectly(DataObjectStore);

    if (segment.isDate && segment.isDateSegmentedInCategories)
    {
        return DataObject.constructFromTypeIdAndValue(
            'Text',
            getResultName(
                segment,
                result,
                idx),
            dataObjectStore);
    }
    else if (result.groupEntity)
    {
        return DataObject.constructFromTypeIdAndValue(
            'Entity',
            result.groupEntity,
            dataObjectStore);
    }
    else if (result.groupValue)
    {
        return result.groupValue;
    }
    else
    {
        return undefined;
    }
}
