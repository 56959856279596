import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import * as React from 'react';
import { CircularProgress, IconButton as MuiIconButton } from '@material-ui/core';
import { ButtonStore } from '../ButtonStore';
import Text from '../../Text/Text';
import Icon from '../../../../@Future/Component/Generic/Icon/Icon';

@observer
export class IconButton extends BaseComponent<ButtonStore>
{
    renderLoader(store: ButtonStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ButtonStore)
    {
        if (store.labelTextStore && store.isIconSeparate)
        {
            return this.renderLabelButton(store);
        }
        else
        {
            return this.renderIconButton(store);
        }
    }

    renderLabelButton(store: ButtonStore)
    {
        return <div
            style={{
                display: 'flex',
                flexDirection:
                    store.iconPosition === 'top'
                        ?
                            'column'
                        :
                            store.iconPosition === 'left'
                                ?
                                    'row'
                                :
                                    'row-reverse',
                alignItems: 'center',
                width:
                    store.iconPosition === 'top'
                        ?
                            store.iconSize * 4
                        :
                            undefined
            }}
        >
            <div
                style={{
                    width: store.iconButtonSize,
                    height: store.iconButtonSize,
                    // border: `${store.nonMuiColor} 1px solid`,
                    // borderRadius: '50%',
                    // background: store.isHighlighted ? store.nonMuiColor : undefined,
                    position: 'relative',
                    marginBottom: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {
                    this.renderIconButton(store)
                }
                {
                    store.showLoader && // If an icon is above a label there is a custom loader (position and color is different)
                        <CircularProgress
                            size={24}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                                color:
                                    store.isHighlighted ? 'white' : undefined
                            }}
                        />
                }
            </div>
            <div
                style={{
                    marginLeft:
                        store.iconPosition === 'left'
                            ?
                                10
                            :
                                0,
                    marginRight:
                        store.iconPosition === 'right'
                            ?
                                10
                            :
                                0,
                    width: 50
                }}
            >
                <Text
                    store={store.labelTextStore}
                />
            </div>
        </div>;
    }

    renderIconButton(store: ButtonStore)
    {
        return <MuiIconButton
            onClick={store.click}
            color={store.muiColor}
            disabled={store.isDisabled}
            tabIndex={store.isDisableTab ? -1 : undefined}
            onMouseEnter={store.startHovering}
            onMouseOver={store.startHovering}
            onMouseLeave={store.stopHovering}
            style={{
                backgroundColor:
                    store.isIconSeparate
                        ?
                            store.isHighlighted
                                ?
                                    store.isMouseOver ? 'white' : store.nonMuiColor
                                :
                                    store.isMouseOver ? store.nonMuiColor : 'white'
                        :
                            store.backgroundColor,
                    //
                    // store.isIconSeparate && store.isHighlighted
                    //     ?
                    //         store.nonMuiColor
                    //     :
                    //         store.backgroundColor,
                border:  store.iconPosition === 'top' ? `${store.nonMuiColor} 1px solid` : undefined,
                borderRadius: '50%',
                width: store.iconButtonSize,
                height: store.iconButtonSize,
                padding: 0,
                visibility: store.isHidden ? 'hidden' : undefined,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...store.style
            }}
        >
            <Icon
                size={store.isIconSeparate ? 18 : store.iconSize}
                icon={store.icon}
                color={
                    store.isIconSeparate
                        ?
                            store.isHighlighted
                                ?
                                    store.isMouseOver ? store.nonMuiColor : 'white'
                                :
                                    store.isMouseOver ? 'white' : store.nonMuiColor
                        :
                            store.iconColor}
                rounded={store.roundedIcon}
                outlined={store.outlined}
                {...store.iconProps}
            />

            {/*<Icon*/}
            {/*    style={{*/}
            {/*        fontSize: store.isIconSeparate ? 18 : store.iconSize,*/}
            {/*        color:*/}
            {/*            store.isIconSeparate*/}
            {/*                ?*/}
            {/*                    store.isHighlighted*/}
            {/*                        ?*/}
            {/*                            store.isMouseOver ? store.nonMuiColor : 'white'*/}
            {/*                        :*/}
            {/*                            store.isMouseOver ? 'white' : store.nonMuiColor*/}
            {/*                :*/}
            {/*                    store.iconColor,*/}
            {/*        display: 'flex',*/}
            {/*        alignItems: 'center',*/}
            {/*        justifyContent: 'center'*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {store.icon}*/}
            {/*</Icon>*/}
        </MuiIconButton>;
    }
}
