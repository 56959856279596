import React, { ReactNode, useCallback, useState } from 'react';
import Selectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import uuid from '../../../../../../../../@Util/Id/uuid';
import { AddressDetailsResult } from '../Model/AddressDetailsResult';
import { AddressSearchResult } from '../Model/AddressSearchResult';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import { AddressSearchQuery } from '../Model/AddressSearchQuery';
import { fromJson } from '../../../../../../../../@Util/Serialization/Serialization';
import { AddressDetailsQuery } from '../Model/AddressDetailsQuery';
import { Icon } from '@material-ui/core';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface AddressLookupSelectboxProps
{
    countryCode: string;
    onLookup: (address: AddressDetailsResult) => void;
    autoFocus?: boolean;
}

export const AddressLookupSelectbox: React.FC<AddressLookupSelectboxProps> =
    ({
        countryCode,
        onLookup,
        autoFocus,
     }) =>
    {
        const [ sessionId ] = useState(() => uuid());
        const [ value, setValue ] = useState<AddressSearchResult | undefined>(undefined);
        const [ inputValue, setInputValue ] = useState('');

        const load =
            useCallback(
                async (query: string) =>
                {
                    if (query.length > 0)
                    {
                        const result =
                            await performAction(
                                undefined,
                                {
                                    code: 'Address.Search',
                                    parameters: {
                                        query: {
                                            sessionId: sessionId,
                                            countryCode: countryCode,
                                            query: query,
                                            context: value?.context
                                        } as AddressSearchQuery
                                    }
                                }
                            );

                        return fromJson(result.result);
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    sessionId,
                    countryCode,
                    value,
                ]
            );

        const fetchDetails =
            useCallback(
                async (context: string) =>
                {
                    const result =
                        await performAction(
                            undefined,
                            {
                                code: 'Address.Details',
                                parameters: {
                                    query: {
                                        sessionId,
                                        countryCode,
                                        context,
                                    } as AddressDetailsQuery
                                }
                            }
                        );

                    return fromJson(result.result);
                },
                []
            );

        const formatHighlights =
            useCallback(
                (option: AddressSearchResult) =>
                {
                    const label = option.label;
                    const substrings: ReactNode[] = [];
                    let lastIndex = 0;

                    option.highlights.forEach(
                        hl =>
                        {
                            if (lastIndex < hl.from)
                            {
                                substrings.push(
                                    <span>
                                        {label.substr(lastIndex, hl.from - lastIndex)}
                                    </span>
                                );
                            }

                            substrings.push(
                                <strong>
                                    {label.substr(hl.from, hl.to - hl.from)}
                                </strong>
                            );

                            lastIndex = hl.to;
                        });

                    if (lastIndex < label.length)
                    {
                        substrings.push(
                            <span>
                                {label.substr(lastIndex, label.length - lastIndex)}
                            </span>
                        );
                    }

                    return <>
                        {substrings}
                    </>;
                },
                []
            );

        const formatOption =
            useCallback(
                (option: AddressSearchResult) =>
                    <div>
                        <ViewGroup
                            orientation="horizontal"
                            justification="center"
                            alignment="center"
                            spacing={5}
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <div
                                    style={{
                                        padding: 3
                                    }}
                                >
                                    <div>
                                        {formatHighlights(option)}
                                    </div>
                                    <div>
                                        <i>{option.description}</i>
                                    </div>
                                </div>
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={undefined}
                            >
                                <Icon
                                    style={{
                                        display: option.precision === 'Address' ? 'none' : undefined
                                    }}
                                >
                                    keyboard_arrow_right
                                </Icon>
                            </ViewGroupItem>
                        </ViewGroup>
                    </div>,
                []
            );

        const idResolver =
            useCallback(
                (option: AddressSearchResult) =>
                    option.context,
                []
            );

        const onChange =
            useCallback(
                (value: AddressSearchResult | undefined) =>
                {
                    setValue(value);

                    if (value && value.context && value.precision === 'Address')
                    {
                        fetchDetails(value.context)
                            .then(onLookup);
                    }
                    else if (value)
                    {
                        setInputValue(value.value);
                    }
                },
                [
                    setValue,
                    fetchDetails,
                    onLookup,
                    setInputValue,
                ]
            );

        return <Selectbox
            load={load}
            formatOption={formatOption}
            idResolver={idResolver}
            value={value}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={setInputValue}
            clearable
            autoFocus={autoFocus}
            disableUnderline={false}
        />;
    };
