import { BaseStore } from '../../../../../../@Framework/Store/BaseStore';
import { observable } from 'mobx';
import { DataObjectEditorStore } from '../Editor/DataObjectEditorStore';

export class DataObjectBespokeEditorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable baseStore: DataObjectEditorStore;

    // ------------------------ Constructor -------------------------

    constructor(baseStore: DataObjectEditorStore)
    {
        super();

        this.baseStore = baseStore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
