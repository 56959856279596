import React from 'react';
import { observer } from 'mobx-react-lite';
import { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import LocalizedTextComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/LocalizedTextComputation';
import LocalizedComputationEditor from '../LocalizedComputation/LocalizedComputationEditor';

export interface LocalizedTextComputationEditorProps extends ComputationEditorProps<ValueType<any>, LocalizedTextComputation>
{

}

const LocalizedTextComputationEditor: React.FC<LocalizedTextComputationEditorProps> =
    props =>
    {
        return <LocalizedComputationEditor
            {...props}
            value={props.value as any}
            onChange={() => {}}
        />;
    };

export default observer(LocalizedTextComputationEditor);
