import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import TypeSelector from './TypeSelector/TypeSelector';
import IsRequiredEditor from './IsRequiredEditor/IsRequiredEditor';
import IsVisibleDuringConstructionEditor from './IsVisibleDuringConstructionEditor/IsVisibleDuringConstructionEditor';
import { Field } from '../Model/Field';
import NameEditor from './NameEditor/NameEditor';
import Options from './Options/Options';
import styles from './FieldItem.module.scss';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';
import { TransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { EntityFieldPath } from '../../../../../Entity/Path/@Model/EntityFieldPath';
import IsNameEditor from './IsNameEditor/IsNameEditor';
import DragHandle from '../../../../../DragAndDrop/DragHandle/DragHandle';
import { Draggable as DraggableDnd } from 'react-beautiful-dnd';
import DraggableStateContext from '../../../../../DragAndDrop/Context/DraggableStateContext';
import DragHandlePropsContext from '../../../../../DragAndDrop/Context/DragHandlePropsContext';
import IsHiddenEditor from './IsHiddenEditor/IsHiddenEditor';
import CodeEditor from './CodeEditor/CodeEditor';
import IsPluralEditor from './IsPluralEditor/IsPluralEditor';
import PackEditor from './PackEditor/PackEditor';
import FieldGroupEditor from './FieldGroupEditor/FieldGroupEditor';
import FilterEditor from '../../ConnectionsEditor/FieldItem/FilterEditor/FilterEditor';

export interface FieldItemProps
{
    entityType: EntityType;
    field: TransactionalModel<Field>;
    onAddField: (fieldPath: EntityFieldPath) => void;
    onSave: (field: Field) => void;
    onCancel: (field: Field) => void;
    idx: number;
    showPackSelector: boolean;
}

const FieldItem: React.FC<FieldItemProps> =
    props =>
    {
        return <DraggableDnd
            draggableId={props.field.id}
            index={props.idx}
        >
            {
                (provided, snapshot) =>
                    <DragHandlePropsContext.Provider
                        value={provided.dragHandleProps}
                    >
                        <DraggableStateContext.Provider
                            value={snapshot}
                        >
                            <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={provided.draggableProps.style}
                                className={classNames(styles.root, props.field.isEditable && styles.editable)}
                            >
                                <TableCell>
                                    <DragHandle />
                                </TableCell>
                                <TableCell>
                                    <NameEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TypeSelector
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IsPluralEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IsRequiredEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IsVisibleDuringConstructionEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IsNameEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IsHiddenEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <CodeEditor
                                        {...props}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FieldGroupEditor
                                        {...props}
                                    />
                                </TableCell>
                                {
                                    props.showPackSelector &&
                                        <TableCell>
                                            <PackEditor
                                                {...props}
                                            />
                                        </TableCell>
                                }
                                <TableCell>
                                    <FilterEditor
                                        entityType={props.entityType}
                                        fieldPath={props.field.fieldPath}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Options
                                        {...props}
                                    />
                                </TableCell>
                            </TableRow>
                        </DraggableStateContext.Provider>
                    </DragHandlePropsContext.Provider>
                }
        </DraggableDnd>;
    };

export default observer(FieldItem);
