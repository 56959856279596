import { DataObject } from '../../../DataObject/Model/DataObject';
import DataSeries from '../Model/DataSeries';
import getSeriesData, { DataPointValue } from './getSeriesData';
import Chart from '../Model/Chart';
import Series from '../Model/Series';
import ComputedSeries from '../Model/ComputedSeries';
import makeSeriesCumulative from './makeSeriesCumulative';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';

export interface ChartDataPoint
{
    idx: number;
    x: DataObject;
    data: Map<Series, DataPointValue>;
}

export default async function getChartData(chart: Chart,
                                           windowStart: Date,
                                           windowEnd: Date,
                                           range?: DataObject,
                                           interval?: DataObject,
                                           filter?: Predicate): Promise<ChartDataPoint[]>
{
    const seriesResults =
        await Promise.all(
            chart.series
                .filter(
                    series =>
                        series instanceof DataSeries)
                .map(
                    series =>
                        getSeriesData(
                            chart,
                            series as DataSeries,
                            windowStart,
                            windowEnd,
                            range,
                            interval,
                            filter)
                            .then(
                                ([ startValue, points ]) =>
                                    Promise.resolve(
                                        points.map(
                                            point => ({
                                                series: series,
                                                startValue: startValue,
                                                point: point
                                            }))))));

    const seriesById =
        new Map<string, Series>(
            chart.series.map(
                series => [
                    series.id,
                    series
                ]));

    const startValueBySeries = new Map<Series, DataPointValue>();
    const pointByValue = new Map<string, ChartDataPoint>();

    seriesResults.forEach(
        seriesResult =>
            seriesResult.forEach(
                (point, idx) =>
                {
                    startValueBySeries.set(
                        point.series,
                        point.startValue);

                    const key = point.point.x.toString();

                    if (!pointByValue.has(key))
                    {
                        pointByValue.set(
                            key,
                            {
                                idx: idx,
                                x: point.point.x,
                                data: new Map()
                            });
                    }

                    pointByValue
                        .get(key)
                        .data
                        .set(point.series, point.point.y);
                }));

    const points =
        Array.from(pointByValue.values())
            .sort(
                (a, b) =>
                    DataObject.compareTo(
                        a.x,
                        b.x,
                        true));

    points.forEach(
        (point, idx) =>
            point.idx = idx);

    chart.series
        .filter(
            series =>
                series instanceof DataSeries
                && series.isCumulative)
        .forEach(
            series =>
                makeSeriesCumulative(
                    series,
                    points,
                    startValueBySeries));

    chart.series
        .filter(
            series =>
                series instanceof ComputedSeries)
        .forEach(
            (series: ComputedSeries) =>
            {
                function computePrimitive(lhs: DataObject, operator: MathematicalOperator, rhs: DataObject)
                {
                    const lhsValue = lhs || DataObject.constructFromValue(rhs.specification, 0);

                    return DataObject.compute(
                        lhsValue,
                        rhs,
                        operator);
                }

                function computeValue(lhs: DataPointValue, operator: MathematicalOperator, rhs: DataPointValue)
                {
                    const value = computePrimitive(lhs.value, operator, rhs.value);
                    const segments = new Map<Entity | DataObject, DataObject>();

                    lhs.segments
                        .forEach(
                            (value, key) =>
                                segments.set(
                                    key,
                                    value));

                    rhs.segments
                        .forEach(
                            (value, key) =>
                            {
                                if (segments.has(key))
                                {
                                    segments.set(
                                        key,
                                        computePrimitive(
                                            segments.get(key),
                                            operator,
                                            value));
                                }
                                else
                                {
                                    segments.set(
                                        key,
                                        value);
                                }
                            });

                    return {
                        value: value,
                        segments: segments
                    };
                }

                function compute(getValueBySeries: (series: Series, positionOffset: number) => DataPointValue | undefined)
                {
                    let value: DataPointValue = {
                        value: undefined,
                        segments: new Map()
                    };

                    series.components
                        .forEach(
                            component =>
                            {
                                let rhsValue: DataPointValue | undefined;

                                if (component.seriesId)
                                {
                                    const componentSeries = seriesById.get(component.seriesId);

                                    if (componentSeries)
                                    {
                                        rhsValue =
                                            getValueBySeries(
                                                componentSeries,
                                                component.seriesValuePositionOffset === undefined
                                                    ?
                                                        0
                                                    :
                                                       component.seriesValuePositionOffset);
                                    }
                                }
                                else if (component.value)
                                {
                                    rhsValue = {
                                        value: component.value,
                                        segments: new Map()
                                    };
                                }

                                if (rhsValue)
                                {
                                    value =
                                        computeValue(
                                            value,
                                            component.operator,
                                            rhsValue);
                                }
                            });

                    return value;
                }

                // Compute start values
                if (series.isCumulative)
                {
                    const startValue = compute(series => startValueBySeries.get(series));

                    if (startValue)
                    {
                        startValueBySeries.set(
                            series,
                            startValue);
                    }
                }

                // Compute points
                pointByValue.forEach(
                    point =>
                    {
                        const value =
                            compute(
                                (series, positionOffset) =>
                                {
                                    const position = point.idx + positionOffset;

                                    if (position === -1)
                                    {
                                        return startValueBySeries.get(series);
                                    }
                                    else if (position >= 0 && position < points.length)
                                    {
                                        return points[position].data.get(series);
                                    }
                                    else
                                    {
                                        return point.data.get(series);
                                    }
                                });

                        if (value)
                        {
                            point.data.set(
                                series,
                                value);
                        }
                    });

                if (series.isCumulative)
                {
                    makeSeriesCumulative(
                        series,
                        points,
                        startValueBySeries);
                }
            });

    return points;
}
