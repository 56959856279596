import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';
import useEntityValue from '../../../../../@Api/Entity/Hooks/useEntityValue';
import { FileValue } from '../../../DataObject/Type/File/FileValue';
import { openEntity } from '../../@Util/openEntity';
import { Chip } from '@material-ui/core';
import deleteEntity from '../../../../../@Api/Entity/deleteEntity';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface AttachmentChipProps
{
    attachment: Entity;
    commitContext?: CommitContext;
    readOnly?: boolean;
    disableDownload?: boolean;
}

const AttachmentChip: React.FC<AttachmentChipProps> =
    props =>
    {
        const types = useTypes();

        const fileValue =
            useEntityValue<FileValue>(
                props.attachment,
                types.Attachment.Field.File,
                undefined,
                props.commitContext
            );

        const downloadFile =
            useCallback(
                (event) =>
                {
                    event.stopPropagation();
                    openEntity(props.attachment);
                },
                [
                    props.attachment
                ]);

        const onDelete =
            useCallback(
                () =>
                    deleteEntity(
                        props.attachment,
                        props.commitContext
                    ),
                [
                    props.attachment,
                    props.commitContext,
                ]);

        if (fileValue)
        {
            return <Chip
                size="small"
                label={`${fileValue.name} (${fileValue.humanReadableFilesize})`}
                onDelete={!!props.readOnly ? undefined : onDelete}
                onClick={!!props.disableDownload ? undefined : downloadFile}
            />;
        }
        else
        {
            return null;
        }
    };


export default observer(AttachmentChip);
