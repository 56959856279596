import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import TabBar from '../../../../../@Future/Component/Generic/TabBar/TabBar';
import TabBarLayout from '../../../../../@Api/Layout/Type/TabBar/TabBarLayout';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import TabSourceEditor from './Source/TabSourceEditor';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';

export interface TabBarLayoutEditorProps extends LayoutEditorProps<TabBarLayout>
{

}

const TabBarLayoutEditor: React.FC<TabBarLayoutEditorProps> =
    props =>
    {
        const { layout, parameterDictionary, parameterAssignment, commitContext } = props;
        const context =
            useMemo(
                () =>
                    new FunctionContext(
                        parameterDictionary,
                        parameterAssignment || new ParameterAssignment(),
                        commitContext
                    ),
                [
                    parameterDictionary,
                    parameterAssignment,
                    commitContext,
                ]);
        const value =
            useComputed(
                () =>
                    context.parameterAssignment.getValue(layout.parameter) || EmptyValue.instance,
                [
                    context,
                    layout
                ]);
        const valueId =
            useMemo(
                () =>
                    value instanceof EmptyValue ? undefined : value.getId(),
                [
                    value
                ]);

        return <TabBar
            value={valueId}
        >
            {
                layout.sources
                    .map(
                        source =>
                            <TabSourceEditor
                                key={source.id}
                                {...props}
                                source={source}
                                context={context}
                            />)
            }
        </TabBar>;
    };

export default observer(TabBarLayoutEditor);
