import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import Dialog from '../../../../../@Future/Component/Generic/Dialog/Dialog';
import Role, { AllPrivileges, Privilege, RoleType } from '../../Model/Role';
import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { runInAction } from 'mobx';
import Right from '../../Model/Right';
import DialogTitle from '../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogContent from '../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import DialogActions from '../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import RightAlignedButtonGroup from '../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import CancelButton from '../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import { makeStyles } from '@material-ui/core';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import { RightPrivilegePredicateEditor } from './RightPrivilegePredicateEditor';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import { setPermission } from '../../Api/Permission/setPermission';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';
import isRightPrivilegeConditionable from './Api/isRightPrivilegeConditionable';
import { useComputed } from 'mobx-react-lite';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { RoleParams } from '../../Model/RoleParams';
import getPredicateFromDescriptor from '../../../../../@Api/Automation/Api/getPredicateFromDescriptor';


const useStyles = makeStyles({
    dialogTitle: {
        color: primaryColor
    },
});

export interface RightPredicateEditorProps
{
    rolesByType: Map<RoleType, Role>;
    role: Role;
    metadataPermission: EntityMetadataPermission;
    onClose: () => void;
    isReadOnly: boolean;
}

export const RightPredicateEditor: React.FC<RightPredicateEditorProps> =
    observer(
    ({
         rolesByType,
         role,
         metadataPermission,
         isReadOnly,
         onClose,
     }) =>
    {
        const classes = useStyles();
        const [ isDialogOpen, setDialogOpen ] = useState(true);
        const [ copyPrivilege, setCopyPrivilege ] = useState<Privilege>(undefined);

        const parameterDictionary =
            useMemo(
                () =>
                {
                    switch (metadataPermission.type)
                    {
                        case 'EntityType':
                            return Role.getEntityTypeParameterDictionary(
                                metadataPermission.entityType
                            );
                        case 'EntityField':
                            return Role.getEntityTypeParameterDictionary(
                                metadataPermission.field.entityType
                            );
                        case 'RelationshipDefinition':
                            return Role.getRelationshipTypeParameterDictionary(
                                metadataPermission.relationship
                            );
                        default:
                            return undefined;
                    }
                },
                [
                    metadataPermission
                ]
            );

        const filterParameter =
            useMemo(
                () =>
                {
                    switch (metadataPermission.type)
                    {
                        case 'EntityType':
                            return parameterDictionary.getParameterById(RoleParams.Entity);
                        case 'EntityField':
                            return parameterDictionary.getParameterById(RoleParams.Entity);
                        case 'RelationshipDefinition':
                            return parameterDictionary.getParameterById(
                                metadataPermission.isParent
                                    ? RoleParams.ChildEntity
                                    : RoleParams.ParentEntity
                            );
                        default:
                            return undefined;
                    }
                },
                [
                    parameterDictionary
                ]
            );

        const automationDependencyContext =
            useMemo(
                () =>
                    new AutomationDependencyContext(
                        parameterDictionary
                    ),
                [
                    parameterDictionary
                ]
            );

        const right =
            useMemo(
                () =>
                {
                    switch (metadataPermission.type)
                    {
                        case 'EntityType':
                            return role.rightByType.get(
                                metadataPermission.entityType
                            );
                        case 'EntityField':
                            return role.rightByField.get(
                                metadataPermission.field
                            );
                        case 'RelationshipDefinition':
                            return role.rightByRelationshipType.get(
                                metadataPermission.relationship
                            );
                        default:
                            return undefined;
                    }
                },
                [
                    role,
                    metadataPermission,
                ]
            );

        const typeName =
            useMemo(
                () =>
                {
                    switch (metadataPermission.type)
                    {
                        case 'EntityType':
                            return metadataPermission.entityType.getName();
                        case 'EntityField':
                            return metadataPermission.field.getName();
                        case 'RelationshipDefinition':
                            return metadataPermission.relationship.name;
                        default:
                            return undefined;
                    }
                },
                [
                    metadataPermission,
                ]
            );

        const [ rightClone, isLoading ] =
            useAsyncResult(
                () =>
                    right?.copy(automationDependencyContext)
                    ?? Promise.resolve(new Right(automationDependencyContext.parameterDictionary)),
                [
                    right,
                    automationDependencyContext,
                ]
            );

        const setFilter =
            useCallback(
                (
                    privilege: Privilege,
                    filter?: Predicate,
                ) =>
                    runInAction(
                        () =>
                        {
                            const conditionSerializationKey = Right.getConditionSerializationKey(privilege);
                            rightClone[conditionSerializationKey] = filter;
                        }),
                [
                    rightClone
                ]
            );

        const close =
            useCallback(
                () =>
                {
                    setDialogOpen(false);
                    onClose();
                },
                [
                    setDialogOpen,
                    onClose
                ]
            );

        const save =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            AllPrivileges.map(
                                privilege =>
                                    setPermission(
                                        rolesByType,
                                        role,
                                        privilege,
                                        'ConditionallyGranted',
                                        metadataPermission,
                                        rightClone[Right.getConditionSerializationKey(privilege)],
                                        automationDependencyContext.parameterDictionary
                                    )
                            );
                            close();
                        }),
                [
                    close,
                    rolesByType,
                    role,
                    metadataPermission,
                    rightClone,
                    automationDependencyContext
                ]
            );

        const conditionablePrivileges =
            useComputed(
                () =>
                    AllPrivileges
                        .filter(
                            privilege =>
                                isRightPrivilegeConditionable(
                                    rolesByType,
                                    role,
                                    privilege,
                                    metadataPermission
                                )
                        ),
                [
                    rolesByType,
                    role,
                    metadataPermission
                ]);

        const copy =
            useCallback(
                (privilege: Privilege) =>
                {
                    setCopyPrivilege(privilege);
                },
                [
                    setCopyPrivilege,
                ]
            );

        const paste =
            useCallback(
                async (privilege: Privilege) =>
                {
                    const filterDescriptor = rightClone[Right.getConditionSerializationKey(copyPrivilege)].toDescriptor();

                    setFilter(privilege,
                        await getPredicateFromDescriptor(
                            filterDescriptor,
                            automationDependencyContext
                        )
                    );

                    setCopyPrivilege(undefined)
                },
                [
                    rightClone,
                    copyPrivilege,
                    automationDependencyContext,
                    setCopyPrivilege
                ]
            );

        return <Dialog
            onClose={close}
            open={isDialogOpen}
        >
            <DialogTitle>
                <div
                    className={classes.dialogTitle}
                >
                    <LocalizedText
                        code="RightPredicateEditor.DialogTitle"
                        value="Conditionele verleende rechten op ${typeName}"
                        typeName={typeName?.toLowerCase()}
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                {
                    !isLoading &&
                    conditionablePrivileges.map(
                        privilege =>
                            <RightPrivilegePredicateEditor
                                privilege={privilege}
                                filterParameter={filterParameter}
                                parameterDictionary={parameterDictionary}
                                filter={rightClone[Right.getConditionSerializationKey(privilege)]}
                                isReadOnly={isReadOnly}
                                onChange={setFilter}
                                onCopy={copy}
                                onPaste={paste}
                                canPaste={copyPrivilege && copyPrivilege !== privilege}
                            />
                    )
                }
            </DialogContent>
            <DialogActions>
                <RightAlignedButtonGroup>
                    <CancelButton
                        onClick={close}
                    />
                    {
                        !isReadOnly &&
                        <SaveButton
                            onClick={save}
                        />
                    }
                </RightAlignedButtonGroup>
            </DialogActions>
        </Dialog>;
    }
);