import Period from '../Model/Period';
import moment from 'moment';

export default function getOverlappingPeriod(periodA: Period,
                                             periodB: Period)
{
    if (periodA.from.isBefore(periodB.to)
        && periodB.from.isBefore(periodA.to))
    {
        return {
            from: moment.max(periodA.from, periodB.from),
            to: moment.min(periodA.to, periodB.to)
        };
    }
    else
    {
        return undefined;
    }
}
