import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import getTypes from '../../Entity/Type/Api/getTypes';

export function getTemplateCategoryType(templateType: EntityType)
{
    const types = getTypes();

    switch (templateType.code)
    {
        case types.Template.Document.Offer?.Type.code:
        case types.Template.Email.Offer?.Type.code:
            return types.TemplateCategory.Offer.Type;
        case types.Template.Document.Invoice.Type?.code:
        case types.Template.Email.Invoice.Type?.code:
            return types.TemplateCategory.Invoice.Type;
        case types.Template.Document.WorkOrder.Type?.code:
        case types.Template.Email.WorkOrder.Type?.code:
        case types.Template.Email.WorkOrderSign.Type?.code:
            return types.TemplateCategory.Workorder.Type;
        case types.Template.Document.DocumentActivity.Type?.code:
            return types.TemplateCategory.Document.Type;
        case types.Template.Email.General.Type?.code:
            return types.TemplateCategory.Email.Type;

    }
}