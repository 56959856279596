import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { FileUploaderStore } from './FileUploaderStore';
import { grey } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';

@observer
export class FileUploader extends BaseComponent<FileUploaderStore>
{
    renderComponent(store: FileUploaderStore)
    {
        const childrenAsArray =
            React.Children.toArray(this.props.children)
                .filter(child => child);

        return <Dropzone
            onDrop={store.onDrop}
        >
            {
                ({getRootProps, getInputProps, isDragActive}) => (
                    <div
                        {...getRootProps()}
                        style={{
                            width: '100%',
                            height: this.props.children ? undefined : 100,
                            minHeight: isDragActive ? 100 : 20,
                            position: 'relative'
                        }}
                    >
                        <input {...getInputProps()} />
                        {
                            !isDragActive && childrenAsArray.length !== 0
                                ?
                                    this.props.children
                                :
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            padding: '16px',
                                            border: `2px dashed ${grey[400]}`,
                                            borderRadius: '15px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: '0 0 auto'
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                            >
                                                {
                                                    store.localizationStore.translate('GenericComponent.FileUploader.ClickOrDropYourFilesHere') // Click or drop your files here
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                        }
                    </div>
                )
            }
        </Dropzone>;
    }
}
