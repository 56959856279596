import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { DependencyList, useCallback } from 'react';
import { EntitySelectionAggregateResult } from '../Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import { useDisposableAsyncResult } from '../../../../../@Util/Async/useDisposableAsyncResult';

export default function useAggregateResult(type: EntityType,
                                           callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
                                           deps: DependencyList): EntitySelectionAggregateResult | undefined
{
    const memorizedCallback = useCallback(callback, deps);
    const selectionBuilder =
        useComputed(
            () =>
            {
                if (type)
                {
                    const selectionBuilder = new EntitySelectionBuilder(type);

                    memorizedCallback(selectionBuilder, selectionBuilder.rootPath);

                    return selectionBuilder;
                }
                else
                {
                    return undefined;
                }
            },
            [
                type,
                memorizedCallback
            ]
        );
    const [ result ] =
        useDisposableAsyncResult<EntitySelectionAggregateResult | undefined>(
            async () =>
            {
                if (type)
                {
                    const result = await selectionBuilder.selectAggregates();

                    return {
                        value: result,
                        disposer:
                            () =>
                                selectionBuilder.dispose(),
                    };
                }
                else
                {
                    return {
                        value: undefined,
                        disposer: () => {},
                    };
                }
            },
            [
                selectionBuilder,
            ]
        );

    return result;
}
