import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ExpansionGroup from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../Type/Api/useTypes';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import useSpotlerCampaignPhaseCode from './Hooks/useSpotlerCampaignPhaseCode';
import { SpotlerCampaignResults } from './SpotlerCampaignResults';
import { SpotlerCampaignSelection } from './SpotlerCampaignSelection';
import { SpotlerCampaignStart } from './SpotlerCampaignStart';

export interface SpotlerCampaignProps
{
    spotlerCampaign: Entity;
    commitContext?: CommitContext
}

export const SpotlerCampaign: React.FC<SpotlerCampaignProps> = observer(
    (
        {
            spotlerCampaign,
            commitContext
        }
    ) =>
    {
        const types = useTypes();
        const phaseCode = useSpotlerCampaignPhaseCode(spotlerCampaign, commitContext);
        const isConcept = useMemo(
            () =>
                phaseCode === types.Activity.SpotlerCampaign.State.Concept,
            [
                phaseCode,
                types
            ]
        );

        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <Card>
                        <CardInset>
                            Campagne wordt verstuurd door: Spotler
                        </CardInset>
                    </Card>
                </ViewGroupItem>
                {
                    !isConcept &&
                    <ViewGroupItem>
                        <SpotlerCampaignResults
                            spotlerCampaign={spotlerCampaign}
                        />
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <Card>
                        <SpotlerCampaignSelection
                            spotlerCampaign={spotlerCampaign}
                            commitContext={commitContext}
                            isConcept={isConcept}
                        />
                    </Card>
                </ViewGroupItem>
                {
                    isConcept &&
                    <ViewGroupItem>
                        <Card>
                            <SpotlerCampaignStart
                                spotlerCampaign={spotlerCampaign}
                            />
                        </Card>
                    </ViewGroupItem>
                }
            </ViewGroup>
        </ExpansionGroup>
    }
);