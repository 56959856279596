import { observable } from 'mobx';
import { ToolbarElementCommandStore } from '../ToolbarElementCommandStore';
import { ToolbarIconCommandButton } from './ToolbarIconCommandButton';

export class ToolbarIconCommandButtonStore extends ToolbarElementCommandStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable icon: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string | number, icon: string, command: string)
    {
        super(id, ToolbarIconCommandButton, command);

        this.icon = icon;
        this.collapsible = true;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
