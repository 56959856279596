import React, { useCallback, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import TypeSelector from '../../../../../../Entity/Type/Selector/TypeSelector';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { IObservableArray, runInAction } from 'mobx';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../../Entity/Type/Api/useTypes';
import PortalDataSource from '../../../../../../../../@Api/Portal/DataSource/PortalDataSource';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import PortalDataSourceField from '../../../../../../../../@Api/Portal/DataSource/PortalDataSourceField';
import uuid from '../../../../../../../../@Util/Id/uuid';
import { EntityPath } from '../../../../../../Entity/Path/@Model/EntityPath';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import Predicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import FilterEditor from '../../../../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/FilterEditor/FilterEditor';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import styles from '../../../../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/ResourcePlannersEditor.module.scss';
import FieldPathSelector from '../../../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EntityContext } from '../../../../../../Entity/@Model/EntityContext';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import { EntityFieldPath } from '../../../../../../Entity/Path/@Model/EntityFieldPath';
import RightAlignedButtonGroup from '../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { default as EntityInput } from '../../../../../../Entity/Input/Input';
import PortalContext from '../../../../../../../../@Api/Portal/Context/PortalContext';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import AutomationDependencyContext from '../../../../../../../../@Api/Automation/AutomationDependencyContext';
import Centered from '../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../@Future/Component/Generic/Loader/Loader';
import ParametersEditor from '../../../../../../Entity/Viewer/Content/Automation/Editor/Parameters/ParametersEditor';
import CancelButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CancelButton/CancelButton';
import useTransactionalEntity from '../../../../../../../../@Api/Entity/Bespoke/useTransactionalEntity';

export interface PortalDataSourceEditorProps
{
    entity: Entity;
    onCancel: () => void;
    onSave: () => void;
}

const PortalDataSourceEditor: React.FC<PortalDataSourceEditorProps> =
    props =>
    {
        const { entity, onCancel, onSave } = props;
        const transactionalEntity = useTransactionalEntity(entity);
        const portalContext = useContext(PortalContext);
        const types = useTypes();

        const [ dataSource, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    const specification = entity.getObjectValueByField(types.PortalDataSource.Field.Specification);

                    if (specification)
                    {
                        return PortalDataSource.fromDescriptor(
                            entity.uuid,
                            undefined,
                            specification,
                            new AutomationDependencyContext(portalContext.parameters));
                    }
                    else
                    {
                        return new PortalDataSource(
                            entity.uuid,
                            entity.name,
                            undefined,
                            new ParameterDictionary([]),
                            undefined,
                            undefined,
                            []);
                    }
                },
                [
                    entity,
                    types,
                    portalContext
                ]);

        const setEntityType =
            useCallback(
                (entityType: EntityType) =>
                    runInAction(
                        () =>
                        {
                            dataSource.entityType = entityType;
                            dataSource.resultParameter = PortalDataSource.getResultParameter(entityType);
                            dataSource.parameters = new ParameterDictionary([]);
                            dataSource.filter = undefined;

                            const idFieldPath =
                                EntityPath.fromEntityType(entityType)
                                    .field(types.Entity.Field.Uuid);

                            dataSource.fields = [
                                new PortalDataSourceField(
                                    PortalDataSourceField.getParameter(
                                        uuid(),
                                        idFieldPath),
                                    idFieldPath)
                            ];
                        }),
                [
                    dataSource
                ]);

        const setFilter =
            useCallback(
                (filter: Predicate) =>
                    runInAction(
                        () =>
                            dataSource.filter = filter),
                [
                    dataSource
                ]);

        const entityContext =
            useComputed(
                () =>
                    dataSource &&
                        EntityContext.fromEntityType(dataSource.entityType),
                [
                    dataSource
                ]);

        const addFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            const correctedFieldPath =
                                fieldPath.isField
                                    ?
                                        fieldPath
                                    :
                                        fieldPath.path.field(
                                                fieldPath.path.entityType.inheritedNameField
                                            ||
                                                types.Entity.Field.Uuid);

                            dataSource.fields.push(
                                new PortalDataSourceField(
                                    PortalDataSourceField.getParameter(
                                        uuid(),
                                        correctedFieldPath),
                                    correctedFieldPath));
                        }),
                [
                    types,
                    dataSource
                ]);

        const save =
            useCallback(
                () =>
                {
                    transactionalEntity.setValueByField(
                        types.PortalDataSource.Field.Specification,
                        dataSource.toDescriptor());

                    return transactionalEntity.checkAndDoCommit()
                        .then(onSave);
                },
                [
                    transactionalEntity,
                    types,
                    dataSource,
                    onSave
                ]);

        const allParameters =
            useComputed(
                () =>
                    dataSource &&
                        ParameterDictionary.union(
                            portalContext.parameters,
                            dataSource.parameters),
                [
                    dataSource,
                    portalContext
                ]);

        const dividerGlue = useDividerGlue();

        if (isLoading)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
            >
                <ViewGroupItem>
                    <CardInset
                        bottom={false}
                    >
                        <EntityInput
                            labelPosition="none"
                            entity={transactionalEntity}
                            field={types.PortalDataSource.Field.Name}
                            doAutocommit={false}
                        />
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ParametersEditor
                        parameterDictionary={dataSource.parameters}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="vertical"
                            spacing={15}
                        >
                            <ViewGroupItem>
                                <InputGroup>
                                    <Input
                                        labelPosition="top"
                                        label={
                                            <LocalizedText
                                                code="Generic.Type"
                                                value="Type"
                                            />
                                        }
                                    >
                                        <TypeSelector
                                            value={dataSource.entityType}
                                            onChange={setEntityType}
                                            autoFocus
                                        />
                                    </Input>
                                    {
                                        dataSource.resultParameter &&
                                            <Input
                                                labelPosition="top"
                                                label={
                                                    <LocalizedText
                                                        code="Generic.Filter"
                                                        value="Filter"
                                                    />
                                                }
                                            >
                                                <FilterEditor
                                                    parameterDictionary={allParameters}
                                                    filterParameter={dataSource.resultParameter}
                                                    value={dataSource.filter}
                                                    onChange={setFilter}
                                                    lhsTypeNotFixed
                                                />
                                            </Input>
                                    }
                                    {
                                        dataSource.resultParameter &&
                                            <Input
                                                labelPosition="top"
                                                label={
                                                    <LocalizedText
                                                        code="PortalDataSource.FieldsInResult"
                                                        value="Velden in resultaat"
                                                    />
                                                }
                                            >
                                                <ol>
                                                    {
                                                        dataSource.fields
                                                            .map(
                                                                field =>
                                                                    <li
                                                                        key={field.parameter.id}
                                                                    >
                                                                        <ViewGroup
                                                                            orientation="horizontal"
                                                                            alignment="center"
                                                                            spacing={15}
                                                                        >
                                                                            <ViewGroupItem
                                                                                ratio={1}
                                                                            >
                                                                                {field.parameter.name}
                                                                            </ViewGroupItem>
                                                                            <ViewGroupItem>
                                                                                <DeleteIconButton
                                                                                    onClick={
                                                                                        () =>
                                                                                            runInAction(
                                                                                                () =>
                                                                                                    (dataSource.fields as IObservableArray).remove(field))
                                                                                    }
                                                                                />
                                                                            </ViewGroupItem>
                                                                        </ViewGroup>
                                                                    </li>)
                                                    }
                                                    <li>
                                                        <MenuButton
                                                            icon="add"
                                                            tooltip={
                                                                <LocalizedText
                                                                    code="Generic.Add"
                                                                    value="Toevoegen"
                                                                />
                                                            }
                                                            small
                                                        >
                                                            <ViewGroup
                                                                orientation="vertical"
                                                                spacing={5}
                                                                glue={dividerGlue}
                                                            >
                                                                <ViewGroupItem>
                                                                    <div
                                                                        className={styles.addSegmentLabel}
                                                                    >
                                                                        <LocalizedText
                                                                            code="Configuration.ResourcePlanner.PathToPlannableItem"
                                                                            value="Selecteer het pad naar het planbare item"
                                                                        />
                                                                    </div>
                                                                </ViewGroupItem>
                                                                <ViewGroupItem>
                                                                    <FieldPathSelector
                                                                        key={dataSource.fields.length}
                                                                        context={entityContext}
                                                                        onSelect={addFieldPath}
                                                                    />
                                                                </ViewGroupItem>
                                                            </ViewGroup>
                                                        </MenuButton>
                                                    </li>
                                                </ol>
                                            </Input>
                                    }
                                </InputGroup>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <RightAlignedButtonGroup>
                                    <CancelButton
                                        onClick={onCancel}
                                    />
                                    <SaveButton
                                        onClick={save}
                                    />
                                </RightAlignedButtonGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default observer(PortalDataSourceEditor);
