import * as React from 'react';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { FileUtils } from './FileUtils';
import { FileValue } from './FileValue';
import { FileViewerLightbox } from '../../../../Generic/FileViewer/Lightbox/FileViewerLightbox';
import { FileViewStore } from './FileViewStore';
import { useFetchedApiFile } from '../../../../../@Service/ApiClient/Hooks/useFetchedApiFile';

export interface FileViewComponentProps
{
    store: DataObjectViewerStore<FileViewStore>;
}

export const FileViewComponent: React.FC<FileViewComponentProps> =
    observer(
        ({
            store,
         }) =>
        {
            const fileValue = store.dataObject.value as FileValue;
            const fileAvatarUrl =
                fileValue
                    ? FileUtils.getFileAvatar(
                        store.dataObject.context.getFileUrl(fileValue.url),
                        fileValue.mime
                    )
                    : undefined;
            const [ initializedAvatarUrl ] = useFetchedApiFile(fileAvatarUrl);

            if (fileValue)
            {
                return <div
                    style={{display: 'flex'}}
                >
                    <div
                        style={{
                            width: '100%',
                            cursor: 'pointer'
                        }}
                        onClick={() => store.bespokeStore.handleClick()}
                    >
                        <img
                            alt=""
                            src={initializedAvatarUrl}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}
                        />
                    </div>
                    {
                        store.bespokeStore.fileViewerLightboxStore &&
                        <FileViewerLightbox
                            store={store.bespokeStore.fileViewerLightboxStore}
                        />
                    }
                </div>;
            }
            else
            {
                return <p>Bestand niet gevonden.</p>;
            }
        }
    );
