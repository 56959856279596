import useTypes from '../../../../Entity/Type/Api/useTypes';
import useCount from '../../../../Entity/Selection/Hooks/useCount';
import { useMemo } from 'react';

export default function useHasUnread()
{
    const types = useTypes();
    const count =
        useCount(
            types.Notification.Type,
            (builder, rootPath) =>
                builder.where(
                    cb =>
                        cb.eq(
                            rootPath.field(types.Notification.Field.IsRead),
                            undefined,
                            false
                        )
                ),
            [
                types,
            ]);

    return useMemo(
        () =>
            count > 0,
        [
            count
        ]);
}