import { DataComparator } from '../ListStore';

export function createBooleanComparator<D>(retriever: (data: D) => boolean): DataComparator<D>
{
    return (d1, d2) =>
    {
        const b1 = retriever(d1);
        const b2 = retriever(d2);

        // false first
        if (b1 === b2)
        {
            return 0;
        }
        else if (!b1 && b2)
        {
            return -1
        }
        else
        {
            return +1
        }
    }
}