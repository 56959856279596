import { DependencyList, useCallback, useEffect, useState } from 'react';

export function useMemoizedArray<T>(
    array: T[] | undefined,
    areValuesEqual: (a: T, b: T) => boolean = (a, b) => a === b,
    deps: DependencyList
): T[] | undefined
{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedAreValuesEqual = useCallback(areValuesEqual, deps);
    const [ memoizedArray, setMemoizedArray ] =
        useState<T[] | undefined>(
            () => array ? [...array] : undefined
        );

    useEffect(
        () =>
        {
            setMemoizedArray(
                oldArray =>
                {
                    if (array)
                    {
                        if (oldArray)
                        {
                            let isNotEqual = array.length !== oldArray.length;
                            const replacementArray = new Array(array.length);

                            for (let i = 0; i < replacementArray.length; i++)
                            {
                                if (areElementsEqual(oldArray, array, i, memoizedAreValuesEqual))
                                {
                                    replacementArray[i] = oldArray[i];
                                }
                                else
                                {
                                    isNotEqual = true;
                                    replacementArray[i] = array[i];
                                }
                            }

                            if (isNotEqual)
                            {
                                return replacementArray;
                            }
                            else
                            {
                                return oldArray;
                            }
                        }
                        else
                        {
                            return array;
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                }
            )
        },
        [
            array,
            memoizedAreValuesEqual,
            setMemoizedArray,
        ]
    );

    return memoizedArray;
}


function areElementsEqual<T>(
    oldArray: T[],
    newArray: T[],
    idx: number,
    equals: (a: T, b: T) => boolean
)
{
    const oldElement = idx >= oldArray.length ? null : oldArray[idx];
    const newElement = newArray[idx];
    const isOldElementNull = oldElement == null;
    const isNewElementNull = newElement == null;

    return (isOldElementNull && isNewElementNull)
        || (
            !isOldElementNull
                && !isNewElementNull
                && equals(oldElement, newElement)
        );
}
