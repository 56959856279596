import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultRelationshipEditor } from '../../DefaultRelationshipEditor/DefaultRelationshipEditor';
import { RelationshipEditorProps } from '../../RelationshipEditor';
import { SelectboxProps } from '../../../../Selectbox/Selectbox';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import CurrentUserContext from '../../../../../User/CurrentUserContext';

export interface EntityToPackEditorProps extends RelationshipEditorProps
{

}

const EntityToPackEditor: React.FC<EntityToPackEditorProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const pack =
            useRelatedEntity(
                props.entity,
                props.relationshipDefinition,
                props.isParent,
                props.commitContext
            );
        const isPackMutable =
            useMemo(
                () =>
                    currentUserStore.rightProfile.isOwnerOfPack(pack),
                [
                    currentUserStore,
                    pack
                ]);
        const selectboxProps =
            useMemo<Partial<SelectboxProps>>(
                () => ({
                    selections: currentUserStore.rightProfile.ownedPacks,
                    genericSelectboxProps: {
                        disabled: !isPackMutable
                    }
                }),
                [
                    currentUserStore,
                    isPackMutable
                ]);

        return <DefaultRelationshipEditor
            {...props}
            selectboxProps={selectboxProps}
        />;
    };

export default observer(EntityToPackEditor);
