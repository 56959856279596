import React from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { default as InternalResourcePlanner } from '../../../ResourcePlanner/ResourcePlanner';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import EmployeePlannerWidget from '../../Model/Widget/EmployeePlanner/EmployeePlannerWidget';
import useEmployeePlanner from '../../../ResourcePlanner/Type/useEmployeePlanner';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';

export interface EmployeePlannerProps extends WidgetProps<EmployeePlannerWidget>
{

}

const EmployeePlanner: React.FC<EmployeePlannerProps> =
    props =>
    {
        const resourcePlanner = useEmployeePlanner();

        return <Card>
            <CardInset
                horizontal={false}
                bottom={false}
            >
                <InternalResourcePlanner
                    id={props.widget.id}
                    resourcePlanner={resourcePlanner}
                    appendix={
                        <MenuButton>
                            <Menu>
                                <DeleteItem
                                    onClick={props.onDelete}
                                />
                            </Menu>
                        </MenuButton>
                    }
                    dragHandle
                />
            </CardInset>
        </Card>;
    };

export default observer(EmployeePlanner);
