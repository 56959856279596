import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import FormInputLayout from './FormInputLayout';
import localizeText from '../../../../Localization/localizeText';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import Computation from '../../../../Automation/Function/Computation/Computation';
import Predicate from '../../../../Automation/Function/Computation/Predicate/Predicate';
import getPredicateFromDescriptor from '../../../../Automation/Api/getPredicateFromDescriptor';
import LayoutEventTriggers from '../../../Event/LayoutEventTriggers';

export default class FilesFormInputLayout extends FormInputLayout
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(formId: string,
                parameterId: string,
                placeholder: Computation<any, any> | undefined,
                isDisabled: Predicate | undefined,
                hasIntrinsicSize: boolean = false,
                eventTriggers: LayoutEventTriggers = undefined)
    {
        super(formId, parameterId, placeholder, isDisabled, hasIntrinsicSize, eventTriggers);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.FilesFormInput', 'Input veld van formulier (meerdere bestanden)');
    }

    validate(): Validation[]
    {
        return super.validate();
    }

    getDependencies(): Dependency[]
    {
        return super.getDependencies();
    }

    _toDescriptor(descriptor: any)
    {
        Object.assign(
            descriptor,
            {
                type: 'FilesFormInput'
            });
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const placeholder =
            descriptor.placeholder
                ?
                    await getComputationFromDescriptor(
                        descriptor.placeholder,
                        dependencyContext)
                :
                    undefined;
        const isDisabled =
            descriptor.isDisabled
                ?
                    await getPredicateFromDescriptor(
                        descriptor.isDisabled,
                        dependencyContext)
                :
                    undefined;
        const eventTriggers =
            await LayoutEventTriggers.fromDescriptor(
                descriptor.eventTriggers || [],
                dependencyContext);

        return new FilesFormInputLayout(
            descriptor.formId,
            descriptor.parameterId,
            placeholder,
            isDisabled,
            descriptor.hasIntrinsicSize,
            eventTriggers);
    }

    // ----------------------- Private logic ------------------------
}
