import { Entity } from '../../Model/Implementation/Entity';
import { useEffect, useState } from 'react';
import useRelatedEntities from './useRelatedEntities';
import { EntityRelationshipDefinition } from '../../Model/Implementation/EntityRelationshipDefinition';
import { EntityExpansionBuilder } from '../../../@Component/Domain/Entity/Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { CommitContext } from '../Commit/Context/CommitContext';

export default function useFetchedRelatedEntities(
    entity: Entity,
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    commitContext?: CommitContext
): [ Entity[], boolean ]
{
    const [ isLoading, setLoading ] = useState(false);
    const relatedEntities =
        useRelatedEntities(
            entity,
            relationshipDefinition,
            isParent,
            commitContext
        );

    useEffect(
        () =>
        {
            if (entity && relationshipDefinition)
            {
                setLoading(true);

                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                relationshipDefinition,
                                isParent)
                    ])
                    .expand()
                    .then(
                        () =>
                            setLoading(false));
            }
        },
        [
            entity,
            relationshipDefinition,
            isParent,
            setLoading
        ]);

    return [
        relatedEntities,
        isLoading
    ];
}
