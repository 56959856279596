import React, { useCallback } from 'react';
import styles from './TimeDurationPicker.module.scss';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import NumberEditor from '../NumberEditor/NumberEditor';
import { observer } from 'mobx-react-lite';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface TimeDurationPickerClasses
{
    root?: string;
}

export interface TimeDurationPickerProps
{
    hours?: number;
    minutes?: number;
    onChange?: (hours?: number, minutes?: number) => void;
    autoFocus?: boolean;
    classes?: TimeDurationPickerClasses;
}

const TimeDurationPicker: React.FC<TimeDurationPickerProps> =
    props =>
    {
        const onChangeCallback = props.onChange;

        const onHoursChanged =
            useCallback(
                (value?: number) =>
                {
                    if (onChangeCallback)
                    {
                        onChangeCallback(value === undefined ? undefined : Math.max(value, 0), props.minutes);
                    }
                },
                [
                    onChangeCallback,
                    props.minutes
                ]);

        const onMinutesChanged =
            useCallback(
                (value?: number) =>
                {
                    if (onChangeCallback)
                    {
                        onChangeCallback(props.hours, value === undefined ? undefined : value);
                    }
                },
                [
                    onChangeCallback,
                    props.hours
                ]);

        return <ViewGroup
            orientation="horizontal"
            alignment="center"
            spacing={20}
        >
            <ViewGroupItem>
                <div
                    className={styles.editor}
                >
                    <NumberEditor
                        value={props.hours}
                        onChange={onHoursChanged}
                        min={0}
                        autoFocus={props.autoFocus}
                    />
                </div>
                <span
                    className={styles.label}
                >
                    <LocalizedText
                        code="Generic.Hour"
                        value="Uur"
                        lowerCase
                    />
                </span>
            </ViewGroupItem>
            <ViewGroupItem>
                <div
                    className={styles.editor}
                >
                    <NumberEditor
                        value={props.minutes}
                        onChange={onMinutesChanged}
                        min={0}
                        max={59}
                    />
                </div>
                <span
                    className={styles.label}
                >
                    <LocalizedText
                        code="Generic.Min"
                        value="Min"
                        lowerCase
                    />
                </span>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(TimeDurationPicker);
