import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import DividedList from '../../../List/V2/Divided/DividedList';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import styles from './RelatedList.module.scss';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import constructRelatedEntity from '../../../Dataset/Segment/Api/constructRelatedEntity';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { getModel } from '../../../../../../@Util/TransactionalModelV2/index';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { CommitContextImpl } from '../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import usePaginatedSelection from '../../../View/Api/usePaginatedSelection';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import useTypes from '../../../Type/Api/useTypes';
import getNameFieldByType from '../../../../../../@Api/Metadata/Field/getNameFieldByType';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import FilterEditor from '../../../../Configuration/TypeManager/TypeEditor/ResourcePlannersEditor/FilterEditor/FilterEditor';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';

export interface RelatedListProps
{
    entity: Entity;
    path: EntityPath;
    initializationPaths?: EntityPath[];
    fieldPathFilter?: (fieldPath: EntityFieldPath) => boolean;
    filterParameter?: Parameter<EntityValueType>;
    filter?: Predicate;
    showMenuButton?: boolean;
    title?: React.ReactNode;
    constructorLabel?: React.ReactNode;
    hideAddButton?: boolean;
}

const RelatedList: React.FC<RelatedListProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const [ filter, setFilter ] = useState<Predicate>(undefined);
        const [ isOpen, setOpen ] = useState(false);

        const parameterDictionary =
            useMemo(
                () =>
                    new ParameterDictionary([
                        new Parameter(
                            'Entity',
                            new EntityValueType(props.path.entityType),
                            true,
                            props.path.entityType.getName()
                        )
                    ]),
                [
                    props.path,
                ]
            );

        const filterParameter =
            useMemo(
                () =>
                    parameterDictionary.getParameterById('Entity'),
                [
                    parameterDictionary,
                ]
            );

        const [
            pages,
            hasMore,
            loadMore,
            isLoading
        ] =
            usePaginatedSelection(
                props.path.entityType,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    props.path.reverse(),
                                    props.entity
                                )
                        )
                        .if(
                            () =>
                                props.initializationPaths !== undefined,
                            sb =>
                                props.initializationPaths.forEach(
                                    initializationPath =>
                                        sb.join(initializationPath)
                                )
                        )
                        .if(
                            () => true,
                            sb =>
                                props.path.entityType.bespoke.getListDependencies()
                                    .forEach(
                                        dependencyPath =>
                                            sb.join(dependencyPath)
                                    )
                        )
                        .if(
                            () =>
                                props.filterParameter !== undefined
                                && props.filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            props.filter,
                                            {
                                                parameter: props.filterParameter,
                                            }
                                        )
                                )
                        )
                        .if(
                            () =>
                                filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            filter,
                                            {
                                                parameter: filterParameter,
                                            }
                                        )
                                )
                        )
                        .orderBy(
                            getNameFieldByType(props.path.entityType)?.toFieldPath()
                                ?? rootPath.field(types.Entity.Field.Name),
                            true
                        ),
                [
                    types,
                    props.path,
                    props.entity,
                    props.initializationPaths,
                    props.filterParameter,
                    props.filter,
                    filter,
                    filterParameter
                ]
            );

        const entities =
            useComputed(
                () =>
                    pages
                        .map(page => page.map(result => result.entity))
                        .reduce((a, b) => a.concat(b), []),
                [
                    pages
                ]);

        const canUpdateEntity =
            useMemo(
                () =>
                    currentUserStore.rightProfile.canUpdate(props.entity),
                [
                   props.entity,
                   currentUserStore.rightProfile
                ]);

        const onAdd =
            useCallback(
                () =>
                {
                    const commitContext = new CommitContextImpl({ allowAutoCommit: false });

                    return constructRelatedEntity(
                        getModel(props.entity),
                        props.path,
                        undefined,
                        undefined,
                        undefined,
                        props.fieldPathFilter,
                        commitContext
                    );
                },
                [
                    props.entity,
                    props.path,
                    props.fieldPathFilter,
                ]);

        useEffect(
            () =>
                {
                    if (!filter && isOpen)
                    {
                        setFilter(
                            new CompositePredicate(
                                LogicalOperator.And,
                                [
                                    new ComparisonPredicate(
                                        new ValueFromEntityComputation(
                                            filterParameter,
                                            undefined
                                        ),
                                        Comparator.Equals,
                                        undefined
                                    ),
                                ]
                            )
                        );
                    }
                },
                [
                    isOpen,
                    setFilter,
                    filterParameter,
                ]
        );

        const openFilter =
            useCallback(
                () =>
                    setOpen(!isOpen),
                [
                    isOpen,
                    setOpen
                ]
            );

        if (!isLoading && entities.length === 0 && props.hideAddButton)
        {
            return null;
        }
        else
        {
            return <Card>
                {
                    !isLoading && (entities.length > 0 || filter !== undefined)
                        ?
                            <CardInset
                                bottom={false}
                            >
                                <div
                                    className={styles.header}
                                >
                                    <CardHeader>
                                        {props.title || props.path.entityType.getName(true)}
                                    </CardHeader>
                                    {
                                        entities.length > 0 && canUpdateEntity && !props.hideAddButton &&
                                            <div
                                                className={styles.addButton}
                                            >
                                                <IconButton
                                                    icon="add"
                                                    tooltip={
                                                        <LocalizedText
                                                            code="Entity.Add"
                                                            value="${entityTypeName} toevoegen"
                                                            entityTypeName={props.path.entityType.getName()}
                                                        />
                                                    }
                                                    onClick={onAdd}
                                                />
                                            </div>
                                    }
                                    {
                                        <div
                                            className={styles.filterButton}
                                        >
                                                <MenuButton
                                                    icon="filter_list"
                                                    tooltip={
                                                        <LocalizedText
                                                            code="Generic.Filter"
                                                            value="Filter"
                                                        />
                                                    }
                                                    color={isOpen ? primaryColor : textSecondaryColor}
                                                    onClick={openFilter}
                                                    badge={filter}
                                                    badgeVariant="dot"
                                                />
                                        </div>
                                    }
                                </div>
                            </CardInset>
                        :
                            null
                }
                {
                    isOpen &&
                        <CardInset>
                            <FilterEditor
                                filterParameter={filterParameter}
                                parameterDictionary={parameterDictionary}
                                value={filter}
                                onChange={setFilter}
                            />
                        </CardInset>
                }
                {
                    isLoading &&
                        <CardInset>
                            <Centered
                                horizontal
                            >
                                <Loader />
                            </Centered>
                        </CardInset>
                }
                {
                    !isLoading && entities.length > 0 &&
                        <DividedList
                            entities={entities}
                            inset
                            openable
                            showMenuButton
                        />
                }
                {
                    hasMore &&
                        <div>
                            <Divider/>
                            <HoverCardBottom
                                onClick={loadMore}
                                disabled={isLoading}
                            >
                                <LocalizedText
                                    code="Generic.LoadMore"
                                    value="Meer laden"
                                />...
                            </HoverCardBottom>
                        </div>
                }
                {
                    !isLoading && entities.length === 0 && canUpdateEntity &&
                        <HoverCardBottom
                            onClick={onAdd}
                        >
                            + {props.constructorLabel || props.path.entityType.getName()}
                        </HoverCardBottom>
                }
            </Card>;
        }
    };

export default observer(RelatedList);
