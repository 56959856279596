import { queryPricesForProduct } from './queryPricesForProduct';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';
import { MathematicalOperator } from '../../../../../../../../DataObject/Model/MathematicalOperator';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import getTypes from '../../../../../../../Type/Api/getTypes';

export function determinePriceForProductLine(
    productLine: Entity,
    product: Entity,
    activity: Entity,
    commitContext: CommitContext,
    date: Date
)
{
    const types = getTypes();
    const quantity = productLine.getObjectValueByField(types.ProductLine.Field.Quantity, commitContext) ?? 0;
    const isProductIncludingVat = product?.getObjectValueByField(types.Product.Field.IsVatIncluded, commitContext);
    const isActivityIncludingVat = activity?.getObjectValueByField(types.Activity.Field.IsVatIncluded, commitContext);

    const relationship =
        activity.getRelatedEntityByDefinition(
            true,
            types.Relationship.RelationshipDefinition.Activities,
            commitContext
        );

    const vatGroup =
        productLine.getRelatedEntityByDefinition(
            false,
            types.ProductLine.RelationshipDefinition.VatGroup,
            commitContext
        );

    return queryPricesForProduct(
        activity,
        product,
        relationship,
        quantity,
        date
    )
        .then(
            prices =>
            {
                let basePrice =
                    types.Datastore.VatGroup.Function.ComputeVatPrice(
                        product.getObjectValueByField(types.Product.Field.SalesPrice),
                        isProductIncludingVat,
                        isActivityIncludingVat,
                        vatGroup
                    ) ?? 0;

                if (prices.length === 0)
                {
                    // No pricelist prices found, return SalesPrice from product
                    return basePrice;
                }
                else
                {
                    const price = prices[0];
                    let newPrice: number = undefined;

                    if (price.hasValueForField(types.Price.Field.Price, commitContext))
                    {
                        newPrice = price.getObjectValueByField(types.Price.Field.Price, commitContext);
                    }
                    else if (price.hasValueForField(types.Price.Field.DiscountPercentage, commitContext))
                    {
                        // Use Price of Price or SalesPrice of Product to calculate discount from DiscountPercentage
                        basePrice =
                            types.Datastore.VatGroup.Function.ComputeVatPrice(
                                price.getObjectValueByField(types.Price.Field.Price) ||
                                product.getObjectValueByField(types.Product.Field.SalesPrice),
                                isProductIncludingVat,
                                isActivityIncludingVat,
                                vatGroup
                            )

                        if (basePrice !== undefined)
                        {
                            newPrice =
                                DataObject.compute(
                                    DataObject.constructFromTypeIdAndValue('Currency', basePrice),
                                    DataObject.compute(
                                        DataObject.constructFromTypeIdAndValue('Percentage', 100),
                                        price.getDataObjectValueByField(types.Price.Field.DiscountPercentage),
                                        MathematicalOperator.Subtract
                                    ),
                                    MathematicalOperator.Multiply
                                )?.value;
                        }
                    }

                    return newPrice;
                }
            }
        );
}