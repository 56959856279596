import { observable } from 'mobx';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import Parameter from '../../../../Automation/Parameter/Parameter';
import ValueType from '../../../../Automation/Value/Type/ValueType';

export default class FormParameter<T extends ValueType<any>>
{
    // ------------------------- Properties -------------------------

    @observable formId: string;
    @observable.ref parameter: Parameter<T>;

    // ------------------------ Constructor -------------------------

    constructor(formId: string, parameter: Parameter<T>)
    {
        this.formId = formId;
        this.parameter = parameter;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId()
    {
        return `${this.formId}.${this.parameter.id}`;
    }

    toDescriptor()
    {
        return {
            formId: this.formId,
            parameterId: this.parameter.id,
        };
    }

    static fromDescriptor(descriptor: any,
                          dependencyContext: LayoutDependencyContext)
    {
        return new FormParameter(
            descriptor.formId,
            dependencyContext.parameterDictionary.getParameterById(
                descriptor.parameterId
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
