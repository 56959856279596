import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import InputGroup from '../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import DynamicActionParameterEditor from './DynamicFunctionParameterEditor';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import DynamicFunctionInvocation from '../../../../../../../../@Api/Automation/Function/Dynamic/DynamicFunctionInvocation';

export interface DynamicFunctionInvocationEditorProps
{
    context: FunctionContext;
    invocation: DynamicFunctionInvocation;
    disabled?: boolean;
}

const DynamicFunctionInvocationEditor: React.FC<DynamicFunctionInvocationEditorProps> =
    props =>
    {
        const parameters =
            useComputed(
                () =>
                    Array.from(props.invocation.parameterDictionary.parameterById.values()),
                [
                    props.invocation
                ]);

        if (props.invocation.function)
        {
            return <CardInset
                top={false}
            >
                <InputGroup>
                    {
                        parameters.map(
                            parameter =>
                                <DynamicActionParameterEditor
                                    key={parameter.id}
                                    context={props.context}
                                    invocation={props.invocation}
                                    parameter={parameter}
                                    disabled={props.disabled}
                                />)
                    }
                </InputGroup>
            </CardInset>;
        }
        else
        {
            return null;
        }
    };

export default observer(DynamicFunctionInvocationEditor);
