import Role, { Permission, Privilege, RoleType } from '../../Model/Role';
import { getEntityTypeSpecifiedPermission } from './EntityType/getEntityTypeSpecifiedPermission';
import { getFieldSpecifiedPermission } from './Field/getFieldSpecifiedPermission';
import { getRelationshipDefinitionSpecifiedPermission } from './RelationshipDefinition/getRelationshipDefinitionSpecifiedPermission';
import { EntityMetadataPermission } from '../../Model/EntityMetadataPermission';

export function getSpecifiedPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    metadataPermission: EntityMetadataPermission
): Permission
{
    switch (metadataPermission.type)
    {
        case 'EntityType':
            return getEntityTypeSpecifiedPermission(
                rolesByType,
                role,
                privilege,
                metadataPermission.entityType
            );
        case 'EntityField':
            return getFieldSpecifiedPermission(
                rolesByType,
                role,
                privilege,
                metadataPermission.field
            );
        case 'RelationshipDefinition':
            return getRelationshipDefinitionSpecifiedPermission(
                rolesByType,
                role,
                privilege,
                metadataPermission.relationship
            );
        default:
            throw new Error('Unsupported entity metadata permission type');
    }

}