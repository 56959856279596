import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import Selectbox from '../../../Entity/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../Entity/Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../../Entity/Type/Api/useTypes';
import useEntityByUuid from '../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import TemplateLayout from '../../../../../@Api/Layout/Type/TemplateLayout';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import DynamicParameterAssignment from '../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import LayoutTemplate from '../../../../../@Api/Layout/Template/LayoutTemplate';
import CompositeLayout from '../../../../../@Api/Layout/Type/CompositeLayout';

export interface TemplateSelectorProps extends LayoutEditorProps<TemplateLayout>
{

}

const TemplateSelector: React.FC<TemplateSelectorProps> =
    props =>
    {
        const types = useTypes();

        const selections =
            useMemo(
                () =>
                    [
                        new EntitySelectionBuilder(types.LayoutTemplate.Type)
                            .build()
                    ],
                [
                    types
                ]);

        const [ template ] = useEntityByUuid(types.LayoutTemplate.Type, props.layout.templateId);
        const setTemplate =
            useCallback(
                async (templateEntity: Entity) =>
                {
                    const template =
                        templateEntity
                            ?
                                await TemplateLayout.getTemplateByEntity(
                                    templateEntity,
                                    new AutomationDependencyContext(new ParameterDictionary([])))
                                        || new LayoutTemplate(new ParameterDictionary([]), new CompositeLayout('Vertical', []))
                            :
                                undefined;

                    runInAction(
                        () =>
                        {
                            props.layout.templateId = templateEntity?.uuid;
                            props.layout.template = template;
                            props.layout.parameterAssignment = new DynamicParameterAssignment();
                        });
                },
                [
                    props.layout,
                    types
                ]);

        return <Input
            labelPosition="left"
            label={
                <LocalizedText
                    code="Generic.Template"
                    value="Sjabloon"
                />
            }
        >
            <Selectbox
                selections={selections}
                value={template}
                onChange={setTemplate}
                disableUnderline={false}
            />
        </Input>;
    };

export default observer(TemplateSelector);
