import { useContext } from 'react';
import AuthenticationManagerContext from '../../../@Service/Authentication/AuthenticationManagerContext';
import useAsyncResult from '../../../@Util/Async/useAsyncResult';
import useApiClient from '../../../@Service/ApiClient/Hooks/useApiClient';
import { getAuthenticationEntryPoint } from '../../AuthenticationEntryPoint/Api/getAuthenticationEntryPoint';

export function useOrganizationAndPortalIdFromContext()
{
    const authenticationManager = useContext(AuthenticationManagerContext);
    const apiClient = useApiClient();

    return useAsyncResult(
        async () =>
        {
            const organizationId = authenticationManager.probeOrganizationIdFromUrl();
            const portalId = authenticationManager.probePortalIdFromUrl();

            if (organizationId && portalId)
            {
                return {
                    organizationId,
                    portalId,
                };
            }
            else
            {
                const authenticationEntrypoint = await getAuthenticationEntryPoint(apiClient);

                return {
                    organizationId: authenticationEntrypoint.organizationId,
                    portalId: authenticationEntrypoint.portalId,
                };
            }
        },
        [
            authenticationManager,
            apiClient,
        ]
    );
}