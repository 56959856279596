import { observable } from 'mobx';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';

export default class ActivityListItem
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref path: EntityPath;
    @observable isConstructable: boolean;
    @observable isConstructorInMoreMenu: boolean;

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<EntityValueType>,
                path: EntityPath,
                isConstructable: boolean,
                isConstructorInMoreMenu: boolean)
    {
        this.parameter = parameter;
        this.path = path;
        this.isConstructable = isConstructable;
        this.isConstructorInMoreMenu = isConstructorInMoreMenu;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get id()
    {
        return this.parameter.id;
    }

    static async fromDescriptor(descriptor: any)
    {
        const path = EntityPath.construct(descriptor.path);
        const parameter = new Parameter(descriptor.id, new EntityValueType(path.entityType), true, path.entityType.getName());

        return new ActivityListItem(
            parameter,
            path,
            descriptor.isConstructable,
            descriptor.isConstructorInMoreMenu);
    }

    toDescriptor(): any
    {
        return {
            id: this.id,
            path: this.path.descriptor,
            isConstructable: this.isConstructable,
            isConstructorInMoreMenu: this.isConstructorInMoreMenu
        };
    }

    // ----------------------- Private logic ------------------------
}
