import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import React, { useMemo } from 'react';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import CampaignPercentageChart from '../../Campaign/Charts/CampaignPercentageChart';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useApsisCampaignSendCount from '../Hooks/useApsisCampaignSendCount';
import useApsisCampaignBouncedCount from '../Hooks/useApsisCampaignBouncedCount';
import { observer } from 'mobx-react';

export interface ApsisCampaignChartUndeliverableProps
{
    apsisCampaign: Entity;
    size: number;
}

export const ApsisCampaignChartUndeliverable: React.FC<ApsisCampaignChartUndeliverableProps> =
    observer(
        (
            {
                apsisCampaign,
                size
            }
        ) =>
        {
            const totalResultCount =
                useApsisCampaignSendCount(
                    apsisCampaign
                );

            const undeliverableCount =
                useApsisCampaignBouncedCount(
                    apsisCampaign
                );

            const allSet =
                useAllSet(
                    [
                        totalResultCount,
                        undeliverableCount
                    ]
                );

            const formattedSelection =
                useMemo(
                    () =>
                    {
                        return `${undeliverableCount} / ${totalResultCount}`;
                    },
                    [
                        undeliverableCount,
                        totalResultCount
                    ]
                );

            return <div>
                {
                    allSet &&
                    <CampaignPercentageChart
                        value={undeliverableCount / totalResultCount * 100}
                        size={size}
                        label={localizeText('ApsisCampaign.Chart.Failed', 'Mislukt')}
                        color={'#8f0012'}
                        selection={formattedSelection}
                    />
                }
            </div>;
        }
    );