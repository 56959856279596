import { observable } from 'mobx';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import getSetting from '../../../Setting/Api/getSetting';
import { SettingSource } from '../../../Setting/SettingStore';
import { Setting } from '../../../../../@Api/Settings/Setting';

export default abstract class Input
{
    // ------------------------- Properties -------------------------

    @observable.ref entityType: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType)
    {
        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id()
    {
        return this.entityType.id.toString();
    }

    toDescriptor()
    {
        return {
            entityTypeId: this.entityType.isStaticType() ? undefined : this.entityType.id,
            entityTypeCode: this.entityType.isStaticType() ? this.entityType.code : undefined
        };
    }

    abstract getName();

    abstract isRequired(): boolean;

    abstract isComputed(): boolean;

    abstract getValue(entity: Entity): DataObject;

    abstract toFieldPath(): EntityFieldPath;

    abstract getFieldCode(): string;

    abstract fromType(entityType: EntityType): Input;

    protected isRequiredBySetting(): boolean
    {
        return getSetting(SettingSource.Organization, Setting.Metadata.RequiredFields)[this.id()];
    }

    // ----------------------- Private logic ------------------------
}
