import Role, { Permission, Privilege } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import Right from '../../../Model/Right';
import { resetChildEntityTypePermissions } from './resetChildEntityTypePermissions';
import { resetEntityTypeFieldsAndRelationshipsPermissions } from './resetEntityTypeFieldsAndRelationshipsPermissions';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export function setEntityTypePermission(
    role: Role,
    privilege: Privilege,
    permission: Permission,
    entityType: EntityType,
    condition: Predicate,
    parameterDictionary: ParameterDictionary
)
{
    const right = role.rightByType.get(entityType)
        ?? new Right(parameterDictionary);

    right.setPermission(privilege, permission, condition);

    if (permission === 'Denied' && privilege === 'canRead')
    {
        const privileges: Privilege[] = [ 'canUpdate', 'canDelete', 'canExport' ];

        privileges.forEach(
            privilege =>
                right
                    .setPermission(
                        privilege,
                        'Inherited'
                    )
        );
    }

    const entityTypePrivileges: Privilege[] =
        (permission === 'Denied' && privilege === 'canRead')
            ? [ privilege, 'canUpdate', 'canDelete', 'canExport' ]
            : [ privilege ];

    if (permission === 'Denied')
    {
        resetEntityTypeFieldsAndRelationshipsPermissions(
            role,
            entityTypePrivileges,
            entityType
        );

        resetChildEntityTypePermissions(
            role,
            entityTypePrivileges,
            entityType
        );
    }

    role.rightByType.set(entityType, right);
}