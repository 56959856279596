import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './RichtextEditor.module.scss';
import './Styles/quill.snow.scss';
import './Styles/quillEditor.scss';
import ReactQuill from 'react-quill';
import { classNames } from '../../../../Util/Class/classNames';
import RichtextEditorContext from './RichtextEditorContext/RichtextEditorContext';
import IconButton from '../../Button/Variant/Icon/IconButton';
import useSwitch from '../../../../../@Util/Switch/useSwitch';
import { GlobalEnvironment } from '../../../../../@Global/GlobalEnvironment';

export interface RichTextValue
{
    value: string;
    delta?: any;
}

export interface RichtextEditorClasses
{
    root?: string;
    editor?: string;
    toolbar?: string;
}

export interface RichtextEditorProps
{
    value?: string | RichTextValue;
    defaultValue?: string;
    onChange?: (value: string | RichTextValue) => void;
    useRichTextValue?: boolean;
    editorRef?: (reference: any) => void;
    placeholder?: string;
    allowTextStyling?: boolean;
    initialHeight?: number;
    collapsibleToolbar?: boolean;
    hideToolbarWhenEmpty?: boolean;
    autoFocus?: boolean;
    onKeyDown?: (event) => void;
    onFocus?: (event: any) => void;
    onBlur?: (event: any) => void;
    classes?: RichtextEditorClasses;
    showToolbarOnFocus?: boolean;
    onChangeSelection?: () => void;
    modules?: any;
    singleLine?: boolean; // https://github.com/quilljs/quill/issues/1432#issuecomment-486659920
    extraTextStylingFormats?: string[]; // meant to expand the
}

export const EmptyDelta = { ops: [] };
const EmptyQuillValue = '<p><br></p>';

const RichtextEditor: React.FC<RichtextEditorProps> =
    props =>
    {
        const { onChange: setValue, onFocus, onBlur } = props;

        const isRichTextFormat =
            useMemo(
                () =>
                    props.useRichTextValue,
                [
                    props.useRichTextValue
                ]);

        const value =
            useMemo(
                () =>
                {
                    if (isRichTextFormat)
                    {
                        if (props.value)
                        {
                            if ((props.value as RichTextValue).delta)
                            {
                                return (props.value as RichTextValue).delta;
                            }
                            else
                            {
                                return (props.value as RichTextValue).value;
                            }
                        }
                        else
                        {
                            return EmptyDelta;
                        }
                    }
                    else
                    {
                        return props.value;
                    }
                },
                [
                    isRichTextFormat,
                    props.value
                ]);

        const onChange =
            useCallback(
                (value, delta, source, editor) =>
                {
                    if (setValue)
                    {
                        const valueIfEmpty = value === EmptyQuillValue ? undefined : value;

                        if (isRichTextFormat)
                        {
                            setValue({
                                value: valueIfEmpty,
                                // Do NOT use delta from the parameters (this is documented by React Quill that you do
                                // not want this)
                                delta: editor.getContents()
                            });
                        }
                        else
                        {
                            if (source === 'user')
                            {
                                setValue(valueIfEmpty);
                            }
                        }
                    }
                },
                [
                    setValue,
                    isRichTextFormat
                ]);

        const quillReference = useRef();
        const [ collapsibleToolbarVisible, setCollapsibleToolbarVisible ] = useState(!props.collapsibleToolbar);
        const { onKeyDown } = props;

        const quillEditor =
            useMemo(
                () =>
                {
                    if (quillReference && quillReference.current)
                    {
                        return (quillReference.current as any).getEditor();
                    }
                    else
                    {
                        return undefined;
                    }
                },
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [
                    quillReference,
                    quillReference.current // Needed to trigger
                ]);

        useEffect(
            () =>
            {
                if (props.autoFocus && quillReference && quillReference.current)
                {
                    (quillReference.current as any).focus();
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                props.autoFocus,
                quillReference,
                quillReference.current  // Needed to trigger
            ]);

        const contentLength =
            useMemo(
                () =>
                {
                    if (isRichTextFormat)
                    {
                        return ((props.value as RichTextValue).value || '').length;
                    }
                    else
                    {
                        return ((props.value as string) || '').length;
                    }
                },
                [
                    isRichTextFormat,
                    props.value
                ]);


        const [ isToolbarFocused, focusToolbar, blurToolbar ] = useSwitch(false);

        const showToolbar =
            useMemo(
                () =>
                {
                    if (props.hideToolbarWhenEmpty)
                    {
                        if (isToolbarFocused)
                        {
                            return true;
                        }
                        else if (contentLength === 0)
                        {
                            return false;
                        }
                        else if (quillEditor)
                        {
                            return quillEditor.getLength() > 1;
                        }
                        else
                        {
                            return false;
                        }
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    props.hideToolbarWhenEmpty,
                    isToolbarFocused,
                    contentLength,
                    quillEditor
                ]);

        const { onChangeSelection } = props;
        const onChangeSelectionCallback =
            useCallback(
                () =>
                {
                    if (onChangeSelection)
                    {
                        onChangeSelection();
                    }
                },
                [
                    onChangeSelection
                ]);

        const linkHandler =
            useCallback(
                () => {
                    if (!quillReference.current) return;

                    // Get the current selection range and insert the link at that index
                    const quill = quillReference.current as unknown as ReactQuill;
                    const editor = quill.getEditor()
                    const range = editor.getSelection(true);
                    const text = editor.getText(range.index, range.length);
                    editor.format('link', text, 'user');

                },
                [
                    quillReference
                ]);

        const performCommand =
            useCallback(
                (command: string, isActive: boolean) =>
                {
                    if (command && quillEditor)
                    {
                        let dividerPos = command.indexOf('.');
                        if (dividerPos > 0)
                        {
                            let commandType = command.substring(0, dividerPos);
                            let commandValue = command.substring(dividerPos + 1);
                            quillEditor.format(commandType, isActive ? commandValue : undefined, 'user');
                        }
                        else
                        {
                            switch (command)
                            {
                                case 'link':
                                    linkHandler();
                                    break;
                                default:
                                    quillEditor.format(command, isActive, 'user');
                                    break;
                            }
                        }
                    }
                },
                [
                    quillEditor
                ]);

        const updateFormat =
            useCallback(
                (command: string, value: any) =>
                {
                    if (quillEditor)
                    {
                        quillEditor.format(command, value, 'user');
                    }
                },
                [
                    quillEditor
                ]);

        const onKeyDownCallback =
            useCallback(
                (event) =>
                    onKeyDown
                        ?
                        onKeyDown(event)
                        :
                        undefined,
                [
                    onKeyDown
                ]);

        const onClickCallback =
            useCallback(
                (e) => e.stopPropagation ? e.stopPropagation() : undefined,
                []);

        const rootClassNames =
            useMemo(
                () =>
                    classNames(
                        styles.root,
                        'rich-textbox-editor'),
                []);

        const editorClassnames =
            useMemo(
                () =>
                    classNames(
                        'rich-textbox-editor',
                        styles.quillWrapper,
                        props.classes && props.classes.editor),
                [
                    props.classes
                ]);

        const collapseButtonClassnames =
            useMemo(
                () =>
                    classNames(
                        styles.collapseButton,
                        !showToolbar && styles.hidden),
                [
                    showToolbar
                ]);

        const toolbarClassnames =
            useMemo(
                () =>
                    classNames(
                        styles.toolbar,
                        props.classes && props.classes.toolbar),
                [
                    props.classes
                ]);

        const toggleToolbarVisibility =
            useCallback(
                () =>
                    setCollapsibleToolbarVisible(
                        !collapsibleToolbarVisible),
                [
                    setCollapsibleToolbarVisible,
                    collapsibleToolbarVisible
                ]);

        const allowedFormats =
            useMemo(
                () =>
                    props.allowTextStyling
                        ?
                        [
                            'header', 'font', 'color', 'size',
                            'bold', 'italic', 'underline', 'strike',
                            'align', 'list', 'bullet', 'indent',
                            'link', 'Computation',
                            ...(props.extraTextStylingFormats || [])
                        ]
                        :
                        [
                            'Computation'
                        ],
                [
                    props.allowTextStyling
                ]);

        const [ hasFocus, setFocus ] = useState(false);

        const onFocusCallback =
            useCallback(
                (event: any) =>
                {
                    if (onFocus)
                    {
                        onFocus(event);
                    }

                    setFocus(true);
                },
                [
                    onFocus,
                    setFocus
                ]);

        const onBlurCallback =
            useCallback(
                (event: any, source: any) =>
                {
                    if (source === 'silent') 
                    {
                        return
                    }

                    if (onBlur)
                    {
                        onBlur(event);
                    }

                    setFocus(false)
                },
                [
                    onBlur,
                    setFocus
                ]);

        const { editorRef } = props;
        useEffect(
            () =>
            {
                if (editorRef)
                {
                    if (quillReference)
                    {
                        editorRef(quillReference.current);
                    }
                }
            },
            [
                editorRef,
                quillReference
            ]);

        const style =
            useMemo(
                () => ({ width: '100%' }),
                []);

        const modules =
            useMemo(
                () => ({
                    toolbar: [],
                    ...props.singleLine
                        ?
                            {
                                keyboard: {
                                    bindings: {
                                        enter: {
                                            key: 13,
                                            handler: () => false
                                        }
                                    }
                                }
                            }
                        :
                            {},
                    clipboard: {
                        matchVisual: false,
                        matchers: [
                            [
                                Node.ELEMENT_NODE,
                                (node, delta) => {
                                    for (const op of delta.ops)
                                    {
                                        // If a link is copied and pasted that has a variable as href, e.g. <a href="{{Document.SignLink}}">...</a>,
                                        // Quill replace this with i.e. "https://app.tribecrm.nl/entity/%7B%7BDocument.SignLink%7D%7D"
                                        // We override it back to the original href="{{Document.SignLink}}"
                                        if (op.insert === 'link'
                                            && op.attributes?.link?.startsWith(GlobalEnvironment.APP_ENDPOINT))
                                        {
                                            const start = op.attributes.link.indexOf('%7B%7B');
                                            const end = op.attributes.link.indexOf('%7D%7D');

                                            if (start >= 0
                                                && end >= 0
                                                && start < end
                                                && op.attributes.link.endsWith(op.attributes.link.substring(start, end + 6)))
                                            {
                                                const variable = op.attributes.link.substring(start + 6, end);
                                                op.attributes.link = `{{${variable}}}`;
                                            }
                                        }
                                    }

                                    return delta;
                                }
                            ]
                        ]
                    },
                    ...props.modules

                }),
                [
                    props.modules,
                    props.singleLine
                ]);

        return <div
            className={rootClassNames}
            onClick={onClickCallback}
        >
            <div
                className={editorClassnames}
                style={{
                    minHeight: props.initialHeight
                }}
            >
                <ReactQuill
                    ref={quillReference}
                    style={style}
                    {...value === undefined || props.defaultValue !== undefined ? {} : { value: value }}
                    {...props.defaultValue === undefined ? {} : { defaultValue: props.defaultValue }}
                    onChange={onChange}
                    onKeyDown={onKeyDownCallback}
                    onChangeSelection={onChangeSelectionCallback}
                    modules={modules}
                    formats={allowedFormats}
                    placeholder={props.placeholder}
                    onFocus={onFocusCallback}
                    onBlur={onBlurCallback}
                />
                {
                    props.collapsibleToolbar &&
                        <IconButton
                            classes={{
                                root: collapseButtonClassnames
                            }}
                            icon="format_color_text"
                            tooltip="Toon/Verberg toolbar"
                            onClick={toggleToolbarVisibility}
                        />
                }
            </div>
            <RichtextEditorContext.Provider
                value={{
                    editor: quillEditor,
                    allowTextStyling: props.allowTextStyling,
                    showCollapsibleToolbar: collapsibleToolbarVisible,
                    performCommand: performCommand,
                    updateFormat: updateFormat,
                    onFocusToolbar: focusToolbar,
                    onBlurToolbar: blurToolbar,
                }}
            >
                <div
                    style={{
                        display: showToolbar && (props.showToolbarOnFocus ? hasFocus || isToolbarFocused : true) ? 'block' : 'none'
                    }}
                    className={toolbarClassnames}
                >
                    {props.children}
                </div>
            </RichtextEditorContext.Provider>
        </div>;
    };

RichtextEditor.defaultProps =
    {
        allowTextStyling: true,
        initialHeight: 32,
        collapsibleToolbar: false,
        hideToolbarWhenEmpty: false,
        value: ''
    };

export default RichtextEditor;
