import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Region.module.scss';
import { classNames } from '../../../../../../../@Future/Util/Class/classNames';

export interface ClickProps
{
    clickable?: boolean;
    highlighted?: boolean;
    onClick?: () => void;
}

const Region: React.FC<ClickProps> =
    props =>
    {
        const rootClassName =
            useMemo(
                () =>
                    classNames(
                        styles.root,
                        props.highlighted && styles.highlighted,
                        props.clickable && styles.clickable),
                [
                    props.highlighted,
                    props.clickable
                ]);

        return <div
            className={rootClassName}
            onClick={props.clickable ? props.onClick : undefined}
        >
            {props.children}
        </div>;
    };

export default observer(Region);
