import React, { useMemo } from 'react';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import useCreatableAddPaths from './Api/useCreatableAddPaths';
import { AddEntityPath } from '../../Path/@Model/AddEntityPath';
import { EntityPathCastNode } from '../../Path/@Model/Node/EntityPathCastNode';

import styles from './EntityConstructorButton.module.scss';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import { EntityConstructorButtonProps } from './EntityConstructorButton';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';
import { RelatedEntityPath } from '../../Path/@Model/RelatedEntityPath';
import { EntityPath } from '../../Path/@Model/EntityPath';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import TypeSelector from '../../Type/Selector/TypeSelector';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import localizeText from '../../../../../@Api/Localization/localizeText';

export interface EntityConstructorPathSelectorProps extends EntityConstructorButtonProps
{
    onSelect: (path: AddEntityPath) => void;
}

interface Group
{
    name: string;
    type: EntityType;
    paths: AddEntityPath[];
}

const EntityConstructorPathSelector: React.FC<EntityConstructorPathSelectorProps> =
    props =>
    {
        const { onSelect } = props;

        const creatableAddPaths =
            useCreatableAddPaths(
                props.rootEntity,
                props.entityType,
                props.pathFromRootEntity,
                props.ignoreHidden);

        const groups =
            useMemo(
                () =>
                {
                    const groupByName = new Map<string, Group>();
                    const groups: Group[] = [];

                    creatableAddPaths
                        .forEach(
                            path =>
                            {
                                if(path.relativePath.path.lastNode instanceof EntityPathCastNode)
                                {
                                    const type = path.relativePath.path.getNodeEntityType(path.relativePath.path.nodes.length - 2);
                                    const name = type.getName(true);

                                    if (groupByName.has(name))
                                    {
                                        groupByName.get(name).paths.push(path);
                                    }
                                    else
                                    {
                                        groupByName.set(
                                            name,
                                            {
                                                name: name,
                                                type: type,
                                                paths: [ path ]
                                            });

                                        groups.push(groupByName.get(name));
                                    }
                                }
                                else
                                {
                                    if (groupByName.has('Overig'))
                                    {
                                        groupByName.get('Overig').paths
                                            .push(path);
                                    }
                                    else
                                    {
                                        groupByName.set(
                                            'Overig',
                                            {
                                                name: localizeText('Generic.Other', 'Overig'),
                                                type: undefined,
                                                paths: [ path ]
                                            });
                                        groups.push(groupByName.get('Overig'));
                                    }
                                }
                            });

                    groups.sort(createStringComparator(d => d.name));

                    return groups;
                },
                [
                    creatableAddPaths
                ]);

        const isCompact = useIsMobile();
        const [ isTypeSelectorOpen, openTypeSelector ] = useToggle(false);

        return <ViewGroup
            orientation={isCompact ? 'vertical' : 'horizontal'}
            spacing={8}
        >
            {
                groups.length > 0
                    ?
                        groups.map(
                            group =>
                                <ViewGroupItem
                                    key={group.name}
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={0}
                                    >
                                        <ViewGroupItem
                                            className={styles.title}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={10}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    {group.name}
                                                </ViewGroupItem>
                                                {
                                                    props.ignoreHidden && group.type &&
                                                        <ViewGroupItem>
                                                            <IconButton
                                                                icon="touch_app"
                                                                onClick={
                                                                    () =>
                                                                        onSelect(
                                                                            new AddEntityPath(
                                                                                new RelatedEntityPath(undefined, EntityPath.fromEntityType(group.type))
                                                                            ))}
                                                                tooltip={`${localizeText('Generic.Select', 'Selecteer')} ${group.name}`}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <Menu
                                                hideGlue
                                            >
                                                {
                                                    group.paths.map(
                                                        path =>
                                                            <Item
                                                                key={path.fullPath.id}
                                                                name={path.relativePath.path.entityType.getName()}
                                                                onClick={() => onSelect(path)}
                                                            />)
                                                }
                                                {
                                                    group.name === `${localizeText('Generic.Other', 'Overig')}`
                                                    && !isTypeSelectorOpen
                                                    && props.ignoreHidden
                                                    &&
                                                        <Item
                                                            name={localizeText('EntityConstructor.Other', 'Overig...')}
                                                            onClick={openTypeSelector}
                                                        />
                                                }
                                            </Menu>
                                            {
                                                isTypeSelectorOpen
                                                && group.name === `${localizeText('Generic.Other', 'Overig')}`
                                                &&
                                                    <CardInset
                                                        vertical={false}
                                                    >
                                                        <TypeSelector
                                                            value={undefined}
                                                            onChange={
                                                                type =>
                                                                    onSelect(
                                                                        new AddEntityPath(
                                                                            new RelatedEntityPath(undefined, EntityPath.fromEntityType(type))))
                                                            }
                                                            open
                                                            autoFocus
                                                        />
                                                    </CardInset>
                                            }
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroupItem>)
                    :
                        <ViewGroupItem>
                            <Menu>
                                {
                                    creatableAddPaths.map(
                                        path =>
                                            <Item
                                                key={path.fullPath.id}
                                                name={path.relativePath.path.entityType.getName()}
                                                onClick={() => undefined}
                                            />)
                                }
                            </Menu>
                        </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default EntityConstructorPathSelector;
