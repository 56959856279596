import reloadWindow from '../../@Component/Generic/Window/reloadWindow';
import { consoleLog } from '../../@Future/Util/Logging/consoleLog';

export function catchImport(reason: any)
{
    consoleLog('error loading chunk: reloading window', reason);

    reloadWindow(true);

    return Promise.resolve(undefined);
}
