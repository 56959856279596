import React from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useWorkflowStates from '../Api/useWorkflowStates';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../Type/Api/useTypes';
import SalesOpportunity from './Activity/SalesOpportunity/SalesOpportunity';
import CloseButton from './StateButton/StateButton';
import Subscription from './Activity/Subscription/Subscription';
import Project from './Activity/Project/Project';
import useRelatedEntity from '../../../../../@Api/Entity/Hooks/useRelatedEntity';
import getPipelineRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPipelineRelationshipDefinition';

export interface CloseButtonsProps
{
    entity: Entity;
}

const CloseButtons: React.FC<CloseButtonsProps> =
    props =>
    {
        const types = useTypes();
        const pipeline = useRelatedEntity(props.entity, getPipelineRelationshipDefinition(props.entity.entityType), false);
        const closedWorkflowStates = useWorkflowStates(props.entity.entityType, true, pipeline);

        if (props.entity.bespoke.isInClosedState)
        {
            if (props.entity.entityType.isA(types.Activity.SalesOpportunity.Type))
            {
                return <SalesOpportunity
                    entity={props.entity}
                />;
            }
            else
            {
                return null;
            }
        }
        else if (props.entity.entityType.isA(types.Activity.Subscription.Type))
        {
            return <Subscription
                entity={props.entity}
            />;
        }
        else
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
            >
                {
                    props.entity.entityType.isA(types.Activity.Project.Type) &&
                        <ViewGroupItem>
                            <Project
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                }
                {
                    closedWorkflowStates &&
                    closedWorkflowStates.map(
                        state =>
                            <ViewGroupItem
                                key={state.uuid}
                            >
                                <CloseButton
                                    entity={props.entity}
                                    state={state}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>;
        }
    };

export default observer(CloseButtons);
