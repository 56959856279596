import { type } from '../../../@Util/Serialization/Serialization';
import { EntityRelationshipMutation } from './EntityRelationshipMutation';
import { Entity } from './Entity';
import { EntityRelationship } from './EntityRelationship';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';

@type('EntityRelationshipDeletionMutation')
export class EntityRelationshipDeletionMutation extends EntityRelationshipMutation
{
    // ------------------- Persistent Properties --------------------

    isRelationshipDeleted: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                entityRelationship: EntityRelationship,
                isParentRelationship: boolean,
                entityRelationshipDefinition: EntityRelationshipDefinition,
                fromRelatedEntity: Entity,
                toRelatedEntity: Entity,
                isRelationshipDeleted: boolean)
    {
        super(
            entity,
            entityRelationship,
            isParentRelationship,
            entityRelationshipDefinition,
            fromRelatedEntity,
            toRelatedEntity);

        this.isRelationshipDeleted = isRelationshipDeleted;
    }

// ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
