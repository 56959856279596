import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import CompositeLayoutItem from '../../../../../../../@Api/Layout/Type/CompositeLayoutItem';
import { CompositeLayoutEditorProps } from '../CompositeLayoutEditor';
import CompositeLayoutItemControls from './Controls/CompositeLayoutItemControls';
import Layout from '../../../../../../../@Api/Layout/Layout';
import LayoutControlsRegion from '../../../../Editor/Controls/Region/LayoutControlsRegion';
import ChildLayoutEditor from '../../../../Editor/Child/ChildLayoutEditor';
import ParentLayoutsContext from '../../../../Editor/Child/ParentLayoutsContext';
import { runInAction } from 'mobx';

export interface CompositeLayoutItemEditorProps extends CompositeLayoutEditorProps
{
    item: CompositeLayoutItem;
    idx: number;
    onAdd: (layout: Layout, afterItem: CompositeLayoutItem) => void;
    draggableType: string;
}

const CompositeLayoutItemEditor: React.FC<CompositeLayoutItemEditorProps> =
    props =>
    {
        const addLayout =
            useCallback(
                (layout: Layout) =>
                    props.onAdd(
                        layout,
                        props.item),
                [
                    props.onAdd,
                    props.item
                ]
            );
        const parentLayouts = useContext(ParentLayoutsContext);
        const newParentLayouts =
            useMemo(
                () => [
                    props.layout,
                    ...parentLayouts
                ],
                [
                    parentLayouts,
                    props.layout
                ]
            );
        const setLayout =
            useCallback(
                (layout: Layout) =>
                {

                    runInAction(
                        () =>
                            props.item.layout = layout
                    )
                },
                [
                    props.item
                ]
            );

        return <LayoutControlsRegion
            {...props}
            layout={props.item.layout}
            editor={
                <ChildLayoutEditor
                    {...props}
                    disableControls
                    childLayout={props.item.layout}
                    onChange={setLayout}
                />
            }
            controls={
                <ParentLayoutsContext.Provider
                    value={newParentLayouts}
                >
                    <CompositeLayoutItemControls
                        {...props}
                        onConstruct={addLayout}
                        onChange={setLayout}
                    />
                </ParentLayoutsContext.Provider>
            }
            onConstruct={addLayout}
            constructorMode={props.layout.orientation}
            onChange={setLayout}
            draggableId={props.item.id}
            draggableIdx={props.idx}
            draggableType={props.draggableType}
            horizontal={props.layout.orientation === 'Horizontal'}
            last={props.layout.items.length - 1 === props.idx}
        />;
    };

export default observer(CompositeLayoutItemEditor);
