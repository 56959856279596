import React from 'react';
import AsyncComponent from '../AsyncComponent/AsyncComponent';
import { catchImport } from '../Import/catchImport';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const reactPdfLoader =
    () =>
        import('react-pdf/dist/esm/entry.webpack')
            .catch(catchImport);

const ReactPdfDocument: React.FC<any> =
    props =>
    {
        return <AsyncComponent
            _loader={reactPdfLoader}
            _loadKey="Document"
            {...props}
            options={{
                // Fixes garbled text in PDF on some computers (https://stackoverflow.com/a/73591613)
                disableFontFace: true,
                // Fixes fonts not showing in combination with disableFontFace
                standardFontDataUrl: `pdfjs-dist/standard_fonts/`,
                cMapUrl: 'pdfjs-dist/cmaps/',
                cMapPacked: true,
                ...props.options,
            }}
        />;
    };

const ReactPdfPage: React.FC<any> =
    props =>
    {
        return <AsyncComponent
            _loader={reactPdfLoader}
            _loadKey="Page"
            {...props}
        />;
    };

const ReactPdf = {
    Document: ReactPdfDocument,
    Page: ReactPdfPage,
};

export default ReactPdf;
