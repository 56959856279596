import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import getTypes from '../../../Type/Api/getTypes';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';

export default function getFilteredEntityType(predicate: Predicate)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const types = getTypes();

    return predicate.getTrueStatements(
        new FunctionContext(
            new ParameterDictionary([]),
            new ParameterAssignment()
        )
    )
        .filter(
            predicate =>
                predicate instanceof ComparisonPredicate
                && predicate.lhs instanceof ValueFromEntityComputation
                && predicate.lhs.fieldPath
                && !predicate.lhs.fieldPath.path.lastJoinNode // no join may be contained, because this should say something about the root
                && predicate.lhs.fieldPath.field === types.Entity.Field.Type
                && predicate.comparator === Comparator.Equals
                && predicate.rhs instanceof PrimitiveValue)
        .map(
            predicate =>
            {
                const constant = ((predicate as ComparisonPredicate).rhs as PrimitiveValue).value;

                if (constant.isEmpty)
                {
                    return [];
                }
                else if (constant.value.ids)
                {
                    return ((constant.value as any).ids as any[]).map(
                        (id: any) =>
                            entityTypeStore.getTypeById(id));
                }
                else if (constant.value instanceof EntityType)
                {
                    return [ constant.value ];
                }
                else
                {
                    return [];
                }
            })
        .reduce((a, b) => a.concat(b), [])
        // Find entity type with lowest depth
        .sort(
            (a, b) =>
                a.depth > b.depth
                    ?
                    1
                    :
                    a.depth === b.depth
                        ?
                        0
                        :
                        -1)
        .find(() => true);
}
