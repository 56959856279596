import Value from './Value';
import ValueType from './Type/ValueType';
import CollectionValue from './CollectionValue';

export default class PagedCollectionValue<T> extends CollectionValue<T>
{
    // ------------------------- Properties -------------------------

    hasMore: boolean;
    loadMore: () => Promise<CollectionValue<T>>;

    // ------------------------ Constructor -------------------------

    constructor(
        value: Value<T, ValueType<any>>[],
        elementType: ValueType<any>,
        hasMore: boolean,
        loadMore: () => Promise<CollectionValue<T>>
    )
    {
        super(
            value,
            elementType
        );

        this.hasMore = hasMore;
        this.loadMore = loadMore;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
