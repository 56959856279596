import React from 'react';
import { RecurrencePatternEditorProps } from '../../RecurrencePatternEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { RelativeYearlyRecurrencePattern, YearlyRecurrencePattern } from '../../../Model/RecurrencePattern';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { Radio } from '@material-ui/core';
import { YearlyRecurrencePatternEditor } from './Yearly/YearlyRecurrencePatternEditor';
import { RelativeYearlyRecurrencePatternEditor } from './RelativeYearly/RelativeYearlyRecurrencePatternEditor';

export const YearlyOrRelativeYearlyRecurrencePatternEditor: React.FC<RecurrencePatternEditorProps<YearlyRecurrencePattern | RelativeYearlyRecurrencePattern>> =
    ({
        value,
        onChange,
     }) =>
    {
        const defaultYearlyPattern: YearlyRecurrencePattern = {
            type: 'Yearly',
            startDate: value.startDate,
            end: value.end,
            modifiedOccurrences: value.modifiedOccurrences,
            deletedOccurrences: value.deletedOccurrences,
            interval: 1,
            month: 'January',
            dayOfMonth: 1,
        };
        const defaultRelativeYearlyPattern: RelativeYearlyRecurrencePattern = {
            type: 'RelativeYearly',
            startDate: value.startDate,
            end: value.end,
            modifiedOccurrences: value.modifiedOccurrences,
            deletedOccurrences: value.deletedOccurrences,
            interval: 1,
            month: 'January',
            dayOfWeek: 'Monday',
            dayOfWeekIndex: 1,
        };

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <Input
                    label={
                        <YearlyRecurrencePatternEditor
                            value={value.type === 'Yearly' ? value : defaultYearlyPattern}
                            onChange={onChange}
                            disabled={value.type !== 'Yearly'}
                        />
                    }
                    labelPosition="right"
                >
                    <Radio
                        checked={value.type === 'Yearly'}
                        onClick={
                            () =>
                                onChange(defaultYearlyPattern)
                        }
                    />
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    label={
                        <RelativeYearlyRecurrencePatternEditor
                            value={value.type === 'RelativeYearly' ? value : defaultRelativeYearlyPattern}
                            onChange={onChange}
                            disabled={value.type !== 'RelativeYearly'}
                        />
                    }
                    labelPosition="right"
                >
                    <Radio
                        checked={value.type === 'RelativeYearly'}
                        onClick={
                            () =>
                                onChange(defaultRelativeYearlyPattern)
                        }
                    />
                </Input>
            </ViewGroupItem>
        </ViewGroup>;
    };
