import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Type/Api/getTypes';

export function getAddressSortOrderId(address: Entity| undefined): number
{
    const types = getTypes();

    if (!address)
    {
        return 100
    }
    else if (address.hasRelationshipsByDefinition(true, types.Relation.Organization.RelationshipDefinition.VisitingAddress))
    {
        return 0
    }
    else if (address.hasRelationshipsByDefinition(true, types.Relation.Organization.RelationshipDefinition.InvoiceAddress))
    {
        return 1
    }
    else if (address.hasRelationshipsByDefinition(true, types.Relation.Organization.RelationshipDefinition.PostalAddress))
    {
        return 2
    }
    else if (address.hasRelationshipsByDefinition(true, types.Relation.Person.RelationshipDefinition.Address))
    {
        return 3
    }
    return 4;
    
}
