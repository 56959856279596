import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import ComputationLayout from '../../../../../@Api/Layout/Type/ComputationLayout';
import HtmlFromLayoutComputation from '../../../../../@Api/Automation/Function/Computation/HtmlFromLayoutComputation';
import { AsyncComputationLayoutViewer } from './AsyncComputationLayoutViewer';
import { SyncComputationLayoutViewer } from './SyncComputationLayoutViewer';

export interface ComputationLayoutViewerProps extends LayoutViewerProps<ComputationLayout>
{

}

const ComputationLayoutViewer: React.FC<ComputationLayoutViewerProps> =
    props =>
    {
        const isAsync =
            useComputed(
                () =>
                    props.layout.computation.isAsync(),
                [
                    props.layout,
                ]
            );

        if (props.layout.computation instanceof HtmlFromLayoutComputation)
        {
            return <LayoutViewer
                parameterDictionary={props.parameterDictionary}
                parameterAssignment={props.parameterAssignment}
                layout={props.layout.computation.layout}
            />;
        }
        else
        {
            if (isAsync)
            {
                return <AsyncComputationLayoutViewer
                    {...props}
                />;
            }
            else
            {
                return <SyncComputationLayoutViewer
                    {...props}
                />;
            }
        }
    };

export default observer(ComputationLayoutViewer);
