import * as React from 'react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { SearchBarStore } from './SearchBarStore';
import { observer } from 'mobx-react';
import { ClickAwayListener, Input } from '@material-ui/core';
import Button from '../Button/Button';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme, { backgroundColor, defaultShadow, textColor } from '../../../@Resource/Theme/Theme';
import { View } from '../ViewStack/View';
import { Loader } from '../Loader/Loader';
import IconButton from '../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { classNames } from '../../../@Future/Util/Class/classNames';
import styles from './SearchBar.module.scss';
import LocalizedText from '../../Domain/Localization/LocalizedText/LocalizedText';

@observer
export class SearchBar extends BaseComponent<SearchBarStore>
{
    renderComponent(store: SearchBarStore)
    {
        return <ClickAwayListener
            onClickAway={store.clickAway}
        >
            <div
                style={{
                    display: 'flex',
                    flex: '1 1 auto',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        flex: '1 1 auto'
                    }}
                >
                    {
                        store.isExpanded &&
                            <div
                                style={{
                                    flex: '1 1 auto',
                                    display: 'flex',
                                    backgroundColor:
                                            store.isLightColor
                                                ?
                                                    'rgba(0,0,0,0.04)'
                                                :
                                                    '#ffffff33',
                                    borderRadius:
                                            store.isPanelVisible
                                                ?
                                                    '5px 5px 0px 0px'
                                                :
                                                    5,
                                    margin: 8,
                                    position: 'relative',
                                    height: 34
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: backgroundColor,
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        zIndex: 15,
                                        borderRadius: '0px 0px 4px 4px',
                                        height:
                                            store.isPanelVisible
                                                ?
                                                    undefined
                                                :
                                                    0,
                                        transition: 'height 0.2s',
                                        top: '100%',
                                        boxShadow: defaultShadow,
                                        color: textColor
                                    }}
                                >
                                    {
                                        store.isPanelVisible &&
                                            <MuiThemeProvider
                                                theme={theme}
                                            >
                                                <View
                                                    viewComponent={store.panelContent}
                                                />
                                            </MuiThemeProvider>
                                    }
                                </div>
                                <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
                                    <Input
                                        classes={{
                                            input: classNames(styles.input, store.isLightColor && styles.light)
                                        }}
                                        placeholder={store.placeholderText}
                                        style={{ fontSize: 15 }}
                                        onFocus={store.activate}
                                        value={store.query}
                                        onChange={store.updateQuery}
                                        autoFocus={store.isAutoFocus}
                                        fullWidth
                                        disableUnderline
                                        inputProps={{
                                            style:
                                            {
                                                padding: 0,
                                                marginBottom: 0,
                                                color:
                                                    store.isLightColor
                                                        ?
                                                            undefined
                                                        :
                                                            'white',
                                            }
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        position: 'relative',
                                        width: 34,
                                        height: 34,
                                        color:
                                            store.isLightColor
                                                ?
                                                    undefined
                                                :
                                                    '#ffffff33',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {
                                        store.isLoadingSearch
                                            ?
                                                <Loader />
                                            :
                                                (store.hasQuery || store.isExpandable) &&
                                                    <IconButton
                                                        icon="close"
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Generic.Clear"
                                                                value="Wissen"
                                                            />
                                                        }
                                                        onClick={store.clear}
                                                        color={store.isLightColor ? undefined : '#ffffff'}
                                                        size={24}
                                                    />
                                    }
                                </div>
                            </div>
                    }
                </div>
                <Button store={store.expandButtonStore} />
            </div>
        </ClickAwayListener>;
    }
}
