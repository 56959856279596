import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Link, { LinkClasses } from '../Link/Link';

export interface LinkListProps
{
    entities: Entity[];
    classes?: LinkClasses;
}

const LinkList: React.FC<LinkListProps> =
    props =>
    {
        return <>
            {
                props.entities.map(
                    (entity, idx) =>
                        <React.Fragment
                            key={entity.uuid}
                        >
                            {idx > 0 ? ', ' : ''}
                            <Link
                                entity={entity}
                                classes={props.classes}
                            />
                        </React.Fragment>)
            }
        </>;
    };

export default observer(LinkList);
