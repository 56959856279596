import { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import deleteEntity from '../../../../../../@Api/Entity/deleteEntity';

export default function useDeleteNotification()
{
    return useCallback(
        (notification: Entity) =>
        {
            return deleteEntity(notification);
        },
        []);
}
