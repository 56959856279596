import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActionEditorProps } from '../../ActionEditor';
import DynamicAction from '../../../../../../../../../../@Api/Automation/Function/Action/DynamicAction';
import DynamicFunctionInvocationEditor from '../../../Dynamic/DynamicFunctionInvocationEditor';

export interface DynamicActionEditorProps extends ActionEditorProps<DynamicAction>
{

}

const DynamicActionEditor: React.FC<DynamicActionEditorProps> =
    props =>
    {
        return <DynamicFunctionInvocationEditor
            context={props.context}
            invocation={props.action.invocation}
        />;
    };

export default observer(DynamicActionEditor);
