import React from 'react';
import { observer } from 'mobx-react-lite';
import Relationship from './Relationship/Relationship';
import { ActivityEmail } from './Activity/Email/ActivityEmail';
import useTypes from '../../Type/Api/useTypes';
import Default from './Default';
import { ConstructorProps } from '../Constructor';
import ActivityTask from './Activity/Task/ActivityTask';
import Relation from './Relation/Relation';
import Attachment from './Attachment/Attachment';
import Note from './Note/Note';
import ActivityAppointment from './Activity/Appointment/ActivityAppointment';
import Address from './Address/Address';
import hasPack from '../../../../../@Api/Pack/hasPack';
import { Pack } from '../../../../../@Api/Pack/Pack';
import PortalUser from './PortalUser/PortalUser';
import CalendarItem from './CalendarItem/CalendarItem';
import CourseTemplate from './CourseTemplate/CourseTemplate';
import ActivityCourse from './Activity/Course/ActivityCourse';
import { ActivityDigitalSigningConstructor } from './Activity/DigitalSigning/ActivityDigitalSigningConstructor';
import PriceList from './PriceList/PriceList';
import Partner from './Relationship/Partner/Partner';

export interface ContentProps extends ConstructorProps
{

}

const Content: React.FC<ContentProps> =
    props =>
    {
        const types = useTypes();

        if (props.entity.entityType.isA(types.Note.Type))
        {
            return <Note
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Attachment.Type))
        {
            return <Attachment
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Relationship.Organization.Partner.Type))
        {
            return <Partner
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Relationship.Type))
        {
            return <Relationship
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Relation.Type))
        {
            return <Relation
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Address.Type)
            && hasPack(Pack.PostalCode))
        {
            return <Address
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Appointment.Type))
        {
            return <ActivityAppointment
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Task.Type))
        {
            return <ActivityTask
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Email.Type))
        {
            return <ActivityEmail
                {...props}
                onSent={props.onClose}
                onSave={props.onClose}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.DigitalSigning.Type))
        {
            return <ActivityDigitalSigningConstructor
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.PortalUser.Type))
        {
            return <PortalUser
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.CalendarItem.Type))
        {
            return <CalendarItem
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.CourseTemplate.Type))
        {
            return <CourseTemplate
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.Activity.Course.Type))
        {
            return <ActivityCourse
                {...props}
            />;
        }
        else if (props.entity.entityType.isA(types.PriceList.Type))
        {
            return <PriceList
                {...props}
            />;
        }
        else
        {
            return <Default
                {...props}
            />;
        }
    };

export default observer(Content);
