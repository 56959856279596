import Column from './Column';
import Ordering from '../Ordering';
import { computed, observable } from 'mobx';
import { mapBy } from '../../../../../../@Util/MapUtils/mapBy';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import { Grouping } from '../Grouping';

export default class List
{
    // ------------------------- Properties -------------------------

    @observable.shallow columns: Column[];
    @observable.shallow ordering: Ordering[];
    @observable.shallow groupings: Grouping[];

    // ------------------------ Constructor -------------------------

    constructor(
        columns: Column[],
        ordering: Ordering[],
        groupings: Grouping[] = []
    )
    {
        this.columns = columns;
        this.ordering = ordering;
        this.groupings = groupings;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get visibleColumns()
    {
        return this.columns
            .filter(
                column =>
                    !column.isHidden
            );
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isValid(): boolean
    {
        return this.columns.every(
            column =>
                column.isValid
        );
    }

    static async fromDescriptor(
        descriptor: any,
        parameters: ParameterDictionary
    )
    {
        const columns =
            (
                await Promise.all(
                    (descriptor.columns as any[])
                        .map(
                            column =>
                                Column.fromDescriptor(
                                    column,
                                    parameters
                                )
                        )
                )
            ).filter(c => c.isValid);
        const columnById =
            mapBy(
                columns,
                column =>
                    column.id
            );

        return new List(
            columns,
            descriptor.ordering
                .map(
                    ordering =>
                        Ordering.fromDescriptor(
                            ordering,
                            columnById
                        )
                )
                .filter(
                    ordering =>
                        ordering.isValid
                ),
            (descriptor.groupings ?? [])
                .map(
                    grouping =>
                        Grouping.fromDescriptor(
                            grouping,
                            columnById
                        )
                )
                .filter(
                    grouping =>
                        grouping.isValid
                )
        );
    }

    toDescriptor(includeColumnFilters: boolean)
    {
        return {
            columns:
                this.columns
                    .map(
                        column =>
                            column.toDescriptor(includeColumnFilters)
                    ),
            ordering:
                this.ordering.map(
                    ordering =>
                        ordering.toDescriptor()
                ),
            groupings:
                this.groupings.map(
                    grouping =>
                    grouping.toDescriptor()
                ),
        };
    }

    // ----------------------- Private logic ------------------------
}
