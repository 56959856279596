import { RecurrencePatternEnd } from '../../Model/End/RecurrencePatternEnd';
import { Options } from 'rrule';
import { toRRuleDate } from './toRRuleDate';

export function getEndAsRRule(
    end: RecurrencePatternEnd
): Partial<Options>
{
    switch (end.type)
    {
        case 'Numbered':
            return {
                count: end.numberOfOccurrences,
            };

        case 'Date':
            return {
                until: 
                    toRRuleDate(
                        new Date(
                           Date.parse(end.date)
                        )
                    ),
            };

        case 'No':
            return {};
    }
}
