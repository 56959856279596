import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../../Editor/LayoutEditor';
import TableLayout from '../../../../../../../@Api/Layout/Type/Table/TableLayout';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import TableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import { runInAction } from 'mobx';
import TableLayoutDimensionSectionConstructor from '../DimensionSection/TableLayoutDimensionSectionConstructor';
import { TableRowSectionEditor } from './Row/TableRowSectionEditor';
import ChildTable from '../../../../../../../@Api/Layout/Type/Table/Model/ChildTable';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export interface TableLayoutEditorProps extends LayoutEditorProps<TableLayout>
{
    childTable?: ChildTable;
}

const TableLayoutEditor: React.FC<TableLayoutEditorProps> =
    props =>
    {
        const {
            layout,
            childTable,
            parameterDictionary,
        } = props;
        const childParameterDictionary =
            useMemo(
                () =>
                    childTable === undefined
                        ? parameterDictionary
                        : ParameterDictionary.union(
                            parameterDictionary,
                            new ParameterDictionary(
                                childTable.parentSection.getParameters()
                            )
                        ),
                [
                    parameterDictionary,
                    childTable,
                ]
            );
        const addRowSection =
            useCallback(
                (section: TableDimensionSection) =>
                    runInAction(
                        () =>
                        {
                            if (childTable)
                            {
                                childTable.sections.push(section);
                            }
                            else
                            {
                                layout.rowSections.push(section);
                            }
                        }),
                [
                    layout
                ]);

        return <>
            {
                (childTable?.sections ?? layout.rowSections).map(
                    (rowSection, rowIdx) =>
                        <TableRowSectionEditor
                            key={rowSection.id}
                            {...props}
                            parameterDictionary={childParameterDictionary}
                            rowSection={rowSection}
                            rowIdx={rowIdx}
                        />
                    )
            }
            <TableRow>
                <TableCell
                    colSpan={layout.columnSections.length + 1}
                >
                    <TableLayoutDimensionSectionConstructor
                        onConstruct={addRowSection}
                    />
                </TableCell>
            </TableRow>
        </>;
    };

export default observer(TableLayoutEditor);
