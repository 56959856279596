import React from 'react';
import { observer } from 'mobx-react-lite';
import { RelationshipConstructorProps } from '../RelationshipConstructor';
import Constructor from '../../../Constructor/Constructor';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import styles from './Expansion.module.scss';
import useOptionalRelatedEntity from '../../../../Layout/Type/OptionalRelatedEntity/Api/useOptionalRelatedEntity';

export interface ExpansionProps extends RelationshipConstructorProps
{

}

const Expansion: React.FC<ExpansionProps> =
    props =>
    {
        // const related =
        //     useComputed(
        //         () =>
        //             props.entity.getRelatedEntityByDefinition(props.isParent, props.relationshipDefinition),
        //         [
        //             props.entity
        //         ]);

        const relatedEntity =
            useOptionalRelatedEntity(
                props.entity,
                props.relationshipDefinition,
                props.isParent,
                props.commitContext);

        return <div
            className={classNames(styles.root, props.inset && styles.inset)}
        >
            <Constructor
                entity={relatedEntity}
                autoCommit={props.autoCommit}
                underline={props.underline}
                disableActions
                commitContext={props.commitContext}
                disabled={props.disabled}
            />
        </div>;
    };

export default observer(Expansion);
