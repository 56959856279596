import Role, { Permission, Privilege, RoleType } from '../../../Model/Role';
import { EntityField } from '../../../../../../@Api/Model/Implementation/EntityField';
import Right from '../../../Model/Right';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export function setFieldPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    permission: Permission,
    field: EntityField,
    condition: Predicate,
    parameterDictionary: ParameterDictionary
)
{
    const right = role.rightByField.get(field)
        ?? new Right(parameterDictionary);

    right.setPermission(privilege, permission, condition);

    if (permission === 'Denied' && privilege === 'canRead')
    {
        const privileges: Privilege[] = [ 'canCreate', 'canUpdate', 'canDelete', 'canExport' ];

        privileges.forEach(
            privilege =>
                right
                    .setPermission(
                        privilege,
                        'Inherited'
                    )
        );
    }

    role.rightByField.set(field, right);
}