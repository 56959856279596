import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import SuccessButton from '../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import useTypes from '../../../Type/Api/useTypes';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';
import constructEntity from '../../../../../../@Api/Entity/constructEntity';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../Selection/Builder/EntityExpansionBuilder';
import Details from '../../Shared/Details/Details';

export interface EventParticipationProps
{
    entity: Entity;
}

const EventParticipation: React.FC<EventParticipationProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const createInvoice =
            useCallback(
                async () =>
                {
                    const pathToParentActivity =
                        EntityPath.fromEntity(entity)
                            .joinTo(
                                types.Activity.Event.RelationshipDefinition.Participations,
                                true);

                    const pathToRelationship =
                        pathToParentActivity
                            .joinTo(
                                types.Relationship.RelationshipDefinition.Activities,
                                true);

                    const pathToContact =
                        pathToParentActivity
                            .joinTo(
                                types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                true);

                    await new EntityExpansionBuilder(
                        entity.entityType,
                        [
                            entity
                        ],
                        [
                            pathToParentActivity,
                            pathToRelationship,
                            pathToContact
                        ])
                        .expand();

                    const invoice =
                        createTransactionalModel(
                            new Entity(types.Activity.Invoice.Type)
                                .initialize());

                    pathToRelationship.traverseEntity(entity)
                        .forEach(
                            relationship =>
                                invoice.updateRelationship(
                                    true,
                                    types.Relationship.RelationshipDefinition.Activities,
                                    createTransactionalModel(relationship)));

                    pathToContact.traverseEntity(entity)
                        .forEach(
                            contact =>
                                invoice.updateRelationship(
                                    true,
                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                    createTransactionalModel(contact)));

                    pathToParentActivity.traverseEntity(entity)
                        .forEach(
                            parentActivity =>
                                invoice.updateRelationship(
                                    true,
                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                    createTransactionalModel(parentActivity)));

                    invoice.updateRelationship(
                        true,
                        types.EventParticipation.RelationshipDefinition.Activities,
                        createTransactionalModel(entity)
                    );

                    return constructEntity(
                        invoice.entityType,
                        undefined,
                        invoice);
                },
                [
                    types,
                    entity
                ]);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        {
                                            types.Activity.Invoice.Type &&
                                                <ViewGroupItem>
                                                    <SuccessButton
                                                        label={`+ ${types.Activity.Invoice.Type.getName()}`}
                                                        onClick={createInvoice}
                                                    />
                                                </ViewGroupItem>
                                        }
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Details
                                entity={entity}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(EventParticipation);
