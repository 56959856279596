import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { ButtonProps, ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { getOrCompute, PropType } from '../../../../../@Framework/Store/BaseStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { FeedbackStore } from '../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { computed } from 'mobx';
import { DialogStore } from '../../../../Generic/Dialog/Type/DialogStore';
import { ReactViewComponent } from '../../../../Generic/ViewStack/Model/ReactViewComponent';
import DuplicateImportDialog from '../../Bespoke/Import/DuplicateImportDialog';

export interface EntityImportDuplicateButtonProps extends ButtonProps
{
    entity: PropType<EntityImportDuplicateButtonStore, EntityImportDuplicateButtonProps, Entity>;
}

const defaultProps: Partial<EntityImportDuplicateButtonProps> =
{
};

export class EntityImportDuplicateButtonStore extends ButtonStore<EntityImportDuplicateButtonProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityImportDuplicateButtonProps)
    {
        super({
            label:
                () =>
                    localizeText(
                        'Generic.Duplicate',
                        'Dupliceren'
                    ),
            isVisible:
                () => this.entity.entityType.isA(
                    this.entityTypeStore.bespoke.types.Import.Type),
            onClick:
                () =>
                {
                    const closeDialog =
                        this.feedbackStore.openDialog({
                            store:
                                new DialogStore({
                                    viewComponent:
                                        new ReactViewComponent(
                                            DuplicateImportDialog,
                                            {
                                                importEntity: this.entity,
                                                closeCallback:
                                                    () =>
                                                        closeDialog()
                                            })
                                })
                        });
                },
            ...props,
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
