import { EntityPathNode } from '../EntityPathNode';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityPath } from '../EntityPath';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../../../../@Api/Model/Implementation/EntityRelationship';
import { createTransactionalModel, getAdministration, getModel, isTransactionalModel } from '../../../../../../@Util/TransactionalModelV2';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { constructEntityOfType } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class EntityPathJoinNode extends EntityPathNode
{
    // ------------------------- Properties -------------------------

    isParent: boolean;
    relationshipDefinition: EntityRelationshipDefinition;
    isRecursive: boolean;

    // ------------------------ Constructor -------------------------

    constructor(isParent: boolean,
                relationshipDefinition: EntityRelationshipDefinition,
                isRecursive: boolean = false)
    {
        super();

        this.isParent = isParent;
        this.relationshipDefinition = relationshipDefinition;
        this.isRecursive = isRecursive;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static construct(descriptor: any,
                     baseEntityType: EntityType,
                     entityTypeStore: EntityTypeStore): EntityPathNode
    {
        if (descriptor.parentRelationshipDefinitionId)
        {
            const relationshipDefinition = entityTypeStore.getRelationshipDefinitionById(descriptor.parentRelationshipDefinitionId);

            if (relationshipDefinition)
            {
                return new EntityPathJoinNode(
                    true,
                    relationshipDefinition,
                    descriptor.isRecursive
                );
            }
            else
            {
                throw new Error(`Parent relationship definition with ID: ${descriptor.parentRelationshipDefinitionId} not found`);
            }
        }
        else
        {
            const relationshipDefinition = entityTypeStore.getRelationshipDefinitionById(descriptor.childRelationshipDefinitionId);

            if (relationshipDefinition)
            {
                return new EntityPathJoinNode(
                    false,
                    relationshipDefinition,
                    descriptor.isRecursive
                );
            }
            else
            {
                throw new Error(`Child relationship definition with ID: ${descriptor.childRelationshipDefinitionId} not found`);
            }
        }
    }

    id(): string
    {
        return `${this.isParent ? 'parentRelationshipDefinitionId' : 'childRelationshipDefinitionId'}.${this.relationshipDefinition.id}`;
    }

    code(): string
    {
        return `${this.isParent ? 'parent' : 'child'}->${this.relationshipDefinition.code}`;
    }

    getName(entityTypeStore: EntityTypeStore): string
    {
        return this.relationshipDefinition.getName(
            this.isParent,
            this.relationshipDefinition.getEntityType(this.isParent),
            entityTypeStore);
    }

    getEntityType(baseEntityType: EntityType): EntityType
    {
        return this.relationshipDefinition.getEntityType(this.isParent);
    }

    joinNode(baseEntityPath: EntityPath): EntityPath
    {
        if (baseEntityPath.nodes.length > 0
            && baseEntityPath.nodes[baseEntityPath.nodes.length - 1] instanceof EntityPathJoinNode)
        {
            let lastJoinNode = baseEntityPath.nodes[baseEntityPath.nodes.length - 1] as EntityPathJoinNode;

            if (lastJoinNode.isParent !== this.isParent
                && lastJoinNode.relationshipDefinition === this.relationshipDefinition)
            {
                return new EntityPath(baseEntityPath.nodes.slice(0, baseEntityPath.nodes.length - 1));
            }
        }

        const baseEntityType = baseEntityPath.entityType;
        const fromType = this.relationshipDefinition.getEntityType(!this.isParent);

        if (baseEntityType.isA(fromType) || fromType.isA(baseEntityType))
        {
            return baseEntityPath.castTo(fromType).addNode(this);
        }
        else
        {
            return baseEntityPath.addNode(this);
        }
    }

    inverseNode(baseEntityType: EntityType): EntityPathNode
    {
        return new EntityPathJoinNode(
            !this.isParent,
            this.relationshipDefinition);
    }

    isVirtual()
    {
        return false;
    }

    traverseEntity(entity: Entity,
                   commitContext?: CommitContext,
                   onRelationship?: (relationship: EntityRelationship, isParent: boolean) => void): Entity[]
    {
        return entity.getRelationshipsByDefinition(
            this.isParent,
            this.relationshipDefinition,
            commitContext)
            .map(relationship =>
            {
                if (onRelationship)
                {
                    onRelationship(relationship, this.isParent);
                }

                return relationship.getEntity(this.isParent);
            });
    }

    constructEntity(baseEntity: Entity,
                    forceCreation: boolean,
                    addRelationshipToBase: boolean,
                    entityType: EntityType,
                    commitContext?: CommitContext): Entity
    {
        const relationship =
            this.relationshipDefinition.isPlural(this.isParent)
                ?
                    undefined
                :
                    baseEntity.getRelationshipByDefinition(this.isParent, this.relationshipDefinition, commitContext);

        if (relationship == null || forceCreation)
        {
            if (commitContext)
            {
                const relatedEntity = constructEntityOfType(entityType, commitContext);

                updateRelationship(
                    relatedEntity,
                    !this.isParent,
                    this.relationshipDefinition,
                    baseEntity,
                    commitContext
                );

                return relatedEntity;
            }
            else
            {
                // Initialize and make the new entity transactional
                // This is because if the base entity is transactional, subsequent operations to this entity
                // will not be performed on the same model as it referenced to by the base entity
                let relatedEntity =
                    new Entity(entityType)
                        .initialize();

                // console.trace('constructed related entity', relatedEntity, relatedEntity.entityType.code, relatedEntityType.code, relatedEntity.uuid, parameterValues, baseEntity);

                // let relationship =
                //     new EntityRelationship(
                //         undefined,
                //         this.isParent ? getModel(relatedEntity) : getModel(baseEntity),
                //         this.isParent ? getModel(baseEntity) : getModel(relatedEntity),
                //         this.relationshipDefinition);

                if (isTransactionalModel(baseEntity))
                {
                    const context = getAdministration(baseEntity).context;

                    relatedEntity =
                        createTransactionalModel(
                            relatedEntity,
                            undefined,
                            context);

                    context.seen(getModel(relatedEntity), relatedEntity);

                    // relatedEntity.addRelationship(
                    //     relationship,
                    //     !this.isParent,
                    //     !addRelationshipToBase);
                }
                else
                {
                    // relatedEntity.addRelationship(
                    //     relationship,
                    //     !this.isParent,
                    //     !addRelationshipToBase);
                }

                relatedEntity.updateRelationship(
                    !this.isParent,
                    this.relationshipDefinition,
                    baseEntity,
                    !addRelationshipToBase);

                return relatedEntity;
            }
        }
        else
        {
            return relationship.getEntity(this.isParent);
        }
    }

    descriptor(): any
    {
        if (this.isParent)
        {
            return {
                parentRelationshipDefinitionId: this.relationshipDefinition.id,
                isRecursive:
                    this.isRecursive === true
                        ?
                            true
                        :
                            undefined
            };
        }
        else
        {
            return {
                childRelationshipDefinitionId: this.relationshipDefinition.id,
                isRecursive:
                    this.isRecursive === true
                        ?
                            true
                        :
                            undefined
            };
        }
    }

    // ----------------------- Private logic ------------------------
}
