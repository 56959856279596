import React, { useState } from 'react';
import Dataset from '../Entity/Dataset/Dataset';
import Card from '../../../@Future/Component/Generic/Card/Card';
import useAsyncResult from '../../../@Util/Async/useAsyncResult';
import { EntitySelectionBuilder } from '../Entity/Selection/Builder/EntitySelectionBuilder';
import { findAssistantEntityTypeOrThrow } from './Api/findAssistantEntityTypeOrThrow';
import { findAssistantPredicate } from './Api/Predicate/findAssistantPredicate';
import getViewParameters from '../Entity/View/Api/getViewParameters';
import { ViewParams } from '../Entity/View/Model/ViewParams';
import { EntityPath } from '../Entity/Path/@Model/EntityPath';
import { findAssistantAggregate } from './Api/findAssistantAggregate';
import Viewer from '../DataObject/Viewer/Viewer';
import { findAssistantDatasetByChart } from './Api/findAssistantDatasetByChart';
import { findAssistantViewByTable } from './Api/findAssistantViewByTable';
import List from '../Entity/View/List/List';
import { observer } from 'mobx-react';
import { findAssistantAggregateFieldPath } from './Api/findAssistantAggregateFieldPath';
import { AssistantFollowUpAnswerViewer } from './AssistantFollowUpAnswerViewer';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Typography } from '@material-ui/core';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { findAssistantAutomation } from './Api/findAssistantAutomation';
import ErrorBoundary from '../../Error/ErrorBoundary';
import TriggerEditor from '../Entity/Viewer/Content/Automation/Editor/Trigger/TriggerEditor';
import ActionEditor from '../Entity/Viewer/Content/Automation/Editor/Action/ActionEditor';
import FunctionContext from '../../../@Api/Automation/Function/FunctionContext';
import SaveButton from '../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { CommitBuilder } from '../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import useTypes from '../Entity/Type/Api/useTypes';
import { openEntity } from '../Entity/@Util/openEntity';
import { useLocalizer } from '../../../@Service/Localization/Api/useLocalizer';
import Icon from '../../../@Future/Component/Generic/Icon/Icon';
import LoadingDots from './Resources/loading-dots.gif';
import Link from '../../../@Future/Component/Generic/Link/Link';
import { AssistantPromptAnswerProps } from './AssistantPromptAnswer';
import { AssistantAnswer } from './Model/Answer/AssistantAnswer';

export interface AssistantPromptAnswerViewerProps extends AssistantPromptAnswerProps
{
    answer: AssistantAnswer;
    onRetry: () => void;
}

export const AssistantPromptAnswerViewer: React.FC<AssistantPromptAnswerViewerProps> =
    observer(
        ({
            prompt,
            remainingPrompts,
            onClose,
            followUpQuestionAnswers,
            hideAvatar,
            answer,
            onRetry,
         }) =>
        {
            const types = useTypes();
            const localizer = useLocalizer();
            const [
                result,
                isLoading,
            ] =
                useAsyncResult(
                    async () =>
                    {
                        if (answer.type === 'AggregateAnswer')
                        {
                            const entityType = findAssistantEntityTypeOrThrow(answer.entityType);
                            const viewParams = getViewParameters(entityType);
                            const parameter = viewParams.getParameterById(ViewParams.Entity);
                            const filter =
                                answer.filter
                                    ? findAssistantPredicate(
                                        answer.filter,
                                        viewParams,
                                        parameter
                                    )
                                    : undefined;
                            const aggregateFieldPath =
                                findAssistantAggregateFieldPath(
                                    EntityPath.fromEntityType(entityType),
                                    answer.field
                                );
                            const aggregate = findAssistantAggregate(answer.aggregate);
                            const result =
                                await EntitySelectionBuilder
                                    .builder(
                                        entityType,
                                        builder =>
                                            builder
                                                .if(
                                                    () =>
                                                        filter !== undefined,
                                                    () =>
                                                        builder.where(
                                                            cb =>
                                                                cb.filter(
                                                                    filter,
                                                                    {
                                                                        parameter,
                                                                    }
                                                                )
                                                        )
                                                )
                                                .aggregateOn(
                                                    aggregateFieldPath,
                                                    undefined,
                                                    aggregate
                                                )
                                    )
                                    .selectAggregates();

                            // playAssistantSpeech(
                            //     `${result.aggregates[0].toString()}`
                            // );

                            return <Card
                                inset
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                <Viewer
                                    dataObject={result.aggregates[0]}
                                />
                            </Card>;
                        }
                        else if (answer.type === 'ChartAnswer')
                        {
                            const dataset = findAssistantDatasetByChart(answer.chart);

                            return <div
                                style={{
                                    width: '100%',
                                    minWidth: 600,
                                }}
                            >
                                <Dataset
                                    id={prompt.id}
                                    name={dataset.entityType.getName(true)}
                                    dataset={dataset}
                                />
                            </div>;
                        }
                        else if (answer.type === 'TableAnswer')
                        {
                            const view = findAssistantViewByTable(answer.table);

                            return <Card>
                                <List
                                    view={view}
                                    disableInfiniteScroll
                                />
                            </Card>;
                        }
                        else if (answer.type === 'AutomationAnswer')
                        {
                            const automation = findAssistantAutomation(answer.automation);
                            const entityType = findAssistantEntityTypeOrThrow(answer.automation.entityType);
                            const functionContext =
                                new FunctionContext(
                                    automation.parameterDictionary
                                );

                            return <ErrorBoundary>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={16}
                                >
                                    <ViewGroupItem>
                                        <Typography
                                            variant="h6"
                                        >
                                            {answer.automation.title}
                                        </Typography>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Card>
                                            <TriggerEditor
                                                entityType={entityType}
                                                onCreate={() => {}}
                                                trigger={automation.trigger}
                                                context={functionContext}
                                            />
                                        </Card>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <ActionEditor
                                            context={functionContext}
                                            action={automation.action}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <SaveButton
                                            fullWidth
                                            label={
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={16}
                                                    alignment="center"
                                                >
                                                    <ViewGroupItem>
                                                        <Icon
                                                            icon="auto_fix_high"
                                                        />
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        Create automation
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            }
                                            onClick={
                                                () =>
                                                    new CommitBuilder()
                                                        .createEntity(
                                                            types.Automation.Type,
                                                            builder =>
                                                                builder
                                                                    .relateTo(
                                                                        true,
                                                                        types.EntityType.RelationshipDefinition.Entities,
                                                                        entityType.entity
                                                                    )
                                                                    .setObjectValue(
                                                                        types.Automation.Field.LocalizedName,
                                                                        {
                                                                            [localizer.languageCode]: answer.automation.title,
                                                                        },
                                                                    )
                                                                    .setObjectValue(
                                                                        types.Automation.Field.Specification,
                                                                        automation.toDescriptor()
                                                                    ),
                                                            'Automation'
                                                        )
                                                        .commit()
                                                        .then(
                                                            result =>
                                                                openEntity(
                                                                    result.getEntity('Automation')
                                                                )
                                                        )
                                                        .then(
                                                            () =>
                                                                onClose()
                                                        )
                                            }
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ErrorBoundary>;
                        }
                        else if (answer.type === 'FollowUpQuestion')
                        {
                            return <Card
                                inset
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                {answer.question}
                            </Card>;
                        }
                        else
                        {
                            return null;
                        }
                    },
                    [prompt.id, prompt.prompt, onClose, followUpQuestionAnswers, answer]
                );

            if (isLoading)
            {
                return <Card
                    inset
                    style={{
                        width: 'fit-content',
                    }}
                >
                    <img
                        src={LoadingDots}
                        width={25}
                    />
                </Card>;
            }
            else
            {
                if (result === undefined)
                {
                    return <Card
                        inset
                    >
                        I was unable to get the data for you. Would you like me to <Link onClick={() => onRetry()}>retry</Link>?
                    </Card>;
                }
                else
                {
                    return result;
                }
            }
        }
    );
