import { EntityType } from '../Model/Implementation/EntityType';
import { useCallback, useMemo } from 'react';
import useTypes from '../../@Component/Domain/Entity/Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';
import { CommitBuilder } from '../Entity/Commit/Context/Builder/CommitBuilder';

export default function useMetadataBaseSetting<T>(entityType: EntityType,
                                                  code: string,
                                                  isInEditMode: boolean): [ T, (value?: T) => void ]
{
    const types = useTypes();
    const metadataValue =
        useComputed(
            () =>
                entityType.getInheritedTypes()
                    .map(
                        inheritedType =>
                            inheritedType.entity.getObjectValueByField(types.EntityType.Field.Metadata))
                    .filter(
                        value =>
                            value !== undefined)
                    .find(() => true),
            [
                entityType,
                types
            ]);

    const setMetadataValue =
        useCallback(
            (value: any) =>
                new CommitBuilder()
                    .setObjectValueInEntity(
                        entityType.entity,
                        types.EntityType.Field.Metadata,
                        value
                    )
                    .commit(),
            [
                entityType,
                types,
            ]);

    const codeWithoutMetadataPrefix =
        useMemo(
            () =>
                code.replace('Metadata.', ''),
            [
                code
            ]);

    const settingValue =
        useMemo(
            () =>
            {
                if (metadataValue && metadataValue.setting)
                {
                    return metadataValue.setting[codeWithoutMetadataPrefix];
                }
                else
                {
                    return undefined;
                }
            },
            [
                metadataValue,
                codeWithoutMetadataPrefix
            ]);

    const setSettingValue =
        useCallback(
            (settingValue?: any) =>
            {
                const newSetting: any = {
                    ...metadataValue?.setting,
                };

                if (settingValue)
                {
                    newSetting[codeWithoutMetadataPrefix] = settingValue;
                }
                else
                {
                    delete newSetting[codeWithoutMetadataPrefix];
                }

                setMetadataValue({
                    ...metadataValue,
                    setting: newSetting
                });
            },
            [
                metadataValue,
                codeWithoutMetadataPrefix,
                setMetadataValue
            ]);

    return [ settingValue, setSettingValue ];
}
