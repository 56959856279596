import Item from '../../../Model/Item';
import TrackCellRange from './TrackCellRange';

export default class TrackItem
{
    // ------------------------- Properties -------------------------

    item: Item;
    range: TrackCellRange;

    // ------------------------ Constructor -------------------------

    constructor(item: Item, range: TrackCellRange)
    {
        this.item = item;
        this.range = range;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
