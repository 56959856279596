import Function from '../Function';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';

export default abstract class Action<T extends ValueType<any>, V extends Value<any, any>> extends Function<T, V>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    toDescriptor()
    {
        const descriptor = {
            class: 'Action'
        };

        this.augmentDescriptor(descriptor);

        return descriptor;
    }

    abstract augmentDescriptor(descriptor: any);

    // ----------------------- Private logic ------------------------
}
