import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import useTypes from '../../../../Type/Api/useTypes';
import { DataObjectViewerStore } from '../../../../../DataObject/Viewer/DataObjectViewerStore';
import { DataObjectViewer } from '../../../../../DataObject/Viewer/DataObjectViewer';
import FileViewer from '../../../../../../Generic/FileViewer/FileViewer';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { FileValue } from '../../../../../DataObject/Type/File/FileValue';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { AttachmentVersionsTable } from './Table/AttachmentVersionsTable';
import { AttachmentDetails } from './AttachmentDetails';
import { FileViewStore } from '../../../../../DataObject/Type/File/FileViewStore';
import PrimaryTextButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import useDividerGlue from '../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';


const MimeTypesWithVersionSupport =
    [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];

export interface AttachmentMoreProps
{
    entity: Entity;
}

const AttachmentMore: React.FC<AttachmentMoreProps> =
    props =>
    {
        const types = useTypes();
        const fileValue =
            useEntityValue<FileValue>(
                props.entity,
                types.Attachment.Field.File
            );
        const commitContext = useNewCommitContext()
        const dataObjectViewerStore =
            useComputed(
                () =>
                    new DataObjectViewerStore(props.entity.getDataObjectValueByField(types.Attachment.Field.File)) as DataObjectViewerStore<FileViewStore>,
                [
                    props.entity,
                    types
                ]
            );

        const canHaveVersions =
            useComputed(
                () =>
                    fileValue &&
                    MimeTypesWithVersionSupport.includes(fileValue.mime),
                [
                    fileValue
                ]
            )

        const onDownload =
            useCallback(
                () =>
                    dataObjectViewerStore.bespokeStore.handleClick(),
                [
                    dataObjectViewerStore
                ]
            );

        const glue = useDividerGlue()

        const isImage =
            useComputed(
                () =>
                    fileValue?.mime.startsWith('image'),
                [
                    fileValue
                ]
            )

        if (fileValue?.mime === 'application/pdf')
        {
            return <FileViewer
                url={
                    props.entity.entityTypeStore.getFileUrl(
                        props.entity.getValueByField(types.Attachment.Field.File)
                    )
                }
                type="pdf"
            />;
        }
        else if (isImage)
        {
            return <FileViewer
                url={
                    props.entity.entityTypeStore.getFileUrl(
                        props.entity.getValueByField(types.Attachment.Field.File)
                    )
                }
                type="image"
            />;
        }
        else
        {
            return <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    glue={glue}
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                        >
                            <ViewGroupItem>
                                <DataObjectViewer
                                    store={dataObjectViewerStore}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem
                                alignment="center"
                            >
                                <PrimaryTextButton
                                    label="Download"
                                    onClick={onDownload}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>

                    <ViewGroupItem
                        ratio={6}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem
                            >
                                <AttachmentDetails
                                    attachment={props.entity}
                                    commitContext={commitContext}
                                    canHaveVersions={canHaveVersions}
                                />
                            </ViewGroupItem>

                            {
                                canHaveVersions &&
                                <ViewGroupItem>
                                    <AttachmentVersionsTable
                                        attachment={props.entity}
                                    />
                                </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>
        }
    };

export default observer(AttachmentMore);
