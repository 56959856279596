import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import List from '../../../../../Model/Specification/List';
import Column from '../../../../../Model/Specification/Column';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import MenuButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import ColumnFilterEditor from '../ColumnFilterEditor/ColumnFilterEditor';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import View from '../../../../../Model/View';

export interface ColumnFilterButtonProps
{
    view: View;
    list: List;
    column: Column;
    open?: boolean;
    onOpen: () => void;
    onClose: () => void;
}

const ColumnFilterButton: React.FC<ColumnFilterButtonProps> =
    props =>
    {
        const { column, open, onOpen, onClose } = props;
        const numberOfPredicates =
            useComputed(
                () =>
                    column.filter ? column.filter.predicates.length : 0,
                [
                    column
                ]);

        return <MenuButton
            icon="filter_list"
            tooltip={
                <LocalizedText
                    code="Generic.Filter"
                    value="Filter"
                />
            }
            color={numberOfPredicates > 0 ? primaryColor : textSecondaryColor}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            badge={numberOfPredicates > 0 ? numberOfPredicates : undefined}
            badgeVariant="dot"
        >
            <CardInset>
                <ColumnFilterEditor
                    {...props}
                />
            </CardInset>
        </MenuButton>;
    };

export default observer(ColumnFilterButton);
