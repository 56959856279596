import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import TemplateLayout from '../../../../../@Api/Layout/Type/TemplateLayout';
import TemplateSelector from './TemplateSelector';

export interface TemplateLayoutControlsProps extends LayoutEditorProps<TemplateLayout>
{

}

const TemplateLayoutControls: React.FC<TemplateLayoutControlsProps> =
    props =>
    {
        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <TemplateSelector
                    {...props}
                />
            </InputGroup>
        </CardInset>;
    };

export default observer(TemplateLayoutControls);
