import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../../../../../../../@Api/Layout/Layout';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import LayoutControlsContent, { LayoutControlsContentProps } from '../../Content/LayoutControlsContent';
import Header from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import uuid from '../../../../../../../@Util/Id/uuid';
import { HighlightedLayoutContext } from '../../Region/HighlightedLayoutContext';
import { runInAction } from 'mobx';

export interface ParentLayoutControlsProps<L extends Layout> extends LayoutControlsContentProps<L>
{

}

const ParentLayoutControls: React.FC<ParentLayoutControlsProps<Layout>> =
    props =>
    {
        const id = useMemo(() => uuid(), []);
        const highlightedLayoutContainer = useContext(HighlightedLayoutContext);

        const highlight =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            highlightedLayoutContainer.set(props.layout)),
                [
                    highlightedLayoutContainer,
                    props.layout
                ]);

        const dehighlight =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            highlightedLayoutContainer.set(undefined)),
                [
                    highlightedLayoutContainer,
                    props.layout
                ]);

        return <div
            onMouseOver={highlight}
            onMouseOut={dehighlight}
        >
            <ExpansionPanel
                id={id}
                summary={
                    <Header
                        title={props.layout.getName()}
                        inset
                    />
                }
                expansion={
                    <CardInset
                        top={false}
                        horizontal={false}
                    >
                        <LayoutControlsContent
                            {...props}
                        />
                    </CardInset>
                }
            />
        </div>;
    };

export default observer(ParentLayoutControls);
