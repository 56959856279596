import EmptyValueType from '../Value/Type/EmptyValueType';
import CollectionType from '../Value/Type/CollectionType';
import PrimitiveValueType from '../Value/Type/PrimitiveValueType';
import EntityValueType from '../Value/Type/EntityValueType';
import ValueType from '../Value/Type/ValueType';
import DataSourceValueType from '../Value/Type/DataSourceValueType';
import AutomationDependencyContext from '../AutomationDependencyContext';
import MapType from '../Value/Type/MapType';

export default async function getValueTypeFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<ValueType<any>>
{
    switch (descriptor.type)
    {
        case 'Empty':
            return EmptyValueType.fromDescriptor(descriptor);

        case 'Collection':
            return CollectionType.fromDescriptor(descriptor, dependencyContext);

        case 'Map':
            return MapType.fromDescriptor(descriptor, dependencyContext);

        case 'Primitive':
            return PrimitiveValueType.fromDescriptor(descriptor);

        case 'Entity':
            return EntityValueType.fromDescriptor(descriptor);

        case 'DataSource':
            return DataSourceValueType.fromDescriptor(descriptor, dependencyContext);
    }
}
