import * as React from 'react';
import { ViewComponent } from './Model/ViewComponent';

export interface ViewProps
{
    viewComponent: ViewComponent<any>
}

export class View extends React.Component<ViewProps>
{
    render()
    {
        return React.createElement(
            this.props.viewComponent.component,
            {
                store: this.props.viewComponent.store,
                ...this.props.viewComponent.props
            });
    }
}
