import * as React from 'react';
import { dimmedColor, EntityEventType } from '../Model/EntityEventType';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { EntityActionEvent } from '../../../../../@Api/Model/Implementation/EntityActionEvent';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { Typography } from '@material-ui/core';
import { EntityCreationMutation } from '../../../../../@Api/Model/Implementation/EntityCreationMutation';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { BadgeStore } from '../../../../Generic/Badge/BadgeStore';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { ImportanceLevel } from '../../../../../@Api/Model/Implementation/EntityField';

export class EventAction extends EntityEventType
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity.Action';
    }

    parentId(): string
    {
        return null;
    }

    isEnabledByDefault(): boolean
    {
        return false;
    }

    color(event: EntityEvent): string
    {
        return dimmedColor(event.entity.entityType);
    }

    icon(event: EntityEvent): string
    {
        return 'low_priority';
    }

    isFilled(event: EntityActionEvent): boolean
    {
        return false;
    }

    importanceLevel(event: EntityEvent): ImportanceLevel
    {
        return ImportanceLevel.Normal;
    }

    name(event: EntityActionEvent): string
    {
        return this.localizationStore.translate('Event.Action.Name'); // Action
    }

    description(event: EntityActionEvent): string
    {
        return event.actionCode;
    }

    content(event: EntityActionEvent): JSX.Element
    {
        return <Typography variant="body2">
            Actie: {event.actionCode}
        </Typography>;
    }

    caption(event: EntityEvent): string
    {
        return this.localizationStore.translate('Event.Action.Caption'); // executed
    }

    actions(event: EntityActionEvent): ButtonStore[]
    {
        return [];
    }

    badges(event: EntityEvent): BadgeStore[]
    {
        return [];
    }

    allowExpansion(event: EntityActionEvent): boolean
    {
        return false;
    }

    expandInSidebar(event: EntityCreationMutation): boolean
    {
        return false;
    }

    expansionComponent(event: EntityActionEvent): React.ComponentClass<BaseComponentProps<BaseStore>>
    {
        return null;
    }

    expansionStore(event: EntityActionEvent): BaseStore
    {
        return null;
    }

    typeNameSingular(event: EntityActionEvent): string
    {
        return this.localizationStore.translate('Event.Action.Singular.Name'); // Action
    }

    typeNamePlural(): string
    {
        return this.localizationStore.translate('Event.Action.Plural.Name'); // Actions
    }
}
