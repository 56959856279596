import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { LogLevel } from '../Model/LogLevel';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import { orange, blue, red, grey } from '@material-ui/core/colors';

const useStyles =
    makeStyles({
        label: {
            fontWeight: 'bolder',
            fontFamily: 'monospace',
            fontSize: 12,
            color: grey[100],
            padding: '3px 5px',
            width: '100%',
            borderRadius: 5
        },
        info: {
            backgroundColor: blue[300],
        },
        warning: {
            backgroundColor: orange[300],
        },
        error: {
            backgroundColor: red[300],
        }
    });

export interface LogLevelViewerProps
{
    level: LogLevel;
}

export const LogLevelViewer: React.FC<LogLevelViewerProps> =
    ({
        level,
     }) =>
    {
        const classes = useStyles();
        return <div
            className={
                classNames(
                    classes.label,
                    level === 'Info' && classes.info,
                    level === 'Warning' && classes.warning,
                    level === 'Error' && classes.error
                )
            }
        >
            {level}
        </div>
    };
