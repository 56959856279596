import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { AddEntityPath } from '../../../Path/@Model/AddEntityPath';
import { RelatedEntityPath } from '../../../Path/@Model/RelatedEntityPath';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useTypes from '../../../Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';

export default function useAddPaths(rootEntity?: Entity,
                                    entityType?: EntityType,
                                    pathFromRootEntity?: EntityPath,
                                    ignoreHidden?: boolean)
{
    const types = useTypes();

    const paths =
        useComputed(
            () =>
            {
                if (rootEntity)
                {
                    if (pathFromRootEntity)
                    {
                        return [
                            new AddEntityPath(
                                new RelatedEntityPath(
                                    rootEntity,
                                    pathFromRootEntity))
                        ];
                    }
                    else
                    {
                        let addPaths: AddEntityPath[] = [];
                        let rootPath = EntityPath.fromEntity(rootEntity);

                        if (rootEntity.entityType.isA(types.Relation.Type))
                        {
                            let relationshipPath: EntityPath;
                            let relationship: Entity;

                            // Resolve path to activities
                            if (rootEntity.entityType.isA(types.Relation.Organization.Type))
                            {
                                relationshipPath =
                                    rootPath.joinTo(
                                        types.Relationship.Organization.RelationshipDefinition.Organization,
                                        true);

                            }
                            else if (rootEntity.entityType.isA(types.Relation.Person.Type))
                            {
                                relationshipPath =
                                    rootPath.joinTo(
                                        types.Relationship.Person.RelationshipDefinition.Person,
                                        true);
                            }

                            if (relationshipPath)
                            {
                                const relationships =
                                    relationshipPath.traverseEntity(rootEntity);

                                if (relationships.length > 0)
                                {
                                    relationship = relationships[0];
                                }
                            }

                            if (relationship)
                            {
                                if (relationship.entityType.isA(types.Relationship.Person.Contact.Type))
                                {
                                    const contactPath =
                                        relationshipPath.castTo(types.Relationship.Person.Contact.Type);

                                    addPaths.push(
                                        new AddEntityPath(
                                            new RelatedEntityPath(
                                                rootEntity,
                                                contactPath
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                        false)),
                                            new RelatedEntityPath(
                                                relationship,
                                                EntityPath.fromEntity(relationship)
                                                    .joinTo(
                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                        false))));
                                }
                                else
                                {
                                    addPaths.push(
                                        new AddEntityPath(
                                            new RelatedEntityPath(
                                                rootEntity,
                                                relationshipPath
                                                    .joinTo(
                                                        types.Relationship.RelationshipDefinition.Activities,
                                                        false)),
                                            new RelatedEntityPath(
                                                relationship,
                                                EntityPath.fromEntity(relationship)
                                                    .joinTo(
                                                        types.Relationship.RelationshipDefinition.Activities,
                                                        false))));
                                }
                            }

                            // Resolve path to relationships (add two casts to enforce grouping the menu on persons and organizations)
                            addPaths.push(
                                new AddEntityPath(
                                    new RelatedEntityPath(
                                        rootEntity,
                                        rootPath
                                            .joinTo(
                                                types.Relation.RelationshipDefinition.Relationships,
                                                false)
                                            .castTo(types.Relationship.Organization.Type))),
                                new AddEntityPath(
                                    new RelatedEntityPath(
                                        rootEntity,
                                        rootPath
                                            .joinTo(
                                                types.Relation.RelationshipDefinition.Relationships,
                                                false)
                                            .castTo(types.Relationship.Person.Type))));
                        }
                        else if (rootEntity.entityType.isA(types.Activity.Type))
                        {
                            // Resolve path to linked activities
                            addPaths.push(
                                new AddEntityPath(
                                    new RelatedEntityPath(
                                        rootEntity,
                                        rootPath
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.LinkedActivities,
                                                false))));

                            // Resolve path to involved
                            addPaths.push(
                                new AddEntityPath(
                                    new RelatedEntityPath(
                                        rootEntity,
                                        rootPath
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.Involved,
                                                false))));
                        }
                        else
                        {
                            [ true, false ]
                                .forEach(
                                    isParent =>
                                        rootEntity.entityType.getInheritedRelationshipDefinitions(isParent)
                                            .filter(
                                                relationshipDefinition =>
                                                    relationshipDefinition.isManaged(!isParent)
                                                    && relationshipDefinition.isPlural(isParent))
                                            .forEach(
                                                definition =>
                                                    addPaths.push(
                                                        new AddEntityPath(
                                                            new RelatedEntityPath(
                                                                rootEntity,
                                                                rootPath
                                                                    .joinTo(
                                                                        definition,
                                                                        false))))));
                        }

                        addPaths.push(
                            new AddEntityPath(
                                new RelatedEntityPath(
                                    rootEntity,
                                    rootPath
                                        .joinTo(
                                            types.Entity.RelationshipDefinition.Attachments,
                                            false))));

                        return addPaths;
                    }
                }
                else if (entityType)
                {
                    return [
                        new AddEntityPath(
                            new RelatedEntityPath(
                                undefined,
                                EntityPath.root(entityType)))
                        ];
                }
                else
                {
                    return [
                        types.Relationship.Person.Type,
                        types.Relationship.Organization.Type,
                        types.Activity.Type,
                        types.CustomEntity.Type,
                        ...ignoreHidden
                            ?
                                [
                                    types.Datastore.Type,
                                    types.Product.Type,
                                    types.Note.Type,
                                    types.Attachment.Type,
                                    types.Notification.Type,
                                    types.Team.Type,
                                    types.Milestone.Type,
                                    types.TimeRegistration.Type,
                                    types.TimeRegistrationActivity.Type,
                                    types.MileageRegistration.Type,
                                    types.MileageRegistrationType.Type,
                                ]
                            :
                                []
                    ]
                        .filter(
                            type =>
                                type)
                        .map(
                            type =>
                                new AddEntityPath(
                                    new RelatedEntityPath(
                                        undefined,
                                        EntityPath.root(type))));
                }
            },
            [
                rootEntity,
                entityType,
                pathFromRootEntity,
                ignoreHidden
            ]);

    return paths;
}
