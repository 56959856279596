import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import PortalListLayout from '../../../../../../@Api/Layout/Type/PortalListLayout';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import PortalDataSourceListQuery from '../../../../../../@Api/Portal/DataSource/PortalDataSourceListQuery';
import uuid from '../../../../../../@Util/Id/uuid';
import { PortalListLayoutViewerForQuery } from './ForQuery/PortalListLayoutViewerForQuery';
import { PortalListLayoutViewerPageSize } from './Constants/PortalListLayoutViewerConstants';

export interface PortalListLayoutViewerProps extends LayoutViewerProps<PortalListLayout>
{

}

const PortalListLayoutViewer: React.FC<PortalListLayoutViewerProps> =
    props =>
    {
        const itemParameterDictionary =
            useComputed(
                () =>
                    ParameterDictionary.union(
                        props.parameterDictionary,
                        props.layout.dataSourceSignature.resultParameters,
                        new ParameterDictionary([ props.layout.itemParameter ])
                    ),
                [
                    props.parameterDictionary,
                    props.layout
                ]
            );
        const [ queryParameterAssignment ] =
            useAsyncResult(
                () =>
                    props.layout.parameterAssignment.apply(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]
            );
        const query =
            useMemo(
                () =>
                    queryParameterAssignment &&
                        new PortalDataSourceListQuery(
                            uuid(),
                            props.layout.dataSourceSignature,
                            queryParameterAssignment,
                            undefined,
                            props.layout.orderings,
                            0,
                            PortalListLayoutViewerPageSize
                        ),
                [
                    props.layout,
                    queryParameterAssignment,
                ]
            );

        return <PortalListLayoutViewerForQuery
            {...props}
            itemParameterDictionary={itemParameterDictionary}
            query={query}
        />;
    };

export default observer(PortalListLayoutViewer);
