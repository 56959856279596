import { ApiBaseType } from '../ApiBaseType';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObject } from '../../../Model/DataObject';
import Dictionary from 'typescript-collections/dist/lib/Dictionary';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import isHiddenType from '../../../../../../@Api/Metadata/EntityType/isHiddenType';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class EntityTypeType extends ApiBaseType<EntityType>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    entityTypeStore: EntityTypeStore;

    constructor(entityTypeStore: EntityTypeStore)
    {
        super();

        this.entityTypeStore = entityTypeStore;
    }

    id(): string
    {
        return 'EntityType';
    }

    name()
    {
        return localizeText('DataObject.Type.EntityType', 'Entiteitstype (statisch)');
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        // Because this type operates on a cache, it is possible to immediately return the cached types
        const uninitializedValue = super.getUninitializedValueFromData(data, specification);

        const types =
            uninitializedValue.ids
                .map(
                    id =>
                        this.entityTypeStore.getTypeById(id))
                .filter(
                    type =>
                        type);

        if (types.length === 0)
        {
            return undefined;
        }
        else if (types.length === 1)
        {
            return types[0];
        }
        else
        {
            return types;
        }
    }

    getInstanceId(instance: EntityType): number
    {
        return instance.id;
    }

    getInstanceName(instance: EntityType): string
    {
        return instance.nameSingular as any;
    }

    getInstanceDescription(instance: EntityType): string
    {
        return null;
    }

    getInstanceAvatarUrl(instance: EntityType, moduleManager: ModuleManager): string
    {
        return null;
    }

    getInstances(ids: number[],
                 dataObjectById: Dictionary<number, DataObject>,
                 moduleManager: ModuleManager): Promise<EntityType[]>
    {
        return Promise.resolve(
            ids.map(
                id =>
                    this.entityTypeStore.getTypeById(id))
                .filter(type => type != null));
    }

    searchInstances(query: string,
                    limit: number,
                    dataObject: DataObject,
                    moduleManager: ModuleManager): Promise<EntityType[]>
    {
        const rootEntityTypeId = dataObject.specification.data.rootEntityTypeId;
        const entityType =
            rootEntityTypeId === undefined
                ?
                    this.entityTypeStore.bespoke.types.Entity.Type
                :
                    this.entityTypeStore.getTypeById(rootEntityTypeId);

        return Promise.resolve(
            entityType.getAllTypes(false, true)
                .filter(
                    type =>
                        dataObject.specification.data.isInstantiable
                            ?
                                type.isInstantiableByInheritance()
                            :
                                true)
                .filter(
                    type =>
                        !isHiddenType(type))
                .filter(
                    type =>
                        query === undefined
                            ||
                        type.nameSingular.toLowerCase().indexOf(query) >= 0));
    }

    getAllInstances(specification: DataObjectSpecification,
                    moduleManager: ModuleManager): Promise<EntityType[]>
    {
        return Promise.resolve(this.entityTypeStore.types);
    }
}
