import Predicate from '../Function/Computation/Predicate/Predicate';
import FunctionContext from '../Function/FunctionContext';
import Computation from '../Function/Computation/Computation';
import Value from '../Value/Value';
import safelyApplyFunction from './safelyApplyFunction';
import ParameterDictionary from '../Parameter/ParameterDictionary';

export async function getPartiallyComputedPredicate(
    predicate: Predicate,
    context: FunctionContext,
    parametersToKeep: ParameterDictionary
): Promise<Predicate>
{
    const computationsToSubstitute: Computation<any, any>[] = [];
    predicate.substitute(
        computation =>
        {
            if (computation.getDependencies()
                .every(
                    dependency =>
                        !parametersToKeep.hasParameter(dependency.parameter)))
            {
                computationsToSubstitute.push(computation);
            }

            return computation;
        }
    );
    const valueBySubstitutedComputationMap = new Map<Computation<any, any>, Value<any, any>>();
    await Promise.all(
        computationsToSubstitute.map(
            computation =>
                safelyApplyFunction(
                    computation,
                    context)
                    .then(
                        value =>
                            valueBySubstitutedComputationMap.set(
                                computation,
                                value
                            )
                    )
        )
    );

    return predicate.substitute(
        computation =>
        {
            if (valueBySubstitutedComputationMap.has(computation))
            {
                return valueBySubstitutedComputationMap.get(computation);
            }
            else
            {
                return computation;
            }
        }
    );
}
