import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import performAction from '../../../../../../../@Api/Entity/performAction';
import Role from '../../../../../Role/Model/Role';
import { DetailedRole } from '../../../../../Configuration/Page/Employees/Employee/RightsViewer/RightsViewer';

export default async function getRoleDetails(
    entity: Entity,
    actionCode: string
): Promise<DetailedRole[]>
{
    const result =
        await performAction(
            entity,
            {
                code: actionCode,
            });

    return await Promise.all(
        result.result
            .map
            (
                async role =>
                {
                    return {
                        id: role.id,
                        name: role.name,
                        role: await Role.fromDescriptor(role.descriptor),
                        isBase: role.isBase
                    }
                }
            )
    );
}