import * as React from 'react';
import { DataObjectBespokeViewer } from '../../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { TextViewerStore } from './TextViewerStore';

@observer
export class TextView extends DataObjectBespokeViewer<TextViewerStore>
{
    renderComponent(store: DataObjectViewerStore<TextViewerStore>)
    {
        if (store.bespokeStore.translation)
        {
            return <span>{store.bespokeStore.translation}</span>;
        }
        else
        {
            return null;
        }
    }
}
