import React from 'react';
import { observer } from 'mobx-react-lite';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '../../../../../../Input/Input';
import { Alignment } from '../../../../../../../DataObject/Model/DataObject';
import useTypes from '../../../../../../Type/Api/useTypes';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { useCommittableEntity } from '../../../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import { makeStyles } from '@material-ui/styles';
import useTotals from './Hooks/useTotals';
import { Entity } from '../../../../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

const useStyles =
    makeStyles({
        total:
            {
                borderBottom: '0 !important',
                fontWeight: '500 !important' as any
            },
        noBorderBottom:
            {
                borderBottom: '0 !important'
            },
        symbolContainer:
            {
                position: 'relative'
            },
        symbol:
            {
                position: 'absolute',
                left: 'calc(50% - 12px)',
                top: -15,
                color: 'rgb(224, 224, 224)',
                fontSize: 12
            }
    });

export interface TotalsProps
{
    entity: Entity;
    lines: Entity[];
    onSelect?: (entity: Entity, selected: boolean) => void; // Presence of hook only used to display the correct number of columns
    disabled?: boolean;
    discountHidden?: boolean;
    autoCommit?: boolean;
    commitContext?: CommitContext;
    currency: string | undefined;
}

export const DesktopTotals: React.FC<TotalsProps> = observer(
    props =>
    {
        const types = useTypes();
        const classes = useStyles();
        const commitContext = useNewCommitContext(props.commitContext);
        const entity = useCommittableEntity(props.entity, commitContext);

        const totals = useTotals(
            props.lines,
            props.currency,
            entity,
            commitContext,
            props.discountHidden,
            props.onSelect
        );

        const baseColSpan = 5;

        return <>
            {
                totals.showDiscountSection &&
                <TableRow>
                    <TableCell
                        colSpan={baseColSpan + totals.additionalColspan}
                        align="right"
                        className={classes.total}
                    >
                        <LocalizedText
                            code="Generic.Subtotal"
                            value="Subtotaal"
                        />
                    </TableCell>
                    <TableCell
                        className={classes.total}
                    />
                    <TableCell
                        align="right"
                        className={classes.total}
                    >
                        {
                            totals.subtotalAmount.toCurrencyString(props.currency)
                        }
                    </TableCell>
                    {
                        props.onSelect &&
                        <TableCell
                            className={classes.total}
                        />
                    }
                    <TableCell
                        className={classes.total}
                    />
                </TableRow>
            }
            {
                totals.showDiscountSection &&
                <TableRow>
                    <TableCell
                        className={classes.noBorderBottom}
                    />
                    <TableCell
                        colSpan={3 + totals.additionalColspan}
                        align="right"
                    >
                        <LocalizedText
                            code="Generic.Discount"
                            value="Korting"
                        />
                    </TableCell>
                    <TableCell>
                        <Input
                            entity={entity}
                            field={types.Activity.Field.DiscountPercentage}
                            labelPosition="none"
                            alignment={Alignment.Right}
                            disabled={props.disabled}
                            doAutocommit={props.autoCommit}
                            commitContext={commitContext}
                        />
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {totals.discountAmount.toCurrencyString(props.currency)}
                    </TableCell>
                    {
                        props.onSelect &&
                        <TableCell
                            className={classes.noBorderBottom}
                        />
                    }
                    <TableCell
                        className={classes.noBorderBottom}
                    />
                </TableRow>
            }
            <TableRow>
                <TableCell
                    colSpan={baseColSpan + totals.additionalColspan}
                    align="right"
                    className={classes.total}
                >
                    <LocalizedText
                        code="Generic.TotalExcludingVat"
                        value="Totaal excl. BTW"
                    />
                </TableCell>
                <TableCell
                    className={classes.total}
                >

                </TableCell>
                <TableCell
                    align="right"
                    className={classes.total}
                >
                    {totals.totalExcludingVatAmount.toCurrencyString(props.currency)}
                </TableCell>
                {
                    totals.showDiscountSection &&
                    <TableCell
                        className={classNames(classes.total, classes.symbolContainer)}
                    >
                        <div
                            className={classes.symbol}
                        >
                            -
                        </div>
                    </TableCell>
                }
                {
                    props.onSelect &&
                    <TableCell
                        className={classes.total}
                    />
                }
            </TableRow>
            {
                Array.from(totals.amountByVatGroup.entries())
                    .map(
                        ([ vatPercentage, vatAmount ], idx) =>
                            <TableRow
                                key={vatPercentage || 'undefined'}
                            >
                                <TableCell
                                    className={classes.noBorderBottom}
                                />
                                <TableCell
                                    colSpan={3 + totals.additionalColspan}
                                    align="right"
                                    className={idx < totals.amountByVatGroup.size - 1 ? classes.noBorderBottom : undefined}
                                >
                                    {
                                        idx === 0
                                            ?
                                            <LocalizedText
                                                code="Generic.VAT"
                                                value="BTW"
                                            />
                                            :
                                            ''
                                    }
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={idx < totals.amountByVatGroup.size - 1 ? classes.noBorderBottom : undefined}
                                >
                                    {vatPercentage}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={idx < totals.amountByVatGroup.size - 1 ? classes.noBorderBottom : undefined}
                                >
                                    {vatAmount.toCurrencyString(props.currency)}
                                </TableCell>
                                {
                                    props.onSelect &&
                                    <TableCell
                                        className={classes.noBorderBottom}
                                    />
                                }
                                <TableCell
                                    className={classes.noBorderBottom}
                                />
                            </TableRow>)
            }
            <TableRow>
                <TableCell
                    colSpan={baseColSpan + totals.additionalColspan}
                    align="right"
                    className={classes.total}
                >
                    <LocalizedText
                        code="Generic.Total"
                        value="Totaal"
                    />
                </TableCell>
                <TableCell
                    className={classes.total}
                >

                </TableCell>
                <TableCell
                    align="right"
                    className={classes.total}
                >
                    {totals.totalIncludingVatAmount.toCurrencyString(props.currency)}
                </TableCell>
                <TableCell
                    className={classNames(classes.total, classes.symbolContainer)}
                >
                    <div
                        className={classes.symbol}
                    >
                        +
                    </div>
                </TableCell>
                {
                    props.onSelect &&
                    <TableCell
                        className={classes.total}
                    />
                }
            </TableRow>
        </>;
    }
);
