import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DocumentEditor.module.scss';
import { CoreBlockType } from './Gutenberg/DefaultBlockType';
import useDelayedCallback from '../../../../Util/Hook/useDelayedCallback';
import DocumentContext from './DocumentContext';
import { observer } from 'mobx-react-lite';
import AsyncComponent from '../../../../../@Util/AsyncComponent/AsyncComponent';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection';
import { FeedbackStore } from '../../../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import IconButton from '../../Button/Variant/Icon/IconButton';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../@Api/Localization/localizeText';

export type DocumentDefinitionType = 'gutenberg' | 'html';
export type DocumentLayoutDefinition = { width: number, widthUnit: 'Percentage' | 'Pixel', backgroundColor?: string };

export interface DocumentDefinition
{
    type: DocumentDefinitionType;
    definition: any;
    layout?: DocumentLayoutDefinition;
}

export interface DocumentEditorProps
{
    type: DocumentDefinitionType;
    blockTypes?: BlockTypeId[];
    definition?: DocumentDefinition;
    onChange: (definition: DocumentDefinition) => void;
    disableCoreBlockTypes?: boolean;
    editLayoutMode?: boolean;
    hideEntityFieldSelector?: boolean;
    enableCopyPaste?: boolean;
    debounceDelay?: number;
    buttons?: React.ReactNode;
    documentLayoutControls?: boolean;
}

export type BlockTypeId = string;

export interface BlockType
{
    name: string;
}

const gutenBergEditorLoader =
    () =>
        import('./Gutenberg/GutenbergBlockEditor');

const DocumentEditor: React.FC<DocumentEditorProps> =
    props =>
    {
        const { onChange } = props;
        const [ currentDocument, setCurrentDocument ] = useState<DocumentDefinition>(props.definition);
        const [ hideEditor, setHideEditor ] = useState(false);
        const [ copiedContent, setCopiedContent ] = useState(undefined);

        const feedbackStore = loadModuleDirectly(FeedbackStore);

        useDelayedCallback(
            currentDocument,
            onChange,
            props.debounceDelay);

        const onChangeCallback =
            useCallback(
                (wpBlocks, layout) =>
                {
                    setCurrentDocument({
                        type: 'gutenberg',
                        definition: wpBlocks,
                        layout: layout
                    });
                },
                [
                    setCurrentDocument
                ]);

        const allowedBlockTypes: CoreBlockType[] =
            useMemo(
                () =>
                    [
                        'core/heading',
                        // 'core/list',
                        // 'core/quote',
                        // 'core/button',
                        // 'core/code',
                        'core/columns',
                        'core/column',
                        'core/group',
                        // 'core/html',
                        // 'core/media-text',
                        // 'core/preformatted',
                        // 'core/pullquote',
                        'core/separator',
                        // 'core/block',
                        'core/spacer',
                        // 'core/subhead',
                        // 'core/table',
                        // 'core/text-columns',
                        // 'core/video'
                    ],
                []);

        useEffect(
            () =>
            {
                setCopiedContent(
                    sessionStorage.getItem('document.clipboard.definition'));

                const timer =
                    setInterval(
                        () =>
                            setCopiedContent(
                                sessionStorage.getItem('document.clipboard.definition')),
                        1000);

                return () => clearInterval(timer);
            },
            [
                setCopiedContent
            ]);

        const copy =
            useCallback(
                () =>
                {
                    const descriptor = JSON.stringify(currentDocument);

                    sessionStorage.setItem('document.clipboard.definition', descriptor);
                    setCopiedContent(descriptor);
                    feedbackStore.enqueueSnackbar("Template gekopieerd");
                },
                [
                    currentDocument,
                    setCopiedContent,
                    feedbackStore
                ]);

        const paste =
            useCallback(
                () =>
                {
                    const confirmation = window.confirm(
                        localizeText(
                            'OverwriteTemplateWarning',
                            'Weet je zeker dat je deze template wilt overschrijven? \n\nLET OP: De huidige template gaat hierbij verloren. Dit is niet omkeerbaar.'
                        )
                    );

                    if (confirmation)
                    {
                        const clipboardValue = sessionStorage.getItem('document.clipboard.definition');

                        if (clipboardValue != null)
                        {
                            setHideEditor(true);

                            setTimeout(
                                () =>
                                {
                                    const document = JSON.parse(clipboardValue);
                                    setCurrentDocument(document);

                                    setTimeout(
                                        () =>
                                        {
                                            setHideEditor(false);
                                            onChange(document);
                                        },
                                        200);
                                },
                                200);
                        }
                    }
                },
                [
                    setCurrentDocument,
                    setHideEditor,
                    onChange
                ]);

        return <div
            className={styles.root}
        >
            <div
                className={styles.wrapper}
            >
                {
                    props.editLayoutMode &&
                    props.enableCopyPaste &&
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                            justification="end"
                        >
                            <ViewGroupItem>
                                <IconButton
                                    onClick={copy}
                                    icon="filter_none"
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.CopyToClipboard"
                                            value="Kopieer naar klembord"
                                        />
                                    }
                                />
                            </ViewGroupItem>
                            {
                                copiedContent &&
                                    <ViewGroupItem>
                                        <IconButton
                                            onClick={paste}
                                            icon="post_add"
                                            tooltip="Plakken"
                                        />
                                    </ViewGroupItem>
                            }
                            {
                                props.buttons &&
                                    <ViewGroupItem>
                                        {props.buttons}
                                    </ViewGroupItem>
                            }
                        </ViewGroup>
                }

                <DocumentContext.Provider
                    value={props}
                >
                    {
                        !hideEditor &&
                            // <ErrorBoundary>
                            //     <GutenbergBlockEditor
                            //         onChange={onChangeCallback}
                            //         blocks={currentDocument ? currentDocument.definition : undefined}
                            //         blockTypes={props.blockTypes as BlockTypeId[]}
                            //         allowedDefaultBlockTypes={allowedBlockTypes}
                            //         addCoreBlockTypes={!props.disableCoreBlockTypes}
                            //         editLayoutMode={props.editLayoutMode}
                            //         documentLayoutControls={props.documentLayoutControls}
                            //         layout={currentDocument?.layout}
                            // />
                            // </ErrorBoundary>
                            <AsyncComponent
                                _loader={gutenBergEditorLoader}
                                _loadKey="default"
                                onChange={onChangeCallback}
                                blocks={currentDocument ? currentDocument.definition : undefined}
                                blockTypes={props.blockTypes as BlockTypeId[]}
                                allowedDefaultBlockTypes={allowedBlockTypes}
                                addCoreBlockTypes={!props.disableCoreBlockTypes}
                                editLayoutMode={props.editLayoutMode}
                                documentLayoutControls={props.documentLayoutControls}
                                layout={currentDocument?.layout}
                            />
                    }
                </DocumentContext.Provider>
            </div>
        </div>;
    };

DocumentEditor.defaultProps =
{
    editLayoutMode: true,
    enableCopyPaste: true,
    debounceDelay: 1000
};

export default observer(DocumentEditor);

export const GutenbergCoreBlockTextId = 'core/text';
export const GutenbergCoreBlockHeadingId = 'core/heading';
export const GutenbergCoreBlockColumnsId = 'core/columns';
export const GutenbergCoreBlockColumnId = 'core/column';
export const GutenbergCoreBlockGroupId = 'core/group';
export const GutenbergCoreBlockSpacerId = 'core/spacer';

export const GutenbergBlockTextId = 'pv/text';
export const GutenbergBlockHtmlId = 'pv/html';
export const GutenbergBlockExpressionId = 'pv/expression';
export const GutenbergBlockImageId = 'pv/image';
export const GutenbergBlockTemplateId = 'pv/template-block';
export const GutenbergBlockVideoId = 'pv/video';
export const GutenbergBlockPageBreakId = 'pv/page-break';
export const GutenbergBlockButtonId = 'pv/button'
export const OrganizationLogoBlockId = 'pv/organization-logo';
export const EntityImageBlockId = 'pv/entity-image';
export const ProductLinesBlockId = 'pv/sales-opportunity-lines';
export const TimeRegistrationsBlockId = 'pv/time-registrations';
export const MileageRegistrationsBlockId = 'pv/mileage-registrations';
export const LayoutBlockId = 'pv/layout';
export const FormFieldBlockId = 'form/field';
export const FormSubmitButtonBlockId = 'form/submit-button';

