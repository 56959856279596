import React from 'react';
import { observer } from 'mobx-react-lite';
import GutenbergBlockViewer from './Gutenberg/GutenbergBlockViewer';

export interface DocumentViewerProps
{
    definition: any;
    safeMode?: boolean;
}

const DocumentViewer: React.FC<DocumentViewerProps> =
    ({
        definition,
        safeMode,
     }) =>
    {
        return <div>
            <GutenbergBlockViewer
                blocks={definition.definition}
                safeMode={safeMode}
            />
        </div>;
    };

export default observer(DocumentViewer);
