import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { PublicEnvironmentStore } from './PublicEnvironmentStore';
import { observer } from 'mobx-react';
import { Router } from '../../../../../@Service/Router/Router';
import Feedback from '../Organization/Feedback/Feedback';
import SettingContext from '../../../../Domain/Setting/SettingContext';

@observer
export class PublicEnvironment extends BaseComponent<PublicEnvironmentStore>
{
    renderComponent(store: PublicEnvironmentStore): any
    {
        return <>
            <SettingContext.Provider
                value={store.settingStore}
            >
                <Router store={store.routerStore} />
                <Feedback store={store.feedbackStore} />
            </SettingContext.Provider>
        </>;
    }
}
