import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityField } from './EntityField';
import { EntityNode } from './EntityNode';
import { observable } from 'mobx';
import { SelectionNode } from './SelectionNode';
import { Entity } from './Entity';
import isEqual from '../../../@Util/IsEqual/isEqual';
import { EntityEvent } from './EntityEvent';
import { isEventCoveringFieldPath } from '../../../@Component/Domain/Entity/Selection/Api/isEventCoveringFieldPath';

@type('GroupNode')
export class GroupNode extends SelectionNode
{
    // ------------------- Persistent Properties --------------------

    @observable groupAlias: string;
    @reference(undefined, 'EntityNode') @observable.ref groupEntityNode: EntityNode;
    @reference(undefined, 'EntityField') @observable.ref groupEntityField: EntityField;
    @observable.ref groupEntityFieldRepresentation: any;
    @observable.ref rangeInterval: any;

    // ------------------------- Properties -------------------------

    @observable.ref range: any;

    // ------------------------ Constructor -------------------------

    constructor(groupAlias: string,
                groupEntityNode: EntityNode,
                groupEntityField: EntityField,
                groupEntityFieldRepresentation: any,
                range: any)
    {
        super();

        this.groupAlias = groupAlias;
        this.groupEntityNode = groupEntityNode;
        this.groupEntityField = groupEntityField;
        this.groupEntityFieldRepresentation = groupEntityFieldRepresentation;
        this.range = range;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAffectedBy(event: EntityEvent): boolean
    {
        return isEventCoveringFieldPath(
            event,
            this.groupEntityNode.entityPath()
                .field(this.groupEntityField)
        );
    }

    equals(node: GroupNode): boolean
    {
        return super.equals(node)
            && this.groupAlias === node.groupAlias
            && (this.groupEntityNode && this.groupEntityNode.alias) === (node.groupEntityNode && node.groupEntityNode.alias)
            && this.groupEntityField === node.groupEntityField
            && isEqual(this.groupEntityFieldRepresentation, node.groupEntityFieldRepresentation)
            && isEqual(this.range, node.range);
    }

    matches(entity: Entity): boolean
    {
        return true;
    }

    descriptor()
    {
        return {
            type: 'Group',
            groupAlias: this.groupAlias,
            groupEntityNodeAlias: this.groupEntityNode.alias,
            groupEntityFieldId: this.groupEntityField ? (this.groupEntityField.isStaticField() ? undefined : this.groupEntityField.id) : undefined,
            groupEntityFieldCode: this.groupEntityField ? (this.groupEntityField.isStaticField() ? this.groupEntityField.code : undefined) : undefined,
            groupEntityFieldRepresentation: this.groupEntityField ? this.groupEntityFieldRepresentation : undefined,
            rangeInterval: this.rangeInterval,
            range: this.range
        };
    }

    // ----------------------- Private logic ------------------------
}
