import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { RightPredicateEditorStore } from './RightPredicateEditorStore';
import { observer } from 'mobx-react';

@observer
export class RightPredicateEditor extends BaseComponent<RightPredicateEditorStore>
{
    renderComponent(store: RightPredicateEditorStore)
    {
        return <div />;
    }
}
