import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { PredicatePickerStore } from './PredicatePickerStore';
import { Grid, Theme, withStyles } from '@material-ui/core';
import Button from '../../../Generic/Button/Button';
import { Dialog } from '../../../Generic/Dialog/Dialog';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class PredicatePicker extends BaseComponent<PredicatePickerStore>
{
    renderLoader(store: PredicatePickerStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: PredicatePickerStore)
    {
        return <Grid container>
            <Grid item xs={12}>
                <Button store={store.editButtonStore} />
                {
                    store.predicate &&
                        <Button store={store.deleteButtonStore} />
                }
            </Grid>
            <Dialog store={store.modalDialogStore} />
        </Grid>;
    }
}

export default withStyles(styles)(PredicatePicker as any);
