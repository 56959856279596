import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { EntityQueryCacheService } from './EntityQueryCacheService';
import { EntityCacheService } from './EntityCacheService';
import { EntityCacheReferrerService } from './EntityCacheReferrerService';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../@Api/Model/Implementation/EntityRelationship';
import { action, IObservableArray } from 'mobx';
import { EntityQuery } from '../../Domain/Entity/Selection/Model/EntityQuery';
import { EntityCacheInformation } from './EntityCacheInformation';
import { EntityCacheReferrer } from './EntityCacheReferrer';
import { EntityRelationshipsCacheReferrerService } from './EntityRelationshipsCacheReferrerService';
import { consoleLog } from '../../../@Future/Util/Logging/consoleLog';

interface GarbageCollectionProps
{
    interval: number;
    queryCacheLifetime: number;
    unreferencedDataLifetime: number;
}

const ProductionProps: GarbageCollectionProps = {
    interval: 1000 * 120, // Every 120 seconds
    queryCacheLifetime: 1000 * 60 * 10, // 10 minutes
    unreferencedDataLifetime: 1000 * 60 * 10, // 10 minutes
};

const DebugProps: GarbageCollectionProps = {
    interval: 2000, // Every 2 seconds
    queryCacheLifetime: 1000, // 1 second
    unreferencedDataLifetime: 1000, // 1 second
};

const isLogged = false;

export class EntityGarbageCollectionService extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityQueryCacheService') entityQueryCacheService: EntityQueryCacheService;

    // ------------------------- Properties -------------------------

    cacheService: EntityCacheService;
    referrerService: EntityCacheReferrerService;
    relationshipsReferrerService: EntityRelationshipsCacheReferrerService;
    finalizationRegistry?: any;
    props: GarbageCollectionProps;
    isInDebugMode: boolean = false;

    // ------------------------ Constructor -------------------------

    constructor(cacheService: EntityCacheService,
                referrerService: EntityCacheReferrerService,
                relationshipsReferrerService: EntityRelationshipsCacheReferrerService)
    {
        super();

        this.cacheService = cacheService;
        this.referrerService = referrerService;
        this.relationshipsReferrerService = relationshipsReferrerService;
        this.startDebugModeIfNecessary();

        if (this.isInDebugMode)
        {
            this.props = DebugProps;
        }
        else
        {
            this.props = ProductionProps;
        }

        this.startGarbageCollectionProcess();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    startGarbageCollectionProcess()
    {
        setInterval(
            () =>
                this.garbageCollect(),
            this.props.interval);
    }

    @action.bound
    garbageCollect()
    {
        // console.log('garbage collection stats | # entities', this.cacheService.entityByUuid.size, ', # relationships:', this.cacheService.relationshipByUuid.size);
        this.garbageCollectQueries();
        this.garbageCollectEntities();
        this.garbageCollectRelationships();
    }

    disposeReferrer(referrer: EntityCacheReferrer)
    {
        this.referrerService.disposeReferrer(referrer);
        this.relationshipsReferrerService.disposeReferrer(referrer);
    }

    private garbageCollectQueries()
    {
        const now = new Date();
        let numberOfGarbageCollectedQueries = 0;

        this.entityQueryCacheService.baseQueryByHash
            .forEach(
                baseQueries =>
                    baseQueries.forEach(
                        baseQuery =>
                            baseQuery.queries
                                // the this.garbageCollectQueryIfNecessary call manipulates the queries, so we have to slice otherwise we will not garbage collect all queries
                                .slice()
                                .forEach(
                                    query =>
                                    {
                                        if (this.garbageCollectQueryIfNecessary(query, now))
                                        {
                                            numberOfGarbageCollectedQueries++;
                                        }
                                    }
                                )
                            )
            );

        if (isLogged)
        {
            consoleLog('garbage collected # queries:', numberOfGarbageCollectedQueries);
        }
    }

    private garbageCollectQueryIfNecessary(query: EntityQuery,
                                           now: Date)
    {
        if (this.shouldGarbageCollectQuery(query, now))
        {
            this.garbageCollectQuery(query);

            return true;
        }
        else
        {
            return false;
        }
    }

    private shouldGarbageCollectQuery(query: EntityQuery,
                                      now: Date): boolean
    {
        return query.cacheInformation.shouldGarbageCollect(now, this.props.queryCacheLifetime);
    }

    private garbageCollectQuery(query: EntityQuery)
    {
        this.entityQueryCacheService.disposeQuery(query);
    }

    private garbageCollectEntities()
    {
        const now = new Date();
        let numberOfGarbageCollectedEntities = 0;

        this.referrerService.cacheInformationByEntityUuid.forEach(
            (cacheInformation, uuid) =>
            {
                const entity = this.cacheService.getEntity(uuid);

                if (entity && this.garbageCollectEntityIfNecessary(entity, cacheInformation, now))
                {
                    numberOfGarbageCollectedEntities++;
                }
            });

        if (isLogged)
        {
            consoleLog('garbage collected # entities:', numberOfGarbageCollectedEntities);
        }
    }

    private garbageCollectRelationships()
    {
        const now = new Date();

        this.referrerService.cacheInformationByRelationshipUuid.forEach(
            (cacheInformation, uuid) =>
            {
                const relationship = this.cacheService.getRelationship(uuid);

                if (relationship)
                {
                    this.garbageCollectRelationshipIfNecessary(relationship, cacheInformation, now)
                }
            }
        );
    }

    private garbageCollectEntityIfNecessary(entity: Entity,
                                            cacheInformation: EntityCacheInformation,
                                            now: Date)
    {
        if (this.shouldGarbageCollectEntity(cacheInformation, now))
        {
            this.garbageCollectEntity(entity);

            return true;
        }
        else
        {
            return false;
        }
    }

    private shouldGarbageCollectEntity(cacheInformation: EntityCacheInformation,
                                       now: Date): boolean
    {
        return cacheInformation.shouldGarbageCollect(now, this.props.unreferencedDataLifetime);
    }

    private garbageCollectEntity(entity: Entity)
    {
        this.tagForDebuggingIfNecessary(entity);
        this.cacheService.discardEntity(entity);
        this.garbageCollectRelationshipsOfEntity(entity, true);
        this.garbageCollectRelationshipsOfEntity(entity, false);
        (entity.parentRelationships as IObservableArray).clear();
        (entity.childRelationships as IObservableArray).clear();
        (entity.values as IObservableArray).clear();

        if (entity.events)
        {
            (entity.events as IObservableArray).clear();
        }

        entity.deletedChildRelationships.clear();
        entity.deletedParentRelationships.clear();
    }

    private garbageCollectRelationshipsOfEntity(entity: Entity,
                                                isParent: boolean)
    {
        for (const relationship of entity.getRelationships(isParent))
        {
            this.garbageCollectRelationship(relationship);
        }
    }

    private garbageCollectRelationshipIfNecessary(relationship: EntityRelationship,
                                                  cacheInformation: EntityCacheInformation,
                                                  now: Date)
    {
        if (this.shouldGarbageCollectRelationship(cacheInformation, now))
        {
            this.garbageCollectRelationship(relationship);

            return true;
        }
        else
        {
            return false;
        }
    }

    private shouldGarbageCollectRelationship(cacheInformation: EntityCacheInformation,
                                             now: Date): boolean
    {
        return cacheInformation.shouldGarbageCollect(now, this.props.unreferencedDataLifetime);
    }

    private garbageCollectRelationship(relationship: EntityRelationship)
    {
        this.logGarbageCollectionOfRelationshipIfNecessary(relationship);
        this.cacheService.discardRelationship(relationship);
        (relationship.parentEntity.childRelationships as IObservableArray).remove(relationship);
        (relationship.childEntity.parentRelationships as IObservableArray).remove(relationship);
        relationship.parentEntity = undefined;
        relationship.childEntity = undefined;
    }

    private logGarbageCollectionOfRelationshipIfNecessary(relationship: EntityRelationship)
    {
        if (relationship.definition.code === 'Pack:Entities'
            && relationship.childEntity.entityType.code === 'ProductLine')
        {
            console.warn(
                '[PI202200838] garbage collecting relationship',
                relationship,
                relationship.parentEntity,
                relationship.childEntity
            );
        }
    }

    private startDebugModeIfNecessary()
    {
        if (EntityGarbageCollectionService.shouldStartDebugMode())
        {
            this.startDebugMode();
        }
    }

    private static shouldStartDebugMode()
    {
        return localStorage.getItem('GcDebugMode') === 'true';
    }

    private startDebugMode()
    {
        this.finalizationRegistry =
            new (window as any).FinalizationRegistry(
                key => console.log('garbage collected:', key)
            );
        (window as any).GcTag =
            function(id: string) {
                this.id = id;
            };
        this.isInDebugMode = true;
    }

    private tagForDebuggingIfNecessary(entity: Entity)
    {
        if (this.isInDebugMode)
        {
            this.tagForDebugging(entity);
        }
    }

    private tagForDebugging(entity: Entity)
    {
        (entity as any)._gcTag = new (window as any).GcTag(entity.id);
        const tagId = `${entity.id}-${entity.entityType.code}-${entity.name}`.replace(/(\r\n|\n|\r)/gm, '');
        this.finalizationRegistry.register(entity, tagId);
        console.log('tagged for GC:', tagId, entity.uuid);
    }

    // ----------------------- Private logic ------------------------
}
