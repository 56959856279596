import { observable } from 'mobx';
import Layout from '../Layout';
import ParameterDictionary from '../../Automation/Parameter/ParameterDictionary';
import Validation from '../../Automation/Validation/Validation';
import Dependency from '../../Automation/Parameter/Dependency';
import AutomationDependencyContext from '../../Automation/AutomationDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../LayoutDependencyContext';

export default class LayoutTemplate
{
    // ------------------------- Properties -------------------------

    @observable.ref parameters: ParameterDictionary;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(parameters: ParameterDictionary,
                layout: Layout)
    {
        this.parameters = parameters;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            parameters: this.parameters.toDescriptor(),
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const parameters =
            await ParameterDictionary.fromDescriptor(
                descriptor.parameters,
                dependencyContext);

        const layout =
            await getLayoutFromDescriptor(
                descriptor.layout,
                new LayoutDependencyContext(
                    parameters,
                    dependencyContext.deferredInitializer
                )
            );

        return new LayoutTemplate(
            parameters,
            layout
        );
    }

    // ----------------------- Private logic ------------------------
}
