import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import ValueFromEntityComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import FieldPathSelector from '../../../../../../../Path/FieldPathEditor/FieldPathSelector';
import { EntityContext } from '../../../../../../../@Model/EntityContext';
import { EntityFieldPath } from '../../../../../../../Path/@Model/EntityFieldPath';
import Link from '../../../../../../../../../../@Future/Component/Generic/Link/Link';
import useSwitch from '../../../../../../../../../../@Util/Switch/useSwitch';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import Parameter from '../../../../../../../../../../@Api/Automation/Parameter/Parameter';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import Dialog from '../../../../../../../../../../@Future/Component/Generic/Dialog/Dialog';
import { makeStyles } from '@material-ui/core';

export interface ValueFromEntityComputationEditorProps extends ComputationEditorProps<ValueType<any>, ValueFromEntityComputation>
{

}

const useStyles = makeStyles({
    dialogPaper: {
        height: '80vh',
        maxHeight: '80vh'
    }
});

const ValueFromEntityComputationEditor: React.FC<ValueFromEntityComputationEditorProps> =
    props =>
    {
        const classes = useStyles();
        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.value.entity.getType().type),
                [
                    props.value
                ]);

        const [ isPickerOpen, openPicker, closePicker ] = useSwitch(false);

        const setFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            props.value.fieldPath = fieldPath;
                            closePicker();
                        }),
                [
                    props.value,
                    closePicker
                ]);

        const fieldDialog =
            <span>
                <Link
                    onClick={openPicker}
                    highlighted
                >
                    {
                        props.value.fieldPath?.getPathName().toLowerCase() ||
                        <LocalizedText
                            code="Computation.ChooseField"
                            value="kies een veld"
                            lowerCase
                        />
                    }
                </Link>
                <Dialog
                    open={isPickerOpen}
                    width="xl"
                    classes={{
                        paper: classes.dialogPaper
                    }}
                    onClose={closePicker}
                >
                    <FieldPathSelector
                        context={context}
                        value={props.value.fieldPath}
                        onSelect={setFieldPath}
                    />
                </Dialog>
            </span>

        const setEntity =
            useCallback(
                (entity?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            if (props.value.entity)
                            {
                                props.value.entity = entity;
                            }
                        }),
                [
                    props.value
                ]);

        if (props.value.entity instanceof Parameter)
        {
            return <span>
                {props.value.entity.getName()}:&nbsp;
                {fieldDialog}
            </span>;
        }
        else
        {
            return <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <Input
                    label="Entiteit"
                    labelPosition="left"
                >
                    <ComputationBox>
                        <ComputationEditor
                            context={props.context}
                            value={props.value.entity}
                            onChange={setEntity}
                        />
                    </ComputationBox>
                </Input>
                <Input
                    label="Veld"
                    labelPosition="left"
                >
                    {fieldDialog}
                </Input>
            </ViewGroup>;
        }
    };

export default observer(ValueFromEntityComputationEditor);
