import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import useTypes from '../../../../../../../Type/Api/useTypes';
import RichtextEditor from '../../../../../../../../../../@Future/Component/Generic/Input/RichtextEditor/RichtextEditor';
import { CommitBuilder } from '../../../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import FieldView from '../../../../../../../Field/View/FieldView';
import { setValueByFieldInEntity } from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';

export interface ProductLineExtraDescriptionProps
{
    productLine: ProductLineEditorInterface;
    disabled?: boolean;
}

export const ProductLineExtraDescription: React.FC<ProductLineExtraDescriptionProps> = observer(
    ({
        productLine,
        disabled
    }) =>
    {
        const types = useTypes();
        const [ extraDescription, setExtraDescription ] =
            useState(
                productLine.productLine.getObjectValueByField<string>(
                    types.ProductLine.Field.ExtraDescription
                )
            );

        const onBlurExtraDescription =
            useCallback(
                () =>
                {
                    setValueByFieldInEntity(
                        productLine.productLine,
                        types.ProductLine.Field.ExtraDescription,
                        extraDescription,
                        productLine.commitContext
                    )
                    const builder = new CommitBuilder(productLine.commitContext)
                    builder.commit()
                        .then();
                },
                [
                    productLine,
                    types,
                    extraDescription
                ]
            );

        return disabled
            ? <FieldView
                entity={productLine.productLine}
                field={types.ProductLine.Field.ExtraDescription}
                commitContext={productLine.commitContext}
            />
            : <RichtextEditor
                value={extraDescription}
                onChange={setExtraDescription as any}
                onBlur={onBlurExtraDescription}
            />;
    }
);