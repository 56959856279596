import { observable } from 'mobx';
import { SelectionHint } from '../../Model/Implementation/SelectionHint';
import { SelectionNode } from '../../Model/Implementation/SelectionNode';
import { Entity } from '../../Model/Implementation/Entity';
import { EntityEvent } from '../../Model/Implementation/EntityEvent';
import { EntityNode } from '../../Model/Implementation/EntityNode';
import { GroupNode } from '../../Model/Implementation/GroupNode';
import { RootNode } from '../../Model/Implementation/RootNode';
import { ConstraintNode } from '../../Model/Implementation/ConstraintNode';

export class Selection
{
    // ------------------- Persistent Properties --------------------

    @observable id: number;
    @observable.shallow nodes: SelectionNode[];
    @observable.shallow hints: SelectionHint[];

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(
        nodes: SelectionNode[] = [],
        hints: SelectionHint[] = []
    )
    {
        this.nodes = nodes;
        this.hints = hints;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    addNodes(nodes: SelectionNode[]): Selection
    {
        nodes.forEach(
            node =>
               this.addNode(node));

        return this;
    }

    addNode(node: SelectionNode): Selection
    {
         this.nodes.push(node);

         return this;
    }

    hashCode()
    {
        return `${this.nodes.map(node => node.hashCode()).join(',')}`;
    }

    equals(selection: Selection)
    {
        return this.nodes.length === selection.nodes.length
            && this.nodes.every(
                (node, idx) =>
                    node.equals(selection.nodes[idx]));

        // return JSON.stringify(this.descriptor) === JSON.stringify(selection.descriptor);
    }

    matches(entity: Entity)
    {
        return this.nodes.every(
            node =>
                node.matches(entity));
    }

    isAffectedBy(event: EntityEvent)
    {
        return this.nodes.some(
            node =>
                node.isAffectedBy(event));
    }

    clone(): Selection
    {
        return new Selection(this.nodes.slice());
    }

    getDependencies(): EntityNode[]
    {
        return this.constraintNodes
            .flatMap(
                node =>
                    node.getDependencies()
            );
    }

    get rootNode(): EntityNode
    {
        return this.nodes
            .filter(node => (node instanceof RootNode))
            .map(node => (node as RootNode))
            .find(() => true);
    }

    get entityNodes(): EntityNode[]
    {
        return this.nodes
            .filter(node => (node instanceof EntityNode))
            .map(node => (node as EntityNode));
    }

    get constraintNodes(): ConstraintNode[]
    {
        return this.nodes
            .filter(node => (node instanceof ConstraintNode))
            .map(node => (node as ConstraintNode));
    }

    get groupNodes(): GroupNode[]
    {
        return this.nodes
            .filter(node => (node instanceof GroupNode))
            .map(node => (node as GroupNode));
    }

    // ----------------------- Private logic ------------------------
}
