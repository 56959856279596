import * as React from 'react';
import { ViewStackStore } from './ViewStackStore';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';

@observer
export class ViewStack<S extends ViewStackStore = ViewStackStore> extends BaseComponent<S>
{
    renderComponent(store: ViewStackStore): JSX.Element
    {
        return store.currentView
            && React.createElement(
                store.currentView.component,
                {
                    store: store.currentView.store
                });
    }
}
