/**
 * Class containing all global environmental variables accessible to TypeScript logic.
 */
export class GlobalEnvironment
{
    public static APP_ENDPOINT: string = process.env.REACT_APP_APP_ENDPOINT;
    public static API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT;
    public static HTML_VIEWER_ENDPOINT: string = process.env.REACT_APP_HTML_VIEWER_ENDPOINT;

    public static OAUTH_CLIENT_ID: string = process.env.REACT_APP_OAUTH_CLIENT_ID;
    public static OAUTH_SCOPE: string = process.env.REACT_APP_OAUTH_SCOPE;
    public static OAUTH_AUTH_URL: string = process.env.REACT_APP_OAUTH_AUTH_URL;
    public static OAUTH_TOKEN_URL: string = process.env.REACT_APP_OAUTH_TOKEN_URL;
    public static OAUTH_LOGOUT_URL: string = process.env.REACT_APP_OAUTH_LOGOUT_URL;
    public static OAUTH_LOGOUT_REDIRECT_URL: string = process.env.REACT_APP_OAUTH_LOGOUT_REDIRECT_URL;

    public static FUNCTION_IMAGE_PROXY: string = process.env.REACT_APP_FUNCTION_IMAGE_PROXY;
    public static FUNCTION_MSEXCHANGE_DOWNLOAD_ATTACHMENT: string = process.env.REACT_APP_FUNCTION_MSEXCHANGE_DOWNLOAD_ATTACHMENT;

    public static SENTRY_ENDPOINT: string = process.env.REACT_APP_SENTRY_ENDPOINT;

    public static AMPLITUDE_API_KEY: string = process.env.REACT_APP_AMPLITUDE_API_KEY;
    public static AMPLITUDE_SERVER_URL: string = process.env.REACT_APP_AMPLITUDE_SERVER_URL;

    public static INTERCOM_WORKSPACE_ID: string = process.env.REACT_APP_INTERCOM_WORKSPACE_ID;
}
