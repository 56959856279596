import Layout from '../../../../../@Api/Layout/Layout';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';

export default function isLayoutCompatibleWithParameterDictionary(layout: Layout,
                                                                  parameterDictionary: ParameterDictionary)
{
    return layout.getDependencies()
        .every(
            element =>
                parameterDictionary.hasParameter(element.parameter));
}
