import { computed, observable } from 'mobx';
import SourceField from './SourceField';
import ParameterDictionary from '../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';
import PrimitiveValueType from '../../../../../@Api/Automation/Value/Type/PrimitiveValueType';

export default class FileSpecification
{
    // ------------------------- Properties -------------------------

    @observable fields: SourceField[];
    @observable numberOfRecords: number;
    @observable.ref parameters: ParameterDictionary;

    // ------------------------ Constructor -------------------------

    constructor(
        fields: SourceField[],
        numberOfRecords: number
    )
    {
        this.fields = fields;
        this.numberOfRecords = numberOfRecords;
        this.parameters =
            new ParameterDictionary(
                fields.map(
                    field =>
                        new Parameter<PrimitiveValueType>(
                            field.id,
                            new PrimitiveValueType(
                                field.type
                            ),
                            false,
                            field.name
                        )
                )
            );
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get fieldById()
    {
        return new Map(
            this.fields.map(
                field => [
                    field.id,
                    field
                ]));
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new FileSpecification(
            descriptor.fields
                .map(
                    field =>
                        SourceField.fromDescriptor(field)),
            descriptor.numberOfRecords);
    }

    toDescriptor()
    {
        return {
            fields:
                this.fields.map(
                    field =>
                        field.toDescriptor()),
            numberOfRecords: this.numberOfRecords
        };
    }

    // ----------------------- Private logic ------------------------
}
