import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Parameter from '../../Parameter/Parameter';
import { EntityFieldPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import Validation from '../../Validation/Validation';
import localizeText from '../../../Localization/localizeText';

export default class GenerateNumberInEntityAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref entity: Computation<any, any>;
    @observable.ref fieldPath: EntityFieldPath;

    // ------------------------ Constructor -------------------------

    constructor(
        entity: Computation<any, any>,
        fieldPath: EntityFieldPath
    )
    {
        super();

        this.entity = entity;
        this.fieldPath = fieldPath;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        return localizeText(
            'Action.GenerateNumber',
            'Genereer nummer voor record'
        );
    }

    validate(): Validation[]
    {
        const validations: Validation[] = [];

        if (!this.entity
            || !this.fieldPath)
        {
            validations.push(
                new Validation(
                    'Error',
                    localizeText(
                        'NoEntityFieldSelected',
                        'Je hebt nog geen veld gekozen om te muteren.'
                    )
                )
            );
        }

        return validations;
    }

    isAsync(): boolean
    {
        return this.entity.isAsync();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'GenerateNumberInEntity';
        descriptor.entity = this.entity.toDescriptor();
        descriptor.fieldPath = this.fieldPath.descriptor;
    }

    getDependencies(): Dependency[]
    {
        if (this.entity instanceof Parameter)
        {
            return [
                new Dependency(
                    this.entity,
                    this.fieldPath
                )
            ];
        }
        else
        {
            return [];
        }
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new GenerateNumberInEntityAction(
            await getComputationFromDescriptor(
                descriptor.entity,
                dependencyContext
            ),
            EntityFieldPath.construct(descriptor.fieldPath)
        );
    }

    // ----------------------- Private logic ------------------------
}
