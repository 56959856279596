import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import Tab from './Tab';
import ValueType from '../../../../Automation/Value/Type/ValueType';
import TabSource from './TabSource';

export default class StaticTabSource extends TabSource
{
    // ------------------------- Properties -------------------------

    @observable.shallow tabs: Tab[];

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                tabs: Tab[])
    {
        super(id);

        this.tabs = tabs;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTabs(): Tab[]
    {
        return this.tabs;
    }

    validate(): Validation[]
    {
        return this.tabs
            .map(
                tab =>
                    tab.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    getDependencies(): Dependency[]
    {
        return this.tabs
            .map(
                tab =>
                    tab.getDependencies())
            .reduce((a, b) => a.concat(b), []);
    }

    toInternalDescriptor(descriptor: any)
    {
        descriptor.type = 'Static';
        descriptor.tabs = this.tabs.map(tab => tab.toDescriptor());
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext,
                                valueType: ValueType<any>)
    {
        return new StaticTabSource(
            descriptor.id,
            await Promise.all(
                (descriptor.tabs as any[]).map(
                    tab =>
                        Tab.fromDescriptor(
                            tab,
                            dependencyContext,
                            valueType))));
    }

    // ----------------------- Private logic ------------------------
}
