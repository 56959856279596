import { injectWithQualifier } from '../../@Util/DependencyInjection/Injection/DependencyInjection';
import { WelcomeController } from '../../@Api/Controller/Directory/WelcomeController';
import { BaseStore } from '../../@Framework/Store/BaseStore';
import { AuthenticationManager } from '../Authentication/AuthenticationManager';
import { action, observable } from 'mobx';
import { WelcomePackage } from '../../@Api/Model/Implementation/WelcomePackage';
import { LanguagePackage } from '../../@Api/Model/Implementation/LanguagePackage';
import { SettingPackage } from '../../@Api/Model/Implementation/SettingPackage';
import { User } from '../../@Api/Model/Implementation/User';
import { EntityMetadata } from '../../@Api/Model/Implementation/EntityMetadata';
import { StatusController } from '../../@Api/Controller/Directory/StatusController';
import { FeedbackStore } from '../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';
import { Entity } from '../../@Api/Model/Implementation/Entity';
import { PackMetadata } from '../../@Api/Model/Implementation/PackMetadata';
import { Currency } from '../../@Api/Localization/Currency';

export type Callback = () => Promise<any>;

export class WelcomeStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('StatusController') statusController: StatusController;
    @injectWithQualifier('WelcomeController') welcomeController: WelcomeController;
    @injectWithQualifier('AuthenticationManager') authenticationManager: AuthenticationManager;
    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;

    // ------------------------- Properties -------------------------

    @observable.shallow onUpdateCallbacks: Callback[];
    @observable.ref welcomePackage: WelcomePackage;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super();

        this.onUpdateCallbacks = observable.array<Callback>([], { deep: false });
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.isInitialized
            && this.authenticationManager.isAuthenticated)
        {
            return this.statusController.getStatus()
                .then(
                    () =>
                        this.initializePacks());
        }
        else
        {
            return this.initializePacks();
        }
    }

    async initializePacks()
    {
        const [ languagePackage, settingPackage, metadata, user, team, contract, currencies ] = await Promise.all([
            this.welcomeController.getLanguagePackage(),
            this.welcomeController.getSettingPackage(),
            this.fetchMetadata(),
            this.welcomeController.getUser(),
            this.welcomeController.getTeam(),
            this.welcomeController.getContract(),
            this.welcomeController.getCurrencies()
        ]);

        return this.registerWelcomePackage(
            languagePackage,
            settingPackage,
            metadata,
            user,
            team,
            contract,
            currencies
        );
    }

    async fetchMetadata()
    {
        const packMetadataStates = await this.welcomeController.getPackMetadataStates();
        const packMetadatas =
            await Promise.all<PackMetadata>(
                packMetadataStates.map(
                    packMetadataState =>
                        this.welcomeController.getPackMetadata(
                            packMetadataState.packId,
                            packMetadataState.state)));

        const metadata = new EntityMetadata();
        metadata.types = [];
        metadata.fields = [];
        metadata.relationshipDefinitions = [];
        metadata.entityByTypeId = {};
        metadata.entityByFieldId = {};
        metadata.entityByRelationshipDefinitionId = {};

        for (const packMetadata of packMetadatas)
        {
            metadata.types.push(
                ...packMetadata.typeRepository.metadataList);

            Object.assign(
                metadata.entityByTypeId,
                packMetadata.typeRepository.entityByMetadataId);

            metadata.fields.push(
                ...packMetadata.fieldRepository.metadataList);

            Object.assign(
                metadata.entityByFieldId,
                packMetadata.fieldRepository.entityByMetadataId);

            metadata.relationshipDefinitions.push(
                ...packMetadata.relationshipDefinitionRepository.metadataList);

            Object.assign(
                metadata.entityByRelationshipDefinitionId,
                packMetadata.relationshipDefinitionRepository.entityByMetadataId);
        }

        return metadata;
    }

    async fetchMetadataTheOldWay()
    {
        const metadataState = await this.welcomeController.getMetadataState();

        return this.welcomeController.getMetadata(metadataState, true);
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    onUpdate(callback: Callback)
    {
        this.onUpdateCallbacks.push(callback);
    }

    @action.bound
    async registerWelcomePackage(
        languagePackage: LanguagePackage,
        settingPackage: SettingPackage,
        metadata: EntityMetadata,
        user: User,
        team: Entity,
        contract: Entity,
        currencies: Currency[]
    )
    {
        this.feedbackStore.clear();

        const welcomePackage = new WelcomePackage();
        welcomePackage.languagePackage = languagePackage;
        welcomePackage.settingPackage = settingPackage;
        welcomePackage.metadata = metadata;
        welcomePackage.user = user;
        welcomePackage.team = team;
        welcomePackage.contract = contract;
        welcomePackage.currencies = currencies;

        // Temporary to delete all old cache data
        if (window.localStorage)
        {
            window.localStorage.removeItem('metadata');
            window.localStorage.removeItem('metadataState');
        }

        if (this.authenticationManager.isAuthenticated
            && !welcomePackage.user)
        {
            console.error('bad welcome package');

            return this.authenticationManager.deleteAuthentication()
                .then(
                    () =>
                        this.authenticationManager.navigateToLogin(
                            undefined,
                            undefined,
                            undefined,
                            true))
                .then(
                    () =>
                        Promise.reject());
        }

        const isInitial = this.welcomePackage === undefined;

        this.welcomePackage = welcomePackage;

        if (!isInitial)
        {
            for (const onUpdate of this.onUpdateCallbacks)
            {
                await onUpdate();
            }
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
