import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ChartStore } from '../ChartStore';
import { Theme, withStyles } from '@material-ui/core';
import { Bar, BarChart as ReBarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class BarChart extends BaseComponent<ChartStore>
{
    // renderComponent(store: ChartStore): JSX.Element
    // {
    //     const keys = Array.from(store.data.keys());
    //     const values = Array.from(store.data.values());
    //     const total = 0;
    //     const maxValue = Math.max(...values);
    //     const goal = maxValue * 1.3; // add some space in the graph
    //     const stages: any[] =
    //         values
    //             .map(
    //                 (child, idx) =>
    //                     ({
    //                         title: keys[idx],
    //                         goal: goal,
    //                         actual: child,
    //                         actualLabel: child,
    //                         revenue: child,
    //                         revLabel: '' // store.fieldPath.field.getName(store.fieldPath.path.entityType, store.entityTypeStore)
    //                     }));
    //
    //     return <div
    //         style={{
    //             height: 150,
    //             padding: '10px 0'
    //         }}
    //     >
    //         <PipelineFunnel
    //             data={stages}
    //             outerValueIdentifier="revenue"
    //             outerSecondaryValueIdentifier="revLabel"
    //             innerValueIdentifier="actual"
    //             innerValueLabel="actualLabel"
    //             compareValueIdentifier="goal"
    //             outerLabelIdentifier="goalLabel"
    //         />
    //     </div>;
    // }

    renderComponent(store: ChartStore): JSX.Element
    {
        const data: any = {
            name: ''
        };

        store.data.forEach(
            (value, key) =>
            {
                data[key] = value;
            });

        return <ResponsiveContainer
            width="100%"
        >
            <ReBarChart
                data={[ data ]}
                layout="vertical"
            >
                <XAxis
                    type="number"
                />
                <YAxis
                    type="category"
                    dataKey="name"
                    hide
                />
                <Tooltip />
                {
                    store.data
                        .map((value, idx) =>
                            <Bar
                                key={value.group.toString()}
                                dataKey={value.group.toString()}
                                onClick={(() => store.toggleIndexSelection(idx)) as any}
                                stackId="a"
                                fill={store.colorByIdx(idx)}
                            />)
                }
            </ReBarChart>
        </ResponsiveContainer>;
    }
}

export default withStyles(styles)(BarChart as any);
