import * as React from 'react';
import { observer } from 'mobx-react';
import { ComputationWidgetStore } from './ComputationWidgetStore';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';


@observer
export class ComputationWidget extends BaseComponent<ComputationWidgetStore>
{
    renderComponent(store: ComputationWidgetStore)
    {
        return <span
            ref={(node: any) => { store.setReference(node); }}
            contentEditable={false}
            spellCheck={false}
            style={{
                textDecoration: 'underline',
                textDecorationColor: '#4A96DA',
                textDecorationStyle: 'dotted',
                cursor: 'pointer',
                background: store.isMouseOver ? '#4A96DAAA' : 'transparent',
                position: 'relative'
            }}
            onMouseEnter={() => store.setMouseOver(true)}
            onMouseLeave={() => store.setMouseOver(false)}
            onClick={store.onWidgetClicked}
        >
            <span
                key="computationTooltip"
                style={{
                    visibility: 'hidden', // store.isMouseOver ? 'visible' : 'hidden',
                    zIndex: 1,
                    position: 'absolute',
                    width: '150px',
                    height: '50px',
                    background: '#fff',
                    border: '1px solid rgb(74, 150, 218)',
                    padding: '5px',
                    fontSize: '12px',
                    borderRadius: '5px',
                    bottom: '100%',
                    left: '50%',
                    marginLeft: '-75px'
                }}
            >
                {store.description}
            </span>
            <span
                key="computationValue"
            >
                {
                    store.showValue &&
                        store.value
                }
                {
                    !store.showValue &&
                        store.description
                        // store.computationType.name()
                }
            </span>
        </span>;
    }
}
