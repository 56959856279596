import React from 'react';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import styles from './Header.module.scss';
import useResultName from '../../../../Dataset/Segment/Api/useResultName';
import useResultValue from '../../../../Dataset/Segment/Api/useResultValue';
import useResultCount from '../../../../Dataset/Segment/Api/useResultCount';
import { DataObjectRepresentation } from '../../../../../DataObject/Model/DataObjectRepresentation';
import Segment from '../../../../Dataset/Model/Segment';

export interface ListProps
{
    entityType: EntityType;
    idx: number;
    segment: Segment;
    result: EntitySelectionAggregateResult;
    firstColumn?: boolean;
    lastColumn?: boolean;
}

const Header: React.FC<ListProps> =
    props =>
    {
        const name = useResultName(props.segment, props.result, props.idx);
        const value = useResultValue(props.result);
        const count = useResultCount(props.result);

        const currencyValue =
            useComputed(
                () =>
                    value
                        ?
                            value.toString(
                                new DataObjectRepresentation({
                                    isCompact: true
                                }))
                        :
                            undefined,
                [
                    value
                ]);

        return <div
            className={styles.root}
        >
            <div
                className={styles.name}
            >
                {name}
            </div>
            <div
                className={styles.aggregates}
            >
                {currencyValue} {props.result.aggregates.length > 1 ? `· ${count} ${props.entityType.getName(true)}` : undefined}
            </div>
        </div>;
    };

export default observer(Header);
