import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import styles from './Dataset.module.scss';
import { FavoriteCallback } from './Favorite/FavoriteCallback';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import DragHandle from '../../DragAndDrop/DragHandle/DragHandle';
import { default as DatasetModel } from './Model/Dataset';
import Content from './Content/Content';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import useAlteredDataset from './Api/useAlteredDataset';
import ErrorBoundary from '../../../Error/ErrorBoundary';
import ErrorDialog from '../../../Error/ErrorDialog';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import DeleteButton from '../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';

export interface DatasetProps
{
    id: string;
    name: string;
    dataset: DatasetModel;
    disableExpansionMemory?: boolean;
    onDelete?: () => void;
    onClose?: () => void;
    hideConstructorButton?: boolean;
    headerAppendix?: React.ReactNode;
    onFavorite?: FavoriteCallback;
    hideCard?: boolean;
    whiteCard?: boolean;
    expanded?: boolean;
    autoReload?: boolean;
    entity?: Entity;
    highlightedEntity?: Entity;
    onOpenEntity?: (entity: Entity) => void;
    showMenuButtonInItems?: boolean;
}

const Dataset: React.FC<DatasetProps> =
    props =>
    {
        const isValid =
            useComputed(
                () =>
                    props.dataset.isValid(),
                [
                    props.dataset
                ]);

        const renderError =
            useCallback(
                (error, info, retry) =>
                    <DragHandle>
                        <ErrorDialog
                            error={error}
                            errorInfo={info}
                            onClose={retry}
                        />
                    </DragHandle>,
                []);

        const content =
            isValid
                ?
                    <ErrorBoundary
                        renderError={renderError}
                    >
                        <Content
                            {...props}
                        />
                    </ErrorBoundary>
                :
                    <DragHandle>
                        <CardInset>
                            <Centered
                                horizontal
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={10}
                                >
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code="Dataset.Invalid"
                                            value="Deze dataset is ongeldig."
                                        />
                                    </ViewGroupItem>
                                    {
                                        props.onDelete &&
                                            <ViewGroupItem>
                                                <DeleteButton
                                                    onClick={props.onDelete}
                                                />
                                            </ViewGroupItem>
                                    }
                                </ViewGroup>
                            </Centered>
                        </CardInset>
                    </DragHandle>;

        useAlteredDataset(props.dataset);

        if (props.hideCard)
        {
            return content;
        }
        else
        {
            return <Card
                classes={{
                    root: classNames(styles.card, props.whiteCard && styles.white)
                }}
            >
                {content}
            </Card>;
        }
    };

export default observer(Dataset);
