import { Entity } from '../../../../../Model/Implementation/Entity';
import { EntityPath } from '../../../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { CommitContext } from '../../CommitContext';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';

export function constructEntityFromPath(
    entity: Entity,
    path: EntityPath,
    commitContext?: CommitContext
)
{
    if (commitContext)
    {
        return path.constructEntity(
            entity,
            undefined,
            commitContext
        );
    }
    else
    {
        return createTransactionalModel(
            path.constructEntity(
                createTransactionalModel(
                    entity
                )
            )
        );
    }
}
