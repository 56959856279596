import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import FieldView from '../../../../../../../Field/View/FieldView';
import Input from '../../../../../../../Input/Input';
import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import useTypes from '../../../../../../../Type/Api/useTypes';

export interface ProductLineDescriptionProps
{
    productLine: ProductLineEditorInterface;
    disabled: boolean;
    autoFocusOnNewProductLine?: boolean;
    showLabel?: boolean;
}

export const ProductLineDescription: React.FC<ProductLineDescriptionProps> = observer(
    ({
         productLine,
         autoFocusOnNewProductLine,
         disabled,
         showLabel
    }) =>
    {
        const types = useTypes();

        const autoFocus =
            useMemo(
                () =>
                    autoFocusOnNewProductLine && productLine.productLine.isNew(),
                [
                    autoFocusOnNewProductLine,
                    productLine
                ]
            );

        return productLine.isProductAndQuantityAndPriceDisabled || productLine.isStrikethrough
            ?
                <FieldView
                    entity={productLine.productLine}
                    field={types.ProductLine.Field.Description}
                    commitContext={productLine.commitContext}
                />
            :
                <Input
                    entity={productLine.productLine}
                    field={types.ProductLine.RelationshipDefinition.Product}
                    labelPosition={ showLabel ? 'left' : 'none'}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    doAutocommit={false}
                    commitContext={productLine.commitContext}
                    contentIfReadOnly={productLine.description.description}
                    onChange={productLine.setProduct}
                    relationshipSelectboxProps={{
                        wrap: true
                    }}
                />

    }
);