import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { Field } from '../../Model/Field';
import { runInAction } from 'mobx';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import resolveInputFromFieldPath from '../../../../../../Multiplayer/Model/Input/Api/resolveInputFromFieldPath';
import isHiddenField from '../../../../../../../../@Api/Metadata/Input/isHiddenField';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const IsVisibleInDetailsEditor: React.FC<FieldItemProps> =
    props =>
    {
        const [ hiddenFields, setHiddenFields ] = useSetting(SettingSource.Organization, Setting.Metadata.HiddenFields);
        const input =
            useComputed(
                () =>
                    props.field.fieldPath &&
                    resolveInputFromFieldPath(props.field.fieldPath),
                [
                    props.field
                ]);
        const isVisible =
            useComputed(
                () =>
                {
                    if (props.field.isEditable)
                    {
                        return props.field.isVisibleInDetails;
                    }
                    else
                    {
                        if (props.field.isVisibleInDetails)
                        {
                            return !isHiddenField(input);
                        }
                        else
                        {
                            return false;
                        }
                    }
                },
                [
                    props.field,
                    hiddenFields,
                    input
                ]);
        const onChange =
            useCallback(
                (isVisible: boolean) =>
                {
                    if (props.field.isEditable)
                    {
                        runInAction(
                            () =>
                                props.field.isVisibleInDetails = isVisible
                        );
                    }
                    else
                    {
                        const descriptor = Object.assign({}, hiddenFields);
                        descriptor[input.id()] = !isVisible;

                        return setHiddenFields(descriptor);
                    }
                },
                [
                    hiddenFields,
                    setHiddenFields,
                    input
                ]);

        if (input)
        {
            return <Switch
                checked={isVisible}
                onToggle={onChange}
                disabled={!(props.field.isEditable || props.field.isVisibleInDetails)}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(IsVisibleInDetailsEditor);
