import React, { useContext, useMemo } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import View from '../../../View/View';
import { default as ViewModel } from '../../../View/Model/View';
import getViewParameters from '../../../View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../View/Model/ViewParams';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import LinkRelatedEntityButton from './LinkRelatedEntity/LinkRelatedEntityButton';

export interface RelatedEntityViewProps
{
    entity: Entity;
    path: EntityPath;
    view?: ViewModel;
    hideLinkButton?: boolean;
}

const RelatedEntityView: React.FC<RelatedEntityViewProps> =
    props =>
    {
        const localStorageKey =
            useMemo(
                () =>
                    `RelatedEntityView.RootEntityTypeId:${props.entity.entityType.id}.RelatedEntityTypeId:${props.path.entityType.id}`,
                [
                    props.entity,
                    props.path,
                ]
            );

        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(props.path.entityType),
                [
                    props.path
                ]);
        const filter =
            useMemo(
                () => ({
                    name: props.entity.entityType.getName(),
                    predicate:
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                viewParameters.getParameterById(ViewParams.Entity),
                                props.path.reverse().field()),
                            Comparator.Equals,
                            new EntityValue(props.entity))
                }),
                [
                    props.path,
                    viewParameters,
                    props.entity
                ]);
        const currentUserStore = useContext(CurrentUserContext);
        const canLink =
            useMemo(
                () =>
                    props.path.relationshipDefinition !== undefined
                    && currentUserStore.rightProfile.canCreateRelationshipType(props.path.relationshipDefinition)
                    && !props.hideLinkButton,
                [
                    props.path.relationshipDefinition,
                    currentUserStore.rightProfile,
                    props.hideLinkButton
                ]
            );

        return <View
            key={props.path.id}
            entityType={props.path.entityType}
            view={props.view}
            filter={filter}
            applyFilterOnAllViews
            headerAppendix={
                canLink
                    ? <LinkRelatedEntityButton
                        entity={props.entity}
                        relationshipDefinition={props.path.lastJoinNode.relationshipDefinition}
                        parent={props.path.lastJoinNode.isParent}
                    />
                    : undefined
            }
            settingStorageKey={localStorageKey}
        />;
    };

export default observer(RelatedEntityView);
