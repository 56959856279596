import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import EntityValueType from '../../Value/Type/EntityValueType';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import EntityValue from '../../Value/EntityValue';
import PrimitiveValue from '../../Value/PrimitiveValue';
import EmptyValue from '../../Value/EmptyValue';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../@Component/Domain/Entity/Type/EntityTypeStore';

export default class EntityTypeFromEntityTypeEntityComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref entity: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(entity: Computation<any, any>)
    {
        super();

        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            DataObject.getTypeById('EntityType')
        );
    }

    isAsync(): boolean
    {
        return this.entity.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value =
            await safelyApplyFunction(
                this.entity,
                context
            );

        return this.getEntityTypeFromValue(value);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const value =
            safelySynchronousApplyFunction(
                this.entity,
                context
            );

        return this.getEntityTypeFromValue(value);
    }

    private getEntityTypeFromValue(value: Value<any, any>)
    {
        if (value instanceof EntityValue
            && value.value.entityType.isA(getTypes().EntityType.Type))
        {
            const entityType = loadModuleDirectly(EntityTypeStore).getTypeByEntityId(value.value.id);

            if (entityType)
            {
                return new PrimitiveValue(
                    DataObject.constructFromTypeIdAndValue(
                        'EntityType',
                        entityType
                    )
                );
            }
            else
            {
                return EmptyValue.instance;
            }
        }
        else
        {
            throw new Error(`Expected entity type entity, but got: ${value.getId()}`);
        }
    }

    getName(): string
    {
        return `${localizeText('Computation.EntityTypeFromEntityTypeEntity', 'Entiteitstype uit entiteitstype entiteit')}: ${this.entity.getName()}`;
    }

    validate(): Validation[]
    {
        const type = this.entity.getType();

        if (!(type instanceof EntityValueType && type.type.isA(getTypes().EntityType.Type)))
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.NoValidEntitySelected', 'Er is geen geldige entiteit geselecteerd.')
                )
            ];
        }
        else
        {
            return [];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'EntityTypeFromEntityTypeEntity';
        descriptor.entity = this.entity.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.entity.getDependencies();
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        return new EntityTypeFromEntityTypeEntityComputation(
            await getComputationFromDescriptor(
                descriptor.entity,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
