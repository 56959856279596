import localizeText from '../../../../../../@Api/Localization/localizeText';

export function getDatePeriodTypeOptions()
{
    return [
        {
            id: 'Minutes',
            value: 'Minutes',
            label: localizeText('Period.Minutes', 'Minuten'),
        },
        {
            id: 'Hours',
            value: 'Hours',
            label: localizeText('Period.Hours', 'Uren'),
        },
        {
            id: 'Days',
            value: 'Days',
            label: localizeText('Period.Days', 'Dagen'),
        },
        {
            id: 'Weeks',
            value: 'Weeks',
            label: localizeText('Period.Weeks', 'Weken'),
        },
        {
            id: 'Months',
            value: 'Months',
            label: localizeText('Period.Months', 'Maanden'),
        },
        {
            id: 'Quarters',
            value: 'Quarters',
            label: localizeText('Period.Quarters', 'Kwartalen'),
        },
        {
            id: 'Years',
            value: 'Years',
            label: localizeText('Period.Years', 'Jaren'),
        },
    ];
}
