import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import useResults from '../../../../Entity/Selection/Hooks/useResults';
import Tab from '../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import TabBar from '../../../../../../@Future/Component/Generic/TabBar/TabBar';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import PipelineEditor from './PipelineEditor/PipelineEditor';
import Constructor from '../../../../Entity/Constructor/Constructor';
import MenuButton from '../../../../Entity/Item/MenuButton/MenuButton';
import usePipelineRelationshipDefinition from '../../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePipelineRelationshipDefinition';
import getDefaultPipelineName from '../../../../../../@Api/Entity/Bespoke/Datastore/Phase/getDefaultPipelineName';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface PipelinesEditorProps
{
    entityType: EntityType;
}

const PipelinesEditor: React.FC<PipelinesEditorProps> =
    ({
         entityType
     }) =>
    {
        const types = useTypes();

        const pipelines = useResults(
            types.Pipeline.Type,
            (builder, rootPath) =>
                builder.where(
                    cb =>
                        cb.relatedToEntity(
                            rootPath
                                .joinTo(
                                    types.EntityType.RelationshipDefinition.Pipelines,
                                    true),
                            entityType.entity
                        )
                ),
            [
                types,
                entityType
            ]
        );

        const [ tab, setTab ] = useState<string | Entity>('default');

        const newEntity =
            useMemo(
                () =>
                {
                    if (tab === 'new')
                    {
                        const pipeline =
                            createTransactionalModel(
                                new Entity(types.Pipeline.Type)
                                    .initialize());

                        pipeline.updateRelationship(
                            true,
                            types.EntityType.RelationshipDefinition.Pipelines,
                            createTransactionalModel(entityType.entity));

                        return pipeline;
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    tab,
                    types,
                    entityType
                ]);

        const gotoDefaultPipeline =
            useCallback(
                () =>
                    setTab('default'),
                [
                    setTab
                ]);

        const pipelineRelationshipDefinition = usePipelineRelationshipDefinition(entityType);
        const defaultPipelineName =
            useComputed(
                () =>
                    getDefaultPipelineName(entityType),
                [
                    entityType
                ]);

        if (pipelineRelationshipDefinition)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={0}
            >
                <ViewGroupItem>
                    <CardInset>
                        <LocalizedText
                            code="Pipelines.Help"
                            value="Het is hier mogelijk om pijplijnen en fasen te beheren. Deze pijplijnen komen terug in de records, widgets en overzichten."
                        />
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <TabBar
                        value={tab}
                        appendix={
                            typeof tab !== 'string' &&
                                <MenuButton
                                    entity={tab}
                                    onDelete={gotoDefaultPipeline}
                                />
                        }
                    >
                        <Tab
                            value="default"
                            onClick={setTab}
                        >
                            {defaultPipelineName}
                        </Tab>
                        {
                            pipelines?.map(
                                pipeline =>
                                    <Tab
                                        key={pipeline.uuid}
                                        value={pipeline}
                                        onClick={setTab}
                                    >
                                        {pipeline.name}
                                    </Tab>)
                        }
                        <Tab
                            value="new"
                            onClick={setTab}
                        >
                            + {types.Pipeline.Type.getName()}
                        </Tab>
                    </TabBar>
                </ViewGroupItem>
                {
                    tab === 'new' &&
                        <ViewGroupItem>
                            <CardInset>
                                <Constructor
                                    autoFocus
                                    entity={newEntity}
                                    onSave={setTab}
                                    openable={false}
                                />
                            </CardInset>
                        </ViewGroupItem>
                }
                {
                    tab !== 'new' &&
                        <ViewGroupItem>
                            <PipelineEditor
                                entityType={entityType}
                                pipeline={typeof tab === 'string' ? undefined : tab}
                                numberOfPipelines={pipelines ? pipelines.length : 0}
                            />
                        </ViewGroupItem>
                }
            </ViewGroup>;
        }
        else
        {
            return <PipelineEditor
                entityType={entityType}
                pipeline={typeof tab === 'string' ? undefined : tab}
                numberOfPipelines={0}
            />;
        }
    };

export default observer(PipelinesEditor);
