import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { computed, isObservableArray, observable, runInAction } from 'mobx';
import { Selection } from '../../../../../@Api/Selection/Model/Selection';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityPathRootNode } from '../../Path/@Model/Node/EntityPathRootNode';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityNode } from '../../../../../@Api/Model/Implementation/EntityNode';
import { EntityConstraintBuilder } from './EntityConstraintBuilder';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';
import { EntitySelectionRange } from '../Model/EntitySelectionRange';
import { GroupNode } from '../../../../../@Api/Model/Implementation/GroupNode';
import { EntitySelectionAggregate } from '../Model/EntitySelectionAggregate';
import { EntitySelectionOrdering } from '../Model/EntitySelectionOrdering';
import { Aggregate } from '../../../DataObject/Model/Aggregate';
import { EntitySelectionAggregateResult } from '../Model/EntitySelectionAggregateResult';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { DataDescriptor } from '../../../DataObject/Model/DataDescriptor';
import { EntitySelectionResult } from '../Model/EntitySelectionResult';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import isArray from 'lodash/isArray';
import { DataObjectRepresentation } from '../../../DataObject/Model/DataObjectRepresentation';
import { CompositeConstraintNode, LogicalOperator } from '../../../../../@Api/Model/Implementation/CompositeConstraintNode';
import { EntityRelationship } from '../../../../../@Api/Model/Implementation/EntityRelationship';
import { EntityCacheService } from '../../../../Service/Entity/EntityCacheService';
import { EntityQuery } from '../Model/EntityQuery';
import { consoleLog } from '../../../../../@Future/Util/Logging/consoleLog';
import markPathsAsFetchedInEntities from '../Api/markPathsAsFetchedInEntities';
import { SelectionHint } from '../../../../../@Api/Model/Implementation/SelectionHint';
import { EntityQueryCacheService } from '../../../../Service/Entity/EntityQueryCacheService';
import Computation from '../../../../../@Api/Automation/Function/Computation/Computation';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import uuid from '../../../../../@Util/Id/uuid';
import { EntityCacheReferrer } from '../../../../Service/Entity/EntityCacheReferrer';
import { getInheritedNameFieldWithFallback } from '../../../../../@Api/Metadata/Field/getInheritedNameFieldWithFallback';
import { EntitySelectionListResult } from '../Model/EntitySelectionListResult';
import { getSelectionDescriptor } from '../../../../../@Api/Selection/Api/getSelectionDescriptor';
import { getSelectionFromPath } from '../../../../../@Api/Selection/Api/getSelectionFromPath';
import { DataObjectType } from '../../../../../@Api/Model/Implementation/EntityField';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../DataObject/Model/Comparator';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';

type EntityCallback = (entity: Entity) => void;

export class EntitySelectionBuilder
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityCacheService') entityCacheService: EntityCacheService;
    @injectWithQualifier('EntityQueryCacheService') entityQueryCacheService: EntityQueryCacheService;

    // ------------------------- Properties -------------------------

    @observable.ref entityType: EntityType;
    @observable.ref selection: Selection;
    @observable.shallow ordering = observable.array<EntitySelectionOrdering>();
    @observable.shallow aggregates = observable.array<EntitySelectionAggregate>();
    @observable offsetNumber: number;
    @observable limitNumber: number;
    @observable languageCode: string;
    @observable logName: string;
    cacheReferrer: EntityCacheReferrer;
    cachedQueries: EntityQuery[];

    // ------------------------ Constructor -------------------------

    constructor(entityType: EntityType, selection?: Selection)
    {
        this.entityType = entityType;
        this.selection =
            selection ||
            getSelectionFromPath(
                new EntityPath([
                    new EntityPathRootNode(entityType)
                ])
            );
        this.offsetNumber = 0;
        this.limitNumber = 50;
        this.cacheReferrer = `Query.${uuid()}`;
        this.cachedQueries = [];
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed get rootPath(): EntityPath
    {
        return EntityPath.root(this.entityType);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    joinAndGetByAlias(path: EntityPath,
                      alias: string)
    {
        if (!this.rootPath.rootEntityType.isA(path.rootEntityType))
        {
            throw new Error(`Cannot join path ${path.code} to ${this.rootPath.rootEntityType.code}`);
        }

        if (!path.rootEntityType.isA(this.rootPath.rootEntityType))
        {
            path = this.rootPath.castTo(path.rootEntityType)
                .join(path);
        }

        let joinSelection = getSelectionFromPath(path);

        for (const nodeInJoinSelection of joinSelection.entityNodes)
        {
            if (nodeInJoinSelection.alias === path.id)
            {
                nodeInJoinSelection.alias = alias;
            }
        }

        let joinEntityNodes = joinSelection.entityNodes.filter(entityNode => this.getNodeByAlias(entityNode.alias) == null);

        // Find last entity node
        let lastEntityNodeIdx = 0;

        this.selection.nodes.forEach((node, idx) =>
        {
            if (node instanceof EntityNode)
            {
                lastEntityNodeIdx = idx;
            }
        });

        // Add entity nodes after last entity node (and before any other nodes, such as constraint nodes)
        this.selection.nodes.splice(lastEntityNodeIdx + 1, 0, ...joinEntityNodes);

        return this.getNodeByAlias(alias);
    }

    join(path: EntityPath): EntitySelectionBuilder
    {
        this.joinAndGetByAlias(path, path.id);

        return this;
    }

    joinAndGet(path: EntityPath): EntityNode
    {
        return this.join(path)
            .getNodeByPath(path);
    }

    where(callback: (builder: EntityConstraintBuilder) => void): EntitySelectionBuilder
    {
        let compositeNode = new CompositeConstraintNode(LogicalOperator.And, []);

        this.selection.nodes.push(compositeNode);

        callback(new EntityConstraintBuilder(this, compositeNode));

        return this;
    }

    orWhere(callback: (builder: EntityConstraintBuilder) => void): EntitySelectionBuilder
    {
        let compositeNode = new CompositeConstraintNode(LogicalOperator.Or, []);

        this.selection.nodes.push(compositeNode);

        callback(new EntityConstraintBuilder(this, compositeNode));

        return this;
    }

    if(predicate: () => boolean, callback: (builder: EntitySelectionBuilder) => void): EntitySelectionBuilder
    {
        if (predicate())
        {
            callback(this);
        }

        return this;
    }

    groupBy(fieldPath: EntityFieldPath, range?: EntitySelectionRange, representation?: any): EntitySelectionBuilder
    {
        let node = this.joinAndGet(fieldPath.path);

        this.selection.nodes.push(new GroupNode(undefined, node, fieldPath.field, representation, range ? {
            interval: range.interval ? range.interval.descriptor() : undefined,
            value: range.value ? range.value.descriptor() : undefined,
            isFloored: range.isFloored,
            isCeiled: range.isCeiled,
        } : undefined));

        return this;
    }

    orderBy(
        fieldPath: EntityFieldPath,
        isAscending: boolean
    ): EntitySelectionBuilder
    {
        let correctedFieldPath = fieldPath.rootedAt(this.entityType);

        // It is not allowed to order on non-primitive fields
        if (!correctedFieldPath.field)
        {
            correctedFieldPath =
                correctedFieldPath.path.field(
                    getInheritedNameFieldWithFallback(
                        correctedFieldPath.path.entityType
                    )
                );
        }

        const node = this.joinAndGet(correctedFieldPath.path);
        const valueExpression =
            new ValueFromEntityComputation(
                node.parameter,
                correctedFieldPath
            );

        if (fieldPath.isField && fieldPath.field.type === DataObjectType.Boolean)
        {
            return this.orderByExpression(
                new ComparisonPredicate(
                    valueExpression,
                    Comparator.Equals,
                    new PrimitiveValue(
                        DataObject.constructFromTypeIdAndValue(
                            'Boolean',
                            true
                        )
                    )
                ),
                isAscending
            );
        }
        else
        {
            return this.orderByExpression(
                valueExpression,
                isAscending
            );
        }
    }

    orderByExpression(
        expression: Computation<any, any>,
        isAscending: boolean
    ): EntitySelectionBuilder
    {
        this.ordering.push({
            expression,
            isAscending,
        });

        return this;
    }

    aggregateOn(
        fieldPath: EntityFieldPath,
        representation: DataObjectRepresentation,
        aggregate: Aggregate,
        isDistinct: boolean = false
    ): EntitySelectionBuilder
    {
        this.join(fieldPath.path);

        this.aggregates.push({
            fieldPath,
            representation,
            aggregate,
            isDistinct,
        });

        return this;
    }

    offset(offset: number): EntitySelectionBuilder
    {
        this.offsetNumber = offset;

        return this;
    }

    limit(limit: number): EntitySelectionBuilder
    {
        this.limitNumber = limit;

        return this;
    }

    hint(hint: SelectionHint): EntitySelectionBuilder
    {
        this.selection.hints.push(hint);

        return this;
    }

    language(languageCode: string): EntitySelectionBuilder
    {
        this.languageCode = languageCode;

        return this;
    }

    log(logName: string): EntitySelectionBuilder
    {
        this.logName = logName;

        return this;
    }

    // ------------------------ Public logic ------------------------

    getNodeByAlias(alias: string): EntityNode
    {
        return this.selection.entityNodes.find(node => node.alias === alias);
    }

    getNodeByPath(path: EntityPath): EntityNode
    {
        return this.getNodeByAlias(path.id);
    }

    async select(
        offset: number = this.offsetNumber,
        limit: number = this.limitNumber,
        forceReload: boolean = false
    ): Promise<EntitySelectionResult[]>
    {
        const { records } =
            await this.selectExtendedResult(
                offset,
                limit,
                forceReload
            );

        return records;
    }

    selectExtendedResult(
        offset: number = this.offsetNumber,
        limit: number = this.limitNumber,
        forceReload: boolean = false
    ): Promise<EntitySelectionListResult>
    {
        let ordering = this.ordering;

        const orderingDescriptors: any[] =
            ordering.map(
                ordering => ({
                    expression: ordering.expression.toDescriptor(),
                    isAscending: ordering.isAscending,
                })
            );

        const query =
            this.getCachedQueryAndRegisterReference(
                new EntityQuery(
                    'List',
                    this.selection,
                    offset,
                    limit,
                    this.ordering,
                    [],
                    this.logName,
                    true
                )
            );

        const result = this.entityQueryCacheService.getListResultByQuery(query);

        if (result && !forceReload)
        {
            const listResult = {
                numberOfRecords: result.numberOfRecords,
                records: result.results as EntitySelectionResult[],
            };
            this.registerReferrerInResultInListResult(listResult);

            return Promise.resolve(listResult);
        }
        else
        {
            const cachedPromise = this.entityQueryCacheService.getPromiseBySelection(query);

            if (cachedPromise)
            {
                return cachedPromise as Promise<EntitySelectionListResult>;
            }

            // console.warn('selecting', this.cacheReferrer);

            const promise =
                this.entityTypeStore.entitySelectionController
                    .select(
                        getSelectionDescriptor(this.selection),
                        offset,
                        limit,
                        orderingDescriptors,
                        this.languageCode
                    )
                    .then(({ numberOfRecords, entities: records }) =>
                    {
                        const dataObjects: DataObject[] = [];

                        const selectionResults =
                            this.resolveSelectionResults(
                                this.selection,
                                records,
                                dataObjects,
                                this.cacheReferrer
                            );

                        return this.entityTypeStore.dataObjectStore
                            .initializeDataObjects(dataObjects)
                            .then(
                                () =>
                                {
                                    selectionResults.forEach(
                                        result =>
                                        {
                                            // Return result from cache
                                            if (result.entity)
                                            {
                                                result.entity = this.entityCacheService.getEntity(result.entity.uuid);
                                            }
                                            else
                                            {
                                                consoleLog('cannot find entity for result', records, selectionResults, result);
                                            }
                                        }
                                    );

                                    const listResult = {
                                        numberOfRecords: numberOfRecords as number,
                                        records: selectionResults,
                                    };

                                    this.registerReferrerInResultInListResult(listResult);

                                    // TODO if forceReload shouldn't we clear the previously cached results?
                                    this.entityQueryCacheService.registerListQueryResult(
                                        query,
                                        offset,
                                        limit,
                                        selectionResults,
                                        numberOfRecords
                                    );
                                    this.entityQueryCacheService.clearPromiseByQuery(query);

                                    return Promise.resolve(listResult);
                                }
                            );
                    });

            this.entityQueryCacheService.setPromiseByQuery(
                query,
                promise
            );

            return promise;
        }
    }

    selectAggregates(forceReload: boolean = false): Promise<EntitySelectionAggregateResult>
    {
        const aggregates: any[] = this.aggregates.map(aggregate => ({
            aggregate: Aggregate[aggregate.aggregate],
            alias: this.getNodeByPath(aggregate.fieldPath.path).alias,
            fieldId: aggregate.fieldPath.field.isStaticField() ? undefined : aggregate.fieldPath.field.id,
            fieldCode: aggregate.fieldPath.field.isStaticField() ? aggregate.fieldPath.field.code : undefined,
            fieldRepresentation: aggregate.representation ? aggregate.representation.data : undefined,
            isDistinct: aggregate.isDistinct,
        }));

        const query =
            this.getCachedQueryAndRegisterReference(
                new EntityQuery(
                    'Aggregate',
                    this.selection,
                    undefined,
                    undefined,
                    [],
                    this.aggregates,
                    this.logName,
                    true
                )
            );

        const result = this.entityQueryCacheService.getAggregateResultByQuery(query);

        if (result && !forceReload)
        {
            const aggregateResult = result as EntitySelectionAggregateResult;
            this.registerReferrerInAggregateResult(aggregateResult);

            return Promise.resolve(aggregateResult);
        }
        else
        {
            const cachedPromise = this.entityQueryCacheService.getPromiseBySelection(query);

            if (cachedPromise)
            {
                return cachedPromise as Promise<EntitySelectionAggregateResult>;
            }

            const promise =
                this.entityTypeStore.entitySelectionController
                    .aggregates(
                        getSelectionDescriptor(this.selection),
                        aggregates
                    )
                    .then(result =>
                    {
                        const dataObjects: DataObject[] = [];
                        const entities: Entity[] = [];

                        const aggregateResult: EntitySelectionAggregateResult =
                            this.resolveAggregateResult(
                                result,
                                dataObjects,
                                entities,
                                this.cacheReferrer
                            );

                        return this.entityTypeStore.dataObjectStore
                            .initializeDataObjects(dataObjects)
                            .then(() =>
                            {
                                // Merge entities
                                aggregateResult.traverse(
                                    result =>
                                    {
                                        if (result.groupEntity)
                                        {
                                            result.groupEntity = this.entityCacheService.getEntity(result.groupEntity.uuid);
                                        }
                                    }
                                );

                                this.entityQueryCacheService.registerAggregateQueryResult(query, aggregateResult);
                                this.entityQueryCacheService.clearPromiseByQuery(query);
                                this.registerReferrerInAggregateResult(aggregateResult);

                                return Promise.resolve(aggregateResult);
                            });
                    });

            this.entityQueryCacheService.setPromiseByQuery(
                query,
                promise);

            return promise;
        }
    }

    count(forceReload: boolean = false): Promise<DataObject>
    {
        this.aggregateOn(
            this.rootPath.field(this.entityTypeStore.idField),
            undefined,
            Aggregate.Count);

        return this.selectAggregates(forceReload)
            .then(
                result =>
                    result.aggregates[0]);
    }

    clone(): EntitySelectionBuilder
    {
        const builder = new EntitySelectionBuilder(this.entityType, new Selection(this.selection.nodes.slice()));

        builder.ordering = observable.array(this.ordering);
        builder.aggregates = observable.array(this.aggregates);

        return builder;
    }

    build()
    {
        return this.selection;
    }

    static fromPath(entityPath: EntityPath): EntitySelectionBuilder
    {
        return new EntitySelectionBuilder(
            entityPath.rootEntityType,
            getSelectionFromPath(entityPath)
        );
    }

    static build(entityType: EntityType,
                 callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void): Selection
    {
        return EntitySelectionBuilder.builder(entityType, callback).build();
    }

    static builder(entityType: EntityType,
                   callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void): EntitySelectionBuilder
    {
        const rootPath = EntityPath.fromEntityType(entityType);
        const builder = EntitySelectionBuilder.fromPath(rootPath);

        callback(builder, rootPath);

        return builder;
    }

    // ----------------------- Private logic ------------------------

    private getCachedQueryAndRegisterReference(query: EntityQuery)
    {
        const cachedQuery = this.entityQueryCacheService.getOrSetCachedQuery(query);
        cachedQuery.cacheInformation.addReferrer(this.cacheReferrer);
        this.cachedQueries.push(cachedQuery);

        return cachedQuery;
    }

    private resolveSelectionResults(selection: Selection,
                                    records: any[],
                                    dataObjects: DataObject[],
                                    cacheReferrer: EntityCacheReferrer)
    {
        const entities: Entity[] = [];
        const relationships: EntityRelationship[] = [];
        const callbacksByEntityUuid = new Map<string, EntityCallback[]>();

        const results =
            this.resolveSelectionResultsInternally(
                selection,
                records,
                dataObjects,
                0,
                entities,
                relationships,
                callbacksByEntityUuid);

        this.entityCacheService
            .mergeEntityNetworkForEntities(
                entities,
                true,
                cacheReferrer)
            .filter(
                mergedEntity =>
                    callbacksByEntityUuid.has(mergedEntity.uuid))
            .forEach(
                mergedEntity =>
                    callbacksByEntityUuid.get(mergedEntity.uuid)
                        .forEach(
                            callback =>
                                callback(mergedEntity)));

        return results;
    }

    private resolveSelectionResultsInternally(selection: Selection,
                                              records: any[],
                                              dataObjects: DataObject[],
                                              groupIdx: number,
                                              entities: Entity[],
                                              relationships: EntityRelationship[],
                                              callbacksByEntityUuid: Map<string, EntityCallback[]>)
    {
        const selectionResults = observable.array<EntitySelectionResult>(undefined, { deep: false });
        const entityById = new Map<number, Entity>();
        const relationshipById = new Map<number, EntityRelationship>();
        const entityPathByNode = new Map<EntityNode, EntityPath>();

        selection.entityNodes
            .forEach(
                entityNode =>
                {
                    const path = entityNode.entityPath();

                    entityPathByNode.set(entityNode, path);
                });

        for (let record of records)
        {
            const selectionResult = new EntitySelectionResult();
            selectionResults.push(selectionResult);

            if (this.selection.groupNodes.length > 0 && record.children)
            {
                if (record.value)
                {
                    const dataObjectSpecification = this.selection.groupNodes[groupIdx].groupEntityField.dataObjectSpecification;
                    const groupValue = new DataObject(
                        dataObjectSpecification,
                        DataDescriptor.construct(
                            record.value,
                            dataObjectSpecification));

                    selectionResult.groupValue = groupValue;
                    dataObjects.push(groupValue);
                }

                selectionResult.children =
                    this.resolveSelectionResultsInternally(
                        selection,
                        record.children,
                        dataObjects,
                        groupIdx + 1,
                        entities,
                        relationships,
                        callbacksByEntityUuid);
            }
            else
            {
                const valuesToInitialize: DataObject[] = [];

                const entity =
                    (record as Entity).initialize(
                        this.entityTypeStore,
                            onEntity =>
                            {
                                entities.push(onEntity);

                                if (!entityById.has(onEntity.id))
                                {
                                    entityById.set(onEntity.id, onEntity);

                                    valuesToInitialize.push(...onEntity.values.map(value => value.dataObject));
                                }
                            },
                            onRelationship =>
                            {
                                relationships.push(onRelationship);

                                if (!relationshipById.has(onRelationship.id))
                                {
                                    relationshipById.set(onRelationship.id, onRelationship);
                                }
                            },
                        undefined,
                        false,
                        false,
                        false);

                dataObjects.push(...valuesToInitialize);

                if (!callbacksByEntityUuid.has(entity.uuid))
                {
                    callbacksByEntityUuid.set(entity.uuid, []);
                }

                callbacksByEntityUuid.get(entity.uuid)
                    .push(
                        cachedEntity =>
                            selectionResult.entity = cachedEntity);
            }
        }

        return selectionResults;
    }

    private resolveAggregateResult(descriptor: any,
                                   dataObjects: DataObject[],
                                   entities: Entity[] = [],
                                   cacheReferrer: EntityCacheReferrer): EntitySelectionAggregateResult
    {
        const callbacksByEntityUuid = new Map<string, EntityCallback[]>();

        const result =
            this.resolveAggregateResultInternally(
                descriptor,
                dataObjects,
                entities,
                0,
                callbacksByEntityUuid);

        this.entityCacheService
            .mergeEntityNetworkForEntities(
                entities,
                true,
                cacheReferrer)
            .filter(
                mergedEntity =>
                    callbacksByEntityUuid.has(mergedEntity.uuid))
            .forEach(
                mergedEntity =>
                    callbacksByEntityUuid.get(mergedEntity.uuid)
                        .forEach(
                            callback =>
                                callback(mergedEntity)));

        return result;
    }

    private resolveAggregateResultInternally(descriptor: any,
                                             dataObjects: DataObject[],
                                             entities: Entity[] = [],
                                             level: number,
                                             callbacksByEntityUuid: Map<string, EntityCallback[]>): EntitySelectionAggregateResult
    {
        let rawAggregates: any[] = [];
        const aggregateResults: DataObject[] = [];
        let groupValue: DataObject;
        let groupInterval: DataObject;
        const children = observable.array<EntitySelectionAggregateResult>();

        if (isArray(descriptor) || isObservableArray(descriptor))
        {
            rawAggregates = descriptor.slice();
        }

        if (descriptor.aggregate)
        {
            rawAggregates.push(descriptor.aggregate);
        }

        if (descriptor.aggregates)
        {
            rawAggregates.push(...descriptor.aggregates);
        }

        rawAggregates.forEach(
            (dataDescriptor, index) =>
            {
                let specification: DataObjectSpecification;

                if (this.aggregates[index].aggregate === Aggregate.Count)
                {
                    specification = new DataObjectSpecification(this.entityTypeStore.dataObjectStore.getTypeById('Number'), {});
                }
                else
                {
                    specification = this.aggregates[index].fieldPath.field.dataObjectSpecification;
                }

                aggregateResults.push(
                    new DataObject(
                        specification,
                        DataDescriptor.construct(
                            dataDescriptor,
                            specification)));
            });

        dataObjects.push(...aggregateResults);

        if (descriptor.groupValue)
        {
            groupValue =
                DataObject.constructFromDescriptor(
                    descriptor.groupValue,
                    this.entityTypeStore.dataObjectStore);

            dataObjects.push(groupValue);
        }

        if (descriptor.groupInterval)
        {
            groupInterval =
                DataObject.constructFromDescriptor(
                    descriptor.groupInterval,
                    this.entityTypeStore.dataObjectStore);

            dataObjects.push(groupInterval);
        }

        if (descriptor.children)
        {
            children.replace(
                (descriptor.children as any[])
                    .map(
                        child =>
                            this.resolveAggregateResultInternally(
                                child,
                                dataObjects,
                                entities,
                                level + 1,
                                callbacksByEntityUuid)));
        }

        const result =
            new EntitySelectionAggregateResult(
                undefined,
                groupValue,
                groupInterval,
                aggregateResults,
                level < this.selection.groupNodes.length
                    ?
                        this.selection.groupNodes[level].groupEntityNode
                            .entityPath()
                            .field(this.selection.groupNodes[level].groupEntityField)
                    :
                        undefined,
                children);

        if (descriptor.groupEntity)
        {
            const groupEntity =
                descriptor.groupEntity.initialize(
                    this.entityTypeStore,
                    undefined,
                    undefined,
                    undefined,
                    false);

            entities.push(groupEntity);

            if (!callbacksByEntityUuid.has(groupEntity.uuid))
            {
                callbacksByEntityUuid.set(groupEntity.uuid, []);
            }

            callbacksByEntityUuid.get(groupEntity.uuid)
                .push(
                    cachedEntity =>
                        result.groupEntity = cachedEntity);
        }

        return result;
    }

    private registerReferrerInResultInListResult(
        result: EntitySelectionListResult
    )
    {
        this.registerReferrerInRootEntities(
            result.records
                .filter(
                    record =>
                        record.entity !== undefined
                )
                .map(
                    record =>
                        record.entity
                )
        );
    }

    private registerReferrerInAggregateResult(
        result: EntitySelectionAggregateResult
    )
    {
        result.traverse(
            result =>
            {
                if (result.groupEntity
                    && result.groupFieldPath)
                {
                    this.registerReferrerInRootEntities(
                        result.groupFieldPath.path
                            .reverse()
                            .traverseEntity(
                                result.groupEntity,
                                undefined,
                                relationship =>
                                    this.entityCacheService.cacheReferrerService.addReferrerToCacheInformationForRelationship(
                                        relationship,
                                        this.cacheReferrer
                                    ),
                                entity =>
                                    this.entityCacheService.cacheReferrerService.addReferrerToCacheInformationForEntity(
                                        entity,
                                        this.cacheReferrer
                                    )
                            )
                    );
                }
            }
        );
    }

    private registerReferrerInRootEntities(
        entities: Entity[]
    )
    {
        runInAction(
            () =>
            {
                const selection = this.selection;
                const paths =
                    selection.entityNodes
                        .map(
                            node =>
                                node.entityPath()
                        );

                // Do not mark any paths as fetched that are partially fetched (in case of one-to-many or many-to-many joins)
                const partiallyFetchedPathsInSelection =
                    selection.getDependencies()
                        .map(
                            node =>
                                node.entityPath()
                        );

                markPathsAsFetchedInEntities(
                    paths,
                    entities,
                    this.cacheReferrer,
                    joinNode =>
                        joinNode.relationshipDefinition.isPlural(joinNode.isParent)
                        && partiallyFetchedPathsInSelection.some(
                            partiallyFetchedPath =>
                                partiallyFetchedPath.nodes
                                    .some(
                                        node =>
                                            node.equals(joinNode)
                                    )
                        )
                );

                for (const entity of entities)
                {
                    paths
                        .forEach(
                            path =>
                                path.traverseEntity(
                                    entity,
                                    undefined,
                                    relationship =>
                                        this.entityCacheService.cacheReferrerService.addReferrerToCacheInformationForRelationship(
                                            relationship,
                                            this.cacheReferrer
                                        ),
                                    entity =>
                                        this.entityCacheService.cacheReferrerService.addReferrerToCacheInformationForEntity(
                                            entity,
                                            this.cacheReferrer
                                        )
                                )
                        );
                }
            });
    }

    dispose()
    {
        for (const query of this.cachedQueries)
        {
            query.cacheInformation.removeReferrer(this.cacheReferrer);
        }

        this.cachedQueries = [];
        this.entityCacheService.disposeReferrer(this.cacheReferrer);
    }
}
