import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { EntityPathEditorStore } from './EntityPathEditorStore';
import Button from '../../../../../Generic/Button/Button';

@observer
export class EntityPathEditor extends DataObjectBespokeEditor<EntityPathEditorStore>
{
    renderComponent(store: DataObjectEditorStore<EntityPathEditorStore>)
    {
        if (store.bespokeStore.buttonStore)
        {
            return <Button
                store={store.bespokeStore.buttonStore}
            />;
        }
        else
        {
            return null;
        }
    }
}
