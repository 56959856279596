import Value from './Value';
import DataSourceValueType from './Type/DataSourceValueType';
import PortalDataSourceValue from '../../Portal/DataSource/PortalDataSourceValue';
import ParameterAssignment from '../Parameter/ParameterAssignment';
import AutomationDependencyContext from '../AutomationDependencyContext';
import getPortalDataSourceSignatureById from '../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import queryPortalDataSource from '../../Portal/DataSource/Api/queryPortalDataSource';
import PortalDataSourceIdsQuery from '../../Portal/DataSource/PortalDataSourceIdsQuery';
import uuid from '../../../@Util/Id/uuid';
import isInPortal from '../../Portal/Api/isInPortal';
import { deduplicateArray } from '../../../@Util/Array/deduplicateArray';

export default class DataSourceValue extends Value<PortalDataSourceValue, DataSourceValueType>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getId(): string
    {
        return `DataSource(${this.value.id})`;
    }

    getType(): DataSourceValueType
    {
        return new DataSourceValueType(this.value.dataSourceId);
    }

    getName(): string
    {
        return this.value.id;
    }

    equals(otherValue: Value<any, any>)
    {
        return otherValue instanceof DataSourceValue
            && this.value.id === otherValue.value.id
            && this.value.valueParameterAssignment.equals(otherValue.value.valueParameterAssignment);
    }

    clone(): DataSourceValue
    {
        return new DataSourceValue(this.value);
    }

    augmentDescriptor(descriptor)
    {
        descriptor.type = 'DataSource';
        descriptor.id = this.value.id;
        descriptor.dataSourceId = this.value.dataSourceId;
        descriptor.dataSourceParameterAssignment = this.value.dataSourceParameterAssignment.toDescriptor();
    }

    static async fromDescriptor(
        descriptor: any,
        dependencyContext: AutomationDependencyContext
    )
    {
        const id = descriptor.id;
        const dataSourceId = descriptor.dataSourceId;
        const dataSourceSignature = await getPortalDataSourceSignatureById(dataSourceId);
        const dataSourceParameterAssignment =
            await ParameterAssignment.fromDescriptor(
                new AutomationDependencyContext(dataSourceSignature.parameters),
                descriptor.dataSourceParameterAssignment);

        if (isInPortal())
        {
            if (dependencyContext.deferredInitializer)
            {
                return dependencyContext.deferredInitializer.defer(
                    descriptor,
                    new DataSourceValueType(
                        dataSourceId,
                        undefined,
                        dataSourceSignature
                    ),
                    descriptor =>
                        descriptor.id,
                    value =>
                        value.value.id,
                    async descriptors =>
                    {
                        const dataSourceIds: string[] =
                            deduplicateArray(
                                descriptors.map(
                                    descriptor =>
                                        descriptor.id as string
                                )
                            ).sort();
                        const result =
                            await queryPortalDataSource(
                                new PortalDataSourceIdsQuery(
                                    uuid(),
                                    dataSourceSignature,
                                    dataSourceParameterAssignment,
                                    dataSourceIds
                                )
                            );

                        return result.results.map(
                            result =>
                                new DataSourceValue(
                                    new PortalDataSourceValue(
                                        result.id,
                                        dataSourceId,
                                        dataSourceParameterAssignment,
                                        result.parameterAssignment
                                    )
                                )
                        );
                    }
                );
            }
            else
            {
                const results =
                    await queryPortalDataSource(
                        new PortalDataSourceIdsQuery(
                            uuid(),
                            dataSourceSignature,
                            dataSourceParameterAssignment,
                            [ id ]
                        )
                    );

                if (results.results.length === 0)
                {
                    throw new Error(`Cannot find data source value with ID: ${id} in data source with ID: ${dataSourceId}`);
                }

                return new DataSourceValue(
                    new PortalDataSourceValue(
                        id,
                        dataSourceId,
                        dataSourceParameterAssignment,
                        results.results[0].parameterAssignment
                    )
                );
            }
        }
        else
        {
            return new DataSourceValue(
                new PortalDataSourceValue(
                    id,
                    dataSourceId,
                    dataSourceParameterAssignment,
                    new ParameterAssignment()));
        }

    }

    // ----------------------- Private logic ------------------------
}
