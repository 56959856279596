import { computed, observable } from 'mobx';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntityTypeStore } from './EntityTypeStore';
import getPhaseRelationshipDefinition from '../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';

export class BespokeEntity
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref entityTypeStore: EntityTypeStore;
    @observable.ref entity: Entity;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                entity: Entity)
    {
        this.entityTypeStore = entityTypeStore;
        this.entity = entity;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    @computed
    get phase()
    {
        const phaseRelationshipDefinition = getPhaseRelationshipDefinition(this.entity.entityType);

        if (phaseRelationshipDefinition)
        {
            return this.entity.getRelatedEntityByDefinition(
                false,
                phaseRelationshipDefinition);
        }
        else
        {
            return undefined;
        }
    }

    get isOwnable()
    {
        return this.entity.entityType.isOwnableByInheritance;
    }

    @computed
    get isAssignable()
    {
        if (this.isInClosedState)
        {
            return false;
        }
        else
        {
            return this.entity.entityType.isAssignableByInheritance
                && (this.phase && this.phase.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.IsAssignable));
        }
    }

    @computed
    get isFollowUpAllDay(): boolean
    {
        const fromDate = this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Activity.Task.Field.StartDate) as Date;
        const toDate = this.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.Activity.Task.Field.EndDate) as Date;

        if (fromDate && toDate)
        {
            return fromDate.getHours() === 0
                && fromDate.getMinutes() === 0
                && fromDate.getSeconds() === 0
                &&
                ((toDate.getHours() === 23
                    && toDate.getMinutes() === 59)
                    ||
                (toDate.getHours() === 0
                    && toDate.getMinutes() === 0
                    && toDate.getSeconds() === 0));
        }
        else
        {
            return false;
        }
    }

    @computed
    get isInWorkflow(): boolean
    {
        return this.phase !== undefined;
    }

    @computed
    get isInClosedState(): boolean
    {
        if (this.phase)
        {
            const isTerminal = this.phase.getObjectValueByField(this.entityTypeStore.bespoke.types.Datastore.Phase.Field.IsTerminal);

            if (isTerminal === undefined)
            {
                return false;
            }
            else
            {
                return isTerminal;
            }
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get timelineDateField(): EntityField
    {
        if (this.isInClosedState)
        {
            return this.entityTypeStore.bespoke.types.Entity.Field.CloseDate;
        }
        else if (this.isAssignable)
        {
            return this.entityTypeStore.bespoke.types.Activity.Task.Field.StartDate;
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
