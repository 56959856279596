import { Entity } from '../../../Model/Implementation/Entity';
import { EntityPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityExpansionBuilder } from '../../../../@Component/Domain/Entity/Selection/Builder/EntityExpansionBuilder';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../@Component/Domain/Entity/Type/EntityTypeStore';
import copyProductLine from './copyProductLine';
import { CommitContext } from '../../Commit/Context/CommitContext';
import { getChildProductLineRelationshipDefinitionsToCopy } from './getChildProductLineRelationshipDefinitionsToCopy';

export default async function copyProductLines(
    fromEntity: Entity,
    toEntity: Entity,
    filter: (entity: Entity) => boolean = () => true,
    commitContext?: CommitContext
)
{
    const entityTypeStore = loadModuleDirectly(EntityTypeStore);
    const types = entityTypeStore.bespoke.types;

    const pathToProductLines =
        EntityPath.fromEntity(fromEntity)
            .joinTo(
                types.Activity.RelationshipDefinition.ProductLines,
                false);

    return new EntityExpansionBuilder(
        fromEntity.entityType,
        [
            fromEntity
        ],
        [
            pathToProductLines
                .joinTo(
                    types.ProductLine.RelationshipDefinition.Product,
                    false
                ),
            pathToProductLines
                .joinTo(
                    types.ProductLine.RelationshipDefinition.VatGroup,
                    false
                ),
            ...getChildProductLineRelationshipDefinitionsToCopy()
                .map(
                    relationshipDefinition =>
                        pathToProductLines
                            .joinTo(
                                relationshipDefinition,
                                false
                            )
                )
        ])
        .expand()
        .then(
            () =>
                Promise.resolve(
                    pathToProductLines
                        .traverseEntity(fromEntity, commitContext)
                        .filter(
                            line =>
                                filter(line))
                        .map(
                            line =>
                                copyProductLine(
                                    line,
                                    toEntity,
                                    commitContext
                                )
                        )
                )
        );
}
