import React, { useCallback } from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LabelButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import useTypes from '../../../../Type/Api/useTypes';
import { green } from '@material-ui/core/colors';
import { getModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import constructRelatedEntity from '../../../../Dataset/Segment/Api/constructRelatedEntity';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContextImpl } from '../../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';

export interface SalesOpportunityProps
{
    entity: Entity;
}

const Project: React.FC<SalesOpportunityProps> =
    props =>
    {
        const types = useTypes();
        const createWorkOrder =
            useCallback(
                () =>
                    constructRelatedEntity(
                        getModel(props.entity),
                        EntityPath.fromEntity(props.entity)
                            .joinTo(
                                types.Activity.RelationshipDefinition.LinkedActivities,
                                false)
                            .castTo(types.Activity.WorkOrder.Type),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        })
                    ),
                [
                    props.entity,
                    types,
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            {
                types.Activity.WorkOrder.Type &&
                    <ViewGroupItem>
                        <LabelButton
                            label={
                                <LocalizedText
                                    code="Project.AddWorkOrder"
                                    value="+ Werkbon"
                                />
                            }
                            onClick={createWorkOrder}
                            filled
                            size="sm"
                            color={green[400]}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Project);
