import { computed } from 'mobx';
import { DataObjectBespokeViewerStore } from '../../Viewer/DataObjectBespokeViewerStore';

export class TextViewerStore extends DataObjectBespokeViewerStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isRichText(): boolean
    {
        return this.baseStore.dataObject.specification.data.isRichText;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
