import React, { useCallback } from 'react';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import useMetadataSettingValue from '../../../../../../@Api/Metadata/useMetadataSettingValue';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import { observer } from 'mobx-react';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { ColorPickerStore } from '../../../../../Generic/ColorPicker/ColorPickerStore';
import { ColorPicker } from '../../../../../Generic/ColorPicker/ColorPicker';
import getEntityTypeColorForPlanner from '../../../../Entity/@Util/getEntityTypeColorForPlanner';

export interface ResourcePlannerColorEditorProps
{
    entityType: EntityType;
}

const ResourcePlannerColorEditor: React.FC<ResourcePlannerColorEditorProps> =
    props =>
    {
        const [ plannerColor, setPlannerColor ] = useMetadataSettingValue<string>(props.entityType, Setting.Metadata.PlannerColor);

        const resetColor =
            useCallback(
                () =>
                    setPlannerColor(undefined),
                [
                    setPlannerColor
                ]
            );

        const onChange =
            useCallback(
                (color) =>
                    setPlannerColor(color),
            [
                setPlannerColor
                ]
            )

        return <Input
                labelPosition="left"
                label={
                    <LocalizedText
                        code="Configuration.PlannerColor"
                        value="Kleur in agenda"
                    />
                }
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={20}
                >
                    <ViewGroupItem>
                        <div
                            style={{
                                width: 70
                            }}
                        >
                            <ColorPicker
                                store={
                                    new ColorPickerStore(
                                        plannerColor ?? getEntityTypeColorForPlanner(props.entityType),
                                        true,
                                        onChange
                                    )
                                }
                            />
                        </div>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <PrimaryButton
                            label={
                                <LocalizedText
                                    code="Configuration.ResetPlannerColor"
                                    value="Reset"
                                />
                            }
                            onClick={resetColor}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </Input>

    };

export default observer(ResourcePlannerColorEditor);
