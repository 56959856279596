import AutomationDependencyContext from '../../AutomationDependencyContext';
import ValueFieldMapping from '../Field/ValueFieldMapping';
import MappingFieldMapping from '../Field/MappingFieldMapping';

export default async function getFieldMappingFromDescriptor(descriptor: any,
                                                            dependencyContext: AutomationDependencyContext)
{
    switch (descriptor.type)
    {
        case 'Value':
            return ValueFieldMapping.fromDescriptor(
                descriptor,
                dependencyContext);

        case 'Mapping':
            return MappingFieldMapping.fromDescriptor(
                descriptor,
                dependencyContext);
    }
}
