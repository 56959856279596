import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import Computation from '../Computation/Computation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';
import getActionFromDescriptor from '../../Api/getActionFromDescriptor';
import CollectionType from '../../Value/Type/CollectionType';
import CollectionValue from '../../Value/CollectionValue';
import Parameter from '../../Parameter/Parameter';
import localizeText from '../../../Localization/localizeText';

export default class ForEachAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref id: string;
    @observable.ref collection: Computation<CollectionType<any>, CollectionValue<any>>;
    @observable.ref action: Action<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                collection: Computation<any, any>,
                action: Action<any, any>)
    {
        super();

        this.id = id;
        this.collection = collection;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return EmptyValueType.instance;
    }

    getName()
    {
        return localizeText('Action.ForEach',
            'Voor elk element uit collectie...'
        );
    }

    getElementParameter()
    {
        if (this.collection
            && this.collection.isValid())
        {
            const collectionType = this.collection.getType();
            const elementType = collectionType instanceof CollectionType ? collectionType.type : collectionType;

            return new Parameter(
                this.id,
                elementType,
                false,
                localizeText(
                    'Action.FromCollection',
                    '${type} uit collectie',
                    {
                        type: elementType.getName()
                    }
                )
            )
        }
        else
        {
            return undefined;
        }
    }

    validate(): Validation[]
    {
        if (!this.collection)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'NoCollectionDefined',
                        'Je hebt nog geen collectie gedefinieerd.'
                    )
                )
            ]
        }

        return [
            ...this.collection.validate(),
            ...this.action.validate()
        ];
    }

    isAsync(): boolean
    {
        return this.collection.isAsync()
            || this.action.isAsync();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'ForEach';
        descriptor.id = this.id;
        descriptor.collection = this.collection.toDescriptor();
        descriptor.action = this.action.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.collection.getDependencies(),
            ...this.action.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const forEachAction =
            new ForEachAction(
                descriptor.id,
                await getComputationFromDescriptor(
                    descriptor.collection,
                    dependencyContext),
                undefined);

        dependencyContext.parameterDictionary
            .addParameter(
                forEachAction.getElementParameter());

        forEachAction.action =
            await getActionFromDescriptor(
                descriptor.action,
                dependencyContext);

        return forEachAction;
    }

    // ----------------------- Private logic ------------------------
}
