import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ValueEditorProps } from '../../ValueEditor';
import { computed } from 'mobx';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import Editor from '../../../../../../../../DataObject/Editor/Editor';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';

export interface PrimitiveEditorProps extends ValueEditorProps<PrimitiveValueType, PrimitiveValue>
{

}

const PrimitiveEditor: React.FC<PrimitiveEditorProps> =
    props =>
    {
        const dataObject =
            useMemo(
                () =>
                {
                    if (props.value)
                    {
                        return props.value.value;
                    }
                    else
                    {
                        return DataObject.constructFromTypeAndValue(
                            props.type.type,
                            undefined);
                    }
                },
                [
                    props.type,
                    props.value
                ]);

        useEffect(
            () =>
                computed(() => dataObject.value)
                    .observe(
                        change =>
                        {
                            if (props.value)
                            {
                                if (change.newValue === undefined)
                                {
                                    props.onChange(undefined);
                                }
                            }
                            else
                            {
                                if (change.newValue !== undefined)
                                {
                                    props.onChange(
                                        new PrimitiveValue(dataObject));
                                }
                            }
                        }),
            [
                props.value,
                props.onChange,
                dataObject
            ]);

        useEffect(
            () =>
            {
                if (!props.value
                    && props.type
                    && props.type.type.hasSemanticValueWhenEmpty())
                {
                    props.onChange(
                        new PrimitiveValue(
                            DataObject.constructFromTypeAndValue(
                                props.type.type,
                                undefined)));
                }
            },
            [
                props.value,
                props.type
            ]);

        return <Editor
            dataObject={dataObject}
            autoFocus={props.autoFocus}
        />;
    };

export default observer(PrimitiveEditor);
