import React from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import ResourcePlannerWidget from '../../Model/Widget/ResourcePlanner/ResourcePlannerWidget';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import { default as ResourcePlannerModel } from '../../../ResourcePlanner/Model/ResourcePlanner';
import { default as InternalResourcePlanner } from '../../../ResourcePlanner/ResourcePlanner';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import DeleteButton from '../../../../../../@Future/Component/Generic/Button/Variant/DeleteButton/DeleteButton';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import { default as MenuItem } from '../../../../../../@Future/Component/Generic/Menu/Item/Item';

export interface ResourcePlannerProps extends WidgetProps<ResourcePlannerWidget>
{

}

const ResourcePlanner: React.FC<ResourcePlannerProps> =
    props =>
    {
        const types = useTypes();
        const entity = props.widget.resourcePlanner;

        const [ resourcePlanner, isLoading ] =
            useAsyncResult(
                () =>
                {
                    if (entity
                        && entity.hasValueForField(types.ResourcePlanner.Field.Specification))
                    {
                        return ResourcePlannerModel.fromDescriptor(
                            entity.getObjectValueByField(types.ResourcePlanner.Field.Specification));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity,
                    types
                ]);

        if (isLoading)
        {
            return <DragHandle>
                <div />
            </DragHandle>;
        }
        else if (resourcePlanner)
        {
            return <Card
                inset
            >
                <InternalResourcePlanner
                    id={props.widget.id}
                    resourcePlanner={resourcePlanner}
                    name={entity.name}
                    appendix={
                        <MenuButton>
                            <Menu>
                                <MenuItem
                                    name={
                                        <LocalizedText
                                            code="Generic.Delete"
                                            value="Verwijderen"
                                        />
                                    }
                                    onClick={props.onDelete}
                                />
                            </Menu>
                        </MenuButton>
                    }
                    dragHandle
                />
            </Card>;
        }
        else
        {
            return <DragHandle>
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <LocalizedText
                                    code="Dataset.NonExistentWidget"
                                    value="Deze widget bestaat niet meer."
                                />
                            </Centered>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <DeleteButton
                                    onClick={props.onDelete}
                                />
                            </Centered>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </DragHandle>;
        }
    };

export default observer(ResourcePlanner);
