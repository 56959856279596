import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useAllSet from '../../../../../../../../@Future/Util/Hook/useAllSet';
import useCampaignTotalEmailCount from '../Hooks/useCampaignTotalEmailCount';
import useCampaignDeliveredCount from '../Hooks/useCampaignDeliveredCount';
import CampaignPercentageChart from './CampaignPercentageChart';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface CampaignChartDeliveredProps
{
    size: number;
    campaign: Entity;
    caption?: boolean;
}

const CampaignChartDelivered: React.FC<CampaignChartDeliveredProps> =
    props =>
    {
        const campaign = props.campaign;

        const totalEmailCount = useCampaignTotalEmailCount(campaign);
        const deliveredCount = useCampaignDeliveredCount(campaign);

        const allSet =
            useAllSet([
                totalEmailCount,
                deliveredCount
            ]);

        const formattedSelection =
            useMemo(
                () =>
                {
                    return `${deliveredCount} / ${totalEmailCount}`;
                },
                [
                    deliveredCount,
                    totalEmailCount
                ]
            );


        return <div>
            {
                allSet &&
                    <CampaignPercentageChart
                        value={deliveredCount / totalEmailCount * 100}
                        size={props.size}
                        label={props.caption ? localizeText('Campaign.Chart.Delivered', 'Bezorgd') : undefined}
                        selection={formattedSelection}
                    />
            }
        </div>;
    };

CampaignChartDelivered.defaultProps =
{
    caption: true
};

export default observer(CampaignChartDelivered);
