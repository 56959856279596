import './global-this-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import 'reflect-metadata'; // for: https://github.com/mass3ff3ct/mobx-react-inject
import 'eventsource/lib/eventsource-polyfill';
import ErrorBoundary from './@Component/Error/ErrorBoundary';
import { App } from './@Component/App/App';
import * as amplitude from '@amplitude/analytics-browser';
import { GlobalEnvironment } from './@Global/GlobalEnvironment';

const AMPLITUDE_API_KEY = GlobalEnvironment.AMPLITUDE_API_KEY;
const AMPLITUDE_SERVER_URL = GlobalEnvironment.AMPLITUDE_SERVER_URL;

if (AMPLITUDE_API_KEY && AMPLITUDE_API_KEY !== '' &&
    AMPLITUDE_SERVER_URL && AMPLITUDE_SERVER_URL !== ''
)
{
    amplitude.init(AMPLITUDE_API_KEY, {
        serverUrl: AMPLITUDE_SERVER_URL,
        serverZone: 'EU',
        defaultTracking: {
            attribution: false,
            pageViews: false,
            sessions: true,
            formInteractions: false,
            fileDownloads: false
        },
        trackingOptions: {
            ipAddress: true,
            language: true,
            platform: true
        }
    });
}

function render(element: JSX.Element)
{
    ReactDOM.render(
        element,
        document.getElementById('root')
    );
}

render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
