import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './CustomEntity.module.scss';
import { Grid } from '@material-ui/core';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import useTypes from '../../../Type/Api/useTypes';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import Details from '../../Shared/Details/Details';
import Header from './Header/Header';
import RelatedList from '../../Shared/RelatedList/RelatedList';
import IsCompactContext from '../../Context/IsCompactContext';
import isHiddenType from '../../../../../../@Api/Metadata/EntityType/isHiddenType';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';
import PageTabBar, { TabFamily } from '../../Shared/Page/TabBar/PageTabBar';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ActivityList from '../Activity/RelatedActivityList/Model/ActivityList';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../@Util/Id/uuid';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ActivityListItem from '../Activity/RelatedActivityList/Model/ActivityListItem';
import RelatedActivityListRoot from '../Activity/RelatedActivityList/RelatedActivityListRoot';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import getCustomPluralRelatedPaths from '../Activity/Api/getCustomPluralRelatedPaths';
import { useRoutingState } from '../../../../../../@Service/Router/Model/Api/useRoutingState';
import RelatedEntityCount from '../../Shared/RelatedEntityCount/RelatedEntityCount';
import { ResizableTwoColumnLayout } from '../../Shared/ResizableTwoColumLayout.tsx/ResizableTwoColumnLayout';
import { useIsNotLgScreen } from '../../../../../../@Util/Responsiveness/useIsNotLgScreen';

export interface RelationProps
{
    entity: Entity;
    commitContext: CommitContext;
    withRelationship?: Entity;
}

const CustomEntity: React.FC<RelationProps> =
    props =>
    {
        const types = useTypes();
        const isCompact = useContext(IsCompactContext);
        const isNotLgScreen = useIsNotLgScreen();
        const relatedActivityPaths =
            useComputed(
                () => [
                    ...types.Activity.Type
                        .getAllInstantiableTypes()
                        .filter(
                            type =>
                                !isHiddenType(type) &&
                                !type.isA(types.Activity.ApsisCampaign.Type) &&
                                !type.isA(types.Activity.ApsisCampaignResult.Type) &&
                                !type.isA(types.Activity.ApsisForm.Type) &&
                                !type.isA(types.Activity.ApsisFormResult.Type)
                        )
                        .map(
                            activityType =>
                                EntityPath.fromEntity(props.entity)
                                    .joinTo(
                                        types.CustomEntity.RelationshipDefinition.Activities,
                                        false)
                                    .castTo(activityType)),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Attachments,
                            false),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Notes,
                            false)
                ],
                [
                    props.entity,
                    types
                ]
            );
        const isMore =
            useCallback(
                (path: EntityPath) =>
                    !isMutable(path.entityType.entity)
                    && path.entityType.isA(types.Activity.Type)
                    && !path.entityType.isA(types.Activity.Task.Type)
                    && !path.entityType.isA(types.Activity.Appointment.Type),
                [
                    types
                ]
            );
        const relatedListPaths =
            useComputed(
                () =>
                    [ true, false ]
                        .map(
                            isParent =>
                                props.entity.entityType.getInheritedRelationshipDefinitions(isParent)
                                    .filter(
                                        relationshipDefinition =>
                                            relationshipDefinition.isPlural(isParent)
                                                && relationshipDefinition !== types.CustomEntity.RelationshipDefinition.Activities
                                                && !types.Entity.Type.isA(relationshipDefinition.getEntityType(!isParent)))
                                    .map(
                                        relationshipDefinition =>
                                            EntityPath.fromEntity(props.entity)
                                                .joinTo(
                                                    relationshipDefinition,
                                                    isParent)))
                        .reduce((a, b) => a.concat(b), []),
                [
                    types,
                    props.entity
                ]
            );
        const activityList =
            useMemo(
                () =>
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        relatedActivityPaths.map(
                            path =>
                                new ActivityListItem(
                                    new Parameter(
                                        uuid(),
                                        new EntityValueType(path.entityType),
                                        true,
                                        path.entityType.getName()),
                                    path,
                                    true,
                                    isMore(path)))),
                [isMore, props.entity.entityType, relatedActivityPaths]
            );

        const pluralRelatedPaths =
            useComputed(
                () =>
                    getCustomPluralRelatedPaths(props.entity.entityType),
                [
                    props.entity,
                    types
                ]);
        const leftColumn =
            useMemo(
                () =>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Details
                                entity={props.entity}
                            />
                        </Grid>
                        {
                            relatedListPaths.map(
                                relatedListPath =>
                                    <Grid
                                        key={relatedListPath.id}
                                        item
                                        xs={12}
                                    >
                                        <RelatedList
                                            entity={props.entity}
                                            path={relatedListPath}
                                        />
                                    </Grid>)
                        }
                    </Grid>,
            [props.entity, relatedListPaths]
        );
        const [ activityCount, setActivityCount ] = useState<number>();
        const [ tab, setTab ] =
            useRoutingState(
                `Entity.${props.entity.id}.TabId`,
                'Static:Activities'
            );
        const tabFamilies =
            useMemo<TabFamily[]>(
                () => [
                    {
                        id: 'Static',
                        tabs: [
                            ...isNotLgScreen
                                ? [
                                    {
                                        id: 'Details',
                                        name:
                                            <LocalizedText
                                                code="Generic.Details"
                                                value="Details"
                                            />,
                                        content:
                                            () =>
                                                leftColumn
                                    }
                                ]
                                : [],
                            {
                                id: 'Activities',
                                name:
                                    <LocalizedText
                                        code="Generic.Activities"
                                        value="Activiteiten"
                                    />,
                                nameAppendix:
                                    activityCount > 0 && activityCount,
                                content:
                                    tabProps =>
                                        <RelatedActivityListRoot
                                            list={activityList}
                                            entity={props.entity}
                                            onCount={setActivityCount}
                                            showCreationItem
                                            {...tabProps}
                                        />
                            }
                        ]
                    },
                    {
                        id: 'PluralRelatedPaths',
                        tabs:
                            pluralRelatedPaths.map(
                                path =>
                                    ({
                                        id: path.id,
                                        name: path.getName(undefined, false),
                                        nameAppendix:
                                            <RelatedEntityCount
                                                entity={props.entity}
                                                path={path}
                                            />,
                                        content:
                                            () =>
                                                <RelatedEntityView
                                                    entity={props.entity}
                                                    path={path}
                                                />
                                    }))
                    }
                ],
                [isNotLgScreen, activityCount, pluralRelatedPaths, leftColumn, activityList, props.entity]
            );
        const rightColumn =
            <PageTabBar
                entity={props.entity}
                tabFamilies={tabFamilies}
                tabId={tab}
                onChangeTab={setTab}
                commitContext={props.commitContext}
            />;

        if (isCompact)
        {
            return <Header
                entity={props.entity}
            />;
        }
        else
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <div
                        className={styles.header}
                    >
                        <Header
                            entity={props.entity}
                        />
                    </div>
                    <div
                        className={styles.content}
                    >
                        {
                            isNotLgScreen
                                ? rightColumn
                                : <ResizableTwoColumnLayout
                                    id={`EntityType.${types.CustomEntity.Type.id}`}
                                    leftColumn={leftColumn}
                                    rightColumn={rightColumn}
                                />
                        }
                    </div>
                </div>
            </BaseLayout>;
        }
    };

export default observer(CustomEntity);
