import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useComputed } from 'mobx-react-lite';
import { Alert } from '@material-ui/lab';
import uuid from 'uuid';

export interface HeaderNotification
{
    message: string;
    muted?: boolean;
}

export interface HeaderNotificationProps
{
    headerNotifications: HeaderNotification[];
}

export const HeaderNotifications: React.FC<HeaderNotificationProps> = observer(
    ({
         headerNotifications,
     }) =>
    {
        const [ refreshToken, refresh ] = useState<string>();

        const onMute =
            useCallback(
                (notification) =>
                {
                    notification.muted = true
                    refresh(uuid());
                },
                [
                    refresh
                ]
        );

        const contents =
            useComputed(
                () =>
                    headerNotifications
                        .filter(
                            notification =>
                                !notification.muted
                        )
                        .map(
                            notification =>
                                <Alert
                                    severity="error"
                                    onClose={() => onMute(notification)}
                                >
                                    {notification.message}
                                </Alert>
                        ),
                [
                    headerNotifications,
                    refreshToken
                ]
            );

        return <>
            {contents}
         </>
    }
);