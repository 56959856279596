import React from 'react';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import TimeRegistration from './TimeRegistration/TimeRegistration';
import { Page } from '../../../../../../../View/Api/usePaginatedSelection';
import { CommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { isTimeRegistrationVisible } from '../../Api/isTimeRegistrationVisible';

export interface TimeSheetTablePageProps
{
    page: Page;
    filter?: (timeRegistration: Entity) => boolean;
    milestone?: Entity;
    onSelect?: (entity: Entity, isSelected: boolean) => void;
    selectedLinesAsSet: Set<string>;
    hasMore?: boolean;
    showActivity?: boolean;
    showBillingActivity?: boolean;
    canHaveMilestone?: boolean;
    commitContext: CommitContext;
}

const TimeSheetTablePage: React.FC<TimeSheetTablePageProps> =
    props =>
    {
        const timeRegistrations =
            useComputed(
                () =>
                    props.page
                        .map(
                            record =>
                                record.entity
                        )
                        .filter(
                            timeRegistration =>
                                isTimeRegistrationVisible(
                                    timeRegistration,
                                    props.filter
                                )
                        ),
                [
                    props.page,
                    props.filter,
                ]);

        return <>
            {
                timeRegistrations
                    .map(
                        timeRegistration =>
                            <TimeRegistration
                                key={timeRegistration.uuid}
                                entity={timeRegistration}
                                onSelect={props.onSelect}
                                selected={props.selectedLinesAsSet.has(timeRegistration.uuid)}
                                milestone={props.milestone}
                                showActivity={props.showActivity}
                                showBillingActivity={props.showBillingActivity}
                                canHaveMilestone={props.canHaveMilestone}
                                commitContext={props.commitContext}
                            />)
            }
        </>;
    };

export default observer(TimeSheetTablePage);
