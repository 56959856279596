import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../ViewGroup/ViewGroup';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import { FormLinkField } from './FormLinkField';
import ViewGroupItem from '../../../../../ViewGroup/ViewGroupItem';
import RightAlignedButtonGroup from '../../../../../Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../Button/Variant/SaveButton/SaveButton';
import { EntityFieldPath } from '../../../../../../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../../../../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityPathRootNode } from '../../../../../../../../@Component/Domain/Entity/Path/@Model/Node/EntityPathRootNode';
import { FormLinkProperties } from './model/FormLinkProperties';

export interface FormLinkFieldsProps
{
    form: EntityType;
    formLinkProperties: FormLinkProperties;
    onChangeProperties: (properties: FormLinkProperties) => void;
}

export const FormLinkFields: React.FC<FormLinkFieldsProps> =
    observer(
    ({
            form,
            formLinkProperties,
            onChangeProperties
        }) =>
    {
        const formFieldPaths =
            [
                ...form.fields.map(
                    field =>
                        new EntityFieldPath(
                            new EntityPath([
                                new EntityPathRootNode(form)
                            ]),
                            field
                        )
                ),

                ...form.childRelationshipDefinitions
                    .filter(
                        // Support for multivalue relationships is future extension
                        relationshipDefinition => !relationshipDefinition.isPlural(false)
                    )
                    .map(
                        relationshipDefinition =>
                            new EntityFieldPath(
                                EntityPath.root(form)
                                    .joinTo(
                                        relationshipDefinition,
                                        false
                                    )
                            )
                    )
                ]

        const onSaveLinkProperties =
            useCallback(
                () => {
                    onChangeProperties({
                        formId: formLinkProperties.formId,
                        fields: formLinkProperties.fields.slice()
                    });
                },
                [
                    formLinkProperties,
                    onChangeProperties
                ]
            );

        const onChangeFormLinkField =
            useCallback(
                (fieldPath, value) =>
                {
                    const idx =
                        formLinkProperties?.fields.findIndex(prop => prop.fieldPath.code === fieldPath.code);

                    if (idx >= 0)
                    {
                        formLinkProperties.fields[idx] = {
                            fieldPath,
                            value
                        }
                    }
                    else
                    {
                        formLinkProperties.fields.push({
                            fieldPath,
                            value
                        });
                    }

                    onChangeProperties({
                        formId: formLinkProperties.formId,
                        fields: formLinkProperties.fields.slice()
                    });
                },
                [
                    formLinkProperties.fields,
                    formLinkProperties.formId,
                    onChangeProperties
                ]
            );

        return <ViewGroup
                orientation="vertical"
                spacing={5}
            >
            {
                formFieldPaths
                    .map(
                        fieldPath =>
                        {
                            const formLinkField =
                                formLinkProperties?.fields.find(prop => prop.fieldPath.code === fieldPath.code);

                            return <ViewGroupItem>
                                <FormLinkField
                                    fieldPath={fieldPath}
                                    computationValue={formLinkField?.value}
                                    onChange={(param) => onChangeFormLinkField(fieldPath, param)}
                                />
                            </ViewGroupItem>
                        }
                    )
            }
            <ViewGroupItem>
                <RightAlignedButtonGroup>
                    <SaveButton
                        onClick={onSaveLinkProperties}
                    />
                </RightAlignedButtonGroup>
            </ViewGroupItem>
        </ViewGroup>
    }
);