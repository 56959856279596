import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import InputGroup from '../../../../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import Switch from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import { ListQueryEditorProps } from '../ListQueryEditor';
import NumberEditor from '../../../../../../../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../../../../../../@Api/Localization/localizeText';

export interface ListQueryOptionsEditorProps extends ListQueryEditorProps
{

}

const ListQueryOptionsEditor: React.FC<ListQueryOptionsEditorProps> =
    props =>
    {
        const isFirstResultSelected =
            useComputed(
                () =>
                    props.query.offset === 0
                    && props.query.limit === 1,
                [
                    props.query
                ]);

        const toggleFirstResultSelected =
            useCallback(
                (isChecked: boolean) =>
                    runInAction(
                        () =>
                        {
                            if (isChecked)
                            {
                                props.query.offset = 0;
                                props.query.limit = 1;
                            }
                            else
                            {
                                props.query.offset = undefined;
                                props.query.limit = undefined;
                            }
                        }),
                [
                    props.query
                ]);

        const setOffset =
            useCallback(
                (value?: number) =>
                    runInAction(
                        () =>
                            props.query.offset = value),
                [
                    props.query
                ]);

        const setLimit =
            useCallback(
                (value?: number) =>
                    runInAction(
                        () =>
                            props.query.limit = value),
                [
                    props.query
                ]);

        return <InputGroup>
            <Input
                label={
                    <LocalizedText
                        code="Generic.QueryFirstRecord"
                        value="Enkel het eerste record ophalen"
                    />
                }
                labelPosition="left"
            >
                <Switch
                    checked={isFirstResultSelected}
                    onToggle={toggleFirstResultSelected}
                />
            </Input>
            {
                !isFirstResultSelected &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.QueryOffset"
                                value="Index van eerst op te halen record"
                            />
                        }
                        labelPosition="left"
                    >
                        <NumberEditor
                            value={props.query.offset}
                            onChange={setOffset}
                            placeholder={localizeText('FromStart', 'Vanaf begin')}
                            min={0}
                        />
                    </Input>
            }
            {
                !isFirstResultSelected &&
                    <Input
                        label={
                            <LocalizedText
                                code="Generic.QueryLimit"
                                value="Aantal op te halen records"
                            />
                        }
                        labelPosition="left"
                    >
                        <NumberEditor
                            value={props.query.limit}
                            onChange={setLimit}
                            placeholder={localizeText('AllRecords', 'Alle records')}
                            min={0}
                        />
                    </Input>
            }
        </InputGroup>;
    };

export default observer(ListQueryOptionsEditor);
