import { computed, observable } from 'mobx';
import { BaseStore } from '../../../../../../../@Framework/Store/BaseStore';
import { EntityViewerStore } from '../../EntityViewerStore';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { DataObjectStore } from '../../../../../DataObject/DataObjectStore';
import { injectWithQualifier } from '../../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../../@Service/Localization/LocalizationStore';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { RelatedEntityWidgetListStore } from '../WidgetList/RelatedEntityWidgetListStore';
import Text from '../../../../../../Generic/Text/Text';
import { TextStore } from '../../../../../../Generic/Text/TextStore';
import { ViewGroupStore } from '../../../../../../Generic/ViewGroup/ViewGroupStore';
import { ViewGroupItem } from '../../../../../../Generic/ViewGroup/ViewGroupItem';
import { ViewComponent } from '../../../../../../Generic/ViewStack/Model/ViewComponent';
import { ReactViewComponent } from '../../../../../../Generic/ViewStack/Model/ReactViewComponent';
import EntityConstructorButton from '../../../../Button/EntityConstructorButton/EntityConstructorButton';
import localizeText from '../../../../../../../@Api/Localization/localizeText';

export class RelatedEntityViewerStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    @observable viewerStore: EntityViewerStore;
    @observable doReloadEvents: () => Promise<any>;
    @observable widgetListStore: RelatedEntityWidgetListStore;

    // ------------------------ Constructor -------------------------

    constructor(viewerStore: EntityViewerStore,
                doReloadEvents: () => Promise<any>)
    {
        super();

        this.viewerStore = viewerStore;
        this.doReloadEvents = doReloadEvents;
        this.widgetListStore = new RelatedEntityWidgetListStore(viewerStore);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get titleStore(): TextStore
    {
        return new TextStore({
            variant: 'overline',
            label: localizeText('Related', 'Gerelateerd')
        });
    }

    // @computed
    // get constructorButtonStore(): EntityConstructorButtonStore
    // {
    //     return new EntityConstructorButtonStore({
    //         rootEntity: this.viewerStore.entity,
    //         outlined: true,
    //         size: 'small',
    //         iconPosition: 'left',
    //         color: 'primary',
    //         label: 'Nieuw' // ##
    //     });
    // }

    @computed
    get headerGroupStore(): ViewGroupStore
    {
        return new ViewGroupStore({
            direction: 'horizontal',
            items:
            [
                new ViewGroupItem({
                    id: 'title',
                    growRatio: 1,
                    view:
                        new ViewComponent(
                            Text,
                            this.titleStore)
                }),
                new ViewGroupItem({
                    id: 'constructor',
                    view:
                        new ReactViewComponent(
                            EntityConstructorButton,
                            {
                                rootEntity: this.viewerStore.entity,
                            })
                })
            ]
        });
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
