import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../../Editor/LayoutEditor';
import TableLayout from '../../../../../../@Api/Layout/Type/Table/TableLayout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRowSectionsEditor from './RowSectionsEditor/TableRowSectionsEditor';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { dividerColor } from '../../../../../../@Resource/Theme/Theme';

const useStyles = makeStyles({
    root: {
        '& td': {
            border: `1px solid ${dividerColor}`,
        }
    },
});

export interface TableLayoutEditorProps extends LayoutEditorProps<TableLayout>
{

}

const TableLayoutEditor: React.FC<TableLayoutEditorProps> =
    props =>
    {
        const classes = useStyles();

        return <Table
            className={classes.root}
        >
            <TableBody>
                <TableRowSectionsEditor
                    {...props}
                />
            </TableBody>
        </Table>;
    };

export default observer(TableLayoutEditor);
