import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import EmptyValue from '../../Value/EmptyValue';
import Value from '../../Value/Value';
import localizeText from '../../../Localization/localizeText';

export default class DocumentComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable specification: any;

    // ------------------------ Constructor -------------------------

    constructor(specification: any)
    {
        super();

        this.specification = specification;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('RichText'));
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        // For now... This will change when we finish the implementation
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return EmptyValue.instance;
    }

    getName(): string
    {
        return localizeText(
            'FormattedDocument',
            'Opgemaakt document'
        );
    }

    validate(): Validation[]
    {
        return [];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Document';
        descriptor.specification = this.specification;
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new DocumentComputation(descriptor.specification);
    }

    // ----------------------- Private logic ------------------------
}
