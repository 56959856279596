import * as React from 'react';
import { BaseComponent } from '../../../../../@Framework/Component/BaseComponent';
import { ExpressionComputationEditorStore } from './ExpressionComputationEditorStore';
import { observer } from 'mobx-react';
import { ExpressionEditor } from '../../../Expression/Editor/ExpressionEditor';

@observer
export class ExpressionComputationEditor extends BaseComponent<ExpressionComputationEditorStore>
{
    renderComponent(store: ExpressionComputationEditorStore)
    {
        return <ExpressionEditor store={store.expressionEditorStore} />
    }
}
