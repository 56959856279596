import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import Segment from '../../Model/Segment';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export default function updateEntityWithResult(entity: Entity,
                                               segment: Segment,
                                               value: EntitySelectionAggregateResult)
{
    const fieldPath = segment.groupFieldPath;

    if (fieldPath.field)
    {
        return new CommitBuilder()
            .ifValid(
                () => true,
                builder =>
                    fieldPath.path.traverseEntity(entity)
                        .forEach(
                            entity =>
                                builder.setObjectValueInEntity(
                                    entity,
                                    fieldPath.field,
                                    value === undefined
                                        ? undefined
                                        : value.groupValue && value.groupValue.value
                                )
                        )
            )
            .commit();
    }
    else
    {
        return new CommitBuilder()
            .ifValid(
                () => true,
                builder =>
                    fieldPath.path.getPathUntilNode(fieldPath.path.lastJoinNode)
                        .traverseEntity(entity)
                        .forEach(
                            entity =>
                                builder.relateEntityTo(
                                    entity,
                                    fieldPath.path.lastJoinNode.isParent,
                                    fieldPath.path.lastJoinNode.relationshipDefinition,
                                    value.groupEntity
                                )
                        )
            )
            .commit();
    }
}
