import { useCallback, useContext } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export default function useMarkAllAsRead()
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);

    return useCallback(
        async () =>
        {
            const rootPath = EntityPath.root(types.Notification.Type);
            let offset = 0;
            const limit = 50;
            let notifications: Entity[];

            do
            {
                const result =
                    await new EntitySelectionBuilder(rootPath.entityType)
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Notifications,
                                        true),
                                    currentUserStore.employeeEntity))
                        .join(
                            rootPath.joinTo(
                                types.Entity.RelationshipDefinition.Notifications,
                                true))
                        .where(
                            cb =>
                                cb.neq(
                                    new EntityFieldPath(
                                        EntityPath.root(types.Notification.Type),
                                        types.Notification.Field.IsRead),
                                    undefined,
                                    true))
                        .join(
                            rootPath.joinTo(
                                types.Notification.RelationshipDefinition.Initiator,
                                false))
                        .orderBy(
                            rootPath.field(types.Entity.Field.Id),
                            false)
                        .select(offset, limit, true);

                offset += result.length;
                const newNotifications =
                    result.map(
                        result =>
                            result.entity);
                notifications = newNotifications;

                return new CommitBuilder()
                    .ifValid(
                        () => true,
                        builder =>
                            newNotifications.forEach(
                                notification =>
                                    builder.setObjectValueInEntity(
                                        notification,
                                        types.Notification.Field.IsRead,
                                        true
                                    )
                            )
                    )
                    .commit();
            }
            while (notifications.length > 0);
        },
        [
            types,
            currentUserStore
        ]);
}
