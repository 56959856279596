import Validation from '../../Automation/Validation/Validation';
import Dependency from '../../Automation/Parameter/Dependency';
import Value from '../../Automation/Value/Value';
import ValueType from '../../Automation/Value/Type/ValueType';
import LayoutContext from '../LayoutContext';

export default abstract class LayoutAction
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract apply(context: LayoutContext): Promise<Value<any, any>>;

    abstract getReturnType(): ValueType<any>;

    abstract getName(): string;

    abstract validate(): Validation[];

    abstract getDependencies(): Dependency[];

    abstract toDescriptor();

    // ----------------------- Private logic ------------------------
}
