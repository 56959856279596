import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import Dimension from '../Style/Dimension';
import localizeText from '../../Localization/localizeText';

export default class CenteredBodyLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable.ref width: Dimension;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout,
                width?: Dimension)
    {
        super();

        this.layout = layout;
        this.width = width;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.CenteredBody', 'Gecentreerde body');
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'CenteredBody',
            layout: this.layout.toDescriptor(),
            width: this.width?.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new CenteredBodyLayout(
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext),
            descriptor.width
                ?
                    Dimension.fromDescriptor(descriptor.width)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
