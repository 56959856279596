import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import EmptyValue from '../../Value/EmptyValue';
import { DateType } from '../../../../@Component/Domain/DataObject/Type/Date/DateType';
import { format } from 'date-fns';
import { Localizer } from '../../../../@Service/Localization/Localizer';
import localizeText from '../../../Localization/localizeText';

export default class FormattedComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable format: string;
    @observable.ref date: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(format: string, date: Computation<any, any>)
    {
        super();

        this.format = format;
        this.date = date;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('Text'));
    }

    getName(): string
    {
        return localizeText(
            'FormattedDate',
            'Geformatteerde datum...'
        );
    }

    isAsync(): boolean
    {
        return this.date.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const value = await safelyApplyFunction(this.date, context);

        return this.getFormattedDateFromValue(value);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const value = safelySynchronousApplyFunction(this.date, context);

        return this.getFormattedDateFromValue(value);
    }

    getFormattedDateFromValue(value: Value<any, any>): Value<any, any>
    {
        if (value instanceof PrimitiveValue
            && value.value.type instanceof DateType)
        {
            const dateValue: Date = value.value.value;
            const formattedDate =
                format(
                    dateValue,
                    this.format,
                    {
                        locale: loadModuleDirectly(Localizer).dateLocale,
                    }
                );

            if (formattedDate.length === 0)
            {
                return EmptyValue.instance;
            }
            else
            {
                return new PrimitiveValue(
                    DataObject.constructFromTypeIdAndValue(
                        'Text',
                        formattedDate
                    )
                );
            }
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    validate(): Validation[]
    {
        if (!this.format)
        {
            return [
                new Validation(
                    'Error',
                    'Geen geldig datumformaat ingesteld.')
            ];
        }

        if (!this.date)
        {
            return [
                new Validation(
                    'Error',
                    'Geen datum ingesteld')
            ];
        }

        return this.date.validate();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'FormattedDate';
        descriptor.format = this.format;
        descriptor.date = this.date.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.date.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new FormattedComputation(
            descriptor.format,
            await getComputationFromDescriptor(
                descriptor.date,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
