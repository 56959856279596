import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useAvatarSrc from './useAvatarSrc';
import useIcon from './useIcon';
import useAllowCharactersOrIcon from './useAllowCharactersOrIcon';
import useCharacters from './useCharacters';
import useAvatarEntity from './useAvatarEntity';
import useIsEditable from './useIsEditable';

export default function useHasAvatar(entity: Entity,
                                     allowEditMode: boolean = true)
{
    const avatarEntity = useAvatarEntity(entity);
    const src = useAvatarSrc(avatarEntity);
    const allowCharactersOrIcon = useAllowCharactersOrIcon(avatarEntity);
    const icon = useIcon(avatarEntity, allowCharactersOrIcon);
    const characters = useCharacters(avatarEntity, allowCharactersOrIcon);
    const isEditable = useIsEditable(avatarEntity, allowEditMode);

    return src !== undefined || icon !== undefined || characters !== undefined || isEditable;
}
