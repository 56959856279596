import { _allowStateChangesInsideComputed, _getGlobalState, action, computed, IComputedValue, IObservableArray, isObservableArray, isObservableMap, isObservableObject, keys, Lambda, observable, observe, untracked } from 'mobx';
import TransactionalModelImpl from './TransactionalModelImpl';
import { equals, isContextDeep, isContextIncluded } from '../Shared/TransactionUtils';
import TransactionalConfiguration from '../Shared/TransactionalConfiguration';
import TransactionalContext from '../Shared/TransactionalContext';
import { commit, createTransactionalModel, destroyTransactionalModel, getModel, isDirty, isTransactionalModel, rollback, TransactionalModel, whyDirty } from './TransactionalModel';
import TransactionalArray from '../Collection/TransactionalArray';
import TransactionalCommitAdministration from '../Shared/TransactionalCommitAdministration';
import TransactionalMap from '../Collection/TransactionalMap';

export interface Change<T>
{
    oldValue: T;
    newValue: T;
}

export type Guard<T> = (model: TransactionalModel<T>) => boolean;
export type Observer<T> = (change: Change<T>) => void;
export type Interceptor<T> = (change: Change<T>) => Change<T>;
export type Disposer = () => void;

export default class TransactionalModelAdministration<T>
{
    // ------------------------- Properties -------------------------

    @observable.ref model: T;
    @observable.ref transactional: TransactionalModelImpl<T>;
    @observable.ref configuration: TransactionalConfiguration;
    @observable.ref context: TransactionalContext<any>;
    @observable.shallow deletedValues = observable.map<string, boolean>();
    @observable.shallow dirtyValues = observable.map<string, any>();
    @observable.shallow computedValues = observable.map<string, IComputedValue<any>>();
    @observable.shallow deepModels = observable.map<string, TransactionalModel<any>>();
    @observable.shallow deepArrays = observable.map<string, TransactionalArray<any>>();
    @observable.shallow deepMaps = observable.map<string, TransactionalMap<any, any>>();
    @observable.shallow observers = observable.map<string, IObservableArray<Observer<any>>>();
    @observable.shallow interceptors = observable.map<string, IObservableArray<Interceptor<any>>>();
    @observable.shallow commitGuards = observable.array<Guard<T>>();
    @observable.shallow disposers = observable.array<Lambda>();
    @observable isInitialized: boolean;

    // ------------------------ Constructor -------------------------

    constructor(model: T,
                transactional: TransactionalModelImpl<T>,
                configuration: TransactionalConfiguration,
                context: TransactionalContext<any>)
    {
        this.model = model;
        this.transactional = transactional;
        this.configuration = configuration;
        this.context = context;

        this.initialize();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isLocallyDirty(): boolean
    {
        return this.dirtyValues.size > 0
            || this.deletedValues.size > 0;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    initialize()
    {
        // If the original model updates, then also update the transactional value
        keys(this.model)
            .forEach(
                key =>
                    this.disposers.push(
                        observe(
                            this.model,
                            key as any,
                            change =>
                            {
                                if (!this.context.isLocked)
                                {
                                    this.setInTransactional(
                                        key,
                                        change.newValue);
                                }
                            })));

        this.isInitialized = true;
    }

    deinitialize()
    {
        if (this.isInitialized)
        {
            this.isInitialized = false;

            this.disposers.forEach(
                disposer =>
                    disposer());

            this.disposers.clear();
        }
    }

    isDirty(checkAdministration: TransactionalCommitAdministration): boolean
    {
        if (checkAdministration.hasSeen(this.transactional))
        {
            return false;
        }

        checkAdministration.seen(this.transactional);

        return this.isLocallyDirty
            || Array.from(this.deepModels.values())
                .some(model =>
                    isDirty(model, checkAdministration))
            || Array.from(this.deepArrays.values())
                .some(array =>
                    array.isDirty(checkAdministration))
            || Array.from(this.deepMaps.values())
                .some(map =>
                    map.isDirty(checkAdministration));
    }

    isPropertyDirty(property: string): boolean
    {
        return this.dirtyValues.has(property);
    }

    whyDirty(checkAdministration: TransactionalCommitAdministration): any
    {
        if (checkAdministration.hasSeen(this.transactional))
        {
            return undefined;
        }

        checkAdministration.seen(this.transactional);

        const descriptor: any =
            {
                isLocallyDirty: this.isLocallyDirty
            };

        this.deepModels.forEach(
            (model, property) =>
            {
                const deepDescriptor = whyDirty(model, checkAdministration);

                if (deepDescriptor !== undefined)
                {
                    descriptor[property] = deepDescriptor;
                }
            });

        this.deepArrays.forEach(
            (array, property) =>
            {
                const deepDescriptor = array.whyDirty(checkAdministration);

                if (deepDescriptor !== undefined)
                {
                    descriptor[property] = deepDescriptor;
                }
            });

        this.deepMaps.forEach(
            (map, property) =>
            {
                const deepDescriptor = map.whyDirty(checkAdministration);

                if (deepDescriptor !== undefined)
                {
                    descriptor[property] = deepDescriptor;
                }
            });

        this.dirtyValues.forEach(
            (value, property) =>
            {
                if (isTransactionalModel(value))
                {
                    const deepDescriptor = whyDirty(value, checkAdministration);

                    if (deepDescriptor === undefined)
                    {
                        descriptor[property] = value;
                    }
                    else
                    {
                        descriptor[property] = deepDescriptor;
                    }
                }
                else
                {
                    descriptor[property] = value;
                }
            });

        this.deletedValues.forEach(
            (value, property) =>
            {
                descriptor[property] = undefined;
            });

        if (Object.keys(descriptor).length === 1 && !this.isLocallyDirty)
        {
            return undefined;
        }
        else
        {
            descriptor['$'] = this.transactional;

            return descriptor;
        }
    }

    getFromModel<D>(property: string): D
    {
        return (this.model as any)[property];
    }

    @action
    setInModel<D>(property: string,
                  value?: D)
    {
        const isEqualToOriginal =
            equals(
                this.getFromModel(property),
                value);

        if (value === undefined)
        {
            this.deepModels.delete(property);
            this.deepArrays.delete(property);
            this.deepMaps.delete(property);
        }

        // Create transactional in model
        this.resolveTransactional(
            property,
            value);

        if (isEqualToOriginal)
        {
            this.deletedValues.delete(property);
            this.dirtyValues.delete(property);
        }
        else
        {
            (this.model as any)[property] = value;
        }
    }

    getFromTransactional<D>(property: string): D | undefined
    {
        if (this.deletedValues.has(property))
        {
            return undefined;
        }
        else if (this.dirtyValues.has(property))
        {
            return this.dirtyValues.get(property);
        }
        else if (this.deepModels.has(property))
        {
            return this.deepModels.get(property);
        }
        else if (this.deepArrays.has(property))
        {
            return this.deepArrays.get(property)!.transactionalArray as any;
        }
        else if (this.deepMaps.has(property))
        {
            return this.deepMaps.get(property)!.transactionalMap as any;
        }
        else
        {
            return this.resolveTransactional(
                property,
                (this.model as any)[property]);
        }
    }

    @action
    setInTransactional<D>(property: string,
                          value: D)
    {
        const oldValue = this.getFromTransactional(property) as D;

        if (this.interceptors.has(property))
        {
            let lastChange: Change<D>;

            this.interceptors.get(property)!.forEach(
                (interceptor, idx) =>
                {
                    let change: Change<D> =
                        {
                            oldValue:
                                lastChange
                                    ?
                                    lastChange.oldValue
                                    :
                                    oldValue,
                            newValue: value
                        };

                    let newChange = interceptor(change);
                    lastChange = newChange;
                    value = newChange.newValue;
                });
        }

        if (this.observers.has(property))
        {
            let change: Change<D> =
                {
                    oldValue: oldValue,
                    newValue: value
                };

            this.observers.get(property)!.forEach(
                observer =>
                {
                    observer(change);
                });
        }

        const childContext = this.context.joinTo(property, value);

        if (isContextIncluded(childContext))
        {
            const isEqualToOriginal =
                equals(
                    this.getFromModel(property),
                    value);

            const adjustedValue =
                this.resolveTransactional(
                    property,
                    value);

            if (this.dirtyValues.has(property))
            {
                if (isEqualToOriginal)
                {
                    this.dirtyValues.delete(property);
                    this.deletedValues.delete(property);
                    this.deepMaps.delete(property);
                    this.deepArrays.delete(property);
                    this.deepModels.delete(property);
                }
                else
                {
                    if (value === undefined)
                    {
                        this.deletedValues.set(property, true);
                    }
                    else
                    {
                        this.deletedValues.delete(property);
                    }

                    this.dirtyValues.set(
                        property,
                        adjustedValue);
                }
            }
            else
            {
                if (!isEqualToOriginal)
                {
                    if (value === undefined)
                    {
                        this.deletedValues.set(property, true);
                    }
                    else
                    {
                        this.deletedValues.delete(property);
                    }

                    this.dirtyValues.set(
                        property,
                        adjustedValue);
                }
            }
        }
        else
        {
            this.deletedValues.delete(property);
            this.dirtyValues.delete(property);

            this.setInModel(property, getModel(value));
        }
    }

    @action
    commit(administration: TransactionalCommitAdministration): Promise<any>
    {
        if (administration.hasSeen(this.transactional))
        {
            return Promise.resolve();
        }

        administration.seen(this.transactional);

        if (this.commitGuards.some(guard => !guard(this.transactional as any)))
        {
            return Promise.reject('Commit guard rejected commit.');
        }

        const promises: Array<Promise<any>> = [];

        this.deepModels.forEach(
            (model, property) =>
            {
                if (!this.dirtyValues.has(property))
                {
                    promises.push(commit(model, administration));
                }
            });

        this.deepArrays.forEach(
            (array, property) =>
            {
                if (!this.dirtyValues.has(property))
                {
                    promises.push(array.commit(administration));
                }
            });

        this.deepMaps.forEach(
            (map, property) =>
            {
                if (!this.dirtyValues.has(property))
                {
                    promises.push(map.commit(administration));
                }
            });

        this.dirtyValues.forEach(
            (value, property) =>
            {
                const isTransactional = isTransactionalModel(value);

                if (isTransactional)
                {
                    promises.push(commit(value, administration));

                    value = getModel(value);
                }

                this.setInModel(
                    property,
                    value);
            });
        this.dirtyValues.clear();

        this.deletedValues.forEach(
            (_, property) =>
            {
                this.setInModel(
                    property,
                    undefined);
            });
        this.deletedValues.clear();

        return Promise.all(promises);
    }

    @action
    rollback(administration: TransactionalCommitAdministration)
    {
        if (administration.hasSeen(this.transactional))
        {
            return Promise.resolve(this.model);
        }

        administration.seen(this.transactional);

        this.dirtyValues.clear();

        this.deepModels.forEach(
            (model, property) =>
            {
                rollback(model, administration);
            });

        this.deepArrays.forEach(
            (array, property) =>
            {
                array.rollback(administration);
            });

        this.deepMaps.forEach(
            (map, property) =>
            {
                map.rollback(administration);
            });
    }

    @action
    guardCommit(guard: Guard<T>)
    {
        this.commitGuards.push(guard);
    }

    @action
    observe(property: string,
            observer: Observer<T>): Disposer
    {
        if (!this.observers.has(property))
        {
            this.observers.set(
                property,
                observable.array());
        }

        this.observers.get(property)!
            .push(observer);

        return () =>
        {
            this.observers.get(property)!
                .remove(observer);
        };
    }

    @action
    intercept(property: string,
              interceptor: Interceptor<T>): Disposer
    {
        if (!this.interceptors.has(property))
        {
            this.interceptors.set(
                property,
                observable.array());
        }

        this.interceptors.get(property)!
            .push(interceptor);

        return () =>
        {
            this.interceptors.get(property)!
                .remove(interceptor);
        };
    }

    @action
    resolveTransactional(property: string,
                         value: any): any
    {
        const childContext = this.context.joinTo(property, value);

        if (isContextDeep(childContext))
        {
            if (isObservableArray(value))
            {
                const transactionalArray =
                    new TransactionalArray<any>(
                        value,
                        this.configuration,
                        childContext);

                this.disposers.push(
                    () =>
                        transactionalArray.deinitialize());

                // This might be a side effect of a computation
                this.performPossibleSideEffect(
                    () =>
                        this.deepArrays.set(
                            property,
                            transactionalArray));

                return transactionalArray.transactionalArray as any;
            }
            else if (isObservableMap(value))
            {
                const transactionalMap =
                    new TransactionalMap<any, any>(
                        value,
                        this.configuration,
                        childContext);

                this.disposers.push(
                    () =>
                        transactionalMap.deinitialize());

                // This might be a side effect of a computation
                this.performPossibleSideEffect(
                    () =>
                        this.deepMaps.set(
                            property,
                            transactionalMap));

                return transactionalMap.transactionalMap as any;
            }
            else if (isObservableObject(value))
            {
                const transactionalModel =
                    createTransactionalModel(
                        value,
                        this.configuration,
                        childContext);

                this.disposers.push(
                    () =>
                        destroyTransactionalModel(transactionalModel));

                // Might not be the case for some values, because it does not pass all tests to become a transactional
                // model
                if (isTransactionalModel(transactionalModel))
                {
                    // This might be a side effect of a computation
                    this.performPossibleSideEffect(
                        () =>
                            this.deepModels.set(
                                property,
                                transactionalModel));
                }

                return transactionalModel as any;
            }
        }

        return value;
    }

    @action
    performPossibleSideEffect(sideEffect: () => void)
    {
        const globalState = _getGlobalState();

        if (globalState.computationDepth > 0)
        {
            untracked(
                () =>
                {
                    _allowStateChangesInsideComputed(
                        () =>
                        {
                            sideEffect();
                        });
                });
        }
        else
        {
            sideEffect();
        }
    }

    // ----------------------- Private logic ------------------------
}
