import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutActionEditorProps } from '../../LayoutActionEditor';
import PortalActionInvocationAction from '../../../../../../@Api/Layout/Action/Type/PortalActionInvocationAction';
import useDialog from '../../../../../../@Service/Navigation/Page/Hooks/useDialog';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import PortalContext from '../../../../../../@Api/Portal/Context/PortalContext';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import useEntityByUuid from '../../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { runInAction } from 'mobx';
import DynamicParameterAssignment from '../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import ActionSignature from '../../../../../../@Api/Automation/ActionTemplate/Model/ActionSignature';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import EmptyValueType from '../../../../../../@Api/Automation/Value/Type/EmptyValueType';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Selectbox from '../../../../Entity/Selectbox/Selectbox';
import EditIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/EditIconButton/EditIconButton';
import DynamicParameterAssignmentEditor from '../../../../Entity/Viewer/Content/Automation/Editor/DynamicParameterAssignment/DynamicParameterAssignmentEditor';
import PortalActionEditor from './PortalActionEditor/PortalActionEditor';
import getPortalActionSignatureById from '../../../../../../@Api/Portal/Action/Api/getPortalActionSignatureById';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/index';

export interface PortalActionInvocationActionEditorProps extends LayoutActionEditorProps<PortalActionInvocationAction>
{

}

const PortalActionInvocationActionEditor: React.FC<PortalActionInvocationActionEditorProps> =
    props =>
    {
        const { action, context } = props;
        const portalContext = useContext(PortalContext);

        const types = useTypes();
        const [ portalAction, isLoading ] = useEntityByUuid(types.PortalAction.Type, action.actionSignature?.id);
        const setPortalAction =
            useCallback(
                async (value?: Entity) =>
                {
                    if (value?.hasValueForField(types.PortalAction.Field.Specification))
                    {
                        const signature = await getPortalActionSignatureById(value.uuid, context);

                        runInAction(
                            () =>
                            {
                                action.parameterAssignment = action.parameterAssignment.getWithNewParameters(signature.parameters);
                                action.actionSignature = signature;
                            });
                    }
                    else
                    {
                        runInAction(
                            () =>
                            {
                                action.parameterAssignment = new DynamicParameterAssignment();
                                action.actionSignature =
                                    value
                                        ?
                                            new ActionSignature(
                                                value.uuid,
                                                new ParameterDictionary([]),
                                                EmptyValueType.instance)
                                        :
                                        undefined;
                            });
                    }
                },
                [
                    action,
                    context,
                    types
                ]);
        const portalActionSelection =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.PortalAction.Type)
                        .build()
                ],
                [
                    types
                ]);
        const parameters =
            useComputed(
                () =>
                    action.actionSignature?.parameters || new ParameterDictionary([]),
                [
                    action
                ]);

        const [ openEditor ] =
            useDialog(
                close =>
                    <Dialog
                        open
                        onClose={close}
                        width="xl"
                    >
                        <PortalActionEditor
                            entity={portalAction}
                            onCancel={close}
                            onSave={
                                async () =>
                                {
                                    await setPortalAction(portalAction);
                                    close();
                                }
                            }
                            portalContext={portalContext}
                        />
                    </Dialog>,
                [
                    portalContext,
                    setPortalAction,
                    portalAction
                ]);

        const onConstruct =
            useCallback(
                (newDataSource: Entity) =>
                {
                    newDataSource.updateRelationship(
                        true,
                        types.Portal.RelationshipDefinition.Actions,
                        createTransactionalModel(portalContext.portal));
                },
                [
                    types,
                    portalContext
                ]);

        return <CardInset
            top={false}
        >
            <Input
                label={
                    <LocalizedText
                        code="Generic.Action"
                        value="Actie"
                    />
                }
                labelPosition="left"
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem
                        ratio={1}
                    >
                        <Selectbox
                            value={portalAction}
                            onChange={setPortalAction}
                            selections={portalActionSelection}
                            genericSelectboxProps={{
                                disabled: isLoading
                            }}
                            onConstruct={onConstruct}
                        />
                    </ViewGroupItem>
                    {
                        portalAction &&
                            <ViewGroupItem>
                                <EditIconButton
                                    onClick={openEditor}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </Input>
            <DynamicParameterAssignmentEditor
                context={context}
                parameterDictionary={parameters}
                parameterAssignment={action.parameterAssignment}
            />
        </CardInset>;
    };

export default observer(PortalActionInvocationActionEditor);
