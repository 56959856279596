import React, { useCallback, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import TableBody from '@material-ui/core/TableBody';
import { Table } from '@material-ui/core';
import FieldGroupItem from './FieldGroupItem/FieldGroupItem';
import HoverCardBottom from '../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { createTransactionalModel } from '../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import usePaginatedSelection from '../../../../Entity/View/Api/usePaginatedSelection';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';

export interface FieldGroupsEditorProps
{
    entityType: EntityType;
}

const FieldGroupsEditor: React.FC<FieldGroupsEditorProps> =
    props =>
    {
        const { entityType } = props;
        const types = useTypes();
        const [ pages, hasMore, loadMore, isLoading ] =
            usePaginatedSelection(
                types.EntityFieldGroup.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.or(
                                    ob =>
                                        entityType.getInheritedTypes()
                                            .forEach(
                                                inheritedType =>
                                                    ob.relatedToEntity(
                                                        rootPath
                                                            .joinTo(
                                                                types.EntityType.RelationshipDefinition.FieldGroups,
                                                                true
                                                            ),
                                                        inheritedType.entity
                                                    )
                                            )
                                )
                        ),
                [
                    entityType,
                    types
                ],
                50
            );

        const groups =
            useComputed(
                () =>
                    pages
                        .reduce(
                            (a, b) =>
                                a.slice().concat(b.slice()),
                                []
                        )
                        .map(
                            result =>
                                result.entity
                        ),
                [
                    pages
                ]
            );

        const [ newFieldGroup, setNewFieldGroup ] = useState<Entity | undefined>();
        const constructNewFieldGroup =
            useCallback(
                () =>
                {
                    const newFieldGroup =
                        createTransactionalModel(
                            new Entity(types.EntityFieldGroup.Type)
                                .initialize());

                    newFieldGroup.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.FieldGroups,
                        createTransactionalModel(entityType.entity));

                    setNewFieldGroup(newFieldGroup);
                },
                [
                    types,
                    entityType,
                    setNewFieldGroup
                ]);
        const closeNewFieldGroup =
            useCallback(
                () =>
                    setNewFieldGroup(undefined),
                [
                    setNewFieldGroup
                ]);

        return <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            {...{
                                width: '15%'
                            }}
                        >
                            <LocalizedText
                                code="Generic.Name"
                                value="Naam"
                            />
                        </TableCell>
                        <TableCell
                            {...{
                                width: '14%'
                            }}
                        >
                            <LocalizedText
                                code="Configuration.Field.DataType"
                                value="Datatype"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Expanded"
                                value="Uitgeklapt"
                            />
                        </TableCell>
                        <TableCell>
                            <LocalizedText
                                code="Generic.Visibility"
                                value="Zichtbaarheid"
                            />
                        </TableCell>
                        <TableCell>
                            {types.Pack.Type.getName()}
                        </TableCell>
                        <TableCell
                            {...{
                                width: '10%'
                            }}
                        >
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        groups?.map(
                            group =>
                                <FieldGroupItem
                                    key={group.uuid}
                                    entityType={entityType}
                                    fieldGroup={group}
                                />)
                    }
                    {
                        newFieldGroup &&
                            <FieldGroupItem
                                entityType={entityType}
                                fieldGroup={newFieldGroup}
                                onClose={closeNewFieldGroup}
                            />
                    }
                </TableBody>
            </Table>
            {
                hasMore &&
                <ViewGroupItem>
                    <HoverCardBottom
                        onClick={loadMore}
                        disabled={isLoading}
                    >
                        <LocalizedText
                            code="Generic.LoadMore"
                            value="Meer laden"
                        />...
                    </HoverCardBottom>
                    <Divider />
                </ViewGroupItem>
            }
            {
                !newFieldGroup &&
                    <HoverCardBottom
                        onClick={constructNewFieldGroup}
                    >
                        + {types.EntityFieldGroup.Type.getName()}
                    </HoverCardBottom>
            }
        </>;
    };

export default observer(FieldGroupsEditor);
