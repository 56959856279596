import Widget from '../../Widget';
import { observable, runInAction } from 'mobx';
import collectAndInitializeByEntityIds from '../../../Api/collectAndInitializeByEntityIds';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';

export default class FileWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable attachmentId: string;
    @observable.ref attachment: Entity;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                attachmentId?: string)
    {
        super(id);

        this.attachmentId = attachmentId;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: FileWidget[])
    {
        return collectAndInitializeByEntityIds(
            widgets,
            widget => widget.attachmentId,
            async (widget, entity) =>
                runInAction(
                    () =>
                        widget.attachment = entity
                ),
            loadModuleDirectly(EntityTypeStore).bespoke.types.Attachment.Type);
    }

    static fromDescriptor(descriptor: any)
    {
        return new FileWidget(
            descriptor.id,
            descriptor.attachmentId);
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'File',
            attachmentId: this.attachmentId
        }
    }

    // ----------------------- Private logic ------------------------
}
