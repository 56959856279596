import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { observable } from 'mobx';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../Type/EntityTypeStore';

export default class AggregateValue
{
    // ------------------------- Properties -------------------------

    @observable.ref fieldPath: EntityFieldPath;
    @observable aggregate: Aggregate;

    // ------------------------ Constructor -------------------------

    constructor(fieldPath: EntityFieldPath,
                aggregate: Aggregate)
    {
        this.fieldPath = fieldPath;
        this.aggregate = aggregate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get isValid()
    {
        return this.fieldPath !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromDescriptor(descriptor: any)
    {
        return new AggregateValue(
            EntityFieldPath.construct(
                descriptor.fieldPath,
                loadModuleDirectly(EntityTypeStore)),
            (Aggregate as any)[descriptor.aggregate]);
    }

    toDescriptor()
    {
        return {
            fieldPath: this.fieldPath.descriptor,
            aggregate: Aggregate[this.aggregate]
        };
    }

    // ----------------------- Private logic ------------------------
}
