import { action, observable } from 'mobx';
import { injectWithQualifier } from '../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../Entity/Type/EntityTypeStore';
import { Localizer } from '../../../@Service/Localization/Localizer';
import { SearchBarStore } from '../../Generic/SearchBar/SearchBarStore';
import { RouterStore } from '../../../@Service/Router/RouterStore';
import { RoutingListener } from '../../../@Service/Router/Model/RoutingListener';
import { PageStore } from '../../../@Service/Navigation/Page/PageStore';
import { BaseComponentProps } from '../../../@Framework/Component/BaseComponent';
import { Search } from './Search';
import { Route } from '../../../@Service/Router/Model/Route';
import { LocalizationStore } from '../../../@Service/Localization/LocalizationStore';
import { DrawerStore } from '../../Generic/Drawer/DrawerStore';
import { CurrentUserStore } from '../User/CurrentUserStore';
import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { openEntity } from '../Entity/@Util/openEntity';

export class SearchStore extends PageStore implements RoutingListener
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('Localizer') localizer: Localizer;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('LeftDrawerStore') drawerStore: DrawerStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;

    // ------------------------- Properties -------------------------

    @observable searchBarStore: SearchBarStore<Entity[]>;

    // ------------------------ Constructor -------------------------

    constructor(searchBarStore: SearchBarStore)
    {
        super(0);

        this.searchBarStore = searchBarStore;
    }

    // ----------------------- Initialization -----------------------

    entersUI(isMounted: boolean)
    {
        super.entersUI(isMounted);

        this.routerStore.registerListener(this);
    }

    exitsUI(isUnmounted: boolean): void
    {
        super.exitsUI(isUnmounted);

        this.routerStore.unregisterListener(this);
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    onOpen(entity: Entity)
    {
        return openEntity(entity);
    }

    @action.bound
    closeSearch()
    {
        this.searchBarStore.clear();
    }

    // ------------------------ Public logic ------------------------

    onRoute(route: Route, parameters: any): void
    {
        this.searchBarStore.deactivate();
    }

    get componentClass(): React.ComponentClass<BaseComponentProps<PageStore>>
    {
        return Search;
    }

    get name(): string
    {
        return this.localizationStore.translate('Search.Name'); // Search
    }

    get wallpaper(): string
    {
        return this.currentUserStore.wallpaperImageUrl;
    }

    // ----------------------- Private logic ------------------------
}
