import React from 'react';
import { observer } from 'mobx-react-lite';
import DynamicFunctionInvocationEditor from '../../../Dynamic/DynamicFunctionInvocationEditor';
import { ComputationEditorProps } from '../../ComputationEditor';
import DynamicComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/DynamicComputation';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';

export interface DynamicComputationEditorProps extends ComputationEditorProps<ValueType<any>, DynamicComputation>
{

}

const DynamicComputationEditor: React.FC<DynamicComputationEditorProps> =
    props =>
    {
        return <DynamicFunctionInvocationEditor
            context={props.context}
            invocation={props.value.invocation}
        />;
    };

export default observer(DynamicComputationEditor);
