import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';

export interface PrimaryTextButtonProps extends LabelButtonProps
{

}

const PrimaryTextButton: React.FC<PrimaryTextButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

PrimaryTextButton.defaultProps = {
    color: primaryColor,
    hoverColor: primaryColor
};

export default PrimaryTextButton;
