import PortalDataSource from '../PortalDataSource';
import getEntityByUuid from '../../../Entity/Bespoke/getEntityByUuid';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import AutomationDependencyContext from '../../../Automation/AutomationDependencyContext';

export default async function getPortalDataSourceById(dataSourceId: string,
                                                      dependencyContext: AutomationDependencyContext): Promise<PortalDataSource>
{
    const types = getTypes();
    const { value: entity } = await getEntityByUuid(types.PortalDataSource.Type, dataSourceId);
    const specification = entity.getObjectValueByField(types.PortalDataSource.Field.Specification);
    const dataSource = await PortalDataSource.fromDescriptor(entity.uuid, entity.name, specification, dependencyContext);

    return dataSource;
}
