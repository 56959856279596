import React from 'react';
import { observer } from 'mobx-react-lite';

export interface DateItemProps
{
    date: Date;
}

const DateItem: React.FC<DateItemProps> =
    props =>
    {
        return <>{props.children}</>;
    };

export default observer(DateItem);
