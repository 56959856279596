import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { EntityCacheInformation } from './EntityCacheInformation';
import { EntityCacheReferrer } from './EntityCacheReferrer';
import { EntityRelationshipDefinition } from '../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { EntityRelationship } from '../../../@Api/Model/Implementation/EntityRelationship';

export class EntityRelationshipsCacheReferrerService
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    cacheInformationByKey: Map<string, EntityCacheInformation>;
    keysByEntityUuid: Map<string, Set<string>>;
    cacheInformationsByReferrer: Map<EntityCacheReferrer, Set<EntityCacheInformation>>;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        this.cacheInformationByKey = new Map<string, EntityCacheInformation>();
        this.keysByEntityUuid = new Map<string, Set<string>>();
        this.cacheInformationsByReferrer = new Map<EntityCacheReferrer, Set<EntityCacheInformation>>();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    registerReferrer(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        referrer: EntityCacheReferrer
    )
    {
        const cacheKey = this.getCacheKey(entity, relationshipDefinition, isParent);
        let cacheInformation = this.cacheInformationByKey.get(cacheKey);

        if (cacheInformation)
        {
            cacheInformation.addReferrer(referrer);
        }
        else
        {
            cacheInformation = new EntityCacheInformation(new Set([ referrer ]));
            this.cacheInformationByKey.set(
                cacheKey,
                cacheInformation
            );

            const keysOfEntityUuid = this.keysByEntityUuid.get(entity.uuid);

            if (keysOfEntityUuid)
            {
                keysOfEntityUuid.add(cacheKey);
            }
            else
            {
                this.keysByEntityUuid.set(
                    entity.uuid,
                    new Set([ cacheKey ])
                );
            }
        }

        this.addCacheInformationToReferrer(
            referrer,
            cacheInformation
        );
    }

    isInitialized(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean
    ): boolean
    {
        const cacheKey = this.getCacheKey(entity, relationshipDefinition, isParent);

        return this.cacheInformationByKey.has(cacheKey);
    }

    private getCacheKey(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean
    )
    {
        return `${entity.uuid}.${relationshipDefinition.id}.${isParent}`;
    }

    private addCacheInformationToReferrer(
        referrer: EntityCacheReferrer,
        information: EntityCacheInformation
    )
    {
        let referredInformations = this.cacheInformationsByReferrer.get(referrer);

        if (referredInformations === undefined)
        {
            referredInformations = new Set<EntityCacheInformation>();

            this.cacheInformationsByReferrer.set(
                referrer,
                referredInformations
            );
        }

        referredInformations.add(information);
    }

    disposeReferrer(referrer: EntityCacheReferrer)
    {
        const informations = this.cacheInformationsByReferrer.get(referrer);

        if (informations !== undefined)
        {
            informations.forEach(
                information =>
                    information.removeReferrer(referrer)
            );
        }

        this.cacheInformationsByReferrer.delete(referrer);
    }

    disposeEntity(entity: Entity)
    {
        const cacheKeysToDelete = this.keysByEntityUuid.get(entity.uuid);

        if (cacheKeysToDelete)
        {
            this.keysByEntityUuid.delete(entity.uuid);

            cacheKeysToDelete.forEach(
                key =>
                    this.cacheInformationByKey.delete(key)
            );
        }
    }

    disposeRelationship(relationship: EntityRelationship)
    {
        this.disposeRelationshipSide(relationship, true);
        this.disposeRelationshipSide(relationship, false);
    }

    private disposeRelationshipSide(
        relationship: EntityRelationship,
        isParent: boolean
    )
    {
        const entity = relationship.getEntity(isParent);
        const cacheKey = this.getCacheKey(entity, relationship.definition, !isParent);
        const keys = this.keysByEntityUuid.get(entity.uuid);

        if (keys)
        {
            keys.delete(cacheKey);
        }

        this.cacheInformationByKey.delete(cacheKey);
    }

    clear()
    {
        this.cacheInformationByKey.clear();
        this.keysByEntityUuid.clear();
    }

    // ----------------------- Private logic ------------------------
}
