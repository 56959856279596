import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { HexagonIconStore } from './HexagonIconStore';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon, Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import { PositionProperty } from 'csstype';

const baseSize = 35;
const topBottomBase =
    {
        position: 'absolute' as PositionProperty,
        zIndex: 1,
        width: 24.75,
        height: 24.75,
        overflow: 'hidden',
        transform: `scaleY(0.5774) rotate(-45deg)`,
        background: 'inherit',
        left: 4.13
    };

const topBottomAfterBase =
    {
        content: '""',
        position: 'absolute' as PositionProperty,
        width: 33,
        height: 19.05255888,
        transform: `rotate(45deg) scaleY(1.7321) translateY(-8.9489px)`,
        transformOrigin: `0 0`,
        background: 'inherit'
    };

const styles = (theme: Theme) =>
    createStyles({
        hexagonRoot:
        {
            'position' : 'relative',
            'width' : 35,
            'height': 20.21,
            'margin': `10.10px 0`,
            'backgroundSize': `auto 38.1051px`,
            'backgroundPosition': 'center',
            '&:after':
            {
                content: '""',
                position: 'absolute',
                top: 0.5774,
                left: 0,
                width: 33,
                height: 19.0526,
                zIndex: 2,
                background: 'inherit'
            }
        },
        hexagonTop:
        {
            ...topBottomBase,
            '&:after':
            {
                ...topBottomAfterBase,
                backgroundPosition: 'center top' as PositionProperty
            },
            'top' : -12.3744
        },
        hexagonBottom:
        {
            ...topBottomBase,
            '&:after':
            {
                ...topBottomAfterBase,
                backgroundPosition: 'center bottom'
            },
            'bottom' : -12.3744
        }
    });

@observer
export class HexagonIcon extends BaseComponent<HexagonIconStore>
{
    renderComponent(store: HexagonIconStore)
    {
        return <div
            style={{
                transform: `scale(${store.size / baseSize})`
            }}
        >
            {
                store.icon &&
                    <Icon
                        style={{
                            color: store.iconColor,
                            zIndex: 3,
                            position: 'absolute',
                            top: -2,
                            left: 6
                        }}
                    >
                        {store.icon}
                    </Icon>
            }
            <div
                className={(this.props as any).classes.hexagonRoot}
                style={{
                    backgroundImage:
                        store.imageUrl
                            ?
                                `url(${store.imageUrl})`
                            :
                                undefined,
                    backgroundColor:
                        !store.imageUrl && store.color
                            ?
                                store.color
                            :
                                undefined,
                    borderLeft: `1px solid ${store.borderColor}`,
                    borderRight: `1px solid ${store.borderColor}`
                }}
            >
                <div
                    className={(this.props as any).classes.hexagonTop}
                    style={{
                        borderTop: `1.8284px solid ${store.borderColor}`,
                        borderRight: `1.8284px solid ${store.borderColor}`
                    }}
                />
                <div
                    className={(this.props as any).classes.hexagonBottom}
                    style={{
                        borderBottom: `1.8284px solid ${store.borderColor}`,
                        borderLeft: `1.8284px solid ${store.borderColor}`
                    }}
                />
            </div>
        </div>;
    }

    // TODO [MS]: convert calculations
    renderNoBorder(store: HexagonIconStore)
    {
        return <div
            style={{
                width: `${store.size}px`,
                height: `${store.height}px`,
                position: 'relative',
                margin: `${store.height / 2}px 0`,
                display: 'inline-block',
                backgroundImage: `url(${store.imageUrl})`,
                backgroundSize: `auto 46px`, // TODO [MS]: compute
                backgroundPosition: 'center'
            }}
        >
            <div style={store.topStyle} />
            <div style={store.middleStyle}>
                {
                    store.hasShadow &&
                        <span style={store.coverStyle} />
                }
            </div>
            <div style={store.bottomStyle} />

            {
                store.icon &&
                    <Icon
                        style={{
                            position: 'absolute',
                            color: store.iconColor,
                            fontSize: `${store.height}px`,
                            width: `${store.size}px`,
                            textAlign: 'center',
                            marginTop: `-${store.height / 2}px`,
                            zIndex: 5,
                            lineHeight: `${store.height * 2}px`
                        }}
                    >
                        {store.icon}
                    </Icon>
            }
        </div>;
    }
}

export default withStyles(styles)(HexagonIcon);
