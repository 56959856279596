import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../Entity/Type/Api/getTypes';
import { useComputed } from 'mobx-react-lite';

export default function useIsConnectorActivationActivated(connectorActivation: Entity)
{
    const types = getTypes();

    return useComputed<boolean>(
        () =>
            connectorActivation &&
                connectorActivation.getObjectValueByField(types.ConnectorActivation.Field.IsActivated),
        [
            types,
            connectorActivation
        ]
    );
}
