import React from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import { SharePackEditor } from '../../../../Configuration/Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';
import { usePackSelector } from '../../../../Configuration/Page/Packs/MySharedPackManager/Api/usePackSelector';
import Divider from '../../../../../../@Future/Component/Generic/Divider/Divider';
import Details from '../../Shared/Details/Details';

export interface DefaultProps
{
    entity: Entity;
}

const Default: React.FC<DefaultProps> =
    ({
        entity,
     }) =>
    {
        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(entity);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                {
                                    isPackSelectorVisible &&
                                    <React.Fragment>
                                        <Divider />
                                        <CardInset>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <SharePackEditor
                                                        entity={entity}
                                                        ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </CardInset>
                                    </React.Fragment>
                                }
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Details
                                entity={entity}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Default);
