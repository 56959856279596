import React from 'react';
import { observer } from 'mobx-react-lite';
import FormField from '../../../../Model/FormField';
import { FormFieldValue } from '../../../../Model/FormFieldValue';
import PrimitiveValueEditor from './Primitive/PrimitiveValueEditor';
import OptionValueEditor from './Option/OptionValueEditor';
import Form from '../../../../Model/Form';

export interface FieldValueEditorProps
{
    form: Form;
    field: FormField;
    value?: FormFieldValue;
    onChange: (value?: FormFieldValue) => void;
    placeholder?: string;
    disabled?: boolean;
}

const FieldValueEditor: React.FC<FieldValueEditorProps> =
    props =>
    {
        if (props.field.type === 'Option')
        {
            return <OptionValueEditor
                {...props}
            />;
        }
        else
        {
            return <PrimitiveValueEditor
                {...props}
            />;
        }
    };

export default observer(FieldValueEditor);
