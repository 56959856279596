import ValueType from './ValueType';
import { EntityType } from '../../../Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../@Component/Domain/Entity/Type/EntityTypeStore';

export default class EntityValueType extends ValueType<EntityType>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return `Entity(${this.type.isStaticType() ? this.type.code : this.type.id})`;
    }

    isA(type: ValueType<any>): boolean
    {
        if (type instanceof EntityValueType)
        {
            return this.type.isA(type.type);
        }
        else
        {
            return false;
        }
    }

    getName(): string
    {
        return this.type.getName();
    }

    toDescriptor()
    {
        return {
            type: 'Entity',
            typeId: this.type.id
        };
    }

    static fromDescriptor(descriptor: any)
    {
        return new EntityValueType(
            loadModuleDirectly(EntityTypeStore).getTypeById(
                descriptor.typeId));
    }

    // ----------------------- Private logic ------------------------
}
