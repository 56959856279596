import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Query from '../../Query/Query';
import Validation from '../../Validation/Validation';
import getQueryFromDescriptor from '../../Api/getQueryFromDescriptor';
import FunctionContext from '../FunctionContext';
import localizeText from '../../../Localization/localizeText';

export type TypeHint = 'List' | 'Aggregate';

export default class QueryComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref query: Query;
    @observable _typeHint: TypeHint;

    // ------------------------ Constructor -------------------------

    constructor(query: Query,
                _typeHint?: TypeHint)
    {
        super();

        this.query = query;
        this._typeHint = _typeHint;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return this.query.getType();
    }

    isAsync(): boolean
    {
        return true;
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        return this.query.execute(context);
    }

    getName(): string
    {
        return localizeText(
            'Computation.ListQuery',
            'Query...'
        );
    }

    validate(): Validation[]
    {
        if (this.query)
        {
            return this.query.validate();
        }
        else
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'NoQueryDefined',
                        'Je hebt nog geen query gedefinieerd.'
                    )
                )
            ];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Query';
        descriptor.query = this.query.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.query.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new QueryComputation(
            await getQueryFromDescriptor(
                descriptor.query,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
