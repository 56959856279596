// https://stackoverflow.com/a/14572494
export default function trimNewlines(value: string | undefined)
{
    if (typeof value === 'string')
    {
        return value.replace(/^\s+|\s+$/g, '');
    }
    else
    {
        return value;
    }
}
