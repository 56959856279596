import { PageStore } from '../../../../../@Service/Navigation/Page/PageStore';
import { computed, observable } from 'mobx';
import { EntityViewerStore } from '../Deprecated/EntityViewerStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { RouterStore } from '../../../../../@Service/Router/RouterStore';
import { EntityViewerPage } from './EntityViewerPage';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { SelectionCursor } from '../SelectionCursor/SelectionCursor';

export class EntityViewerPageStore extends PageStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('RouterStore') routerStore: RouterStore;
    @injectWithQualifier('CurrentUserStore') currentUserStore: CurrentUserStore;
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    @observable.ref entity: Entity;
    @observable.ref entityViewerStore: EntityViewerStore;
    @observable.ref selectionCursor: SelectionCursor;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                entityViewerStore: EntityViewerStore)
    {
        super(0);

        this.entity = entity;
        this.entityViewerStore = entityViewerStore;
        this.hasPagePadding = entityViewerStore.isConfigurationType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get showNewViewer(): boolean
    {
        return !this.entityViewerStore.entity.entityType.bespoke.isConfigurationType();
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    get componentClass(): React.ComponentClass<BaseComponentProps<PageStore>>
    {
        return EntityViewerPage;
    }

    get routePath(): string
    {
        return `/entity/${this.entityViewerStore.entity.id}`;
    }

    get name(): string
    {
        if (this.entityViewerStore)
        {
            return this.entityViewerStore.entity.name;
        }
        else
        {
            return undefined;
        }
    }

    get icon(): string
    {
        if (this.entityViewerStore)
        {
            return this.entityViewerStore.entity.entityType.inheritedIcon;
        }
        else
        {
            return undefined;
        }
    }

    get color(): string
    {
        if (this.entityViewerStore)
        {
            return this.entityViewerStore.entity.entityType.inheritedColor;
        }
        else
        {
            return undefined;
        }
    }

    // ----------------------- Private logic ------------------------
}
