import { EntityType } from '../Model/Implementation/EntityType';
import useSetting from '../../@Component/Domain/Setting/Api/useSetting';
import { SettingSource } from '../../@Component/Domain/Setting/SettingStore';
import { useComputed } from 'mobx-react-lite';
import { useCallback } from 'react';

export default function useMetadataSettingValue<T>(entityType: EntityType,
                                                   code: string): [ T | undefined, (value: T | undefined) => void ]
{
    const [ setting, setSetting ] = useSetting<any>(SettingSource.Organization, code, true);
    const value =
        useComputed(
            () =>
            {
                if (setting)
                {
                    return setting[entityType.id];
                }
                else
                {
                    return undefined;
                }
            },
            [
                setting,
                entityType
            ]);
    const setValue =
        useCallback(
            (value: T | undefined) =>
            {
                const newSetting: any = {
                    ...setting
                };

                if (value === undefined)
                {
                    delete newSetting[entityType.id];
                }
                else
                {
                    newSetting[entityType.id] = value;
                }

                setSetting(newSetting);
            },
            [
                setting,
                setSetting,
                entityType
            ]);

    return [ value, setValue ];
}
