import React from 'react';
import { observer } from 'mobx-react-lite';
import ExpansionButton, { ExpansionButtonProps } from '../../../../../../../../../../@Future/Component/Generic/Button/Variant/Expansion/ExpansionButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface PathSelectorExpansionButtonProps extends ExpansionButtonProps
{

}

const useStyles = makeStyles({
    chipHeader: {
        fontSize: '14px',
        fontWeight: 500,
        background: 'rgb(123, 123, 123)',
        color: 'white',
        borderColor: 'rgb(123, 123, 123)',
        fontFamily: '"Roboto", sans-serif',
        lineHeight: '1.5',
        position: 'relative',
        display: 'inline-block',
        padding: '0 12px',
        borderRadius: '15px',
        margin: 0,
        transition: '0.1s',
        boxSizing: 'border-box',
        height:'23px',
        textAlign: 'center'
    }
});

const PathSelectorExpansionButton: React.FC<PathSelectorExpansionButtonProps> =
    props =>
    {
        const classes = useStyles();

        return <ExpansionButton
            {...props}
            forceDisplayLabel
            chevronPosition="end"
            classes={{
                root: classes.chipHeader
            }}
        />;
    };

export default observer(PathSelectorExpansionButton);
