import * as React from 'react';
import { Checkbox as MuiCheckbox, Theme, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../@Framework/Component/BaseComponent';
import { CheckboxStore } from './CheckboxStore';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class Checkbox extends BaseComponent<CheckboxStore>
{
    renderLoader(store: CheckboxStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: CheckboxStore)
    {
        return <div
            style={{
                visibility: store.isVisible ? 'visible' : 'hidden',
                width: store.size,
                height: store.size
            }}
        >
            <MuiCheckbox
                checked={store.isSelected}
                onChange={store.handleChange}
                disabled={store.isDisabled}
                color="primary"
                style={{
                    width: store.size,
                    height: store.size
                }}
            />
        </div>;
    }
}

export default withStyles(styles)(Checkbox as any);
