import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './HeaderWithScrollBodyView.module.scss';

export interface HeaderWithScrollBodyViewProps
{
    header: React.ReactNode;
    body: React.ReactNode;
}

const HeaderWithScrollBodyView: React.FC<HeaderWithScrollBodyViewProps> =
    props =>
    {
        return <div
            className={styles.root}
        >
            <div
                className={styles.header}
            >
                {props.header}
            </div>
            <div
                className={styles.body}
            >
                {props.body}
            </div>
        </div>;
    };

export default observer(HeaderWithScrollBodyView);
