import React, { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import StaticSelectbox, { StaticOption } from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import DataObjectContext from '../../../../../../DataObject/DataObjectContext';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import CollectionType from '../../../../../../../../@Api/Automation/Value/Type/CollectionType';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PortalContext from '../../../../../../../../@Api/Portal/Context/PortalContext';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import DataSourceValueType from '../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import PortalDataSource from '../../../../../../../../@Api/Portal/DataSource/PortalDataSource';
import AutomationDependencyContext from '../../../../../../../../@Api/Automation/AutomationDependencyContext';
import MapType from '../../../../../../../../@Api/Automation/Value/Type/MapType';

export interface ValueTypeEditorProps
{
    value: ValueType<any>;
    onChange: (value?: ValueType<any>) => void;
    parentType?: ValueType<any>;
    autoFocus?: boolean;
}

function getTypeName(type: ValueType<any>): string
{
    if (type instanceof CollectionType)
    {
        return `${getTypeName(type.type)} (${localizeText('Generic.Collection', 'Collectie')})`;
    }
    else if (type instanceof MapType)
    {
        return `${getTypeName(type.type)} (${localizeText('Generic.Map', 'Map')})`;
    }
    else if (type instanceof EntityValueType)
    {
        return `${type.getName()} (${type.type.code})`;
    }
    else
    {
        return type.getName();
    }
}

const ValueTypeEditor: React.FC<ValueTypeEditorProps> =
    props =>
    {
        const { value, onChange, parentType, autoFocus } = props;

        const dataObjectStore = useContext(DataObjectContext);
        const entityTypeStore = useContext(EntityTypeContext);
        const portalContext = useContext(PortalContext);
        const types = useTypes();

        const singleTypes =
            useMemo<ValueType<any>[]>(
                () => [
                    ...dataObjectStore.types
                        .map(
                            type => new PrimitiveValueType(type)),
                    ...entityTypeStore.types
                        .filter(
                            type =>
                                !type.isStaticType())
                        .map(
                            type =>
                                new EntityValueType(type))
                ].filter(
                    type =>
                        !parentType || type.isA(parentType)),
                [
                    dataObjectStore,
                    entityTypeStore,
                    parentType
                ]);

        const [ portalDataSourceTypes ] =
            useAsyncResult(
                async () =>
                {
                    if (portalContext)
                    {
                        return EntitySelectionBuilder.builder(
                            types.PortalDataSource.Type,
                            (builder, rootPath) =>
                                builder.where(
                                    cb =>
                                        cb.relatedToEntity(
                                            rootPath.joinTo(
                                                types.Portal.RelationshipDefinition.DataSources,
                                                true),
                                            portalContext.portal)))
                            .select()
                            .then(
                                results =>
                                    Promise.all(
                                        results.map(
                                            async result =>
                                            {
                                                const specification = result.entity.getObjectValueByField(types.PortalDataSource.Field.Specification);

                                                if (specification)
                                                {
                                                    try
                                                    {
                                                        const dataSource =
                                                            await PortalDataSource.fromDescriptor(
                                                                result.entity.uuid,
                                                                result.entity.name,
                                                                specification,
                                                                new AutomationDependencyContext(portalContext.parameters)
                                                            );

                                                        return {
                                                            entity: result.entity,
                                                            dataSource: dataSource
                                                        };
                                                    }
                                                    catch (e)
                                                    {
                                                        console.log('Cannot deserialize data source specification with ID:', result.entity.uuid, e);

                                                        return undefined;
                                                    }
                                                }
                                                else
                                                {
                                                    return undefined;
                                                }
                                            })))
                            .then(
                                results =>
                                    Promise.resolve(
                                        results
                                            .filter(result => result !== undefined)
                                            .map(
                                                result => ({
                                                    id: `DataSource(${result.entity.uuid})`,
                                                    label: `${localizeText('Generic.DataSourceValue', 'Databron item')}: ${result.entity.name}`,
                                                    value: `DataSource(${result.entity.uuid})`,
                                                    dataSource: result.dataSource
                                                }))));
                    }
                    else
                    {
                        return [];
                    }
                },
                [
                    portalContext,
                    types
                ]);

        const options =
            useMemo<StaticOption<any>[]>(
                () => [
                    ...(portalDataSourceTypes || []),
                    ...singleTypes.map(
                        type => ({
                            id: type.id(),
                            label: getTypeName(type),
                            value: type.id()
                        })),
                    ...(!parentType || parentType instanceof CollectionType)
                        ?
                            [
                                {
                                    id: 'Collection',
                                    label: localizeText('Generic.Collection', 'Collectie'),
                                    value: 'Collection'
                                },
                                {
                                    id: 'Map',
                                    label: localizeText('Generic.Map', 'Map'),
                                    value: 'Map'
                                }
                            ]
                        :
                            []
                ],
                [
                    portalDataSourceTypes,
                    singleTypes,
                    parentType
                ]);

        const onChangeTypeId =
            useCallback(
                (typeId: string) =>
                {
                    if (typeId === 'Collection')
                    {
                        onChange(new CollectionType(singleTypes[0]));
                    }
                    else if (typeId === 'Map')
                    {
                        onChange(new MapType(singleTypes[0], singleTypes[0]));
                    }
                    else if (typeId.startsWith('DataSource'))
                    {
                        const option = portalDataSourceTypes.find(type => type.id === typeId);

                        onChange(
                            new DataSourceValueType(
                                option.dataSource.id,
                                option.dataSource));
                    }
                    else
                    {
                        onChange(singleTypes.find(type => type.id() === typeId));
                    }
                },
                [
                    portalDataSourceTypes,
                    singleTypes,
                    onChange
                ]);

        const setCollectionType =
            useCallback(
                (type?: ValueType<any>) =>
                    onChange(type ? new CollectionType(type) : undefined),
                [
                    onChange
                ]);

        const setMapKeyType =
            useCallback(
                (type?: ValueType<any>) =>
                    onChange(type && value instanceof MapType ? new MapType(type, value.valueType) : undefined),
                [
                    value,
                    onChange
                ]);

        const setMapValueType =
            useCallback(
                (type?: ValueType<any>) =>
                    onChange(type && value instanceof MapType ? new MapType(value.keyType, type) : undefined),
                [
                    value,
                    onChange
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem
                ratio={1}
            >
                <StaticSelectbox
                    options={options}
                    onChange={onChangeTypeId}
                    value={value instanceof CollectionType ? 'Collection' : value instanceof MapType ? 'Map' : value?.id()}
                    autoFocus={autoFocus}
                />
            </ViewGroupItem>
            {
                value instanceof CollectionType &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                van
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ValueTypeEditor
                                    value={value.type}
                                    onChange={setCollectionType}
                                    autoFocus={autoFocus}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
            {
                value instanceof MapType &&
                    <ViewGroupItem
                        ratio={1}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ValueTypeEditor
                                    value={value.keyType}
                                    onChange={setMapKeyType}
                                    autoFocus={autoFocus}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                &nbsp;&#x2192;&nbsp;
                            </ViewGroupItem>
                            <ViewGroupItem
                                ratio={1}
                            >
                                <ValueTypeEditor
                                    value={value.valueType}
                                    onChange={setMapValueType}
                                    autoFocus={autoFocus}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(ValueTypeEditor);
