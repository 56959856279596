import React, { useContext } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import Inset from '../Inset/Inset';
import useTypes from '../../../Type/Api/useTypes';
import Click from '../Click/Click';
import LocalizerContext from '../../../../../../@Service/Localization/LocalizerContext';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import styles from './TimeRegistration.module.scss';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import { observer } from 'mobx-react-lite';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface TimeRegistrationProps
{
    entity: Entity;
}

const TimeRegistration: React.FC<TimeRegistrationProps> =
    props =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);

        const startDate =
            useEntityValue<Date>(
                props.entity,
                types.TimeRegistration.Field.StartDate);

        const durationInHours =
            useEntityValue<number>(
                props.entity,
                types.TimeRegistration.Field.DurationInHours);

        const relationship =
            useRelatedEntity(
                props.entity,
                types.Relationship.RelationshipDefinition.TimeRegistrations,
                true);

        const parentActivity =
            useRelatedEntity(
                props.entity,
                types.Activity.RelationshipDefinition.TimeRegistrations,
                true);

        const activity =
            useRelatedEntity(
                props.entity,
                types.TimeRegistration.RelationshipDefinition.Activity,
                false);

        const employee =
            useRelatedEntity(
                props.entity,
                types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                true);

        const viewerEntity = useContext(ViewerEntityContext);

        return <Click
            open
        >
            <Inset>
                <div
                    className={styles.name}
                >
                    {
                        viewerEntity || !parentActivity
                            ?
                                props.entity.entityType.getName()
                            :
                                relationship?.name
                    }
                </div>
                <div
                    className={styles.description}
                >
                    {activity?.name}{parentActivity && !equalsEntity(parentActivity, viewerEntity)? ` ${localizeText('TimeRegistration.By', 'bij')} ${parentActivity.name}`: ''}, {localizer.formatDateTime(startDate)}, {durationInHours === undefined ? localizeText('TimeRegistration.StillRunning', 'loopt nog') : `${durationInHours} uur`} door {employee?.name}
                </div>
            </Inset>
        </Click>;
    };

export default observer(TimeRegistration);
