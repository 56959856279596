import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { useEffect, useState } from 'react';
import { EntityExpansionBuilder } from '../../../../Selection/Builder/EntityExpansionBuilder';
import { useComputed } from 'mobx-react-lite';
import { createCreationDateComparator } from '../../../../List/Comparator/EntityComparator';
import useTypes from '../../../../Type/Api/useTypes';

export default function useEntities(entity?: Entity,
                                    path?: EntityPath): [ boolean, Entity[] ]
{
    const types = useTypes();
    const [ isLoading, setLoading ] = useState(false);

    useEffect(
        () =>
        {
            if (entity && path)
            {
                setLoading(true);

                new EntityExpansionBuilder(
                    entity.entityType,
                    [
                        entity
                    ],
                    [
                        path
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                                true)
                    ])
                    .expand()
                    .then(
                        () =>
                            setLoading(false))
            }
        },
        [
            entity,
            path,
            types,
            setLoading
        ]);

    return useComputed(
        () => [
            isLoading,
            path && entity &&
            path.traverseEntity(entity)
                .sort(createCreationDateComparator(e => e, 'Descending'))
        ],
        [
            path,
            entity,
            isLoading
        ]);
}
