import * as React from 'react';
import { DataObjectBespokeViewer } from '../../../Viewer/DataObjectBespokeViewer';
import { DataObjectViewerStore } from '../../../Viewer/DataObjectViewerStore';
import { observer } from 'mobx-react';
import { TimeViewerStore } from './TimeViewerStore';

@observer
export class TimeView extends DataObjectBespokeViewer<TimeViewerStore>
{
    renderComponent(store: DataObjectViewerStore<TimeViewerStore>)
    {
        if (store.dataObject.value)
        {
            return <span>{store.dataObject.value}</span>;
        }
        else
        {
            return null;
        }
    }
}
