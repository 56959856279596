import React, { useContext } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { Type } from '../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import LinkList from '../LinkList/LinkList';
import useTypes from '../../../Type/Api/useTypes';
import ViewerEntityContext from '../../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import equalsEntity from '../../../../../../@Api/Entity/Bespoke/equalsEntity';
import { CaptionClasses } from '../Caption';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export interface DefaultProps
{
    entity: Entity;
    classes?: CaptionClasses;
}

const Default: React.FC<DefaultProps> =
    props =>
    {
        // Rules
        // (1) If {viewer entity} is one of {owners}, then omit that specific {owner}
        // (2) Do not show pack relationship
        const types = useTypes();
        const viewerEntity = useContext(ViewerEntityContext);

        const owners =
            useComputed(
                () =>
                    props.entity.getRelationships(true)
                        .filter(
                            relationship =>
                                relationship.definition.type === Type.Ownership
                                && relationship.definition !== types.Pack.RelationshipDefinition.Entities)
                        .map(
                            relationship =>
                                relationship.getEntity(true))
                        .filter(
                            entity =>
                                !equalsEntity(
                                    viewerEntity,
                                    entity)),
                [
                    props.entity,
                    types
                ]);

        if (owners.length > 0)
        {
            return <>
                {localizeText('Generic.By', 'bij')} <LinkList entities={owners} classes={props.classes && props.classes.link} />
            </>;
        }
        else
        {
            return null;
        }
    };

export default observer(Default);
