import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import EntityInputLayout from '../../../../../@Api/Layout/Type/EntityInputLayout';
import Input from '../../../Entity/Input/Input';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { createTransactionalModelIfCommitContextIsAbsent } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/createTransactionalModelIfCommitContextIsAbsent';

export interface EntityInputLayoutViewerProps extends LayoutViewerProps<EntityInputLayout>
{
    fallback?: React.ReactNode;
}

const EntityInputLayoutViewer: React.FC<EntityInputLayoutViewerProps> =
    props =>
    {
        const { layout, parameterAssignment, commitContext, fallback } = props;

        const entity =
            useComputed<Entity>(
                () =>
                    parameterAssignment.getObjectValue(layout.parameter),
                [
                    parameterAssignment,
                    layout
                ]
            );
        const transactionalEntity =
            useMemo(
                () =>
                    createTransactionalModelIfCommitContextIsAbsent(
                        entity,
                        props.commitContext
                    ),
                [
                    entity,
                    props.commitContext,
                ]
            );

        if (layout.field && transactionalEntity)
        {
            return <Input
                entity={transactionalEntity}
                field={layout.field}
                labelPosition={layout.labelPosition.toLowerCase() as any || 'left'}
                doAutocommit
                inline={layout.hasIntrinsicSize}
                commitContext={commitContext}
            />;
        }
        else
        {
            if (fallback)
            {
                return <>{fallback}</>;
            }
            else
            {
                return null;
            }
        }
    };

export default observer(EntityInputLayoutViewer);
