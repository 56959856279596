import React, { useCallback, useMemo, useRef } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ContentProps } from '../../../Content';
import useTypes from '../../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SignerRow } from './SignerRow';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import useFetchedRelatedEntities from '../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntities';
import HoverCardBottom from '../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getDatastoreByCode from '../../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { createNumberComparator } from '../../../../../../../Generic/List/V2/Comparator/NumberComparator';

export interface SignersProps extends ContentProps
{
    readOnly? : boolean;
}

const useStyles =
    makeStyles({
        container: {
            maxHeight: 400,
            border: 'rgba(0, 0, 0, 0.08) 1px solid',
            overflow: 'visible'
        },
        message: {
            whiteSpace: 'pre-line'
        },
        header: {
            backgroundColor: 'white',
            position: 'sticky',
            top: 0
        },
        loader:
            {
                height: 5,
                display: 'block !important'
            }
    });

export const SignersTable: React.FC<SignersProps> = observer(
    props =>
    {
        const types = useTypes();
        const activitySigningEntity = props.entity;
        const classes = useStyles();

        const tableRef = useRef(null);

        const [ signers ] = useFetchedRelatedEntities(
            activitySigningEntity,
            types.Activity.DigitalSigning.RelationshipDefinition.Signers,
            false,
            props.commitContext
        );

        const sortedSigners =
            useComputed(
                () =>
                    signers
                        .sort(
                            createNumberComparator(
                                signer => signer.getObjectValueByField(
                                        types.DigitalSigner.Field.SignOrderIndex
                                    )
                            )
                        ),
                [
                    types,
                    signers
                ]
            );

        const signerEntityName =
            useMemo(
                () => {
                    return types.Activity.DigitalSigning.RelationshipDefinition.Signers.childEntityType.getName()
                },
                [
                    types
                ]
            )

        const onAddSigner =
            useCallback(
                () => {
                    const newSigner = constructEntityOfType(
                        types.DigitalSigner.Type,
                        props.commitContext
                    );

                    getDatastoreByCode(types.Datastore.Phase.DigitalSigner.Type, 'Unsigned')
                        .then(
                            unsignedStateEntity =>
                            {
                                updateRelationship(
                                    newSigner,
                                    false,
                                    types.DigitalSigner.RelationshipDefinition.Phase,
                                    unsignedStateEntity,
                                    props.commitContext
                                );
                            }
                        );

                    const maxOrder =
                        signers.reduce(
                            (accumulator, currentValue) => {
                                const order =
                                    currentValue.getObjectValueByField(
                                        types.DigitalSigner.Field.SignOrderIndex,
                                        props.commitContext
                                      ) || 0;
                                return Math.max(accumulator, order);
                            },
                            0
                        )

                    newSigner.setValueByField(
                        types.DigitalSigner.Field.SignOrderIndex,
                        maxOrder + 1,
                        false,
                        false,
                        props.commitContext,
                    )

                    updateRelationship(
                        activitySigningEntity,
                        false,
                        types.Activity.DigitalSigning.RelationshipDefinition.Signers,
                        newSigner,
                        props.commitContext
                    )

                },
                [
                    types,
                    activitySigningEntity,
                    props.commitContext,
                    signers
                ]
            );

        const onDeleteSigner =
            useCallback(
                (signer: Entity) => {

                    updateRelationship(
                        activitySigningEntity,
                        false,
                        types.Activity.DigitalSigning.RelationshipDefinition.Signers,
                        undefined,
                        props.commitContext,
                        signer
                    )

                },
                [
                    activitySigningEntity,
                    types,
                    props.commitContext
                ]
            )

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >

            <ViewGroupItem>
                <TableContainer
                    className={classes.container}
                    ref={tableRef}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '14%'
                                    }}
                                >
                                    {signerEntityName}*
                                </TableCell>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '22%'
                                    }}
                                >
                                    {types.DigitalSigner.Field.EmailAddress.getName()}*
                                </TableCell>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '14%'
                                    }}
                                >
                                    {types.DigitalSigner.Field.FirstName.getName()}*
                                </TableCell>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '12%'
                                    }}
                                >
                                    {types.DigitalSigner.Field.LastName.getName()}*
                                </TableCell>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '12%'
                                    }}
                                >
                                    {types.DigitalSigner.Field.CanChangeSigner.getName()}
                                </TableCell>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '12%'
                                    }}
                                >
                                    <LocalizedText
                                        code="SignerPhase"
                                        value="Fase"
                                    />
                                </TableCell>
                                <TableCell
                                    className={classes.header}
                                    style={{
                                        width: '2%'
                                    }}
                                >
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sortedSigners.map(
                                    signer =>
                                        <SignerRow
                                            key={signer.uuid}
                                            entity={signer}
                                            touched={props.touched}
                                            commitContext={props.commitContext}
                                            onDelete={onDeleteSigner}
                                            readOnly={props.readOnly}
                                            autoCommit={props.autoCommit}
                                        />
                                )
                            }
                        </TableBody>
                    </Table>
                    {
                        !props.readOnly &&
                        <HoverCardBottom
                            onClick={onAddSigner}
                        >
                            + {signerEntityName} <LocalizedText code="Generic.Add" value="toevoegen" />
                        </HoverCardBottom>
                    }
                </TableContainer>
            </ViewGroupItem>

        </ViewGroup>
    }
)
