import { EntityFieldPath } from '../../Entity/Path/@Model/EntityFieldPath';
import { EntityPath } from '../../Entity/Path/@Model/EntityPath';
import getTypes from '../../Entity/Type/Api/getTypes';
import { findAssistantFieldPath } from './findAssistantFieldPath';

export function findAssistantAggregateFieldPath(
    rootPath: EntityPath,
    path: string
): EntityFieldPath
{
    const fieldPath =
        findAssistantFieldPath(
            rootPath,
            path
        );

    if (fieldPath.isField)
    {
        return fieldPath;
    }
    else
    {
        return fieldPath.path.field(
            getTypes().Entity.Field.Id
        );
    }
}
