import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../../../../Input/Input';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as GenericInput } from '../../../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ReadCount from '../../../../../../../../../../ReadCount/ReadCount';
import Base from '../../Base/Base';
import { ContentProps } from '../../Content';
import useIsInViewport from 'use-is-in-viewport';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';
import useTextEntityValue from '../../../../../../../../../../../../../@Api/Entity/Hooks/useTextEntityValue';

export interface OfferProps extends ContentProps
{

}

const Offer: React.FC<OfferProps> =
    props =>
    {
        const types = useTypes();
        const offerVersion =
            useTextEntityValue(
                props.entity,
                types.Activity.Offer.Field.Version,
                props.commitContext
            );

        const fields =
            [
                <Input
                    key={0}
                    entity={props.entity}
                    field={types.Activity.Offer.RelationshipDefinition.Phase}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />,
                <GenericInput
                    key={1}
                    label=""
                    labelPosition="none"
                    inline={props.inlineLabel}
                >
                    <LocalizedText code="Generic.Version" value="Versie" /> {offerVersion}
                </GenericInput>,
                <Input
                    key={2}
                    entity={props.entity}
                    field={types.Activity.Offer.Field.ExpirationDate}
                    labelPosition={props.labelPosition}
                    inline={props.inlineLabel}
                    commitContext={props.commitContext}
                />
        ];

        const [ isInViewPort, targetRef ] = useIsInViewport({ threshold: 50 });

        return <div
            ref={targetRef}
        >
            <Base
                {...props}
                expand
                buttons={
                    isInViewPort &&
                        <ViewGroupItem>
                            <ReadCount
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                }
            >
                {fields}
            </Base>
        </div>;
    };

export default observer(Offer);
