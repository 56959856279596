import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { computed, observable } from 'mobx';
import { PredicateType } from './Type/PredicateType';
import { PredicateTypeStore } from './PredicateTypeStore';
import { PredicateSpecification } from './Type/PredicateSpecification';
import { PredicateContext } from './PredicateContext';

export class PredicateEditorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable type: PredicateType;
    @observable context: PredicateContext;
    @observable specification: PredicateSpecification;

    // ------------------------ Constructor -------------------------

    constructor(type: PredicateType,
                context: PredicateContext,
                specification: PredicateSpecification)
    {
        super();

        this.type = type;
        this.context = context;
        this.specification = specification;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get descriptor()
    {
        return this.type
            .editorSpecification(
                this.editorStore);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get editorStore(): PredicateEditorStore
    {
        if (this.type)
        {
            return this.type.editorStore(
                this.context,
                this.specification);
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static construct(context: PredicateContext,
                     specification: PredicateSpecification,
                     typeStore: PredicateTypeStore): PredicateEditorStore
    {
        if (!specification)
        {
            specification = { type: 'Composite' };
        }

        let type = typeStore.getTypeById(specification.type);

        return new PredicateEditorStore(
            type,
            context,
            specification);
    }

    // ----------------------- Private logic ------------------------
}
