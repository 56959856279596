import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { DependencyList, useCallback, useEffect, useState } from 'react';
import { EntitySelectionAggregateResult } from '../Model/EntitySelectionAggregateResult';
import { useIsMounted } from '../../../../../@Util/Async/useIsMounted';

export default function useCombinedAggregateResults(types: EntityType[],
                                                    callback: (idx: number, builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
                                                    deps: DependencyList): (EntitySelectionAggregateResult | undefined)[] | undefined
{
    const memorizedCallback = useCallback(callback, deps);
    const [ results, setResults ] = useState<(EntitySelectionAggregateResult | undefined)[] | undefined>();
    const isMounted = useIsMounted();

    useEffect(
        () =>
        {
            const buildersToDiscard: EntitySelectionBuilder[] = [];

            Promise.all(
                types.map(
                    (type, idx) =>
                    {
                        if (type)
                        {
                            const rootPath = EntityPath.fromEntityType(type);
                            const builder = EntitySelectionBuilder.fromPath(rootPath);

                            memorizedCallback(
                                idx,
                                builder,
                                rootPath);

                            buildersToDiscard.push(builder);

                            return builder.selectAggregates();
                        }
                        else
                        {
                            return Promise.resolve(undefined);
                        }
                    }))
                .then(
                    results =>
                    {
                        if (isMounted())
                        {
                            setResults(results);
                        }
                    });

            return () =>
                buildersToDiscard.forEach(
                    builder =>
                        builder.dispose());
        },
        [
            types,
            memorizedCallback,
            setResults,
            isMounted
        ]);

    return results;
}
