import { observable } from 'mobx';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { OldPredicate } from '../OldPredicate';
import NewCompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import AutomationDependencyContext from '../../../../../@Api/Automation/AutomationDependencyContext';

export class OldCompositePredicate extends OldPredicate
{
    // ------------------------- Properties -------------------------

    @observable logicalOperator: LogicalOperator;
    @observable predicates = observable.array<OldPredicate>();

    // ------------------------ Constructor -------------------------

    constructor(logicalOperator: LogicalOperator,
                predicates: OldPredicate[])
    {
        super('Composite');

        this.logicalOperator = logicalOperator;
        this.predicates.replace(predicates);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTrueStatements(): OldPredicate[]
    {
        if (this.logicalOperator === LogicalOperator.And)
        {
            return this.predicates
                .map(value =>
                    value.getTrueStatements())
                .reduce((a, b) => a.concat(b), []);
        }
        else
        {
            if (this.logicalOperator === LogicalOperator.Or && this.predicates.length === 1)
            {
                return this.predicates[0].getTrueStatements();
            }
            else
            {
                return [];
            }
        }
    }

    getLeafs(): OldPredicate[]
    {
        return this.predicates
            .map(value =>
                value.getLeafs())
            .reduce((a, b) => a.concat(b), []);
    }

    async initialize(): Promise<any>
    {
        return Promise.all(
            this.predicates.map(
                predicate =>
                    predicate.initialize()));
    }

    async toNewPredicate(context: AutomationDependencyContext)
    {
        return new NewCompositePredicate(
            this.logicalOperator,
            await Promise.all(
                this.predicates.map(
                    predicate =>
                        predicate.toNewPredicate(context)
                )
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
