import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import PortalTableLayout from '../../../../../../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import { PortalTableColumn } from '../../../../../../../../../@Api/Layout/Type/PortalTable/Model/PortalTableColumn';
import { TableCell, TableRow } from '@material-ui/core';
import ComputationEditor from '../../../../../../../Entity/Viewer/Content/Automation/Editor/Computation/ComputationEditor';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import Switch from '../../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import NumberEditor from '../../../../../../../../../@Future/Component/Generic/Input/NumberEditor/NumberEditor';
import ParameterAssignment from '../../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import DeleteIconButton from '../../../../../../../../../@Future/Component/Generic/Button/Variant/DeleteIconButton/DeleteIconButton';
import LayoutConstructor from '../../../../../../Editor/Constructor/LayoutConstructor';
import LayoutEditor from '../../../../../../Editor/LayoutEditor';

export interface PortalTableColumnEditorProps
{
    context: FunctionContext;
    layout: PortalTableLayout;
    column: PortalTableColumn;
    onDelete: () => void;
}

const PortalTableColumnEditor: React.FC<PortalTableColumnEditorProps> =
    ({
        context,
        layout,
        column,
        onDelete,
     }) =>
    {
        const expressionContext =
            useMemo(
                () =>
                    new FunctionContext(
                        layout.dataSourceSignature.resultParameters,
                        new ParameterAssignment()
                    ),
                [
                    layout,
                ]
            );
        const contextWithRowParameter =
            useMemo(
                () =>
                    new FunctionContext(
                        context.parameterDictionary.getNewDictionaryWithParameter(
                            layout.rowParameter
                        ),
                        context.parameterAssignment,
                        context.commitContext
                    ),
                [
                    context,
                ]
            );

        return <TableRow>
            <TableCell>
                <ComputationEditor
                    context={context}
                    value={column.name}
                    onChange={
                        name =>
                            runInAction(
                                () =>
                                    column.name = name
                            )
                    }
                />
            </TableCell>
            <TableCell>
                <ComputationEditor
                    context={expressionContext}
                    value={column.expression}
                    onChange={
                        expression =>
                            runInAction(
                                () =>
                                    column.expression = expression
                            )
                    }
                />
            </TableCell>
            <TableCell>
                {
                    column.layout === undefined
                        ? <LayoutConstructor
                            mode="EditButton"
                            parameterDictionary={contextWithRowParameter.parameterDictionary}
                            onConstruct={
                                layout =>
                                    runInAction(
                                        () =>
                                            column.layout = layout
                                    )
                            }
                        />
                        : <LayoutEditor
                            parameterDictionary={contextWithRowParameter.parameterDictionary}
                            parameterAssignment={contextWithRowParameter.parameterAssignment}
                            layout={column.layout}
                            onChange={
                                layout =>
                                    runInAction(
                                        () =>
                                            column.layout = layout
                                    )
                            }
                            commitContext={contextWithRowParameter.commitContext}
                        />
                }
            </TableCell>
            <TableCell>
                <Switch
                    checked={column.isSearchable}
                    onToggle={
                        isChecked =>
                            runInAction(
                                () =>
                                    column.isSearchable = isChecked
                            )
                    }
                />
            </TableCell>
            <TableCell>
                <Switch
                    checked={column.isFilterable}
                    onToggle={
                        isChecked =>
                            runInAction(
                                () =>
                                    column.isFilterable = isChecked
                            )
                    }
                />
            </TableCell>
            <TableCell>
                <Switch
                    checked={column.isOrderable}
                    onToggle={
                        isChecked =>
                            runInAction(
                                () =>
                                    column.isOrderable = isChecked
                            )
                    }
                />
            </TableCell>
            <TableCell>
                <NumberEditor
                    value={column.ratio}
                    onChange={
                        ratio =>
                            runInAction(
                                () =>
                                    column.ratio = ratio
                            )
                    }
                    min={0}
                />
            </TableCell>
            <TableCell
                align="right"
            >
                <DeleteIconButton
                    onClick={onDelete}
                />
            </TableCell>
        </TableRow>;
    };

export default observer(PortalTableColumnEditor);
