import useAddPaths from './useAddPaths';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { useContext } from 'react';
import { AddEntityPath } from '../../../Path/@Model/AddEntityPath';
import { createStringComparator } from '../../../../../Generic/List/V2/Comparator/StringComparator';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import { isAttachedType } from '../../../Item/Navigator/Api/getIsAttachedType';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import useTypes from '../../../Type/Api/useTypes';
import { RelatedEntityPath } from '../../../Path/@Model/RelatedEntityPath';
import { useComputed } from 'mobx-react-lite';
import isHiddenType from '../../../../../../@Api/Metadata/EntityType/isHiddenType';

export default function useCreatableAddPaths(rootEntity?: Entity,
                                             entityType?: EntityType,
                                             pathFromRootEntity?: EntityPath,
                                             ignoreHidden: boolean = false)
{
    const currentUserStore = useContext(CurrentUserContext);
    const entityTypeContext = useContext(EntityTypeContext);
    const types = useTypes();

    const addPaths = useAddPaths(rootEntity, entityType, pathFromRootEntity, ignoreHidden);

    const illegalRelativeAddPathIds =
        useComputed(
            () =>
            {
                const illegalPathIds = new Set<string>();

                if (rootEntity)
                {
                    const rootPath = EntityPath.fromEntity(rootEntity);

                    if (rootEntity.entityType.isA(types.Relation.Type))
                    {
                        if (rootEntity.entityType.isA(types.Relation.Organization.Type))
                        {
                            // If root entity is an environment, then disallow standard contacts
                            if (rootEntity.entityType.isA(types.Relation.Organization.Environment.Type))
                            {
                                illegalPathIds.add(
                                    new RelatedEntityPath(
                                        rootEntity,
                                        rootPath
                                            .joinTo(
                                                types.Relation.RelationshipDefinition.Relationships,
                                                false)
                                            // Because of grouping cast
                                            .castTo(types.Relationship.Person.Type)
                                            .castTo(types.Relationship.Person.Contact.Standard.Type))
                                            .id);
                            }
                            else
                            {
                                // Otherwise, disallow employee contacts
                                illegalPathIds.add(
                                    new RelatedEntityPath(
                                        rootEntity,
                                        rootPath
                                            .joinTo(
                                                types.Relation.RelationshipDefinition.Relationships,
                                                false)
                                            // Because of grouping cast
                                            .castTo(types.Relationship.Person.Type)
                                            .castTo(types.Relationship.Person.Contact.Employee.Type))
                                        .id);
                            }

                        }
                        else if (rootEntity.entityType.isA(types.Relation.Person.Type))
                        {
                            // If the root entity is a person, then disallow all contacts
                            types.Relationship.Person.Contact.Type.getAllTypes(false, true)
                                .forEach(
                                    contactType =>
                                        illegalPathIds.add(
                                            new RelatedEntityPath(
                                                rootEntity,
                                                rootPath
                                                    .joinTo(
                                                        types.Relation.RelationshipDefinition.Relationships,
                                                        false)
                                                    // Because of grouping cast
                                                    .castTo(types.Relationship.Person.Type)
                                                    .castTo(contactType))
                                                .id));
                        }
                    }
                }

                return illegalPathIds;
            },
            [

            ]);

    const illegalTypes =
        useComputed(
            () =>
                new Set<EntityType>([
                    types.Relationship.Organization.Identity.Type,
                    types.Activity.SpotlerCampaignResult.Type,
                    types.Activity.ApsisCampaign.Type,
                    types.Activity.ApsisCampaignResult.Type,
                    types.Activity.ApsisForm.Type,
                    types.Activity.ApsisFormResult.Type
                ]),
            [
                types
            ]);

    const creatableAddPaths =
        useComputed(
            () =>
            {
                const creatablePaths: AddEntityPath[] = [];

                for (const path of addPaths)
                {
                    creatablePaths.push(
                        ...currentUserStore
                            .rightProfile
                            .getCreatableSubtypes(path.relativePath.path.entityType)
                            .filter(type => (ignoreHidden || !isAttachedType(entityTypeContext, type)) && !isHiddenType(type))
                            .filter(
                                type =>
                                    rootEntity || ignoreHidden
                                        ?
                                            true
                                        :
                                            !type.isA(types.Relationship.Person.Contact.Type))
                            .filter(
                                type =>
                                    !type.isA(types.Relationship.Person.PortalUser.Type))
                            .map(
                                type =>
                                    new AddEntityPath(
                                        new RelatedEntityPath(
                                            path.fullPath.entity,
                                            path.fullPath.path.castTo(type)),
                                        new RelatedEntityPath(
                                            path.relativePath.entity,
                                            path.relativePath.path.castTo(type))))
                            .filter(
                                addPath =>
                                    !illegalRelativeAddPathIds.hasOwnProperty(addPath.relativePath.id) &&
                                    !illegalTypes.has(addPath.relativePath.path.entityType)));
                }

                return creatablePaths.sort(
                    createStringComparator<AddEntityPath>(
                        path =>
                            path.relativePath.path.entityType.getName()));
            },
            [
                addPaths,
                ignoreHidden
            ]);

    return creatableAddPaths;

}
