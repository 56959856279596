import React, { useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { ChartStore } from '../ChartStore';
import { ColorUtils } from '../../../../@Util/Color/ColorUtils';
import { blue } from '../../../../@Resource/Theme/Blue';
import { DataObjectRepresentation } from '../../../Domain/DataObject/Model/DataObjectRepresentation';

const D3Funnel = require('d3-funnel');

export interface FunnelProps
{
    store: ChartStore;
}

let lastFunnelId = 1;

const Funnel: React.FC<FunnelProps> =
    props =>
    {
        const data =
            useComputed(
                () =>
                    props.store.data
                        .map(
                            (point, idx) =>
                                ({
                                    label: point.name,
                                    value: point.value.value ?? 0,
                                    backgroundColor: props.store.selectedIndex === idx ? blue[100] : ColorUtils.color(idx)[500],
                                    formattedValue: point.value.toString(new DataObjectRepresentation({ isCompact: true })),
                                    labelColor: props.store.selectedIndex === idx ? '#ffffff' : undefined
                                })
                        )
                        .filter(
                            element =>
                                element.label !== undefined
                        ),
                [
                    props.store
                ]);

        const h0 = 330 / 5 * data.length;
        const height = h0 > 500 ? 330 / 6 * data.length : h0;

        const id =
            useMemo(
                () =>
                    `funnel${lastFunnelId++}`,
                []);

        useEffect(
            () =>
            {
                const options = {
                    chart: {
                        width: 280,
                        height: height,
                        curve: {
                            enabled: true
                        },
                        animate: true,
                        bottomPinch: 1
                    },
                    block: {
                        dynamicHeight: true,
                        minHeight: 50,
                        highlight: true,
                        fill: {
                            type: 'gradient'
                        }
                    },
                    label: {
                        format: '{l}\n{f}',
                        fontSize: '12px'
                    },
                    tooltip: {
                        enabled: true
                    },
                    events: {
                        click: {
                            block:
                                function (data)
                                {
                                    if (props.store.selectedIndex === data.index)
                                    {
                                        props.store.unselectIndex();
                                    }
                                    else
                                    {
                                        props.store.selectIndex(data.index);
                                    }
                                }
                        }
                    }
                };

                const chart = new D3Funnel(`#${id}`);

                if (data.length > 0)
                {
                    chart.draw(data, options);
                }
            },
            [
                id,
                data,
                props.store,
                height
            ]);

        return <div
            style={{
                padding: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: height
            }}
        >
            <div
                id={id}
            />
        </div>;
    };

export default observer(Funnel);
