import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { TableRow } from '@material-ui/core';
import { TotalCell } from './Cell/TotalCell';
import styles from '../Row/Row.module.scss';
import Column from '../../Model/Specification/Column';
import TableCell from '@material-ui/core/TableCell';
import View from '../../Model/View';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';

export interface TotalRowProps
{
    view: View;
    columns: Column[];
    result?: EntitySelectionAggregateResult;
    isInEditMode?: boolean;
    sortable?: boolean;
    selectable?: boolean;
    onClick?: () => void;
    color?: string;
    prefix?: React.ReactNode;
}

export const TotalRow: React.FC<TotalRowProps> =
    observer(
        ({
            view,
            columns,
            result,
            isInEditMode,
            sortable,
            selectable,
            onClick,
            color,
            prefix,
         }) =>
        {
            const visibleColumns =
                useComputed(
                    () =>
                        columns
                            .filter(
                                column =>
                                    column.shouldShowColumn(
                                        view,
                                        isInEditMode
                                    )
                            ),
                    [columns, view, isInEditMode]
                );
            const aggregatedVisibleColumns =
                useComputed(
                    () =>
                        visibleColumns.filter(
                            column =>
                                column.aggregate !== undefined
                        ),
                    [visibleColumns]
                );

            return <TableRow
                onClick={onClick}
                className={
                    onClick
                        ? styles.root
                        : undefined
                }
            >
                {
                    selectable &&
                    <TableCell />
                }
                {
                    sortable &&
                    <TableCell />
                }
                {
                    visibleColumns.map(
                        (column, idx) =>
                            <TotalCell
                                key={column.id}
                                aggregatedVisibleColumns={aggregatedVisibleColumns}
                                column={column}
                                result={result}
                                color={color}
                                prefix={
                                    idx === 0
                                        ? prefix
                                        : undefined
                                }
                            />
                    )
                }
            </TableRow>;
        }
    );