import { useContext } from 'react';
import useAllowedDomains from './useAllowedDomains';
import moment from 'moment';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import useCurrentLicensePeriod from '../../../../License/Api/useCurrentLicensePeriod';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import performAction from '../../../../../../@Api/Entity/performAction';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import isEmailAddressValid from '../../../../../../@Util/EmailAddress/isEmailAddressValid';
import useAllowedDomainsForSMTP from './useAllowedDomainsForSMTP';

export default function useIsSendingFromDomainAllowed(senderAddress?: DataObject): [ boolean | undefined, boolean, (value: boolean) => void ]
{
    const types = useTypes();
    const currentUserStore = useContext(CurrentUserContext);
    const [ , periodEnd ] = useCurrentLicensePeriod();
    const allowedDomains = useAllowedDomains();
    const allowedDomainsFromSMTP = useAllowedDomainsForSMTP();

    return useAsyncResult(
        async () =>
        {
            const numberOfDaysLeftInPeriod = Math.round(moment(periodEnd)
                    .diff(moment(), 'days', true));
            const isActiveTrial =
                !currentUserStore.contractEntity?.hasValueForField(types.LicenseContract.Field.ActivationDate) &&
                    numberOfDaysLeftInPeriod >= 0;

            if (isActiveTrial)
            {
                return true;
            }

            if (senderAddress && isEmailAddressValid(senderAddress.value))
            {
                const fromDomain = senderAddress.value.split('@')[1].toLowerCase();

                const isFromDomainAllowedForSMTP =
                    allowedDomainsFromSMTP
                        .some(domain => domain === fromDomain);

                if (isFromDomainAllowedForSMTP)
                {
                    return true;
                }

                const isFromDomainAllowed = allowedDomains.some(domain => domain === fromDomain);
                if (isFromDomainAllowed)
                {
                    const report =
                        await performAction(
                            undefined,
                            {
                                code: 'DomainValidation.GetReport',
                                parameters: {
                                    domain: fromDomain
                                }
                            }
                        );

                    return report.result?.isValid ?? false;
                }
            }

            return [false, true, () => {}];
        },
        [
            periodEnd,
            senderAddress,
            senderAddress?.value,
            currentUserStore,
            types,
        ]
    );
}
