import React from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Role from '../../Model/Role';
import CardHeader from '../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { FeatureEditor } from './Feature/FeatureEditor';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import {
    HideEmployeeAllocationInProjectFeature,
    HideEmployeePlannerInProjectFeature,
    HideExternalCostInProjectFeature,
    HideFinancialWidgetsInProjectFeature,
    HideInvoiceInProjectFeature,
    HideMileageRegistrationInProjectFeature,
    HideTimeRegistrationInProjectFeature,
    HideToInvoiceInProjectFeature,
} from '../../Model/Features';
import useTypes from '../../../Entity/Type/Api/useTypes';

import styles from '../RoleEditor.module.scss';

export interface ProjectFeaturesEditorProps
{
    role: Role;
    isReadOnly: boolean;
}

export const ProjectFeaturesEditor: React.FC<ProjectFeaturesEditorProps> =
    observer(
        ({
            role,
            isReadOnly,
         }) =>
        {
            const dividerGlue = useDividerGlue();
            const types = useTypes();

            return <Card>
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <CardInset
                            classes={{
                                root: styles.cardHeader
                            }}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                                alignment="center"
                            >
                                <CardHeader>
                                    {types.Activity.Project.Type.getName()}
                                </CardHeader>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideFinancialWidgetsInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowFinancialWidgetsInProject"
                                    value="Financiële widgets tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideTimeRegistrationInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowTimeRegistrationInProject"
                                    value="Uren registratie tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideMileageRegistrationInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowMileageRegistrationInProjectInProject"
                                    value="Kilometer registratie tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideExternalCostInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowExternalCostInProject"
                                    value="Externe kosten tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideToInvoiceInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowToInvoiceInProject"
                                    value="Te factureren tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideInvoiceInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowInvoiceInProject"
                                    value="Facturen tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideEmployeePlannerInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowEmployeePlannerInProject"
                                    value="Planner tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FeatureEditor
                            role={role}
                            feature={HideEmployeeAllocationInProjectFeature}
                            label={
                                <LocalizedText
                                    code="Configuration.ShowEmployeeAllocationInProject"
                                    value="Bezetting tonen"
                                />
                            }
                            inverted
                            isReadOnly={isReadOnly}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </Card>;
        }
    );
