export type IntervalType = 'Yearly' | 'HalfYearly' | 'Monthly' | 'Quarterly' | 'FourWeekly' | 'Weekly' | 'Daily'

export interface DateIntervalValue
{
    type: IntervalType;
    frequency: number;
}

export function intervalValueFromIntervalType(type: any): DateIntervalValue
{
    if (type === 'TwoYearly')
    {
        return {
            type: 'Yearly',
            frequency: 2
        };
    }
    else if (type === 'TwoMonthly')
    {
        return {
            type: 'Monthly',
            frequency: 2
        };
    }
    else if (type === 'FourMonthly')
    {
        return {
            type: 'Monthly',
            frequency: 4
        }
    }
    else if (type === 'HalfYearly')
    {
        return {
            type: 'Monthly',
            frequency: 6
        }
    }
    else if (type === 'FourWeekly')
    {
        return {
            type: 'Weekly',
            frequency: 4
        }
    }
    else
    {
        return {
            type: type,
            frequency: 1
        };
    }
}
