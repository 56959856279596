import React, { useCallback, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import Card from '../../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import EntitySummary from '../../../../../Summary/EntitySummary';
import styles from './Contact.module.scss';
import Link from '../../../../../../../../@Future/Component/Generic/Link/Link';
import { openEntity } from '../../../../../@Util/openEntity';
import { RelatedEntityPath } from '../../../../../Path/@Model/RelatedEntityPath';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import Caption from '../../../../../Item/Caption/Caption';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import RelationshipEditorDialog from '../RelationshipEditorDialog/RelationshipEditorDialog';
import CardHeader from '../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import HoverCard from '../../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import { createTransactionalModel } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface ContactProps
{
    entity: Entity;
}

const Contact: React.FC<ContactProps> =
    props =>
    {
        const types = useTypes();
        const contact =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.Person.Contact.RelationshipDefinition.Activities),
                [
                    props.entity,
                    types
                ]);
        const openContact =
            useCallback(
                () =>
                    openEntity(
                        contact,
                        new RelatedEntityPath(
                            props.entity,
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    types.Relationship.RelationshipDefinition.Activities,
                                    true))),
                [
                    contact,
                    props.entity,
                    types
                ]);

        const [ open, setOpen ] = useState(false);

        const editClickCallback =
            useCallback(
                () => setOpen(true),
                [
                    setOpen
                ]);

        const saveCallback =
            useCallback(
                successful =>
                {
                    setOpen(!successful)
                },
                [
                    setOpen
                ]);

        const closeCallback =
            useCallback(
                () => setOpen(false),
                [
                    setOpen
                ]);

        const decoupleContact =
            useCallback(
                () =>
                {
                    const entity = createTransactionalModel(props.entity);
                    entity.updateRelationship(
                        true,
                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                        undefined);

                    return entity.checkAndDoCommit(false, true);
                },
                [
                    props.entity,
                    types
                ]);

        if (contact)
        {
            return <>
                <Card>
                    <CardInset>
                        <div
                            className={styles.header}
                        >
                            <div
                                className={styles.name}
                            >
                                <CardHeader>
                                    <Link
                                        onClick={openContact}
                                    >
                                        {contact.name}
                                    </Link>
                                </CardHeader>
                                <Caption
                                    entity={contact}
                                />
                            </div>
                            <div
                                className={styles.menu}
                            >
                                <MenuButton
                                    expansion={
                                        close =>
                                            <Menu>
                                                <Item
                                                    name={
                                                        <LocalizedText
                                                            code="Relationships.EditContact"
                                                            value="Contactpersoon wijzigen"
                                                        />
                                                    }
                                                    onClick={() => {close(); editClickCallback();}}
                                                />
                                                <Item
                                                    name={
                                                        <LocalizedText
                                                            code="Relationships.UnlinkContact"
                                                            value="Contactpersoon ontkoppelen"
                                                        />
                                                    }
                                                    onClick={() => {close(); decoupleContact();}}
                                                />
                                            </Menu>
                                    }
                                />
                            </div>
                        </div>
                        <EntitySummary
                            entity={contact}
                            clickable
                        />
                    </CardInset>
                </Card>
                {
                    open &&
                        <RelationshipEditorDialog
                            entity={props.entity}
                            saveCallback={saveCallback}
                            closeCallback={closeCallback}
                        />
                }
            </>;
        }
        else
        {
            return <>
                <HoverCard
                    onClick={editClickCallback}
                >
                    <LocalizedText
                        code="Relationships.AddContactButton"
                        value="+ Contactpersoon koppelen"
                    />
                </HoverCard>
                {
                    open &&
                        <RelationshipEditorDialog
                            entity={props.entity}
                            saveCallback={saveCallback}
                            closeCallback={closeCallback}
                        />
                }
            </>;
        }
    };

export default observer(Contact);
