import React from 'react';
import { observer } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import InsetLayout from '../../../../../@Api/Layout/Type/InsetLayout';
import InsetLayoutView from './View/InsetLayoutView';

export interface InsetLayoutViewerProps extends LayoutViewerProps<InsetLayout>
{

}

const InsetLayoutViewer: React.FC<InsetLayoutViewerProps> =
    props =>
    {
        return <InsetLayoutView
            layout={props.layout}
        >
            <LayoutViewer
                {...props}
                layout={props.layout.layout}
            />
        </InsetLayoutView>;
    };

export default observer(InsetLayoutViewer);
