import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ResizableTwoColumnLayout.module.scss';
import { Bar, Container, Resizer, Section } from 'react-simple-resizer';
import useLocalSetting from '../../../../Setting/Api/useLocalSetting';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import isInIframe from '../../../../../../@Future/Util/Iframe/IsInIframe';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';

export interface ResizableTwoColumnLayoutProps
{
    id: string;
    leftColumn: React.ReactNode;
    rightColumn: React.ReactNode;
    compact?: boolean;
}

export const ResizableTwoColumnLayout: React.FC<ResizableTwoColumnLayoutProps> = observer(
    ({
        id,
        leftColumn,
        rightColumn,
        compact
    }) =>
        {
            const deviceId =
                useMemo(
                    () =>
                        isInIframe()
                            ?
                                '.Iframe'
                            :
                                '',
                    []);

            const [ lhsSize, setLhsSize ] = useLocalSetting<number>(`ColumnLayout${deviceId}.${id}.0.Size`);

            const theme = useTheme();
            const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
            const isMd = useMediaQuery(theme.breakpoints.only('md'));
            const isSm = useMediaQuery(theme.breakpoints.only('sm'));
            const isXs = useMediaQuery(theme.breakpoints.down('xs'));

            const defaultLhsSize =
                useMemo(
                    () =>
                    {
                        if (isLgUp)
                        {
                            return 500;
                        }
                        else if (isMd)
                        {
                            return 400;
                        }
                        else if (isSm)
                        {
                            return 300;
                        }
                        else
                        {
                            return undefined;
                        }
                    },
                    [
                        isLgUp,
                        isMd,
                        isSm
                    ]);

            const [ isInitialResize, setInitialResize ] = useState(false);

            const resize =
                useCallback(
                    (resizer: Resizer) =>
                    {
                        if (isInitialResize)
                        {
                            setInitialResize(false);

                            return;
                        }

                        const lhsSize = resizer.getSectionSize(0);

                        setLhsSize(
                            lhsSize === defaultLhsSize
                                ?
                                    undefined
                                :
                                    lhsSize);
                    },
                    [
                        isInitialResize,
                        setInitialResize,
                        defaultLhsSize,
                        setLhsSize
                    ]);

            if (isXs || compact)
            {
                return <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        lg={3}
                        xl={3}
                    >
                        {leftColumn}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        lg={9}
                        xl={9}
                    >
                        {rightColumn}
                    </Grid>
                </Grid>;
            }
            else
            {
                if (defaultLhsSize === undefined)
                {
                    return null;
                }
                else
                {
                    return <Container
                        className={styles.root}
                        beforeApplyResizer={resize}
                    >
                        <Section
                            minSize={300}
                            defaultSize={lhsSize || defaultLhsSize}
                            className={classNames(styles.section, styles.lhs)}
                            disableResponsive
                        >
                            {leftColumn}
                        </Section>
                        <Bar
                            size={16}
                            className={styles.bar}
                        >
                            <div
                                className={styles.line1}
                            />
                            <div
                                className={styles.line2}
                            />
                        </Bar>
                        <Section
                            minSize={100}
                            className={classNames(styles.section, styles.rhs)}
                        >
                            {rightColumn}
                        </Section>
                    </Container>;
                }
            }
        }
    );