import { reference, type } from '../../../@Util/Serialization/Serialization';
import { EntityRelationship } from './EntityRelationship';
import { Entity } from './Entity';
import { EntityRelationshipDefinition } from './EntityRelationshipDefinition';
import { EntityMutation } from './EntityMutation';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';

@type('EntityRelationshipMutation')
export class EntityRelationshipMutation extends EntityMutation
{
    // ------------------- Persistent Properties --------------------

    @reference(undefined, 'EntityRelationship') entityRelationship: EntityRelationship;
    entityRelationshipUuid: string;
    isParentRelationship: boolean;
    @reference(undefined, 'Entity') fromRelatedEntity: Entity;
    fromRelatedEntityUuid: string;
    @reference(undefined, 'Entity') toRelatedEntity: Entity;
    toRelatedEntityUuid: string;
    @reference(undefined, 'EntityRelationshipDefinition') entityRelationshipDefinition: EntityRelationshipDefinition;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                entityRelationship: EntityRelationship,
                isParentRelationship: boolean,
                entityRelationshipDefinition: EntityRelationshipDefinition,
                fromRelatedEntity: Entity,
                toRelatedEntity: Entity)
    {
        super(entity);

        this.entityRelationship = entityRelationship;
        this.entityRelationshipUuid = entityRelationship && entityRelationship.uuid;
        this.isParentRelationship = isParentRelationship;
        this.entityRelationshipDefinition = entityRelationshipDefinition;
        this.fromRelatedEntity = fromRelatedEntity;
        this.toRelatedEntity = toRelatedEntity;
    }

    // ----------------------- Initialization -----------------------

    initialize(entityTypeStore: EntityTypeStore,
               doMergeEditableValues: boolean = true)
    {
        super.initialize(
            entityTypeStore,
            doMergeEditableValues);

        this.entityRelationshipDefinition =
            entityTypeStore.getRelationshipDefinitionById(this.entityRelationshipDefinition.id);
    }

    postInitialize(entityById: Map<number, Entity>)
    {
        super.postInitialize(entityById);

        if (this.fromRelatedEntity)
        {
            this.fromRelatedEntity = entityById.get(this.fromRelatedEntity.id);
        }

        if (this.toRelatedEntity)
        {
            this.toRelatedEntity = entityById.get(this.toRelatedEntity.id);
        }
    }

    getEntitiesToInitialize(): Entity[]
    {
        return [
            ...super.getEntitiesToInitialize(),
            ...this.fromRelatedEntity
                ?
                    [ this.fromRelatedEntity ]
                :
                    [],
            ...this.toRelatedEntity
                ?
                    [ this.toRelatedEntity ]
                :
                    []
        ];
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
