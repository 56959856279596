import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { TextView } from './TextView';
import TextEditor from './TextEditor';
import { TextSpecification } from './TextSpecification';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { TextEditorStore } from './TextEditorStore';
import { ExpressionSpecification } from '../../../Expression/Model/ExpressionSpecification';
import { DataObjectBespokeViewerStore } from '../../Viewer/DataObjectBespokeViewerStore';
import { TextViewerStore } from './TextViewerStore';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { express } from '../../../Expression/ExpressionUtils';
import { ComputationTypeStore } from '../../../Computation/ComputationTypeStore';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { toJS } from 'mobx';
import Editor from './Editor';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { getTextComparatorOverloads } from './Comparator/getTextComparatorOverloads';

export type TextValue = string | ExpressionSpecification;

export class TextType implements DataObjectType<TextValue>
{
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Text';
    }

    name()
    {
        return localizeText('DataObject.Type.Text', 'Tekst');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            },
            {
                comparator: Comparator.Contains
            },
            {
                comparator: Comparator.NotContains
            },
            {
                comparator: Comparator.StartsWith
            },
            {
                comparator: Comparator.EndsWith
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return TextView;
    }

    viewStore(viewerStore: DataObjectViewerStore): DataObjectBespokeViewerStore
    {
        return new TextViewerStore(viewerStore);
    }

    hooksEditor()
    {
        return Editor;
    }

    editor(): DataObjectEditorType
    {
        return TextEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new TextEditorStore(editorStore);
    }

    hasLargeEditor(specification: DataObjectSpecification): boolean
    {
        return specification.data['isRichText'] === true;
    }

    specification(): DataObjectSpecificationType
    {
        return TextSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: TextValue): boolean
    {
        return false;
    }

    getDataFromValue(value: TextValue, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();

        if (value != null)
        {
            if (typeof value === 'string')
            {
                data.text = value;
            }
            else
            {
                const expression = value as ExpressionSpecification;
                data.text = expression.expression;
                data.complex = expression;
            }
        }

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): TextValue
    {
        if (data.complex)
        {
            return data.complex;
        }
        else
        {
            return data.text;
        }
    }

    valueId(value: TextValue): string
    {
        if (typeof value === 'string')
        {
            return value;
        }
        else
        {
            return JSON.stringify(toJS(value));
        }
    }

    getString(value: TextValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        if (typeof value === 'string')
        {
            return value;
        }
        else
        {
            return express(
                this.computationTypeStore,
                context,
                value,
                true);
        }
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return getTextComparatorOverloads(this);
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        /*if (dataObject.specification.type && dataObject.specification.type instanceof EmailType)
        {
            if (dataObject.getValue() !== null)
            {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!re.test(dataObject.getValue().toLowerCase()))
                {
                    return false;
                }
            }
        }*/

        if (dataObject.specification.isRequired)
        {
            return dataObject.getValue() !== undefined && dataObject.getValue() !== null && dataObject.getValue() !== '';
        }

        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return true;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
