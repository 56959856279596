import React from 'react';
import { observer } from 'mobx-react';
import { default as InternalDataset } from '../../../Dataset/Dataset';
import { default as DatasetModel } from '../../../Dataset/Model/Dataset';
import useTypes from '../../../Type/Api/useTypes';
import DatasetWidget from '../../Model/Widget/Dataset/DatasetWidget';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';

export interface DatasetProps extends WidgetProps<DatasetWidget>
{
    whiteCard?: boolean;
}

const Dataset: React.FC<DatasetProps> =
    props =>
    {
        const types = useTypes();
        const dataset = props.widget.dataset;
        const [ specification, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    if (dataset
                        && dataset.hasValueForField(types.Dataset.Field.Specification))
                    {
                        return DatasetModel.fromDescriptor(
                            dataset.getObjectValueByField(types.Dataset.Field.Specification),
                            dataset);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    dataset,
                    types
                ]);

        if (isLoading)
        {
            return <DragHandle>
                <Card
                    inset
                >
                    <Centered>
                        <Loader />
                    </Centered>
                </Card>
            </DragHandle>;
        }
        else if (specification)
        {
            return <InternalDataset
                id={props.widget.id}
                entity={dataset}
                dataset={specification}
                name={dataset.name}
                onDelete={props.onDelete}
                whiteCard={props.whiteCard}
            />;
        }
        else
        {
            return <DragHandle>
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <LocalizedText
                                    code="Dataset.NonExistentWidget"
                                    value="Deze widget bestaat niet meer."
                                />
                            </Centered>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Delete"
                                        />
                                    }
                                    onClick={props.onDelete}
                                />
                            </Centered>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </DragHandle>;
        }
    };

export default observer(Dataset);
