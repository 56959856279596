import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import Color from '../Style/Color';
import localizeText from '../../Localization/localizeText';

export default class AppBarLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable.ref color: Color;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout,
                color: Color)
    {
        super();

        this.layout = layout;
        this.color = color;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.AppBar', 'App bar');
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            type: 'AppBar',
            layout: this.layout.toDescriptor(),
            color: this.color.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new AppBarLayout(
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext),
            Color.fromDescriptor(descriptor.color));
    }

    // ----------------------- Private logic ------------------------
}
