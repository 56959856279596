import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import ProductLines from '../ProductLines/ProductLines';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { default as EntityInput } from '../../../../../Input/Input';
import useTypes from '../../../../../Type/Api/useTypes';
import EntityTypeContext from '../../../../../Type/EntityTypeContext';
import constructEntity from '../../../../../../../../@Api/Entity/constructEntity';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import getConnectorActivationByCode from '../../../../../../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import RefreshIconButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/RefreshIconButton/RefreshIconButton';
import performAction from '../../../../../../../../@Api/Entity/performAction';
import SubscriptionActions from '../../Subscription/Actions/SubscriptionActions';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { isCommittingEntity } from '../../../../../../../../@Api/Entity/Commit/commitEntity';
import Switch from '../../../../../../../../@Future/Component/Generic/Input/Switch/Switch';
import Input from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ProductLinesProcessingOptions from '../ProductsInvoiceEditor/ProductLinesProcessingOptions';
import Divider from '../../../../../../../../@Future/Component/Generic/Divider/Divider';
import useFetchedRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import useIsInClosedState from '../../../../../Workflow/Api/useIsInClosedState';
import { updateRelationship } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { CommitContextImpl } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContextImpl';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import useSetting from '../../../../../../Setting/Api/useSetting';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import CurrencyEditor from '../../../../../Currency/CurrencyEditor';

export interface ProductLinesEditorProps
{
    entity: Entity;
    commitContext?: CommitContext;
    onClose?: () => void;
    disabled?: boolean;
    addNewLineIfEmpty?: boolean;
}

const ProductLinesEditor: React.FC<ProductLinesEditorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const [ relationship ] =
            useFetchedRelatedEntity(
                props.entity,
                types.Relationship.RelationshipDefinition.Activities,
                true);
        const pathToLines =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Activity.RelationshipDefinition.ProductLines,
                            false),
                [
                    props.entity,
                    types
                ]);

        const createOffer =
            useCallback(
                () =>
                {
                    const commitContext =
                        new CommitContextImpl({
                            allowAutoCommit: false,
                        });
                    const offer = commitContext.createEntity(types.Activity.Offer.Type);

                    updateRelationship(
                        offer,
                        true,
                        types.Activity.RelationshipDefinition.LinkedActivities,
                        props.entity,
                        commitContext
                    );

                    return constructEntity(
                        offer.entityType,
                        undefined,
                        offer,
                        props.onClose ? () => props.onClose() : undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        commitContext
                    );
                },
                [
                    types,
                    entityTypeStore,
                    props.entity,
                    props.onClose
                ]);

        const [ exactOnlineActivation ] =
            useAsyncResult(
                () =>
                    getConnectorActivationByCode('ExactOnline'),
                []);

        const reloadPricesFromExactOnline =
            useCallback(
                () =>
                    performAction(
                        exactOnlineActivation,
                        {
                            code: 'ConnectorActivation.ExactOnline.SyncPriceLists',
                            name: localizeText('PriceSynchronisation', 'Prijssynchronisatie')
                        }),
                [
                    exactOnlineActivation
                ]);

        const isSubscription =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Subscription.Type),
                [
                    props.entity,
                    types
                ]);
        const isSalesOpportunity =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.SalesOpportunity.Type),
                [
                    props.entity,
                    types
                ]);
        const isOffer =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Offer.Type),
                [
                    props.entity,
                    types
                ]);
        const isWorkOrder =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.WorkOrder.Type),
                [
                    props.entity,
                    types
                ]);
        const isClosed = useIsInClosedState(props.entity);
        const shouldShowLineProcessingEditor =
            useMemo(
                () =>
                {
                    if (isSalesOpportunity || isOffer)
                    {
                        return isClosed;
                    }
                    else
                    {
                        return isWorkOrder;
                    }
                },
                [
                    isSalesOpportunity,
                    isOffer,
                    isClosed,
                    isWorkOrder
                ]);

        const [ freshTransactionalEntity, setFreshTransactionalEntity ] = useState<Entity>(props.entity);
        const isCommittingActivityOrProductLines =
            useComputed(
                () => [
                    freshTransactionalEntity,
                    ...freshTransactionalEntity.getRelatedEntitiesByDefinition(
                        false,
                        types.Activity.RelationshipDefinition.ProductLines)
                ].some(
                    entity =>
                        isCommittingEntity(entity)),
                [
                    freshTransactionalEntity,
                    types
                ]);
        const [ showExpiredLines, setShowExpiredLines ] = useState(!isSubscription);
        const [ hideBilledNonRecurringLines, setHideBilledNonRecurringLines ] = useState(false);
        const [ selectedLines, setSelectedLines ] = useState(() => new Set<Entity>());
        const onSelect =
            useCallback(
                (line: Entity, doSelect: boolean) =>
                {
                    const newSelectedLines = new Set(selectedLines);

                    if (doSelect)
                    {
                        newSelectedLines.add(line);
                    }
                    else
                    {
                        newSelectedLines.delete(line);
                    }

                    setSelectedLines(newSelectedLines);
                },
                [
                    selectedLines,
                    setSelectedLines
                ]);
        const onSelectAll =
            useCallback(
                (lines: Entity[], doSelect: boolean) =>
                    setSelectedLines(doSelect ? new Set(lines) : new Set()),
                [
                    setSelectedLines
                ]);

        const [ areWorkorderPricesHidden ] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.WorkOrder.HidePrices
            );

        const numberOfProductLines =
            useEntityValue(
                props.entity,
                types.Activity.Field.NumberOfProductLines,
                0,
                props.commitContext
            );

        return <>
            <CardInset>
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    {
                        types.Activity.Offer.Type &&
                        !props.entity.entityType.isA(types.Activity.Offer.Type) &&
                        !props.entity.entityType.isA(types.Activity.Invoice.Type) &&
                        !props.entity.entityType.isA(types.Activity.SalesOrder.Type) &&
                        !props.entity.entityType.isA(types.Activity.Subscription.Type) &&
                            <ViewGroupItem>
                                <PrimaryButton
                                    label={`+ ${types.Activity.Offer.Type.getName()}`}
                                    onClick={createOffer}
                                    disabled={isCommittingActivityOrProductLines}
                                />
                            </ViewGroupItem>
                    }
                    {
                        isSubscription &&
                            <ViewGroupItem>
                                <SubscriptionActions
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                    }
                    <ViewGroupItem
                        ratio={1}
                    />
                    {
                        isSubscription &&
                            <ViewGroupItem>
                                <ViewGroup orientation="vertical" spacing={1}>
                                    <ViewGroupItem>
                                        <Input
                                            labelPosition="right"
                                            label={
                                                <LocalizedText
                                                    code="ProductLineEditor.ShowExpiredLines"
                                                    value="Toon verlopen productregels"
                                                />
                                            }
                                        >
                                            <Switch
                                                onToggle={setShowExpiredLines}
                                                checked={showExpiredLines}
                                            />
                                        </Input>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            labelPosition="right"
                                            label={
                                                <LocalizedText
                                                    code="ProductLineEditor.HideBilledNonRecurringLines"
                                                    value="Verberg gefactureerde eenmalige regels"
                                                />
                                            }
                                        >
                                            <Switch
                                                onToggle={setHideBilledNonRecurringLines}
                                                checked={hideBilledNonRecurringLines}
                                            />
                                        </Input>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <CurrencyEditor
                            entity={props.entity}
                            field={types.Activity.Field.Currency}
                            priceListRelationshipDefinition={types.Activity.RelationshipDefinition.PriceList}
                            disabled={numberOfProductLines > 0}
                            commitContext={props.commitContext}
                        />
                    </ViewGroupItem>
                    {
                        (!isWorkOrder || !areWorkorderPricesHidden) &&
                        <ViewGroupItem>
                            <EntityInput
                                entity={props.entity}
                                field={types.Activity.Field.IsVatIncluded}
                                labelPosition="right"
                            />
                        </ViewGroupItem>
                    }
                    {
                        exactOnlineActivation &&
                            <ViewGroupItem>
                                <RefreshIconButton
                                    tooltip={
                                        <LocalizedText
                                            code="ProductEditor.SyncProductPriceFromExactOnline"
                                            value="Prijzen opnieuw synchronizeren vanuit ExactOnline"
                                        />
                                    }
                                    onClick={reloadPricesFromExactOnline}
                                />
                            </ViewGroupItem>
                    }
                </ViewGroup>
            </CardInset>
            <ProductLines
                entity={props.entity}
                addNewLineIfEmpty={props.addNewLineIfEmpty}
                onFreshTransactionalEntity={setFreshTransactionalEntity}
                disabled={props.disabled}
                showExpiredLines={showExpiredLines}
                hideBilledNonRecurringLines={hideBilledNonRecurringLines}
                onSelectProductLine={shouldShowLineProcessingEditor ? onSelect : undefined}
                onSelectAllProductLines={shouldShowLineProcessingEditor ? onSelectAll : undefined}
                selectedProductLines={shouldShowLineProcessingEditor ? selectedLines : undefined}
                commitContext={props.commitContext}
            />
            {
                relationship && shouldShowLineProcessingEditor &&
                    <>
                        <Divider />
                        <ProductLinesProcessingOptions
                            relationship={relationship}
                            entity={props.entity}
                            path={pathToLines}
                            invoiceLines={selectedLines}
                            onChangeInvoiceLines={setSelectedLines}
                        />
                    </>
            }
        </>;
    };

export default observer(ProductLinesEditor);
