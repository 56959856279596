import { User } from '../../../../@Api/Model/Implementation/User';
import useAsyncResult, { AsyncResult } from '../../../../@Util/Async/useAsyncResult';
import { TwoFactorAuthenticationUserConfiguration } from '../Model/TwoFactorAuthenticationUserConfiguration';
import performAction from '../../../../@Api/Entity/performAction';

export function useTwoFactorAuthenticationConfiguration(
    user: User,
    refreshKey?: string
): AsyncResult<TwoFactorAuthenticationUserConfiguration | undefined>
{
    return useAsyncResult(
        async () =>
        {
            if (user)
            {
                const userConfiguration = await performAction<TwoFactorAuthenticationUserConfiguration>(
                    undefined,
                    {
                        code: 'Authentication.TwoFactor.Configuration',
                        parameters: {
                            userId: user.id,
                            key: refreshKey // dummy variable to refresh configuration
                        }
                    });
                return userConfiguration.result;
            }
            else
            {
                return undefined;
            }
        },
        [
            user,
            refreshKey
        ]
    );
}