import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import EntityCalendar from '../../../Calendar/EntityCalendar';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import AgendaButton from '../../../Agenda/Button/AgendaButton';
import { WidgetProps } from '../Widget';
import CalendarWidget from '../../Model/Widget/Calendar/CalendarWidget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import PlanButton from '../../../Agenda/PlanButton/PlanButton';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { removeValuesFromStorage } from '../../../../Setting/Api/useLocalSetting';

export interface CalendarProps extends WidgetProps<CalendarWidget>
{

}

const Calendar: React.FC<CalendarProps> =
    props =>
    {
        const settingStorageKey =
            useMemo(
                () =>
                    `Widget.${props.widget.id}.Calendar`,
                [
                    props.widget.id
                ]
            );

        const onDelete =
            useCallback(
                () =>
                {
                    removeValuesFromStorage(settingStorageKey);
                    if (props.onDelete)
                    {
                        props.onDelete();
                    }
                },
                [
                    props.onDelete,
                    settingStorageKey,
                ]
            );

        return <Card>
            <DragHandle>
                <CardInset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <CardHeader>
                                <LocalizedText
                                    code="Generic.Calendar"
                                    value="Kalender"
                                />
                            </CardHeader>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <AgendaButton
                                settingStorageKey={settingStorageKey}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <PlanButton />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <MenuButton>
                                <Menu>
                                    <DeleteItem
                                        onClick={onDelete}
                                    />
                                </Menu>
                            </MenuButton>
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </DragHandle>
            <CardInset
                top={false}
            >
                <EntityCalendar
                    viewType="month"
                    height="noscroll"
                    settingStorageKey={settingStorageKey}
                />
            </CardInset>
        </Card>;
    };

export default observer(Calendar);
