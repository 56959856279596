import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import Computation from '../../../../Automation/Function/Computation/Computation';
import TableCellLocation from './TableCellLocation';
import Layout from '../../../Layout';
import LayoutDependencyContext from '../../../LayoutDependencyContext';
import getComputationFromDescriptor from '../../../../Automation/Api/getComputationFromDescriptor';
import TableDimensionSection from './TableDimensionSection';
import ParameterDictionary from '../../../../Automation/Parameter/ParameterDictionary';
import getLayoutFromDescriptor from '../../../Api/getLayoutFromDescriptor';

export default class TableCell
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref location: TableCellLocation;
    @observable.ref rowSpan: Computation<any, any>;
    @observable.ref columnSpan: Computation<any, any>;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                location: TableCellLocation,
                rowSpan: Computation<any, any>,
                columnSpan: Computation<any, any>,
                layout: Layout)
    {
        this.id = id;
        this.location = location;
        this.rowSpan = rowSpan;
        this.columnSpan = columnSpan;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return [
            ...this.rowSpan.validate(),
            ...this.columnSpan.validate(),
            ...this.layout.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.rowSpan.getDependencies(),
            ...this.columnSpan.getDependencies(),
            ...this.layout.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            id: this.id,
            location: this.location.toDescriptor(),
            rowSpan: this.rowSpan.toDescriptor(),
            columnSpan: this.columnSpan.toDescriptor(),
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext,
                                dimensionSectionById: Map<string, TableDimensionSection>)
    {
        const id = descriptor.id;
        const location = TableCellLocation.fromDescriptor(descriptor.location);
        const rowSection = dimensionSectionById.get(location.rowId);
        const columnSection = dimensionSectionById.get(location.columnId);
        const cellDependencyContext =
            dependencyContext.withParameterDictionary(
                ParameterDictionary.union(
                    dependencyContext.parameterDictionary,
                    new ParameterDictionary(rowSection.getParameters()),
                    new ParameterDictionary(columnSection.getParameters())
                )
            );
        const rowSpan =
            await getComputationFromDescriptor(
                descriptor.rowSpan,
                cellDependencyContext);
        const columnSpan =
            await getComputationFromDescriptor(
                descriptor.columnSpan,
                cellDependencyContext);
        const layout =
            await getLayoutFromDescriptor(
                descriptor.layout,
                cellDependencyContext);

        return new TableCell(
            id,
            location,
            rowSpan,
            columnSpan,
            layout);
    }

    // ----------------------- Private logic ------------------------
}
