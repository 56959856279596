import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Statistic.module.scss';
import CircularPercentageChart from '../../../../../../../../../../@Future/Component/Generic/Chart/CircularPercentageChart';
import { dangerColor, successColor } from '../../../../../../../../../../@Resource/Theme/Theme';

export interface StatisticProps
{
    label: React.ReactNode;
    value: React.ReactNode;
    total: React.ReactNode;
    percentage: number;
    onClick: () => void;
    danger?: boolean;
}

const Statistic: React.FC<StatisticProps> =
    props =>
    {
        return <div
            className={styles.root}
            onClick={props.onClick}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={30}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem
                                    className={styles.title}
                                    ratio={1}
                                >
                                    {props.label}
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                        <ViewGroupItem
                            className={styles.value}
                        >
                            <span
                                className={styles.primary}
                            >
                                {props.value}
                            </span>
                            <span
                                className={styles.secondary}
                            >
                                / {props.total}
                            </span>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <CircularPercentageChart
                        size={60}
                        value={props.percentage}
                        color={props.danger ? dangerColor : successColor}
                        customLabel={value => <span className={styles.percentage}>{Math.round(value)}%</span>}
                    />
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

export default observer(Statistic);
