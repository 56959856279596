import React from 'react';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import ContactList from '../LeftColumn/ContactList/ContactList';
import useTypes from '../../../../Type/Api/useTypes';

export interface RightColumnProps
{
    entity: Entity;
}

const Contacts: React.FC<RightColumnProps> =
    props =>
    {
        const types = useTypes();

        if (props.entity.entityType.isA(types.Relationship.Organization.Type))
        {
            return <ContactList
                entity={props.entity}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(Contacts);
