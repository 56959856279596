import React, { useMemo } from 'react';
import { Selection } from '../../../../../../@Api/Selection/Model/Selection';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import View from '../../../View/View';
import { getRootNodeFromSelection } from '../../../../../../@Api/Selection/Api/getRootNodeFromSelection';

export interface SelectboxAdvancedSelectionSearcherProps
{
    selection: Selection;
    onSelect: (entity: Entity) => void;
}

export const SelectboxAdvancedSelectionSearcher: React.FC<SelectboxAdvancedSelectionSearcherProps> =
    ({
         selection,
         onSelect,
     }) =>
    {
        const entityType =
            useMemo(
                () =>
                    getRootNodeFromSelection(selection).entityType,
                [
                    selection,
                ]
            );

        return <View
            entityType={entityType}
            onOpen={onSelect}
            settingStorageKey={`SelectboxAdvancedSearch.EntityTypeId:${entityType.id}`}
            hideAddButton
        />;
    };
