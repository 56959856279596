import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { LayoutViewerProps } from '../../../Viewer/LayoutViewer';
import PrimitiveFormInputLayout from '../../../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import Editor from '../../../../DataObject/Editor/Editor';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import { runInAction } from 'mobx';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import { DataObjectRepresentationProps } from '../../../../DataObject/Model/DataObjectRepresentation';
import FormLayoutContexts from '../../Form/FormLayoutContexts';
import LayoutContext from '../../../../../../@Api/Layout/LayoutContext';
import useFormInputLayoutPlaceholder from '../Shared/Api/useFormInputLayoutPlaceholder';
import { useFormInputParameter } from '../Api/useFormInputParameter';
import { useFormInputParameterValue } from '../Api/useFormInputParameterValue';
import { InputEventTrigger } from '../../../../InputEvent/InputEventTrigger';
import { DataObject } from '../../../../DataObject/Model/DataObject';

export interface PrimitiveFormInputLayoutViewerProps extends LayoutViewerProps<PrimitiveFormInputLayout>
{

}

const PrimitiveFormInputLayoutViewer: React.FC<PrimitiveFormInputLayoutViewerProps> =
    props =>
    {
        const parameter =
            useFormInputParameter(
                props.layout,
                props.parameterDictionary
            );
        const value =
            useFormInputParameterValue(
                parameter,
                props.parameterAssignment
            );
        const onChange =
            useCallback(
                dataObject =>
                    runInAction(
                        () =>
                            props.parameterAssignment.setValue(
                                parameter,
                                dataObject.isEmpty
                                    ? EmptyValue.instance
                                    : new PrimitiveValue(dataObject)
                            )
                    ),
                [
                    props.parameterAssignment,
                    parameter
                ]
            );
        const placeholder =
            useFormInputLayoutPlaceholder(
                props.layout,
                props.parameterDictionary,
                props.parameterAssignment,
                props.commitContext
            );
        const isDisabled =
            useComputed(
                () =>
                    props.layout.isDisabled?.synchronouslyEvaluate(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext
                        )
                    ),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]
            );
        const representation =
            useComputed<DataObjectRepresentationProps>(
                () => ({
                    fitContent: props.layout.hasIntrinsicSize
                }),
                [
                    props.layout
                ]
            );
        const formLayoutContexts = useContext(FormLayoutContexts);
        const inputEventTriggers =
            useMemo<InputEventTrigger[]>(
                () =>
                    props.layout.eventTriggers.triggers.map(
                        trigger => ({
                            eventType: trigger.eventType,
                            trigger:
                                (value: DataObject) =>
                                {
                                    const parameter = props.parameterDictionary.getParameterById(props.layout.parameterId);
                                    const triggerParameterAssignment =
                                        props.parameterAssignment
                                            .getNewAssignmentWithParameter(
                                                parameter,
                                                new PrimitiveValue(value)
                                            );

                                    return trigger.action.apply(
                                        new LayoutContext(
                                            props.parameterDictionary,
                                            triggerParameterAssignment,
                                            props.commitContext,
                                            props.safeMode,
                                            formLayoutContexts
                                        )
                                    );
                                }
                        })),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                    props.safeMode,
                    formLayoutContexts,
                ]
            );

        useEffect(
            () =>
            {
                if (value.isEmpty)
                {
                    runInAction(
                        () =>
                            props.parameterAssignment.setValue(
                                parameter,
                                EmptyValue.instance));
                }
                else
                {
                    runInAction(
                        () =>
                            props.parameterAssignment.setValue(
                                parameter,
                                new PrimitiveValue(value.clone())));
                }
            },
            [
                props.parameterAssignment,
                parameter,
                value?.value
            ]);

        return <Editor
            dataObject={value}
            onChange={onChange}
            underline
            placeholder={placeholder}
            disabled={isDisabled}
            representation={representation}
            eventTriggers={inputEventTriggers}
        />;
    };

export default observer(PrimitiveFormInputLayoutViewer);
