import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import { BlockTypeById } from './GutenbergBlockEditor';
import RichText from '../../../../../../@Component/Generic/RichText/RichText';
import { GutenbergBlockViewerProps } from './GutenbergBlockViewerProps';

export interface BlockViewerProps extends GutenbergBlockViewerProps
{
    blocks?: any[];
}

const GutenbergBlockViewer: React.FC<BlockViewerProps> =
    ({
        blocks,
        ...viewerProps
     }) =>
    {
        const getView =
            useCallback(
                (block) =>
                    BlockTypeById.get(block.name).view(block, viewerProps),
                [
                    viewerProps,
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            {
                blocks
                    ?.filter(
                        block =>
                            BlockTypeById.has(block.name))
                    .map(
                        (block, key) =>
                            BlockTypeById.has(block.name)
                                ?
                                    <ViewGroupItem
                                        key={key}
                                    >
                                        {
                                            getView(block)
                                        }
                                    </ViewGroupItem>
                                :
                                    block?.attribute?.content &&
                                        <ViewGroupItem
                                            key={key}
                                        >
                                            <RichText
                                                value={block.attributes.content}
                                            />
                                        </ViewGroupItem>
                )
            }
        </ViewGroup>;
    };

export default observer(GutenbergBlockViewer);
