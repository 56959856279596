import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';


export default function useParent(entity: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
        {
            if (entity && entity.entityType.isA(types.Activity.Type))
            {
                return entity.getRelatedEntityByDefinition(
                    true,
                    types.Activity.RelationshipDefinition.LinkedActivities)
                    || entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.Person.Contact.RelationshipDefinition.Activities)
                    || entity.getRelatedEntityByDefinition(
                        true,
                        types.Relationship.RelationshipDefinition.Activities);
            }
            else
            {
                return undefined;
            }
        },
        [
            entity
        ]);
}
