import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import StaticTabSource from '../../../../../../../@Api/Layout/Type/TabBar/Model/StaticTabSource';
import { TabSourceEditorProps } from '../TabSourceEditor';
import StaticTabEditor from './Tab/StaticTabEditor';
import { default as TabBarTab } from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import { runInAction } from 'mobx';
import Tab from '../../../../../../../@Api/Layout/Type/TabBar/Model/Tab';
import Parameter from '../../../../../../../@Api/Automation/Parameter/Parameter';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import ComputationLayout from '../../../../../../../@Api/Layout/Type/ComputationLayout';
import TextComputation from '../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import uuid from '../../../../../../../@Util/Id/uuid';

export interface StaticTabSourceEditorProps extends TabSourceEditorProps<StaticTabSource>
{

}

const StaticTabSourceEditor: React.FC<StaticTabSourceEditorProps> =
    props =>
    {
        const { layout, source } = props;
        const createTab =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            source.tabs.push(
                                new Tab(
                                    new Parameter(
                                        uuid(),
                                        layout.parameter.type,
                                        true,
                                        localizeText('Generic.Value', 'Waarde')),
                                    undefined,
                                    new ComputationLayout(
                                        new TextComputation(
                                            `${localizeText('Generic.Value', 'Waarde')}...`,
                                            true,
                                            []))))),
                [
                    source,
                    layout
                ]);

        return <>
            {
                source.tabs
                    .map(
                        tab =>
                            <StaticTabEditor
                                {...props}
                                key={tab.getId()}
                                tab={tab}
                            />
                    )
            }
            <TabBarTab
                value="__createTab"
                onClick={createTab}
            >
                +
            </TabBarTab>
        </>;
    };

export default observer(StaticTabSourceEditor);
