import { getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { computed, observable } from 'mobx';
import { SidebarStore } from '../Sidebar/SidebarStore';
import { WindowProps, WindowStore } from '../Window/WindowStore';

export type MaxWidth = 'xs' | 'sm' | 'md';

export interface BaseDialogProps extends WindowProps
{
    isFullWidth?: PropType<BaseDialogStore, BaseDialogProps, boolean>;
    isFullScreenOnSmallScreen?: PropType<BaseDialogStore, BaseDialogProps, boolean>;
    isModal?: PropType<BaseDialogStore, BaseDialogProps, boolean>;
    alertMessage?: PropType<BaseDialogStore, BaseDialogProps, string>;
    hasContentInset?: PropType<BaseDialogStore, BaseDialogProps, boolean>;
}

const defaultProps: BaseDialogProps =
{
    isOpen: true,
    isFullWidth: true,
    isFullScreenOnSmallScreen: true,
    maxWidth: 'sm',
    hasContentInset: true
};

export abstract class BaseDialogStore extends WindowStore<BaseDialogProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable sidebarStore: SidebarStore;

    // ------------------------ Constructor -------------------------

    constructor(props: BaseDialogProps, givenDefaultProps: BaseDialogProps)
    {
        super(props, { ...defaultProps, ...givenDefaultProps});

        this.sidebarStore = new SidebarStore();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isFullWidth(): boolean
    {
        return getOrCompute(this, this.props.isFullWidth);
    }

    @computed
    get isFullScreenOnSmallScreen(): boolean
    {
        return getOrCompute(this, this.props.isFullScreenOnSmallScreen);
    }

    @computed
    get isModal(): boolean
    {
        return getOrCompute(this, this.props.isModal);
    }

    @computed
    get alertMessage(): string
    {
        return getOrCompute(this, this.props.alertMessage);
    }

    @computed
    get hasContentInset(): boolean
    {
        return getOrCompute(this, this.props.hasContentInset);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
