import { EntityField } from '../../../../../@Api/Model/Implementation/EntityField';
import { EntitySelectionBuilder } from '../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import initializeDefaultPipeline from './initializeDefaultPipeline';
import { CurrentUserStore } from '../../../User/CurrentUserStore';
import getTypes from '../../Type/Api/getTypes';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { updateRelationship } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';

export default function initializeDefaultRelationships(
    entity: Entity,
    commitContext?: CommitContext
): Promise<any>
{
    // If the entity is not new, do not initialize defaults
    // If removed, then the relationships might still be loading for the existent entity when this call is made (so they might be overwritten) which results in erronous calls
    // with duplicate relationships
    if (!entity.isNew())
    {
        return Promise.resolve();
    }

    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return Promise.all([
        initializeDefaultPipeline(entity, commitContext),
        ...[ true, false ]
            .map(
                isParent =>
                    entity.entityType.getInheritedRelationshipDefinitions(isParent)
                        .filter(
                            relationshipDefinition =>
                                relationshipDefinition.isSingular(isParent)
                                && relationshipDefinition.isPlural(!isParent) // avoid one-to-one relationship definitions
                                && !entity.hasRelationshipsByDefinition(isParent, relationshipDefinition)
                                && !relationshipDefinition.getEntityType(isParent).isA(types.Datastore.Phase.Type))
                        .map(
                            relationshipDefinition =>
                            {
                                // Set default values for datastores, templates, time registration activities and mileage registration types
                                const relatedEntityType = relationshipDefinition.getEntityType(isParent);
                                let isDefaultField: EntityField;

                                if (relatedEntityType.isA(types.Datastore.Type))
                                {
                                    if (relationshipDefinition !== types.Entity.RelationshipDefinition.ClosePhase
                                        && relationshipDefinition !== types.Entity.RelationshipDefinition.PhaseReference
                                        && relationshipDefinition !== types.Address.RelationshipDefinition.Type)
                                    {
                                        isDefaultField = types.Datastore.Field.IsDefault;
                                    }
                                }
                                else if (relatedEntityType.isA(types.Template.Type)
                                    && relationshipDefinition !== types.Template.Document.Offer.RelationshipDefinition.DefaultEmailTemplate
                                    && relationshipDefinition !== types.Template.Document.Invoice.RelationshipDefinition.DefaultEmailTemplate
                                    && relationshipDefinition !== types.Template.Document.WorkOrder.RelationshipDefinition.DefaultEmailTemplate)
                                {
                                    isDefaultField = types.Template.Field.IsDefault;
                                }

                                if (isDefaultField)
                                {
                                    return new EntitySelectionBuilder(relatedEntityType)
                                        .where(
                                            cb =>
                                                cb.eq(
                                                    EntityPath.fromEntityType(relatedEntityType)
                                                        .field(isDefaultField),
                                                    undefined,
                                                    true))
                                        .limit(1)
                                        .select()
                                        .then(
                                            results =>
                                            {
                                                if (results.length > 0
                                                    // Entity might be updated by now
                                                    && !entity.hasRelationshipsByDefinition(
                                                        isParent,
                                                        relationshipDefinition,
                                                        commitContext))
                                                {
                                                    updateRelationship(
                                                        entity,
                                                        isParent,
                                                        relationshipDefinition,
                                                        results[0].entity,
                                                        commitContext
                                                    );
                                                }
                                            });
                                }
                                else if (relatedEntityType.isA(types.TimeRegistrationActivity.Type))
                                {
                                    return selectDefaultOrLastTimeRegistrationActivity(
                                        entity,
                                        relationshipDefinition,
                                        isParent,
                                        commitContext
                                    );
                                }
                                else if (relatedEntityType.isA(types.MileageRegistrationType.Type))
                                {
                                    return selectLastMileageRegistrationActivity(
                                        entity,
                                        relationshipDefinition,
                                        isParent,
                                        commitContext
                                    );
                                }
                                else
                                {
                                    return Promise.resolve();
                                }
                            }))
            .reduce((a, b) => a.concat(b))
    ]);
}


async function selectDefaultOrLastTimeRegistrationActivity(
    entity: Entity,
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    commitContext?: CommitContext
)
{
    const types = getTypes();
    if (
        entity.hasRelationshipsByDefinition(
        isParent,
        relationshipDefinition,
        commitContext
    ))
    {
        return;
    }

    const defaultTimeRegistrationsActivity =
        await EntitySelectionBuilder.builder(
            types.TimeRegistrationActivity.Type,
            (builder, rootPath) =>
                builder
                    .join(
                        rootPath
                            .joinTo(
                                types.TimeRegistrationActivity.RelationshipDefinition.Product,
                                false
                            )
                    )
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.TimeRegistrationActivity.Field.IsDefault),
                                undefined,
                                true
                            )
                    )
                    .limit(1)
        ).select();

    if (defaultTimeRegistrationsActivity.length > 0)
    {
        const activity = defaultTimeRegistrationsActivity[0].entity;
        updateRelationship(
            entity,
            isParent,
            relationshipDefinition,
            activity,
            commitContext
        );
    }
    else
    {
        await selectLastTimeRegistrationActivity(
            entity,
            relationshipDefinition,
            isParent,
            commitContext
        );
    }
}

async function selectLastTimeRegistrationActivity(
    entity: Entity,
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    commitContext?: CommitContext
)
{
    const types = getTypes();
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const rootPath = EntityPath.fromEntityType(types.TimeRegistration.Type);

    // Select last time registration that was created by the current user
    const lastTimeRegistrations =
        await new EntitySelectionBuilder(types.TimeRegistration.Type)
            .join(
                rootPath
                    .joinTo(
                        types.TimeRegistration.RelationshipDefinition.Activity, // uursoort
                        false
                    )
                    .joinTo(
                        types.TimeRegistrationActivity.RelationshipDefinition.Product,
                        false
                    )
            )
            .where(
                cb =>
                    cb.relatedToEntity(
                        rootPath
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                                true),
                        currentUserStore.employeeEntity))
            .orderBy(
                rootPath.field(types.Entity.Field.CreationDate),
                false)
            .limit(1)
            .select();

    if (lastTimeRegistrations.length > 0)
    {
        const activity =
            lastTimeRegistrations[0].entity.getRelatedEntityByDefinition(
                false,
                types.TimeRegistration.RelationshipDefinition.Activity,
                commitContext
            );
        if (activity)
        {
            updateRelationship(
                entity,
                isParent,
                relationshipDefinition,
                activity,
                commitContext
            );
        }
    }
}

async function selectLastMileageRegistrationActivity(
    entity: Entity,
    relationshipDefinition: EntityRelationshipDefinition,
    isParent: boolean,
    commitContext?: CommitContext
)
{
    const types = getTypes();
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const rootPath = EntityPath.fromEntityType(types.MileageRegistration.Type);

    // Select last mileage registration that was created by the current user
    const lastMileageRegistrations =
        await new EntitySelectionBuilder(types.MileageRegistration.Type)
            .join(
                rootPath
                    .joinTo(
                        types.MileageRegistration.RelationshipDefinition.Type,
                        false))
            .where(
                cb =>
                    cb.relatedToEntity(
                        rootPath
                            .joinTo(
                                types.Relationship.Person.Contact.Employee.RelationshipDefinition.CreatedEntities,
                                true),
                        currentUserStore.employeeEntity))
            .orderBy(
                rootPath.field(types.Entity.Field.CreationDate),
                false)
            .limit(1)
            .select();

    if (lastMileageRegistrations.length > 0)
    {
        const type =
            lastMileageRegistrations[0].entity.getRelatedEntityByDefinition(
                false,
                types.MileageRegistration.RelationshipDefinition.Type,
                commitContext
            );

        if (type
            && !entity.hasRelationshipsByDefinition(
                isParent,
                relationshipDefinition,
                commitContext
            )
        )
        {
            updateRelationship(
                entity,
                isParent,
                relationshipDefinition,
                type,
                commitContext
            );
        }
    }
}
