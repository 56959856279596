import Role, { Permission, Privilege, RoleType } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';

function getPermission(
    role: Role,
    privilege: Privilege,
    entityType: EntityType
): Permission
{
    return role.rightByType.get(entityType)?.getPermission(privilege)
        ?? 'Inherited';
}

export function getEntityTypeSpecifiedPermission(
    rolesByType: Map<RoleType, Role>,
    role: Role,
    privilege: Privilege,
    entityType: EntityType
): Permission
{
    if (privilege === 'canExport' && !role.isExportingEnabled())
    {
        return 'Denied';
    }

    const permission =
        getPermission(
            role,
            privilege,
            entityType
        );

    if (permission === 'Inherited')
    {
        const defaultPermission =
            getPermission(
                rolesByType.get('UserDefaultRole'),
                privilege,
                entityType
            );

        if (defaultPermission === 'Inherited')
        {
            return getPermission(
                rolesByType.get('UserBaseRole'),
                privilege,
                entityType
            );
        }
        else
        {
            return defaultPermission;
        }
    }
    else
    {
        return permission;
    }
}