import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { WidgetProps } from '../Widget';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import CustomWidget from '../../Model/Widget/Custom/CustomWidget';
import LayoutViewer from '../../../../Layout/Viewer/LayoutViewer';
import styles from './Custom.module.scss';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import getCustomWidgetParameters from '../../../../../../@Api/Dashboard/CustomWidget/getCustomWidgetParameters';
import { getCustomWidgetParameterAssignment } from '../../../../../../@Api/Dashboard/CustomWidget/getCustomWidgetParameterAssignment';

export interface CustomProps extends WidgetProps<CustomWidget>
{
    whiteCard?: boolean;
}

const Custom: React.FC<CustomProps> =
    ({
        widget,
        onDelete,
     }) =>
    {
        const parameterDictionary =
            useMemo(
                () =>
                    widget.parameters ?? getCustomWidgetParameters(),
                [
                    widget,
                ]
            );
        const parameterAssignment =
            useMemo(
                () =>
                    getCustomWidgetParameterAssignment(parameterDictionary),
                [
                    parameterDictionary,
                ]
            );

        if (widget.specification)
        {
            return <Card
                classes={{
                    root: styles.root
                }}
            >
                <LayoutViewer
                    layout={widget.specification.layout}
                    parameterDictionary={parameterDictionary}
                    parameterAssignment={parameterAssignment}
                />
                <div
                    className={styles.menuButton}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        alignment="center"
                    >
                        <ViewGroupItem
                            ratio={1}
                        />
                        <ViewGroupItem>
                            <DragHandle
                                horizontal
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <MenuButton>
                                <Menu>
                                    <DeleteItem
                                        onClick={onDelete}
                                    />
                                </Menu>
                            </MenuButton>
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </Card>;
        }
        else
        {
            return <DragHandle>
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <LocalizedText
                                    code="Dataset.NonExistentWidget"
                                    value="Deze widget bestaat niet meer."
                                />
                            </Centered>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Delete"
                                        />
                                    }
                                    onClick={onDelete}
                                />
                            </Centered>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </DragHandle>;
        }
    };

export default observer(Custom);
