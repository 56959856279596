import { computed, observable } from 'mobx';
import { SupportedPreviewImageMimeTypes } from './FileUtils';
import toHumanReadableFilesize from '../../../../../@Util/File/toHumanReadableFilesize';
import { FileValueDescriptor, FileValueTypeDescriptor } from './FileValueDescriptor';

export enum FileValueType { Url, File }

export class FileValue
{
    // ------------------------- Properties -------------------------

    @observable type: FileValueType;
    @observable name: string;
    @observable description: string;
    @observable url: string;
    @observable originUrl: string;
    @observable mime: string;
    @observable file: File;
    @observable size: number;

    // ------------------------ Constructor -------------------------

    constructor(type: FileValueType,
                name: string,
                description: string,
                url: string,
                originUrl: string,
                mime: string,
                file: File,
                size: number)
    {
        this.type = type;
        this.name = name;
        this.description = description;
        this.url = url;
        this.originUrl = originUrl;
        this.mime = mime;
        this.file = file;
        this.size = size;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get isImage(): boolean
    {
        if (this.mime)
        {
            const isSupportedPrevieImageMimeType = SupportedPreviewImageMimeTypes.has(this.mime.toLowerCase());

            if (!isSupportedPrevieImageMimeType && this.name)
            {
                return !!this.name.match(/.(jpg|jpeg|png|gif|bmp|svg)$/i);
            }
            else
            {
                return isSupportedPrevieImageMimeType
            }
        }
        else if (this.name)
        {
            return !!this.name.match(/.(jpg|jpeg|png|gif|bmp|svg)$/i);
        }
        else
        {
            return false;
        }
    }

    get humanReadableFilesize(): string
    {
        return toHumanReadableFilesize(this.size, 0);
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromFile(file: File)
    {
        return new FileValue(
            FileValueType.File,
            file.name,
            undefined,
            undefined,
            undefined,
            file.type,
            file,
            file.size);
    }

    static fromBlob(blob: Blob,
                    fileName: string)
    {
        // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript/29390393
        const file: any = blob;
        file.lastModifiedDate = new Date();
        file.name = fileName;

        //Cast to a File() type
        return FileValue.fromFile(file as File);
    }

    getDataUri(): Promise<any>
    {
        if (this.file)
        {
            return new Promise(
                resolve =>
                {
                    const reader = new FileReader();

                    reader.addEventListener(
                        'load',
                        () =>
                        {
                            resolve(reader.result);
                        });

                    reader.readAsDataURL(this.file);
                });
        }
        else
        {
            return Promise.resolve(undefined);
        }
    }

    toDescriptor(): FileValueDescriptor
    {
        return {
            type: FileValueType[this.type] as FileValueTypeDescriptor,
            name: this.name,
            description: this.description,
            url: this.url,
            originUrl: this.originUrl,
            mime: this.mime,
            file: this.file,
            size: this.size,
        };
    }

    static fromDescriptor(descriptor: FileValueDescriptor): FileValue
    {
        return new FileValue(
            descriptor.type === 'File' ? FileValueType.File : FileValueType.Url,
            descriptor.name,
            descriptor.description,
            descriptor.url,
            descriptor.originUrl,
            descriptor.mime,
            descriptor.file,
            descriptor.size
        );
    }

    // ----------------------- Private logic ------------------------
}
