import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Type/Api/useTypes';
import useResults from '../../../../Selection/Hooks/useResults';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import DividedList from '../../../../List/V2/Divided/DividedList';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface ParticipationListProps
{
    entity: Entity;
}

const ParticipationList: React.FC<ParticipationListProps> =
    props =>
    {
        const types = useTypes();

        const participations =
            useResults(
                types.EventParticipation.Type,
                (builder, rootPath) =>
                    builder
                        .join(
                            rootPath
                                .joinTo(
                                    types.Activity.Event.RelationshipDefinition.Participations,
                                    true))
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath.joinTo(
                                        types.EventParticipation.RelationshipDefinition.Relationship,
                                        false),
                                    props.entity)),
                [
                    types,
                    props.entity
                ]);

        if (participations && participations.length > 0)
        {
            return <Card>
                <CardInset
                    bottom={false}
                >
                    <CardHeader>
                        <LocalizedText
                            code="Relationships.EventParticipant"
                            value="Deelnemer in evenement"
                        />
                    </CardHeader>
                </CardInset>
                <DividedList
                    entities={participations}
                    inset
                />
            </Card>;
        }
        else
        {
            return null;
        }
    };

export default observer(ParticipationList);
