import RouteMatcher from 'route-parser';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityCacheService } from '../../../Service/Entity/EntityCacheService';

const routeMatcher = new RouteMatcher('/entity/:id');

export default function getCurrentlyOpenedEntity()
{
    const results = routeMatcher.match(window.location.pathname);
    const entityCacheService = loadModuleDirectly(EntityCacheService);

    if (results)
    {
        const entityId = results.id;
        const entityIdAsNumber = parseInt(entityId);

        if (isNaN(entityIdAsNumber))
        {
            return entityCacheService.getEntity(entityId);
        }
        else
        {
            return entityCacheService.getEntityById(entityIdAsNumber);
        }
    }
    else
    {
        return undefined;
    }
}
