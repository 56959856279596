import { EntranceState } from '../Model/EntranceState';

export function serializeEntranceState(state: EntranceState)
{
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set(
        'token',
        state.token
    );
    urlSearchParams.set(
        'doRedirectToLogin',
        state.doRedirectToLogin.toString()
    );

    return urlSearchParams.toString();
}