import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityEventSelectionBuilder } from '../../Event/Selection/EntityEventSelectionBuilder';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import { EntityEventFilter } from '../../../../../@Api/Model/Implementation/EntityEventFilter';
import EntityCacheContext from '../../../../Service/Entity/EntityCacheContext';
import { IObservableArray, observable, runInAction } from 'mobx';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';

const pageSize = 20;

export default function useEvents(entityType: EntityType,
                                  filters: EntityEventFilter[],
                                  entity: Entity,
                                  onlyIncludeEventsWithoutUser?: boolean | undefined,
                                  fromDate?: Date,
                                  toDate?: Date): [ EntityEvent[], boolean, boolean, () => void ]
{
    const [ page, setPage ] = useState(0);
    const [ events, setEvents ] = useState<IObservableArray<EntityEvent>>(undefined);
    const [ isLoading, setLoading ] = useState(false);
    const entityCacheService = useContext(EntityCacheContext);
    const [ hasMore, setHasMore ] = useState(true);
    const isValid =
        useMemo(
            () =>
                events !== undefined,
            [
                events
            ]);

    // When filters change, (re)initialize data structures
    useEffect(
        () =>
        {
            setPage(0);
            setLoading(false);
            setEvents(observable.array<EntityEvent>());
            setHasMore(true);
        },
        [
            filters,
            onlyIncludeEventsWithoutUser,
            fromDate,
            toDate,
            setPage,
            setEvents,
            setHasMore
        ]);

    const addEvents =
        useCallback(
            (addedEvents: EntityEvent[],
             isPrepend: boolean = false) =>
            {
                runInAction(
                    () =>
                    {
                        if (isPrepend)
                        {
                            events.unshift(...addedEvents)
                        }
                        else
                        {
                            events.push(...addedEvents)
                        }
                    });
            },
            [
                events,
                setHasMore
            ]);

    useEffect(
        () =>
        {
            if (isValid)
            {
                if (filters.length === 0)
                {
                    addEvents([]);
                }
                else
                {
                    setLoading(true);

                    const eventSelectionBuilder = EntityEventSelectionBuilder.fromEntity(entity);

                    eventSelectionBuilder
                        .filter(...filters)
                        .onlyIncludeEventsWithoutUser(onlyIncludeEventsWithoutUser)
                        .from(fromDate)
                        .to(toDate)
                        .offset(page * pageSize)
                        .limit(pageSize)
                        .select()
                        .then(
                            result =>
                            {
                                addEvents(result.events);
                                setHasMore(result.hasMore);
                            })
                        .catch(
                            (error) =>
                            {
                                console.log(error);
                                addEvents([]);
                                setHasMore(false);
                            }
                                )
                        .finally(
                            () =>
                                setLoading(false));

                    return () =>
                        eventSelectionBuilder.dispose();
                }
            }
        },
        [
            isValid,
            entity,
            entityType,
            filters,
            onlyIncludeEventsWithoutUser,
            fromDate,
            toDate,
            page,
            addEvents,
            setHasMore
        ]);

    // Listens to all events executed in the application and dynamically extends the list of events if it matches
    useEffect(
        () =>
        {
            if (entity)
            {
                const listener =
                    (event: EntityEvent) =>
                    {
                        if (filters.some(filter => filter.appliesTo(event, entity)))
                        {
                            addEvents([ event ], true);
                        }
                    };

                entityCacheService.registerEventListener(listener);

                return () =>
                {
                    entityCacheService.unregisterEventListener(listener);
                };
            }
        },
        [
            entity,
            addEvents,
            entityCacheService,
            filters
        ]);

    const loadMore =
        useCallback(
            () =>
            {
                if (hasMore && !isLoading)
                {
                    setPage(page + 1);
                }
            },
            [
                setPage,
                page,
                hasMore,
                isLoading
            ]);

    return [ events, isLoading, hasMore, loadMore ];
}
