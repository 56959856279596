import { observable } from 'mobx';
import Computation from './Computation';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Validation from '../../Validation/Validation';

export default class LocalizedComputationItem
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable languageCode: string;
    @observable.ref computation: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                languageCode: string,
                computation: Computation<any, any>)
    {
        this.id = id;
        this.languageCode = languageCode;
        this.computation = computation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.computation.validate();
    }

    isAsync(): boolean
    {
        return this.computation.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return this.computation.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            languageCode: this.languageCode,
            computation: this.computation.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new LocalizedComputationItem(
            descriptor.id,
            descriptor.languageCode,
            await getComputationFromDescriptor(
                descriptor.computation,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
