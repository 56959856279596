import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ActivityList from '../Model/ActivityList';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import TableBody from '@material-ui/core/TableBody';
import ActivityListItemEditor from './ItemEditor/ActivityListItemEditor';
import { Droppable } from 'react-beautiful-dnd';
import uuid from '../../../../../../../../@Util/Id/uuid';
import useOnDragEnd from '../../../../../../DragAndDrop/Api/useOnDragEnd';
import { IObservableArray, runInAction } from 'mobx';

export interface ActivityListEditorProps
{
    list: ActivityList;
}

const ActivityListEditor: React.FC<ActivityListEditorProps> =
    props =>
    {
        const { list } = props;
        const [ droppableId ] = useState(() => uuid());

        useOnDragEnd(
            useCallback(
                result =>
                {
                    if (result.destination?.droppableId === droppableId)
                    {
                        runInAction(
                            () =>
                            {
                                const item =
                                    list.items.find(
                                        item =>
                                            item.id === result.draggableId);

                                const newItems =
                                    list.items.slice()
                                        .filter(
                                            checkField =>
                                                checkField !== item);

                                newItems.splice(
                                    result.destination.index,
                                    0,
                                    item);

                                (list.items as IObservableArray).replace(newItems);
                            });
                    }
                },
                [
                    droppableId,
                    list
                ]));

        return <Droppable
            droppableId={droppableId}
            type="item"
        >
            {(provided, snapshot) =>
                <Table
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{
                                    width: '5%'
                                }}
                            >

                            </TableCell>
                            <TableCell
                                style={{
                                    width: '65%'
                                }}
                            >
                                <LocalizedText
                                    code="Generic.Path"
                                    value="Pad"
                                />
                            </TableCell>
                            <TableCell
                                style={{
                                    width: '15%'
                                }}
                            >
                                <LocalizedText
                                    code="Generic.IsConstructable"
                                    value="Is aanmaakbaar"
                                />
                            </TableCell>
                            <TableCell
                                style={{
                                    width: '15%'
                                }}
                            >
                                <LocalizedText
                                    code="Generic.ShowInMore"
                                    value="Tonen in méér"
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            list.items.map(
                                (item, idx) =>
                                    <ActivityListItemEditor
                                        key={item.id}
                                        item={item}
                                        idx={idx}
                                    />)
                        }
                        {provided.placeholder}
                    </TableBody>
                </Table>
            }
        </Droppable>;
    };

export default observer(ActivityListEditor);
