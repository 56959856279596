import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';
import { EntityRelationshipDefinition } from '../../../Model/Implementation/EntityRelationshipDefinition';

export function getChildProductLineRelationshipDefinitionsToCopy(): EntityRelationshipDefinition[]
{
    const types = getTypes();

    return types.ProductLine.Type.getInheritedRelationshipDefinitions(false)
        .filter(
            relationshipDefinition =>
                relationshipDefinition.isSingular(false) &&
                relationshipDefinition !== types.ProductLine.RelationshipDefinition.InvoiceProductLine
        );
}
