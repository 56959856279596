import React, { useMemo } from 'react';
import { classNames } from '../../../Util/Class/classNames';
import styles from './Icon.module.scss';

const outlineIconNames = new Set<string>([
    'check_circle',
    'delete',
    'done',
    'help',
    'work',
    'error',
    'pause_circle',
    'play_circle',
    'chat_bubble',
    'mail',
    'add_circle',
    'remove_circle',
    'people',
    'person'
]);

export interface IconProps
{
    icon: string;
    className?: string;
    color?: string;
    size?: number | string;
    outlined?: boolean;
    rounded?: boolean;
}

const Icon: React.FC<IconProps> =
    props =>
    {
        const icon =
            useMemo(
                () =>
                    <i
                        className={classNames(styles.icon, `material-icons${props.outlined ? '-outlined' : ''}`, props.className, 'notranslate')}
                        style={{
                            fontSize: props.size,
                            color: props.rounded && !props.outlined ? 'white' :  props.color
                        }}
                    >
                        {props.outlined && outlineIconNames.has(props.icon)
                            ?
                            `${props.icon}_outline`
                            :
                            props.icon
                        }
                    </i>,
                [
                    props.outlined,
                    props.className,
                    props.size,
                    props.rounded,
                    props.color,
                    props.icon
                ]);

        if (props.rounded)
        {
            return <span
                className={classNames(styles.rounded, props.outlined ? styles.outlined : undefined)}
                style={{
                    width: props.size,
                    height: props.size,
                    backgroundColor: props.outlined ? 'transparent' : props.color,
                    borderColor: props.outlined ? props.color : undefined
                }}
            >
                {icon}
            </span>;
        }
        else
        {
            return icon;
        }
    };

Icon.defaultProps =
{
    size: 24
};

export default Icon;
