export class ApiResponse<T>
{
    // ------------------ Members -----------------------------

    ok: boolean;
    data: T;
    error: string;
    status?: number;

    // -------------- Constructor -----------------------------

    constructor(
        ok: boolean,
        data: T,
        error: string,
        status?: number
    )
    {
        this.ok = ok;
        this.data = data;
        this.error = error;
        this.status = status;
    }

    /***********************************
     **         Business logic        **
     ***********************************/

    /***********************************
     **      Getters and setters      **
     ***********************************/
}
