import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import styles from './RichTextIframe.module.scss';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import { RichTextIframeProps } from './RichTextIframe';

const NonCspCompliantRichTextIframe: React.FC<RichTextIframeProps> =
    props =>
    {
        const ref = useRef<any>();
        const value = props.value;
        const onLoad =
            useCallback(
                () =>
                {
                    try
                    {
                        if (ref.current)
                        {
                            ref.current.style.height = `${ref.current.contentWindow.document.body.scrollHeight}px`;
                        }
                    }
                    catch (e)
                    {
                        console.error(e);
                    }
                },
                [
                    ref,
                    value,
                ]
            );

        useEffect(
            () =>
            {
                if (ref.current)
                {
                    const document = ref.current.contentWindow.document;
                    document.open('text/html', 'replace');
                    document.write(value);
                    if (document.body?.style)
                    {
                        document.body.style['margin'] = '0px';
                    }
                    document.close();
                }
            },
            [
                ref,
                value,
            ]
        );

        return <iframe
            title="NonCspCompliantRichTextIframe"
            className={classNames(styles.root, props.allowScrolling && styles.allowScrolling)}
            ref={ref}
            sandbox="allow-same-origin allow-popups"
            // srcDoc does not work in Edge
            // srcDoc={valueWithAHref}
            frameBorder={0}
            scrolling={props.allowScrolling ? 'yes' : 'no'}
            onLoad={onLoad}
        />;
    };

export default observer(NonCspCompliantRichTextIframe);

