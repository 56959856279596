import React from 'react';
import { Tabs } from '@material-ui/core';
import { TabsProps } from '@material-ui/core/Tabs/Tabs';
import TabBarContainer from './TabBarContainer';

export interface TabBarClasses
{
    root?: string;
    tabBar?: string;
    indicator?: string;
}

export interface TabBarProps
{
    value?: any;
    orientation?: 'horizontal' | 'vertical'
    scrollable?: boolean;
    classes?: TabBarClasses;
    appendix?: React.ReactNode;
    fullWidth?: boolean;
    centered?: boolean;
    bordered?: boolean;
    tabIndicatorProps?: Partial<React.HTMLAttributes<HTMLDivElement>>;
    tabsProps?: Partial<TabsProps>;
}

const TabBar: React.FC<TabBarProps> =
    props =>
    {
        return <TabBarContainer
            {...props}
            renderTabs={
                tabsProps =>
                    <Tabs
                        {...tabsProps}
                    >
                        {props.children}
                    </Tabs>
            }
        />;
    };

TabBar.defaultProps = {
    orientation: 'horizontal',
    scrollable: true,
    bordered: true
};

export default TabBar;
