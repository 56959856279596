import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import useTypes from '../../../Type/Api/useTypes';
import useAsyncResult from '../../../../../../@Util/Async/useAsyncResult';
import getLayoutFromDescriptor from '../../../../../../@Api/Layout/Api/getLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../@Api/Layout/LayoutDependencyContext';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import CompositeLayout from '../../../../../../@Api/Layout/Type/CompositeLayout';
import LayoutEditor from '../../../../Layout/Editor/LayoutEditor';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import useFetchedRelatedEntity from '../../../../../../@Api/Entity/Hooks/useFetchedRelatedEntity';
import PortalContext from '../../../../../../@Api/Portal/Context/PortalContext';
import performAction from '../../../../../../@Api/Entity/performAction';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import AutomationDependencyContext from '../../../../../../@Api/Automation/AutomationDependencyContext';
import CenteredBodyLayout from '../../../../../../@Api/Layout/Type/CenteredBodyLayout';
import InsetLayout from '../../../../../../@Api/Layout/Type/InsetLayout';
import getPortalParameters from '../Portal/Api/getPortalParameters';
import Layout from '../../../../../../@Api/Layout/Layout';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface PageProps
{
    entity: Entity;
}

const PortalPage: React.FC<PageProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();

        const [ portal ] =
            useFetchedRelatedEntity(
                entity,
                types.Portal.RelationshipDefinition.Pages,
                true);

        const route = useEntityValue(entity, types.PortalPage.Field.Route);

        const [ routeParameters ] =
            useAsyncResult(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Portal.GetRouteParameters',
                            parameters: {
                                route: route || '/'
                            }
                        })
                        .then(
                            result =>
                                Promise.resolve(
                                    ParameterDictionary.fromDescriptor(
                                        result.result,
                                        AutomationDependencyContext.GetEmptyContext()))),
                [
                    route
                ]);

        const [ portalParameters ] =
            useAsyncResult(
                async () =>
                    portal &&
                        getPortalParameters(portal),
                [
                    portal
                ]);

        const parameterDictionary =
            useMemo(
                () =>
                    portalParameters && routeParameters &&
                        ParameterDictionary.union(
                            portalParameters,
                            routeParameters),
                [
                    portalParameters,
                    routeParameters
                ]);

        const [ layout, setLayout ] = useState<Layout | undefined>(undefined);
        const [ , isLoadingLayout ] =
            useAsyncResult(
                async () =>
                {
                    if (parameterDictionary)
                    {
                        const layoutDescriptor = entity.getObjectValueByField(types.PortalPage.Field.Layout);

                        if (layoutDescriptor)
                        {
                            setLayout(
                                await getLayoutFromDescriptor(
                                    layoutDescriptor,
                                    new LayoutDependencyContext(
                                        parameterDictionary)));
                        }
                        else
                        {
                            setLayout(
                                new CenteredBodyLayout(
                                    new InsetLayout(
                                        new CompositeLayout(
                                            'Vertical',
                                            []),
                                        15,
                                        0,
                                        0,
                                        0)));
                        }
                    }
                    else
                    {
                        setLayout(undefined);
                    }
                },
                [
                    parameterDictionary,
                    entity,
                    types,
                    setLayout
                ]);

        const saveLayout =
            useCallback(
                () =>
                    new CommitBuilder()
                        .setObjectValueInEntity(
                            entity,
                            types.PortalPage.Field.Layout,
                            layout.toDescriptor()
                        )
                        .commit(),
                [
                    entity,
                    types,
                    layout
                ]);

        const portalContext =
            useMemo(
                () => ({
                    portal: portal,
                    parameters: portalParameters
                }),
                [
                    portal,
                    portalParameters
                ]);

        return <>
            <BaseLayout>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Card>
                                    <CardInset
                                        bottom={false}
                                    >
                                        <ViewGroup
                                            orientation="horizontal"
                                            spacing={15}
                                        >
                                            <ViewGroupItem
                                                ratio={1}
                                            >
                                                <TypeAndName
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <SaveButton
                                                    onClick={saveLayout}
                                                />
                                            </ViewGroupItem>
                                            <ViewGroupItem>
                                                <MenuButton
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>
                                        </ViewGroup>
                                    </CardInset>
                                    <Fields
                                        entity={entity}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </BaseLayout>
            {
                isLoadingLayout &&
                    <BaseLayout>
                        <Centered
                            horizontal
                            vertical
                        >
                            <Loader />
                        </Centered>
                    </BaseLayout>
            }
            {
                portal && layout &&
                    <>
                        <BaseLayout>
                            <CardInset
                                horizontal={false}
                            >
                                <CardHeader>
                                    {types.PortalPage.Field.Layout.getName()}
                                </CardHeader>
                            </CardInset>
                        </BaseLayout>
                        <PortalContext.Provider
                            value={portalContext}
                        >
                            <LayoutEditor
                                layout={layout}
                                parameterDictionary={parameterDictionary}
                                onChange={setLayout}
                            />
                        </PortalContext.Provider>
                    </>
            }
        </>;
    };

export default observer(PortalPage);
