import { createContext } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';

export interface DroppingEntity
{
    entity: Entity;
    target: EntitySelectionAggregateResult;
    targetIdx: number;
}

export default createContext<DroppingEntity | undefined>(undefined);
