import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import uuid from '../../../../../../../../../../@Util/Id/uuid';
import FunctionContext from '../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComputationBox from '../../Box/ComputationBox';
import MappedCollectionComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/MappedCollectionComputation';

export interface MappedCollectionComputationEditorProps extends ComputationEditorProps<ValueType<any>, MappedCollectionComputation>
{

}

const MappedCollectionComputationEditor: React.FC<MappedCollectionComputationEditorProps> =
    ({
        value,
        context,
     }) =>
    {
        const setCollection =
            useCallback(
                (collection?: Computation<any, any>) =>
                    runInAction(
                        () =>
                        {
                            value.collection = collection;
                            value.elementParameter =
                                MappedCollectionComputation.getElementParameter(
                                    value.collection,
                                    value.elementParameter?.id || uuid()
                                );
                        }),
                [
                    value
                ]
            );
        const expressionContext =
            useComputed(
                () =>
                    new FunctionContext(
                        context.parameterDictionary.getNewDictionaryWithParameter(value.elementParameter),
                        context.parameterAssignment,
                        context.commitContext
                    ),
                [
                    context,
                    value
                ]
            );
        const setExpression =
            useCallback(
                (expression?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            value.expression = expression),
                [
                    value
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                label="Collectie"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={context}
                        value={value.collection}
                        onChange={setCollection}
                    />
                </ComputationBox>
            </Input>
            <Input
                label="Expressie"
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={expressionContext}
                        value={value.expression}
                        onChange={setExpression}
                    />
                </ComputationBox>
            </Input>
        </ViewGroup>;
    };

export default observer(MappedCollectionComputationEditor);
