import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import HorizontalInsetContext from '../../Context/HorizontalInsetContext';
import VerticalInsetContext from '../../Context/VerticalInsetContext';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import styles from './Inset.module.scss';
import ShowTypeContext from '../../Context/ShowTypeContext';
import EntityContext from '../../Context/EntityContext';

export interface InsetProps
{
    horizontal?: boolean;
    vertical?: boolean;
}

const Inset: React.FC<InsetProps> =
    props =>
    {
        const hasHorizontalInset = useContext(HorizontalInsetContext) || props.horizontal;
        const hasVerticalInset = useContext(VerticalInsetContext) || props.vertical;
        const showType = useContext(ShowTypeContext);
        const entity = useContext(EntityContext);

        const className =
            useMemo(
                () =>
                    classNames(
                        hasHorizontalInset && styles.horizontal,
                        hasVerticalInset && styles.vertical),
                [
                    hasHorizontalInset,
                    hasVerticalInset
                ]);

        return <div
            className={className}
        >
            {
                showType &&
                    <div
                        className={styles.typeName}
                    >
                        {entity.entityType.getName()}
                    </div>
            }
            {props.children}
        </div>
    };

export default observer(Inset);
