import { ListItemStore, ListItemStoreProps } from '../Item/ListItemStore';
import { ExpansionPanelProps, ExpansionPanelStore } from '../../../ExpansionPanel/ExpansionPanelStore';
import { computed, observable } from 'mobx';
import { getOrCompute, PropType } from '../../../../../@Framework/Store/BaseStore';
import { ViewComponent } from '../../../ViewStack/Model/ViewComponent';
import { ExpansionPanelListItem } from './ExpansionPanelListItem';

export interface ExpansionPanelListItemStoreProps<D> extends ListItemStoreProps<D>
{
    expansionPanel: PropType<ExpansionPanelListItemStore<D>, ExpansionPanelListItemStoreProps<D>, ExpansionPanelProps>;
}

export class ExpansionPanelListItemStore<D> extends ListItemStore<D, ExpansionPanelListItemStoreProps<D>>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable expansionPanelStore: ExpansionPanelStore;

    // ------------------------ Constructor -------------------------

    constructor(props: ExpansionPanelListItemStoreProps<D>,
                defaultProps?: Partial<ExpansionPanelListItemStoreProps<D>>)
    {
        super(props, defaultProps);

        this.expansionPanelStore =
            new ExpansionPanelStore(
            {
                ...this.expansionPanelProps,
                isInList: true,
                isFirstInList: () => this.isFirst,
                isLastInList: () => this.isLast,
                isLastInListGroup: () => this.isLastInGroup,
                isFirstInListGroup: () => this.isFirstInGroup,
                onSelect:
                    panelStore =>
                    {
                        // Unselect all other expansion panels
                        this.listStore.itemStores
                            .forEach(
                                itemStore =>
                                {
                                    if (itemStore instanceof ExpansionPanelListItemStore
                                        && itemStore !== this)
                                    {
                                        itemStore.expansionPanelStore.unselect();
                                    }
                                });

                        // Call onSelect if it was provided
                        if (this.expansionPanelProps.onSelect)
                        {
                            this.expansionPanelProps.onSelect(panelStore);
                        }
                    },
                onExpand:
                    panelStore =>
                    {
                        // Collapse all other expansion panels
                        this.listStore.itemStores
                            .forEach(
                                itemStore =>
                                {
                                    if (itemStore instanceof ExpansionPanelListItemStore
                                        && itemStore !== this)
                                    {
                                        itemStore.expansionPanelStore.collapse();
                                    }
                                });

                        // Call onExpand if it was provided
                        if (this.expansionPanelProps.onExpand)
                        {
                            this.expansionPanelProps.onExpand(panelStore);
                        }
                    }
            });

        // this.props.template = new ViewComponent(ExpansionPanel as any, this.expansionPanelStore);

        this.props.template =
            new ViewComponent(
                ExpansionPanelListItem,
                this);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get expansionPanelProps(): ExpansionPanelProps
    {
        return getOrCompute(this, this.props.expansionPanel);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
