import { useMemo } from 'react';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { Entity } from '../../Model/Implementation/Entity';
import useTypes from '../../../@Component/Domain/Entity/Type/Api/useTypes';

export default function useNotePath(entity: Entity)
{
    const types = useTypes();

    return useMemo(
        () =>
            EntityPath.fromEntity(entity)
                .joinTo(
                    types.Entity.RelationshipDefinition.Notes,
                    false),
        [
            entity,
            types
        ]);
}
