import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../Type/Api/useTypes';
import { ItemPlannerEmployeePlanCellProps } from '../EmployeeRow/PlanCell/EmployeeAllocationEditorEmployeePlanCell';
import usePaginatedSelection from '../../View/Api/usePaginatedSelection';
import { getWeek, getYear } from 'date-fns';
import HoverCardBottom from '../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';
import { InitializedEmployeeAllocationTableEditor } from './InitializedEmployeeAllocationTableEditor';

export interface UninitializedEmployeeAllocationTableEditorProps extends ItemPlannerEmployeePlanCellProps
{

}

export const UninitializedEmployeeAllocationTableEditor: React.FC<UninitializedEmployeeAllocationTableEditorProps> =
    observer(
        props =>
        {
            const {
                specification,
                employeeAllocationParameterAssignment,
                resource,
                startDate,
            } = props;
            const types = useTypes();
            const [ pages, isLoading, loadMore, hasMore ]  =
                usePaginatedSelection(
                    types.EmployeeAllocation.Type,
                    (builder, rootPath) =>
                        builder
                            .join(
                                rootPath.joinTo(
                                    types.EmployeeAllocationSequence.RelationshipDefinition.Allocations,
                                    true
                                )
                            )
                            .where(
                                cb =>
                                    cb.relatedToEntity(
                                        specification.resourceToEmployeeAllocationPath.reverse(),
                                        resource
                                    )
                            )
                            .where(
                                cb =>
                                    cb.eq(
                                        rootPath.field(types.EmployeeAllocation.Field.Year),
                                        undefined,
                                        getYear(startDate)
                                    )
                            )
                            .where(
                                cb =>
                                    cb.eq(
                                        rootPath.field(types.EmployeeAllocation.Field.Week),
                                        undefined,
                                        getWeek(startDate)
                                    )
                            )
                            .if(
                                () =>
                                    specification.employeeAllocationFilter !== undefined,
                                sb =>
                                    sb.where(
                                        cb =>
                                            cb.filter(
                                                specification.employeeAllocationFilter,
                                                {
                                                    parameter: specification.employeeAllocationParameter,
                                                    context:
                                                        new FunctionContext(
                                                            specification.employeeAllocationFilterParameters!,
                                                            employeeAllocationParameterAssignment
                                                        )
                                                }
                                            )
                                    )
                            )
                            .join(
                                rootPath.joinTo(
                                    types.Relationship.RelationshipDefinition.EmployeeAllocations,
                                    true
                                )
                            )
                            .join(
                                rootPath.joinTo(
                                    types.Activity.RelationshipDefinition.EmployeeAllocations,
                                    true
                                )
                            ),
                    [
                        types,
                        resource,
                        startDate,
                        specification,
                        employeeAllocationParameterAssignment,
                    ]
                );
            const items =
                useComputed(
                    () =>
                        pages
                            .map(page => page.slice())
                            .reduce(
                                (a, b) => a.concat(b),
                                []
                            )
                            .map(
                                result =>
                                    result.entity!
                            ),
                    [pages]
                );

            return <>
                <InitializedEmployeeAllocationTableEditor
                    {...props}
                    items={items}
                    loading={isLoading}
                />
                {
                    hasMore &&
                    <HoverCardBottom
                        onClick={loadMore}
                        disabled={isLoading}
                    >
                        <LocalizedText
                            code="Generic.LoadMore"
                            value="Meer laden"
                        />...
                    </HoverCardBottom>
                }
            </>;
        }
    );
