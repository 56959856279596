import React, { useCallback, useState } from 'react';
import { FileValue } from '../../../../../../DataObject/Type/File/FileValue';
import TextEditor from '../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';

import styles from './NameEditor.module.scss';

export interface NameEditorProps
{
    fileValue: FileValue;
}


const NameEditor: React.FC<NameEditorProps> =
    props =>
    {
        const [ value, setValue ] = useState(props.fileValue.name);

        const onChangeCallback =
            useCallback(
                value =>
                {
                    props.fileValue.name = value;
                    setValue(value);
                },
                [
                    props.fileValue
                ]);

        return <div className={styles.root}>
            <TextEditor
                onChange={onChangeCallback}
                value={value}
                className={styles.input}
                disableUnderline
            />
        </div>;
    };

export default NameEditor;
