import React, { useCallback } from 'react';
import { BaseButtonProps } from '../../Button';
import LabelButton from '../Label/LabelButton';
import { primaryColor } from '../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface ClearButtonProps extends BaseButtonProps
{

}

const ClearButton: React.FC<ClearButtonProps> =
    props =>
    {
        const { onClick } = props;

        const onClickCallback =
            useCallback(
                () =>
                {
                    if (onClick)
                    {
                        return onClick();
                    }
                    else
                    {
                        return Promise.resolve();
                    }
                },
                [
                    onClick
                ]);

        return <LabelButton
            label={
                <LocalizedText
                    code="Generic.Clear"
                    value="Wissen"
                />
            }
            onClick={onClickCallback}
            color={primaryColor}
            hoverColor={primaryColor}
            {...props}
        />;
    };

export default ClearButton;
