import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useResults from '../../Selection/Hooks/useResults';
import useTypes from '../../Type/Api/useTypes';
import CurrentUserContext from '../../../User/CurrentUserContext';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import styles from './DashboardSelector.module.scss';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import constructEntity from '../../../../../@Api/Entity/constructEntity';
import EntityTypeContext from '../../Type/EntityTypeContext';
import { createTransactionalModel } from '../../../../../@Util/TransactionalModelV2/index';
import RouterContext from '../../../../../@Service/Router/RouterContext';
import equalsEntity from '../../../../../@Api/Entity/Bespoke/equalsEntity';
import { autorun } from 'mobx';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import useFavoriteDashboardIds from '../Api/useFavoriteDashboardIds';
import useOnToggleFavoriteDashboard from '../Api/useOnToggleFavoriteDashboard';
import OverlineLabel from '../../../../../@Future/Component/Generic/Label/Variant/Overline/OverlineLabel';
import Item from './Item/Item';
import { default as MenuItem } from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import Divider from '../../../../../@Future/Component/Generic/Divider/Divider';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';

export interface DashboardSelectorProps
{
    renderMenu?: boolean;
    onOpen?: (dashboard: Entity) => void;
}

const DashboardSelector: React.FC<DashboardSelectorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);
        const routerStore = useContext(RouterContext);
        const [ favoriteDashboardIds ] = useFavoriteDashboardIds();

        const myDashboards =
            useResults(
                types.Dashboard.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.Dashboards,
                                            true),
                                    currentUserStore.employeeEntity))
                        .orderBy(rootPath.field(types.Entity.Field.SortIndex), true),
                [
                    types,
                    currentUserStore
                ]);

        const dashboardsSharedWithMe =
            useResults(
                types.Dashboard.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            types.Dashboard.RelationshipDefinition.SharedWithEmployees,
                                            false),
                                    currentUserStore.employeeEntity))
                        .orderBy(rootPath.field(types.Entity.Field.SortIndex), true),
                [
                    types,
                    currentUserStore
                ]);

        const dashboards =
            useComputed(
                () =>
                    myDashboards && dashboardsSharedWithMe
                        ?
                        [
                            ...myDashboards,
                            ...dashboardsSharedWithMe
                        ]
                        :
                        undefined,
                [
                    myDashboards,
                    dashboardsSharedWithMe
                ]);

        const myNotSharedDashboards =
            useComputed(
                () =>
                    (dashboards || [])
                        .filter(
                            dashboard =>
                                equalsEntity(
                                    dashboard.getRelatedEntityByDefinition(
                                        true,
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Dashboards),
                                    currentUserStore.employeeEntity)
                                && !dashboard.hasRelationshipsByDefinition(
                                false,
                                types.Dashboard.RelationshipDefinition.SharedWithEmployees)),
                [
                    dashboards,
                    types,
                    currentUserStore
                ]);

        const mySharedDashboards =
            useComputed(
                () =>
                    (dashboards || [])
                        .filter(
                            dashboard =>
                                equalsEntity(
                                    dashboard.getRelatedEntityByDefinition(
                                        true,
                                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Dashboards),
                                    currentUserStore.employeeEntity)
                                && dashboard.hasRelationshipsByDefinition(
                                false,
                                types.Dashboard.RelationshipDefinition.SharedWithEmployees)),
                [
                    dashboards,
                    types,
                    currentUserStore
                ]);

        const isActive =
            useComputed(
                () =>
                    routerStore.path.startsWith('/dashboard/'),
                [
                    routerStore
                ]);

        const [ dashboard, setDashboard ] = useState<Entity>();

        useEffect(
            () =>
                autorun(
                    () =>
                    {
                        if (dashboards)
                        {
                            if (isActive)
                            {
                                    const dashboardIdSplit = routerStore.path.split('/');
                                    const dashboardId = parseInt(dashboardIdSplit[dashboardIdSplit.length - 1]);

                                    setDashboard(
                                        dashboards.find(
                                            dashboard =>
                                                dashboard.id === dashboardId));
                            }
                            else
                            {
                                if (!dashboard)
                                {
                                    setDashboard(dashboards.find(() => true));
                                }
                            }
                        }
                    }),
            [
                dashboard,
                dashboards,
                isActive,
                routerStore,
                setDashboard
            ]);

        const [ isOpen, toggleOpen ] = useToggle(false);

        const onOpen =
            useCallback(
                (dashboard: Entity) =>
                {
                    routerStore.route(
                            `/dashboard/${dashboard.id}`);

                    toggleOpen(false);

                    if (props.onOpen)
                    {
                        props.onOpen(dashboard);
                    }
                },
                [
                    routerStore,
                    toggleOpen,
                    props.onOpen
                ]);

        const onConstruct =
            useCallback(
                () =>
                {
                    toggleOpen(false);

                    const dashboard =
                        createTransactionalModel(
                            new Entity(types.Dashboard.Type)
                                .initialize(entityTypeStore));

                    dashboard.updateRelationship(
                        true,
                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.Dashboards,
                        createTransactionalModel(currentUserStore.employeeEntity));

                    dashboard.setValueByField(
                        types.Entity.Field.SortIndex,
                        (dashboards || []).length + 1);

                    constructEntity(
                        dashboard.entityType,
                        undefined,
                        dashboard,
                        dashboard =>
                            onOpen(dashboard),
                        false);
                },
                [
                    toggleOpen,
                    types,
                    currentUserStore,
                    entityTypeStore,
                    dashboards,
                    onOpen
                ]);

        const favoriteDashboardIdsAsSet =
            useMemo(
                () =>
                    new Set(favoriteDashboardIds || []),
                [
                    favoriteDashboardIds
                ]);

        const onToggleFavoriteDashboard = useOnToggleFavoriteDashboard();
        const onClick =
            useCallback(
                () =>
                {
                    toggleOpen();
                },
                [
                    toggleOpen
                ]);

        const menu =
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <CardInset
                        bottom={false}
                    >
                        <OverlineLabel>
                            <LocalizedText
                                code="DashboardSwitcher.Title"
                                value="Mijn dashboards"
                            />
                        </OverlineLabel>
                    </CardInset>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Menu>
                        {
                            myNotSharedDashboards.map(
                                dashboardOption =>
                                    <Item
                                        key={dashboardOption.uuid}
                                        dashboard={dashboardOption}
                                        onOpen={onOpen}
                                        onToggleFavorite={onToggleFavoriteDashboard}
                                        favoriteDashboardIds={favoriteDashboardIdsAsSet}
                                        selectedDashboard={dashboard}
                                    />)
                        }
                        <MenuItem
                            icon="add"
                            name={
                                <LocalizedText
                                    code="DashboardSwitcher.NewDashboard"
                                    value="Nieuw dashboard maken"
                                />
                            }
                            onClick={onConstruct}
                        />
                    </Menu>
                    <Divider />
                </ViewGroupItem>
                {
                    mySharedDashboards.length > 0 &&
                    <ViewGroupItem>
                        <CardInset
                            vertical={false}
                        >
                            <OverlineLabel>
                                <LocalizedText
                                    code="DashboardSwitcher.SharedWithOthers"
                                    value="Gedeeld met anderen"
                                />
                            </OverlineLabel>
                        </CardInset>
                    </ViewGroupItem>
                }
                {
                    mySharedDashboards.length > 0 &&
                    <ViewGroupItem>
                        <Menu>
                            {
                                mySharedDashboards.map(
                                    dashboardOption =>
                                        <Item
                                            key={dashboardOption.uuid}
                                            dashboard={dashboardOption}
                                            onOpen={onOpen}
                                            onToggleFavorite={onToggleFavoriteDashboard}
                                            favoriteDashboardIds={favoriteDashboardIdsAsSet}
                                            selectedDashboard={dashboard}
                                        />)
                            }
                        </Menu>
                        <Divider />
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <CardInset
                        vertical={false}
                    >
                        <OverlineLabel>
                            <LocalizedText
                                code="DashboardSwitcher.SharedWithMe"
                                value="Gedeeld met mij"
                            />
                        </OverlineLabel>
                    </CardInset>
                </ViewGroupItem>
                {
                    dashboardsSharedWithMe && dashboardsSharedWithMe.length === 0 &&
                    <ViewGroupItem>
                        <CardInset
                            top={false}
                        >
                            <LocalizedText
                                code="DashboardSwitcher.NoSharedDashboards"
                                value="Er zijn géén dashboards met je gedeeld."
                            />
                        </CardInset>
                    </ViewGroupItem>
                }
                {
                    dashboardsSharedWithMe && dashboardsSharedWithMe.length > 0 &&
                    <ViewGroupItem>
                        <Menu>
                            {
                                dashboardsSharedWithMe.map(
                                    dashboardOption =>
                                        <Item
                                            key={dashboardOption.uuid}
                                            dashboard={dashboardOption}
                                            onOpen={onOpen}
                                            onToggleFavorite={onToggleFavoriteDashboard}
                                            favoriteDashboardIds={favoriteDashboardIdsAsSet}
                                            selectedDashboard={dashboard}
                                        />)
                            }
                        </Menu>
                    </ViewGroupItem>
                }
            </ViewGroup>;

        if (props.renderMenu)
        {
            return menu;
        }
        else
        {
            return <Popper
                reference={
                    <div
                        className={classNames(styles.root, dashboard && styles.dashboard, isOpen && styles.active)}
                        onClick={onClick}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={2}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <Icon
                                    size={18}
                                    icon="dashboard"
                                />
                            </ViewGroupItem>
                            <ViewGroupItem
                                className={styles.dropdownButton}
                            >
                                <Icon
                                    icon="keyboard_arrow_down"
                                    size={18}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </div>
                }
                popper={
                    <Card>
                        {menu}
                    </Card>
                }
                open={isOpen}
                onClose={() => toggleOpen(false)}
            />;
        }
    };

export default observer(DashboardSelector);
