import { amber, deepOrange, green, orange, red } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';
import { createMuiTheme } from '@material-ui/core/styles';
import { blue } from './Blue';
import createTypography from '@material-ui/core/styles/createTypography';
import shadows from '@material-ui/core/styles/shadows';

export const lighterGrey = 'rgb(253, 253, 253)';
export const lightGrey = 'rgb(250, 250, 250)';
export const regularGrey = 'rgb(240, 240, 240)';
export const mediumGrey = 'rgb(230, 230, 230)';
export const normalGrey = 'rgb(225, 225, 225)';
export const transparentColor = 'rgba(0,0,0,0)';
export const attentionColor = '#d90000';
export const accentColor = '#ed7a2c';
export const noteColor = '#fcf8e3';

export const backgroundColor = lightGrey;
export const recordBackgroundColor = 'white';
export const recordHoverBackgroundColor = 'rgb(232, 240, 254)';

export const dividerColor = mediumGrey;
export const darkDividerColor = normalGrey;

export const hoverFieldUnderlineWidth = 2;
export const hoverFieldUnderline = `${hoverFieldUnderlineWidth}px solid #000000`;
export const hoverFieldUnderlineTransparent = `${hoverFieldUnderlineWidth}px solid transparent`;

// '0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 1px -2px rgba(0, 0, 0, 0.05)'
export const defaultShadow = '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 2px 5px 0 rgba(0,0,0,0.2)';
export const lightShadow = '0px 2px 6px 0px rgba(0,0,0,.12)';
//export const darkerShadow = '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)';

shadows[1] = defaultShadow;
shadows[2] = defaultShadow;

export const NoUnderlineClassName = 'Form-NoUnderline';

export const hoverBoxShadow = `inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15) !important`;

export const viewInset = 5;
export const smallViewInset = 5;
export const buttonInset = 5;
export const cardBorderRadius = 5;
export const listAvatarSize = 35;

const palette =
    createPalette(
    {
        type: 'light',
        primary: blue,
        secondary: amber,
        // A200 is used by raised buttons as background, but default is too light
        ...{ tertiary: { ...green, A200: green.A700 } } as any,
        background:
        {
            default: backgroundColor
        }
    });

// https://material.io/design/material-studies/basil.html#typography

export const weightThin = 300;
export const weightRegular = 400;
export const weightMedium = 500;
export const weightSemiBold = 600;
export const weightBold = 700;

export const primaryColor = 'rgb(52, 151, 219)';
export const secondaryColor = deepOrange[500];
export const highlightColor = red[500];
export const dangerColor = red[500];
export const warningColor = orange[500];
export const successColor = green[500];
export const veryLightTextColor = 'rgba(0, 0, 0, 0.2)';
export const lightTextColor = 'rgba(0, 0, 0, 0.4)';
export const textColor = 'rgba(0, 0, 0, 0.87)';
export const textSecondaryColor = 'rgba(0, 0, 0, 0.54)';
export const primaryFontFamily = '"Roboto", sans-serif';
export const primaryFontSize = '14px';
export const lineHeight = 1.5;
export const underlineColor = 'rgba(0, 0, 0, 0.22)';
export const borderColor = mediumGrey;

const typography =
    createTypography(
        palette,
        {
            fontFamily: primaryFontFamily,
            body1:
            {
                // Body2 in Google
                fontFamily: primaryFontFamily,
                lineHeight: lineHeight,
                fontWeight: weightSemiBold,
                fontSize: '16px'
            },
            body2:
            {
                // Body1 in Google
                fontFamily: primaryFontFamily,
                lineHeight: lineHeight,
                fontWeight: weightRegular,
                fontSize: primaryFontSize
            },
            h6:
            {
                fontFamily: primaryFontFamily,
                lineHeight: lineHeight,
                fontWeight: weightMedium,
                fontSize: '21px'
            },
            subtitle1:
            {
                fontFamily: primaryFontFamily,
                lineHeight: lineHeight,
                fontWeight: weightMedium,
                fontSize: '18px'
            },
            caption:
            {
                fontFamily: primaryFontFamily,
                lineHeight: lineHeight,
                fontWeight: weightMedium,
                fontSize: '12px'
            },
            button:
            {
                fontFamily: primaryFontFamily,
                lineHeight: lineHeight,
                fontWeight: weightMedium,
                fontSize: primaryFontSize,
                textTransform: 'uppercase'
            }
        } as any);

// noinspection TsLint
const theme = createMuiTheme(
{
    palette: palette,
    typography: typography,
    shadows: shadows,
    props:
    {
        MuiTabLabel:
        {
            fontSize: '0.9rem'
        },
        MuiGrid:
        {
            spacing: 3
        },
        MuiPaper:
        {
            square: true
        }
    },
    overrides:
    {
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiInputAdornment:
        {
            // disableTypography: true
        },
        MuiTypography:
        {

        },
        MuiFormLabel:
        {
            root:
            {
                color: textSecondaryColor,
                fontSize: 9,
            }
        },
        MuiInputLabel:
        {
            shrink:
            {
                transform: 'translate(0, 1.5px) scale(1.0)'
            }
        },
        MuiInput:
        {
            root:
            {
                // Body2 in Google
                fontFamily: primaryFontFamily,
                fontWeight: weightRegular,
                fontSize: primaryFontSize,
                padding: '0 !important'
            },
            underline:
            {
                '&:before':
                {
                    borderBottomColor: underlineColor
                }
            }
        },
        MuiInputBase:
        {
            root:
            {
                color: 'inherit'
            },
            input:
            {
                marginBottom: 0,
                padding: '2px 0px'
            }
        },
        MuiOutlinedInput:
        {
            input:
            {
                padding: 0
            }
        },
        MuiPaper:
        {
            root:
            {
                overflow: 'visible !important'
            },
            elevation1:
            {
                boxShadow: 'none',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: borderColor
            },
            elevation2:
            {
                boxShadow: 'none',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: borderColor
            }
        },
        MuiCard:
        {
            root:
            {
                borderRadius: cardBorderRadius
            }
        },
        MuiCardContent:
        {
            root:
            {
                paddingTop: 10,
                paddingBottom: 10,
                '&:last-child':
                {
                    paddingBottom: 10
                }
            }
        },
        MuiDialog:
        {
            paper:
            {
                overflow: 'visible !important'
            }
        },
        MuiDialogContent:
        {
            root:
            {
                overflow: 'visible !important'
            }
        },
        MuiTable:
        {
            root:
            {
                overflow: 'visible !important'
            }
        },
        MuiTableRow:
        {
            head:
            {
                height: 40
            }
        },
        MuiTableCell:
        {
            root:
            {
                position: 'relative',
                padding: 6,
                '&:first-child':
                {
                    paddingLeft: 15
                },
                borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
            },
            sizeSmall:
            {
                padding: '6px 6px 6px 6px'
            }
        },
        MuiCollapse:
        {
            entered:
            {
                overflow: 'visible !important'
            }
        },
        MuiExpansionPanelSummary:
        {
            root:
            {
                '&$expanded':
                {
                    minHeight: undefined,
                    margin: 0
                }
            },
            content:
            {
                maxWidth: '100%',
                '&$expanded':
                {
                    margin: 0
                }
            }
        },
        MuiFormControlLabel:
        {
            label:
            {
                // Body2 in Google
                fontFamily: primaryFontFamily,
                fontWeight: weightRegular,
                fontSize: '13px'
            }
        },
        MuiChip:
        {
            root:
            {
                color: textColor
            }
        },
        MuiMenuItem:
        {
            root:
            {
                fontWeight: 400,
                fontSize: 14
            }
        }
    }
} as any);

// Ensure that buttons have white contrast text
theme.palette.getContrastText = (color: string) =>
{
    return 'rgba(255, 255, 255, 1)';
};

export default theme;
