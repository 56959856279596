import React, { useMemo, useState } from 'react';

import styles from './Chip.module.scss';
import { observer } from 'mobx-react-lite';
import { classNames } from '../../../Util/Class/classNames';
import Icon from '../Icon/Icon';
import { v4 as uuid } from 'uuid';
import { primaryColor } from '../../../../@Resource/Theme/Theme';

export interface ChipClasses
{
    root?: string;
}

export interface ChipProps
{
    label: React.ReactNode;
    icon?: string;
    selected?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    hoverColor?: string;
    textColor?: string;
    onClick?: () => void;
    size?: 'sm' | 'md' | 'lg',
    classes?: ChipClasses
}

const Chip: React.FC<ChipProps> =
    props =>
    {
        const [ id ] = useState(uuid());

        const backgroundColor =
            useMemo(
                () =>
                    props.backgroundColor ? props.backgroundColor : primaryColor,
                [
                    props.backgroundColor
                ]);

        const hoverColor =
            useMemo(
                () =>
                    props.hoverColor ? props.hoverColor : '#a3c7f5',
                [
                    props.hoverColor
                ]);

        const textColor =
            useMemo(
                () =>
                    props.textColor ? props.textColor : '#fff',
                [
                    props.textColor
                ]);

        const borderColor =
            useMemo(
                () =>
                    props.borderColor ? props.borderColor : backgroundColor,
                [
                    props.borderColor,
                    backgroundColor
                ]
            )

        const hasIcon =
            useMemo(
                () =>
                    props.icon !== undefined,
                [
                    props.icon
                ]);

        const isClickable = props.onClick !== undefined;

        return <>
            <style>
                {isClickable && `.${styles.root + id}:hover { background: ${hoverColor}; color: ${textColor}; }`}
                {isClickable && `.${styles.selected + id}:hover { background: ${hoverColor} !important; color: ${backgroundColor} !important;`}
            </style>
            <div
                className={
                    classNames(
                        styles.root,
                        styles.root + id,
                        props.classes && props.classes.root,
                        props.size === 'sm' && styles.small,
                        hasIcon && styles.iconWrapper,
                        isClickable && styles.clickable,
                        props.selected && styles.selected,
                        props.selected && styles.selected + id)}
                onClick={props.onClick}
                style={{
                    background: props.selected ? backgroundColor : 'transparent',
                    color: props.selected ? textColor : backgroundColor,
                    borderColor: borderColor
                }}
            >
                <div
                    className={styles.content}
                >
                    {
                        props.icon &&
                        <Icon
                            icon={props.icon}
                            size={14}
                            className={styles.icon}

                        />
                    }
                    {props.label}
                </div>
            </div>
        </>;
    };

Chip.defaultProps =
    {
        size: 'md'
    };

export default observer(Chip);
