import { BaseStore, CallbackType, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { TextStore } from '../Text/TextStore';
import { ButtonStore } from '../Button/ButtonStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { action, computed, observable } from 'mobx';
import { AvatarStore } from '../Avatar/AvatarStore';
import { grey } from '@material-ui/core/colors';
import { BadgeStore } from '../Badge/BadgeStore';
import { CSSProperties } from 'react';
import { cardBorderRadius, darkDividerColor, dividerColor, primaryColor, recordHoverBackgroundColor } from '../../../@Resource/Theme/Theme';
import { CheckboxStore } from '../Checkbox/CheckboxStore';
import { MenuItemStore } from '../Menu/MenuItemStore';
import { IconStore } from '../Icon/IconStore';
import { MoreButtonStore } from '../Button/More/MoreButtonStore';
import { VerticalAlignment } from '../../../@Resource/Theme/Alignment';
import { ExpansionTextStore } from './Text/ExpansionTextStore';

export type ButtonStoreVisibilityCallback = (buttonStore: ButtonStore, store: ExpansionPanelStore) => boolean;

export interface ExpansionPanelProps
{
    // Components
    summaryPrefix?: PropType<ExpansionPanelStore, ExpansionPanelProps, ViewComponent>;
    summarySuffix?: PropType<ExpansionPanelStore, ExpansionPanelProps, ViewComponent>;
    summaryIcon?: PropType<ExpansionPanelStore, ExpansionPanelProps, IconStore>;
    summaryAvatar?: PropType<ExpansionPanelStore, ExpansionPanelProps, AvatarStore>;
    summaryCheckbox?: PropType<ExpansionPanelStore, ExpansionPanelProps, CheckboxStore>;
    summarySecondaryAvatar?: PropType<ExpansionPanelStore, ExpansionPanelProps, AvatarStore>;
    summaryPrimaryText?: PropType<ExpansionPanelStore, ExpansionPanelProps, TextStore>;
    summarySecondaryText?: PropType<ExpansionPanelStore, ExpansionPanelProps, TextStore>;
    summaryIcons?: PropType<ExpansionPanelStore, ExpansionPanelProps, IconStore[]>;
    summaryButtons?: PropType<ExpansionPanelStore, ExpansionPanelProps, ButtonStore[]>;
    isSummaryButtonVisible?: ButtonStoreVisibilityCallback;
    summaryBadges?: PropType<ExpansionPanelStore, ExpansionPanelProps, BadgeStore[]>;
    summaryView?: PropType<ExpansionPanelStore, ExpansionPanelProps, ViewComponent>;
    detailView?: PropType<ExpansionPanelStore, ExpansionPanelProps, ViewComponent>;
    detailButtons?: PropType<ExpansionPanelStore, ExpansionPanelProps, ButtonStore[]>;
    listIndentation?: PropType<ExpansionPanelStore, ExpansionPanelProps, number>;
    detailViewStyle?: PropType<ExpansionPanelStore, ExpansionPanelProps, CSSProperties>;
    viewComponent?: PropType<ExpansionPanelStore, ExpansionPanelProps, ViewComponent>;
    bottomView?: PropType<ExpansionPanelStore, ExpansionPanelProps, ViewComponent>;
    chevronIcon?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    showSummaryIconsWhenExpanded?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    menuItems?: PropType<ExpansionPanelStore, ExpansionPanelProps, MenuItemStore[]>;

    // Corner ribbon
    showRibbonOnlyOnHover?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    ribbonColor?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    showTopDivider?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;

    // Timeline border
    showTimeline?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    timelineColor?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    isFirstInTimeline?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isLastInTimeline?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;

    // State
    isHidden?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isClickable?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isExpandable?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isCollapsible?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isSelectable?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isExpanded?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isSelected?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;

    // Callbacks
    onClick?: CallbackType<ExpansionPanelStore, ExpansionPanelProps, Promise<any>>;
    onExpand?: CallbackType<ExpansionPanelStore, ExpansionPanelProps, void>;
    onCollapse?: CallbackType<ExpansionPanelStore, ExpansionPanelProps, void>;
    onSelect?: CallbackType<ExpansionPanelStore, ExpansionPanelProps, void>;
    onUnselect?: CallbackType<ExpansionPanelStore, ExpansionPanelProps, void>;

    // Styling
    hasPopOut?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasLeftInset?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasBottomInset?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasTopInset?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isEmbedded?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasChevron?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    alwaysShowChevron?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isCard?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasVerticalExpansionInset?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasHorizontalExpansionInset?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;

    isExpansionInGrey?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    wrapExpansionInCardContent?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    backgroundColor?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    hoverBackgroundColor?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    selectedBackgroundColor?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    hasBorderLeftRight?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasBorderTop?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasBorderBottom?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasSummaryBorderBottom?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasSummaryHorizontalPadding?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasDarkBorder?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isOutlined?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    showShadow?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    selectorColor?: PropType<ExpansionPanelStore, ExpansionPanelProps, string>;
    isRounded?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    hasOutsideMargin?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    verticalAlignment?: PropType<ExpansionPanelStore, ExpansionPanelProps, VerticalAlignment>;

    // List behaviour
    isInList?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isFirstInList?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isLastInList?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isFirstInListGroup?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
    isLastInListGroup?: PropType<ExpansionPanelStore, ExpansionPanelProps, boolean>;
}

const defaultProps: Partial<ExpansionPanelProps> =
{
    hasPopOut: false,
    isHidden: false,
    isExpandable:
        store =>
            store.detailView !== undefined
                ||
            store.bottomView !== undefined,
    isClickable:
        store =>
            store.props.onClick !== undefined
                ||
            store.isExpandable,
    hoverBackgroundColor: recordHoverBackgroundColor,
    isCollapsible: true,
    isSelectable: false,
    isExpanded: false,
    summaryButtons: [],
    detailButtons: [],
    showRibbonOnlyOnHover: false,
    showTimeline: false,
    timelineColor: grey[400],
    isFirstInTimeline: false,
    isLastInTimeline: false,
    ribbonColor: undefined,
    hasBottomInset: false,
    isEmbedded: false,
    hasChevron: true,
    alwaysShowChevron: false,
    showSummaryIconsWhenExpanded: false,
    hasBorderLeftRight:
        store =>
            store.isOutlined,
    hasBorderTop: true,
        /*store =>
            store.isOutlined
                ?
                    (store.isInList && (store.isFirstInList || store.isFirstInListGroup)) || !store.isInList
                :
                    true,*/
    hasBorderBottom: true,
    hasSummaryBorderBottom: true,
    hasDarkBorder: false,
    hasSummaryHorizontalPadding: true,
    isCard: true,
    hasVerticalExpansionInset: false,
    hasHorizontalExpansionInset: false,
    isOutlined: true,
    isExpansionInGrey:
        store =>
            store.isCard,
    isSelected: false,
    wrapExpansionInCardContent: false,
    showTopDivider: true,
    selectorColor: primaryColor,
    backgroundColor:
        store =>
            store.isCard
                ?
                    'white'
                :
                    'transparent',
    menuItems: [],
    isRounded: true,
    hasOutsideMargin: false,
    verticalAlignment: VerticalAlignment.Center
};

export class ExpansionPanelStore extends BaseStore<ExpansionPanelProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable isHovering: boolean = false;
    @observable isHoveringOverSummary: boolean = false;
    @observable isHoveringOverRibbon: boolean = false;
    @observable isHoveringOverAvatar: boolean = false;
    @observable isExpanded: boolean;
    @observable isSelected: boolean;
    @observable isMarked: boolean;
    @observable detailsViewOverride: ViewComponent;

    // ------------------------ Constructor -------------------------

    constructor(props: ExpansionPanelProps)
    {
        super(props, defaultProps);

        setTimeout(
            () =>
            {
                if (getOrCompute(this, this.props.isExpanded))
                {
                    this.expand();
                }

                if (getOrCompute(this, this.props.isSelected))
                {
                    this.select();
                }
            },
            0);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get selectorColor(): string
    {
        return getOrCompute(this, this.props.selectorColor);
    }

    @computed
    get menuItems(): MenuItemStore[]
    {
        return getOrCompute(this, this.props.menuItems);
    }

    @computed
    get menuButtonStore(): MoreButtonStore
    {
        return new MoreButtonStore({
            items: () => this.menuItems
        });
    }

    @computed
    get showShadow(): boolean
    {
        let property = getOrCompute(this, this.props.showShadow);

        if (property === undefined)
        {
            return this.isCard && !this.isEmbedded;
        }
        else
        {
            return property;
        }
    }

    @computed
    get showSummaryIconsWhenExpanded(): boolean
    {
        return getOrCompute(this, this.props.showSummaryIconsWhenExpanded);
    }

    @computed
    get listIndentation(): number
    {
        return getOrCompute(this, this.props.listIndentation);
    }

    @computed
    get chevronIcon(): string
    {
        return getOrCompute(this, this.props.chevronIcon);
    }

    @computed
    get isHidden(): boolean
    {
        return getOrCompute(this, this.props.isHidden);
    }

    @computed
    get hasExpansion(): boolean
    {
        return this.detailView !== undefined
            || this.bottomView !== undefined;
    }

    @computed
    get isClickable(): boolean
    {
        return getOrCompute(this, this.props.isClickable);
    }

    @computed
    get isExpandable(): boolean
    {
        return getOrCompute(this, this.props.isExpandable);
    }

    @computed
    get isCollapsible(): boolean
    {
        return getOrCompute(this, this.props.isCollapsible);
    }

    @computed
    get isSelectable(): boolean
    {
        return getOrCompute(this, this.props.isSelectable);
    }

    @computed
    get summaryView(): ViewComponent
    {
        return getOrCompute(this, this.props.summaryView);
    }

    @computed
    get detailView(): ViewComponent
    {
        return this.detailsViewOverride || getOrCompute(this, this.props.detailView);
    }

    @computed
    get bottomView(): ViewComponent
    {
        return getOrCompute(this, this.props.bottomView);
    }

    @computed
    get ribbonColor(): string
    {
        return getOrCompute(this, this.props.ribbonColor);
    }

    @computed
    get showRibbonOnlyOnHover(): boolean
    {
        return getOrCompute(this, this.props.showRibbonOnlyOnHover);
    }

    @computed
    get showTimeline(): boolean
    {
        return getOrCompute(this, this.props.showTimeline);
    }

    @computed
    get timelineColor(): string
    {
        return getOrCompute(this, this.props.timelineColor);
    }

    @computed
    get isFirstInTimeline(): boolean
    {
        return getOrCompute(this, this.props.isFirstInTimeline);
    }

    @computed
    get isLastInTimeline(): boolean
    {
        return getOrCompute(this, this.props.isLastInTimeline);
    }

    @computed
    get hasTopInset(): boolean
    {
        return getOrCompute(this, this.props.hasTopInset);
    }

    @computed
    get hasBottomInset(): boolean
    {
        return getOrCompute(this, this.props.hasBottomInset);
    }

    @computed
    get hasBorderLeftRight(): boolean
    {
        if (this.isCard)
        {
            return getOrCompute(this, this.props.hasBorderLeftRight);
        }
        else
        {
            return false;
        }
    }

    @computed
    get hasBorderTop(): boolean
    {
        if (this.isCard)
        {
            return getOrCompute(this, this.props.hasBorderTop);
        }
        else
        {
            return false;
        }
    }

    @computed
    get hasBorderBottom(): boolean
    {
        if (this.isCard)
        {
            return getOrCompute(this, this.props.hasBorderBottom);
        }
        else
        {
            return false;
        }
    }

    @computed
    get hasSummaryBorderBottom(): boolean
    {
        return getOrCompute(this, this.props.hasSummaryBorderBottom);
    }

    @computed
    get hasSummaryHorizontalPadding(): boolean
    {
        return getOrCompute(this, this.props.hasSummaryHorizontalPadding);
    }

    @computed
    get hasSummarySmallRightPadding(): boolean
    {
        return this.menuButtonStore.isVisible
            || this.showSummaryIcons
            || this.showChevron;
    }

    @computed
    get borderRadius(): number
    {
        return this.isRounded
            ?
                cardBorderRadius
            :
                !this.hasBorderBottom && !this.hasBorderTop
                    ?
                        17
                    :
                        undefined;
    }

    @computed
    get topBorderRadius(): number
    {
        return this.showTopBorderRadius
            ?
                cardBorderRadius
            :
                0;
    }

    @computed
    get bottomBorderRadius(): number
    {
        return this.showBottomBorderRadius
            ?
                cardBorderRadius
            :
                0;
    }

    @computed
    get borderRadiusStyle(): CSSProperties
    {
        return {
            borderRadius: this.borderRadius,
            borderTopLeftRadius: this.topBorderRadius,
            borderTopRightRadius: this.topBorderRadius,
            borderBottomLeftRadius: this.bottomBorderRadius,
            borderBottomRightRadius: this.bottomBorderRadius
        };
    }

    @computed
    get isOutlined(): boolean
    {
        return getOrCompute(this, this.props.isOutlined);
    }

    @computed
    get isEmbedded(): boolean
    {
        return getOrCompute(this, this.props.isEmbedded);
    }

    @computed
    get hasChevron(): boolean
    {
        return getOrCompute(this, this.props.hasChevron);
    }

    @computed
    get hasPopOut(): boolean
    {
        return getOrCompute(this, this.props.hasPopOut);
    }

    @computed
    get alwaysShowChevron(): boolean
    {
        return getOrCompute(this, this.props.alwaysShowChevron);
    }

    @computed
    get isCard(): boolean
    {
        return getOrCompute(this, this.props.isCard);
    }

    @computed
    get hasVerticalExpansionInset(): boolean
    {
        return getOrCompute(this, this.props.hasVerticalExpansionInset);
    }

    @computed
    get hasHorizontalExpansionInset(): boolean
    {
        return getOrCompute(this, this.props.hasHorizontalExpansionInset);
    }

    @computed
    get isExpansionInGrey(): boolean
    {
        return getOrCompute(this, this.props.isExpansionInGrey);
    }

    @computed
    get detailViewStyle(): CSSProperties
    {
        return getOrCompute(this, this.props.detailViewStyle);
    }

    @computed
    get viewComponent(): ViewComponent
    {
        return getOrCompute(this, this.props.viewComponent);
    }

    @computed
    get showBoxShadow(): boolean
    {
        if (this.isSelected)
        {
            return true;
        }
        else if (this.isHoveringOverSummary)
        {
            return this.showPointerOverSummary || this.isSelectable;
        }
        else
        {
            return false;
        }
    }

    @computed
    get showPointerOverSummary(): boolean
    {
        return this.isClickable;

        // if (this.isClickable)
        // {
        //     return true;
        // }
        // else if (this.isExpanded)
        // {
        //     // Panel should be collapsible (collapse on click)
        //     return this.isCollapsible;
        // }
        // else
        // {
        //     // Panel should have an expansion (expand on click)
        //     return this.hasExpansion;
        // }
    }

    @computed
    get backgroundColor(): string
    {
        return getOrCompute(this, this.props.backgroundColor);
    }

    @computed
    get hoverBackgroundColor(): string
    {
        return getOrCompute(this, this.props.hoverBackgroundColor);
    }

    @computed
    get selectedBackgroundColor(): string
    {
        return getOrCompute(this, this.props.selectedBackgroundColor);
    }

    @computed
    get showHoverBackgroundColor(): boolean
    {
        if (this.isMarked)
        {
            return true;
        }
        else
        {
            if (this.summaryPrimaryTextStore instanceof ExpansionTextStore)
            {
                return false;
            }
            else
            {
                return this.isHoveringOverSummary && this.isClickable;
            }
        }
    }

    @computed
    get isRounded(): boolean
    {
        return getOrCompute(this, this.props.isRounded);
    }

    @computed
    get hasOutsideMargin(): boolean
    {
        return getOrCompute(this, this.props.hasOutsideMargin);
    }

    @computed
    get hasOutsideTopMargin(): boolean
    {
        return this.hasOutsideMargin &&
            !(this.isInList && (this.isFirstInList || this.isFirstInListGroup));
    }

    @computed
    get hasOutsideBottomMargin(): boolean
    {
        return this.hasOutsideMargin &&
            !(this.isInList && (this.isLastInList || this.isLastInListGroup));
    }

    @computed
    get verticalAlignment(): VerticalAlignment
    {
        return getOrCompute(this, this.props.verticalAlignment);
    }

    @computed
    get showCheckbox(): boolean
    {
        return this.summaryCheckboxStore
            ?
                this.summaryAvatarStore
                    ?
                        this.isHoveringOverAvatar
                    :
                        true
            :
                false;
    }

    @computed
    get allowHidingSummaryButtons(): boolean
    {
        if (this.summaryButtonStores)
        {
            return !this.summaryButtonStores.some(
                store => store.allowHiding === false);
        }

        return true;
    }

    @computed
    get showSummaryAvatarStore(): boolean
    {
        return this.summaryAvatarStore && (this.summaryAvatarStore.isVisible || this.showTimeline);
    }

    @computed
    get finalBackgroundColor(): string
    {
        return this.isSelected && this.selectedBackgroundColor
            ?
                this.selectedBackgroundColor
            :
                this.showHoverBackgroundColor
                    ?
                        this.hoverBackgroundColor
                    :
                        this.backgroundColor;
    }

    // --------------------------- Stores ---------------------------

    @computed
    get summaryPrefix(): ViewComponent
    {
        return getOrCompute(this, this.props.summaryPrefix);
    }

    @computed
    get summarySuffix(): ViewComponent
    {
        return getOrCompute(this, this.props.summarySuffix);
    }

    @computed
    get summaryIcon(): IconStore
    {
        return getOrCompute(this, this.props.summaryIcon);
    }

    @computed
    get summaryPrimaryTextStore(): TextStore
    {
        return getOrCompute(this, this.props.summaryPrimaryText);
    }

    @computed
    get summarySecondaryTextStore(): TextStore
    {
        return getOrCompute(this, this.props.summarySecondaryText);
    }

    @computed
    get summaryAvatarStore(): AvatarStore
    {
        return getOrCompute(this, this.props.summaryAvatar);
    }

    @computed
    get summaryCheckboxStore(): CheckboxStore
    {
        return getOrCompute(this, this.props.summaryCheckbox);
    }

    @computed
    get summaryIcons(): IconStore[]
    {
        return getOrCompute(this, this.props.summaryIcons);
    }

    @computed
    get summaryButtonStores(): ButtonStore[]
    {
        return getOrCompute(this, this.props.summaryButtons);
    }

    @computed
    get detailButtonStores(): ButtonStore[]
    {
        return getOrCompute(this, this.props.detailButtons);
    }

    @computed
    get summaryBadgeStores(): BadgeStore[]
    {
        return getOrCompute(this, this.props.summaryBadges);
    }

    @computed
    get wrapExpansionInCardContent(): boolean
    {
        return getOrCompute(this, this.props.wrapExpansionInCardContent);
    }

    @computed
    get isInList(): boolean
    {
        return getOrCompute(this, this.props.isInList);
    }

    @computed
    get isFirstInList(): boolean
    {
        return getOrCompute(this, this.props.isFirstInList);
    }

    @computed
    get isLastInList(): boolean
    {
        return getOrCompute(this, this.props.isLastInList);
    }

    @computed
    get isFirstInListGroup(): boolean
    {
        return getOrCompute(this, this.props.isFirstInListGroup);
    }

    @computed
    get isLastInListGroup(): boolean
    {
        return getOrCompute(this, this.props.isLastInListGroup);
    }

    @computed
    get showTopBorderRadius(): boolean
    {
        if (this.isRounded)
        {
            if (this.hasOutsideMargin)
            {
                return true;
            }
            else if (this.isInList)
            {
                return this.isFirstInList || this.isFirstInListGroup;
            }
            else
            {
                return true;
            }
        }
        else
        {
            return false;
        }
    }

    @computed
    get showBottomBorderRadius(): boolean
    {
        if (this.isRounded)
        {
            if (this.bottomView && this.isExpanded)
            {
                return false;
            }
            else if (this.hasOutsideMargin)
            {
                return true;
            }
            else if (this.isInList)
            {
                return this.isLastInList || this.isLastInListGroup;
            }
            else
            {
                return true;
            }
        }
        else
        {
            return false;
        }
    }

    @computed
    get visibleSummaryButtonStores(): ButtonStore[]
    {
        if (this.summaryButtonStores)
        {
            return this.summaryButtonStores
                .filter(
                    buttonStore =>
                        buttonStore.isVisible &&
                            (
                                !this.allowHidingSummaryButtons ||
                                (!this.isHoveringOverSummary && buttonStore.isHighlighted) ||
                                this.isHoveringOverSummary ||
                                (this.props.isSummaryButtonVisible !== undefined &&
                                    this.props.isSummaryButtonVisible(buttonStore, this)) ||
                                (this.showSummaryIconsWhenExpanded && this.isExpanded) ||
                                buttonStore.isPopperOpen ||
                                this.menuButtonStore.isPopperOpen));
        }
        else
        {
            return [];
        }
    }

    @computed
    get chevronButtonStore(): ButtonStore
    {
        return new ButtonStore(
        {
            icon:
                this.chevronIcon
                    ?
                        this.chevronIcon
                    :
                        this.isExpanded
                            ?
                                'expand_less'
                            :
                                'expand_more',
            color: 'default',
            onClick:
                store =>
                    this.chevronIcon
                        ?
                            this.click()
                        :
                            this.toggleExpansion()
        });
    }

    @computed
    get summarySecondaryAvatar(): AvatarStore
    {
        return getOrCompute(this, this.props.summarySecondaryAvatar);
    }

    @computed
    get borderColor()
    {
        return this.props.hasDarkBorder ? darkDividerColor : dividerColor;
    }

    @computed
    get showSummaryIcons(): boolean
    {
        return this.isHovering && this.summaryIcons && this.summaryIcons.length > 0;
    }

    @computed
    get showChevron(): boolean
    {
        return false;

        // return this.chevronIcon
        //     ?
        //         true
        //     :
        //         this.hasExpansion && this.isExpandable && this.hasChevron;
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    setSelected(isSelected: boolean)
    {
        this.props.isSelected = isSelected;
    }

    @action.bound
    startHovering()
    {
        this.isHovering = true;
    }

    @action.bound
    stopHovering()
    {
        this.isHovering = false;
        this.setMarked(false);
    }

    @action.bound
    setMarked(isMarked: boolean)
    {
        this.isMarked = isMarked;
    }

    @action.bound
    startHoveringOverSummary()
    {
        this.isHoveringOverSummary = true;
    }

    @action.bound
    stopHoveringOverSummary()
    {
        this.isHoveringOverSummary = false;
    }

    @action.bound
    startHoveringOverRibbon()
    {
        this.isHoveringOverRibbon = true;
    }

    @action.bound
    stopHoveringOverRibbon()
    {
        this.isHoveringOverRibbon = false;
    }

    @action.bound
    startHoveringOverAvatar()
    {
        this.isHoveringOverAvatar = true;
    }

    @action.bound
    stopHoveringOverAvatar()
    {
        this.isHoveringOverAvatar = false;
    }

    @action.bound
    onCollapseChange(event: any, expanded: boolean)
    {
        // if (this.hasExpansion)
        // {
        //     if (expanded)
        //     {
        //         this.expand();
        //     }
        //     else
        //     {
        //         this.collapse();
        //     }
        // }
    }

    @action.bound
    toggleExpansion()
    {
        if (this.isExpanded)
        {
            this.collapse();
        }
        else
        {
            this.expand();
        }
    }

    @action.bound
    expand(override?: ViewComponent)
    {
        this.detailsViewOverride = override;

        if (!this.isExpanded && this.hasExpansion)
        {
            this.isExpanded = true;
            
            if (this.props.onExpand)
            {
                this.props.onExpand(this);
            }
        }
    }

    @action.bound
    collapse(doClearOverride: boolean = true)
    {
        if (this.isCollapsible)
        {
            if (this.isExpanded)
            {
                this.isExpanded = false;

                if (this.props.onCollapse)
                {
                    this.props.onCollapse(this);
                }
            }

            if (doClearOverride)
            {
                this.detailsViewOverride = undefined;
            }
        }
    }

    @action.bound
    toggleSelection()
    {
        if (this.isSelectable)
        {
            if (this.isSelected)
            {
                this.unselect();
            }
            else
            {
                this.select();
            }
        }
    }

    @action.bound
    select()
    {
        if (!this.isSelected && this.isSelectable)
        {
            this.isSelected = true;

            if (this.props.onSelect)
            {
                this.props.onSelect(this);
            }
        }
    }

    @action.bound
    unselect()
    {
        if (this.isSelected)
        {
            this.isSelected = false;

            if (this.props.onUnselect)
            {
                this.props.onUnselect(this);
            }
        }
    }

    @action.bound
    click(event?: any)
    {
        if (this.showPointerOverSummary && this.isSelectable)
        {
            this.toggleSelection();
        }

        if (event)
        {
            event.stopPropagation();
        }

        this.stopHovering();

        if (this.props.onClick)
        {
            let promise = this.props.onClick(this);

            return promise;
        }
        else
        {
            this.toggleExpansion();

            return Promise.resolve();
        }
    }

    @action.bound
    setIsExpandable(isExpandable: boolean)
    {
        this.props.isExpandable = isExpandable;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
