import { ComputationType } from '../ComputationType';
import { ExpressionComputationEditorStore } from './ExpressionComputationEditorStore';
import { BaseComponentProps } from '../../../../../@Framework/Component/BaseComponent';
import { ExpressionComputationEditor } from './ExpressionComputationEditor';
import { ExpressionComputationSpecification } from './ExpressionComputationSpecification';
import { ComputationContext } from '../../ComputationContext';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { express, getDependencies } from '../../../Expression/ExpressionUtils';
import { ComputationTypeStore } from '../../ComputationTypeStore';
import { EntityFieldPath } from '../../../Entity/Path/@Model/EntityFieldPath';
import { EntityContext } from '../../../Entity/@Model/EntityContext';
import { ExpressionComputation } from './ExpressionComputation';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';

export class ExpressionComputationType extends ComputationType<ExpressionComputationEditorStore, ExpressionComputationSpecification, ExpressionComputation>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return 'Expression';
    }

    name(): string
    {
        return this.localizationStore.translate('Computation.VariableText'); // Variable text
    }

    allow(context: ComputationContext): boolean
    {
        return true;
    }

    isTerminal(): boolean
    {
        return true;
    }

    isResource(): boolean
    {
        return false;
    }

    fromSpecification(specification: ExpressionComputationSpecification): ExpressionComputation
    {
        return new ExpressionComputation(specification.expression);
    }

    toSpecification(computation: ExpressionComputation): ExpressionComputationSpecification
    {
        return {
            type: this.id(),
            expression: computation.expression
        };
    }

    compute(context: ComputationContext,
            computation: ExpressionComputation): DataObject
    {
        return DataObject.constructFromValue(
            this.getSpecification(),
            computation.expression
                ?
                    express(
                        this.computationTypeStore,
                        context,
                        computation.expression)
                :
                    undefined);
    }

    description(context: ComputationContext,
                specification: ExpressionComputationSpecification): string
    {
        return this.name();
    }

    editorStore(context: ComputationContext,
                specification: ExpressionComputationSpecification): ExpressionComputationEditorStore
    {
        return new ExpressionComputationEditorStore(
            this as any,
            context,
            specification,
            specification.expression);
    }

    editorView(): React.ComponentClass<BaseComponentProps<ExpressionComputationEditorStore>>
    {
        return ExpressionComputationEditor;
    }

    editorSpecification(store: ExpressionComputationEditorStore): ExpressionComputationSpecification
    {
        return {
            type: this.id(),
            expression: store.expression
        };
    }

    editorResultSpecification(store: ExpressionComputationEditorStore): DataObjectSpecification
    {
        return this.getSpecification();
    }

    entityFieldPaths(specification: ExpressionComputationSpecification,
                     context: EntityContext,
                     parameter?: string): EntityFieldPath[]
    {
        let paths: EntityFieldPath[] = [];

        if (specification.expression)
        {
            return getDependencies(
                this.computationTypeStore,
                specification.expression,
                context,
                parameter);
        }

        return paths;
    }

    getSpecification()
    {
        return new DataObjectSpecification(
            this.dataObjectStore.getTypeById('Text'),
            {});
    }

    // ----------------------- Private logic ------------------------
}
