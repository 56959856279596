import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import View from '../../../../Entity/View/Model/View';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import FieldPathSelector from '../../../../Entity/Path/FieldPathEditor/FieldPathSelector';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EntityFieldPath } from '../../../../Entity/Path/@Model/EntityFieldPath';
import EntityTypeContext from '../../../../Entity/Type/EntityTypeContext';
import { EntityContext } from '../../../../Entity/@Model/EntityContext';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { runInAction } from 'mobx';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Entity/Type/Api/useTypes';
import DangerButton from '../../../../../../@Future/Component/Generic/Button/Variant/DangerButton/DangerButton';
import { ViewSelector } from '../../../../Entity/View/ViewSelector/ViewSelector';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import Column from '../../../../Entity/View/Model/Column/Column';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import deleteEntity from '../../../../../../@Api/Entity/deleteEntity';
import FilterEditor from '../ResourcePlannersEditor/FilterEditor/FilterEditor';
import Predicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { ViewParams } from '../../../../Entity/View/Model/ViewParams';
import ViewColumnOrderingsEditor from './Column/ViewColumnOrderingsEditor';
import ViewColumnItemLayoutEditor from './Column/ViewColumnItemLayoutEditor';
import { SharePackEditor } from '../../../Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';
import { usePackSelector } from '../../../Page/Packs/MySharedPackManager/Api/usePackSelector';
import { default as EntityMenuButton } from '../../../../Entity/Item/MenuButton/MenuButton';
import { useNewCommitContext } from '../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import ViewOwnershipAndVisibilityEditor from './ViewOwnersAndShareEditor';
import CurrentUserContext from '../../../../User/CurrentUserContext';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import LocalizedTextEditor from '../../../../../../@Future/Component/Generic/Input/LocalizedTextEditor/LocalizedTextEditor';
import { LocalizedTextValue } from '../../../../../../@Api/Localization/LocalizedTextValue';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface ViewEditorProps
{
    entityType: EntityType;
    view: View;
    onChangeView: (view: View) => void
}

const ViewEditor: React.FC<ViewEditorProps> =
    ({
        entityType,
        view,
        onChangeView
    }) =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const localizationStore = loadModuleDirectly(LocalizationStore);
        const viewCommitContext = useNewCommitContext();
        const newViewCommitContext = useNewCommitContext();
        const entityTypeStore = useContext(EntityTypeContext);
        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(view.entity);
        const [ localizedName, setLocalizedName ] = useState<LocalizedTextValue>();

        const isSystemDefaultView =
            useMemo(
                () =>
                    view.entity === undefined,
                [
                    view.entity,
                ]);

        useEffect(
            () =>
                setLocalizedName(
                    isSystemDefaultView
                        ? ({
                            [localizationStore.languageCode]:
                                localizeText('View.AllOfType', 'Alle ${typeName}', { typeName: entityType.getName(true)?.toLowerCase() || '' })
                        })
                        : view.entity.getObjectValueByField(types.View.Field.LocalizedName)
                ),
            [
                types,
                setLocalizedName,
                isSystemDefaultView,
                view.entity,
                localizationStore.languageCode,
                entityType
            ]);

        const selectListViewType =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            view.type = 'List'
                    ),
                [
                    view
                ]);

        const selectColumnViewType =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            if (!view.specification.column)
                            {
                                view.specification.column =
                                    new Column(
                                        undefined,
                                        []);
                            }

                            view.type = 'Column';
                        }),
                [
                    view
                ]);

        const selectGroupFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            if (view.specification.column)
                            {
                                view.specification.column.groupFieldPath = fieldPath;
                            }
                        }),
                [
                    view
                ]);

        const filterParameter =
            useMemo(
                () =>
                    view.parameters.getParameterById(ViewParams.Entity),
                [
                    view
                ]);

        const setFilter =
            useCallback(
                (filter?: Predicate) =>
                    runInAction(
                        () =>
                            view.filter = filter
                    ),
                [
                    view
                ]);

        const save =
            useCallback(
                (
                    entity: Entity,
                    commitContext: CommitContext
                ) =>
                {
                    commitContext.setValue(
                        entity,
                        types.View.Field.LocalizedName,
                        DataObject.constructFromTypeIdAndValue(
                            'LocalizedText',
                            localizedName
                        )
                    );

                    commitContext.setValue(
                        entity,
                        types.View.Field.Specification,
                        DataObject.constructFromTypeIdAndValue(
                            'Complex',
                            view.toDescriptor()
                        )
                    );

                    commitContext
                       .commit()
                       .then(
                           () =>
                               onChangeView(
                                   new View(
                                       view.type,
                                       localizedName.toString(),
                                       entityType,
                                       view.parameters,
                                       view.filter,
                                       view.specification,
                                       entity)
                               )
                       )
                        .finally(
                            () =>
                                commitContext.clear()
                        );
                },
                [
                    types,
                    localizedName,
                    view,
                    entityType,
                    onChangeView,
                ]);

        const onSave =
            useCallback(
                () =>
                {
                    save(view.entity, viewCommitContext);
                    newViewCommitContext.clear();
                },
                [
                    save,
                    view.entity,
                    viewCommitContext,
                    newViewCommitContext
                ]);

        const onSaveAsNew =
            useCallback(
                () =>
                {
                    const newEntity =
                        newViewCommitContext.createEntity(
                            types.View.Type
                        );

                    newEntity.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.Views,
                        entityType.entity
                    );

                    newViewCommitContext.setValue(
                        newEntity,
                        types.View.Field.IsPrivate,
                        DataObject.constructFromTypeIdAndValue(
                            'Boolean',
                            view.entity?.getObjectValueByField<boolean>(
                                types.View.Field.IsPrivate,
                                viewCommitContext
                            )
                        )
                    );

                    [
                        types.View.RelationshipDefinition.Owners,
                        types.View.RelationshipDefinition.SharedWithEmployees,
                        types.View.RelationshipDefinition.SharedWithTeams,
                    ].map(
                        relationshipDefinition =>
                            view.entity
                                ?.getRelatedEntitiesByDefinition(
                                    false,
                                    relationshipDefinition,
                                    viewCommitContext
                                )
                                ?.map(
                                    relationship =>
                                        newViewCommitContext.createRelationship(
                                            newEntity,
                                            relationshipDefinition,
                                            false,
                                            relationship
                                        )
                                )
                    );

                    const isCurrentEmployeeOwner = view.entity
                        ?.getRelatedEntitiesByDefinition(
                            false,
                            types.View.RelationshipDefinition.Owners,
                            viewCommitContext
                        )
                        .some(
                            owner =>
                                owner.id === currentUserStore.employeeEntity.id
                        );

                    if (!isCurrentEmployeeOwner)
                    {
                        newViewCommitContext.createRelationship(
                            newEntity,
                            types.View.RelationshipDefinition.Owners,
                            false,
                            currentUserStore.employeeEntity
                        );
                    }

                    save(newEntity, newViewCommitContext);
                    viewCommitContext.clear();
                },
                [
                    types,
                    newViewCommitContext,
                    viewCommitContext,
                    view.entity,
                    currentUserStore.employeeEntity,
                    entityType,
                    save
                ]);

        const onDelete =
            useCallback(
                () =>
                    onChangeView(undefined),
                [
                    onChangeView,
                ]);

        const deleteView =
            useCallback(
                () =>
                {
                    deleteEntity(view.entity)
                        .then(
                            () =>
                                onChangeView(undefined),
                        );
                },
                [
                    view.entity,
                    onChangeView,
                ]);


        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(entityType),
                [
                    entityType
                ]
            );

        const isViewPrivate =
            useMemo(
                () =>
                    view.entity
                    && view.entity.hasValueForField(types.View.Field.IsPrivate)
                    && view.entity.getObjectValueByField<boolean>(types.View.Field.IsPrivate),
                [
                    types,
                    view.entity
                ]
            )

        const isCurrentUserViewOwner =
            useMemo(
                () =>
                    view.entity
                    && view.entity.getRelatedEntitiesByDefinition(
                        false,
                        types.View.RelationshipDefinition.Owners)
                        .some(
                            owner =>
                                owner.id === currentUserStore.employeeEntity.id
                        ),
                [
                    types,
                    view.entity,
                    currentUserStore.employeeEntity
                ]
            )

        const isEditable =
            useComputed(
                () =>
                    view.entity
                    && isMutable(view.entity)
                    && (!isViewPrivate || isCurrentUserViewOwner),
                [
                    types,
                    view.entity,
                    isViewPrivate,
                    isCurrentUserViewOwner
                ]);

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <Input
                                label={
                                    <LocalizedText
                                        code="Configuration.View.SelectedView"
                                        value="Geselecteerde weergave"
                                    />
                                }
                                labelPosition="left"
                            >
                                <ViewSelector
                                    // whenever the view changes, the selector should be rerendered
                                    key={view.id}
                                    entityType={entityType}
                                    view={view}
                                    onChange={onChangeView}
                                />
                            </Input>
                        </ViewGroupItem>
                        <ViewGroupItem>
                        {
                            !isSystemDefaultView && isEditable &&
                            <EntityMenuButton
                                entity={view.entity}
                                onDelete={onDelete}
                            />
                        }
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <Input
                        label={types.View.Field.LocalizedName.getName()}
                        labelPosition="left"
                    >
                        <LocalizedTextEditor
                            value={localizedName}
                            onChange={setLocalizedName}
                        />
                    </Input>
                </ViewGroupItem>
                {
                    isEditable && isPackSelectorVisible &&
                    <ViewGroupItem>
                        <SharePackEditor
                            entity={view.entity}
                            ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                            commitContext={viewCommitContext}
                            doAutocommit={false}
                        />
                    </ViewGroupItem>
                }
                {
                    !isSystemDefaultView &&
                    <ViewGroupItem>
                        <ViewOwnershipAndVisibilityEditor
                            entity={view.entity}
                            commitContext={viewCommitContext}
                            disabled={!isEditable}
                        />
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <Input
                        label={
                            <LocalizedText
                                code="Configuration.View.Type"
                                value="Weergavetype"
                            />
                        }
                        labelPosition="left"
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <IconButton
                                    icon="view_list"
                                    tooltip={
                                        <LocalizedText
                                            code="Configuration.View.ListView"
                                            value="Lijstweergave"
                                        />
                                    }
                                    onClick={selectListViewType}
                                    color={view.type === 'List' ? primaryColor : textSecondaryColor}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <IconButton
                                    icon="view_column"
                                    tooltip={
                                        <LocalizedText
                                            code="Configuration.View.ColumnView"
                                            value="Kolomweergave"
                                        />
                                    }
                                    onClick={selectColumnViewType}
                                    color={view.type === 'Column' ? primaryColor : textSecondaryColor}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                    </Input>
                </ViewGroupItem>
                <ViewGroupItem>
                    {
                        view.type === 'Column' &&
                        <Input
                            label={
                                <LocalizedText
                                    code="Configuration.View.GroupedBy"
                                    value="Gegroepeerd op"
                                />
                            }
                            labelPosition="left"
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={10}
                            >
                                <ViewGroupItem>
                                    {view.specification.column?.groupFieldPath?.getName(entityTypeStore)}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <MenuButton
                                        icon="edit"
                                        tooltip={
                                            <LocalizedText
                                                code="Generic.Edit"
                                                value="Wijzigen"
                                            />
                                        }
                                        small
                                    >
                                        <FieldPathSelector
                                            context={context}
                                            onSelect={selectGroupFieldPath}
                                        />
                                    </MenuButton>
                                </ViewGroupItem>
                            </ViewGroup>
                        </Input>
                    }
                </ViewGroupItem>
                {
                    view.type === 'Column' &&
                    view.specification.column &&
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            <ViewGroupItem>
                                <ViewColumnOrderingsEditor
                                    view={view}
                                    column={view.specification.column}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewColumnItemLayoutEditor
                                    view={view}
                                    column={view.specification.column}
                                />
                            </ViewGroupItem>
                        </ViewGroup>
                }
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <CardHeader>
                                <LocalizedText
                                    code="Configuration.View.Filter"
                                    value="Filter"
                                />
                            </CardHeader>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <FilterEditor
                                filterParameter={filterParameter}
                                parameterDictionary={view.parameters}
                                value={view.filter}
                                onChange={setFilter}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        {
                            !isSystemDefaultView &&
                            <PrimaryButton
                                label={
                                    <LocalizedText
                                        code="Generic.Save"
                                    />
                                }
                                onClick={onSave}
                                disabled={!isEditable}
                            />
                        }
                        <PrimaryButton
                            label={
                                <LocalizedText
                                    code="Configuration.View.SaveAsNew"
                                    value="Opslaan als nieuwe weergave"
                                />
                            }
                            onClick={onSaveAsNew}
                        />
                        {
                            !isSystemDefaultView &&
                            <DangerButton
                                label={
                                    <LocalizedText
                                        code="Generic.Delete"
                                    />
                                }
                                onClick={deleteView}
                                disabled={!isEditable}
                            />
                        }
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(ViewEditor);
