import { useContext } from 'react';
import LocalizationContext from '../LocalizationContext';

export default function useTranslation(code: string,
                                       ...args: string[])
{
    const localizationStore = useContext(LocalizationContext);

    return localizationStore.translate(code, ...args);
}
