import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import LayoutAction from '../Action/LayoutAction';
import getLayoutActionFromDescriptor from '../Action/Api/getLayoutActionFromDescriptor';
import localizeText from '../../Localization/localizeText';

export default class ClickLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref layout: Layout;
    @observable.ref action: LayoutAction;

    // ------------------------ Constructor -------------------------

    constructor(layout: Layout,
                action: LayoutAction)
    {
        super();

        this.layout = layout;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Click', 'Klikregio');
    }

    validate(): Validation[]
    {
        return [
            ...this.layout.validate(),
            ...this.action.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.layout.getDependencies(),
            ...this.action.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Click',
            layout: this.layout.toDescriptor(),
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new ClickLayout(
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext),
            await getLayoutActionFromDescriptor(
                descriptor.action,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
