import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import localizeText from '../../Localization/localizeText';
import { Orientation } from './CompositeLayout';
import Computation from '../../Automation/Function/Computation/Computation';
import Parameter from '../../Automation/Parameter/Parameter';
import getDependenciesWithoutParameters from '../../Automation/Api/getDependenciesWithoutParameters';
import getComputationFromDescriptor from '../../Automation/Api/getComputationFromDescriptor';
import CollectionType from '../../Automation/Value/Type/CollectionType';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';

export default class CollectionLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable orientation: Orientation;
    @observable.ref collection: Computation<any, any>;
    @observable.ref elementParameter: Parameter<any>;
    @observable.ref itemLayout: Layout;
    @observable.ref itemRatio: Computation<any, any>;
    @observable spacing: number;
    @observable hasDivider: boolean;
    @observable isWrapped: boolean;

    // ------------------------ Constructor -------------------------

    constructor(orientation: Orientation,
                collection: Computation<any, any>,
                elementParameter: Parameter<any>,
                itemLayout: Layout,
                itemRatio: Computation<any, any>,
                spacing: number,
                hasDivider: boolean,
                isWrapped: boolean)
    {
        super();

        this.orientation = orientation;
        this.collection = collection;
        this.elementParameter = elementParameter;
        this.itemLayout = itemLayout;
        this.itemRatio = itemRatio;
        this.spacing = spacing;
        this.hasDivider = hasDivider;
        this.isWrapped = isWrapped;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        switch (this.orientation)
        {
            case 'Vertical':
                return localizeText('Layout.Collection.Vertical', 'Dynamische verticale layout');

            case 'Horizontal':
                return localizeText('Layout.Collection.Horizontal', 'Dynamische horizontale layout');
        }
    }

    validate(): Validation[]
    {
        return [
            ...this.collection.validate(),
            ...this.itemLayout.validate(),
            ...this.itemRatio.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.collection.getDependencies(),
            ...getDependenciesWithoutParameters(
                this.itemLayout.getDependencies(),
                this.elementParameter),
            ...getDependenciesWithoutParameters(
                this.itemRatio.getDependencies(),
                this.elementParameter)
        ];
    }

    toDescriptor()
    {
        return {
            type: 'Collection',
            orientation: this.orientation,
            collection: this.collection.toDescriptor(),
            elementParameterId: this.elementParameter.id,
            itemLayout: this.itemLayout.toDescriptor(),
            itemRatio: this.itemRatio.toDescriptor(),
            spacing: this.spacing,
            hasDivider: this.hasDivider,
            isWrapped: this.isWrapped
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const orientation = descriptor.orientation;
        const collection =
            await getComputationFromDescriptor(
                descriptor.collection,
                dependencyContext);
        const elementParameter =
            CollectionLayout.getParameterFromCollection(
                descriptor.elementParameterId,
                collection);
        const itemDependencyContext =
            dependencyContext.withParameterDictionary(
                dependencyContext.parameterDictionary
                    .getNewDictionaryWithParameter(elementParameter)
            );
        const itemLayout =
            await getLayoutFromDescriptor(
                descriptor.itemLayout,
                itemDependencyContext);
        const itemRatio =
            await getComputationFromDescriptor(
                descriptor.itemRatio,
                itemDependencyContext);

        return new CollectionLayout(
            orientation,
            collection,
            elementParameter,
            itemLayout,
            itemRatio,
            descriptor.spacing,
            descriptor.hasDivider,
            descriptor.isWrapped);
    }

    static getParameterFromCollection(id: string,
                                      collection: Computation<any, any>)
    {
        const collectionType = collection.getType();

        if (collectionType instanceof CollectionType)
        {
            return new Parameter(
                id,
                collectionType.type,
                true,
                collectionType.type.getName());
        }
        else
        {
            throw new Error('Expected collection type');
        }
    }

    // ----------------------- Private logic ------------------------
}
