import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import { Table, TableFooter } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import useTypes from '../../../../../Type/Api/useTypes';
import styles from './ProductLinesTable.module.scss';
import { classNames } from '../../../../../../../../@Future/Util/Class/classNames';
import uuid from '../../../../../../../../@Util/Id/uuid';
import useOnDragEnd from '../../../../../../DragAndDrop/Api/useOnDragEnd';
import { observable, runInAction } from 'mobx';
import { createNumberComparator } from '../../../../../../../Generic/List/V2/Comparator/NumberComparator';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { deduplicateArray } from '../../../../../../../../@Util/Array/deduplicateArray';
import { Droppable } from 'react-beautiful-dnd';
import useSetting from '../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../@Api/Settings/Setting';
import useAsyncResult from '../../../../../../../../@Util/Async/useAsyncResult';
import { getAndInitializeLayoutFromDescriptor } from '../../../../../../../../@Api/Layout/Api/getAndInitializeLayoutFromDescriptor';
import LayoutDependencyContext from '../../../../../../../../@Api/Layout/LayoutDependencyContext';
import { getEntityLayoutParameter } from '../../../../../Layout/Api/getEntityLayoutParameter';
import ParameterDictionary from '../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import Layout from '../../../../../../../../@Api/Layout/Layout';
import ParameterAssignment from '../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import { useInitializedParameterAssignments } from '../../../../../../../../@Api/Automation/Api/useInitializedParameterAssignments';
import { useMemoizedArray } from '../../../../../../../../@Util/Array/useMemoizedArray';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { DesktopHeader } from './Header/DesktopHeader';
import { TabletHeader } from './Header/TabletHeader';
import { MobileHeader } from './Header/MobileHeader';
import { DesktopTotals } from './Totals/DesktopTotals';
import { TabletTotals } from './Totals/TabletTotals';
import { MobileTotals } from './Totals/MobileTotals';
import useIsMobile from '../../../../../../../../@Util/Responsiveness/useIsMobile';
import { ResponsiveLayout } from '../../../../../../../../@Util/Responsiveness/ResponsiveLayout';
import { MobileProductLine } from './ProductLine/MobileProductLine';
import { TabletProductLine } from './ProductLine/TabletProductLine';
import { DesktopProductLine } from './ProductLine/DesktopProductLine';

export interface ProductLinesTableProps
{
    entity: Entity;
    lines: Entity[];
    onSelectProductLine?: (entity: Entity, selected: boolean) => void;
    onSelectAllProductLines?: (lines: Entity[], selected: boolean) => void;
    selectedProductLines?: Set<Entity>;
    disabled?: boolean;
    showMilestone?: boolean;
    isStrikethrough?: (line: Entity) => boolean;
    noScroll?: boolean;
    discountHidden?: boolean;
    autoCommit?: boolean;
    hideTotals?: boolean;
    loading?: boolean;
    commitContext?: CommitContext;
}

export const ProductLinesTable: React.FC<ProductLinesTableProps> = observer(
    props =>
    {
        const types = useTypes();
        const isVatIncluded =
            useComputed(
                () =>
                    props.entity.getObjectValueByField(types.Activity.Field.IsVatIncluded),
                [
                    props.entity,
                    types
                ]);
        const currency = useEntityValue<string | undefined>(props.entity, types.Activity.Field.Currency);
        const droppableId = useMemo(() => uuid(), []);
        const [ sortIndexByProductLine ] = useState(() => observable.map<string, number>());
        const sortedProductLines =
            useComputed(
                () =>
                    deduplicateArray(
                        props.lines.slice(),
                        line => line.uuid
                    )
                        .sort(
                            createNumberComparator(
                                d =>
                                    sortIndexByProductLine.has(d.uuid)
                                        ?
                                        sortIndexByProductLine.get(d.uuid)
                                        :
                                        d.getObjectValueByField(
                                            types.Entity.Field.SortIndex,
                                            props.commitContext
                                        )
                            )
                        ),
                [
                    props.lines,
                    sortIndexByProductLine,
                    types,
                    props.commitContext,
                ]);

        useOnDragEnd(
            useCallback(
                result =>
                {
                    if (result.destination?.droppableId === droppableId)
                    {
                        runInAction(
                            () =>
                            {
                                const field =
                                    sortedProductLines.find(
                                        line =>
                                            line.uuid === result.draggableId
                                    );

                                const lines =
                                    sortedProductLines.slice()
                                        .filter(
                                            checkField =>
                                                checkField !== field);

                                lines.splice(
                                    result.destination.index,
                                    0,
                                    field);

                                const commitBuilder = new CommitBuilder();

                                lines.forEach(
                                    (line, idx) =>
                                    {
                                        commitBuilder.setObjectValueInEntity(
                                            line,
                                            types.Entity.Field.SortIndex,
                                            idx
                                        );

                                        runInAction(
                                            () =>
                                                sortIndexByProductLine.set(line.uuid, idx));

                                    });

                                commitBuilder.commit({
                                    isForced: true
                                }).then(
                                    () =>
                                        runInAction(
                                            () =>
                                                lines.forEach(
                                                    line =>
                                                        sortIndexByProductLine.delete(line.uuid)
                                                )
                                        )
                                );
                            });
                    }
                },
                [
                    droppableId,
                    sortedProductLines,
                    types,
                    sortIndexByProductLine
                ]));
        const [areWorkorderPricesHidden] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.WorkOrder.HidePrices
            );
        const hidePrices = useMemo(
            () =>
                props.entity.entityType.isA(types.Activity.WorkOrder.Type)
                && areWorkorderPricesHidden,
            [
                types,
                props.entity,
                areWorkorderPricesHidden
            ]
        );
        const [ specificationLayoutDescriptor, setSpecificationLayoutDescriptor ] =
            useSetting(
                SettingSource.Organization,
                Setting.Metadata.ProductLine.SpecificationLayout,
                true
            );
        const setSpecificationLayout =
            useCallback(
                (layout?: Layout) =>
                    setSpecificationLayoutDescriptor(layout?.toDescriptor()),
                [setSpecificationLayoutDescriptor]
            );
        const specificationLayoutEntityParameter =
            useMemo(
                () =>
                    getEntityLayoutParameter(types.ProductLine.Type),
                [
                    types
                ]
            );
        const specificationLayoutParameters =
            useMemo(
                () =>
                    new ParameterDictionary([
                        specificationLayoutEntityParameter,
                    ]),
                [specificationLayoutEntityParameter]
            );
        const stableSortedProductLines =
            useMemoizedArray(
                sortedProductLines,
                (line1, line2) =>
                    line1.uuid === line2.uuid,
                []
            );
        const [ specificationLayout, isLoadingSpecificationLayout ] =
            useAsyncResult<Layout | undefined>(
                async () =>
                {
                    if (specificationLayoutDescriptor && stableSortedProductLines.length > 0)
                    {
                        return getAndInitializeLayoutFromDescriptor(
                            specificationLayoutDescriptor,
                            new LayoutDependencyContext(specificationLayoutParameters)
                        );
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    stableSortedProductLines,
                    specificationLayoutDescriptor,
                    specificationLayoutParameters,
                ]
            );
        const specificationLayoutParameterAssignments =
            useMemo(
                () =>
                    stableSortedProductLines.map(
                        line =>
                            new ParameterAssignment()
                                .setValue(
                                    specificationLayoutEntityParameter,
                                    new EntityValue(line)
                                )
                    ),
                [
                    stableSortedProductLines,
                    specificationLayoutEntityParameter,
                ]
            );
        const specificationLayoutDependencies =
            useMemo(
                () =>
                    specificationLayout?.getDependencies() ?? [],
                [
                    specificationLayout
                ]
            );
        const [ , isInitializingSpecificationLayoutParameterAssignments ] =
            useInitializedParameterAssignments(
                specificationLayoutParameterAssignments,
                specificationLayoutDependencies
            );

        const isMobile = useIsMobile();

        const isSubscription =
            useMemo(
                () =>
                    props.entity.entityType.isA(types.Activity.Subscription.Type),
                [
                    props.entity,
                    types
                ]);

        return <div
            className={classNames(styles.root, props.noScroll && styles.noScroll)}
        >
            <Table
                className={classNames(!isMobile && styles.table, !isMobile && props.showMilestone && styles.showMilestone)}
            >
                <ResponsiveLayout
                    mobile={
                        <MobileHeader
                            lines={props.lines}
                            hidePrices={hidePrices}
                            showMilestone={props.showMilestone}
                            onSelectProductLine={props.onSelectProductLine}
                            onSelectAllProductLines={props.onSelectAllProductLines}
                            selectedProductLines={props.selectedProductLines}
                            currency={currency}
                        />
                    }
                    tablet={
                        <TabletHeader
                            lines={props.lines}
                            hidePrices={hidePrices}
                            showMilestone={props.showMilestone}
                            onSelectProductLine={props.onSelectProductLine}
                            onSelectAllProductLines={props.onSelectAllProductLines}
                            selectedProductLines={props.selectedProductLines}
                            currency={currency}
                        />
                    }
                    desktop={
                        <DesktopHeader
                            lines={props.lines}
                            hidePrices={hidePrices}
                            showMilestone={props.showMilestone}
                            onSelectProductLine={props.onSelectProductLine}
                            onSelectAllProductLines={props.onSelectAllProductLines}
                            selectedProductLines={props.selectedProductLines}
                            currency={currency}
                        />
                    }
                />
                <Droppable
                    droppableId={droppableId}
                    isDropDisabled={props.disabled}
                >
                    {
                        (droppableProvided, ) =>
                            <TableBody
                                ref={(ref: HTMLElement) => {
                                    droppableProvided.innerRef(ref);
                                }}
                                {...droppableProvided.droppableProps}
                            >
                                {
                                    sortedProductLines.map(
                                        (line, idx) =>
                                        {
                                            const isBilled =
                                                line.getObjectValueByField(
                                                    types.ProductLine.Field.IsBilled
                                                );

                                            const isProductlineDisabled =
                                                props.disabled || (isBilled && !isSubscription);

                                            return <ResponsiveLayout
                                                mobile={
                                                    <MobileProductLine
                                                        key={`${line.uuid}.${isVatIncluded ? 'incl' : 'exl'}`}
                                                        entity={line}
                                                        onSelected={props.onSelectProductLine}
                                                        isSelected={props.selectedProductLines && props.selectedProductLines.has(line)}
                                                        isVatIncluded={isVatIncluded}
                                                        hidePrices={hidePrices}
                                                        disabled={isProductlineDisabled}
                                                        showMilestone={props.showMilestone}
                                                        idx={idx}
                                                        isStrikethroughed={props.isStrikethrough}
                                                        autoCommit={props.autoCommit}
                                                        loading={props.loading}
                                                        commitContext={props.commitContext}
                                                        specificationLayout={
                                                            isInitializingSpecificationLayoutParameterAssignments
                                                                ? undefined
                                                                : specificationLayout
                                                        }
                                                        specificationLayoutParameters={specificationLayoutParameters}
                                                        specificationLayoutParameterAssignment={specificationLayoutParameterAssignments[idx]}
                                                        onChangeSpecificationLayout={setSpecificationLayout}
                                                        isLoadingSpecificationLayout={
                                                            specificationLayoutDescriptor !== undefined
                                                            && (isLoadingSpecificationLayout || isInitializingSpecificationLayoutParameterAssignments)
                                                        }
                                                        currency={currency}
                                                    />
                                                }
                                                tablet={
                                                    <TabletProductLine
                                                        key={`${line.uuid}.${isVatIncluded ? 'incl' : 'exl'}`}
                                                        entity={line}
                                                        onSelected={props.onSelectProductLine}
                                                        isSelected={props.selectedProductLines && props.selectedProductLines.has(line)}
                                                        isVatIncluded={isVatIncluded}
                                                        hidePrices={hidePrices}
                                                        disabled={isProductlineDisabled}
                                                        showMilestone={props.showMilestone}
                                                        idx={idx}
                                                        isStrikethroughed={props.isStrikethrough}
                                                        autoCommit={props.autoCommit}
                                                        loading={props.loading}
                                                        commitContext={props.commitContext}
                                                        specificationLayout={
                                                            isInitializingSpecificationLayoutParameterAssignments
                                                                ? undefined
                                                                : specificationLayout
                                                        }
                                                        specificationLayoutParameters={specificationLayoutParameters}
                                                        specificationLayoutParameterAssignment={specificationLayoutParameterAssignments[idx]}
                                                        onChangeSpecificationLayout={setSpecificationLayout}
                                                        isLoadingSpecificationLayout={
                                                            specificationLayoutDescriptor !== undefined
                                                            && (isLoadingSpecificationLayout || isInitializingSpecificationLayoutParameterAssignments)
                                                        }
                                                        currency={currency}
                                                    />
                                                }
                                                desktop={
                                                    <DesktopProductLine
                                                        key={`${line.uuid}.${isVatIncluded ? 'incl' : 'exl'}`}
                                                        entity={line}
                                                        onSelected={props.onSelectProductLine}
                                                        isSelected={props.selectedProductLines && props.selectedProductLines.has(line)}
                                                        isVatIncluded={isVatIncluded}
                                                        hidePrices={hidePrices}
                                                        disabled={isProductlineDisabled}
                                                        showMilestone={props.showMilestone}
                                                        idx={idx}
                                                        isStrikethroughed={props.isStrikethrough}
                                                        autoCommit={props.autoCommit}
                                                        loading={props.loading}
                                                        commitContext={props.commitContext}
                                                        specificationLayout={
                                                            isInitializingSpecificationLayoutParameterAssignments
                                                                ? undefined
                                                                : specificationLayout
                                                        }
                                                        specificationLayoutParameters={specificationLayoutParameters}
                                                        specificationLayoutParameterAssignment={specificationLayoutParameterAssignments[idx]}
                                                        onChangeSpecificationLayout={setSpecificationLayout}
                                                        isLoadingSpecificationLayout={
                                                            specificationLayoutDescriptor !== undefined
                                                            && (isLoadingSpecificationLayout || isInitializingSpecificationLayoutParameterAssignments)
                                                        }
                                                        currency={currency}
                                                    />
                                                }
                                            />
                                        }
                                    )
                                }
                                {droppableProvided.placeholder}
                            </TableBody>
                    }
                </Droppable>
                <TableFooter>
                    {
                        !props.hideTotals && !hidePrices &&
                        <ResponsiveLayout
                            mobile={
                                <MobileTotals
                                    entity={props.entity}
                                    lines={props.lines}
                                    onSelect={props.onSelectProductLine}
                                    disabled={props.disabled}
                                    discountHidden={props.discountHidden}
                                    autoCommit={props.autoCommit}
                                    commitContext={props.commitContext}
                                    currency={currency}
                                />
                            }
                            tablet={
                                <TabletTotals
                                    entity={props.entity}
                                    lines={props.lines}
                                    onSelect={props.onSelectProductLine}
                                    disabled={props.disabled}
                                    discountHidden={props.discountHidden}
                                    autoCommit={props.autoCommit}
                                    commitContext={props.commitContext}
                                    currency={currency}
                                />
                            }
                            desktop={
                                <DesktopTotals
                                    entity={props.entity}
                                    lines={props.lines}
                                    onSelect={props.onSelectProductLine}
                                    disabled={props.disabled}
                                    discountHidden={props.discountHidden}
                                    autoCommit={props.autoCommit}
                                    commitContext={props.commitContext}
                                    currency={currency}
                                />
                            }
                        />
                    }
                </TableFooter>
            </Table>
        </div>
    }
);