import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DialogTitle from '../../../../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogActions from '../../../../../../@Future/Component/Generic/Dialog/Actions/DialogActions';
import Dialog from '../../../../../../@Future/Component/Generic/Dialog/Dialog';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../Type/Api/useTypes';
import DialogContent from '../../../../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import Input from '../../../Input/Input';
import useFreshTransactionalEntity from '../../../../../../@Api/Entity/Bespoke/useFreshTransactionalEntity';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';

export interface LinkedActivityDialogProps
{
    entity: Entity;
    saveCallback: (success: boolean) => void;
    closeCallback: () => void;
}

const LinkedActivityDialog: React.FC<LinkedActivityDialogProps> =
    props =>
    {
        const { saveCallback, closeCallback } = props;

        const types = useTypes();

        // Create transactional copy of the passed entity so that
        // we can commit only when the Apply button is pressed.
        const transactionalEntity = useFreshTransactionalEntity(props.entity);

        const [ isTouched, setTouched ] = useState(false);

        const save =
            useCallback(
                () =>
                {
                    setTouched(true);

                    return transactionalEntity.checkAndDoCommit(false, true)
                        .then(
                            () =>
                                saveCallback(true));
                },
                [
                    setTouched,
                    transactionalEntity,
                    saveCallback
                ]);

        return <Dialog
            onClose={closeCallback}
            open={true}
        >
            <DialogTitle>
                <LocalizedText
                    code="Activity.ConnectToActivity"
                    value="Koppelen aan activiteit..."
                />
            </DialogTitle>
            <DialogContent>
                <InputGroup>
                    <Input
                        entity={transactionalEntity}
                        field={types.Activity.RelationshipDefinition.LinkedActivities}
                        parent
                        autoFocus
                        doAutocommit={false}
                        touched={isTouched}
                        labelPosition="none"
                        underline="always"
                    />
                </InputGroup>
            </DialogContent>
            <DialogActions>
                <SaveButton
                    onClick={save}
                />
            </DialogActions>
        </Dialog>;
    };

export default observer(LinkedActivityDialog);
