import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import TextEditor from './Editor/TimeTextEditor';
import { TextSpecification } from './Specification/TextSpecification';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import { DataObjectBespokeEditorStore } from '../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { DataObjectEditorStore } from '../../Editor/Value/Editor/DataObjectEditorStore';
import { TimeTextEditorStore } from './Editor/TimeTextEditorStore';
import { DataObjectBespokeViewerStore } from '../../Viewer/DataObjectBespokeViewerStore';
import { TimeViewerStore } from './View/TimeViewerStore';
import { DataObjectViewerStore } from '../../Viewer/DataObjectViewerStore';
import { ComputationTypeStore } from '../../../Computation/ComputationTypeStore';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import Editor from './Editor/Editor';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { getTextComparatorOverloads } from '../Text/Comparator/getTextComparatorOverloads';
import { TimeView } from './View/TimeView';

export class TimeType implements DataObjectType<any>
{
    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Time';
    }

    name()
    {
        return localizeText('Generic.Time', 'Tijd');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            },
            {
                comparator: Comparator.Contains
            },
            {
                comparator: Comparator.NotContains
            },
            {
                comparator: Comparator.StartsWith
            },
            {
                comparator: Comparator.EndsWith
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return TimeView;
    }

    viewStore(viewerStore: DataObjectViewerStore): DataObjectBespokeViewerStore
    {
        return new TimeViewerStore(viewerStore);
    }

    hooksEditor()
    {
        return Editor;
    }

    editor(): DataObjectEditorType
    {
        return TextEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new TimeTextEditorStore(editorStore);
    }

    hasLargeEditor(specification: DataObjectSpecification): boolean
    {
        return false;
    }

    specification(): DataObjectSpecificationType
    {
        return TextSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: string): boolean
    {
        return false;
    }

    getDataFromValue(value: string, specification: DataObjectSpecification): DataDescriptor
    {
        const data = new DataDescriptor();
        data.text = value;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): string
    {
        return data.text;
    }

    valueId(value: any): any
    {
        return value;
    }

    getString(value: any,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return value;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return getTextComparatorOverloads(this);
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        if (dataObject.specification.isRequired)
        {
            return dataObject.getValue() !== undefined && dataObject.getValue() !== null && dataObject.getValue() !== '';
        }

        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
