import Predicate from '../Function/Computation/Predicate/Predicate';
import NotPredicate from '../Function/Computation/Predicate/NotPredicate';
import CompositePredicate from '../Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../Function/Computation/Predicate/ComparisonPredicate';
import AutomationDependencyContext from '../AutomationDependencyContext';
import DynamicPredicate from '../Function/Computation/Predicate/DynamicPredicate';
import InstanceOfPredicate from '../Function/Computation/Predicate/InstanceOfPredicate';

export default async function getPredicateFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<Predicate>
{
    switch (descriptor.type)
    {
        case 'Comparison':
            return ComparisonPredicate.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Composite':
            return CompositePredicate.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Not':
            return NotPredicate.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'InstanceOf':
            return InstanceOfPredicate.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Dynamic':
            return DynamicPredicate.fromDescriptor(
                descriptor,
                dependencyContext
            );
    }
}
