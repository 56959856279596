import React from 'react';
import { observer } from 'mobx-react-lite';
import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import { makeStyles } from '@material-ui/core';
import { textSecondaryColor } from '../../../../../../../../../../@Resource/Theme/Theme';

export interface ProductLineStockLevelProps
{
    productLine: ProductLineEditorInterface;
}

const useStyles = makeStyles({
    underline:
        {
            fontSize: 12,
            color: textSecondaryColor
        }
});

export const ProductLineStockLevel: React.FC<ProductLineStockLevelProps> = observer(
    ({
         productLine
     }) =>
    {
        const classes = useStyles();

        return productLine.stockLevel
            ?
                <ViewGroupItem
                    className={classes.underline}
                >
                    <LocalizedText
                        code="Products.StockLevel"
                        value="${stockLevel} op voorraad"
                        stockLevel={productLine.stockLevel}
                    />
                </ViewGroupItem>
            :
                null;
    })