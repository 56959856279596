import React, { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { WidgetProps } from '../Widget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import styles from './File.module.scss';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import { buttonInset } from '../../../../../../@Resource/Theme/Theme';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import useExpanded from '../../../Dataset/Api/useExpanded';
import { runInAction } from 'mobx';
import FileWidget from '../../Model/Widget/File/FileWidget';
import useTypes from '../../../Type/Api/useTypes';
import { useComputed } from 'mobx-react-lite';
import { FileValue } from '../../../../DataObject/Type/File/FileValue';
import FileDropZone from '../../../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import DashboardContext from '../../Context/DashboardContext';
import { createTransactionalModel, getModel } from '../../../../../../@Util/TransactionalModelV2/index';
import FileViewer from '../../../../../Generic/FileViewer/FileViewer';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import DownloadIconButton from '../../../../../../@Future/Component/Generic/Button/Variant/DownloadIconButton/DownloadIconButton';
import { SupportedPreviewImageMimeTypes } from '../../../../DataObject/Type/File/FileUtils';
import { downloadApiFile } from '../../../../../../@Service/ApiClient/Hooks/downloadApiFile';

export interface FileProps extends WidgetProps<FileWidget>
{

}

const File: React.FC<FileProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const [ isExpanded, toggleExpansion ] = useExpanded(`Widget.${props.widget.id}`);
        const dashboard = useContext(DashboardContext);
        const [ isLoading, setLoading ] = useState(false);

        const save =
            useCallback(
                (files: File[]) =>
                    runInAction(
                        () =>
                        {
                            if (files.length > 0)
                            {
                                setLoading(true);

                                const attachment =
                                    createTransactionalModel(
                                        new Entity(types.Attachment.Type)
                                            .initialize(entityTypeStore));

                                attachment.updateRelationship(
                                    true,
                                    types.Entity.RelationshipDefinition.Attachments,
                                    createTransactionalModel(dashboard.entity));

                                attachment.setValueByField(
                                    types.Attachment.Field.File,
                                    FileValue.fromFile(files.find(() => true)));

                                attachment.updateRelationship(
                                    true,
                                    types.Entity.RelationshipDefinition.Attachments,
                                    dashboard.entity);

                                return attachment.checkAndDoCommit()
                                    .then(
                                        attachment =>
                                            runInAction(
                                                () =>
                                                {
                                                    props.widget.attachmentId = attachment.uuid;
                                                    props.widget.attachment = getModel(attachment);
                                                }))
                                    .then(
                                        () =>
                                            setLoading(false));
                            }
                        }),
                [
                    props.widget,
                    entityTypeStore,
                    dashboard,
                    setLoading,
                    types
                ]);

        const entity = props.widget.attachment;

        const fileValue =
            useComputed(
                () =>
                    entity && entity.getObjectValueByField(types.Attachment.Field.File) as FileValue,
                [
                    entity,
                    types
                ]);

        const fileUrl =
            useComputed(
                () =>
                    entity &&
                        entityTypeStore.getFileUrl(
                            entity.getValueByField(
                                types.Attachment.Field.File)),
                [
                    entityTypeStore,
                    entity,
                    types
                ]);

        const download =
            useCallback(
                () =>
                    downloadApiFile(fileUrl),
                [
                    fileUrl
                ]);

        const filePreviewUrl =
            useComputed(
                () =>
                {
                    if (entity
                        && fileValue
                        && fileValue.mime
                        && (SupportedPreviewImageMimeTypes.has(fileValue.mime.toLowerCase())
                            || fileValue.mime.startsWith('application/pdf')))
                    {
                        return entityTypeStore.getFileUrl(entity.getValueByField(types.Attachment.Field.File));
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity,
                    fileValue,
                    entityTypeStore
                ]
            );
        const filePreviewType =
            useComputed(
                () =>
                    SupportedPreviewImageMimeTypes.has(fileValue?.mime?.toLowerCase())
                        ? 'image'
                        : 'pdf',
                [
                    fileValue
                ]
            );

        return <Card
            classes={{
                root: styles.root
            }}
        >
            <ExpansionGroup>
                <ExpansionPanel
                    id={`Widget.${props.widget.id}`}
                    summary={
                        <DragHandle>
                            <Header
                                title={props.widget.attachmentId ? (entity?.name || '') : '...'}
                                large
                                inset
                            >
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={buttonInset}
                                    alignment="center"
                                >
                                    {
                                        props.onAdd &&
                                            <ViewGroupItem>
                                                <IconButton
                                                    icon="add"
                                                    onClick={props.onAdd}
                                                    tooltip="Toevoegen aan dashboard"
                                                />
                                            </ViewGroupItem>
                                    }
                                    {
                                        entity &&
                                            <ViewGroupItem>
                                                <DownloadIconButton
                                                    onClick={download}
                                                />
                                            </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        <MenuButton>
                                            <Menu>
                                                <DeleteItem
                                                    onClick={props.onDelete}
                                                />
                                            </Menu>
                                        </MenuButton>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Header>
                        </DragHandle>
                    }
                    expansion={
                        <CardInset
                            top={false}
                        >
                            {
                                (isLoading || (props.widget.attachmentId && !entity)) &&
                                    <Centered
                                        horizontal
                                    >
                                        <Loader />
                                    </Centered>
                            }
                            {
                                !isLoading && !props.widget.attachmentId &&
                                    <FileDropZone
                                        onDrop={save}
                                    />
                            }
                            {
                                filePreviewUrl &&
                                    <FileViewer
                                        url={filePreviewUrl}
                                        type={filePreviewType}
                                    />
                            }
                            {
                                fileValue && !filePreviewUrl &&
                                    <Centered
                                        horizontal
                                    >
                                        Geen voorbeeldweergave beschikbaar.
                                    </Centered>
                            }
                        </CardInset>
                    }
                    expanded={isExpanded}
                    onExpand={toggleExpansion}
                    onCollapse={toggleExpansion}
                />
            </ExpansionGroup>
        </Card>;
    };

export default observer(File);
