import React, { ReactElement, useContext, useMemo } from 'react';
import { createDateComparator } from '../../../../@Component/Generic/List/V2/Comparator/DateComparator';
import { DateItemProps } from './Item/DateItem';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import { classNames } from '../../../Util/Class/classNames';
import styles from './DateList.module.scss';
import LocalizerContext from '../../../../@Service/Localization/LocalizerContext';
import moment from 'moment';
import { createInverseComparator } from '../../../../@Component/Generic/List/V2/Comparator/InverseComparator';
import { observer } from 'mobx-react-lite';
import Chip from '../Chip/Chip';
import useDividerGlue from '../ViewGroup/Api/useDividerGlue';

export interface DateListProps
{
    divided?: boolean;
    ascending?: boolean;
}

const DateList: React.FC<DateListProps> =
    props =>
    {
        const dateComparator =
            useMemo(
                () =>
                    props.ascending
                        ?
                            createDateComparator<React.ReactElement<DateItemProps>>(
                                node =>
                                    node &&
                                        (node as ReactElement).props.date)
                        :
                            createInverseComparator(
                                createDateComparator<React.ReactElement<DateItemProps>>(
                                    node =>
                                        node &&
                                            (node as ReactElement).props.date)),
                [
                    props.ascending
                ]);

        const children = React.Children.toArray(props.children);
        const items =
            useMemo(
                () =>
                    children
                        .filter(item => item)
                        .sort(dateComparator) as ReactElement<DateItemProps>[],
                [
                    children,
                    dateComparator
                ]);
        const localizer = useContext(LocalizerContext);
        const dividerGlue = useDividerGlue();

        if (items.length > 0)
        {
            return <ViewGroup
                orientation="vertical"
                spacing={15}
                glue={props.divided && dividerGlue}
            >
                {
                    items.map(
                        (item, idx) =>
                            <ViewGroupItem
                                key={item.key}
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={10}
                                >
                                    {
                                        (idx === 0 || (idx > 0 && !moment(items[idx - 1].props.date).isSame(item.props.date, 'date'))) &&
                                            <ViewGroupItem
                                                className={classNames(styles.label, idx === 0 && styles.first)}
                                            >
                                                <Chip
                                                    label={localizer.formatDateFromNow(moment(item.props.date))}
                                                    backgroundColor="rgba(0, 0, 0, 0.4)"
                                                    textColor="rgba(0, 0, 0, 0.4)"
                                                    borderColor="rgba(0, 0, 0, 0.3)"
                                                />
                                            </ViewGroupItem>
                                    }
                                    <ViewGroupItem>
                                        {item}
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>)
                }
            </ViewGroup>;
        }
        else
        {
            return null;
        }
    };

export default observer(DateList);
