import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import FormattedDateComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/FormattedDateComputation';
import TextEditor from '../../../../../../../../../../@Future/Component/Generic/Input/TextEditor/TextEditor';

export interface FormattedDateComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, FormattedDateComputation>
{

}

const FormattedDateComputationEditor: React.FC<FormattedDateComputationEditorProps> =
    props =>
    {
        const setFormat =
            useCallback(
                (format?: string) =>
                    runInAction(
                        () =>
                            props.value.format = format),
                [
                    props.value
                ]);

        const setDate =
            useCallback(
                (date?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.date = date),
                [
                    props.value
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                Datum:
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationEditor
                    context={props.context}
                    value={props.value.date}
                    onChange={setDate}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                Format:
            </ViewGroupItem>
            <ViewGroupItem>
                <TextEditor
                    value={props.value.format}
                    onChange={setFormat}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(FormattedDateComputationEditor);
