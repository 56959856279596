import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import EntityValueType from '../../Automation/Value/Type/EntityValueType';
import Parameter from '../../Automation/Parameter/Parameter';
import LayoutDependencyContext from '../LayoutDependencyContext';
import resolveInputFromDescriptor from '../../../@Component/Domain/Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import RelationshipInput from '../../../@Component/Domain/Multiplayer/Model/Input/RelationshipInput';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import localizeText from '../../Localization/localizeText';

export default class OptionalRelatedEntityLayout extends Layout
{
    // ------------------------- Properties -------------------------

    @observable.ref parameter: Parameter<EntityValueType>;
    @observable.ref field: RelationshipInput;
    @observable.ref relatedEntityParameter: Parameter<EntityValueType>;
    @observable.ref layout: Layout;

    // ------------------------ Constructor -------------------------

    constructor(parameter: Parameter<EntityValueType>,
                field: RelationshipInput,
                relatedEntityParameter: Parameter<EntityValueType>,
                layout: Layout)
    {
        super();

        this.parameter = parameter;
        this.field = field;
        this.relatedEntityParameter = relatedEntityParameter;
        this.layout = layout;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.OptionalRelatedEntity', 'Optioneel gerelateerd record');
    }

    static getRelatedEntityParameter(id: string,
                                     parameter: Parameter<EntityValueType>,
                                     field: RelationshipInput): Parameter<EntityValueType>
    {
        return new Parameter(
            id,
            new EntityValueType(field.relationshipDefinition.getEntityType(field.isParent)),
            true,
            `${field.getName()} van ${parameter.getName()}`);
    }

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        const layoutDependencies = this.layout.getDependencies();

        return [
            ...layoutDependencies
                .filter(
                    dependency =>
                        dependency.parameter !== this.relatedEntityParameter),
            new Dependency(
                this.parameter,
                this.field.toFieldPath()),
            ...layoutDependencies
                .filter(
                    dependency =>
                        dependency.parameter === this.relatedEntityParameter)
                .map(
                    dependency =>
                        new Dependency(
                            this.parameter,
                            EntityPath.fromEntityType(this.parameter.type.type)
                                .joinTo(
                                    this.field.relationshipDefinition,
                                    this.field.isParent)
                                .join(dependency.fieldPath.path)
                                .field(dependency.fieldPath.field)))
        ];
    }

    toDescriptor()
    {
        return {
            type: 'OptionalRelatedEntity',
            parameterId: this.parameter.id,
            relatedEntityParameterId: this.relatedEntityParameter.id,
            field: this.field.toDescriptor(),
            layout: this.layout.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        const parameter = dependencyContext.parameterDictionary.getParameterById(descriptor.parameterId);
        const field = resolveInputFromDescriptor(descriptor.field) as RelationshipInput;
        const optionalRelatedEntityParameter =
            OptionalRelatedEntityLayout.getRelatedEntityParameter(
                descriptor.relatedEntityParameterId,
                parameter,
                field);

        const nextDependencyContext  =
            dependencyContext.withParameterDictionary(
                dependencyContext.parameterDictionary
                    .getNewDictionaryWithParameter(
                        optionalRelatedEntityParameter
                    )
            );

        const layout =
            await getLayoutFromDescriptor(
                descriptor.layout,
                nextDependencyContext);

        return new OptionalRelatedEntityLayout(
            parameter,
            field,
            optionalRelatedEntityParameter,
            layout);
    }

    // ----------------------- Private logic ------------------------
}
