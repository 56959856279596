import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationInText from '../../../../../../../../../../../@Api/Automation/Function/Computation/ComputationInText';
import FunctionContext from '../../../../../../../../../../../@Api/Automation/Function/FunctionContext';
import styles from './ComputationInTextEditor.module.scss';

export interface ComputationInTextEditorProps
{
    context: FunctionContext;
    computationInText: ComputationInText;
    opened?: boolean;
    onOpen: (computation: ComputationInText) => void;
}

const ComputationInTextEditor: React.FC<ComputationInTextEditorProps> =
    props =>
    {
        const open =
            useCallback(
                () =>
                    props.onOpen(props.computationInText),
                [
                    props.onOpen,
                    props.computationInText
                ]);

        return <span
            className={styles.root}
            onClick={open}
        >
            {props.computationInText.computation.getName()}
        </span>;
    };

export default observer(ComputationInTextEditor);
