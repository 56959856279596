import { ApiClient } from '../../../@Service/ApiClient/ApiClient';
import { ApiRequest, Method } from '../../../@Service/ApiClient/Model/ApiRequest';
import { fromJson } from '../../../@Util/Serialization/Serialization';
import { ExternalPicture } from '../../Model/Implementation/ExternalPicture';

export class ExternalPictureController
{
    apiClient: ApiClient;
    constructor(apiClient: ApiClient)
    {
            this.apiClient = apiClient;
    };
    searchPictures(query: string): Promise<ExternalPicture[]>
    {
            return new Promise<ExternalPicture[]>(
            (resolve, reject) =>
            {
                this.apiClient.request(new ApiRequest<any>('/user/entity/picture/search', Method.Get, { query: query }))
                    .then(result =>
                    {
                        resolve((fromJson(result, ExternalPicture as any) as ExternalPicture[]));
                    })
                    .catch(reason =>
                    {
                        reject(reason);
                    });
                });
    };
}
