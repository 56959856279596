import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import MenuButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import constructEntity from '../../../../../../../../@Api/Entity/constructEntity';
import { createTransactionalModel } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Checkbox from '../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface PageTabsProps
{
    entity: Entity;
    onChangeTab: (value: any) => void;
    isInEditMode: boolean;
    onChangeEditMode: (isInEditMode: boolean) => void;
}

const PageTabManager: React.FC<PageTabsProps> =
    props =>
    {
        const { entity, onChangeTab, isInEditMode, onChangeEditMode } = props;
        const currentUserStore = useContext(CurrentUserContext);

        const types = useTypes();
        const [ isOpen, open, close ] = useSwitch(false);
        const constructPage =
            useCallback(
                () =>
                {
                    const page =
                        createTransactionalModel(
                            new Entity(types.Page.Type)
                                .initialize());

                    page.updateRelationship(
                        true,
                        types.EntityType.RelationshipDefinition.Pages,
                        createTransactionalModel(entity.entityType.entity));

                    constructEntity(
                        page.entityType,
                        undefined,
                        page,
                        close,
                        undefined,
                        entity =>
                            onChangeTab(`Page:${entity.uuid}`));

                    close();
                },
                [
                    types,
                    entity,
                    onChangeTab,
                    close
                ]);
        const dividerGlue = useDividerGlue();

        if (currentUserStore.isSupport)
        {
            return <MenuButton
                open={isOpen}
                onOpen={open}
                onClose={close}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={0}
                    glue={dividerGlue}
                >
                    <ViewGroupItem>
                        <Menu>
                            <Item
                                name={`+ ${types.Page.Type.getName()}`}
                                onClick={constructPage}
                            />
                        </Menu>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <CardInset
                            vertical={false}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    <Checkbox
                                        checked={isInEditMode}
                                        onToggle={onChangeEditMode}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="Generic.EditMode"
                                        value="Beheermodus"
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </CardInset>
                    </ViewGroupItem>
                </ViewGroup>
            </MenuButton>;
        }
        else
        {
            return null;
        }
    };

export default observer(PageTabManager);
