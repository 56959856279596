import { Entity } from '../../../../Model/Implementation/Entity';
import useRelatedEntity from '../../../Hooks/useRelatedEntity';
import usePipelineRelationshipDefinition from './usePipelineRelationshipDefinition';

export default function usePipeline(entity: Entity)
{
    const pipelineRelationshipDefinition = usePipelineRelationshipDefinition(entity?.entityType);

    return useRelatedEntity(
        entity,
        pipelineRelationshipDefinition,
        false);
}
