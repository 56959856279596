import { BespokeEntityType } from '../../../BespokeEntityType';
import { EntityTypeStore } from '../../../EntityTypeStore';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { CurrentUserStore } from '../../../../../User/CurrentUserStore';
import { EntityRelationshipDefinition } from '../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import copyProductLines from '../../../../../../../@Api/Entity/Bespoke/Activity/copyProductLines';
import { CommitContext } from '../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { updateRelationship } from '../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';

export class BespokeActivityWorkOrder extends BespokeEntityType
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                code: string = 'Activity.WorkOrder')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    onConstruct(entity: Entity,
                commitContext?: CommitContext): void
    {
        super.onConstruct(entity, commitContext);

        if (!entity.hasRelationshipsByDefinition(
            false,
            this.types.Activity.WorkOrder.RelationshipDefinition.Employee,
            commitContext))
        {
            updateRelationship(
                entity,
                false,
                this.types.Activity.WorkOrder.RelationshipDefinition.Employee,
                loadModuleDirectly(CurrentUserStore).employeeEntity,
                commitContext
            );
        }
    }

    async onRelate(
        entity: Entity,
        relationshipDefinition: EntityRelationshipDefinition,
        isParent: boolean,
        relatedEntity?: Entity,
        commitContext?: CommitContext
    )
    {
        await super.onRelate(
            entity,
            relationshipDefinition,
            isParent,
            relatedEntity,
            commitContext
        );

        // In case of linking a new work order to an activity, then:
        // - copy the subject
        // - copy the product lines
        if (entity.isNew()
            && isParent
            && relationshipDefinition === this.types.Activity.RelationshipDefinition.LinkedActivities
            && relatedEntity)
        {
            // Copy product lines (non-billed ones)
            await copyProductLines(
                relatedEntity,
                entity,
                line =>
                    !line.hasValueForField(this.types.ProductLine.Field.RepeatInterval),
                commitContext
            );

            // Copy fields
            [
                this.types.Activity.Field.Subject,
                this.types.Activity.Field.DiscountPercentage,
                this.types.Activity.Field.IsVatIncluded,
                this.types.Activity.Field.Currency,
            ].forEach(
                field =>
                    setValueByFieldInEntity(
                        entity,
                        field,
                        relatedEntity.getObjectValueByField(field),
                        commitContext
                    )
            );
        }
    }

    allowWorkflowInteraction(): boolean
    {
        return false;
    }

    // ----------------------- Private logic ------------------------
}
