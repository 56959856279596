import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import FunctionContext from '../FunctionContext';
import Value from '../../Value/Value';
import Layout from '../../../Layout/Layout';
import EmptyValue from '../../Value/EmptyValue';
import getLayoutFromDescriptor from '../../../Layout/Api/getLayoutFromDescriptor';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import LayoutDependencyContext from '../../../Layout/LayoutDependencyContext';

export default class PdfComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref header: Layout;
    @observable.ref body: Layout;
    @observable.ref footer: Layout;
    @observable marginTop: number;
    @observable marginRight: number;
    @observable marginBottom: number;
    @observable marginLeft: number;
    @observable.ref fileName: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(header: Layout,
                body: Layout,
                footer: Layout,
                marginTop: number,
                marginRight: number,
                marginBottom: number,
                marginLeft: number,
                fileName: Computation<any, any>)
    {
        super();

        this.header = header;
        this.body = body;
        this.footer = footer;
        this.marginTop = marginTop;
        this.marginRight = marginRight;
        this.marginBottom = marginBottom;
        this.marginLeft = marginLeft;
        this.fileName = fileName;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('File'));
    }

    getName(): string
    {
        return 'Pdf';
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext)
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        return EmptyValue.instance;
    }

    validate(): Validation[]
    {
        return [
            ...this.header.validate(),
            ...this.body.validate(),
            ...this.footer.validate(),
            ...this.fileName.validate()
        ];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Pdf';
        descriptor.header = this.header.toDescriptor();
        descriptor.body = this.body.toDescriptor();
        descriptor.footer = this.footer.toDescriptor();
        descriptor.marginTop = this.marginTop;
        descriptor.marginRight = this.marginRight;
        descriptor.marginBottom = this.marginBottom;
        descriptor.marginLeft = this.marginLeft;
        descriptor.fileName = this.fileName.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.header.getDependencies(),
            ...this.body.getDependencies(),
            ...this.footer.getDependencies(),
            ...this.fileName.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const layoutDependencyContext = LayoutDependencyContext.fromAutomationDependencyContext(dependencyContext);

        return new PdfComputation(
            await getLayoutFromDescriptor(
                descriptor.header,
                layoutDependencyContext
            ),
            await getLayoutFromDescriptor(
                descriptor.body,
                layoutDependencyContext
            ),
            await getLayoutFromDescriptor(
                descriptor.footer,
                layoutDependencyContext
            ),
            descriptor.marginTop,
            descriptor.marginRight,
            descriptor.marginBottom,
            descriptor.marginLeft,
            await getComputationFromDescriptor(
                descriptor.fileName,
                dependencyContext
            )
        );
    }

    // ----------------------- Private logic ------------------------
}
