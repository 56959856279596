import { PublicEnvironmentContext } from './Context/PublicEnvironmentContext';
import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { observable } from 'mobx';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { RouterStore } from '../../../../../@Service/Router/RouterStore';
import { FeedbackStore } from '../Organization/Feedback/FeedbackStore';
import { SettingStore } from '../../../../Domain/Setting/SettingStore';

export class PublicEnvironmentStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('FeedbackStore') feedbackStore: FeedbackStore;
    @injectWithQualifier('RouterStore') routerStore: RouterStore;

    // ------------------------- Properties -------------------------

    @observable context: PublicEnvironmentContext;
    @observable.ref settingStore: SettingStore;

    // ------------------------ Constructor -------------------------

    constructor(moduleManager: ModuleManager)
    {
        super();

        this.context = new PublicEnvironmentContext(moduleManager);
        // this.routerStore = new RouterStore(
        //     [],
        //     [],
        //     [
        //         new Route(
        //             '/',
        //             true,
        //             () =>
        //                 import('./Login/LoginStore')
        //                     .then(
        //                         index =>
        //                             Promise.resolve(
        //                                 new index.LoginStore()))
        //                     .catch(catchImport)),
        //     ],
        //     '/');
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        return this.context.initialize();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
