import React from 'react';
import { observer } from 'mobx-react-lite';
import { EntityEvent } from '../../../../../@Api/Model/Implementation/EntityEvent';
import DateList from '../../../../../@Future/Component/Generic/DateList/DateList';
import DateItem from '../../../../../@Future/Component/Generic/DateList/Item/DateItem';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useGroupedEvents from '../Api/useGroupedEvents';
import Group from '../Group/Group';

export interface ListProps
{
    entity: Entity;
    events: EntityEvent[];
    showConstructor?: boolean
}

const List: React.FC<ListProps> =
    props =>
    {
        const groups =
            useGroupedEvents(
                props.entity,
                props.events);

        return <DateList>
            {/*{*/}
            {/*    props.showConstructor &&*/}
            {/*        <DateItem*/}
            {/*            date={today}*/}
            {/*        >*/}
            {/*            <Constructor*/}
            {/*                entity={props.entity}*/}
            {/*                white*/}
            {/*            />*/}
            {/*        </DateItem>*/}
            {/*}*/}
            {
                groups.map(
                    group =>
                        <DateItem
                            key={group.id}
                            date={new Date(group.date)}
                        >
                            <Group
                                entity={group.entity}
                                events={group.events}
                                rootEntity={props.entity}
                                user={group.user}
                            />
                        </DateItem>)
            }
        </DateList>;
    };

export default observer(List);
