import AutomationDependencyContext from '../../../../../../../@Api/Automation/AutomationDependencyContext';
import { isExpressionSpecification } from '../../../../../Expression/Api/isExpressionSpecification';
import getNewComputationFromExpression from '../../../../../Expression/Api/getNewComputationFromExpression';
import getComputationFromDescriptor from '../../../../../../../@Api/Automation/Api/getComputationFromDescriptor';

export async function getEmailSubjectComputationFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
)
{
    if (isExpressionSpecification(descriptor))
    {
        return getNewComputationFromExpression(
            descriptor,
            dependencyContext,
            false
        );
    }
    else
    {
        return getComputationFromDescriptor(
            descriptor,
            dependencyContext
        );
    }
}
