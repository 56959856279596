import { BaseStore, getOrCompute, PropType } from '../../../@Framework/Store/BaseStore';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { computed } from 'mobx';
import { CSSProperties } from 'react';

export interface ViewGroupItemProps
{
    id: PropType<ViewGroupItem, ViewGroupItemProps, string>;
    view: PropType<ViewGroupItem, ViewGroupItemProps, ViewComponent>;
    growRatio?: PropType<ViewGroupItem, ViewGroupItemProps, number>;
    spacing?: PropType<ViewGroupItem, ViewGroupItemProps, number>;
    style?: PropType<ViewGroupItem, ViewGroupItemProps, CSSProperties>;
    isHidden?: PropType<ViewGroupItem, ViewGroupItemProps, boolean>;
}

const defaultProps: Partial<ViewGroupItemProps> =
{
    spacing: 12
};

export class ViewGroupItem extends BaseStore<ViewGroupItemProps>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: ViewGroupItemProps)
    {
        super(props, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get id(): string
    {
        return getOrCompute(this, this.props.id, this.viewComponent && this.viewComponent.store && this.viewComponent.store.uuid);
    }

    @computed
    get viewComponent(): ViewComponent
    {
        return getOrCompute(this, this.props.view);
    }

    @computed
    get growRatio(): number
    {
        return getOrCompute(this, this.props.growRatio);
    }

    @computed
    get spacing(): number
    {
        return getOrCompute(this, this.props.spacing);
    }

    @computed
    get style(): CSSProperties
    {
        return getOrCompute(this, this.props.style);
    }

    @computed
    get isHidden(): boolean
    {
        return getOrCompute(this, this.props.isHidden);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

}
