import React, { useCallback, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityContext } from '../../../../../@Model/EntityContext';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import ExpansionPanel from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { v4 as uuid } from 'uuid';
import { CampaignProps } from '../Campaign';
import Input from '../../../../../Input/Input';
import ExpressionEditorContext from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import DocumentEditor, {
    DocumentDefinition, EntityImageBlockId, GutenbergBlockButtonId, GutenbergBlockExpressionId, GutenbergBlockHtmlId, GutenbergBlockImageId, GutenbergBlockTemplateId, GutenbergBlockVideoId, LayoutBlockId, OrganizationLogoBlockId,
} from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import useEntityValue from '../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import { setValueByFieldInEntity } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import { commitEntityWithContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import FontSelectbox from '../../../../../../../Generic/Font/FontSelectbox';
import { default as GenericInput } from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import InteractiveLayoutContext from '../../../../../../Layout/InteractiveLayoutContext';


export interface CampaignSelectionProps extends CampaignProps
{
    commitContext: CommitContext;
}

const Campaign: React.FC<CampaignSelectionProps> =
    ({
        entity,
        commitContext,
     }) =>
    {
        const types = useTypes();

        const onTemplateChangeCallback =
            useCallback(
                (definition: DocumentDefinition) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.Template,
                        definition,
                        commitContext
                    );

                    return commitEntityWithContext(
                        entity,
                        commitContext
                    );
                },
                [
                    entity,
                    types,
                    commitContext,
                ]);

        const rootType =
            useEntityValue(
                entity,
                types.Activity.Campaign.Field.RootType,
                types.Relationship.Organization.Type,
                commitContext
            );
        const relationshipPath =
            useComputed(
                () =>
                {
                    const relationshipPathDescriptor =
                        entity.getObjectValueByField(
                            types.Activity.Campaign.Field.RelationshipPath,
                            commitContext
                        );

                    if (relationshipPathDescriptor)
                    {
                        return EntityPath.construct(relationshipPathDescriptor);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    entity,
                    types,
                    commitContext,
                ]
            );

        const expressionContext =
            useMemo(
                () =>
                {
                    let entityContext =
                        new EntityContext(
                            [],
                            EntityPath.fromEntityType(types.Activity.Email.Type)
                        )
                            .setContext(
                                'Addressee',
                                new EntityContext([], EntityPath.fromEntityType(relationshipPath?.entityType ?? rootType))
                            );

                    if (relationshipPath
                        && relationshipPath.entityType.isA(types.Relationship.Type)
                        && relationshipPath.entityType !== rootType)
                    {
                        entityContext =
                            entityContext.setContext(
                                'Subject',
                                new EntityContext([], EntityPath.fromEntityType(rootType))
                            );
                    }

                    return {
                        entityContext,
                    };
                },
                [
                    types,
                    rootType,
                    relationshipPath,
                ]);

        const templateDefinition =
            useComputed(
                () =>
                {
                    let def = entity.getObjectValueByField(types.Activity.Campaign.Field.Template, commitContext);

                    if (!def)
                    {
                        def = {
                            type: 'gutenberg',
                            definition:
                                [
                                    {
                                        clientId: uuid(),
                                        name: 'pv/expression',
                                        isValid: true,
                                        attributes: {},
                                        innerBlocks: []
                                    }
                                ]
                        };
                    }

                    return def;
                },
                [
                    entity,
                    types,
                    commitContext
                ]);

        const blockTypes =
            useMemo(
                () => [
                    GutenbergBlockExpressionId,
                    GutenbergBlockImageId,
                    GutenbergBlockTemplateId,
                    GutenbergBlockVideoId,
                    GutenbergBlockButtonId,
                    OrganizationLogoBlockId,
                    EntityImageBlockId,
                    GutenbergBlockHtmlId,
                    LayoutBlockId,
                ],
                []);

        const setFont =
            useCallback(
                (font: string) =>
                {
                    setValueByFieldInEntity(
                        entity,
                        types.Activity.Campaign.Field.Font,
                        font,
                        commitContext
                    );

                    return commitEntityWithContext(
                        entity,
                        commitContext,
                        {
                            isForced: true
                        }
                    );
                },
                [
                    types,
                    entity,
                    commitContext
                ]);

        const font =
            useEntityValue<string>(
                entity,
                types.Activity.Campaign.Field.Font,
                undefined,
                commitContext
            );

        return <ExpansionPanel
            id="Template"
            summary={
                <Header
                    large
                    inset
                    title={
                        <LocalizedText
                            code="Generic.Template"
                            value="Sjabloon"
                        />
                    }
                />
            }
            expansion={
                <CardInset>
                    <ViewGroupItem>
                        <Input
                            labelPosition="left"
                            entity={entity}
                            field={types.Activity.Campaign.Field.EmailSubject}
                            commitContext={commitContext}
                        />
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <GenericInput
                            label={
                                <LocalizedText
                                    code="Generic.Font"
                                    value="Lettertype"
                                />
                            }
                            labelPosition="left"
                        >
                            <FontSelectbox
                                onChange={setFont}
                                value={font}
                            />
                       </GenericInput>
                    </ViewGroupItem>

                    <ExpressionEditorContext.Provider
                        value={expressionContext}
                    >
                        <InteractiveLayoutContext.Provider
                            value={false}
                        >
                            <DocumentEditor
                                key={entity.uuid}
                                type="gutenberg"
                                onChange={onTemplateChangeCallback}
                                definition={templateDefinition}
                                blockTypes={blockTypes}
                                editLayoutMode
                                documentLayoutControls
                            />
                        </InteractiveLayoutContext.Provider>
                    </ExpressionEditorContext.Provider>
                </CardInset>
            }
        />;
    };

export default observer(Campaign);
