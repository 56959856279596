import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import ValueFromEntityComputation from '../../../../../../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ListQueryEditorProps } from '../ListQueryEditor';
import { EntityContext } from '../../../../../../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../../../../../../Path/@Model/EntityPath';
import useSwitch from '../../../../../../../../../../../../../@Util/Switch/useSwitch';
import { EntityFieldPath } from '../../../../../../../../../../Path/@Model/EntityFieldPath';
import ViewGroup from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ListQueryOrdering from '../../../../../../../../../../../../../@Api/Automation/Query/ListQueryOrdering';
import Divider from '../../../../../../../../../../../../../@Future/Component/Generic/Divider/Divider';
import Popper from '../../../../../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import HoverCardBottom from '../../../../../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import Card from '../../../../../../../../../../../../../@Future/Component/Generic/Card/Card';
import FieldPathSelector from '../../../../../../../../../../Path/FieldPathEditor/FieldPathSelector';
import uuid from '../../../../../../../../../../../../../@Util/Id/uuid';
import ListQueryOrderingEditor from './ListQueryOrderingEditor';
import LocalizedText from '../../../../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface ListQueryOrderingsEditorProps extends ListQueryEditorProps
{

}

const ListQueryOrderingsEditor: React.FC<ListQueryOrderingsEditorProps> =
    props =>
    {
        const { query } = props;

        const fieldContext =
            useMemo(
                () =>
                    new EntityContext(
                        [],
                        EntityPath.fromEntityType(query.entityType)),
                [
                    query
                ]);

        const [ isFieldSelectorOpen, openFieldSelector, closeFieldSelector ] = useSwitch(false);
        const selectFieldPath =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            query.orderings.push(
                                new ListQueryOrdering(
                                    uuid(),
                                    new ValueFromEntityComputation(
                                        query.parameter,
                                        fieldPath),
                                    'Descending'));

                            closeFieldSelector();
                        }),
                [
                    query,
                    closeFieldSelector
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
        >
            <ViewGroupItem>
                <ol>
                    {
                        query.orderings.map(
                            ordering =>
                                <li
                                    key={ordering.id}
                                >
                                    <ListQueryOrderingEditor
                                        {...props}
                                        ordering={ordering}
                                    />
                                </li>
                        )
                    }
                </ol>
            </ViewGroupItem>
            <ViewGroupItem>
                <Divider />
                <Popper
                    reference={
                        <HoverCardBottom
                            onClick={openFieldSelector}
                        >
                            <LocalizedText
                                code="Query.AddField"
                                value="Veld toevoegen"
                            />
                        </HoverCardBottom>
                    }
                    popper={
                        <Card>
                            <FieldPathSelector
                                context={fieldContext}
                                onSelect={selectFieldPath}
                            />
                        </Card>
                    }
                    open={isFieldSelectorOpen}
                    onClose={closeFieldSelector}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(ListQueryOrderingsEditor);
