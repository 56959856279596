import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import getPortalRuntimeParameters from './getPortalRuntimeParameters';
import getPortalUserSettingsParameters from './getPortalUserSettingsParameters';

export default async function getPortalParameters(portal: Entity)
{
    const runtimeParameters = getPortalRuntimeParameters();

    return ParameterDictionary.union(
        runtimeParameters,
        await getPortalUserSettingsParameters(
            portal,
            runtimeParameters));
}

