import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import performAction from '../../../@Api/Entity/performAction';
import Dialog from '../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogContent from '../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import AppStoreBadge from './resources/app-store.png';
import GooglePlayBadge from './resources/google-play.png';
import Switch from '../../../@Future/Component/Generic/Input/Switch/Switch';
import uuid from '../../../@Util/Id/uuid';
import Input from '../../../@Future/Component/Generic/Input/Input/Input';
import RightAlignedButtonGroup from '../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import PrimaryTextButton from '../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import InputGroup from '../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { useTwoFactorAuthenticationConfiguration } from './Api/useTwoFactorAuthenticationConfiguration';
import PrimaryButton from '../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import AuthenticationManagerContext from '../../../@Service/Authentication/AuthenticationManagerContext';
import LocalizedText from '../Localization/LocalizedText/LocalizedText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { dangerColor } from '../../../@Resource/Theme/Theme';
import { User } from '../../../@Api/Model/Implementation/User';


const useStyles = makeStyles({
    danger: {
        color: dangerColor,
        fontWeight: "bold",
    },
});

export interface TwoFactorAuthenticationSetupProps
{
    user: User;
    onClose?: () => void;
}

const TwoFactorAuthenticationSetup: React.FC<TwoFactorAuthenticationSetupProps> =
    ({
        user,
        onClose,
    }) =>
    {
        const classes = useStyles();
        const authenticationManagerContext = useContext(AuthenticationManagerContext);

        const [ qrCode, setQrCode ] = useState();
        const [ refreshKey, setRefreshKey ] = useState(() => uuid());

        const [ twoFactorAuthenticationUserConfiguration, isLoadingUserConfiguration] =
            useTwoFactorAuthenticationConfiguration(
                user,
                refreshKey
            );

        const isTwoFactorAuthenticationRequired =
            useMemo<boolean>(
                () =>
                    !isLoadingUserConfiguration && twoFactorAuthenticationUserConfiguration.isRequired,
                [
                    isLoadingUserConfiguration,
                    twoFactorAuthenticationUserConfiguration
                ]
            );
        const isTwoFactorAuthenticationEnabled =
            useMemo<boolean>(
                () =>
                    !isLoadingUserConfiguration && twoFactorAuthenticationUserConfiguration.isEnabled,
                [
                    isLoadingUserConfiguration,
                    twoFactorAuthenticationUserConfiguration
                ]
            );

        const forceSetup =
            useMemo<boolean>(
                () =>
                    isTwoFactorAuthenticationRequired && !isTwoFactorAuthenticationEnabled,
                [
                    isTwoFactorAuthenticationRequired,
                    isTwoFactorAuthenticationEnabled,
                ]
            );

        const resetTwoFactorAuthentication =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Reset',
                            parameters: {
                                userId: user.id,
                            }
                        })
                        .then(
                            (result) =>
                            {
                                setQrCode(result.result);
                            }),
                [
                    user,
                    setQrCode
                ]
            );

        const disableTwoFactorAuthentication =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Disable',
                            parameters: {
                                userId: user.id,
                            }
                        })
                        .then(
                            (result) =>
                            {
                                setQrCode(result.result);
                            }),
                [
                    user,
                    setQrCode
                ]);

        const toggleTwoFactorAuthentication =
            useCallback(
                (checked: boolean) =>
                {
                    if (checked)
                    {
                        return resetTwoFactorAuthentication()
                            .then(
                                () =>
                                    setRefreshKey(uuid())
                            );
                    }
                    else
                    {
                        return disableTwoFactorAuthentication()
                            .then(
                                () =>
                                    setQrCode(undefined))
                            .then(
                                () =>
                                    setRefreshKey(uuid())
                            );
                    }
                },
                [
                    resetTwoFactorAuthentication,
                    disableTwoFactorAuthentication,
                    setRefreshKey,
                    setQrCode
                ]);

        const signOut =
            useCallback(
                () =>
                {
                    authenticationManagerContext.logout().finally();
                },
                [
                    authenticationManagerContext
                ]);

        useEffect(
            () =>
            {
                if (forceSetup)
                {
                    resetTwoFactorAuthentication().finally();
                }
            },
            [
                forceSetup,
                resetTwoFactorAuthentication,
            ]
        )

        return <Dialog
            onClose={onClose}
            hideCloseButton={false}
            open
        >
            <DialogTitle>
                {
                    <LocalizedText
                        code='TwoFactorAuthentication'
                        value='Twee factor authenticatie'
                    />
                }
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    {
                        !isTwoFactorAuthenticationRequired &&
                        <ViewGroupItem>
                            <InputGroup>
                                <Input
                                    labelPosition="right"
                                    label={
                                        <LocalizedText
                                            code='TwoFactorAuthenticationEnabled'
                                            value='Twee factor authenticatie ingeschakeld'
                                        />
                                    }
                                >
                                    <Switch
                                        onToggle={toggleTwoFactorAuthentication}
                                        checked={isTwoFactorAuthenticationEnabled}
                                        large
                                        disabled={isTwoFactorAuthenticationRequired}
                                    />
                                </Input>
                            </InputGroup>
                        </ViewGroupItem>
                    }
                    {
                        qrCode &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={15}
                            >
                                <ViewGroupItem>
                                    <LocalizedText
                                        code='TwoFactorAuthenticationStep1'
                                        value='<strong>Stap 1:</strong> download de Google Authenticator app.'
                                        containsHTML
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <a
                                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={AppStoreBadge}
                                            width={200}
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={GooglePlayBadge}
                                            width={200}
                                            style={{
                                                marginLeft: 10
                                            }}
                                            alt=""
                                        />
                                    </a>
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LocalizedText
                                        code='TwoFactorAuthenticationStep2'
                                        value='<strong>Stap 2:</strong> voeg een profiel toe in de Google Authenticator middels de volgende QR code'
                                        containsHTML
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <QRCode
                                        value={qrCode}
                                        size={250}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    <LocalizedText
                                        code='TwoFactorAuthenticationStep3'
                                        value='<strong>Stap 3:</strong> gebruik de unieke inlogcode na het invoeren van je e-mail adres en wachtwoord bij het inloggen'
                                        containsHTML
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            {
                                isTwoFactorAuthenticationEnabled &&
                                    <PrimaryTextButton
                                        label={
                                            <LocalizedText
                                                code='TwoFactorAuthenticationGenerateKey'
                                                value='Nieuwe sleutel genereren'
                                            />
                                        }
                                        onClick={resetTwoFactorAuthentication}
                                    />
                            }
                            {
                                forceSetup &&
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                >
                                    <ViewGroupItem
                                        className={classes.danger}
                                        ratio={1}
                                    >
                                        <LocalizedText
                                            code="Authentication.TwoFactor.Danger"
                                            value="Let op: je kan niet meer kunnen inloggen totdat de nieuwe code geactiveerd is"
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <PrimaryButton
                                            label={
                                                <LocalizedText
                                                    code="Authentication.TwoFactor.Login"
                                                    value="Opnieuw inloggen met twee factor authenticatie "
                                                />
                                            }
                                            onClick={signOut}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            }
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </DialogContent>
        </Dialog>;
    };

export default observer(TwoFactorAuthenticationSetup);
