import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../Type/EntityTypeStore';

export default function isHiddenInImportResult(type: EntityType)
{
    const types = loadModuleDirectly(EntityTypeStore).bespoke.types;

    return type.isA(types.Address.Type)
        || type.isA(types.ExternalId.Type);
}
