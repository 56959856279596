import { BaseStore } from '../../../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { EntityConstructorStore } from '../EntityConstructorStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { EntityRelationshipDefinition } from '../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { PredicateTypeStore } from '../../../Predicate/PredicateTypeStore';
import { EntityRelationship } from '../../../../../@Api/Model/Implementation/EntityRelationship';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { commit, createTransactionalModel, getModel } from '../../../../../@Util/TransactionalModelV2';

export class EntityRelationshipConstructorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('PredicateTypeStore') predicateTypeStore: PredicateTypeStore;

    // ------------------------- Properties -------------------------

    @observable entity: Entity;
    @observable relationshipDefinition: EntityRelationshipDefinition;
    @observable isParent: boolean;
    @observable relatedType: EntityType;
    @observable relatedEntity: Entity;
    @observable isTouched: boolean;

    // ------------------------ Constructor -------------------------

    constructor(entity: Entity,
                relationshipDefinition: EntityRelationshipDefinition,
                isParent: boolean,
                relatedType: EntityType = relationshipDefinition.getEntityType(isParent))
    {
        super();

        this.entity = entity;
        this.relationshipDefinition = relationshipDefinition;
        this.isParent = isParent;
        this.relatedType = relatedType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get relatedEntityConstructorStore(): EntityConstructorStore
    {
        if (this.relatedEntity && this.relatedEntity.isNew())
        {
            return new EntityConstructorStore({
                entityType: this.relatedType,
                entity: this.relatedEntity,
                sourceRelationshipDefinition: this.relationshipDefinition
            });
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get isValid(): boolean
    {
        return this.relatedEntity != null
            && (this.relatedEntityConstructorStore ? this.relatedEntityConstructorStore.isValid : true);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    @action.bound
    selectRelatedEntity(entity: Entity)
    {
        entity = createTransactionalModel(entity);

        this.relatedEntity = entity;

        return entity;
    }

    @action.bound
    touch()
    {
        this.isTouched = true;

        if (this.relatedEntityConstructorStore)
        {
            this.relatedEntityConstructorStore.touchAll();
        }
    }

    // ------------------------ Public logic ------------------------

    createRelationship(): EntityRelationship
    {
        commit(this.relatedEntity);

        return new EntityRelationship(
            undefined,
            this.isParent
                ?
                    getModel(this.relatedEntity)
                :
                    getModel(this.entity),
            this.isParent
                ?
                    getModel(this.entity)
                :
                    getModel(this.relatedEntity),
            this.relationshipDefinition)
            .initialize(this.entityTypeStore);
    }

    // ----------------------- Private logic ------------------------
}
