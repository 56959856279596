export default function openWindow(
    url?: string,
    target?: string,
    features?: string,
    replace?: boolean
)
{
    // Execute window.open() in main thread, needed for iPads
    setTimeout(
        () =>
            window.open(
                url,
                target,
                features,
                replace
            )
    );
}
