import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import PrimaryTextButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import useResult from '../../../../Selection/Hooks/useResult';
import useTypes from '../../../../Type/Api/useTypes';
import { createTransactionalModel } from '../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { openEntity } from '../../../../@Util/openEntity';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import deleteEntity from '../../../../../../../@Api/Entity/deleteEntity';
import MutationTrigger from '../../../../../../../@Api/Automation/Trigger/MutationTrigger';
import { Automator } from '../../../../Type/BespokeEntityType';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface AutomatorItemProps
{
    entity: Entity;
    automator: Automator;
}

const AutomatorItem: React.FC<AutomatorItemProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();

        const [ activation ] =
            useResult(
                types.AutomationActivation.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.eq(
                                rootPath
                                    .joinTo(
                                        types.Automation.RelationshipDefinition.Activations,
                                        true)
                                    .field(types.Automation.Field.Code),
                                undefined,
                                props.automator.code)),
                [
                    types,
                    props.automator
                ]);

        const automate =
            useCallback(
                () =>
                {
                    const automation = props.automator.automate();

                    const automationEntity =
                        createTransactionalModel(
                            new Entity(types.Automation.Type)
                                .initialize());

                    automationEntity.setValueByField(
                        types.Automation.Field.LocalizedName,
                        props.automator.name);

                    automationEntity.setValueByField(
                        types.Automation.Field.Code,
                        props.automator.code);

                    automationEntity.setValueByField(
                        types.Automation.Field.IsUser,
                        props.automator.isUser);

                    automationEntity.setValueByField(
                        types.Automation.Field.Specification,
                        automation.toDescriptor());

                    if (automation.trigger instanceof MutationTrigger)
                    {
                        automationEntity.updateRelationship(
                            true,
                            types.EntityType.RelationshipDefinition.Automations,
                            createTransactionalModel(automation.trigger.entityType.entity));
                    }

                    const activationEntity =
                        createTransactionalModel(
                            new Entity(types.AutomationActivation.Type)
                                .initialize());

                    activationEntity.updateRelationship(
                        true,
                        types.Automation.RelationshipDefinition.Activations,
                        automationEntity);

                    activation.updateRelationship(
                        true,
                        types.Relation.Organization.Environment.RelationshipDefinition.AutomationActivations,
                        createTransactionalModel(currentUserStore.environmentEntity));

                    if (automationEntity.getObjectValueByField(types.Automation.Field.IsUser))
                    {
                        activation.updateRelationship(
                            true,
                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.AutomationActivations,
                            createTransactionalModel(currentUserStore.employeeEntity));
                    }

                    return activationEntity.checkAndDoCommit();
                },
                [
                    props.automator,
                    types,
                    currentUserStore
                ]);

        const deactivate =
            useCallback(
                () =>
                    deleteEntity(activation),
                [
                    activation
                ]);

        const manage =
            useCallback(
                () =>
                    openEntity(
                        activation.getRelatedEntityByDefinition(
                            true,
                            types.Automation.RelationshipDefinition.Activations)),
                [
                    activation,
                    types
                ]);

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <b>
                        {props.automator.name}
                    </b>
                </ViewGroupItem>
                <ViewGroupItem>
                    {props.automator.description}
                </ViewGroupItem>
                <ViewGroupItem>
                    <RightAlignedButtonGroup>
                        {
                            !activation &&
                                <PrimaryTextButton
                                    label={
                                        <LocalizedText
                                            code="Automator.AutomateExclamationMark"
                                            value="Automatiseer!"
                                        />
                                    }
                                    onClick={automate}
                                />
                        }
                        {
                            activation &&
                                <PrimaryTextButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Deactivate"
                                            value="Deactveren"
                                        />
                                    }
                                    onClick={deactivate}
                                />
                        }
                        {
                            activation &&
                                <PrimaryTextButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Manage"
                                            value="Beheren"
                                        />
                                    }
                                    onClick={manage}
                                />
                        }
                    </RightAlignedButtonGroup>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(AutomatorItem);
