import { EntitySelectionBuilder } from '../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import { Entity } from '../../../Model/Implementation/Entity';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityPath } from '../../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { CurrentUserStore } from '../../../../@Component/Domain/User/CurrentUserStore';
import getTypes from '../../../../@Component/Domain/Entity/Type/Api/getTypes';

export default async function getConnectorActivationByCode(code: string,
                                                           isPersonal: boolean = false): Promise<Entity>
{
    const currentUserStore = loadModuleDirectly(CurrentUserStore);
    const types = getTypes();
    const rootPath = EntityPath.fromEntityType(types.ConnectorActivation.Type);

    const results = await new EntitySelectionBuilder(types.ConnectorActivation.Type)
        .where(
            cb =>
                cb.eq(
                    rootPath
                        .joinTo(
                            types.Connector.RelationshipDefinition.Activations,
                            true
                        )
                        .field(types.Connector.Field.Code),
                    undefined,
                    code
                )
        )
        .if(
            () =>
                isPersonal,
            sb =>
                sb.where(
                    cb =>
                        cb.relatedToEntity(
                            rootPath
                                .joinTo(
                                    types.Relationship.Person.Contact.Employee.RelationshipDefinition.ConnectorActivations,
                                    true),
                            currentUserStore.employeeEntity
                        )
                )
        )
        .select();

    return results
        .map(
            result =>
                result.entity)
        .find(() => true)
}
