import Layout from '../Layout';
import Dependency from '../../Automation/Parameter/Dependency';
import Validation from '../../Automation/Validation/Validation';
import { observable } from 'mobx';
import LayoutDependencyContext from '../LayoutDependencyContext';
import getLayoutFromDescriptor from '../Api/getLayoutFromDescriptor';
import ParameterAssignment from '../../Automation/Parameter/ParameterAssignment';

export default class CompositeLayoutItem
{
    // ------------------------- Properties -------------------------

    @observable id: string;
    @observable.ref layout: Layout;
    @observable ratio: number;
    @observable.ref parameterAssignment?: ParameterAssignment;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                layout: Layout,
                ratio: number,
                parameterAssignment?: ParameterAssignment)
    {
        this.id = id;
        this.layout = layout;
        this.ratio = ratio;
        this.parameterAssignment = parameterAssignment;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return this.layout.validate();
    }

    getDependencies(): Dependency[]
    {
        return this.layout.getDependencies();
    }

    toDescriptor()
    {
        return {
            id: this.id,
            layout: this.layout.toDescriptor(),
            ratio: this.ratio
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: LayoutDependencyContext)
    {
        return new CompositeLayoutItem(
            descriptor.id,
            await getLayoutFromDescriptor(
                descriptor.layout,
                dependencyContext),
            descriptor.ratio);
    }

    // ----------------------- Private logic ------------------------
}
