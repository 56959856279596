import TrackItem from '../../Model/TrackItem';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import getDurationLabel from '../../../../../../../../@Util/Date/getDurationLabel';
import Icon from '../../../../../../../../@Future/Component/Generic/Icon/Icon';
import { makeStyles } from '@material-ui/styles';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface TrackItemTooltipTimespanProps
{
    trackItem: TrackItem;
}

const useStyles =
    makeStyles({
        wrapper:
            {
                padding: 5,
                paddingLeft: 0,
                fontSize: 13,
                whiteSpace: 'normal',
            }
    });

export const TrackItemTooltipTimespan: React.FC<TrackItemTooltipTimespanProps> =
    observer(
        ({
             trackItem
        }) =>
        {
            const classes = useStyles();
            const localizedDateString =
                useMemo(
                    () =>
                    {
                        if (trackItem.item.period.from !== undefined && trackItem.item.period.to !== undefined)
                        {
                            if (trackItem.item.period.to.date() === trackItem.item.period.from.date())
                            {
                                return trackItem.item.period.from.format("LLLL") + "-" + trackItem.item.period.to.format("LT");
                            }
                            else
                            {
                                return <span>
                                    {trackItem.item.period.from.format("LLLL")}
                                    <br />
                                    {trackItem.item.period.to.format("LLLL")}
                                </span>;
                            }
                        }
                        return "";
                    },
                    [
                        trackItem
                    ]
                );

            return <ViewGroup
                orientation="vertical"
                spacing={0}
                alignment="start"
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={8}
                        alignment="center"
                        className={classes.wrapper}
                        justification="center"
                    >
                        <ViewGroupItem>
                            <Icon
                                icon="access_time"
                                size={22}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                <ViewGroupItem>
                                    {localizedDateString}
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={8}
                        alignment="center"
                        className={classes.wrapper}
                        justification="start"
                    >
                        <ViewGroupItem>
                            <Icon
                                icon="timelapse"
                                size={22}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                <ViewGroupItem>
                                    {getDurationLabel(trackItem.item.period.to.diff(trackItem.item.period.from, 'milliseconds'))}
                                    <LocalizedText
                                        code="Generic.Hour"
                                        value="Uur"
                                        lowerCase
                                    />
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
            </ViewGroup>

        }
    );