import React from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroupItem from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import { Entity } from '../../../../../../../../../../../@Api/Model/Implementation/Entity';
import Author from '../Author/Author';

export interface HeaderProps
{
    name: React.ReactNode;
    entity: Entity;
    includeAuthor?: boolean;
}

const Header: React.FC<HeaderProps> =
    props =>
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={10}
            alignment="center"
        >
            <ViewGroupItem>
                <strong>{props.name}</strong>
            </ViewGroupItem>
            {
                props.includeAuthor &&
                    <ViewGroupItem>
                        <Author
                            entity={props.entity}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Header);
