import { Page } from './usePaginatedSelection';
import getTypes from '../../Type/Api/getTypes';
import { GoogleMapLocation } from '../../../../Generic/GoogleMaps/GoogleMap';
import { useMemo } from 'react';
import useRelatedAddresses from './useRelatedAddresses';
import { getAddressSortOrderId } from './getAddressSortOrderId';
import { useComputed } from 'mobx-react-lite';

export function useGoogleMapsPinsFromPagedResult(pages: Page[]): [ GoogleMapLocation[], boolean]
{
    const types= getTypes();

    const resultEntities =
        useMemo(
            () =>
                pages
                    .flatMap(
                        page =>
                            page.map(result => result.entity)
                    ),
        [
                pages
            ]
        );

    const [ entitiesAndAddresses, loadingAddresses ] =
        useRelatedAddresses(resultEntities);

    // Extract first address with location info
    const entitiesAndSingleLocation =
        useComputed(
            () =>
                {
                    if (loadingAddresses)
                    {
                        return []
                    }
                    else
                    {
                        return entitiesAndAddresses
                            .map(
                                entityWithAddresses =>
                                {
                                    const firstAddress =
                                        entityWithAddresses?.addresses.length > 0
                                            ? entityWithAddresses.addresses[0]
                                            : undefined;

                                    const firstAddressWithLocation =
                                        [...entityWithAddresses.addresses]
                                            .filter(
                                                address =>
                                                    address?.hasValueForField(types.Address.Field.Latitude) &&
                                                    address?.hasValueForField(types.Address.Field.Longitude)
                                            )
                                            .sort( (a, b) => getAddressSortOrderId(a) - getAddressSortOrderId(b))
                                            .find(() => true)

                                    return {
                                        entity: entityWithAddresses.entity,
                                        addressProvider: entityWithAddresses.addressProvider,
                                        address: firstAddressWithLocation || firstAddress
                                    }
                                }
                            )
                    }
                },
            [
                entitiesAndAddresses
            ]
        )

    const pinLocations=
        useMemo(
            () =>
                {
                    if (loadingAddresses)
                    {
                        return []
                    }

                    return entitiesAndSingleLocation
                        .map(
                            entityAndAddress =>
                            {
                                const hasLocation = entityAndAddress.address &&
                                    entityAndAddress.address.hasValueForField(types.Address.Field.Latitude) &&
                                    entityAndAddress.address.hasValueForField(types.Address.Field.Longitude)

                                const addressLine=
                                    entityAndAddress.address
                                        ? types.Address.Functions.getAddressLine(entityAndAddress.address)
                                        : '';

                                return {
                                    lat: hasLocation ? entityAndAddress.address.getObjectValueByField<number>(types.Address.Field.Latitude) : undefined,
                                    lng: hasLocation  ? entityAndAddress.address.getObjectValueByField<number>(types.Address.Field.Longitude) : undefined,
                                    label: entityAndAddress.addressProvider?.getName() || entityAndAddress.entity.getName(),
                                    googleMapsLink: hasLocation ? `https://www.google.com/maps/place/${encodeURIComponent(addressLine)}` : undefined,
                                    entity: entityAndAddress.entity,
                                    addressProvider: entityAndAddress.addressProvider,
                                    address: entityAndAddress.address
                                };
                            }
                        );
                },
        [
                types,
                loadingAddresses,
                entitiesAndSingleLocation
            ]
        );

    return [ pinLocations, loadingAddresses ];
}
