import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';
import { useCallback } from 'react';
import { CommitBuilder } from '../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export default function useEndTimeRegistration()
{
    const types = useTypes();

    return useCallback(
        (timeRegistration: Entity,
         endDate?: Date) =>
            new CommitBuilder()
                .setObjectValueInEntity(
                    timeRegistration,
                    types.TimeRegistration.Field.EndDate,
                    endDate ? endDate : new Date()
                )
                .commit(),
        [
            types,
        ]);
}
