import { ComputationEditorStore } from '../../ComputationEditorStore';
import { action, computed, observable } from 'mobx';
import { ComputationContext } from '../../ComputationContext';
import { ComputationType } from '../ComputationType';
import { ComputationSpecification } from '../ComputationSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { DataObjectStore } from '../../../DataObject/DataObjectStore';
import { DataObjectEditorStore } from '../../../DataObject/Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectType } from '../../../DataObject/Model/DataObjectType';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { DataDescriptor } from '../../../DataObject/Model/DataDescriptor';
import { MenuStore } from '../../../../Generic/Menu/MenuStore';
import { MenuItemStore } from '../../../../Generic/Menu/MenuItemStore';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';

export class ConstantComputationEditorStore extends ComputationEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('DataObjectStore') dataObjectStore: DataObjectStore;

    // ------------------------- Properties -------------------------

    @observable constant: DataObject;
    @observable isTypeMenuOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification,
                constant: DataObject)
    {
        super(type, context, specification);

        this.constant = constant;
        this.isTypeMenuOpen = !constant;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get editButtonStore(): ButtonStore
    {
        return new ButtonStore({
           icon: 'edit',
           onClick: this.openTypeMenu
        });
    }

    @computed
    get constantEditorStore()
    {
        if (this.constant)
        {
            let editorStore = new DataObjectEditorStore({
                dataObject: this.constant,
                isFocused: true
            });

            return editorStore;
        }
        else
        {
            return undefined;
        }
    }

    // --------------------------- Stores ---------------------------

    @computed
    get typeSelectorStore(): MenuStore
    {
        return new MenuStore({
            maxHeight: 250,
            items:
                () => this.dataObjectStore.types
                    .sort((a, b) => a.name() < b.name() ? -1 : a.name() > b.name() ? 1 : 0)
                    .map(
                        type =>
                            new MenuItemStore({
                                value: type,
                                id: `item.${type.id()}`,
                                name: type.name()})),

            onSelect:
                itemStore =>
                {
                    this.setType(itemStore.value);
                    this.closeTypeMenu();
                }
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    openTypeMenu()
    {
        this.isTypeMenuOpen = true;
    }

    @action.bound
    closeTypeMenu()
    {
        this.isTypeMenuOpen = false;
    }

    @action.bound
    setType(type: DataObjectType)
    {
        this.constant = new DataObject(new DataObjectSpecification(type, {}), new DataDescriptor());
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
