import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import Value from '../../Value/Value';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';

export default class LinkComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref text: Computation<any, any>;
    @observable.ref url: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(text: Computation<any, any>,
                url: Computation<any, any>)
    {
        super();

        this.text = text;
        this.url = url;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('RichText'));
    }

    getName(): string
    {
        return this.text?.getName() || 'Link...';
    }

    isAsync(): boolean
    {
        return this.url.isAsync()
            || (this.text?.isAsync() ?? false);
    }

    async apply(context: FunctionContext)
    {
        const [ text, url ] =
            await Promise.all([
                safelyApplyFunction(this.text, context),
                safelyApplyFunction(this.url, context)
            ]);

        return this.getAHref(text, url);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const text = safelySynchronousApplyFunction(this.text, context);
        const url = safelySynchronousApplyFunction(this.url, context);

        return this.getAHref(text, url);
    }

    getAHref(text: Value<any, any>,
             url: Value<any, any>)
    {
        return new PrimitiveValue(
            DataObject.constructFromTypeIdAndValue(
                'RichText',
                `<a href="${url.getName()}">${text.getName()}</a>`));
    }

    validate(): Validation[]
    {
        const validations: Validation[] = [];

        if (!this.text)
        {
            validations.push(
                new Validation(
                    'Error',
                    'Geen tekst in link ingevuld'));
        }

        if (!this.url)
        {
            validations.push(
                new Validation(
                    'Error',
                    'Geen geldige link ingevuld'));
        }

        return validations;
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Link';
        descriptor.text = this.text.toDescriptor();
        descriptor.url = this.url.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.text.getDependencies(),
            ...this.url.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new LinkComputation(
            await getComputationFromDescriptor(
                descriptor.text,
                dependencyContext),
            await getComputationFromDescriptor(
                descriptor.url,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
