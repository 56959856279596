import React from 'react';
import { observer } from 'mobx-react-lite';
import FunctionContext from '../../../../../../../../@Api/Automation/Function/FunctionContext';
import Action from '../../../../../../../../@Api/Automation/Function/Action/Action';
import CompositeAction from '../../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import CreateEntityAction from '../../../../../../../../@Api/Automation/Function/Action/CreateEntityAction';
import CompositeActionEditor, { CompositeActionEditorProps } from './Type/Composite/CompositeActionEditor';
import CreateEntityActionEditor, { CreateEntityActionEditorProps } from './Type/CreateEntity/CreateEntityActionEditor';
import UpdateEntityAction from '../../../../../../../../@Api/Automation/Function/Action/UpdateEntityAction';
import UpdateEntityActionEditor, { UpdateEntityActionEditorProps } from './Type/UpdateEntity/UpdateEntityActionEditor';
import ForEachAction from '../../../../../../../../@Api/Automation/Function/Action/ForEachAction';
import ForEachActionEditor, { ForEachActionEditorProps } from './Type/ForEach/ForEachActionEditor';
import DynamicAction from '../../../../../../../../@Api/Automation/Function/Action/DynamicAction';
import DynamicActionEditor, { DynamicActionEditorProps } from './Type/Dynamic/DynamicActionEditor';
import ActionConstructor from './Constructor/ActionConstructor';
import ConditionalAction from '../../../../../../../../@Api/Automation/Function/Action/ConditionalAction';
import ConditionalActionEditor, { ConditionalActionEditorProps } from './Type/ConditionalAction/ConditionalActionEditor';
import DeleteEntityActionEditor, { DeleteEntityActionEditorProps } from './Type/DeleteEntity/DeleteEntityActionEditor';
import DeleteEntityAction from '../../../../../../../../@Api/Automation/Function/Action/DeleteEntityAction';
import ExceptionAction from '../../../../../../../../@Api/Automation/Function/Action/ExceptionAction';
import ExceptionActionEditor, { ExceptionActionEditorProps } from './Type/Exception/ExceptionActionEditor';
import InvocationAction from '../../../../../../../../@Api/Automation/Function/Action/InvocationAction';
import InvocationActionEditor, { InvocationActionEditorProps } from './Type/Invocation/InvocationActionEditor';
import CreateRelationshipAction from '../../../../../../../../@Api/Automation/Function/Action/CreateRelationshipAction';
import CreateRelationshipActionEditor, { CreateRelationshipActionEditorProps } from './Type/CreateRelationship/CreateRelationshipActionEditor';
import DeleteRelationshipAction from '../../../../../../../../@Api/Automation/Function/Action/DeleteRelationshipAction';
import DeleteRelationshipActionEditor, { DeleteRelationshipActionEditorProps } from './Type/DeleteRelationship/DeleteRelationshipActionEditor';
import FtpUploadActionEditor, { FtpUploadActionEditorProps } from './Type/FtpUpload/FtpUploadActionEditor';
import FtpUploadAction from '../../../../../../../../@Api/Automation/Function/Action/FtpUploadAction';
import GenerateNumberInEntityAction from '../../../../../../../../@Api/Automation/Function/Action/GenerateNumberInEntityAction';
import GenerateNumberInEntityActionEditor, { GenerateNumberInEntityActionEditorProps } from './Type/GenerateNumberInEntity/GenerateNumberInEntityActionEditor';

export interface ActionEditorProps<T extends Action<any, any> = Action<any, any>>
{
    context: FunctionContext;
    action?: T;
    onChange?: (action: Action<any, any>) => void;
    returnValue?: boolean;
}

const ActionEditor: React.FC<ActionEditorProps> =
    props =>
    {
        if (!props.action && props.onChange)
        {
            return <ActionConstructor
                onConstruct={props.onChange}
                mode="Button"
                context={props.context}
            />;
        }
        else if (props.action instanceof CompositeAction)
        {
            return <CompositeActionEditor
                {...props as CompositeActionEditorProps}
            />;
        }
        else if (props.action instanceof ForEachAction)
        {
            return <ForEachActionEditor
                {...props as ForEachActionEditorProps}
            />;
        }
        else if (props.action instanceof ConditionalAction)
        {
            return <ConditionalActionEditor
                {...props as ConditionalActionEditorProps}
            />;
        }
        else if (props.action instanceof CreateEntityAction)
        {
            return <CreateEntityActionEditor
                {...props as CreateEntityActionEditorProps}
            />;
        }
        else if (props.action instanceof UpdateEntityAction)
        {
            return <UpdateEntityActionEditor
                {...props as UpdateEntityActionEditorProps}
            />;
        }
        else if (props.action instanceof DeleteEntityAction)
        {
            return <DeleteEntityActionEditor
                {...props as DeleteEntityActionEditorProps}
            />;
        }
        else if (props.action instanceof CreateRelationshipAction)
        {
            return <CreateRelationshipActionEditor
                {...props as CreateRelationshipActionEditorProps}
            />;
        }
        else if (props.action instanceof DeleteRelationshipAction)
        {
            return <DeleteRelationshipActionEditor
                {...props as DeleteRelationshipActionEditorProps}
            />;
        }
        else if (props.action instanceof DynamicAction)
        {
            return <DynamicActionEditor
                {...props as DynamicActionEditorProps}
            />;
        }
        else if (props.action instanceof InvocationAction)
        {
            return <InvocationActionEditor
                {...props as InvocationActionEditorProps}
            />;
        }
        else if (props.action instanceof FtpUploadAction)
        {
            return <FtpUploadActionEditor
                {...props as FtpUploadActionEditorProps}
            />;
        }
        else if (props.action instanceof ExceptionAction)
        {
            return <ExceptionActionEditor
                {...props as ExceptionActionEditorProps}
            />;
        }
        else if (props.action instanceof GenerateNumberInEntityAction)
        {
            return <GenerateNumberInEntityActionEditor
                {...props as GenerateNumberInEntityActionEditorProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(ActionEditor);
