import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { ComputationType } from './Type/ComputationType';
import { ComputationTypeStore } from './ComputationTypeStore';
import { ComputationSpecification } from './Type/ComputationSpecification';
import { ComputationContext } from './ComputationContext';
import { CompositeComputationSpecification } from './Type/Composite/CompositeComputationSpecification';

export class ComputationEditorStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable type: ComputationType;
    @observable context: ComputationContext;
    @observable specification: ComputationSpecification;

    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification)
    {
        super();

        this.type = type;
        this.context = context;
        this.specification = specification;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get descriptor()
    {
        return this.type
            .editorSpecification(
                this.editorStore);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get editorStore(): ComputationEditorStore
    {
        if (this.type)
        {
            return this.type.editorStore(
                this.context,
                this.specification);
        }
        else
        {
            return null;
        }
    }

    // -------------------------- Actions ---------------------------

    @action
    setType(type: any) // ComputationType instead of any causes compilation errors
    {
        this.type = type;
    }

    // ------------------------ Public logic ------------------------

    static construct(context: ComputationContext,
                     specification: ComputationSpecification,
                     typeStore: ComputationTypeStore): ComputationEditorStore
    {
        if (!specification)
        {
            specification = { type: 'Composite' };
        }

        let type = typeStore.getTypeById(specification.type);

        if (specification.type === 'Mathematical')
        {
            let newSpec = { type: 'Composite' } as CompositeComputationSpecification;
            newSpec.type = 'Composite';
            newSpec.operations = [ { computation: (specification as any).a }, { operator: (specification as any).operator, computation: (specification as any).b } ];
            specification = newSpec;

            return new ComputationEditorStore(
                type,
                context,
                newSpec);
        }

        return new ComputationEditorStore(
            type,
            context,
            specification);
    }

    // ----------------------- Private logic ------------------------
}
