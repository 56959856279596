import { type } from '../../../@Util/Serialization/Serialization';
import { EntityValueMutation } from './EntityValueMutation';

@type('EntityValueCreationMutation')
export class EntityValueCreationMutation extends EntityValueMutation
{
    // ------------------- Persistent Properties --------------------
    
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
