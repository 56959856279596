import { reference, type } from '../../../@Util/Serialization/Serialization';
import { DataObjectSpecification } from '../../../@Component/Domain/DataObject/Model/DataObjectSpecification';
import { Computation } from '../../../@Component/Domain/Computation/Type/Computation';
import { OldPredicate } from '../../../@Component/Domain/Predicate/Type/OldPredicate';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { DataObjectType, EntityField } from './EntityField';
import { EntityContext } from '../../../@Component/Domain/Entity/@Model/EntityContext';
import { EntityPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityPath';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';

@type('EntityFieldCondition')
export class EntityFieldCondition
{
    // ------------------- Persistent Properties --------------------

    id: number;
    @reference(undefined, 'EntityField') entityField: EntityField;
    predicate: any;
    specification: any;
    isReadonly: boolean;
    computation: any;

    // ------------------------- Properties -------------------------

    initializedPredicate: OldPredicate;
    initializedSpecification: DataObjectSpecification;
    initializedComputation: Computation;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(field: EntityField,
               entityTypeStore: EntityTypeStore)
    {
        this.entityField = field;

        this.initializedPredicate =
            entityTypeStore.predicateTypeStore.fromSpecification(this.predicate);

        this.initializedSpecification =
            this.specification
                ?
                    entityTypeStore.dataObjectStore.constructSpecification(
                        DataObjectType[this.entityField.type as any],
                        this.specification,
                        field.isRequired)
                :
                    undefined;

        this.initializedComputation =
            this.computation
                ?
                    entityTypeStore.computationTypeStore.fromSpecification(this.computation)
                :
                    undefined;
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(path: EntityPath): EntityFieldPath[]
    {
        const paths: EntityFieldPath[] = [];
        const context = new EntityContext([], path);

        paths.push(
            ...this.initializedPredicate.type.entityFieldPaths(
                this.predicate,
                context));

        if (this.initializedComputation)
        {
            paths.push(
                ...this.initializedComputation.type.entityFieldPaths(
                    this.computation,
                    context));
        }

        return paths;
    }

    // ----------------------- Private logic ------------------------
}
