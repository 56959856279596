import { IntervalType } from '../../DataObject/Type/Date/DateInterval/DateIntervalValue';
import getIntervalMultiplier from '../../DataObject/Type/Date/DateInterval/Api/getIntervalMultiplier';
import { ApplicationPack } from '../Model/ApplicationPack';

export function getApplicationPackPrice(
    applicationPack: ApplicationPack,
    interval: IntervalType
): number
{
    if (applicationPack.price > 0)
    {
        return applicationPack.price *
            getIntervalMultiplier(applicationPack.priceRepeatInterval, interval);
    }
    else
    {
        return 0;
    }
}