import React, { useCallback, useContext, useState } from 'react';
import { BlockEditProps } from '@wordpress/blocks';
import { PanelBody, PanelRow, TextControl } from '@wordpress/components';
import FormLinkPanel from './formLinkOptions/FormLinkPanel';
import { useComputed } from 'mobx-react-lite';
import localizeText from '../../../../../../../@Api/Localization/localizeText';
import ViewGroup from '../../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import ExpressionEditorContext from '../Expression/ExpressionEditorContext';
import { FormLinkProperties, fromDescriptor } from './formLinkOptions/model/FormLinkProperties';


export interface LinkOptionsPanelProps
{
    props: BlockEditProps<{}>;
    open?: boolean;
}

const LinkOptionsPanel: React.FC<LinkOptionsPanelProps> =
    ({
        props,
        open
    }) =>
    {
        const {
            linkUrl
        } = props.attributes as any;

        const expressionContext = useContext(ExpressionEditorContext);
        const functionContext =
            useComputed(
                () => expressionContext.entityContext.toFunctionContext(),
                [
                    expressionContext
                ]
            );

        const [ formLinkProperties, setFormLinkProperties ] =
            useState<FormLinkProperties>(
                () =>
                    fromDescriptor(
                        props.attributes.formLink,
                        functionContext
                    )
                )

        const onChangeLinkUrl =
            useCallback(
                (link) => {
                    props.setAttributes({
                        linkUrl: link,
                        formLink: undefined
                    })
                    setFormLinkProperties(undefined)

                },
                [
                    props,
                    setFormLinkProperties
                ]
            );

        const onChangeFormLinkProps =
            useCallback(
                (newProps: FormLinkProperties) =>
                {
                    if (newProps.formId)
                    {
                        const serializedFields =
                            newProps.fields.map(
                                formLinkField =>
                                {
                                    return {
                                        fieldPath: formLinkField.fieldPath.descriptor,
                                        value: formLinkField.value?.toDescriptor()
                                    }
                                }
                            )

                        const serializedProps = {
                            formId: newProps.formId,
                            fields: serializedFields
                        }

                        props.setAttributes({
                            formLink: serializedProps,
                            linkUrl: undefined
                        });
                    }
                    else
                    {
                        props.setAttributes({
                            formLink: undefined
                        });
                    }
                    setFormLinkProperties(newProps);
                },
                [
                    setFormLinkProperties,
                    props
                ]
            )

        return <PanelBody
            title={localizeText('Gutenberg.LinkOptions.Title', 'Link')}
            initialOpen={!!open}
        >
            <PanelRow>
                <ViewGroup 
                    orientation="vertical" 
                    spacing={5}
                >
                    <ViewGroupItem>
                        {
                            !formLinkProperties?.formId &&
                            <TextControl
                                name="linkUrl"
                                label={
                                    <LocalizedText
                                        code="Gutenberg.LinkOptions.URL"
                                        value="URL"
                                    />
                                }
                                value={linkUrl}
                                onChange={onChangeLinkUrl}
                            />
                        }
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <FormLinkPanel
                            formLinkProps={formLinkProperties}
                            onChange={onChangeFormLinkProps}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </PanelRow>
        </PanelBody>;
    };

export default LinkOptionsPanel;
