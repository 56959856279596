import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import useDividerGlue from '../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Divider from '../../../../@Future/Component/Generic/Divider/Divider';
import TypeItem from './TypeItem/TypeItem';
import TypeEditor from './TypeConstructor/TypeConstructor';
import HoverCardMiddle from '../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import SettingsIconButton from '../../../../@Future/Component/Generic/Button/Variant/SettingsIconButton/SettingsIconButton';
import { openEntityTypeConfiguration } from '../../Entity/@Util/openEntityTypeConfiguration';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import { createStringComparator } from '../../../Generic/List/V2/Comparator/StringComparator';

export interface TypeViewProps
{
    name: React.ReactNode;
    addName: React.ReactNode;
    entityType: EntityType;
    filter?: (childType: EntityType) => boolean;
    allowConfig?: boolean;
    disallowAdd?: boolean;
    disallowConfigOnRoot?: boolean;
    addRequiresCreator?: boolean;
    additionalTypes?: EntityType[]
}

const TypeManager: React.FC<TypeViewProps> =
    props =>
    {
        const { filter, additionalTypes } = props;
        const childTypes =
            useComputed(
                () =>
                    props.entityType.childTypes
                        .filter(
                            type =>
                                filter(type)
                        ),
                [
                    props.entityType,
                    filter
                ]);
        const allChildTypes =
            useMemo(
                () => [
                    ...childTypes,
                    ...(additionalTypes ?? [])
                ].sort(
                    createStringComparator(
                        a =>
                            a.getName()
                    )
                ),
                [
                    childTypes,
                    additionalTypes,
                ]
            );
        const [ isInCreationMode, setCreationMode ] = useState(false);
        const createType =
            useCallback(
                () =>
                    setCreationMode(true),
                [
                    setCreationMode
                ]);
        const closeTypeCreation =
            useCallback(
                () =>
                    setCreationMode(false),
                [
                    setCreationMode
                ]);


        const openConfiguration =
            useCallback(
                () =>
                    openEntityTypeConfiguration(props.entityType),
                [
                    props.entityType
                ]);

        return <Card>
            <CardInset>
                <CardHeader>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            {props.name}
                        </ViewGroupItem>
                        {
                            !props.disallowConfigOnRoot &&
                                <ViewGroupItem>
                                    <SettingsIconButton
                                        onClick={openConfiguration}
                                    />
                                </ViewGroupItem>
                        }
                    </ViewGroup>
                </CardHeader>
            </CardInset>
            <Divider />
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={useDividerGlue()}
            >
                {
                    !props.disallowAdd &&
                        <ViewGroupItem>
                            {
                                isInCreationMode
                                    ?
                                        <TypeEditor
                                            parentType={props.entityType}
                                            onClose={closeTypeCreation}
                                            addRequiresCreator={props.addRequiresCreator}
                                        />
                                    :
                                        <HoverCardMiddle
                                            onClick={createType}
                                        >
                                            <LocalizedText
                                                code="Configuration.AddTypeButton"
                                                value="+ Creëer je eigen"
                                            />
                                            &nbsp;{props.addName}
                                        </HoverCardMiddle>
                            }
                        </ViewGroupItem>
                }
                {
                    allChildTypes.map(
                        childType =>
                            <ViewGroupItem
                                key={childType.id}
                            >
                                <TypeItem
                                    type={childType}
                                    depth={0}
                                    filter={filter}
                                    allowConfig={props.allowConfig}
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>
        </Card>;
    };

TypeManager.defaultProps = {
    filter: () => true
};

export default observer(TypeManager);
