import { EntityFieldPath } from '../@Model/EntityFieldPath';
import { EntityContext } from '../../@Model/EntityContext';
import { MenuStore } from '../../../../Generic/Menu/MenuStore';
import { ViewComponent } from '../../../../Generic/ViewStack/Model/ViewComponent';
import Menu from '../../../../Generic/Menu/Menu';
import { MenuItemStore } from '../../../../Generic/Menu/MenuItemStore';
import { EntityPath } from '../@Model/EntityPath';
import { blue } from '../../../../../@Resource/Theme/Blue';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { AvatarStore } from '../../../../Generic/Avatar/AvatarStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import { mediumGrey } from '../../../../../@Resource/Theme/Theme';
import { EntityTypeStore } from '../../Type/EntityTypeStore';
import { createStringComparator } from '../../../../Generic/List/V2/Comparator/StringComparator';

export class EntityFieldPathPickerStore extends MenuStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(context: EntityContext,
                onSelect: (path: EntityFieldPath, parameter?: string) => void,
                parameter?: string,
                allowParameterSelection: boolean = true)
    {
        super(
            allowParameterSelection && context.getContext(parameter).contextByParameter.size > 0
                ?
                    {
                        maxHeight: 250,
                        items:
                            () =>
                                [ ...Array.from(context.contextByParameter.keys()), undefined ]
                                    .map(
                                        parameter =>
                                            new MenuItemStore(
                                            {
                                                id: parameter || 'context',
                                                name:
                                                    () => context.getContext(parameter).entityPath.name(this.entityTypeStore),
                                                avatar: new AvatarStore(
                                                {
                                                    color: context.getContext(parameter).entityPath.entityType.getInheritedColor(),
                                                    icon: context.getContext(parameter).entityPath.entityType.getInheritedIcon()
                                                }),
                                                expansionView:
                                                    () =>
                                                        new ViewComponent(
                                                            Menu as any,
                                                            new MenuStore(
                                                            {
                                                                maxHeight: 250,
                                                                items:
                                                                    this.getMenuItems(
                                                                        context,
                                                                        onSelect,
                                                                        parameter),
                                                                onSelect: this.onSelectWrapper(onSelect, context, parameter)
                                                            }))
                                            })).sort(createStringComparator(a => a.name))
                    }
                :
                    {
                        maxHeight: 250,
                        items:
                            () =>
                                this.getMenuItems(
                                    context,
                                    onSelect,
                                    parameter),
                        onSelect:
                            () =>
                                this.onSelectWrapper(onSelect, context, parameter)
                    });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private getMenuItems(context: EntityContext,
                         onSelect: (path: EntityFieldPath, parameter?: string) => void,
                         parameter?: string): MenuItemStore[]
    {
        const entityType = context.getContext(parameter).entityPath.entityType;

        return entityType
            .getInheritedFieldGroups(this.entityTypeStore)
            .concat([
                new EntityFieldGroup(
                    entityType,
                    undefined,
                    () => this.localizationStore.translate('Generic.Uncategorized'))
            ])
            .sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
            .filter(group => group.fieldPaths(entityType, true, true).length > 0)               // Hide empty groups
            .map(group =>
                new MenuItemStore({
                    id: `FieldGroup.${group.id}`,
                    value: group,
                    name: group.name,
                    avatar: new AvatarStore({
                        icon: 'list',
                        color: mediumGrey}),
                    expansionView: () =>
                        new ViewComponent(
                            Menu as any,
                            new MenuStore({
                                maxHeight: 250,
                                items:
                                    this.entityTypeStore
                                        .getVisibleEntityFieldPaths(entityType, true)
                                        .filter(path => path.isInFieldGroup(group, this.entityTypeStore))
                                        .map(path =>
                                            this.getFieldPathItem(
                                                path,
                                                context,
                                                onSelect,
                                                parameter)),
                                onSelect: this.onSelectWrapper(
                                    onSelect,
                                    context,
                                    parameter)}))
                        }))
            .concat(
                entityType
                    .childTypes
                    .slice()
                    .sort((a, b) => a.nameSingular < b.nameSingular ? -1 : a.nameSingular === b.nameSingular ? 0 : 1)
                    .map(type =>
                        this.getChildEntityTypePathItem(
                            new EntityFieldPath(context.getContext(parameter).entityPath.castTo(type)),
                            context,
                            onSelect,
                            parameter)));
    }

    private onSelectWrapper(onSelect: (path: EntityFieldPath, parameter?: string) => void,
                            context: EntityContext,
                            parameter?: string)
    {
        return (store: MenuItemStore) =>
            {
                if (store.value instanceof EntityFieldPath)
                {
                    onSelect(
                        new EntityFieldPath(
                            context.getContext(parameter).entityPath.join(store.value.path),
                            store.value.field),
                        parameter);
                }
            };
    }

    private getChildEntityTypePathItem(fieldPath: EntityFieldPath,
                                       context: EntityContext,
                                       onSelect: (path: EntityFieldPath, parameter?: string) => void,
                                       parameter?: string): MenuItemStore
    {
        return new MenuItemStore({
            id: fieldPath.id,
            value: fieldPath,
            name: this.localizationStore.translate('EntityPath.Picker.If', fieldPath.path.entityType.nameSingular.toLowerCase()), // If {entitytype}
            avatar: new AvatarStore(
            {
                color: fieldPath.path.entityType.getInheritedColor(),
                icon: fieldPath.path.entityType.getInheritedIcon()
            }),
            actions:
                [
                    new ButtonStore(
                        {
                            icon: 'play_arrow',
                            label: () => this.localizationStore.translate('Generic.Select'), // select
                            onClick: () => onSelect(fieldPath, parameter)
                        })
                ],
            expansionView:
                (() =>
                    new ViewComponent(
                        Menu as any,
                        new EntityFieldPathPickerStore(
                            context.traverse(
                                EntityPath
                                    .root(context.getContext(parameter).entityPath.entityType)
                                    .castTo(fieldPath.path.entityType),
                                parameter),
                            onSelect,
                            parameter,
                            false)))
        });
    }

    private getFieldPathItem(fieldPath: EntityFieldPath,
                             context: EntityContext,
                             onSelect: (path: EntityFieldPath, parameter?: string) => void,
                             parameter?: string): MenuItemStore
    {
        const fieldPathContext =
            fieldPath.isRelationship
                ?
                    context.traverse(
                        EntityPath
                            .root(context.getContext(parameter).entityPath.entityType)
                            .joinTo(
                                fieldPath.path.lastJoinNode.relationshipDefinition,
                                fieldPath.path.lastJoinNode.isParent),
                        parameter)
                :
                    undefined;

        return new MenuItemStore({
            id: fieldPath.id,
            value: fieldPath,
            name: fieldPath.getName(
                this.entityTypeStore),
            avatar:
                fieldPath.isRelationship
                    ?
                        new AvatarStore(
                        {
                            color: fieldPath.path.lastJoinNode.isParent ? blue[500] : undefined,
                            icon: fieldPath.path.lastJoinNode.isParent ? 'arrow_upward' : 'arrow_downward'
                        })
                    :
                        new AvatarStore({
                            color: undefined,
                            icon: 'edit'
                        }),
            actions:
                fieldPath.isRelationship
                    ?
                        [
                            new ButtonStore(
                            {
                                icon: 'play_arrow',
                                label: () => this.localizationStore.translate('Generic.Select'), // select
                                onClick: () =>
                                {
                                    onSelect(
                                        fieldPathContext.getContext(parameter)
                                            .entityPath
                                            .field(fieldPath.field),
                                        parameter);
                                }
                            })
                        ]
                    :
                        [],
            expansionView:
                fieldPath.isRelationship
                    ?
                        (() =>
                            new ViewComponent(
                                Menu as any,
                                new EntityFieldPathPickerStore(
                                    fieldPathContext,
                                    onSelect,
                                    parameter,
                                    false)))
                    :
                        null
        });
    }
}
