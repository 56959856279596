import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { BaseEditorProps } from '../../../Editor/Editor';
import TimePicker from '../../../../../../@Future/Component/Generic/Input/TimePicker/TimePicker';
import { getHourAndMinuteFromValue } from './Api/getHourAndMinuteFromValue';
import { getTimeAsString } from './Api/getTimeAsString';

export interface TimeEditorProps extends BaseEditorProps
{

}

const Editor: React.FC<TimeEditorProps> =
    props =>
    {
        const [ hour, minute ] =
            useComputed(
                () =>
                    getHourAndMinuteFromValue(props.dataObject.value),
                [
                    props.dataObject
                ]
            );
        const onChange =
            useCallback(
                (hour?: number, minute?: number) =>
                    props.dataObject.setValue(
                        getTimeAsString(hour, minute)
                    ),
                [
                    props.onChange,
                ]);

        return <TimePicker
            hour={hour}
            minute={minute}
            onChange={onChange}
            inputProps={props.inputProps}
            disableUnderline={!props.underline}
            disabled={props.disabled}
        />;
    };

export default observer(Editor);
