import isEqual from 'lodash.isequal';
import { runInAction } from 'mobx';
import TransactionalContext from './TransactionalContext';
import { getBuilder } from './TransactionalBuilder';
import { getAdministration, isTransactionalModel } from '../Model/TransactionalModel';

export function equals(lhs: any, rhs: any): boolean
{
    return isEqual(lhs, rhs);
}

export function merge<T>(target: T,
                         source: T,
                         includeProperty: (property: string, value: any) => boolean = () => true)
{
    const targetKeys = new Set(Array.from(Object.keys(target)));
    const sourceKeys = new Set(Array.from(Object.keys(source)));

    runInAction(
        () =>
        {
            // Delete properties
            targetKeys.forEach(
                targetKey =>
                {
                    if (!sourceKeys.has(targetKey))
                    {
                        if (includeProperty(targetKey, undefined))
                        {
                            (target as any)[targetKey] = undefined;
                        }
                    }
                });

            // Update properties
            sourceKeys.forEach(
                sourceKey =>
                {
                    const value = (source as any)[sourceKey];

                    if (includeProperty(sourceKey, value))
                    {
                        (target as any)[sourceKey] = value;
                    }
                });
        });
}

export function isContextIncluded(context: TransactionalContext<any>)
{
    const baseValue = context.secondToLastValue;

    if (baseValue === undefined)
    {
        return true;
    } else
    {
        const builder = getBuilder(baseValue.constructor);

        if (builder)
        {
            return builder.isIncluded(context);
        } else
        {
            const baseTransactional = context.getSeenTransactional(baseValue);

            if (isTransactionalModel(baseTransactional))
            {
                return getAdministration(baseTransactional).configuration.includes(context);
            } else
            {
                return false;
            }
        }
    }
}

export function isContextDeep(context: TransactionalContext<any>)
{
    const baseValue = context.secondToLastValue;

    // console.log('second to last value',
    //     context.keyPath.join('.'),
    //     baseValue === undefined ? 'undefined' : baseValue.constructor.name,
    //     context.value === undefined ? 'undefined' : context.value.constructor.name);

    if (baseValue === undefined)
    {
        return true;
    } else
    {
        const builder = getBuilder(baseValue.constructor);

        // console.log('base value constructor', baseValue.constructor.name, builder !== undefined);

        if (builder)
        {
            return builder.isDeep(context);
        } else
        {
            const baseTransactional = context.getSeenTransactional(baseValue);

            if (isTransactionalModel(baseTransactional))
            {
                return getAdministration(baseTransactional).configuration.includes(context);
            } else
            {
                return false;
            }
        }
    }
}
