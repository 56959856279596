import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TableLayoutCellLocationEditorProps } from './TableLayoutCellLocationEditor';
import TableCell from '../../../../../../../@Api/Layout/Type/Table/Model/TableCell';
import ParameterDictionary from '../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import LayoutEditor from '../../../../Editor/LayoutEditor';
import Layout from '../../../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface TableLayoutCellEditorProps extends TableLayoutCellLocationEditorProps
{
    cell: TableCell;
}

const TableLayoutCellEditor: React.FC<TableLayoutCellEditorProps> =
    props =>
    {
        const {
            cell,
            parameterDictionary,
            rowSection,
            columnSection,
            parameterAssignment,
            childTable,
        } = props;
        const cellParameters =
            useMemo(
                () =>
                    ParameterDictionary.union(
                        parameterDictionary,
                        new ParameterDictionary(rowSection.getParameters()),
                        new ParameterDictionary(columnSection.getParameters()),
                        new ParameterDictionary(
                            childTable === undefined
                                ? []
                                : childTable.parentSection.getParameters()
                        )
                    ),
                [
                    parameterDictionary,
                    rowSection,
                    columnSection
                ]);
        const setLayout =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            cell.layout = layout),
                [
                    cell
                ]);

        return <LayoutEditor
            layout={cell.layout}
            parameterDictionary={cellParameters}
            parameterAssignment={parameterAssignment}
            onChange={setLayout}
        />;
    };

export default observer(TableLayoutCellEditor);
