import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import InputGroup from '../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { runInAction } from 'mobx';
import DimensionEditor from '../../Style/DimensionEditor/DimensionEditor';
import Dimension from '../../../../../@Api/Layout/Style/Dimension';
import ResourceImageLayout from '../../../../../@Api/Layout/Type/ResourceImageLayout';
import Selectbox from '../../../Entity/Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../Entity/Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../../Entity/Type/Api/useTypes';
import useEntityByUuid from '../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export interface ResourceImageLayoutControlsProps extends LayoutEditorProps<ResourceImageLayout>
{

}

const ResourceImageLayoutControls: React.FC<ResourceImageLayoutControlsProps> =
    props =>
    {
        const setWidth =
            useCallback(
                (width: Dimension) =>
                    runInAction(
                        () =>
                            props.layout.width = width),
                [
                    props.layout
                ]);

        const setHeight =
            useCallback(
                (height: Dimension) =>
                    runInAction(
                        () =>
                            props.layout.height = height),
                [
                    props.layout
                ]);

        const types = useTypes();

        const selections =
            useMemo(
                () =>
                    [
                        new EntitySelectionBuilder(types.Resource.Type)
                            .build()
                    ],
                [
                    types
                ]);

        const [ resource ] = useEntityByUuid(types.Resource.Type, props.layout.resourceId);
        const setResource =
            useCallback(
                (resource: Entity) =>
                    runInAction(
                        () =>
                        {
                            props.layout.resourceToken = resource?.getObjectValueByField(types.Resource.Field.Token);
                            props.layout.resourceId = resource?.uuid;
                        }),
                [
                    props.layout,
                    types
                ]);

        return <CardInset
            vertical={false}
        >
            <InputGroup>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Image"
                            value="Afbeelding"
                        />
                    }
                >
                    <Selectbox
                        selections={selections}
                        value={resource}
                        onChange={setResource}
                        disableUnderline={false}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Width"
                            value="Breedte"
                        />
                    }
                >
                    <DimensionEditor
                        value={props.layout.width}
                        onChange={setWidth}
                    />
                </Input>
                <Input
                    labelPosition="left"
                    label={
                        <LocalizedText
                            code="Generic.Height"
                            value="Hoogte"
                        />
                    }
                >
                    <DimensionEditor
                        value={props.layout.height}
                        onChange={setHeight}
                    />
                </Input>
            </InputGroup>
        </CardInset>;
    };

export default observer(ResourceImageLayoutControls);
