import * as React from 'react';
import { TextView } from '../TextView';
import { observer } from 'mobx-react';
import { TextViewerStore } from '../TextViewerStore';
import { DataObjectViewerStore } from '../../../Viewer/DataObjectViewerStore';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';
import { isValidUrl } from '../../../../../../@Util/Url/isValidUrl';
import openWindow from '../../../../../../@Util/Window/openWindow';

@observer
export class UrlView extends TextView
{
    renderComponent(store: DataObjectViewerStore<TextViewerStore>): any
    {
        return <Link
            onClick={
                () =>
                {
                    if (isValidUrl(store.dataObject.value))
                    {
                        openWindow(
                            store.dataObject.value,
                            '_blank'
                        );
                    }
                }
            }
        />;
    }
}
