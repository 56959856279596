import { WeeklyRecurrencePattern } from '../../../Model/RecurrencePattern';
import { Frequency, Options } from 'rrule';
import { getRRuleWeekDay } from '../Util/getRRuleWeekDay';

export function getWeeklyRecurrencePatternAsRRule(
    pattern: WeeklyRecurrencePattern
): Partial<Options>
{
    return {
        freq: Frequency.WEEKLY,
        interval: pattern.interval ?? 1,
        wkst: getRRuleWeekDay(pattern.firstDayOfWeek),
        byweekday:
            pattern.daysOfWeek.map(
                dayOfWeek =>
                    getRRuleWeekDay(dayOfWeek)
            )
    };
}
