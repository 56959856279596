import React from 'react';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import { RecurrencePatternEnd } from '../../Model/End/RecurrencePatternEnd';
import { RecurrencePatternEndTypeEditor } from './TypeSelector/RecurrencePatternEndTypeEditor';
import { DateRecurrencePatternEndEditor } from './Type/Date/DateRecurrencePatternEndEditor';
import { NumberedRecurrencePatternEndEditor } from './Type/Numbered/NumberedRecurrencePatternEndEditor';

export interface RecurrencePatternEndEditorProps
{
    value: RecurrencePatternEnd;
    onChange: (value: RecurrencePatternEnd) => void;
    inset?: boolean;
}

export const RecurrencePatternEndEditor: React.FC<RecurrencePatternEndEditorProps> =
    ({
        value,
        onChange,
        inset = false,
     }) =>
    {
        return <CardInset
            top={false}
            horizontal={inset}
            vertical={inset}
        >
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <RecurrencePatternEndTypeEditor
                        value={value}
                        onChange={onChange}
                    />
                </ViewGroupItem>
                {
                    value.type !== 'No' &&
                    <ViewGroupItem>
                        {
                            value.type === 'Date' &&
                            <DateRecurrencePatternEndEditor
                                value={value}
                                onChange={onChange}
                            />
                        }
                        {
                            value.type === 'Numbered' &&
                            <NumberedRecurrencePatternEndEditor
                                value={value}
                                onChange={onChange}
                            />
                        }
                    </ViewGroupItem>
                }
            </ViewGroup>
        </CardInset>;
    };
