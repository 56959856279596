import React, { useCallback } from 'react';
import FileDropZone from '../FileDropZone/FileDropZone';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import SelectBox from '../../../../../@Component/Domain/Entity/Selectbox/Selectbox';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import InputGroup from '../InputGroup/InputGroup';
import { default as GenericInput } from '../../../../../@Future/Component/Generic/Input/Input/Input';
import { EntitySelectionBuilder } from '../../../../../@Component/Domain/Entity/Selection/Builder/EntitySelectionBuilder';
import useTypes from '../../../../../@Component/Domain/Entity/Type/Api/useTypes';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import { CommitContext } from '../../../../../@Api/Entity/Commit/Context/CommitContext';
import { FileValue } from '../../../../../@Component/Domain/DataObject/Type/File/FileValue';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface FileDropZoneWithLibraryProps {
    onChangeFiles: (files: File[]) => void;
    onAddFile: (file: FileValue) => void;
    entity?: Entity;
    commitContext?: CommitContext
}

const useStyles =
    makeStyles(
        {
            root:
                {
                    marginTop: '10px',
                    marginBottom: '10px'
                },
        }
    );

const FileDropZoneWithLibrary: React.FC<FileDropZoneWithLibraryProps> =
    props =>
    {
        const { onChangeFiles, onAddFile, commitContext } = props;
        const types = useTypes();
        const classes = useStyles();

        const librarySelections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(types.Resource.Type)
                        .selection
                ],
                [
                    types
                ]);

        const onAddResourceOrAttachment =
            useCallback(
                (resource) =>
                {
                    const fileField =
                        resource.entityType.isA(types.Resource.Type)
                            ? types.Resource.Field.File
                            : types.Attachment.Field.File

                    const file = resource.getObjectValueByField(fileField, commitContext);
                    onAddFile(file);
                },
                [
                    types,
                    commitContext,
                    onAddFile
                ]
            );

        const attachmentSelections =
            useComputed(
                () =>
                    props.entity.entityType.bespoke.getAttachmentSelections(
                        props.entity,
                        commitContext
                    ),
                [
                    props.entity,
                    commitContext
                ]
            );

        return <div
            className={classes.root}
        >
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <FileDropZone
                        onDrop={onChangeFiles}
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <InputGroup>
                        <GenericInput
                            label={
                                <LocalizedText
                                    code="Generic.FromLibrary"
                                    value="Uit bibliotheek"
                                />
                            }
                            labelPosition="left"
                        >
                            <SelectBox
                                selections={librarySelections}
                                onChange={onAddResourceOrAttachment}
                            />
                        </GenericInput>
                    </InputGroup>
                </ViewGroupItem>
                {
                    attachmentSelections && attachmentSelections.length > 0 &&
                    <ViewGroupItem>
                        <InputGroup>
                            <GenericInput
                                label={
                                    <LocalizedText
                                        code="FileDropZone.RelatedAttachments"
                                        value="Gerelateerde attachments"
                                    />
                                }
                                labelPosition="left"
                            >
                                <SelectBox
                                    selections={attachmentSelections}
                                    onChange={onAddResourceOrAttachment}
                                    disableConstruction
                                />
                            </GenericInput>
                        </InputGroup>
                    </ViewGroupItem>
                }
            </ViewGroup>
        </div>
    }

export default observer(FileDropZoneWithLibrary);