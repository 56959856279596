import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { ButtonStore } from '../../../../../Generic/Button/ButtonStore';
import Button from '../../../../../Generic/Button/Button';
import useIsRead from '../Api/useIsRead';
import useMarkAsRead from '../Api/useMarkAsRead';

export interface ReadStateButtonProps
{
    notification: Entity;
}

const ReadStateButton: React.FC<ReadStateButtonProps> =
    props =>
    {
        const isRead = useIsRead(props.notification);
        const markAsRead = useMarkAsRead();

        const buttonStore = useComputed(
            () =>
                new ButtonStore({
                    icon:
                        isRead
                            ? 'album'
                            : 'fiber_manual_record',
                    onClick:
                        () =>
                            markAsRead(
                                props.notification,
                                !isRead),
                    tooltip:
                        () =>
                            isRead
                                ? 'Markeer als ongelezen'
                                : 'Markeer als gelezen',
                }),
            [
                props.notification,
                isRead,
                markAsRead
            ]);

        if (buttonStore)
        {
            return <Button store={buttonStore} />;
        }
        else
        {
            return null;
        }
    };

export default observer(ReadStateButton);
