import React, { useCallback } from 'react';
import TypeMapping from '../../../Model/TypeMapping';
import { default as KeyModel } from '../../../Model/Key';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import FieldPathSelector from '../../../../Path/FieldPathEditor/FieldPathSelector';
import MenuButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import { EntityFieldPath } from '../../../../Path/@Model/EntityFieldPath';
import { IObservableArray, runInAction } from 'mobx';
import styles from './Key.module.scss';
import { observer, useComputed } from 'mobx-react-lite';
import IconButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { EntityContext } from '../../../../@Model/EntityContext';
import KeyField from '../../../Model/KeyField';
import uuid from '../../../../../../../@Util/Id/uuid';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';

export interface KeyProps
{
    typeMapping: TypeMapping;
    keyModel: KeyModel;
    idx: number;
}

const Key: React.FC<KeyProps> =
    props =>
    {
        const deleteKey =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.typeMapping.keys as IObservableArray).remove(props.keyModel)),
                [
                    props.typeMapping,
                    props.keyModel
                ]);

        const addField =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    runInAction(
                        () =>
                        {
                            if (!props.keyModel.fields.some(field => field.fieldPath.id === fieldPath.id))
                            {
                                props.keyModel.fields.push(
                                    new KeyField(
                                        uuid(),
                                        fieldPath,
                                        true));
                            }
                        }),
                [
                    props.keyModel
                ]);

        const deleteField =
            useCallback(
                (field: KeyField) =>
                    runInAction(
                        () =>
                            (props.keyModel.fields as IObservableArray).remove(field)),
                [
                    props.keyModel
                ]);

        const context =
            useComputed(
                () =>
                    EntityContext.fromEntityType(props.typeMapping.entityType),
                [
                    props.typeMapping
                ]);

        return <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        alignment="center"
                    >
                        {
                            props.idx === 0 &&
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="Import.SearchForExistingEntitiesByFieldCombination1"
                                        value="${index}. Zoek naar bestaande ${type} op basis van de volgende veldencombinatie:"
                                        index={props.idx + 1}
                                        type={props.typeMapping.entityType.getName(true).toLowerCase()}
                                    />
                                </ViewGroupItem>
                        }
                        {
                            props.idx > 0 &&
                                <ViewGroupItem>
                                    <LocalizedText
                                        code="Import.SearchForExistingEntitiesByFieldCombination2"
                                        value="${index}. óf op basis van de volgende veldencombinatie:"
                                        index={props.idx + 1}
                                    />
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <IconButton
                                icon="close"
                                tooltip={
                                    <LocalizedText
                                        code="Import.DeleteFieldCombination"
                                        value="Veldencombinatie verwijderen"
                                    />
                                }
                                small
                                onClick={deleteKey}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                <ViewGroupItem>
                    <div
                        className={styles.fieldContainer}
                    >
                        <ViewGroup
                            orientation="vertical"
                            spacing={5}
                        >
                            {
                                props.keyModel.fields.map(
                                    (field, idx) =>
                                        <ViewGroupItem
                                            key={field.id}
                                        >
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={5}
                                                alignment="center"
                                            >
                                                <ViewGroupItem>
                                                    {idx + 1}. {field.fieldPath.getName()}
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <IconButton
                                                        icon="close"
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Import.DeleteField"
                                                                value="Veld verwijderen"
                                                            />
                                                        }
                                                        small
                                                        onClick={() => deleteField(field)}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>)
                            }
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="horizontal"
                                    spacing={5}
                                    alignment="center"
                                >
                                    <ViewGroupItem>
                                        {props.keyModel.fields.length + 1}.
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <MenuButton
                                            key={props.keyModel.fields.length}
                                            icon="add"
                                            tooltip={
                                                <LocalizedText
                                                    code="Import.AddField"
                                                    value="Veld toevoegen"
                                                />
                                            }
                                            small
                                        >
                                            <FieldPathSelector
                                                context={context}
                                                onSelect={addField}
                                            />
                                        </MenuButton>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </div>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>;
    };

export default observer(Key);
