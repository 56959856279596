import * as React from 'react';
import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ChartStore } from '../ChartStore';
import { Theme, withStyles } from '@material-ui/core';
import { Bar, BarChart as ReBarChart, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ColorUtils } from '../../../../@Util/Color/ColorUtils';
import { ChartLabel } from './ChartLabel';
import { DataObject } from '../../../Domain/DataObject/Model/DataObject';
import { DataObjectRepresentation } from '../../../Domain/DataObject/Model/DataObjectRepresentation';

const styles =
    (theme: Theme) =>
        ({

        });

@observer
class BarChart extends BaseComponent<ChartStore>
{
    renderComponent(store: ChartStore): JSX.Element
    {
        const chartData =
            store.data
                .map(point =>
                {
                    if (point.segmentations.length > 0)
                    {
                        return point.segmentations
                            .map(
                                segmentation =>
                                {
                                    const descriptor: any =
                                    {
                                        name: point.name
                                    };

                                    segmentation.points
                                        .forEach(
                                            segment =>
                                            {
                                                (descriptor as any)[segment.name] = segment.value.value;
                                            });

                                    return descriptor;
                                });
                    }
                    else
                    {
                        return [
                            {
                                name: point.name,
                                value: point.value.value
                            }
                        ];
                    }
                })
                .reduce((a, b) => a.concat(b), []);

        return <ResponsiveContainer
            width="100%"
        >
            <ReBarChart
                data={chartData}
                margin={{
                    left: 25,
                    bottom: 30
                }}
                onClick={
                    (data: any) =>
                    {
                        if (data)
                        {
                            store.toggleIndexSelection(data.activeTooltipIndex);
                        }
                    }}
            >
                <XAxis
                    dataKey="name"
                    height={60}
                    tick={<ChartLabel />}
                    interval={0}
                />
                <YAxis
                    tickFormatter={
                        e =>
                        {
                            if (store.data.length === 0)
                            {
                                return e;
                            }
                            else
                            {
                                return DataObject.constructFromTypeAndValue(
                                    store.data[0].value.specification.type,
                                    e)
                                    .toString(
                                        new DataObjectRepresentation({ isCompact: true }))
                                    .replace(/ /g, '');
                            }
                        }
                    }
                />
                <Tooltip />
                {
                    store.segmentGroups.size > 0
                        ?
                            Array.from(store.segmentGroups)
                                .map(
                                    (segmentGroup, idx) =>
                                        <Bar
                                            key={segmentGroup ? segmentGroup.toString() : ''}
                                            dataKey={segmentGroup ? segmentGroup.toString() : store.localizationStore.translate('Generic.NoValue')} // No value}
                                            stackId="a"
                                            fill={ColorUtils.color(idx)[500]}
                                        >
                                            {
                                                Array.from(store.data.keys())
                                                    .map((entry, idx2) =>
                                                        <Cell
                                                            key={idx2}
                                                            cursor="pointer"
                                                            fill={idx2 === store.selectedIndex ? ColorUtils.color(idx)[100] : ColorUtils.color(idx)[500]}
                                                        />)
                                            }
                                        </Bar>)
                        :
                            <Bar
                                dataKey="value"
                            >
                                {
                                    Array.from(store.data.keys())
                                        .map((entry, idx) =>
                                            <Cell
                                                key={idx}
                                                cursor="pointer"
                                                fill={idx === store.selectedIndex ? store.selectedColor : store.color}
                                            />)
                                }
                            </Bar>
                }
                {
                    store.segmentGroups.size > 0 &&
                        <Legend
                            verticalAlign="top"
                        />
                }
            </ReBarChart>
        </ResponsiveContainer>;
    }
}

export default withStyles(styles)(BarChart as any);
