import { EntityContext } from '../../@Model/EntityContext';
import { MenuStore } from '../../../../Generic/Menu/MenuStore';
import { ViewComponent } from '../../../../Generic/ViewStack/Model/ViewComponent';
import Menu from '../../../../Generic/Menu/Menu';
import { MenuItemStore } from '../../../../Generic/Menu/MenuItemStore';
import { EntityPath } from '../@Model/EntityPath';
import { blue } from '../../../../../@Resource/Theme/Blue';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import { AvatarStore } from '../../../../Generic/Avatar/AvatarStore';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { EntityFieldGroup } from '../../../Management/Application/EntityTypesEditor/EntityFieldGroup';
import { mediumGrey } from '../../../../../@Resource/Theme/Theme';
import { EntityTypeStore } from '../../Type/EntityTypeStore';

export class EntityPathPickerStore extends MenuStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(context: EntityContext,
                onSelect: (path: EntityPath, parameter?: string) => void,
                parameter?: string)
    {
        super(
            context.getContext(parameter).contextByParameter.size > 0
                ?
                    {
                        maxHeight: 250,
                        items:
                            [ ...Array.from(context.contextByParameter.keys()), null ]
                                .map(
                                    parameter =>
                                        new MenuItemStore(
                                        {
                                            id: parameter || 'context',
                                            name: context.getContext(parameter).entityPath.name(this.entityTypeStore),
                                            avatar: new AvatarStore(
                                            {
                                                color: context.getContext(parameter).entityPath.entityType.getInheritedColor(),
                                                icon: context.getContext(parameter).entityPath.entityType.getInheritedIcon()
                                            }),
                                            expansionView:
                                                () =>
                                                    new ViewComponent(
                                                        Menu as any,
                                                        new MenuStore(
                                                        {
                                                            maxHeight: 250,
                                                            items:
                                                                this.getMenuItems(
                                                                    context,
                                                                    onSelect,
                                                                    parameter),
                                                            onSelect: this.onSelectWrapper(onSelect, context, parameter)
                                                        }))
                                        }))
                                .slice()
                                .sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
                    }
                :
                    {
                        maxHeight: 250,
                        items:
                            () =>
                                this.getMenuItems(
                                    context,
                                    onSelect,
                                    parameter),
                        onSelect:
                            () =>
                                this.onSelectWrapper(onSelect, context, parameter)
                    });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private getMenuItems(context: EntityContext,
                         onSelect: (path: EntityPath, parameter?: string) => void,
                         parameter?: string): MenuItemStore[]
    {
        const entityType = context.getContext(parameter).entityPath.entityType;

        return entityType
            .getInheritedFieldGroups(this.entityTypeStore)
            .concat([
                new EntityFieldGroup(
                    entityType,
                    undefined,
                    () => this.localizationStore.translate('Generic.Uncategorized'))
            ])
            .sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
            .filter(group => group.entityPaths(entityType).length > 0)               // Hide empty groups
            .map(group =>
                new MenuItemStore({
                    id: `FieldGroup.${group.id}`,
                    value: group,
                    name: group.name,
                    avatar: new AvatarStore({
                        icon: 'list',
                        color: mediumGrey}),
                    expansionView: () =>
                        new ViewComponent(
                            Menu as any,
                            new MenuStore({
                                maxHeight: 250,
                                items:
                                    this.entityTypeStore
                                        .getVisibleEntityPaths(entityType, true)
                                        .filter(path => path.isInFieldGroup(group, this.entityTypeStore))
                                        .map(path =>
                                            this.getEntityPathItem(
                                                path,
                                                context,
                                                onSelect,
                                                parameter)),
                                onSelect: this.onSelectWrapper(
                                    onSelect,
                                    context,
                                    parameter)}))
                        }))
            .concat(
                entityType
                    .childTypes
                    .slice()
                    .sort((a, b) => a.nameSingular < b.nameSingular ? -1 : a.nameSingular === b.nameSingular ? 0 : 1)
                    .map(type =>
                        this.getChildEntityTypePathItem(
                            context.getContext(parameter).entityPath.castTo(type),
                            context,
                            onSelect,
                            parameter)));
    }

    private onSelectWrapper(onSelect: (path: EntityPath, parameter?: string) => void,
                            context: EntityContext,
                            parameter?: string)
    {
        return (store: MenuItemStore) =>
            {
                if (store.value instanceof EntityPath)
                {
                    onSelect(
                        context.getContext(parameter).entityPath.join(store.value),
                        parameter);
                }
            };
    }

    private getChildEntityTypePathItem(entityPath: EntityPath,
                                       context: EntityContext,
                                       onSelect: (path: EntityPath, parameter?: string) => void,
                                       parameter?: string): MenuItemStore
    {
        return new MenuItemStore({
            id: entityPath.id,
            value: entityPath,
            name: this.localizationStore.translate('EntityPath.Picker.If', entityPath.entityType.nameSingular.toLowerCase()), // If {entitytype}
            avatar: new AvatarStore(
            {
                color: entityPath.entityType.getInheritedColor(),
                icon: entityPath.entityType.getInheritedIcon()
            }),
            actions:
                [
                    new ButtonStore(
                        {
                            icon: 'play_arrow',
                            label: () => this.localizationStore.translate('Generic.Select'), // select
                            onClick: () => onSelect(entityPath, parameter)
                        })
                ],
            expansionView:
                (() =>
                    new ViewComponent(
                        Menu as any,
                        new EntityPathPickerStore(
                            context.traverse(
                                EntityPath
                                    .root(context.getContext(parameter).entityPath.entityType)
                                    .castTo(entityPath.entityType),
                                parameter),
                            onSelect,
                            parameter)))
        });
}

    private getEntityPathItem(entityPath: EntityPath,
                              context: EntityContext,
                              onSelect: (path: EntityPath, parameter?: string) => void,
                              parameter?: string): MenuItemStore
    {
        return new MenuItemStore({
            id: entityPath.id,
            value: entityPath,
            name: entityPath.name(this.entityTypeStore),
            avatar:
                new AvatarStore({
                    color: entityPath.lastJoinNode.isParent ? blue[500] : undefined,
                    icon: entityPath.lastJoinNode.isParent ? 'arrow_upward' : 'arrow_downward'
                }),
            actions:
                [
                    new ButtonStore(
                    {
                        icon: 'play_arrow',
                        label: () => this.localizationStore.translate('Generic.Select'), // select
                        onClick: () => onSelect(entityPath, parameter)
                    })
                ],
            expansionView:
                () =>
                    new ViewComponent(
                        Menu as any,
                        new EntityPathPickerStore(
                            context.traverse(
                                EntityPath
                                    .root(context.getContext(parameter).entityPath.entityType)
                                    .joinTo(
                                        entityPath.lastJoinNode.relationshipDefinition,
                                        entityPath.lastJoinNode.isParent),
                                parameter),
                            onSelect,
                            parameter))
        });
    }
}
