import Action from '../Function/Action/Action';
import AutomationDependencyContext from '../AutomationDependencyContext';
import CompositeAction from '../Function/Action/CompositeAction';
import ConditionalAction from '../Function/Action/ConditionalAction';
import CreateEntityAction from '../Function/Action/CreateEntityAction';
import UpdateEntityAction from '../Function/Action/UpdateEntityAction';
import DynamicAction from '../Function/Action/DynamicAction';
import ForEachAction from '../Function/Action/ForEachAction';
import DeleteEntityAction from '../Function/Action/DeleteEntityAction';
import ExceptionAction from '../Function/Action/ExceptionAction';
import InvocationAction from '../Function/Action/InvocationAction';
import CreateRelationshipAction from '../Function/Action/CreateRelationshipAction';
import DeleteRelationshipAction from '../Function/Action/DeleteRelationshipAction';
import FtpUploadAction from '../Function/Action/FtpUploadAction';
import GenerateNumberInEntityAction from '../Function/Action/GenerateNumberInEntityAction';

export default async function getActionFromDescriptor(
    descriptor: any,
    dependencyContext: AutomationDependencyContext
): Promise<Action<any, any>>
{
    switch (descriptor.type)
    {
        case 'Composite':
            return CompositeAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'ForEach':
            return ForEachAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Conditional':
            return ConditionalAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'CreateEntity':
            return CreateEntityAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'UpdateEntity':
            return UpdateEntityAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'DeleteEntity':
            return DeleteEntityAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'CreateRelationship':
            return CreateRelationshipAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'DeleteRelationship':
            return DeleteRelationshipAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Dynamic':
            return DynamicAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Invocation':
            return InvocationAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'FtpUpload':
            return FtpUploadAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'Exception':
            return ExceptionAction.fromDescriptor(
                descriptor,
                dependencyContext
            );

        case 'GenerateNumberInEntity':
            return GenerateNumberInEntityAction.fromDescriptor(
                descriptor,
                dependencyContext
            );
    }
}
