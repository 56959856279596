import { DataObject } from './DataObject';

export interface DataObjectRepresentationProps
{
    isCompact?: boolean;
    granularity?: 'high' | 'low'
    isOpenByDefault?: boolean;
    parentInterval?: DataObject;
    interval?: DataObject;
    range?: DataObject;
    placeholder?: string;
    hasTime?: boolean;
    key?: string;
    fitContent?: boolean;
    disableThousandSeparator?: boolean;
    currency?: string;
}

export class DataObjectRepresentation<T extends DataObjectRepresentationProps = DataObjectRepresentationProps>
{
    // ------------------------- Properties -------------------------

    data: T;

    // ------------------------ Constructor -------------------------

    constructor(data: T)
    {
        this.data = data;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
