import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import PrimitiveValueType from '../../Value/Type/PrimitiveValueType';
import PrimitiveValue from '../../Value/PrimitiveValue';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../../@Component/Domain/DataObject/DataObjectStore';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import Value from '../../Value/Value';
import { DateType } from '../../../../@Component/Domain/DataObject/Type/Date/DateType';
import { DateTimeType } from '../../../../@Component/Domain/DataObject/Type/Date/DateTime/DateTimeType';
import { DataObject } from '../../../../@Component/Domain/DataObject/Model/DataObject';
import EmptyValue from '../../Value/EmptyValue';
import { startOfDay } from 'date-fns';
import localizeText from '../../../Localization/localizeText';

export default class StartOfDayComputation extends Computation<PrimitiveValueType, PrimitiveValue>
{
    // ------------------------- Properties -------------------------

    @observable.ref date: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(date: Computation<any, any>)
    {
        super();

        this.date = date;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return new PrimitiveValueType(
            loadModuleDirectly(DataObjectStore)
                .getTypeById('DateTime'));
    }

    isAsync(): boolean
    {
        return this.date.isAsync();
    }

    async apply(context: FunctionContext): Promise<PrimitiveValue>
    {
        const date = await safelyApplyFunction(this.date, context);

        return this.getStartOfDayValueOfDateValue(date);
    }

    synchronousApply(context: FunctionContext): PrimitiveValue
    {
        const date = safelySynchronousApplyFunction(this.date, context);

        return this.getStartOfDayValueOfDateValue(date);
    }

    private getStartOfDayValueOfDateValue(value: Value<any, any>): Value<any, any>
    {
        if (value instanceof PrimitiveValue
            && (value.getType().type instanceof DateType || value.getType().type instanceof DateTimeType))
        {
            return new PrimitiveValue(
                DataObject.constructFromTypeIdAndValue(
                    'DateTime',
                    this.getStartOfDayOfDate(value.value.value)));
        }
        else
        {
            return EmptyValue.instance;
        }
    }

    private getStartOfDayOfDate(date: Date): Date
    {
        return startOfDay(date);
    }

    getName(): string
    {
        return localizeText(
            'StartOfDay',
            'Start van dag...'
        );
    }

    validate(): Validation[]
    {
        if (!this.date)
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        'NoDateSet',
                        'Geen datum ingesteld'
                    )
                )
            ];
        }

        return this.date.validate();
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'StartOfDay';
        descriptor.date = this.date.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.date.getDependencies();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new StartOfDayComputation(
            await getComputationFromDescriptor(
                descriptor.date,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
