import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Input from '../../../../../../../../../Input/Input';
import { ContentProps } from '../Content';
import getNameFieldByType from '../../../../../../../../../../../../@Api/Metadata/Field/getNameFieldByType';

export interface TypeNameProps extends ContentProps
{
    disabled?: boolean;
}

const TypeName: React.FC<TypeNameProps> =
    props =>
    {
        const nameInput =
            useMemo(
                () =>
                    getNameFieldByType(props.entity.entityType),
                [
                    props.entity
                ]);

        const representation =
            useMemo(
                () => ({
                    fitContent: true
                }),
                [

                ]);

        if (nameInput)
        {
            return <Input
                entity={props.entity}
                field={nameInput}
                labelPosition={props.labelPosition}
                inline={props.inlineLabel}
                representation={representation}
                disabled={props.disabled}
                commitContext={props.commitContext}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(TypeName);
