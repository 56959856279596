import { TextProps, TextStore } from '../../../Generic/Text/TextStore';
import { getOrCompute, PropType } from '../../../../@Framework/Store/BaseStore';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { injectWithQualifier } from '../../../../@Util/DependencyInjection/index';
import { computed } from 'mobx';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';

export interface EntityCaptionButtonTextProps extends TextProps
{
    entity: PropType<EntityCaptionLinkStore, EntityCaptionButtonTextProps, Entity>;
    onVisit?: (entity: Entity) => Promise<any>;

}

const defaultProps: Partial<EntityCaptionButtonTextProps> =
{

};

export class EntityCaptionLinkStore extends TextStore<EntityCaptionButtonTextProps>
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(props: EntityCaptionButtonTextProps)
    {
        super({
            // viewComponent:
            //     () =>
            //         props.onVisit
            //             ?
            //                 new ViewComponent(
            //                     Button as any,
            //                     new ButtonStore({
            //                         renderAsChip: true,
            //                         size: 'small',
            //                         outlined: true,
            //                         icon: () => this.contextType.getInheritedIcon(),
            //                         color: () => this.contextType.getInheritedColor(),
            //                         label: () => this.entity.name,
            //                         onClick:
            //                             props.onVisit
            //                                 ?
            //                                     () => this.props.onVisit(this.entity)
            //                                 :
            //                                     undefined
            //                     }))
            //             :
            //                 undefined,
            label: () => this.entity.name,
            onClick:
                props.onVisit
                    ?
                        () => this.props.onVisit(this.entity)
                    :
                        undefined,
            isInline: true,
            ...props,
            color:
                () =>
                    'primary'
        }, defaultProps);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get entity(): Entity
    {
        return getOrCompute(this, this.props.entity);
    }

    @computed
    get contextType(): EntityType
    {
        if (this.entity.entityType.isA(this.entityTypeStore.bespoke.types.Relationship.Type))
        {
            return this.entityTypeStore.bespoke.types.Relationship.Function.GetRelationType(this.entity);
        }
        else
        {
            return this.entity.entityType;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------=
}
