import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import { DataObject } from '../../../../../../../../DataObject/Model/DataObject';
import PeriodBetweenDatesComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/PeriodBetweenDatesComputation';
import { getDatePeriodTypeOptions } from '../../../../../../../../DataObject/Type/Date/DatePeriod/DatePeriodTypeOptions';
import StaticSelectbox from '../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { DatePeriodType } from '../../../../../../../../DataObject/Type/Date/DatePeriod/DatePeriodValue';

export interface PeriodBetweenDatesComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, PeriodBetweenDatesComputation>
{

}

const PeriodBetweenDatesComputationEditor: React.FC<PeriodBetweenDatesComputationEditorProps> =
    props =>
    {
        const dateTimeType =
            useMemo(
                () =>
                    new PrimitiveValueType(
                        DataObject.getTypeById('DateTime')
                    ),
                []
            );
        const setStartDate =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.startDate = value
                    ),
                [
                    props.value
                ]
            );
        const setEndDate =
            useCallback(
                (value?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.endDate = value
                    ),
                [
                    props.value
                ]
            );
        const setPeriodType =
            useCallback(
                (value?: DatePeriodType) =>
                    runInAction(
                        () =>
                            props.value.periodType = value ?? 'Days'
                    ),
                [
                    props.value
                ]
            );

        const datePeriodTypeOptions =
            useMemo(
                () =>
                    getDatePeriodTypeOptions(),
                []
            );

        return <InputGroup>
            <Input
                label={
                    <LocalizedText
                        code="Generic.StartDate"
                        value="Startdatum"
                    />
                }
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={props.context}
                        value={props.value.startDate}
                        onChange={setStartDate}
                        type={dateTimeType}
                    />
                </ComputationBox>
            </Input>
            <Input
                label={
                    <LocalizedText
                        code="Generic.EndDate"
                        value="Einddatum"
                    />
                }
                labelPosition="left"
            >
                <ComputationBox>
                    <ComputationEditor
                        context={props.context}
                        value={props.value.endDate}
                        onChange={setEndDate}
                        type={dateTimeType}
                    />
                </ComputationBox>
            </Input>
            <Input
                label={
                    <LocalizedText
                        code="Generic.Type"
                        value="Type"
                    />
                }
                labelPosition="left"
            >
                <StaticSelectbox
                    options={datePeriodTypeOptions}
                    value={props.value.periodType}
                    onChange={setPeriodType}
                />
            </Input>
        </InputGroup>;
    };

export default observer(PeriodBetweenDatesComputationEditor);
