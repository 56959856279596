import * as React from 'react';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { observer } from 'mobx-react';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import { action } from 'mobx';
import getDateIntervalOptions from './Api/getDateIntervalOptions';
import { intervalValueFromIntervalType } from './DateIntervalValue';

@observer
export class DateIntervalEditor extends DataObjectBespokeEditor
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    get options(): any[] {
        return getDateIntervalOptions()
    };

    convertValue(type: string)
    {
        return intervalValueFromIntervalType(type);
    }

    getTypeFromValue(value?: any)
    {
        if (value?.type === 'Yearly' && value?.frequency === 2)
        {
            return 'TwoYearly';
        }
        else if (value?.type === 'Monthly' && value?.frequency === 2)
        {
            return 'TwoMonthly';
        }
        else if (value?.type === 'Monthly' && value?.frequency === 4)
        {
            return 'FourMonthly'
        }
        else if (value?.type === 'Monthly' && value?.frequency === 6)
        {
            return 'HalfYearly'
        }
        else if (value?.type === 'Weekly' && value?.frequency === 4)
        {
            return 'FourWeekly'
        }
        else
        {
            return value?.type;
        }
    }

    @action.bound
    onChange(type?: string)
    {
        const newValue = type ? this.convertValue(type) : undefined;
        this.store.changeValue(newValue);
    }

    renderComponent(store: DataObjectEditorStore)
    {
        return <StaticSelectbox
            {...store.handlerProperties as any}
            options={this.options}
            value={this.getTypeFromValue(store.dataObject.value)}
            onChange={this.onChange}
            clearable
        />;
    }
}
