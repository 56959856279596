import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../../../../Type/Api/useTypes';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '../../../../../../../../Input/Input';
import MenuButton from '../../../../../../../../Item/MenuButton/MenuButton';
import Checkbox from '../../../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import { computed } from 'mobx';
import { EntityExpansionBuilder } from '../../../../../../../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../../../../../../Path/@Model/EntityPath';
import Planner from '../../../../../../../../Planner/Planner';
import useClock from '../../../../../../../../../../../@Util/Date/useClock';
import useEntityValue from '../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import moment from 'moment';
import getDurationLabel from '../../../../../../../../../../../@Util/Date/getDurationLabel';
import ButtonGroup from '../../../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import IconButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import useEndTimeRegistration from '../../../../../../../../Timetracker/Hooks/useEndTimeRegistration';
import useToggle from '../../../../../../../../../../../@Util/Toggle/useToggle';
import TimeRegistrationProductLinesEditor from '../../../../../../../../ResourcePlanner/TimeSheetDialog/Item/TimeRegistrationProductLinesEditor/TimeRegistrationProductLinesEditor';
import NoteIconButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/NoteIconButton/NoteIconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../../../@Resource/Theme/Theme';
import LocalizedText from '../../../../../../../../../Localization/LocalizedText/LocalizedText';
import { default as GenericInput } from '../../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import CurrentUserContext from '../../../../../../../../../User/CurrentUserContext';
import useSetting from '../../../../../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../../../../../@Api/Settings/Setting';
import EntityLayout from '../../../../../../../../Layout/EntityLayout';
import EntityLink from '../../../../../../../../Link/EntityLink';
import { CommitContext } from '../../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import TimeRegistrationMileageRegistrationsEditor from '../../../../../../../../ResourcePlanner/TimeSheetDialog/Item/TimeRegistrationMileageRegistrationsEditor/TimeRegistrationMileageRegistrationsEditor';

export interface TimeRegistrationEditorProps
{
    entity: Entity;
    onSelect?: (entity: Entity, isSelected: boolean) => void;
    selected?: boolean;
    milestone?: Entity;
    showActivity?: boolean;
    showBillingActivity?: boolean;
    canHaveMilestone?: boolean;
    onDelete?: () => void;
    commitContext: CommitContext;
}

const TimeRegistration: React.FC<TimeRegistrationEditorProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const types = useTypes();

        const isProcessed =
            useEntityValue(
                props.entity,
                types.TimeRegistration.Field.IsProcessed,
                undefined,
                props.commitContext
            );
        const isBilled =
            useComputed(
                () =>
                    props.entity.hasRelationshipsByDefinition(
                        true,
                        types.ProductLine.RelationshipDefinition.BilledTimeRegistrations,
                        props.commitContext
                    ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);
        const onSelect =
            useCallback(
                () =>
                    props.onSelect(props.entity, !props.selected),
                [
                    props.onSelect,
                    props.entity,
                    props.selected
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            types.TimeRegistration.RelationshipDefinition.Activity,
                            props.commitContext
                        )
                )
                    .observe(
                        change =>
                        {
                            if (change.newValue)
                            {
                                new EntityExpansionBuilder(
                                    change.newValue.entityType,
                                    [
                                        change.newValue
                                    ],
                                    [
                                        EntityPath.fromEntity(change.newValue)
                                            .joinTo(
                                                types.TimeRegistrationActivity.RelationshipDefinition.Product,
                                                false)
                                    ])
                                    .expand();
                            }
                        }),
            [
                props.entity,
                types,
                props.commitContext,
            ]);

        const isBillableAllowed =
            useComputed(
                () =>
                    props.entity.getRelatedEntityByDefinition(
                        false,
                        types.TimeRegistration.RelationshipDefinition.Activity,
                        props.commitContext
                    )?.hasRelationshipsByDefinition(
                            false,
                            types.TimeRegistrationActivity.RelationshipDefinition.Product,
                            props.commitContext
                        ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const endDate =
            useEntityValue<Date>(
                props.entity,
                types.TimeRegistration.Field.EndDate,
                undefined,
                props.commitContext
            );
        const now = useClock(endDate === undefined);
        const duration =
            useComputed(
                () =>
                {
                    return getDurationLabel(
                        moment(endDate || now)
                            .diff(
                                props.entity.getObjectValueByField(
                                    types.TimeRegistration.Field.StartDate,
                                    props.commitContext
                                ),
                                'milliseconds'),
                        true);
                },
                [
                    props.entity,
                    types,
                    endDate,
                    now,
                    props.commitContext,
                ]);
        const endTimeRegistration = useEndTimeRegistration();

        const stop =
            useCallback(
                () =>
                    endTimeRegistration(
                        props.entity,
                        new Date()),
                [
                    endTimeRegistration,
                    props.entity
                ]);

        const employeePath =
            useMemo(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                            true),
                [
                    props.entity,
                    types
                ]);

        const note =
            useEntityValue(
                props.entity,
                types.TimeRegistration.Field.Description,
                undefined,
                props.commitContext
            );
        const [ isNoteOpen, toggleNote ] = useToggle(note !== undefined);
        const [ isProductLinesOpen, toggleProductLines ] = useToggle(false);
        const [ isMileageRegistrationsOpen, toggleMileageRegistrations ] = useToggle(false);

        const showMilestone =
            useMemo(
                () =>
                    props.canHaveMilestone && !props.milestone,
                [
                    props.canHaveMilestone,
                    props.milestone
                ]);

        const billingActivity =
            useComputed(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.ProductLine.RelationshipDefinition.BilledTimeRegistrations,
                            true)
                        .joinTo(
                            types.ProductLine.RelationshipDefinition.InvoiceProductLine,
                            false)
                        .joinTo(
                            types.Activity.RelationshipDefinition.ProductLines,
                            true)
                        .traverseEntity(
                            props.entity,
                            props.commitContext
                        )
                        .find(() => true),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const colSpan =
            useMemo(
                () =>
                {
                    let base = 6;

                    if (props.onSelect)
                    {
                        base += 1;
                    }

                    if (props.showActivity)
                    {
                        base += 1;
                    }

                    if (showMilestone)
                    {
                        base += 1;
                    }

                    return base;
                },
                [
                    props.onSelect,
                    props.showActivity,
                    showMilestone
                ]);

        const [ isSpecificationLayoutInEditMode, setSpecificationLayoutEditMode ] = useState(false);
        const closeSpecificationLayout =
            useCallback(
                () =>
                    setSpecificationLayoutEditMode(false),
                [
                    setSpecificationLayoutEditMode
                ]);
        const [ specificationLayout, setSpecificationLayout ] =
            useSetting(
                SettingSource.Organization,
                Setting.Metadata.TimeRegistration.SpecificationLayout,
                true
            );

        const isValid =
            useComputed(
                () =>
                    {
                        const hasActivity =
                            props.entity.hasRelationshipsByDefinition(
                                true,
                                types.Activity.RelationshipDefinition.TimeRegistrations,
                                props.commitContext
                            );

                        const hasTimeRegistration =
                            props.entity.hasRelationshipsByDefinition(
                                false,
                                types.TimeRegistration.RelationshipDefinition.Activity,
                                props.commitContext
                            );

                        return hasActivity && hasTimeRegistration;
                    },
                [
                    props.entity,
                    types,
                    props.commitContext
                ]
            );

        return <>
            <TableRow>
                {
                    props.onSelect &&
                        <TableCell>
                            <Checkbox
                                checked={props.selected}
                                onToggle={onSelect}
                                disabled={!isValid || isBilled}
                            />
                        </TableCell>
                }
                {
                    props.showActivity &&
                        <TableCell>
                            <Input
                                entity={props.entity}
                                field={types.Activity.RelationshipDefinition.TimeRegistrations}
                                parent
                                labelPosition="none"
                                autoFocus={props.entity.isNew()}
                                disabled={isProcessed}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                }
                <TableCell>
                    <Input
                        entity={props.entity}
                        field={types.TimeRegistration.RelationshipDefinition.Activity}
                        labelPosition="none"
                        disabled={isProcessed}
                        commitContext={props.commitContext}
                    />
                </TableCell>
                <TableCell>
                    <Input
                        entity={props.entity}
                        field={types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations}
                        parent
                        labelPosition="none"
                        disabled={isProcessed}
                        commitContext={props.commitContext}
                    />
                </TableCell>
                <TableCell>
                    {
                        endDate
                            ?
                                <Planner
                                    entity={props.entity}
                                    startDateField={types.TimeRegistration.Field.StartDate}
                                    endDateField={types.TimeRegistration.Field.EndDate}
                                    employeePath={employeePath}
                                    autoCommit
                                    disabled={isProcessed}
                                    labelPosition="none"
                                    commitContext={props.commitContext}
                                />
                            :
                                <Input
                                    entity={props.entity}
                                    field={types.TimeRegistration.Field.StartDate}
                                    labelPosition="none"
                                    disabled={isProcessed}
                                    commitContext={props.commitContext}
                                />
                    }
                </TableCell>
                <TableCell>
                    <Planner
                        entity={props.entity}
                        startDateField={types.TimeRegistration.Field.StartDate}
                        endDateField={types.TimeRegistration.Field.EndDate}
                        employeePath={employeePath}
                        autoCommit
                        disabled={isProcessed}
                        labelPosition="none"
                        commitContext={props.commitContext}
                    >
                        {duration}
                    </Planner>
                </TableCell>
                {
                    showMilestone &&
                        <TableCell>
                            <Input
                                entity={props.entity}
                                field={types.Milestone.RelationshipDefinition.TimeRegistrations}
                                parent
                                labelPosition="none"
                                disabled={isProcessed}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                }
                <TableCell
                    align="center"
                >
                    {
                        isBillableAllowed &&
                            <Input
                                entity={props.entity}
                                field={types.TimeRegistration.Field.IsBillable}
                                labelPosition="none"
                                disabled={isBilled}
                                commitContext={props.commitContext}
                            />
                    }
                </TableCell>
                {
                    props.showBillingActivity &&
                        <TableCell>
                            {
                                billingActivity &&
                                    <EntityLink
                                        entity={billingActivity}
                                    />
                            }
                        </TableCell>
                }
                <TableCell
                    align="right"
                >
                    <ButtonGroup
                        noWrap
                        justification="end"
                    >
                        <NoteIconButton
                            onClick={toggleNote}
                            color={isNoteOpen ? primaryColor : textSecondaryColor}
                        />
                        <IconButton
                            onClick={toggleProductLines}
                            color={isProductLinesOpen ? primaryColor : textSecondaryColor}
                            icon="attach_money"
                            tooltip={types.TimeRegistration.RelationshipDefinition.ProductLines.getName(false)}
                        />
                        {
                            types.TimeRegistration.RelationshipDefinition.MileageRegistrations &&
                            !props.entity.isNew() &&
                                <IconButton
                                    onClick={toggleMileageRegistrations}
                                    color={isMileageRegistrationsOpen ? primaryColor : textSecondaryColor}
                                    icon="directions_car"
                                    tooltip={types.TimeRegistration.RelationshipDefinition.MileageRegistrations.getName(false)}
                                />
                        }
                        {
                            endDate === undefined &&
                                <IconButton
                                    icon="timer_off"
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.Stop"
                                            value="Stoppen"
                                        />
                                    }
                                    onClick={stop}
                                />
                        }
                        <MenuButton
                            entity={props.entity}
                            onDelete={props.onDelete}
                        >
                            {
                                currentUserStore.isAdministrator &&
                                    <GenericInput
                                        labelPosition="right"
                                        label={
                                            <LocalizedText
                                                code="ProductLine.EditSpecificationLayout"
                                                value="Specificatie layout wijzigen"
                                            />
                                        }
                                    >
                                        <Checkbox
                                            checked={isSpecificationLayoutInEditMode}
                                            onToggle={setSpecificationLayoutEditMode}
                                        />
                                    </GenericInput>
                            }
                        </MenuButton>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            {
                isNoteOpen &&
                    <TableRow>
                        <TableCell
                            colSpan={colSpan}
                        >
                            <Input
                                entity={props.entity}
                                field={types.TimeRegistration.Field.Description}
                                labelPosition="none"
                                doAutocommit={!props.entity.isNew()}
                                autoFocus={note === undefined}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                    </TableRow>
            }
            {
                isProductLinesOpen &&
                    <TableRow>
                        <TableCell
                            colSpan={colSpan}
                        >
                            <TimeRegistrationProductLinesEditor
                                entity={props.entity}
                                noScroll
                            />
                        </TableCell>
                    </TableRow>
            }
            {
                isMileageRegistrationsOpen &&
                    <TableRow>
                        <TableCell
                            colSpan={colSpan}
                        >
                            <TimeRegistrationMileageRegistrationsEditor
                                entity={props.entity}
                                noScroll
                            />
                        </TableCell>
                    </TableRow>
            }
            {
                (isSpecificationLayoutInEditMode || specificationLayout) &&
                    <TableRow
                        // className={classNames(entity.isNew() && styles.new)}
                    >
                        <TableCell
                            colSpan={colSpan}
                        >
                            <EntityLayout
                                entity={props.entity}
                                isInEditMode={isSpecificationLayoutInEditMode}
                                onCloseEditMode={closeSpecificationLayout}
                                layout={specificationLayout}
                                onChangeLayout={setSpecificationLayout}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                    </TableRow>
            }
        </>;
    };

export default observer(TimeRegistration);
