import React, { useCallback } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../Type/Api/useTypes';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import PrimaryTextButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryTextButton/PrimaryTextButton';
import constructEntity from '../../../../../../../../@Api/Entity/constructEntity';
import { createTransactionalModel } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import DangerTextButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/DangerTextButton/DangerTextButton';
import SuccessTextButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SuccessTextButton/SuccessTextButton';
import useDividerGlue from '../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import CloseButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/CloseButton/CloseButton';
import deleteEntity from '../../../../../../../../@Api/Entity/deleteEntity';
import getFreshTransactionalEntity from '../../../../../../../../@Api/Entity/Bespoke/getFreshTransactionalEntity';
import PortalUserSettingsEditor from '../../../PortalUser/UserSettings/PortalUserSettingsEditor';
import { getEmailAddressesByEntity } from '../../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';

export interface PortalProps
{
    portal: Entity;
    relationship: Entity;
    users: Entity[];
    contact?: boolean;
}

const Portal: React.FC<PortalProps> =
    props =>
    {
        const types = useTypes();
        const openInvite =
            useCallback(
                () =>
                {
                    const newPortalUser =
                        createTransactionalModel(
                            new Entity(types.PortalUser.Type)
                                .initialize());

                    newPortalUser.updateRelationship(
                        true,
                        types.Portal.RelationshipDefinition.Users,
                        createTransactionalModel(props.portal));

                    newPortalUser.updateRelationship(
                        false,
                        types.PortalUser.RelationshipDefinition.Relationship,
                        getFreshTransactionalEntity(props.relationship));

                    newPortalUser.setValueByField(
                        types.PortalUser.Field.EmailAddress,
                        getEmailAddressesByEntity(props.relationship)
                            .map(e => e.emailAddress)
                            .find(() => true));

                    return constructEntity(
                        newPortalUser.entityType,
                        undefined,
                        newPortalUser);
                },
                [
                    types,
                    props.portal,
                    props.contact,
                    props.relationship
                ]);
        const dividerGlue = useDividerGlue();

        const [ isManaging, manage, cancelManage ] = useSwitch(false);
        const deletePortalUser =
            useCallback(
                (portalUser: Entity) =>
                    deleteEntity(portalUser),
                []);

        return <ViewGroup
            orientation="vertical"
            spacing={0}
            glue={dividerGlue}
        >
            <ViewGroupItem>
                <CardInset>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={15}
                        alignment="center"
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            {props.portal.name}
                        </ViewGroupItem>
                        <ViewGroupItem>
                            {
                                props.users.length === 0
                                    ?
                                        <PrimaryTextButton
                                            label={
                                                <LocalizedText
                                                    code="Generic.Invite"
                                                    value="Uitnodigen"
                                                />
                                            }
                                            onClick={openInvite}
                                        />
                                    :
                                        isManaging
                                            ?
                                                <CloseButton
                                                    onClick={cancelManage}
                                                />
                                            :
                                                <SuccessTextButton
                                                    label={
                                                        <LocalizedText
                                                            code="Generic.Manage"
                                                            value="Beheren"
                                                        />
                                                    }
                                                    onClick={manage}
                                                />
                            }
                        </ViewGroupItem>
                    </ViewGroup>
                </CardInset>
            </ViewGroupItem>
            {
                isManaging &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="vertical"
                            spacing={0}
                            glue={dividerGlue}
                        >
                            {
                                props.users.map(
                                    user =>
                                        <ViewGroupItem
                                            key={user.uuid}
                                        >
                                            <CardInset
                                                bottom={false}
                                            >
                                                <ViewGroup
                                                    orientation="horizontal"
                                                    spacing={15}
                                                    alignment="center"
                                                >
                                                    <ViewGroupItem
                                                        ratio={1}
                                                    >
                                                        <ViewGroup
                                                            orientation="vertical"
                                                            spacing={0}
                                                        >
                                                            {
                                                                !props.contact &&
                                                                    <ViewGroupItem>
                                                                        {user.getRelatedEntityByDefinition(false, types.PortalUser.RelationshipDefinition.Contact)?.name}
                                                                    </ViewGroupItem>
                                                            }
                                                            <ViewGroupItem>
                                                                <i>{user.getObjectValueByField(types.PortalUser.Field.EmailAddress)}</i>
                                                            </ViewGroupItem>
                                                        </ViewGroup>
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        <DangerTextButton
                                                            label={
                                                                <LocalizedText
                                                                    code="Generic.Delete"
                                                                    value="Verwijderen"
                                                                />
                                                            }
                                                            onClick={
                                                                () =>
                                                                    deletePortalUser(user)
                                                            }
                                                        />
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                                <PortalUserSettingsEditor
                                                    portalUser={user}
                                                    doAutoCommit
                                                />
                                            </CardInset>
                                        </ViewGroupItem>)
                            }
                        </ViewGroup>
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(Portal);
