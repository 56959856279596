import { observable } from 'mobx';
import ValueType from '../../Value/Type/ValueType';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Action from './Action';
import EmptyValueType from '../../Value/Type/EmptyValueType';
import EmptyValue from '../../Value/EmptyValue';
import ConditionalInComputation from './ConditionalInAction';
import ConditionalInAction from './ConditionalInAction';
import Validation from '../../Validation/Validation';
import getActionFromDescriptor from '../../Api/getActionFromDescriptor';
import localizeText from '../../../Localization/localizeText';

export default class ConditionalAction extends Action<EmptyValueType, EmptyValue>
{
    // ------------------------- Properties -------------------------

    @observable.shallow conditionals: ConditionalInComputation[];
    @observable.ref otherwise: Action<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(conditionals: ConditionalInComputation[],
                otherwise: Action<any, any>)
    {
        super();

        this.conditionals = conditionals;
        this.otherwise = otherwise;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        if (this.conditionals.length === 0)
        {
            return EmptyValueType.instance;
        }
        else
        {
            return this.conditionals[0].action?.getType() || EmptyValueType.instance;
        }
    }

    getName()
    {
        return localizeText(
            'Action.IfThenElse',
            'Als ..., dan ..., of anders ...'
        );
    }

    validate(): Validation[]
    {
        return this.conditionals
            .map(
                conditional =>
                    conditional.validate())
            .reduce((a, b) => a.concat(b), []);
    }

    isAsync(): boolean
    {
        return this.conditionals.some(
            conditional =>
                conditional.isAsync()
        );
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Conditional';
        descriptor.conditionals =
            this.conditionals.map(
                conditional =>
                    conditional.toDescriptor());
        descriptor.otherwise = this.otherwise?.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.conditionals
                .map(
                    conditional =>
                        conditional.getDependencies())
                .reduce((a, b) => a.concat(b), []),
            ...this.otherwise
                ?
                    this.otherwise.getDependencies()
                :
                    []
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ConditionalAction(
            await Promise.all(
                descriptor.conditionals.map(
                    conditional =>
                        ConditionalInAction.fromDescriptor(
                            conditional,
                            dependencyContext))),
            descriptor.otherwise
                ?
                    await getActionFromDescriptor(
                        descriptor.otherwise,
                        dependencyContext)
                :
                    undefined);
    }

    // ----------------------- Private logic ------------------------
}
