import { useContext, useMemo } from 'react';
import AuthenticationManagerContext from '../../Authentication/AuthenticationManagerContext';

export default function useApiClient()
{
    const authenticationManager = useContext(AuthenticationManagerContext);

    return useMemo(
        () =>
        {
            return authenticationManager?.apiClient;
        },
        [
            authenticationManager
        ]);
}
