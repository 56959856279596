import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { MobileStepper, Typography } from '@material-ui/core';
import { Loader } from '../../../../@Component/Generic/Loader/Loader';
import ReactPdf from '../../../../@Util/ReactPdf/ReactPdf';
import LabelButton from '../Button/Variant/Label/LabelButton';
import styles from '../../../../@Component/Generic/FileViewer/FileViewer.module.scss';
import useIsMobile from '../../../../@Util/Responsiveness/useIsMobile';
import ViewGroup from '../ViewGroup/ViewGroup';
import ViewGroupItem from '../ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';
import { PdfViewerDownloadMenu } from './PdfViewerDownloadMenu';
import { PdfViewerMobileDownloadButtons } from './PdfViewerMobileDownloadButtons';
import { DownloadOption } from './model/DownloadOption';
import { useComputed } from 'mobx-react-lite';
import {
    FileDownloadOption
} from "../../../../@Component/Domain/Entity/Item/Navigator/More/Hooks/useFileDownloadOptions";

export interface PdfViewerProps
{
    url: string;
    initializedUrl: string;
    extraDownloadOptions?: FileDownloadOption[];
}

const PdfViewer: React.FC<PdfViewerProps> =
    ({
         url,
         initializedUrl,
         extraDownloadOptions,
     }) =>
    {
        const isMobile = useIsMobile();
        const [ pdfPageNr, setPdfPageNr ] = useState(0);
        const [ pdfNumberOfPages, setPdfNumberOfPages ] = useState(0);
        const [ pdfWidth, setPdfWidth ] = useState(0);
        const [ , setPdfHeight ] = useState(0);

        const downloadOptions: DownloadOption[] =
            useComputed(
                () =>
                {
                    return [
                            {
                                url: url,
                                label: <LocalizedText
                                        code="PDFViewer.DownloadPDF"
                                        value="PDF downloaden"
                                    />
                            },
                            ...(extraDownloadOptions ?
                                extraDownloadOptions.map(
                                    (option) => {
                                        return {
                                            url: option.url,
                                            label: <LocalizedText
                                                code={option.code}
                                                value={option.value}
                                            />
                                        }
                                    }
                                ) : [])
                        ]
                },
                [
                    url,
                    extraDownloadOptions
                ]
            )

        const nextButton =
            useMemo(
                () =>
                {
                    return <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                    >
                        {
                            !isMobile &&
                            <ViewGroupItem>
                                <PdfViewerDownloadMenu
                                    options={downloadOptions}
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <LabelButton
                                label={
                                    <LocalizedText
                                        code="GenericComponent.FileViewer.NextPage"
                                        value="Volgende pagina"
                                    />
                                }
                                onClick={() => setPdfPageNr(pdfPageNr + 1)}
                                disabled={pdfPageNr === pdfNumberOfPages - 1}
                            />
                        </ViewGroupItem>
                    </ViewGroup>

                },
                [
                    url,
                    extraDownloadOptions,
                    pdfPageNr,
                    pdfNumberOfPages,
                    setPdfPageNr
                ]
            );

        const onLoadSuccess =
            useCallback(
                (pdf: any) =>
                {
                    const containerElement = document.querySelector('#pdfContainer');

                    // setPdf(pdf);
                    setPdfWidth(containerElement.clientWidth - 40); // Padding (2 * 32))
                    setPdfHeight(containerElement.clientHeight - 40) // Padding (2 * 32)
                    setPdfPageNr(0);
                    setPdfNumberOfPages(pdf.numPages);
                },
                [
                    setPdfPageNr,
                    setPdfNumberOfPages,
                    setPdfWidth,
                    setPdfHeight,
                ]
            );

        return <ViewGroup
            orientation="vertical"
            spacing={5}
        >
            {
                isMobile &&
                <PdfViewerMobileDownloadButtons
                    options={downloadOptions}
                />
            }
            <ViewGroupItem>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        // marginTop: '48px'
                    }}
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <div>
                         <MobileStepper
                             variant={(pdfNumberOfPages > 6) ? 'progress' : 'dots'}
                             steps={pdfNumberOfPages}
                             position="static"
                             activeStep={pdfPageNr}
                             nextButton={nextButton}
                             backButton={
                                 <div
                                     style={{
                                         marginRight: isMobile ? 0 : 50
                                     }}
                                 >
                                     <LabelButton
                                         label={
                                             <LocalizedText
                                                code="GenericComponent.FileViewer.PreviousPage"
                                                value="Vorige pagina"
                                             />
                                         }
                                         onClick={() => setPdfPageNr(pdfPageNr - 1)}
                                         disabled={pdfPageNr === 0}
                                     />
                                 </div>}
                         />
                        <div
                            style={{
                                paddingTop: 10,
                                background: 'whitesmoke'
                            }}
                        >
                            <Typography
                                align="center"
                            >
                                {pdfNumberOfPages > 0 ? pdfPageNr + 1 : 0 } / {pdfNumberOfPages}
                            </Typography>
                        </div>
                    </div>
                    <div
                        id="pdfContainer"
                        style={{
                            display: 'flex',
                            flex: '1 1 auto',
                            justifyContent: 'center',
                            background: 'whitesmoke',
                            paddingBottom: 15
                        }}
                    >
                        <ReactPdf.Document
                            file={initializedUrl}
                            onLoadSuccess={onLoadSuccess}
                            loading={
                                <Loader
                                    inline
                                />
                            }
                        >
                            <ReactPdf.Page
                                renderTextLayer={false}
                                className={styles.page}
                                pageNumber={pdfPageNr + 1}
                                width={Math.min(800, pdfWidth)}
                            />
                        </ReactPdf.Document>
                    </div>
                </div>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(PdfViewer);
