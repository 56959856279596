import { DataObjectEditorType, DataObjectSpecificationType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { DataObject } from '../../../Model/DataObject';
import { DateTimeView } from './DateTimeView';
import { DateTimeEditor } from './DateTimeEditor';
import { DateTimeSpecification } from './DateTimeSpecification';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { DateType, DateValue } from '../DateType';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import moment from 'moment';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DateTimeEditorStore } from './DateTimeEditorStore';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';
import { Localizer } from '../../../../../../@Service/Localization/Localizer';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class DateTimeType extends DateType
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;
    @injectWithQualifier('Localizer') localizer: Localizer;

    id(): string
    {
        return 'DateTime';
    }

    name()
    {
        return localizeText('DataObject.Type.DateTime', 'Datum met tijd');
    }

    view(): DataObjectViewerType
    {
        return DateTimeView;
    }

    editor(): DataObjectEditorType
    {
        return DateTimeEditor;
    }

    editorStore(editorStore: DataObjectEditorStore): DataObjectBespokeEditorStore
    {
        return new DateTimeEditorStore(editorStore);
    }

    specification(): DataObjectSpecificationType
    {
        return DateTimeSpecification;
    }

    getDataFromValue(value: DateValue, specification: DataObjectSpecification): DataDescriptor
    {
        const data = new DataDescriptor();
        data.dateTime1 = this.getDateFromValue(value);

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        if (data.dateTime1)
        {
            return data.dateTime1;
        }
        else if (data.text)
        {
            return new Date(data.text);
        }
        else
        {
            return undefined;
        }
    }

    getString(value: DateValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        const date = this.getDateFromValue(value);

        if (date)
        {
            if (representation.data.isCompact)
            {
                return this.localizer.formatNumeralDateTime(date);
            }
            else
            {
                const momentDate = moment(date);

                return `${this.localizer.formatDate(momentDate)} ${this.localizer.formatTime(momentDate)}`;
            }
        }
        else
        {
            return undefined;
        }
    }
}
