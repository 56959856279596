import React, { useContext, useState } from 'react';
import Column from './Column/Column';
import { observer, useComputed } from 'mobx-react-lite';
import useFilterAndSortAggregateResult from '../../Dataset/Segment/Selector/Chart/Api/useFilterAndSortAggregateResult';
import styles from './Columns.module.scss';
import useAugmentedResult from '../../Dataset/Segment/Api/useAugmentedResult';
import Drawer from './Drawer/Drawer';
import View from '../Model/View';
import FilterContext from '../../Dataset/Segment/FilterContext/FilterContext';
import DroppingEntityContext, { DroppingEntity } from './Context/DroppingEntityContext';
import OnDropEntityContext from './Context/OnDropEntityContext';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import Segment from '../../Dataset/Model/Segment';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';

export interface ColumnContainerProps
{
    view: View;
    isInEditMode: boolean;
    onOpen?: (entity: Entity) => void;
}

const Columns: React.FC<ColumnContainerProps> =
    props =>
    {
        const segment =
            useComputed(
                () =>
                    new Segment(
                        '1',
                        'Tile',
                        props.view.specification.column.groupFieldPath,
                        props.view.specification.column.aggregateValues),
                [
                    props.view
                ]);

        const filter = useContext(FilterContext);
        const result =
            useAugmentedResult(
                props.view.entityType,
                segment,
                props.view.parameter,
                undefined,
                filter);

        const sorted =
            useFilterAndSortAggregateResult(
                segment,
                result);

        const [ droppingEntity, setDroppingEntity ] = useState<DroppingEntity | undefined>();

        if (!result)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }

        return <DroppingEntityContext.Provider
            value={droppingEntity}
        >
            <OnDropEntityContext.Provider
                value={setDroppingEntity}
            >
                <div
                    className={styles.root}
                >
                    {
                        sorted.map(
                            (child, idx) =>
                                <Column
                                    key={child.id}
                                    view={props.view}
                                    entityType={props.view.entityType}
                                    idx={idx}
                                    segment={segment}
                                    result={child}
                                    firstColumn={idx === 0}
                                    lastColumn={idx === sorted.length - 1}
                                    numberOfColumns={sorted.length}
                                    onOpen={props.onOpen}
                                />)
                    }
                </div>
                <Drawer
                    entityType={props.view.entityType}
                    segment={segment}
                />
            </OnDropEntityContext.Provider>
        </DroppingEntityContext.Provider>;
    };

export default observer(Columns);
