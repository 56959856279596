import { observable } from 'mobx';
import Trigger from './Trigger/Trigger';
import Action from './Function/Action/Action';
import Dependency from './Parameter/Dependency';
import AutomationDependencyContext from './AutomationDependencyContext';
import getTriggerFromDescriptor from './Trigger/Api/getTriggerFromDescriptor';
import getActionFromDescriptor from './Api/getActionFromDescriptor';
import Validation from './Validation/Validation';
import ParameterDictionary from './Parameter/ParameterDictionary';

export default class Automation
{
    // ------------------------- Properties -------------------------

    static EnvironmentParameterId = 'Environment';
    static UserParameterId = 'User';

    @observable.ref parameterDictionary: ParameterDictionary;
    @observable.ref trigger: Trigger;
    @observable.ref action: Action<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(parameterDictionary: ParameterDictionary,
                trigger: Trigger,
                action: Action<any, any>)
    {
        this.parameterDictionary = parameterDictionary;
        this.trigger = trigger;
        this.action = action;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        return [
            ...this.trigger.validate(),
            ...this.action.validate()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.trigger.getDependencies(),
            ...this.action.getDependencies()
        ];
    }

    toDescriptor()
    {
        return {
            parameters: this.parameterDictionary.toDescriptor(),
            trigger: this.trigger.toDescriptor(),
            action: this.action.toDescriptor()
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const parameters =
            descriptor.parameters
                ?
                    await ParameterDictionary.fromDescriptor(
                        descriptor.parameters,
                        dependencyContext)
                :
                    new ParameterDictionary([]);

        parameters.parameterById
            .forEach(
                parameter =>
                    dependencyContext
                        .parameterDictionary
                        .addParameter(parameter));

        const trigger =
            await getTriggerFromDescriptor(
                descriptor.trigger,
                dependencyContext);

        return new Automation(
            parameters,
            trigger,
            await getActionFromDescriptor(
                descriptor.action,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
