import React from 'react';
import { observer } from 'mobx-react-lite';
import TableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/TableDimensionSection';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import StaticTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/StaticTableDimensionSection';
import CollectionTableDimensionSection from '../../../../../../../@Api/Layout/Type/Table/Model/CollectionTableDimensionSection';
import TableLayoutCollectionDimensionSectionEditor, { TableLayoutCollectionDimensionSectionEditorProps } from './TableLayoutCollectionDimensionSectionEditor';
import TableLayoutStaticDimensionSectionEditor, { TableLayoutStaticDimensionSectionEditorProps } from './TableLayoutStaticDimensionSectionEditor';
import { TableLayoutCellLocationEditorProps } from '../Cell/TableLayoutCellLocationEditor';
import TableLayoutDimensionSectionShiftEditor from './TableLayoutDimensionSectionShiftEditor';
import TableLayoutDimensionSectionOnClickEditor from './TableLayoutDimensionSectionOnClickEditor';
import TableLayoutDimensionSectionSizeEditor from './TableLayoutDimensionSectionSizeEditor';

export interface TableLayoutDimensionSectionEditorProps extends TableLayoutCellLocationEditorProps
{
    section: TableDimensionSection;
}

const TableLayoutDimensionSectionEditor: React.FC<TableLayoutDimensionSectionEditorProps> =
    props =>
    {
        const { section, columnSectionEditor } = props;

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                {
                    section instanceof StaticTableDimensionSection &&
                        <TableLayoutStaticDimensionSectionEditor
                            {...props as TableLayoutStaticDimensionSectionEditorProps}
                        />
                }
                {
                    section instanceof CollectionTableDimensionSection &&
                        <TableLayoutCollectionDimensionSectionEditor
                            {...props as TableLayoutCollectionDimensionSectionEditorProps}
                        />
                }
            </ViewGroupItem>
            <ViewGroupItem>
                <TableLayoutDimensionSectionOnClickEditor
                    {...props}
                />
            </ViewGroupItem>
            {
                columnSectionEditor &&
                    <ViewGroupItem>
                        <TableLayoutDimensionSectionSizeEditor
                            {...props}
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <TableLayoutDimensionSectionShiftEditor
                    {...props}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(TableLayoutDimensionSectionEditor);
