import React from 'react';
import LabelButton, { LabelButtonProps } from '../Label/LabelButton';
import { successColor } from '../../../../../../@Resource/Theme/Theme';

export interface SuccessButtonProps extends LabelButtonProps
{

}

const SuccessButton: React.FC<SuccessButtonProps> =
    props =>
    {
        return <LabelButton
            {...props}
        />;
    };

SuccessButton.defaultProps = {
    color: successColor,
    filled: true
};

export default SuccessButton;
