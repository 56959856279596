import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../Type/Api/useTypes';
import { useExpansion } from '../../Selection/Api/useExpansion';
import getRelatedAddressesPathsFromEntityType from './getRelatedAddressesPathsFromEntityType';
import { useMemo } from 'react';

export interface EntityAndAddressProvider
{
    entity: Entity;
    provider: Entity;
}

export interface EntityAndAddresses
{
    entity: Entity;
    addressProvider?: Entity;
    addresses: Entity[];
}

export default function useRelatedAddresses( entities: Entity[]) : [ EntityAndAddresses[], boolean ]
{
    const types = useTypes();

    const entitiesAndPossibleProvider: EntityAndAddressProvider[] =
        useMemo(
            () =>
                entities
                    .map(
                        entity =>
                        {
                            if (entity.entityType.isA(types.Activity.Appointment.Type) ||
                                entity.entityType.isA(types.Activity.WorkOrder.Type)
                            )
                            {
                                return {
                                    entity: entity,
                                    provider: entity
                                }
                            }
                            else if (entity.entityType.isA(types.Activity.Type))
                            {
                                const provider =
                                    entity.getRelatedEntityByDefinition(
                                        true,
                                        types.Activity.RelationshipDefinition.Relationship
                                    );
                                return {
                                    entity,
                                    provider
                                }
                            }
                            else if (
                                entity.entityType.isA(types.Relationship.Organization.Type) ||
                                entity.entityType.isA(types.Relationship.Person.Type))
                            {
                                return {
                                    entity,
                                    provider: entity
                                }
                            }
                            else
                            {
                                return {
                                    entity,
                                    provider: undefined
                                }
                            }
                        }
                    ),
            [
                types,
                entities
            ]
        );

    const possibleAddressProviders =
        useMemo(
            () =>
            entitiesAndPossibleProvider
                .map(er => er.provider)
                .filter(provider => provider !== undefined),
            [
                entitiesAndPossibleProvider
            ]
        );

    const [ loadingOrganization ] =
        useExpansion(
            types.Relationship.Organization.Type,
                () => getRelatedAddressesPathsFromEntityType(types.Relationship.Organization.Type),
                () =>
                    possibleAddressProviders
                        .filter(entity => entity.entityType.isA(types.Relationship.Organization.Type)),
                [
                    types,
                    possibleAddressProviders
                ]
            );

    const  [ loadingPersons ] =
        useExpansion(
            types.Relationship.Person.Type,
            () => getRelatedAddressesPathsFromEntityType(types.Relationship.Person.Type),
            () =>
                possibleAddressProviders
                    .filter(entity => entity.entityType.isA(types.Relationship.Person.Type)),
            [
                types,
                possibleAddressProviders
            ]
        );

    const [ loadingAppointments ] =
        useExpansion(
            types.Activity.Appointment.Type,
            () => getRelatedAddressesPathsFromEntityType(types.Activity.Appointment.Type),
            () =>
                possibleAddressProviders
                    .filter(entity => entity.entityType.isA(types.Activity.Appointment.Type)),
            [
                types,
                possibleAddressProviders
            ]
        );

    const [ loadingWorkOrders ] =
        useExpansion(
            types.Activity.WorkOrder.Type,
            () => getRelatedAddressesPathsFromEntityType(types.Activity.WorkOrder.Type),
            () =>
                possibleAddressProviders
                    .filter(entity => entity.entityType.isA(types.Activity.WorkOrder.Type)),
            [
                types,
                possibleAddressProviders
            ]
        );

    const entitiesAndAddresses: EntityAndAddresses[] =
        useMemo(
            () =>
            {
                if (loadingOrganization || loadingPersons || loadingAppointments || loadingWorkOrders)
                {
                    return [];
                }

                // Map Relationships to Relation
                const entitiesAndRelations =
                    entitiesAndPossibleProvider
                        .map(
                            entityAndPossibleProvider => {

                                const relation = [
                                    types.Relationship.Organization.RelationshipDefinition.Organization,
                                    types.Relationship.Person.RelationshipDefinition.Person
                                ]
                                    .map(def => entityAndPossibleProvider.provider?.getRelatedEntityByDefinition(false, def))
                                    .filter(ent => !!ent)
                                    .find(() => true);

                                return {
                                    ...entityAndPossibleProvider,
                                    provider: relation || entityAndPossibleProvider.provider
                                }
                            }
                        );

                // Get all relationships with AddressType and return a map with original entity and all related address entities
                return entitiesAndRelations
                    .flatMap(
                        entityAndRelation =>
                        {
                            // Get all child relation definitions with AddressType
                            const addresses =
                                entityAndRelation.provider?.entityType.getInheritedRelationshipDefinitions(false)
                                    .filter(
                                        relationshipDefinition =>
                                        {
                                            return relationshipDefinition.isSingular(false) &&
                                                relationshipDefinition.getEntityType(false).isA(types.Address.Type)
                                        }
                                    )
                                    .map(
                                        addressRspDef =>
                                            entityAndRelation.provider.getRelatedEntityByDefinition(false, addressRspDef)
                                    );

                            return  {
                                entity: entityAndRelation.entity,
                                addressProvider: entityAndRelation.provider,
                                addresses: addresses || []
                            };
                        }
                    )
            },
            [
                types,
                entitiesAndPossibleProvider,
                loadingPersons,
                loadingOrganization,
                loadingAppointments,
                loadingWorkOrders
            ]
        );

    return [ entitiesAndAddresses, loadingOrganization || loadingPersons || loadingAppointments || loadingWorkOrders ];
}