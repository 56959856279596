import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { EntityTypeStore } from '../../EntityTypeStore';
import { BespokeDatastore } from './BespokeDatastore';

export class BespokeDatastoreTaskType extends BespokeDatastore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore, code: string = 'Datastore.TaskType')
    {
        super(entityTypeStore, code);
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    hideFieldPath(
        entity: Entity,
        fieldPath: EntityFieldPath,
        fromFieldPath?: EntityFieldPath,
        isInConstructor?: boolean
    ): boolean
    {
        if (fieldPath.field === this.types.Datastore.Field.Color)
        {
            return false;
        }
        else
        {
            return super.hideFieldPath(entity, fieldPath, fromFieldPath, isInConstructor);
        }
    }

    // ----------------------- Private logic ------------------------
}
