import { Entity } from '../../Model/Implementation/Entity';
import { EntityField } from '../../Model/Implementation/EntityField';
import { CommitContext } from '../Commit/Context/CommitContext';
import { isPrimitiveFieldRequired } from '../../Metadata/Input/isPrimitiveFieldRequired';

export default function isFieldInEntityValid(entity: Entity,
                                             field: EntityField,
                                             commitContext?: CommitContext)
{
    if (entity.hasValueForField(field, commitContext))
    {
        return entity.getDataObjectValueByField(
            field,
            undefined,
            commitContext
        ).isValid;
    }
    else
    {
        return !isPrimitiveFieldRequired(entity.entityType, field)
            || field.dataObjectSpecification.type.hasSemanticValueWhenEmpty();
    }
}
