import React from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import Recipient from './Recipient/Recipient';

export interface RecipientListProps
{
    recipients: Entity[];
}

const RecipientList: React.FC<RecipientListProps> =
    props =>
    {
        return <>
            {
                props.recipients.map(
                    (recipient, idx) =>
                        <Recipient
                            key={recipient.uuid}
                            recipient={recipient}
                            idx={idx}
                        />)
            }
        </>;
    };

export default observer(RecipientList);
