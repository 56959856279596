import Dependency from '../../../../Automation/Parameter/Dependency';
import Validation from '../../../../Automation/Validation/Validation';
import { observable } from 'mobx';
import Tab from './Tab';

export default abstract class TabSource
{
    // ------------------------- Properties -------------------------

    @observable id: string;

    // ------------------------ Constructor -------------------------

    constructor(id: string)
    {
        this.id = id;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getTabs(): Tab[];

    abstract validate(): Validation[];

    abstract getDependencies(): Dependency[];

    toDescriptor()
    {
        const descriptor = {
            id: this.id
        };

        this.toInternalDescriptor(descriptor);

        return descriptor;
    }

    abstract toInternalDescriptor(descriptor: any);

    // ----------------------- Private logic ------------------------
}
