import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../Entity/Type/Api/useTypes';

export default function useIsRead(notification: Entity)
{
    const types = useTypes();

    return useComputed(
        () =>
            notification.getObjectValueByField<boolean>(types.Notification.Field.IsRead),
        [
            types,
            notification
        ]);
}
