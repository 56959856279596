import { DayOfWeek } from '../../../Model/Util/DayOfWeek';
import { Weekday } from 'rrule';

export function getRRuleWeekDay(dayOfWeek: DayOfWeek): Weekday
{
    return Weekday.fromStr(
        getRRuleWeekDayCode(dayOfWeek)
    );
}

function getRRuleWeekDayCode(dayOfWeek: DayOfWeek)
{
    switch (dayOfWeek)
    {
        case 'Monday':
            return 'MO';

        case 'Tuesday':
            return 'TU';

        case 'Wednesday':
            return 'WE';

        case 'Thursday':
            return 'TH';

        case 'Friday':
            return 'FR';

        case 'Saturday':
            return 'SA';

        case 'Sunday':
            return 'SU';
    }
}
