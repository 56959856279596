import React from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import LayoutViewer, { LayoutViewerProps } from '../../Viewer/LayoutViewer';
import TemplateLayout from '../../../../../@Api/Layout/Type/TemplateLayout';
import FunctionContext from '../../../../../@Api/Automation/Function/FunctionContext';

export interface TemplateLayoutViewerProps extends LayoutViewerProps<TemplateLayout>
{

}

const TemplateLayoutViewer: React.FC<TemplateLayoutViewerProps> =
    props =>
    {
        const parameterAssignment =
            useComputed(
                () =>
                    props.layout.parameterAssignment?.synchronouslyApply(
                        new FunctionContext(
                            props.parameterDictionary,
                            props.parameterAssignment,
                            props.commitContext,
                        )),
                [
                    props.layout,
                    props.parameterDictionary,
                    props.parameterAssignment,
                    props.commitContext,
                ]);

        if (props.layout.template)
        {
            return <LayoutViewer
                layout={props.layout.template.layout}
                parameterDictionary={props.layout.template.parameters}
                parameterAssignment={parameterAssignment}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(TemplateLayoutViewer);
