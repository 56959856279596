import React, { useContext, useMemo } from 'react';
import useTypes from '../../Type/Api/useTypes';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import CurrentUserContext from '../../../User/CurrentUserContext';
import LeftDrawerContext from '../../../../Generic/Drawer/LeftDrawerContext';
import { EntityActivationButtonStore } from '../../Button/EntityActivationButtonStore';
import { EntityDeleteButtonStore } from '../../Button/EntityDeleteButtonStore';
import { EntityReadCountButtonStore } from '../../Button/EntityReadCountButtonStore';
import { ViewComponent } from '../../../../Generic/ViewStack/Model/ViewComponent';
import { SidebarPanel } from '../../../../Generic/SidebarPanel/SidebarPanel';
import { SidebarPanelStore } from '../../../../Generic/SidebarPanel/SidebarPanelStore';
import EntitySystemFields from '../../SystemFields/EntitySystemFields';
import Button from '../../../../Generic/Button/Button';
import { ButtonStore } from '../../../../Generic/Button/ButtonStore';
import styles from './MenuButton.module.scss';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import ViewerEntityContext from '../../Viewer/Context/ViewerEntity/ViewerEntityContext';
import HeaderContext from '../Context/HeaderContext';
import useChildRelation from '../../../../../@Api/Entity/Bespoke/Relationship/useChildRelation';
import { EntityActivityLinkButtonStore } from '../../Button/EntityActivityLinkButtonStore';
import { EntityActivityConvertButtonStore } from '../../Button/EntityActivityConvertButtonStore';
import { catchImport } from '../../../../../@Util/Import/catchImport';
import performAction from '../../../../../@Api/Entity/performAction';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { EntityActivityRelationshipsButtonStore } from '../../Button/EntityActivityRelationshipsButtonStore';
import RouterContext from '../../../../../@Service/Router/RouterContext';
import { MergingFeature } from '../../../Role/Model/Features';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { EntityImportDuplicateButtonStore } from '../../Button/ImportDuplicateButton/EntityImportDuplicateButtonStore';

export interface MenuButtonProps
{
    entity: Entity;
    icon?: string;
    small?: boolean;
    onDelete?: () => void;
    isTimelineOpen: boolean;
    openTimeline: () => void;
    closeTimeline: () => void;
}

const MenuButton: React.FC<MenuButtonProps> =
    props =>
    {
        const types = useTypes();
        const viewerEntity = useContext(ViewerEntityContext);
        const isHeader = useContext(HeaderContext);
        const currentUserStore = useContext(CurrentUserContext);
        const leftDrawerStore = useContext(LeftDrawerContext);
        const routerStore = useContext(RouterContext);

        const relation = useChildRelation(props.entity);
        const activationButtonStore =
            useMemo(
                () =>
                {
                    if (isHeader)
                    {
                        return new EntityActivationButtonStore({
                            entity: viewerEntity
                        });
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    isHeader,
                    viewerEntity
                ]);

        const deleteButtonStore =
            useMemo(
                () =>
                    new EntityDeleteButtonStore({
                        entity: props.entity,
                        onDelete: props.onDelete,
                        isVisible: props.entity.entityType.bespoke.isDeletable(props.entity)
                    }),
                [
                    props.entity,
                    props.onDelete
                ]);

        const overrideDeleteButtonStore =
            useMemo(
                () =>
                    new EntityDeleteButtonStore({
                        entity:
                            () =>
                                props.entity.getRelatedEntityByDefinition(
                                    true,
                                    types.Entity.RelationshipDefinition.Overrides),
                        label:
                            () => localizeText('RemoveFromOwnEnvironment', 'Verwijder uit eigen omgeving'),
                        isVisible:
                            () =>
                            {
                                const extendedEntity =
                                    props.entity.getRelatedEntityByDefinition(
                                        true,
                                        types.Entity.RelationshipDefinition.Overrides);

                                return extendedEntity && currentUserStore.rightProfile.canDelete(extendedEntity);
                            }
                    }),
                [
                    props.entity,
                    types,
                    currentUserStore
                ]);

        const activityLinkButtonStore =
            useMemo(
                () =>
                    new EntityActivityLinkButtonStore({
                        entity: props.entity
                    }),
                [
                    props.entity
                ]);

        const activityRelationshipsButtonStore =
            useMemo(
                () =>
                    new EntityActivityRelationshipsButtonStore({
                        entity: props.entity
                    }),
                [
                    props.entity
                ]);

        const convertActivityButtonStore =
            useMemo(
                () =>
                    props.entity
                        ?
                            new EntityActivityConvertButtonStore({
                                entity: props.entity
                            })
                        :
                            undefined,
                [
                    props.entity
                ]);

        const readCountButtonStore =
            useMemo(
                () =>
                    new EntityReadCountButtonStore({
                        entity: viewerEntity
                    }),
                [
                    viewerEntity
                ]);

        const systemButtonStore =
            useMemo(
                () =>
                    new ButtonStore({
                        label:
                            () =>
                                localizeText('System', 'Systeem'),
                        onClick:
                            () =>
                                import('../../SystemFields/EntitySystemFieldsStore')
                                    .then(
                                        index =>
                                            leftDrawerStore.pushView(
                                                new ViewComponent(
                                                    SidebarPanel,
                                                    new SidebarPanelStore({
                                                        title: localizeText('SystemFields', 'Systeem velden'),
                                                        onClose:
                                                            () => leftDrawerStore.popView(),
                                                        viewComponent:
                                                            new ViewComponent(
                                                                EntitySystemFields,
                                                                new index.EntitySystemFieldsStore({
                                                                    entity: props.entity
                                                                }))
                                                    }))))
                                    .catch(catchImport),
                        isVisible:
                            () =>
                                currentUserStore.isSupport
                    }),
                [
                    leftDrawerStore,
                    props.entity,
                    currentUserStore
                ]);

        const systemRelationButtonStore =
            useMemo(
                () =>
                {
                    if (relation)
                    {
                        return new ButtonStore({
                            label:
                                () => localizeText('SystemRelation', 'Systeem (relatie)'),
                            onClick:
                                () =>
                                    import('../../SystemFields/EntitySystemFieldsStore')
                                        .then(
                                            index =>
                                                leftDrawerStore.pushView(
                                                    new ViewComponent(
                                                        SidebarPanel,
                                                        new SidebarPanelStore({
                                                            title: localizeText('SystemFields', 'Systeem velden'),
                                                            onClose:
                                                                () => leftDrawerStore.popView(),
                                                            viewComponent:
                                                                new ViewComponent(
                                                                    EntitySystemFields,
                                                                    new index.EntitySystemFieldsStore({
                                                                        entity: relation
                                                                    }))
                                                        }))))
                                        .catch(catchImport),
                            isVisible:
                                () =>
                                    currentUserStore.isSupport
                        });
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    relation,
                    leftDrawerStore,
                    currentUserStore
                ]);

        const duplicateImportButtonStore =
            useMemo(
                () =>
                    new EntityImportDuplicateButtonStore({
                        entity: props.entity
                    }),
                [
                    props.entity
                ]);

        const buttons =
            useMemo(
                () =>
                    [
                        activationButtonStore,
                        deleteButtonStore,
                        overrideDeleteButtonStore,
                        duplicateImportButtonStore,
                        activityLinkButtonStore && activityLinkButtonStore,
                        activityRelationshipsButtonStore && activityRelationshipsButtonStore,
                        convertActivityButtonStore && convertActivityButtonStore,
                        readCountButtonStore,
                        systemButtonStore,
                        systemRelationButtonStore
                    ].filter(item => item),
                [
                    activationButtonStore,
                    deleteButtonStore,
                    overrideDeleteButtonStore,
                    activityLinkButtonStore,
                    activityRelationshipsButtonStore,
                    convertActivityButtonStore,
                    readCountButtonStore,
                    systemButtonStore,
                    systemRelationButtonStore,
                    duplicateImportButtonStore
                ]);

        const actions =
            useComputed(
                () =>
                    props.entity.entityType.bespoke.getActions(props.entity),
                [
                    props.entity
                ]);

        return <>
            <div
                className={styles.hidden}
            >
                {
                    buttons.map(
                        buttonStore =>
                            <Button
                                key={buttonStore.uuid}
                                store={buttonStore}
                            />)
                }
            </div>
            <Menu>
                {
                    actions.map(
                        action =>
                            <Item
                                key={action.id}
                                name={action.name}
                                onClick={
                                    () =>
                                        performAction(
                                            props.entity,
                                            action)
                                }
                            />)
                }
                {
                    buttons
                        .filter(
                            buttonStore =>
                                buttonStore.isVisible && !buttonStore.isHidden)
                        .map(
                            buttonStore =>
                                <Item
                                    key={buttonStore.uuid}
                                    icon={buttonStore.iconStore && buttonStore.iconStore.icon}
                                    name={buttonStore.label as any || buttonStore.tooltip}
                                    onClick={() => buttonStore.click(undefined)}
                                />)
                }
                {
                    (currentUserStore.isAdministrator
                    || currentUserStore.rightProfile.role.isFeatureEnabled(MergingFeature)) &&
                    props.entity.entityType.isA(types.Relationship.Type) &&
                        <Item
                            icon="call_merge"
                            name={
                                <>
                                    <LocalizedText
                                        code="Generic.Merge"
                                        value="Samenvoegen"
                                    />...
                                </>
                            }
                            onClick={
                                () =>
                                    routerStore.route(
                                        `/merge/${props.entity.id}`
                                    )
                            }
                        />
                }
                {
                    currentUserStore.isAdministrator &&
                        <Item
                            icon="timeline"
                            name={
                                <LocalizedText
                                    code="Generic.AuditTrail"
                                    value="Audit trail"
                                />
                            }
                            onClick={() => props.openTimeline()}
                        />
                }
                {props.children}
            </Menu>
        </>;
    };

export default observer(MenuButton);
