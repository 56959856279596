import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ResourceSelection from '../../Model/ResourceSelection';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import { PlanDialogProps } from '../PlanDialog';
import { default as ViewModel } from '../../../View/Model/View';
import { LogicalOperator } from '../../../../DataObject/Model/LogicalOperator';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import Specification from '../../../View/Model/Specification';
import { default as ListModel } from '../../../View/Model/Specification/List';
import Column from '../../../View/Model/Specification/Column';
import uuid from '../../../../../../@Util/Id/uuid';
import useTypes from '../../../Type/Api/useTypes';
import List from '../../../View/List/List';
import useDividerGlue from '../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { DataObject } from '../../../../DataObject/Model/DataObject';
import useCount from '../../../Selection/Hooks/useCount';
import getSystemDefaultView from '../../../View/Api/getSystemDefaultView';
import ScrollRefContext from '../../../../../../@Service/Navigation/Page/Scroll/ScrollRefContext';
import styles from './PlanDialogSelector.module.scss';
import getViewParameters from '../../../View/Api/getViewParameters';
import CompositePredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { ViewParams } from '../../../View/Model/ViewParams';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import PrimitiveValue from '../../../../../../@Api/Automation/Value/PrimitiveValue';
import { CommitBuilder } from '../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface PlanDialogSelectorProps extends PlanDialogProps
{
    resourceSelection: ResourceSelection;
}

const PlanDialogSelector: React.FC<PlanDialogSelectorProps> =
    props =>
    {
        const types = useTypes();

        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(props.resourceSelection.entityType),
                [
                    props.resourceSelection
                ]);
        const unplannedView =
            useMemo(
                () =>
                    new ViewModel(
                        'List',
                        undefined,
                        props.resourceSelection.entityType,
                        viewParameters,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        props.resourceSelection.startDateFieldPath),
                                    Comparator.IsNotDefined,
                                    EmptyValue.instance),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        props.resourceSelection.endDateFieldPath),
                                    Comparator.IsNotDefined,
                                    EmptyValue.instance),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        EntityPath.fromEntityType(props.resourceSelection.entityType)
                                            .field(types.Entity.Field.IsClosed)),
                                    Comparator.Equals,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Boolean',
                                            false)))
                            ]),
                        new Specification(
                            new ListModel(
                                [
                                    ...getSystemDefaultView(props.resourceSelection.entityType, viewParameters).specification.list.columns,
                                    new Column(
                                        uuid(),
                                        props.resourceSelection.resourcePath.field()),
                                ],
                                []))),
                [
                    props.resourceSelection,
                    viewParameters,
                    types
                ]);

        const plannedView =
            useMemo(
                () =>
                    new ViewModel(
                        'List',
                        undefined,
                        props.resourceSelection.entityType,
                        viewParameters,
                        new CompositePredicate(
                            LogicalOperator.And,
                            [
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        props.resourceSelection.startDateFieldPath),
                                    Comparator.IsDefined,
                                    EmptyValue.instance),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        props.resourceSelection.endDateFieldPath),
                                    Comparator.IsDefined,
                                    EmptyValue.instance),
                                new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        viewParameters.getParameterById(ViewParams.Entity),
                                        EntityPath.fromEntityType(props.resourceSelection.entityType)
                                            .field(types.Entity.Field.IsClosed)),
                                    Comparator.Equals,
                                    new PrimitiveValue(
                                        DataObject.constructFromTypeIdAndValue(
                                            'Boolean',
                                            false)))
                            ]),
                        new Specification(
                            new ListModel(
                                [
                                    ...getSystemDefaultView(props.resourceSelection.entityType, viewParameters).specification.list.columns,
                                    new Column(
                                        uuid(),
                                        props.resourceSelection.startDateFieldPath),
                                    new Column(
                                        uuid(),
                                        props.resourceSelection.endDateFieldPath),
                                    new Column(
                                        uuid(),
                                        props.resourceSelection.resourcePath.field()),
                                ],
                                []))),
                [
                    props.resourceSelection,
                    viewParameters,
                    types
                ]);

        const onPlan =
            useCallback(
                (entity: Entity) =>
                    new CommitBuilder()
                        .setObjectValueInEntityByFieldPath(
                            entity,
                            props.resourceSelection.startDateFieldPath,
                            props.period.from.toDate()
                        )
                        .setObjectValueInEntityByFieldPath(
                            entity,
                            props.resourceSelection.endDateFieldPath,
                            props.period.to.toDate(),
                        )
                        .setObjectValueInEntityByFieldPath(
                            entity,
                            props.resourceSelection.resourcePath.field(),
                            props.resource
                        )
                        .commit()
                        .then(
                            () =>
                            {
                                if (props.onSave)
                                {
                                    props.onSave(entity);
                                }

                                props.onClose();
                            }
                        ),
                [
                    props.resourceSelection,
                    props.period,
                    props.resource,
                    props.onSave,
                    props.onClose
                ]);

        const unplannedCount =
            useCount(
                unplannedView.entityType,
                builder =>
                    builder.where(
                        cb =>
                            cb.filter(
                                unplannedView.filter,
                                {
                                    parameter: unplannedView.parameter,
                                }
                            )
                    ),
                [
                    unplannedView
                ]);

        const plannedCount =
            useCount(
                plannedView.entityType,
                builder =>
                    builder.where(
                        cb =>
                            cb.filter(
                                plannedView.filter,
                                {
                                    parameter: plannedView.parameter,
                                }
                            )
                    ),
                [
                    plannedView
                ]);

        const dividerGlue = useDividerGlue();
        const unplannedRef = useRef();
        const plannedRef = useRef();

        return <ExpansionGroup>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <ExpansionPanel
                        id="Unplanned"
                        summary={
                            <Header
                                title={
                                    <><LocalizedText code="Generic.Unplanned" value="Ongepland" /> ({unplannedCount})</>
                                }
                                inset
                            />
                        }
                        expansion={
                            <div
                                ref={unplannedRef}
                                className={styles.expansion}
                            >
                                <ScrollRefContext.Provider
                                    value={unplannedRef}
                                >
                                    <List
                                        view={unplannedView}
                                        readonly
                                        onClick={onPlan}
                                    />
                                </ScrollRefContext.Provider>
                            </div>
                        }
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <ExpansionPanel
                        id="Planned"
                        summary={
                            <Header
                                title={
                                    <><LocalizedText code="Generic.Planned" value="Gepland" /> ({plannedCount})</>
                                }
                                inset
                            />
                        }
                        expansion={
                            <div
                                ref={plannedRef}
                                className={styles.expansion}
                            >
                                <ScrollRefContext.Provider
                                    value={plannedRef}
                                >
                                    <List
                                        view={plannedView}
                                        readonly
                                        onClick={onPlan}
                                    />
                                </ScrollRefContext.Provider>
                            </div>
                        }
                    />
                </ViewGroupItem>
            </ViewGroup>
        </ExpansionGroup>;
    };

export default observer(PlanDialogSelector);
