import React from 'react';
import { RecurrencePatternEditorProps } from '../../../RecurrencePatternEditor';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { RelativeMonthlyRecurrencePattern } from '../../../../Model/RecurrencePattern';
import { DayOfWeekIndexEditor } from '../../../Util/DayOfWeekIndex/DayOfWeekIndexEditor';
import { DayOfWeekEditor } from '../../../Util/DayOfWeek/DayOfWeekEditor';
import { RecurrenceIntervalEditor } from '../../../Util/Interval/RecurrenceIntervalEditor';

export const RelativeMonthlyRecurrencePatternEditor: React.FC<RecurrencePatternEditorProps<RelativeMonthlyRecurrencePattern>> =
    ({
        value,
        onChange,
        disabled,
     }) =>
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.The"
                    value="De"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <DayOfWeekIndexEditor
                    value={value?.dayOfWeekIndex}
                    onChange={
                        dayOfWeekIndex =>
                            onChange({
                                ...value,
                                dayOfWeekIndex,
                            })
                    }
                    disabled={disabled}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <DayOfWeekEditor
                    value={value?.dayOfWeek}
                    onChange={
                        dayOfWeek =>
                            onChange({
                                ...value,
                                dayOfWeek,
                            })
                    }
                    disabled={disabled}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.OfEvery"
                    value="van elke"
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <RecurrenceIntervalEditor
                    value={value?.interval}
                    onChange={
                        interval =>
                            onChange({
                                ...value,
                                interval,
                            })
                    }
                    disabled={disabled}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                <LocalizedText
                    code="Generic.Month(s)"
                    value="Maand(en)"
                />
            </ViewGroupItem>
        </ViewGroup>;
    };
