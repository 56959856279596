import * as React from 'react';
import * as ReactDOM from 'react-dom';

export class AppBodyPortal extends React.Component
{
    render()
    {
        const element = document.getElementById('app-body-portal-container');

        if (element)
        {
            return ReactDOM.createPortal(
                this.props.children,
                element);
        }
        else
        {
            return null;
        }
    }
}
