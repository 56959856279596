import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FeedbackStore } from './FeedbackStore';
import { useSnackbar } from 'notistack';
import { Dialog } from '../../../../../Generic/Dialog/Dialog';

export interface FeedbackProps
{
    store: FeedbackStore;
}

const Feedback: React.FC<FeedbackProps> =
    props =>
    {
        const snackbar = useSnackbar();

        useEffect(
            () =>
            {
                props.store.setSnackbar(snackbar);
            },
            [
                snackbar
            ]);

        return <>
            {
                props.store.dialogs.map(
                    dialog =>
                        dialog.store
                            ?
                                <Dialog
                                    key={dialog.id}
                                    store={dialog.store}
                                />
                            :
                                <React.Fragment
                                    key={dialog.id}
                                >
                                    {dialog.content}
                                </React.Fragment>)
            }
        </>;
    };

export default observer(Feedback);
