import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../../Model/DataObjectType';
import { Comparator } from '../../../Model/Comparator';
import { Aggregate } from '../../../Model/Aggregate';
import { MathematicalOperator } from '../../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../../Model/DataObjectFunction';
import { DataObject } from '../../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { DatePeriodView } from './DatePeriodView';
import { DatePeriodEditor } from './DatePeriodEditor';
import { DatePeriodSpecification } from './DatePeriodSpecification';
import { DatePeriodValue } from './DatePeriodValue';
import { DataDescriptor } from '../../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { toJS } from 'mobx';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { DataObjectOverloadType } from '../../../Model/DataObjectOverloadType';
import { DateType } from '../DateType';
import { DateTimeType } from '../DateTime/DateTimeType';
import moment from 'moment';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { NumberType } from '../../Number/NumberType';

export class DatePeriodType implements DataObjectType<DatePeriodValue>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'DatePeriod';
    }

    name()
    {
        return localizeText('DataObject.Type.DatePeriod', 'Periode');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return DatePeriodView;
    }

    editor(): DataObjectEditorType
    {
        return DatePeriodEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return DatePeriodSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: DatePeriodValue): boolean
    {
        return false;
    }

    getDataFromValue(value: DatePeriodValue, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.complex = value;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        return data.complex;
    }

    valueId(value: DatePeriodValue): string
    {
        return JSON.stringify(toJS(value));
    }

    getString(value: DatePeriodValue,
              representation: DataObjectRepresentation,
              context: DataObjectContext,
              dataObject: DataObject): string
    {
        return `${value.period} ${value.type.toLowerCase()}`;
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        return false;
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [
            new DataObjectOperatorOverload(
                this,
                MathematicalOperator.Multiply,
                DataObjectOverloadType.Symmetric,
                type =>
                    type instanceof NumberType,
                (type, relatedType, isThisLhs) =>
                    isThisLhs ? type : relatedType,
                (value, relatedValue, isThisLhs) =>
                {
                    const period: DatePeriodValue = (isThisLhs ? value.value : relatedValue.value);
                    const number: number = (isThisLhs ? relatedValue.value : value.value);

                    return DataObject.constructFromTypeIdAndValue(
                        'DatePeriod',
                        {
                            type: period.type,
                            period: Math.round(period.period * number)
                        }
                    );
                }),
            ...[
                MathematicalOperator.Add,
                MathematicalOperator.Subtract
            ].map(
                operator =>
                    new DataObjectOperatorOverload(
                        this,
                        operator,
                        DataObjectOverloadType.Symmetric,
                        type =>
                            type instanceof DateType
                            || type instanceof DateTimeType,
                        (type, relatedType, isThisLhs) =>
                            relatedType,
                        (value, relatedValue, isThisLhs) =>
                        {
                            const period: DatePeriodValue = (value.type instanceof DatePeriodType ? value : relatedValue).getValue();
                            const dateDataObject = (value.type instanceof DatePeriodType ? relatedValue : value);
                            const date = moment(dateDataObject.getValue());

                            date.add(
                                operator === MathematicalOperator.Add ? period.period : -1 * period.period,
                                period.type.toLowerCase() as any);

                            return DataObject.constructFromTypeAndValue(
                                dateDataObject.type,
                                date.toDate());
                        }))
        ];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return true;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return false;
    }
}
