import moment from 'moment';
import { useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../../../../../../../../@Api/Model/Implementation/Entity';
import { EntityField } from '../../../../../../../../../../../../../../../@Api/Model/Implementation/EntityField';
import { CommitContext } from '../../../../../../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export default function useIsDueToday(entity: Entity,
                                      startDateField: EntityField,
                                      commitContext?: CommitContext)
{
    return useComputed(
        () =>
        {
            const date = entity.getObjectValueByField(startDateField, commitContext);

            if (date)
            {
                return moment().isSame(date, 'day');
            }
            else
            {
                return false;
            }
        },
        [
            entity,
            startDateField,
            commitContext,
        ]);
}
