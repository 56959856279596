import { DataObjectBespokeEditorStore } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditorStore';
import { action, computed } from 'mobx';
import { DateRangeType, DateRangeValueType, types } from './DateRangeType';
import { StaticOption } from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import moment from 'moment';
import getDatesFromRange from './Api/getDatesFromRange';


export class DateRangeEditorStore extends DataObjectBespokeEditorStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get types(): DateRangeValueType[]
    {
        return Array.from(
            (this.baseStore.dataObject.specification.type as DateRangeType)
                .typeById
                .values())
    }

    @computed
    get selectedType(): string
    {
        if (this.baseStore.dataObject.isEmpty)
        {
            return undefined;
        }
        else
        {
            return this.baseStore.dataObject.value.type;
        }
    }

    @computed
    get momentFrom()
    {
        if (this.baseStore.dataObject.value?.from)
        {
            return moment(this.baseStore.dataObject.value.from)
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get momentTo()
    {
        if (this.baseStore.dataObject.value?.to)
        {
            return moment(this.baseStore.dataObject.value.to)
        }
        else
        {
            return undefined;
        }
    }

    @computed
    get selectedYear()
    {
        return this.momentFrom?.get('year');
    }

    @computed
    get selectedQuarter()
    {
        return this.momentFrom?.get('quarter');
    }

    @computed
    get selectedMonth()
    {
        return this.momentFrom?.get('month');
    }

    // --------------------------- Stores ---------------------------

    @computed
    get typeOptions(): StaticOption<string>[]
    {
        return types()
            .map(
                type => ({
                    id: type.value,
                    value: type.value,
                    label: type.label
                }));
    }

    @computed
    get yearOptions(): StaticOption<number>[]
    {
        const yearOptions = [];
        const currentYear: number = new Date().getFullYear();

        for (let year = currentYear - 10; year < currentYear + 10; year++)
        {
            yearOptions.push(
                {
                    id: year.toString(),
                    label: year.toString(),
                    value: year
                });
        }

        return yearOptions;
    }

    @computed
    get quarterOptions(): StaticOption<number>[]
    {
        return [ 1, 2, 3, 4 ].map(
            quarter => ({
                id: quarter.toString(),
                label: this.baseStore.localizationStore.translate(`DatePeriod.Quarter.${quarter}`),
                value: quarter
            }));
    }

    @computed
    get monthOptions(): StaticOption<number>[]
    {
        let monthOptions = [];
        for (let index = 0; index <= 11; index++)
        {
            monthOptions.push({
                id: index.toString(),
                label: moment.months()[index],
                value: index
            });
        }

        return monthOptions;
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    selectType(type: string)
    {
        const value = Object.assign({}, this.baseStore.dataObject.value, { type: type });

        [value.from, value.to] = getDatesFromRange(value);

        this.baseStore.changeValue(value);
    }

    @action.bound
    selectYear(year: number)
    {
        const value = Object.assign({}, this.baseStore.dataObject.value);

        value.from = moment(this.momentFrom).set('year', year).startOf('year').toDate();
        value.to = moment(value.from).set('year', year + 1).startOf('year').toDate();

        this.baseStore.changeValue(value);
    }

    @action.bound
    selectQuarter(quarter: number)
    {
        const value = Object.assign({}, this.baseStore.dataObject.value);

        value.from = moment(this.momentFrom).set('quarter', quarter).startOf('quarter').toDate();
        value.to = moment(value.from).set('quarter', quarter + 1).startOf('quarter').toDate();

        this.baseStore.changeValue(value);
    }

    @action.bound
    selectMonth(month: number)
    {
        const value = Object.assign({}, this.baseStore.dataObject.value);

        value.from = moment(this.momentFrom).set('month', month).startOf('month').toDate();
        value.to = moment(value.from).set('month', month + 1).startOf('month').toDate();

        this.baseStore.changeValue(value);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
