import { Entity } from '../../../@Api/Model/Implementation/Entity';
import { EntityRelationship } from '../../../@Api/Model/Implementation/EntityRelationship';
import { BaseStore } from '../../../@Framework/Store/BaseStore';
import { EntityCacheInformation } from './EntityCacheInformation';
import { EntityCacheReferrer } from './EntityCacheReferrer';

export class EntityCacheReferrerService extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    cacheInformationByEntityUuid: Map<string, EntityCacheInformation>;
    cacheInformationByRelationshipUuid: Map<string, EntityCacheInformation>;
    cacheInformationsByReferrer: Map<EntityCacheReferrer, Set<EntityCacheInformation>>;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super();

        this.cacheInformationByEntityUuid = new Map<string, EntityCacheInformation>();
        this.cacheInformationByRelationshipUuid = new Map<string, EntityCacheInformation>();
        this.cacheInformationsByReferrer = new Map<EntityCacheReferrer, Set<EntityCacheInformation>>();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    insertCacheInformationForEntity(
        entity: Entity,
        referrer: EntityCacheReferrer
    )
    {
        const information =
            new EntityCacheInformation(
                new Set()
            );
        this.cacheInformationByEntityUuid
            .set(
                entity.uuid,
                information
            );
        this.addReferrerToCacheInformationForEntity(
            entity,
            referrer,
            information
        );
    }

    addReferrerToCacheInformationForEntity(
        entity: Entity,
        referrer: EntityCacheReferrer,
        providedInformation?: EntityCacheInformation
    )
    {
        const information = providedInformation ?? this.cacheInformationByEntityUuid.get(entity.uuid);

        if (information)
        {
            information.addReferrer(referrer);
            this.addCacheInformationToReferrer(
                referrer,
                information
            );
        }
        else
        {
            console.log('no cache information for entity:', entity.id, entity.name);
        }
    }

    removeCacheInformationForEntity(entity: Entity)
    {
        this.cacheInformationByEntityUuid.delete(entity.uuid);
    }

    insertCacheInformationForRelationship(
        relationship: EntityRelationship,
        referrer: EntityCacheReferrer
    )
    {
        const information =
            new EntityCacheInformation(
                new Set()
            );
        this.cacheInformationByRelationshipUuid
            .set(
                relationship.uuid,
                information
            );
        this.addReferrerToCacheInformationForRelationship(
            relationship,
            referrer,
            information
        );
    }

    addReferrerToCacheInformationForRelationship(
        relationship: EntityRelationship,
        referrer: EntityCacheReferrer,
        providedInformation?: EntityCacheInformation
    )
    {
        const information = providedInformation ?? this.cacheInformationByRelationshipUuid.get(relationship.uuid);

        if (information)
        {
            information.addReferrer(referrer);
            this.addCacheInformationToReferrer(
                referrer,
                information
            );
        }
        else
        {
            console.warn('no cache information for relationship:', relationship);
        }
    }

    removeCacheInformationForRelationship(relationship: EntityRelationship)
    {
        this.cacheInformationByRelationshipUuid.delete(relationship.uuid);
    }

    addCacheInformationToReferrer(
        referrer: EntityCacheReferrer,
        information: EntityCacheInformation
    )
    {
        let referredInformations = this.cacheInformationsByReferrer.get(referrer);

        if (referredInformations === undefined)
        {
            referredInformations = new Set<EntityCacheInformation>();

            this.cacheInformationsByReferrer.set(
                referrer,
                referredInformations
            );
        }

        referredInformations.add(information);
    }

    disposeReferrer(referrer: EntityCacheReferrer)
    {
        const informations = this.cacheInformationsByReferrer.get(referrer);

        if (informations !== undefined)
        {
            informations.forEach(
                information =>
                    information.removeReferrer(referrer)
            );
        }

        this.cacheInformationsByReferrer.delete(referrer);
    }

    clear()
    {
        this.cacheInformationByEntityUuid.clear();
        this.cacheInformationByRelationshipUuid.clear();
        this.cacheInformationsByReferrer.clear();
    }

    // ----------------------- Private logic ------------------------
}
