import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import Icon from '../../../../../../../../@Future/Component/Generic/Icon/Icon';
import EntityLink from '../../../../../Link/EntityLink';
import { makeStyles } from '@material-ui/styles';

const useStyles =
    makeStyles({
        wrapper:
            {
                padding: 5,
                paddingLeft: 0,
                fontSize: 13,
                whiteSpace: 'normal',
            },
        content:
            {
                whiteSpace: 'normal',
                paddingLeft: '8px',
                paddingRight: '8px'
            }
    });

export interface TrackItemTooltipRelationProps
{
    activity: Entity;
    relation: Entity;
}

export const TrackItemTooltipRelation: React.FC<TrackItemTooltipRelationProps> =
    observer(
        ({
            activity,
            relation
        }) =>
        {
            const types = useTypes();
            const classes = useStyles();

            const contact =
                useMemo(
                    () =>
                        activity.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.Contact),
                    [
                        types,
                        activity
                    ]
                );

            const project =
                useMemo(
                    () =>
                        activity.getRelatedEntityByDefinition(
                            true,
                            types.Activity.RelationshipDefinition.LinkedActivities
                        ),
                    [
                        activity
                    ]
                );

            const relationIcon = useMemo(
                () =>
                    relation?.entityType.isA(types.Relation.Organization.Type)
                        ? 'business'
                        : 'person',
                [
                    relation,
                    types
                ]
            );

            return <ViewGroup
                orientation="vertical"
                spacing={5}
                className={classes.wrapper}
            >
                {
                    project &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <Icon
                                    icon="work"
                                    size={22}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem
                                        className={classes.content}
                                    >
                                        <EntityLink
                                            entity={project}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                }
                <ViewGroupItem>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                        alignment="center"
                    >
                        <ViewGroupItem>
                            <Icon
                                icon={relationIcon}
                                size={22}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                            >
                                <ViewGroupItem
                                    className={classes.content}
                                >
                                    {
                                        relation &&
                                        <EntityLink
                                            entity={relation}
                                        />
                                    }
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    </ViewGroup>
                </ViewGroupItem>
                {
                    contact &&
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <Icon
                                    icon="person"
                                    size={22}
                                />
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={0}
                                >
                                    <ViewGroupItem
                                        className={classes.content}
                                    >
                                        <EntityLink
                                            entity={contact}
                                        />
                                    </ViewGroupItem>
                                </ViewGroup>
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                }
            </ViewGroup>;
        }
    );