import { ComputationEditorStore } from '../../ComputationEditorStore';
import { action, observable } from 'mobx';
import { ComputationContext } from '../../ComputationContext';
import { ComputationType } from '../ComputationType';
import { ComputationSpecification } from '../ComputationSpecification';
import { injectWithQualifier } from '../../../../../@Util/DependencyInjection/index';
import { ComputationTypeStore } from '../../ComputationTypeStore';
import { CompositeComputationOperationStore } from './Operation/CompositeComputationOperationStore';
import { MathematicalOperator } from '../../../DataObject/Model/MathematicalOperator';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { TextStore } from '../../../../Generic/Text/TextStore';

export class CompositeComputationEditorStore extends ComputationEditorStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('ComputationTypeStore') computationTypeStore: ComputationTypeStore;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    @observable operationStores = observable.array<CompositeComputationOperationStore>();
    @observable isAddMenuOpen: boolean;

    // ------------------------ Constructor -------------------------

    constructor(type: ComputationType,
                context: ComputationContext,
                specification: ComputationSpecification,
                operationStores: CompositeComputationOperationStore[])
    {
        super(type, context, specification);

        operationStores.forEach(store => (store.onDelete = deletedStore => (this.deleteOperation(deletedStore))));

        this.operationStores.push(...operationStores);

        this.ensureFirstOperationWithNoOperator();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    get calculationTextStore(): TextStore
    {
        return new TextStore({
            label: this.localizationStore.translate('Computation.Calculation'), // Calculation
            variant: 'body1',
            style:
            {
                flex: '1 1 auto'
            }
        });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    openAddMenu()
    {
        this.isAddMenuOpen = true;
    }

    @action.bound
    closeAddMenu()
    {
        this.isAddMenuOpen = false;
    }

    @action.bound
    addOperation(type: ComputationType)
    {
        this.operationStores.push(
            new CompositeComputationOperationStore(
                MathematicalOperator.Add,
                ComputationEditorStore.construct(
                    this.context,
                    { type: type.id() },
                    this.computationTypeStore),
                store => (this.deleteOperation(store))));

        this.ensureFirstOperationWithNoOperator();
    }

    @action.bound
    deleteOperation(operationStore: CompositeComputationOperationStore)
    {
        this.operationStores.remove(operationStore);

        this.ensureFirstOperationWithNoOperator();
    }

    @action.bound
    ensureFirstOperationWithNoOperator()
    {
        if (this.operationStores.length > 0)
        {
            this.operationStores[0].operator = null;
            this.operationStores.slice(1)
                .filter(store => store.operator == null)
                .forEach(store => store.operator = MathematicalOperator.Add);
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
