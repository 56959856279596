import Predicate from '../../../../../@Api/Automation/Function/Computation/Predicate/Predicate';
import { findAssistantFieldPath } from '../findAssistantFieldPath';
import { EntityPath } from '../../../Entity/Path/@Model/EntityPath';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { AssistantInstanceOfPredicate } from '../../Model/Predicate/AssistantInstanceOfPredicate';
import { findAssistantEntityTypeOrThrow } from '../findAssistantEntityTypeOrThrow';
import InstanceOfPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import EntityValueType from '../../../../../@Api/Automation/Value/Type/EntityValueType';
import Parameter from '../../../../../@Api/Automation/Parameter/Parameter';

export function findAssistantInstanceOfPredicate(
    predicate: AssistantInstanceOfPredicate,
    resultParameter: Parameter<EntityValueType>
): Predicate
{

    const rootPath = EntityPath.fromEntityType(resultParameter.type.type);
    const lhsFieldPath =
        findAssistantFieldPath(
            rootPath,
            predicate.field
        );
    const lhs =
        new ValueFromEntityComputation(
            resultParameter,
            lhsFieldPath
        );
    const entityType = findAssistantEntityTypeOrThrow(predicate.entityType);
    const entityValueType = new EntityValueType(entityType);

    return new InstanceOfPredicate(
        lhs,
        entityValueType
    );
}
