import { useContext, useMemo } from 'react';
import LocalizerContext from '../../../../../../../../@Service/Localization/LocalizerContext';
import { Currency } from '../../../../../../../../@Api/Localization/Currency';

export function useBaseCurrency(): Currency
{
    const localizer = useContext(LocalizerContext);

    return useMemo(
        () =>
            localizer.baseCurrency,
        [
            localizer.baseCurrency,
        ]
    );
}