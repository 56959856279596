import { observable } from 'mobx';
import { Computation } from '../../Computation';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { EntityPath } from '../../../../Entity/Path/@Model/EntityPath';
import AutomationDependencyContext from '../../../../../../@Api/Automation/AutomationDependencyContext';
import AggregateOfCollectionComputation from '../../../../../../@Api/Automation/Function/Computation/AggregateOfCollectionComputation';
import { getParameterInOldComputation } from '../../../Api/getParameterInOldComputation';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import MappedCollectionComputation from '../../../../../../@Api/Automation/Function/Computation/MappedCollectionComputation';
import uuid from '../../../../../../@Util/Id/uuid';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';

export class EntityAggregateComputation extends Computation
{
    // ------------------------- Properties -------------------------

    @observable entityContextParameter?: string;
    @observable aggregate: Aggregate;
    @observable entityPath: EntityPath;
    @observable computation: Computation;

    // ------------------------ Constructor -------------------------

    constructor(entityContextParameter: string,
                aggregate: Aggregate,
                entityPath: EntityPath,
                computation: Computation)
    {
        super('EntityAggregate');

        this.entityContextParameter = entityContextParameter;
        this.aggregate = aggregate;
        this.entityPath = entityPath;
        this.computation = computation;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------


    async initialize(): Promise<any>
    {
        return this.computation?.initialize();
    }

    async toNewComputation(context: AutomationDependencyContext)
    {
        const parameter =
            getParameterInOldComputation(
                context,
                this.entityContextParameter
            );
        const collection =
            new ValueFromEntityComputation(
                parameter,
                this.entityPath.field()
            );
        const elementParameter =
            MappedCollectionComputation.getElementParameter(
                collection,
                uuid()
            );
        const elementContext =
            new AutomationDependencyContext(
                new ParameterDictionary([
                    ...context.parameterDictionary.parameters
                        .filter(
                            checkParameter =>
                                checkParameter !== parameter
                        ),
                    elementParameter
                ])
            );
        const elementComputation = await this.computation.toNewComputation(elementContext);

        return new AggregateOfCollectionComputation(
            new MappedCollectionComputation(
                collection,
                elementParameter,
                elementComputation
            ),
            this.aggregate
        );
    }

    // ----------------------- Private logic ------------------------
}
