import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import TypeMapping from './TypeMapping/TypeMapping';
import { EntityPath } from '../Path/@Model/EntityPath';
import FileSpecificationContext from './Context/FileSpecificationContext';
import ImportResultContext from './Context/ImportResultContext';
import useTypes from '../Type/Api/useTypes';
import RelationshipFieldMapping from './Model/FieldMapping/Relationship/RelationshipFieldMapping';
import uuid from '../../../../@Util/Id/uuid';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Card from '../../../../@Future/Component/Generic/Card/Card';
import Icon from '../../../../@Future/Component/Generic/Icon/Icon';
import { textSecondaryColor } from '../../../../@Resource/Theme/Theme';
import CurrentUserContext from '../../User/CurrentUserContext';
import SourceFieldMapping from './Model/FieldMapping/SourceField/SourceFieldMapping';
import SourceField from './SourceField/SourceField';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import CardHeader from '../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import useDividerGlue from '../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import TypeReferenceFieldMapping from './Model/FieldMapping/Relationship/TypeReferenceFieldMapping';
import FileDropZone from '../../../../@Future/Component/Generic/Input/FileDropZone/FileDropZone';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { createTransactionalModel } from '../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import EntityTypeContext from '../Type/EntityTypeContext';
import Mapping from './Model/Mapping';
import { FileValue } from '../../DataObject/Type/File/FileValue';
import FileSpecification from './Model/FileSpecification';
import styles from './TypeImport.module.scss';
import { computed } from 'mobx';
import { openEntity } from '../@Util/openEntity';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import ImportResult from './Model/ImportResult';
import IconButton from '../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import Divider from '../../../../@Future/Component/Generic/Divider/Divider';
import PrimaryButton from '../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import ImportSummaryContext from './Context/ImportSummaryContext';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import EntityMenuButton from '../Viewer/Shared/MenuButton/MenuButton';
import EntityFieldMapping from './Model/FieldMapping/Constant/EntityFieldMapping';
import ImportSummary from './ImportSummary/ImportSummary';
import { default as ImportSummaryModel } from './Model/ImportSummary';
import buildMappingForType from './Api/buildMappingForType';
import DangerButton from '../../../../@Future/Component/Generic/Button/Variant/DangerButton/DangerButton';
import { FeedbackStore } from '../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import FileContext from './Context/FileContext';
import ImportEntityContext from './Context/ImportEntityContext';
import initializeInitialPhase from '../../../../@Api/Entity/Bespoke/Datastore/Phase/initializeInitialPhase';
import { EntityExpansionBuilder } from '../Selection/Builder/EntityExpansionBuilder';
import useRelatedEntity from '../../../../@Api/Entity/Hooks/useRelatedEntity';
import useEntityValue from '../../../../@Api/Entity/Hooks/useEntityValue';
import MappingContext from './Context/MappingContext';
import performAction from '../../../../@Api/Entity/performAction';
import WarningButton from '../../../../@Future/Component/Generic/Button/Variant/WarningButton/WarningButton';
import useDebouncedCallback from '../../../../@Util/Debounce/useDebouncedCallback';
import MenuButton from '../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../@Future/Component/Generic/Menu/Item/Item';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../@Api/Localization/localizeText';
import { useNewCommitContext } from '../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';
import useAsyncResult from '../../../../@Util/Async/useAsyncResult';

export interface TypeImportProps
{
    entityType?: EntityType;
    entity?: Entity;
}

const TypeImport: React.FC<TypeImportProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);
        const entityTypeStore = useContext(EntityTypeContext);

        const [ mapping, setMapping ] = useState<Mapping | undefined>();
        const importEntity = props.entity;

        const [ isLoading, setLoading ] = useState(false);

        const onDrop =
            useCallback(
                (files: File[]) =>
                {
                    const file = files.find(() => true);

                    if (file && mapping)
                    {
                        const fileName = file.name.toLowerCase();

                        if (fileName.endsWith('.xls')
                            || fileName.endsWith('.xlsx')
                            || fileName.endsWith('.csv'))
                        {
                            setLoading(true);

                            const importEntity =
                                createTransactionalModel(
                                    new Entity(types.Import.Type)
                                        .initialize(entityTypeStore));

                            importEntity.updateRelationship(
                                true,
                                types.EntityType.RelationshipDefinition.Imports,
                                createTransactionalModel(props.entityType.entity));

                            importEntity.setValueByField(
                                types.Import.Field.Date,
                                new Date());

                            importEntity.setValueByField(
                                types.Import.Field.File,
                                FileValue.fromFile(file));

                            importEntity.setValueByField(
                                types.Import.Field.Mapping,
                                mapping.toDescriptor());

                            return initializeInitialPhase(importEntity)
                                .then(
                                    () =>
                                        importEntity.checkAndDoCommit()
                                            .then(
                                                () =>
                                                    openEntity(importEntity))
                                            .finally(
                                                () =>
                                                {
                                                    setLoading(false);
                                                }));
                        }
                        else
                        {
                            loadModuleDirectly(FeedbackStore)
                                .enqueueSnackbar(
                                    <LocalizedText
                                        code="Import.InvalidImportFile"
                                        value="Ongeldig importbestand. Je kan enkel een .xls, .xlsx of een .csv uploaden."
                                    />,
                                    {
                                        variant: 'error'
                                    });
                        }
                    }
                },
                [
                    types,
                    entityTypeStore,
                    mapping,
                    setLoading,
                    props.entityType
                ]);

        const fileSpecification =
            useComputed(
                () =>
                    importEntity && importEntity.hasValueForField(types.Import.Field.FileSpecification)
                        ?
                            FileSpecification.fromDescriptor(
                                importEntity.getObjectValueByField(types.Import.Field.FileSpecification))
                        :
                            undefined,
                [
                    importEntity,
                    types
                ]);

        const fileValue =
            useComputed<FileValue | undefined>(
                () =>
                    importEntity && importEntity.getObjectValueByField(types.Import.Field.File),
                [
                    importEntity,
                    types
                ]);

        const importPhase =
            useRelatedEntity(
                importEntity,
                types.Import.RelationshipDefinition.Phase,
                false);

        const importPhaseCode =
            useEntityValue<string>(
                importPhase,
                types.Datastore.Field.Code);

        useAsyncResult(
            async () =>
            {
                if (importEntity && fileSpecification)
                {
                    const dependencyContext = new AutomationDependencyContext(fileSpecification.parameters);

                    setMapping(
                        await Mapping.fromDescriptor(
                            importEntity.getObjectValueByField(types.Import.Field.Mapping),
                            dependencyContext,
                            importEntity
                        )
                    );

                    await new EntityExpansionBuilder(
                        importEntity.entityType,
                        [
                            importEntity
                        ],
                        [
                            EntityPath.fromEntity(importEntity)
                                .joinTo(
                                    types.Import.RelationshipDefinition.Phase,
                                    false
                                )
                        ])
                        .expand();

                    return;
                }

                async function build()
                {
                    const typeMapping = buildMappingForType(props.entityType);

                    if (props.entityType.isA(types.Relationship.Type))
                    {
                        if (props.entityType.isA(types.Relationship.Person.Contact.Type))
                        {
                            const grandparentRelationshipMapping = buildMappingForType(types.Relationship.Organization.Type);

                            grandparentRelationshipMapping.fieldMappings.push(

                                new EntityFieldMapping(
                                    uuid(),
                                    EntityPath.fromEntityType(grandparentRelationshipMapping.entityType)
                                        .joinTo(
                                            types.Relation.RelationshipDefinition.Relationships,
                                            true)
                                        .field(),
                                    currentUserStore.environmentEntity.uuid));

                            const parentRelationMapping =
                                grandparentRelationshipMapping
                                    .fieldMappings
                                    .map(
                                        fieldMapping =>
                                            fieldMapping instanceof RelationshipFieldMapping
                                                && fieldMapping.typeMapping)
                                    .find(typeMapping => typeMapping);

                            typeMapping.fieldMappings.push(
                                new TypeReferenceFieldMapping(
                                    uuid(),
                                    EntityPath.fromEntityType(typeMapping.entityType)
                                        .joinTo(
                                            types.Relation.RelationshipDefinition.Relationships,
                                            true)
                                        .field(),
                                    parentRelationMapping.id));

                            setMapping(new Mapping([ typeMapping, grandparentRelationshipMapping ]));

                            return;
                        }
                        else
                        {
                            typeMapping.fieldMappings.push(
                                new EntityFieldMapping(
                                    uuid(),
                                    EntityPath.fromEntityType(props.entityType)
                                        .joinTo(
                                            types.Relation.RelationshipDefinition.Relationships,
                                            true)
                                        .field(),
                                    currentUserStore.environmentEntity.uuid));
                        }
                    }
                    else if (props.entityType.isA(types.Activity.Type))
                    {
                        const relationshipMapping = buildMappingForType(types.Relationship.Type);
                        const relationMapping =
                            relationshipMapping
                                .fieldMappings
                                .map(
                                    fieldMapping =>
                                        fieldMapping instanceof RelationshipFieldMapping
                                        && fieldMapping.typeMapping)
                                .find(typeMapping => typeMapping);

                        const contactRelationshipMapping = buildMappingForType(types.Relationship.Person.Contact.Type);

                        if (relationMapping)
                        {
                            contactRelationshipMapping.fieldMappings.push(
                                new TypeReferenceFieldMapping(
                                    uuid(),
                                    EntityPath.fromEntityType(contactRelationshipMapping.entityType)
                                        .joinTo(
                                            types.Relation.RelationshipDefinition.Relationships,
                                            true)
                                        .field(),
                                    relationMapping.id));
                        }

                        typeMapping.fieldMappings.push(
                            new TypeReferenceFieldMapping(
                                uuid(),
                                EntityPath.fromEntityType(typeMapping.entityType)
                                    .joinTo(
                                        types.Relationship.RelationshipDefinition.Activities,
                                        true)
                                    .field(),
                                relationshipMapping.id));

                        typeMapping.fieldMappings.push(
                            new TypeReferenceFieldMapping(
                                uuid(),
                                EntityPath.fromEntityType(typeMapping.entityType)
                                    .joinTo(
                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                        true)
                                    .field(),
                                contactRelationshipMapping.id));

                        setMapping(
                            new Mapping([
                                typeMapping,
                                relationshipMapping,
                                contactRelationshipMapping
                            ]));

                        return;
                    }

                    setMapping(new Mapping([ typeMapping ]));
                }

                await build();
            },
            [
                props.entityType,
                importEntity,
                fileSpecification,
                types,
                currentUserStore,
                setMapping
            ]
        );
        const usedSourceFields =
            useComputed(
                () =>
                    new Set(
                        (mapping && fileSpecification
                            ?
                                mapping
                                    .typeMappings
                                    .map(
                                        typeMapping =>
                                            typeMapping.getAllFieldMappings())
                                    .reduce((a, b) => a.concat(b), [])
                            :
                                [])
                            .filter(
                                fieldMapping =>
                                    fieldMapping instanceof SourceFieldMapping)
                            .map(
                                fieldMapping =>
                                    fileSpecification.fieldById.get((fieldMapping as SourceFieldMapping).sourceFieldId))),
                [
                    mapping,
                    fileSpecification
                ]);

        const [ , setRevisionId ] = useState<string>(() => uuid());
        const [ isCommitting, setCommitting ] = useState(false);

        const saveImportDebounced =
            useDebouncedCallback(
                (mapping: any) =>
                {
                    importEntity.setValueByField(
                        types.Import.Field.Mapping,
                        mapping);

                    setCommitting(true);

                    importEntity.checkAndDoCommit()
                        .then(
                            () =>
                            {
                                setCommitting(false);
                                setRevisionId(uuid());
                            });
                },
                [
                    importEntity,
                    types,
                    mapping,
                    setCommitting,
                    setRevisionId
                ],
                3000);

        useEffect(
            () =>
                computed(
                    () =>
                        mapping && mapping.toDescriptor())
                    .observe(
                        change =>
                        {
                            if (importEntity && mapping)
                            {
                                saveImportDebounced(change.newValue);
                            }
                        }),
            [
                importEntity,
                mapping,
                saveImportDebounced
            ]);

        const [ previewIdx, setPreviewIdx ] = useState(0);
        const [ importResult, setImportResult ] = useState<ImportResult | undefined>();

        const loadPreviewIdx =
            useCallback(
                (idx: number) =>
                {
                    if (importEntity)
                    {
                        return performAction<ImportResult[]>(
                            importEntity,
                            {
                                code: 'Import.Preview',
                                parameters: {
                                    offset: idx,
                                    limit: 1
                                },
                                resultType: ImportResult as any
                            })
                            .then(
                                result =>
                                {
                                    const results = result.result;

                                    const importResult = results.find(() => true);

                                    if (importResult)
                                    {
                                        importResult.initialize();
                                        setPreviewIdx(idx);
                                        setImportResult(importResult);
                                    }
                                });
                    }
                },
                [
                    importEntity,
                    setPreviewIdx,
                    setImportResult
                ]);

        useEffect(
            () =>
            {
                if (importEntity)
                {
                    loadPreviewIdx(0);
                }
            },
            [
                importEntity,
                loadPreviewIdx
            ]);

        const jumpToPreviewIdx =
            useCallback(
                () =>
                {
                    const value = window.prompt(localizeText('Import.EnterLineNumber', 'Vul hier het gewenste regelnummer in.'));

                    const idx = parseInt(value);

                    if (!isNaN(idx))
                    {
                        return loadPreviewIdx(idx - 1);
                    }
                },
                [
                    loadPreviewIdx
                ]);

        // useEffect(
        //     () =>
        //     {
        //         if (importEntity)
        //         {
        //             performAction<ImportResult[]>(
        //                 importEntity,
        //                 {
        //                     code: 'Import.Preview',
        //                     parameters: {
        //                         offset: previewIdx,
        //                         limit: 1
        //                     },
        //                     resultType: ImportResult as any
        //                 })
        //                 .then(
        //                     result =>
        //                     {
        //                         const results = result.result;
        //
        //                         const importResult = results.find(() => true);
        //
        //                         if (importResult)
        //                         {
        //                             importResult.initialize();
        //                             setImportResult(importResult);
        //                         }
        //                     });
        //         }
        //     },
        //     [
        //         importEntity,
        //         setImportResult,
        //         previewIdx,
        //         revisionId
        //     ]);

        const importSummary =
            useEntityValue<ImportSummaryModel>(
                importEntity,
                types.Import.Field.Result);

        const testImport =
            useCallback(
                () =>
                    performAction(
                        importEntity,
                        {
                            code: 'Import.Test',
                            name: localizeText('Import.Test', 'Import testen'),
                            resultType: ImportSummaryModel
                        }),
                [
                    importEntity
                ]);

        const startImport =
            useCallback(
                () =>
                    performAction(
                        importEntity,
                        {
                            code: 'Import.Start',
                            name: localizeText('Import.Start', 'Import starten'),
                            resultType: ImportSummaryModel
                        }),
                [
                    importEntity
                ]);

        const rollbackImport =
            useCallback(
                () =>
                {
                    if (window.confirm(
                        localizeText(
                            'Import.RollbackConfirmation',
                            'Weet u zeker dat u de ongedaan wilt maken? Records die zijn geïmporteerd en eventueel later gewijzigd zijn zullen permanent verwijderd worden.')))
                    {
                        return performAction(
                            importEntity,
                            {
                                code: 'Import.Rollback',
                                name: localizeText('Import.StartRollback', 'Import terugdraaien starten')
                            });
                    }
                },
                [
                    importEntity
                ]);

        const pauseImport =
            useCallback(
                () =>
                    performAction(
                        importEntity,
                        {
                            code: 'Import.Pause',
                            name: localizeText('Import.Pause', 'Import pauzeren'),
                            resultType: ImportSummaryModel
                        }),
                [
                    importEntity
                ]);

        const resumeImport =
            useCallback(
                () =>
                    performAction(
                        importEntity,
                        {
                            code: 'Import.Resume',
                            name: localizeText('Import.Resume', 'Import hervatten'),
                            resultType: ImportSummaryModel
                        }),
                [
                    importEntity
                ]);

        const dividerGlue = useDividerGlue();
        const commitContext = useNewCommitContext();

        return <FileSpecificationContext.Provider
            value={fileSpecification}
        >
            <MappingContext.Provider
                value={mapping}
            >
                <ImportResultContext.Provider
                    value={importResult}
                >
                    <ImportSummaryContext.Provider
                        value={importSummary}
                    >
                        <ImportEntityContext.Provider
                            value={importEntity}
                        >
                            <FileContext.Provider
                                value={fileValue}
                            >
                                <div
                                    className={styles.root}
                                >
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <CardHeader>
                                                        {mapping && mapping.typeMappings[0].entityType.getName(true)} 
                                                        <LocalizedText 
                                                            code="Generic.Import" 
                                                            value="Importeren"
                                                        />
                                                    </CardHeader>
                                                </ViewGroupItem>
                                                {
                                                    importPhase &&
                                                        <ViewGroupItem>
                                                            <strong>Status:</strong> {importPhase.name}
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    (importPhaseCode === 'Concept' || importPhaseCode === 'Testing' || importPhaseCode === 'Tested' || importPhaseCode === 'RolledBack') &&
                                                        <ViewGroupItem>
                                                            <PrimaryButton
                                                                label={
                                                                    <LocalizedText
                                                                        code="Import.Test"
                                                                        value="Test import"
                                                                    />
                                                                }
                                                                onClick={testImport}
                                                                loading={importPhaseCode === 'Testing'}
                                                                disabled={isCommitting}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    (importPhaseCode === 'Concept' || importPhaseCode === 'Tested' || importPhaseCode === 'Running' || importPhaseCode === 'RolledBack') &&
                                                        <ViewGroupItem>
                                                            <PrimaryButton
                                                                label={
                                                                    <LocalizedText
                                                                        code="Import.Start"
                                                                        value="Import starten"
                                                                    />
                                                                }
                                                                onClick={startImport}
                                                                loading={importPhaseCode === 'Running'}
                                                                disabled={isCommitting}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    (importPhaseCode === 'Running') &&
                                                        <ViewGroupItem>
                                                            <WarningButton
                                                                label={
                                                                    <LocalizedText
                                                                        code="Import.Pause"
                                                                        value="Import pauzeren"
                                                                    />
                                                                }
                                                                onClick={pauseImport}
                                                                disabled={isCommitting}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    (importPhaseCode === 'Paused') &&
                                                        <ViewGroupItem>
                                                            <PrimaryButton
                                                                label={
                                                                    <LocalizedText
                                                                        code="Import.Resume"
                                                                        value="Import hervatten"
                                                                    />
                                                                }
                                                                onClick={resumeImport}
                                                                disabled={isCommitting}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    (importPhaseCode === 'Paused' || importPhaseCode === 'Finished' || importPhaseCode === 'PartiallyFinished' || importPhaseCode === 'RollingBack') &&
                                                        <ViewGroupItem>
                                                            <DangerButton
                                                                label={
                                                                    <LocalizedText
                                                                        code="Import.Rollback"
                                                                        value="Import ongedaan maken"
                                                                    />
                                                                }
                                                                onClick={rollbackImport}
                                                                loading={importPhaseCode === 'RollingBack'}
                                                                disabled={isCommitting}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                                {
                                                    props.entity &&
                                                        <ViewGroupItem>
                                                            <EntityMenuButton
                                                                entity={props.entity}
                                                            />
                                                        </ViewGroupItem>
                                                }
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <ViewGroup
                                                        orientation="horizontal"
                                                        spacing={15}
                                                        alignment="center"
                                                    >
                                                        <ViewGroupItem
                                                            ratio={1}
                                                        >
                                                            {
                                                                !importEntity &&
                                                                    <ViewGroup
                                                                        orientation="vertical"
                                                                        spacing={15}
                                                                    >
                                                                        <ViewGroupItem>
                                                                            <Card
                                                                                inset
                                                                            >
                                                                                {
                                                                                    isLoading
                                                                                        ?
                                                                                            <Centered
                                                                                                horizontal
                                                                                            >
                                                                                                <Loader />
                                                                                            </Centered>
                                                                                        :
                                                                                            <FileDropZone
                                                                                                onDrop={onDrop}
                                                                                            />
                                                                                }
                                                                            </Card>
                                                                        </ViewGroupItem>
                                                                    </ViewGroup>
                                                            }
                                                            {
                                                                fileSpecification &&
                                                                    <Card
                                                                        classes={{
                                                                            root: styles.sourceFields
                                                                        }}
                                                                    >
                                                                        <CardInset>
                                                                            <ViewGroup
                                                                                orientation="horizontal"
                                                                                spacing={15}
                                                                            >
                                                                                <ViewGroupItem
                                                                                    ratio={1}
                                                                                >
                                                                                    <CardHeader>
                                                                                        {fileValue && fileValue.name}
                                                                                    </CardHeader>
                                                                                </ViewGroupItem>
                                                                                <ViewGroupItem>
                                                                                    <ViewGroup
                                                                                        orientation="horizontal"
                                                                                        spacing={5}
                                                                                        alignment="center"
                                                                                    >
                                                                                        <ViewGroupItem>
                                                                                            <IconButton
                                                                                                icon="keyboard_arrow_left"
                                                                                                onClick={() => loadPreviewIdx(previewIdx - 1)}
                                                                                                tooltip={
                                                                                                    <LocalizedText
                                                                                                        code="Generic.Previous"
                                                                                                        value="Vorige"
                                                                                                    />
                                                                                                }
                                                                                                disabled={previewIdx === 0}
                                                                                            />
                                                                                        </ViewGroupItem>
                                                                                        <ViewGroupItem>
                                                                                            {previewIdx + 1}{fileSpecification.numberOfRecords === undefined ? '' : `/${fileSpecification.numberOfRecords}`}
                                                                                        </ViewGroupItem>
                                                                                        <ViewGroupItem>
                                                                                            <IconButton
                                                                                                icon="keyboard_arrow_right"
                                                                                                onClick={() => loadPreviewIdx(previewIdx + 1)}
                                                                                                tooltip={
                                                                                                    <LocalizedText
                                                                                                        code="Generic.Next"
                                                                                                        value="Volgende"
                                                                                                    />
                                                                                                }
                                                                                                disabled={fileSpecification.numberOfRecords !== undefined && previewIdx === fileSpecification.numberOfRecords - 1}
                                                                                            />
                                                                                        </ViewGroupItem>
                                                                                        <ViewGroupItem>
                                                                                            <MenuButton>
                                                                                                <Menu>
                                                                                                    <Item
                                                                                                        name={
                                                                                                            <LocalizedText
                                                                                                                code="Import.GoToLineNumber"
                                                                                                                value="Ga naar regelnummer..."
                                                                                                            />
                                                                                                        }
                                                                                                        onClick={jumpToPreviewIdx}
                                                                                                    />
                                                                                                </Menu>
                                                                                            </MenuButton>
                                                                                        </ViewGroupItem>
                                                                                    </ViewGroup>
                                                                                </ViewGroupItem>
                                                                            </ViewGroup>
                                                                        </CardInset>
                                                                        <Divider />
                                                                        <ViewGroup
                                                                            orientation="vertical"
                                                                            spacing={0}
                                                                            glue={dividerGlue}
                                                                        >
                                                                            {
                                                                                fileSpecification.fields
                                                                                    .map(
                                                                                        sourceField =>
                                                                                            <ViewGroupItem
                                                                                                key={sourceField.id}
                                                                                            >
                                                                                                <SourceField
                                                                                                    sourceField={sourceField}
                                                                                                    usedSourceFields={usedSourceFields}
                                                                                                    result={importResult}
                                                                                                />
                                                                                            </ViewGroupItem>)
                                                                            }
                                                                        </ViewGroup>
                                                                    </Card>
                                                            }
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <Icon
                                                                icon="arrow_forward"
                                                                color={textSecondaryColor}
                                                            />
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                                <ViewGroupItem
                                                    ratio={3}
                                                >
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={15}
                                                    >
                                                        {
                                                            importEntity && mapping &&
                                                                <ViewGroupItem>
                                                                    <ImportSummary
                                                                        importEntity={importEntity}
                                                                        importPhase={importPhaseCode}
                                                                        mapping={mapping}
                                                                        summary={importSummary}
                                                                        onSelect={loadPreviewIdx}
                                                                    />
                                                                </ViewGroupItem>
                                                        }
                                                        {
                                                            mapping &&
                                                                <ViewGroupItem>
                                                                    <ViewGroup
                                                                        orientation="horizontal"
                                                                        spacing={15}
                                                                    >
                                                                        {
                                                                            mapping
                                                                                .typeMappings
                                                                                .map(
                                                                                    (typeMapping, idx) =>
                                                                                        <ViewGroupItem
                                                                                            key={typeMapping.id}
                                                                                            ratio={1}
                                                                                        >
                                                                                            <TypeMapping
                                                                                                mapping={mapping}
                                                                                                typeMapping={typeMapping}
                                                                                                commitContext={commitContext}
                                                                                                root={idx === 0}
                                                                                            />
                                                                                        </ViewGroupItem>)
                                                                        }
                                                                    </ViewGroup>
                                                                </ViewGroupItem>
                                                        }
                                                    </ViewGroup>
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </div>
                            </FileContext.Provider>
                        </ImportEntityContext.Provider>
                    </ImportSummaryContext.Provider>
                </ImportResultContext.Provider>
            </MappingContext.Provider>
        </FileSpecificationContext.Provider>;
    };

export default observer(TypeImport);
