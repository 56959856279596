import Layout from '../Layout';
import localizeText from '../../Localization/localizeText';
import Validation from '../../Automation/Validation/Validation';
import Dependency from '../../Automation/Parameter/Dependency';
import { observable } from 'mobx';

export default class InvalidLayout extends Layout
{
    // ------------------------- Properties -------------------------

    message: string;
    debugInformation?: string;
    @observable.ref descriptor: any;

    // ------------------------ Constructor -------------------------

    constructor(
        message: string,
        debugInformation: string | undefined,
        descriptor: any
    )
    {
        super();
        this.message = message;
        this.debugInformation = debugInformation;
        this.descriptor = descriptor;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getName(): string
    {
        return localizeText('Layout.Invalid', 'Invalide layout');
    }

    validate(): Validation[]
    {
        return [
            new Validation(
                'Error',
                'Invalid layout'
            )
        ];
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    toDescriptor()
    {
        return this.descriptor;
    }

    // ----------------------- Private logic ------------------------
}
