import { BaseStore, CallbackType, getOrCompute, getOrComputeProperty, PropType } from '../../../@Framework/Store/BaseStore';
import { action, computed, observable } from 'mobx';
import { StoreState } from '../../../@Framework/Store/@Model/StoreState';
import { CSSProperties } from 'react';
import {
    typographyBucketName,
    typographyCardHeader,
    typographyChip,
    typographyEntityName,
    typographyExpansionPanelHeader,
    typographyExpansionPanelHeaderInBackground,
    typographyExpansionPanelHeaderRoot,
    typographyLabel,
    typographyLargerUnderline,
    typographyOverline,
    typographyTab,
    typographyTimelineDate,
    typographyTimelineLabel,
    typographyUnderline,
} from '../../../@Resource/Theme/Typography';
import { primaryColor, textColor, textSecondaryColor, weightBold, weightMedium, weightRegular, weightSemiBold, weightThin } from '../../../@Resource/Theme/Theme';
import { blue } from '../../../@Resource/Theme/Blue';
import { ViewComponent } from '../ViewStack/Model/ViewComponent';
import { ButtonStore } from '../Button/ButtonStore';

interface TextTemplate
{
    chunks: string[];
    indices: number[];
}

export const TextColors = new Map<string, string>();
TextColors.set('default', textColor);
TextColors.set('primary', primaryColor);
TextColors.set('secondary', textSecondaryColor);

export const CustomTextVariants = new Map<string, CSSProperties>();
CustomTextVariants.set('overline', typographyOverline);
CustomTextVariants.set('underline', typographyUnderline);
CustomTextVariants.set('largerUnderline', typographyLargerUnderline);
CustomTextVariants.set('label', typographyLabel);
CustomTextVariants.set('tab', typographyTab);
CustomTextVariants.set('chip', typographyChip);
CustomTextVariants.set('cardHeader', typographyCardHeader);
CustomTextVariants.set('expansionPanelHeader', typographyExpansionPanelHeader);
CustomTextVariants.set('expansionPanelHeaderRoot', typographyExpansionPanelHeaderRoot);
CustomTextVariants.set('expansionPanelHeaderInBackground', typographyExpansionPanelHeaderInBackground);
CustomTextVariants.set('bucketName', typographyBucketName);
CustomTextVariants.set('timelineDate', typographyTimelineDate);
CustomTextVariants.set('timelineLabel', typographyTimelineLabel);
CustomTextVariants.set('entityName', typographyEntityName);

export const DefaultTextColorByVariant = new Map<string, string>();
DefaultTextColorByVariant.set('underline', 'secondary');
DefaultTextColorByVariant.set('overline', '#80868b');

export const TextWeights = new Map<TextWeight, number>();
TextWeights.set('thin', weightThin);
TextWeights.set('regular', weightRegular);
TextWeights.set('medium', weightMedium);
TextWeights.set('semibold', weightSemiBold);
TextWeights.set('bold', weightBold);

export type TextVariant =
    any
    | 'caption'
    | 'button'
    | 'overline'
    | 'underline'
    | 'largerUnderline'
    | 'label'
    | 'tab'
    | 'chip'
    | 'cardHeader'
    | 'expansionPanelHeader'
    | 'expansionPanelHeaderRoot'
    | 'expansionPanelHeaderInBackground'
    | 'bucketName'
    | 'timelineDate'
    | 'timelineLabel'
    | 'entityName';
export type TextWeight = 'thin' | 'regular' | 'medium' | 'semibold' | 'bold';
export type TextAlignment = 'left' | 'center' | 'right';
export type TextSize = number | string;
export type TextHoverDecoration = 'link' | 'field';

export interface TextProps
{
    label?: PropType<TextStore, TextProps, string>;
    buttons?: PropType<TextStore, TextProps, ButtonStore[]>;
    onClick?: CallbackType<TextStore, TextProps, Promise<any>>;
    style?: PropType<TextStore, TextProps, CSSProperties>;
    innerStyle?: PropType<TextStore, TextProps, CSSProperties>;
    childTextStores?: PropType<TextStore, TextProps, TextStore[]>;
    stopPropagation?: PropType<TextStore, TextProps, boolean>;
    isDense?: PropType<TextStore, TextProps, boolean>;
    isInline?: PropType<TextStore, TextProps, boolean>;
    isItalic?: PropType<TextStore, TextProps, boolean>;
    isLowercase?: PropType<TextStore, TextProps, boolean>;
    isUppercase?: PropType<TextStore, TextProps, boolean>;
    noWrap?: PropType<TextStore, TextProps, boolean>;
    variant?: PropType<TextStore, TextProps, TextVariant>;
    weight?: PropType<TextStore, TextProps, TextWeight>;
    size?: PropType<TextStore, TextProps, TextSize>;
    lineHeight?: PropType<TextStore, TextProps, number | string>;
    alignment?: PropType<TextStore, TextProps, TextAlignment>;
    color?: PropType<TextStore, TextProps, string>;
    provider?: () => Promise<string>;
    isVisible?: PropType<TextStore, TextProps, boolean>;
    hoverDecoration?: PropType<TextStore, TextProps, TextHoverDecoration>;
    viewComponent?: PropType<TextStore, TextProps, ViewComponent>;
    tooltip?: PropType<TextStore, TextProps, string>;
}

const defaultProps: Partial<TextProps> =
{
    childTextStores: [],
    buttons: [],
    stopPropagation:
        store =>
            store.view === undefined,
    isDense: false,
    isInline: false,
    isItalic: false,
    variant: 'body2',
    isVisible: true,
    isLowercase: false,
    isUppercase: false,
    hoverDecoration: undefined,
    color:
        store =>
        {
            if (store.isLink) // && store.hoverDecoration === 'link')
            {
                if (store.isLoading)
                {
                    return 'default';
                }
                else
                {
                    if (store.isHovering)
                    {
                        return blue[200];
                    }
                    else
                    {
                        return blue[500];
                    }
                }
            }
            else
            {
                if (DefaultTextColorByVariant.has(store.variant))
                {
                    return DefaultTextColorByVariant.get(store.variant);
                }
                else
                {
                    return 'inherit';
                }
            }
        }
};

export class TextStore<P extends TextProps = TextProps> extends BaseStore<P>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable providedLabel: string;
    @observable isHovering: boolean;
    @observable viewComponent: ViewComponent;

    // ------------------------ Constructor -------------------------

    constructor(props: P, givenDefaultProps?: any)
    {
        super(props,  { ...defaultProps, ...givenDefaultProps});
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        if (this.props.provider)
        {
            return this.props.provider()
                .then(this.setProvidedLabel);
        }
        else
        {
            return Promise.resolve();
        }
    }

    // -------------------------- Computed --------------------------

    @computed
    get label(): string
    {
        return this.providedLabel || getOrComputeProperty(this, 'label');
    }

    @computed
    get style(): CSSProperties
    {
        return getOrComputeProperty(this, 'style');
    }

    @computed
    get innerStyle(): CSSProperties
    {
        return getOrComputeProperty(this, 'innerStyle');
    }

    @computed
    get stopPropagation(): boolean
    {
        return getOrCompute(this, this.props.stopPropagation);
    }

    @computed
    get isDense(): boolean
    {
        return getOrComputeProperty(this, 'isDense');
    }

    @computed
    get lineHeight(): number | string
    {
        return getOrCompute(this, this.props.lineHeight);

    }

    @computed
    get isInline(): boolean
    {
        return getOrComputeProperty(this, 'isInline');
    }

    @computed
    get isItalic(): boolean
    {
        return getOrComputeProperty(this, 'isItalic');
    }

    @computed
    get tooltip(): string
    {
        return getOrCompute(this, this.props.tooltip);
    }

    @computed
    get isLowercase(): boolean
    {
        return getOrComputeProperty(this, 'isLowercase');
    }

    @computed
    get isUppercase(): boolean
    {
        return getOrComputeProperty(this, 'isUppercase');
    }

    @computed
    get variant(): TextVariant
    {
        return getOrComputeProperty(this, 'variant');
    }

    @computed
    get buttons(): ButtonStore[]
    {
        return getOrCompute(this, this.props.buttons);
    }

    @computed
    get weight(): TextWeight
    {
        return getOrComputeProperty(this, 'weight');
    }

    @computed
    get size(): TextSize
    {
        return getOrComputeProperty(this, 'size');
    }

    @computed
    get alignment(): TextAlignment
    {
        return getOrComputeProperty(this, 'alignment');
    }

    @computed
    get color(): string
    {
        return getOrComputeProperty(this, 'color');
    }

    @computed
    get isVisible(): boolean
    {
        return getOrComputeProperty(this, 'isVisible');
    }

    @computed
    get hoverDecoration(): TextHoverDecoration
    {
        return getOrCompute(this, this.props.hoverDecoration);
    }

    @computed
    get view(): ViewComponent
    {
        return getOrCompute(this, this.props.viewComponent) || this.viewComponent;
    }

    @computed
    get isLink(): boolean
    {
        return this.props.onClick != null;
    }

    @computed
    get isNoWrap(): boolean
    {
        return getOrCompute(this, this.props.noWrap);
    }

    @computed
    get showFieldUnderline(): boolean
    {
        return this.hoverDecoration === 'field'
            && !this.viewComponent;
    }

    @computed
    get template(): TextTemplate
    {
        if (typeof this.label === 'string')
        {
            if (this.childTextStores.length > 0)
            {
                return {
                    chunks: (' ' + this.label).match(/((?!%|\d).)+/g) || [ this.label ],
                    indices: (this.label.match(/%(\d+)/g) || []).map(result => parseInt(result.substring(1), 10))
                };
            }
            else
            {
                return {
                    chunks: [ this.label ],
                    indices: []
                };
            }
        }
        else
        {
            return {
                chunks: [],
                indices: []
            };
        }
    }

    @computed
    get filteredTemplate(): TextTemplate
    {
        return {
            chunks:
                this.template.chunks
                    .filter(
                        chunk =>
                            chunk.trim().length > 0),
            indices:
                this.template.indices
                    .filter(
                        idx =>
                            idx >= 0
                                && idx < this.childTextStores.length
                                && this.childTextStores[idx] instanceof TextStore)
        };
    }

    @computed
    get isEmpty(): boolean
    {
        return this.view === undefined
            && this.viewComponent === undefined
            && this.filteredTemplate.chunks.length === 0
            && this.filteredTemplate.indices
                .every(
                    idx =>
                        this.childTextStores[idx].isEmpty);
    }

    // --------------------------- Stores ---------------------------

    @computed
    get childTextStores(): TextStore[]
    {
        return getOrComputeProperty(this, 'childTextStores');
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    click(event: any): Promise<any>
    {
        this.attemptToStopPropagation(event);

        this.setState(StoreState.Loading);

        return this.props.onClick(this)
            .then(() => this.setState(StoreState.Loaded));
    }

    @action.bound
    attemptToStopPropagation(event: any)
    {
        if (this.stopPropagation)
        {
            event.stopPropagation();
        }
    }

    @action.bound
    startHovering()
    {
        this.isHovering = true;
    }

    @action.bound
    stopHovering()
    {
        this.isHovering = false;
    }

    @action.bound
    openViewComponent(viewComponent: ViewComponent)
    {
        this.viewComponent = viewComponent;
    }

    @action.bound
    closeViewComponent()
    {
        this.viewComponent = undefined;
    }

    @action.bound
    private setProvidedLabel(label: string)
    {
        this.providedLabel = label;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
