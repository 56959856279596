import { EntityConstraintBuilderFilterContext } from './Model/EntityConstraintBuilderFilterContext';
import { ConstraintNode } from '../../../../../../@Api/Model/Implementation/ConstraintNode';
import InstanceOfPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/InstanceOfPredicate';
import { EntityNode } from '../../../../../../@Api/Model/Implementation/EntityNode';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import { InstanceOfConstraintNode } from '../../../../../../@Api/Model/Implementation/InstanceOfConstraintNode';
import { getRootNodeFromSelection } from '../../../../../../@Api/Selection/Api/getRootNodeFromSelection';

export function buildInstanceOfConstraintNode(
    filter: InstanceOfPredicate,
    context: EntityConstraintBuilderFilterContext
): ConstraintNode
{
    const selectionBuilder = context.builder.selectionBuilder;
    const lhs = filter.value;
    let lhsNode: EntityNode;

    if (lhs instanceof Parameter)
    {
        lhsNode = getRootNodeFromSelection(selectionBuilder.selection);
    }
    else if (lhs instanceof ValueFromEntityComputation
        && lhs.entity instanceof Parameter
        && lhs.fieldPath)
    {
        const pathToJoin = lhs.fieldPath.path.rootedAt(selectionBuilder.entityType);
        lhsNode = selectionBuilder.joinAndGet(pathToJoin);
    }

    if (lhsNode && filter.valueType instanceof EntityValueType)
    {
        return new InstanceOfConstraintNode(
            lhsNode,
            filter.valueType.type
        );
    }
    else
    {
        return undefined;
    }
}
