import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import { useMemo } from 'react';
import useResults from '../../../../../Selection/Hooks/useResults';

export default function useEmailsByExternalId(service: string,
                                              type: string,
                                              id?: string): Entity[]
{
    const types = useTypes();

    const externalIds =
        useResults(
            id && types.ExternalId.Type,
            (builder, rootPath) =>
                builder
                    .join(
                        rootPath.joinTo(
                            types.Entity.RelationshipDefinition.ExternalIds,
                            true)
                            .castTo(types.Activity.Email.Type)
                            .joinTo(
                                types.Activity.RelationshipDefinition.Relationship,
                                true))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Service),
                                undefined,
                                service))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Type),
                                undefined,
                                type))
                    .where(
                        cb =>
                            cb.eq(
                                rootPath.field(types.ExternalId.Field.Id),
                                undefined,
                                id)),
            [
                types,
                service,
                type,
                id
            ]);

    return useMemo(
        () =>
            externalIds?.map(
                externalId =>
                    externalId.getRelatedEntityByDefinition(
                        true,
                        types.Entity.RelationshipDefinition.ExternalIds))
        ,
        [
            externalIds,
            types
        ]);
}