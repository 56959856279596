import React from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../../../../@Api/Layout/Layout';
import CompositeLayout from '../../../../@Api/Layout/Type/CompositeLayout';
import InsetLayout from '../../../../@Api/Layout/Type/InsetLayout';
import EntityInputLayout from '../../../../@Api/Layout/Type/EntityInputLayout';
import CompositeLayoutEditor, { CompositeLayoutEditorProps } from '../Type/Composite/Editor/CompositeLayoutEditor';
import InsetLayoutEditor, { InsetLayoutEditorProps } from '../Type/Inset/InsetLayoutEditor';
import EntityInputLayoutEditor, { EntityInputLayoutEditorProps } from '../Type/EntityInput/EntityInputLayoutEditor';
import ExpansionLayout from '../../../../@Api/Layout/Type/ExpansionLayout';
import ExpansionLayoutEditor, { ExpansionLayoutEditorProps } from '../Type/Expansion/ExpansionLayoutEditor';
import ComputationLayout from '../../../../@Api/Layout/Type/ComputationLayout';
import ComputationLayoutEditor, { ComputationLayoutEditorProps } from '../Type/Computation/ComputationLayoutEditor';
import ConditionalLayout from '../../../../@Api/Layout/Type/ConditionalLayout';
import ConditionalLayoutEditor, { ConditionalLayoutEditorProps } from '../Type/Conditional/ConditionalLayoutEditor';
import OptionalRelatedEntityLayout from '../../../../@Api/Layout/Type/OptionalRelatedEntityLayout';
import OptionalRelatedEntityLayoutEditor, { OptionalRelatedEntityLayoutEditorProps } from '../Type/OptionalRelatedEntity/OptionalRelatedEntityLayoutEditor';
import CardLayoutEditor, { CardLayoutEditorProps } from '../Type/Card/CardLayoutEditor';
import CardLayout from '../../../../@Api/Layout/Type/CardLayout';
import PortalListLayout from '../../../../@Api/Layout/Type/PortalListLayout';
import PortalListLayoutEditor, { PortalListLayoutEditorProps } from '../Type/PortalList/Editor/PortalListLayoutEditor';
import ClickLayout from '../../../../@Api/Layout/Type/ClickLayout';
import ClickLayoutEditor, { ClickLayoutEditorProps } from '../Type/Click/ClickLayoutEditor';
import FormLayout from '../../../../@Api/Layout/Type/Form/FormLayout';
import FormLayoutEditor, { FormLayoutEditorProps } from '../Type/Form/FormLayoutEditor';
import PrimitiveFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/PrimitiveFormInputLayout';
import PrimitiveFormInputLayoutEditor, { PrimitiveFormInputLayoutEditorProps } from '../Type/FormInput/PrimitiveFormInput/PrimitiveFormInputLayoutEditor';
import LabelButtonLayout from '../../../../@Api/Layout/Type/LabelButtonLayout';
import LabelButtonLayoutEditor, { LabelButtonLayoutEditorProps } from '../Type/LabelButton/LabelButtonLayoutEditor';
import SelectionFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/SelectionFormInputLayout';
import SelectionFormInputLayoutEditor, { SelectionFormInputLayoutEditorProps } from '../Type/FormInput/SelectionFormInput/SelectionFormInputLayoutEditor';
import { LayoutEditorProps } from './LayoutEditor';
import LabelLayout from '../../../../@Api/Layout/Type/LabelLayout';
import LabelLayoutEditor, { LabelLayoutEditorProps } from '../Type/Label/LabelLayoutEditor';
import PortalImageLayout from '../../../../@Api/Layout/Type/PortalImageLayout';
import PortalImageLayoutEditor, { PortalImageLayoutEditorProps } from '../Type/PortalImage/PortalImageLayoutEditor';
import AppBarLayout from '../../../../@Api/Layout/Type/AppBarLayout';
import CenteredBodyLayout from '../../../../@Api/Layout/Type/CenteredBodyLayout';
import PortalRouterLayout from '../../../../@Api/Layout/Type/PortalRouterLayout';
import PortalUserButtonLayout from '../../../../@Api/Layout/Type/PortalUserButtonLayout';
import AppBarLayoutEditor, { AppBarLayoutEditorProps } from '../Type/AppBar/AppBarLayoutEditor';
import CenteredBodyLayoutEditor, { CenteredBodyLayoutEditorProps } from '../Type/CenteredBody/CenteredBodyLayoutEditor';
import RouterLayoutEditor, { PortalRouterLayoutEditorProps } from '../Type/PortalRouter/PortalRouterLayoutEditor';
import PortalButtonLayoutEditor, { PortalButtonLayoutEditorProps } from '../Type/PortalUserButton/PortalUserButtonLayoutEditor';
import HeaderWithScrollBodyLayout from '../../../../@Api/Layout/Type/HeaderWithScrollBodyLayout';
import HeaderWithScrollBodyLayoutEditor, { HeaderWithScrollBodyLayoutEditorProps } from '../Type/HeaderWithScrollBody/HeaderWithScrollBodyLayoutEditor';
import PortalSwitcherTabBarLayout from '../../../../@Api/Layout/Type/PortalSwitcherTabBarLayout';
import PortalSwitcherTabBarLayoutEditor, { PortalSwitcherTabBarLayoutEditorProps } from '../Type/PortalSwitcherTabBar/PortalSwitcherTabBarLayoutEditor';
import LocalizedLayout from '../../../../@Api/Layout/Type/LocalizedLayout';
import LocalizedLayoutEditor, { LocalizedLayoutEditorProps } from '../Type/Localized/LocalizedLayoutEditor';
import BodyLayout from '../../../../@Api/Layout/Type/BodyLayout';
import BodyLayoutEditor, { BodyLayoutEditorProps } from '../Type/Body/BodyLayoutEditor';
import ResourceImageLayout from '../../../../@Api/Layout/Type/ResourceImageLayout';
import ResourceImageLayoutEditor, { ResourceImageLayoutEditorProps } from '../Type/ResourceImage/ResourceImageLayoutEditor';
import TemplateLayout from '../../../../@Api/Layout/Type/TemplateLayout';
import TemplateLayoutEditor, { TemplateLayoutEditorProps } from '../Type/Template/TemplateLayoutEditor';
import ExpansionGroupLayout from '../../../../@Api/Layout/Type/ExpansionGroupLayout';
import ExpansionGroupLayoutEditor, { ExpansionGroupLayoutEditorProps } from '../Type/ExpansionGroup/ExpansionGroupLayoutEditor';
import DrawingFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/DrawingFormInputLayout';
import DrawingFormInputLayoutEditor, { DrawingFormInputLayoutEditorProps } from '../Type/FormInput/DrawingFormInput/DrawingFormInputLayoutEditor';
import ComputedImageLayout from '../../../../@Api/Layout/Type/ComputedImageLayout';
import ComputedImageLayoutEditor, { ComputedImageLayoutEditorProps } from '../Type/ComputedImage/ComputedImageLayoutEditor';
import FilesFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/FilesFormInputLayout';
import FilesFormInputLayoutEditor, { FilesFormInputLayoutEditorProps } from '../Type/FormInput/FilesFormInput/FilesFormInputLayoutEditor';
import TabBarLayout from '../../../../@Api/Layout/Type/TabBar/TabBarLayout';
import TabBarLayoutEditor, { TabBarLayoutEditorProps } from '../Type/TabBar/TabBarLayoutEditor';
import TableLayout from '../../../../@Api/Layout/Type/Table/TableLayout';
import TableLayoutEditor, { TableLayoutEditorProps } from '../Type/Table/Editor/TableLayoutEditor';
import EntitySelectionFormInputLayout from '../../../../@Api/Layout/Type/Form/Input/EntitySelectionFormInputLayout';
import EntitySelectionFormInputLayoutEditor, { EntitySelectionFormInputLayoutEditorProps } from '../Type/FormInput/EntitySelectionFormInput/EntitySelectionFormInputLayoutEditor';
import PortalRouteLayout from '../../../../@Api/Layout/Type/PortalRouteLayout';
import PortalRouteLayoutEditor, { PortalRouteLayoutEditorProps } from '../Type/PortalRoute/PortalRouteLayoutEditor';
import CollectionLayout from '../../../../@Api/Layout/Type/CollectionLayout';
import CollectionLayoutEditor, { CollectionLayoutEditorProps } from '../Type/Collection/Editor/CollectionLayoutEditor';
import EffectLayout from '../../../../@Api/Layout/Type/EffectLayout';
import EffectLayoutEditor, { EffectLayoutEditorProps } from '../Type/Effect/EffectLayoutEditor';
import AddressLookupLayout from '../../../../@Api/Layout/Type/AddressLookupLayout';
import AddressLookupLayoutEditor, { AddressLookupLayoutEditorProps } from '../Type/AddressLookup/AddressLookupLayoutEditor';
import InvalidLayout from '../../../../@Api/Layout/Type/InvalidLayout';
import InvalidLayoutEditor, { InvalidLayoutEditorProps } from '../Type/Invalid/InvalidLayoutEditor';
import PortalTableLayout from '../../../../@Api/Layout/Type/PortalTable/PortalTableLayout';
import PortalTableLayoutEditor, { PortalTableLayoutEditorProps } from '../Type/PortalTable/Editor/PortalTableLayoutEditor';

const LayoutEditorContent: React.FC<LayoutEditorProps<Layout>> =
    props =>
    {
        if (props.layout instanceof CompositeLayout)
        {
            return <CompositeLayoutEditor
                {...props as CompositeLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof CollectionLayout)
        {
            return <CollectionLayoutEditor
                {...props as CollectionLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof LocalizedLayout)
        {
            return <LocalizedLayoutEditor
                {...props as LocalizedLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof InsetLayout)
        {
            return <InsetLayoutEditor
                {...props as InsetLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof CardLayout)
        {
            return <CardLayoutEditor
                {...props as CardLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ExpansionGroupLayout)
        {
            return <ExpansionGroupLayoutEditor
                {...props as ExpansionGroupLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ExpansionLayout)
        {
            return <ExpansionLayoutEditor
                {...props as ExpansionLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ComputationLayout)
        {
            return <ComputationLayoutEditor
                {...props as ComputationLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ConditionalLayout)
        {
            return <ConditionalLayoutEditor
                {...props as ConditionalLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ClickLayout)
        {
            return <ClickLayoutEditor
                {...props as ClickLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof LabelButtonLayout)
        {
            return <LabelButtonLayoutEditor
                {...props as LabelButtonLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof LabelLayout)
        {
            return <LabelLayoutEditor
                {...props as LabelLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof EntityInputLayout)
        {
            return <EntityInputLayoutEditor
                {...props as EntityInputLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof OptionalRelatedEntityLayout)
        {
            return <OptionalRelatedEntityLayoutEditor
                {...props as OptionalRelatedEntityLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof TableLayout)
        {
            return <TableLayoutEditor
                {...props as TableLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalListLayout)
        {
            return <PortalListLayoutEditor
                {...props as PortalListLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalTableLayout)
        {
            return <PortalTableLayoutEditor
                {...props as PortalTableLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ResourceImageLayout)
        {
            return <ResourceImageLayoutEditor
                {...props as ResourceImageLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalImageLayout)
        {
            return <PortalImageLayoutEditor
                {...props as PortalImageLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof ComputedImageLayout)
        {
            return <ComputedImageLayoutEditor
                {...props as ComputedImageLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof FormLayout)
        {
            return <FormLayoutEditor
                {...props as FormLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PrimitiveFormInputLayout)
        {
            return <PrimitiveFormInputLayoutEditor
                {...props as PrimitiveFormInputLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof EntitySelectionFormInputLayout)
        {
            return <EntitySelectionFormInputLayoutEditor
                {...props as EntitySelectionFormInputLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof SelectionFormInputLayout)
        {
            return <SelectionFormInputLayoutEditor
                {...props as SelectionFormInputLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof DrawingFormInputLayout)
        {
            return <DrawingFormInputLayoutEditor
                {...props as DrawingFormInputLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof FilesFormInputLayout)
        {
            return <FilesFormInputLayoutEditor
                {...props as FilesFormInputLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof AppBarLayout)
        {
            return <AppBarLayoutEditor
                {...props as AppBarLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof HeaderWithScrollBodyLayout)
        {
            return <HeaderWithScrollBodyLayoutEditor
                {...props as HeaderWithScrollBodyLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof BodyLayout)
        {
            return <BodyLayoutEditor
                {...props as BodyLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof CenteredBodyLayout)
        {
            return <CenteredBodyLayoutEditor
                {...props as CenteredBodyLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof TabBarLayout)
        {
            return <TabBarLayoutEditor
                {...props as TabBarLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalRouterLayout)
        {
            return <RouterLayoutEditor
                {...props as PortalRouterLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalSwitcherTabBarLayout)
        {
            return <PortalSwitcherTabBarLayoutEditor
                {...props as PortalSwitcherTabBarLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalUserButtonLayout)
        {
            return <PortalButtonLayoutEditor
                {...props as PortalButtonLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof PortalRouteLayout)
        {
            return <PortalRouteLayoutEditor
                {...props as PortalRouteLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof TemplateLayout)
        {
            return <TemplateLayoutEditor
                {...props as TemplateLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof TemplateLayout)
        {
            return <TemplateLayoutEditor
                {...props as TemplateLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof EffectLayout)
        {
            return <EffectLayoutEditor
                {...props as EffectLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof AddressLookupLayout)
        {
            return <AddressLookupLayoutEditor
                {...props as AddressLookupLayoutEditorProps}
            />;
        }
        else if (props.layout instanceof InvalidLayout)
        {
            return <InvalidLayoutEditor
                {...props as InvalidLayoutEditorProps}
            />;
        }
        else
        {
            return null;
        }
    };

export default observer(LayoutEditorContent);
