import { Currency } from '../../../../../../../../@Api/Localization/Currency';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import getTypes from '../../../../../Type/Api/getTypes';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import getDefaultPriceList from './getDefaultPriceList';

export default async function getOrCreateDefaultPriceList(
    currency: Currency,
): Promise<Entity>
{
    const types = getTypes();

    const defaultPriceList = await getDefaultPriceList(currency);
    if (defaultPriceList)
    {
        return Promise.resolve(defaultPriceList);
    }
    else
    {
        return new CommitBuilder()
            .createEntity(
                types.PriceList.Type,
                builder =>
                    builder
                        .setObjectValue(
                            types.PriceList.Field.IsDefaultForCurrency,
                            true
                        )
                        .setObjectValue(
                            types.PriceList.Field.Name,
                            currency.code
                        )
                        .setObjectValue(
                            types.PriceList.Field.Currency,
                            currency.code
                        ),
                'defaultPriceList'
            )
            .commit()
            .then(
                result =>
                    Promise.resolve(
                        result.getEntity('defaultPriceList')
                    )
            );
        }
    }