import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Emphasis.module.scss';

export interface EmphasisProps
{

}

const Emphasis: React.FC<EmphasisProps> =
    props =>
    {
        return <span
            className={styles.root}
        >
            {props.children}
        </span>;
    };

export default observer(Emphasis);
