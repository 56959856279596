import React from 'react';
import { PromiseOrAny } from '../Button/Button';
import IconButton from '../Button/Variant/Icon/IconButton';
import LocalizedText from '../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface FilterHeaderProps
{
    isOpen: boolean;
    onChange: () => PromiseOrAny<any>
}

export const HeaderFilter: React.FC<FilterHeaderProps> =
    ({
         isOpen,
         onChange
     }) =>
    {
        return <IconButton
            icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            onClick={onChange}
            tooltip={
                isOpen
                    ? <LocalizedText
                        code="Generic.HideFilter"
                        value="Filter verbergen"
                    />
                    : <LocalizedText
                        code="Generic.ShowFilter"
                        value="Toon filter"
                    />
            }
        />;
    }