import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntitySelectionBuilder } from '../Builder/EntitySelectionBuilder';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import useResults from './useResults';
import { DependencyList } from 'react';

export default function useResult(type: EntityType,
                                  callback: (builder: EntitySelectionBuilder, rootPath: EntityPath) => void,
                                  deps: DependencyList): [ Entity | undefined, boolean ]
{
    const results = useResults(type, callback, deps);

    return useComputed(
        () =>
            type
                ?
                    results
                        ?
                            [
                                results.find(() => true),
                                false
                            ]
                        :
                            [
                                undefined,
                                true
                            ]
                :
                    [
                        undefined,
                        false
                    ],
        [
            type,
            results
        ]);
}
