import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Centered from '../../../../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../../../../@Future/Component/Generic/Loader/Loader';
import { HideableFixedBox } from '../../../../../../../../../../@Future/Component/Generic/HideableFixedBox/HideableFixedBox';
import { ResettableLayoutEditor } from '../../../../../../../../Layout/ResettableEditor/ResettableLayoutEditor';
import Layout from '../../../../../../../../../../@Api/Layout/Layout';
import { makeStyles } from '@material-ui/core';
import ParameterDictionary from '../../../../../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ParameterAssignment from '../../../../../../../../../../@Api/Automation/Parameter/ParameterAssignment';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';


const useStyles = makeStyles({
    dragging:
        {
            visibility: 'hidden'
        }
});

export interface ProductLineLayoutEditorProps
{
    showMilestone?: boolean;
    onSelected?: (entity: Entity, doInvoice: boolean) => void;
    productLine: ProductLineEditorInterface;
    isDragging: boolean;
    specificationLayout?: Layout;
    isLoadingSpecificationLayout?: boolean
    specificationLayoutParameters?: ParameterDictionary;
    specificationLayoutParameterAssignment?: ParameterAssignment;
    onChangeSpecificationLayout: (layout: Layout) => void;
    isSpecificationLayoutInEditMode?: boolean;
    setSpecificationLayoutEditMode?: (isEdit: boolean) => void;
}

export const ProductLineLayoutEditor: React.FC<ProductLineLayoutEditorProps> = observer(
    ({
         showMilestone,
         onSelected,
         productLine,
         isDragging,
         specificationLayout,
         isLoadingSpecificationLayout,
         specificationLayoutParameters,
         specificationLayoutParameterAssignment,
         onChangeSpecificationLayout,
         isSpecificationLayoutInEditMode,
         setSpecificationLayoutEditMode
     }) =>
    {
        const classes = useStyles();

        const closeSpecificationLayout =
            useCallback(
                () =>
                    setSpecificationLayoutEditMode &&
                    setSpecificationLayoutEditMode(false),
                [
                    setSpecificationLayoutEditMode
                ]
            );

        return (isSpecificationLayoutInEditMode || specificationLayout || isLoadingSpecificationLayout)
            ?
                <TableRow
                    className={isDragging && classes.dragging}
                >
                    <TableCell
                        colSpan={(showMilestone ? 1 : 0) + (onSelected ? 2 : 1) + 8}
                    >
                        {
                            isLoadingSpecificationLayout
                                ? <Centered
                                    horizontal
                                >
                                    <Loader />
                                </Centered>
                                : specificationLayoutParameterAssignment
                                    ? <HideableFixedBox
                                        hidden={isDragging}  // this condition is needed otherwise number fields are overwritten while changing the order of product lines
                                    >
                                        <ResettableLayoutEditor
                                            layout={specificationLayout}
                                            parameterDictionary={specificationLayoutParameters}
                                            parameterAssignment={specificationLayoutParameterAssignment}
                                            isInEditMode={isSpecificationLayoutInEditMode}
                                            onCloseEditMode={closeSpecificationLayout}
                                            onChangeLayout={onChangeSpecificationLayout}
                                            commitContext={productLine.commitContext}
                                        />
                                    </HideableFixedBox>
                                    : null
                        }
                    </TableCell>
                </TableRow>
            : null;
    }
);