import React, { useContext } from 'react';
import { Entity } from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../Type/Api/useTypes';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Popper from '../../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import useSwitch from '../../../../../../../../../../@Util/Switch/useSwitch';
import useEntityValue from '../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import styles from './ProductLineSubscriptionEditor.module.scss';
import LocalizerContext from '../../../../../../../../../../@Service/Localization/LocalizerContext';
import InputGroup from '../../../../../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import Input from '../../../../../../../Input/Input';
import useDividerGlue from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import ChangeSubscriptionLineInNextPeriodEditor from './ChangeSubscriptionLineInNextPeriodEditor/ChangeSubscriptionLineInNextPeriodEditor';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';
import { CommitContext } from '../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface ProductLineSubscriptionEditorProps
{
    entity: Entity;
    disabled?: boolean;
    commitContext?: CommitContext;
}

const ProductLineSubscriptionEditor: React.FC<ProductLineSubscriptionEditorProps> =
    props =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);

        const startDate = useEntityValue<Date>(props.entity, types.ProductLine.Field.StartDate, undefined, props.commitContext);
        const endDate = useEntityValue<Date>(props.entity, types.ProductLine.Field.EndDate, undefined, props.commitContext);
        const billedUntil = useEntityValue<Date>(props.entity, types.ProductLine.Field.BilledUntil, undefined, props.commitContext);
        const repeatInterval = useEntityValue(props.entity, types.ProductLine.Field.RepeatInterval, undefined, props.commitContext);

        const [ isPopperOpen, openPopper, closePopper ] = useSwitch(false);
        const dividerGlue = useDividerGlue();

        return <Popper
            reference={
                <div
                    className={styles.root}
                    onClick={openPopper}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={5}
                        alignment="center"
                    >
                        {
                            repeatInterval &&
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <span>
                                        {
                                            startDate
                                            ?
                                                <LocalizedText
                                                    code="Products.ProductionscriptionStartsOnDate"
                                                    value="start op ${startDate}"
                                                    startDate={localizer.formatDate(startDate)}
                                                />
                                            :
                                                <LocalizedText
                                                    code="Products.ProductionscriptionStartsOnBeginning"
                                                    value="start op ingangsdatum"
                                                />
                                        }
                                    </span>
                                    <span>{endDate ? ', ' : ''}</span>
                                    {
                                        endDate &&
                                            <span>
                                                <LocalizedText
                                                    code="Products.ProductionscriptionEndsOnDate"
                                                    value="eindigt op ${endDate}"
                                                    endDate={localizer.formatDate(endDate)}
                                                />
                                            </span>
                                    }
                                </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            {
                                billedUntil
                                    ?
                                        repeatInterval
                                            ?
                                                <LocalizedText
                                                    code="Products.InvoicedUntil"
                                                    value="gefactureerd tot ${billedUntil}"
                                                    billedUntil={localizer.formatDate(billedUntil)}
                                                    lowerCase
                                                />
                                            :
                                                <LocalizedText
                                                    code="Products.Invoiced"
                                                    value="Gefactureerd"
                                                    lowerCase
                                                />
                                    :
                                        <span
                                            className={styles.warning}
                                        >
                                            <LocalizedText
                                                code="Products.NotYetInvoiced"
                                                value="nog niet gefactureerd"
                                                lowerCase
                                            />
                                        </span>
                            }
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            }
            popper={
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                        glue={dividerGlue}
                    >
                        <ViewGroupItem>
                            <InputGroup>
                                {
                                    repeatInterval &&
                                        <Input
                                            entity={props.entity}
                                            field={types.ProductLine.Field.StartDate}
                                            labelPosition="left"
                                            commitContext={props.commitContext}
                                        />
                                }
                                {
                                    repeatInterval &&
                                        <Input
                                            entity={props.entity}
                                            field={types.ProductLine.Field.EndDate}
                                            labelPosition="left"
                                            commitContext={props.commitContext}
                                        />
                                }
                                <Input
                                    entity={props.entity}
                                    field={types.ProductLine.Field.BilledUntil}
                                    labelPosition="left"
                                    commitContext={props.commitContext}
                                />
                            </InputGroup>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ChangeSubscriptionLineInNextPeriodEditor
                                {...props}
                            />
                        </ViewGroupItem>
                    </ViewGroup>

                </Card>
            }
            open={isPopperOpen}
            onClose={closePopper}
        />;
    };

export default observer(ProductLineSubscriptionEditor);
