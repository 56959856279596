import { observer } from 'mobx-react';
import { BaseComponent } from '../../../../@Framework/Component/BaseComponent';
import { ButtonStore } from '../ButtonStore';
import * as React from 'react';
import Avatar from '../../Avatar/Avatar';
import { Icon } from '@material-ui/core';

@observer
export class AvatarButton extends BaseComponent<ButtonStore>
{
    renderLoader(store: ButtonStore)
    {
        return this.renderComponent(store);
    }

    renderComponent(store: ButtonStore)
    {
        return <div
            onClick={store.click}
            onMouseEnter={store.startHovering}
            onMouseOver={store.startHovering}
            onMouseLeave={store.stopHovering}
            style={{
                cursor: 'pointer',
                width: store.avatar.size,
                height: store.avatar.size,
                position: 'relative',
                ...store.style
            }}
        >
            <div
                style={{
                    background: store.isMouseOver ? 'black' : undefined,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    borderRadius: '50%',
                    opacity: 0.5
                }}
            />
            <Icon
                style={{
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    margin: 'auto',
                    visibility: store.isMouseOver ? undefined : 'hidden',
                    color: 'white'
                }}
            >
                arrow_drop_down
            </Icon>
            <div
                style={{
                    position: 'absolute',
                    display: 'block'
                }}
            >
                <Avatar store={store.avatar} />
            </div>
        </div>;
    }
}
