import { Aggregate } from '../../DataObject/Model/Aggregate';
import { AssistantAggregate } from '../Model/AssistantAggregate';

export function findAssistantAggregate(
    aggregate: AssistantAggregate
): Aggregate
{
    const convertedAggregate = Aggregate[aggregate];

    if (convertedAggregate === undefined)
    {
        throw new Error(`Could not resolve aggregate: ${aggregate}`);
    }
    else
    {
        return convertedAggregate;
    }
}
