import { reference, registerType, type } from '../../../@Util/Serialization/Serialization';
import { Entity } from './Entity';
import { EntityTypeStore } from '../../../@Component/Domain/Entity/Type/EntityTypeStore';
import { Account } from './Account';
import { Organization } from './Organization';
import { UserType } from './UserType';

@type('User')
export class User
{
    // ------------------- Persistent Properties --------------------

    id: number;
    @reference(undefined, 'Account') account: Account;
    @reference(undefined, 'Organization') organization: Organization;
    type: UserType;
    @reference(undefined, 'Entity') portal: Entity;
    @reference(undefined, 'Entity') entity: Entity;
    description?: string;
    creationDate: number;
    isBlocked: boolean;
    isDeleted: boolean;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    initialize(entityTypeStore: EntityTypeStore,
               isRoot: boolean = true)
    {
        if (this.entity)
        {
            this.entity = this.entity.initialize(entityTypeStore, undefined, undefined, undefined, isRoot, undefined, true);
        }
    }

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    setEntity(entity: Entity)
    {
        this.entity = entity;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

registerType(Organization, 'Organization');
