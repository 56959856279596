import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as PopperJS from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import styles from './Popper.module.scss';
import { classNames } from '../../../Util/Class/classNames';
import { IsNestedPopperOpenContext } from './Context/IsNestedPopperOpenContext';
import { observable, runInAction } from 'mobx';
import PopperResizer from './Resizer/PopperResizer';
import { ClickAwayListener, Fade } from '@material-ui/core';
import ReactDOM from 'react-dom';

export interface PopperClasses
{
    reference?: string;
}

export interface PopperProps
{
    reference: JSX.Element;
    popper: JSX.Element;
    placement?: PopperJS.Placement;
    open?: boolean;
    onClose?: () => void;
    classes?: PopperClasses;
    fullWidth?: boolean;
    renderInPortal?: any;
    inline?: boolean;
    referenceIsSpan?: boolean;
}

function isClickawayFromSelectbox(event: React.MouseEvent<any>)
{
    return (event as any)?.path?.some(element => element.className?.startsWith && element.className.startsWith('Selectbox'));
}

export type InternalPopper = {
    styles: { [key: string]: React.CSSProperties };
    attributes: { [key: string]: { [key: string]: string } | undefined };
    state: any;
    update: any;
    forceUpdate: any;
};

const padding = 15;

const Popper: React.FC<PopperProps> =
    ({
        onClose,
        open,
        placement,
        fullWidth,
        classes,
        inline,
        renderInPortal,
        popper,
        referenceIsSpan,
        reference
    }) =>
    {
        const referenceElement = useRef();
        const popperElement = useRef();

        // Do not close popper if a nested popper is still open
        const parentNestedPopperOpen = useContext(IsNestedPopperOpenContext);
        const [ isNestedPopperOpen ] =
            useState(
                () =>
                    observable.box(false));

        useEffect(
            () =>
            {
                if (parentNestedPopperOpen)
                {
                    runInAction(
                        () =>
                            parentNestedPopperOpen.set(open));
                }
            },
            [
                parentNestedPopperOpen,
                open
            ]);

        const clickAway =
            useCallback(
                (event: React.MouseEvent<Document, MouseEvent>) =>
                {
                    if (!isClickawayFromSelectbox(event)
                        && !isNestedPopperOpen.get())
                    {
                        // Schedule the closing, to ensure that all inner blur events are triggered
                        setTimeout(
                            onClose,
                            0);
                        event.stopPropagation();
                    }
                },
                [
                    onClose,
                    isNestedPopperOpen
                ]);

        const [ internalPopper, setPopper ] = useState<InternalPopper>();

        useEffect(
            () =>
            {
                if (referenceElement.current && popperElement.current && open)
                {
                    const popper: any =
                        createPopper(
                            referenceElement.current,
                            popperElement.current,
                            {
                                modifiers: [
                                    {
                                        name: 'flip',
                                        enabled: true,
                                        options: {
                                            fallbackPlacements: [ 'bottom-end', 'auto' ]
                                        }
                                    },
                                    // Prevent overflow modifier does not work in a lot of situations (involving HiDPI screens),
                                    // the following does:
                                    {
                                        name: 'custom',
                                        enabled: true,
                                        phase: 'main',
                                        fn: args =>
                                        {
                                            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                                            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

                                            const py = args.state.modifiersData.popperOffsets.y;
                                            const ph = args.state.rects.popper.height;

                                            if (py + ph + padding > vh)
                                            {
                                                args.state.modifiersData.popperOffsets.y += (vh - ph - py - padding);
                                            }

                                            const px = args.state.modifiersData.popperOffsets.x;
                                            const pw = args.state.rects.popper.width;

                                            if (px + pw + padding > vw)
                                            {
                                                args.state.modifiersData.popperOffsets.x += (vw - pw - px - padding);
                                            }
                                        }
                                    }

                                ],
                                strategy: 'fixed',
                                placement: placement || 'bottom-start'
                            });

                    setPopper(popper);

                    return () =>
                    {
                        setPopper(undefined);
                        popper.destroy();
                    };
                }
            },
            [
                referenceElement,
                popperElement,
                open,
                placement,
                setPopper
            ]);

        useEffect(
            () =>
            {
                if (internalPopper)
                {
                    internalPopper.update();
                }
            },
            [
                internalPopper
            ]);

        const className =
            useMemo(
                () =>
                    classNames(
                        styles.reference,
                        fullWidth && styles.fullWidth,
                        inline && styles.inline,
                        classes.reference),
                [
                    fullWidth,
                    inline,
                    classes
                ]);

        // - We only show the popper in the DOM tree when it is open, because otherwise the Popper stays 'active' and
        // gives us a big performance penalty for many poppers.
        // - We do not want the reference to leave the DOM tree when the popper is opened, because if the reference
        // is an input, then it will lose focus (e.g. for the date editor)

        const popperContent = useMemo(
            () =>
                <div
                    ref={popperElement}
                    style={internalPopper?.styles?.popper}
                    className={internalPopper && styles.popper}
                    {...internalPopper?.attributes?.popper}
                >
                    {
                        internalPopper &&
                        <IsNestedPopperOpenContext.Provider
                            value={isNestedPopperOpen}
                        >
                            {
                                internalPopper.update &&
                                <PopperResizer
                                    update={internalPopper.update}
                                />
                            }
                            <ClickAwayListener
                                // Click away listener provides args that we do not want to disclose to the onClose
                                onClickAway={clickAway}
                                // set mouse event to "MouseDown", otherwise the onClose gets fired immediately after
                                // rendering (in e.g. the DateEditor component)
                                mouseEvent="onMouseDown"
                            >
                                <Fade
                                    in
                                >
                                    <div
                                        className={styles.popperContent}
                                    >
                                        {popper}
                                    </div>
                                </Fade>
                            </ClickAwayListener>
                        </IsNestedPopperOpenContext.Provider>
                    }
                </div>,
            [
                clickAway,
                isNestedPopperOpen,
                internalPopper,
                popperElement,
                popper
            ]
        );

        return <>
            {
                referenceIsSpan
                    ?
                        <span
                            ref={referenceElement}
                            className={className}
                        >
                                {reference}
                        </span>
                    :
                        <div
                            ref={referenceElement}
                            className={className}
                        >
                            {reference}
                        </div>
            }
            {
                renderInPortal && popper && open &&
                ReactDOM.createPortal(
                    popperContent,
                    renderInPortal
                )
            }
            {
                !renderInPortal && popper && open &&
                popperContent
            }
        </>;
    };

Popper.defaultProps = {
    onClose: () => {},
    classes: {}
};

export default Popper;
