import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TruncatedDateComputation, { Truncation } from '../../../../../../../../../../@Api/Automation/Function/Computation/TruncatedDateComputation';
import localizeText from '../../../../../../../../../../@Api/Localization/localizeText';
import StaticSelectbox, { StaticOption } from '../../../../../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';

export interface TruncatedDateComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, TruncatedDateComputation>
{

}

const TruncatedDateComputationEditor: React.FC<TruncatedDateComputationEditorProps> =
    props =>
    {
        const setTruncation =
            useCallback(
                (truncation?: Truncation) =>
                    runInAction(
                        () =>
                            props.value.truncation = truncation),
                [
                    props.value
                ]);

        const setDate =
            useCallback(
                (date?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.date = date),
                [
                    props.value
                ]);

        const truncationOptions =
            useMemo<StaticOption<Truncation>[]>(
                () => [
                    // 'Day' | 'Week' | 'FourWeeks' | 'Month' | 'Quarter' | 'HalfYear' | 'Year'
                    {
                        id: 'Day',
                        label: localizeText('Generic.Day', 'Dag'),
                        value: 'Day'
                    },
                    {
                        id: 'Week',
                        label: localizeText('Generic.Week', 'Week'),
                        value: 'Week'
                    },
                    {
                        id: 'FourWeeks',
                        label: localizeText('Generic.FourWeekly', 'Vierwekelijks'),
                        value: 'FourWeeks'
                    },
                    {
                        id: 'Month',
                        label: localizeText('Generic.Month', 'Maand'),
                        value: 'Month'
                    },
                    {
                        id: 'Quarter',
                        label: localizeText('Generic.Quarter', 'Kwartaal'),
                        value: 'Quarter'
                    },
                    {
                        id: 'HalfYear',
                        label: localizeText('Generic.HalfYear', 'Half jaar'),
                        value: 'HalfYear'
                    },
                    {
                        id: 'Year',
                        label: localizeText('Generic.Year', 'Jaar'),
                        value: 'Year'
                    }
                ],
                []);

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                Datum:
            </ViewGroupItem>
            <ViewGroupItem>
                <ComputationEditor
                    context={props.context}
                    value={props.value.date}
                    onChange={setDate}
                />
            </ViewGroupItem>
            <ViewGroupItem>
                Afkapping op:
            </ViewGroupItem>
            <ViewGroupItem>
                <StaticSelectbox
                    options={truncationOptions}
                    value={props.value.truncation}
                    onChange={setTruncation}
                />
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(TruncatedDateComputationEditor);
