import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';
import getPhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';

export default function useWorkflowState(entity?: Entity)
{
    return useComputed(
        () =>
        {
            if (entity)
            {
                const phaseRelationshipDefinition = getPhaseRelationshipDefinition(entity.entityType);

                if (phaseRelationshipDefinition)
                { 
                    return entity.getRelatedEntityByDefinition(
                        false,
                        phaseRelationshipDefinition
                    );
                }
            }
            return undefined;
        },
        [
            entity
        ]);
}
