import AutomationDependencyContext from '../AutomationDependencyContext';
import Parameter from '../Parameter/Parameter';
import Trigger from './Trigger';
import { observable } from 'mobx';
import Predicate from '../Function/Computation/Predicate/Predicate';
import Dependency from '../Parameter/Dependency';
import localizeText from '../../Localization/localizeText';

export default class CronTrigger extends Trigger
{
    // ------------------------- Properties -------------------------

    @observable cron: string;

    // ------------------------ Constructor -------------------------

    constructor(predicate: Predicate,
                cron: string)
    {
        super(predicate);

        this.cron = cron;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getParameters(): Parameter<any>[]
    {
        return [
            this.getDateParameter()
        ];
    }

    getDependencies(): Dependency[]
    {
        return [];
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'Cron';
        descriptor.cron = this.cron;
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new CronTrigger(
            undefined,
            descriptor.cron);
    }

    getName(): string
    {
        return localizeText('Cron.EachPeriod', `Elke dag/week/maand/jaar`);
    }

    // ----------------------- Private logic ------------------------
}
