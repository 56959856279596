import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import FunctionContext from '../../../../../../@Api/Automation/Function/FunctionContext';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import ActionEditor from '../../LayoutActionEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../@Future/Component/Generic/Menu/Item/Item';
import { IObservableArray, runInAction } from 'mobx';
import ParameterDictionary from '../../../../../../@Api/Automation/Parameter/ParameterDictionary';
import ErrorBoundary from '../../../../../Error/ErrorBoundary';
import ActionConstructor from '../../Constructor/ActionConstructor';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import LayoutAction from '../../../../../../@Api/Layout/Action/LayoutAction';
import CompositeActionInvocation from '../../../../../../@Api/Layout/Action/Type/CompositeActionInvocation';
import CompositeAction from '../../../../../../@Api/Layout/Action/Type/CompositeAction';

export interface InvocationProps
{
    context: FunctionContext;
    action: CompositeAction;
    invocation: CompositeActionInvocation;
    tail: CompositeActionInvocation[];
    idx: number;
    onConstruct: (action: LayoutAction, afterIdx: number) => void;
}

const CompositeActionInvocationEditor: React.FC<InvocationProps> =
    props =>
    {
        const onDelete =
            useCallback(
                () =>
                    runInAction(
                        () =>
                            (props.action.invocations as IObservableArray).remove(props.invocation)),
                [
                    props.action,
                    props.invocation
                ]);

        const [ nextInvocation, ...nextTail ] = props.tail;
        const nextContext =
            useComputed(
                () =>
                {
                    const resultParameter = props.invocation.resultParameter;

                    return new FunctionContext(
                        new ParameterDictionary([
                            ...Array.from(props.context.parameterDictionary.parameterById.values()),
                            ...resultParameter
                                ?
                                    [
                                        resultParameter
                                    ]
                                :
                                    []
                        ]),
                        undefined,
                        props.context.commitContext
                    );
                },
                [
                    props.context,
                    props.invocation
                ]);

        const onConstructAfter =
            useCallback(
                (action: LayoutAction) =>
                    props.onConstruct(
                        action,
                        props.idx + 1),
                [
                    props.onConstruct,
                    props.idx
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            {
                props.idx === 0 &&
                    <ViewGroupItem>
                        <ActionConstructor
                            context={props.context}
                            onConstruct={props.onConstruct}
                            mode="Line"
                        />
                    </ViewGroupItem>
            }
            <ViewGroupItem>
                <Card>
                    <CardInset
                        bottom={false}
                    >
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem
                                ratio={1}
                            >
                                <CardHeader>
                                    {props.idx + 1}. {props.invocation.action.getName()}
                                </CardHeader>
                            </ViewGroupItem>
                            <ViewGroupItem>
                                <MenuButton>
                                    <Menu>
                                        <Item
                                            name={
                                                <LocalizedText
                                                    code="Generic.Delete"
                                                />
                                            }
                                            onClick={onDelete}
                                        />
                                    </Menu>
                                </MenuButton>
                            </ViewGroupItem>
                        </ViewGroup>
                    </CardInset>
                    <ErrorBoundary>
                        <ActionEditor
                            context={props.context}
                            action={props.invocation.action}
                        />
                    </ErrorBoundary>
                </Card>
            </ViewGroupItem>
            <ViewGroupItem>
                <ActionConstructor
                    context={props.context}
                    onConstruct={onConstructAfter}
                    mode={nextInvocation ? 'Line' : 'Button'}
                />
            </ViewGroupItem>
            {
                nextInvocation &&
                    <ViewGroupItem>
                        <CompositeActionInvocationEditor
                            context={nextContext}
                            action={props.action}
                            invocation={nextInvocation}
                            tail={nextTail}
                            idx={props.idx + 1}
                            onConstruct={props.onConstruct}
                        />
                    </ViewGroupItem>
            }
        </ViewGroup>;
    };

export default observer(CompositeActionInvocationEditor);
