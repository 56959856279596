import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Calendar, { CalendarChangeEvent, CalendarEvent, CalendarTimeSlot, CalendarViewType, EventFetcher, EventQuery } from '../../../../@Future/Component/Generic/Calendar/Calendar';
import CurrentUserContext from '../../User/CurrentUserContext';
import useTypes from '../Type/Api/useTypes';
import { openEntity } from '../@Util/openEntity';
import moment from 'moment';
import styles from './EntityCalendar.module.scss';
import useEmployeePlanner from '../ResourcePlanner/Type/useEmployeePlanner';
import useCombinedResults from '../Selection/Hooks/useCombinedResults';
import openDialog from '../../../../@Service/Navigation/Page/Hooks/openDialog';
import PlanDialog from '../ResourcePlanner/PlanDialog/PlanDialog';
import Item from '../ResourcePlanner/Model/Item';
import ResourceSelection from '../ResourcePlanner/Model/ResourceSelection';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import { EntitySelectionBuilder } from '../Selection/Builder/EntitySelectionBuilder';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Selectbox from '../Selectbox/Selectbox';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import useResizeObserver from 'use-resize-observer/polyfilled';
import LocalizationContext from '../../../../@Service/Localization/LocalizationContext';
import trimNewlines from '../../../../@Util/String/trimNewlines';
import { queryOccurrences } from '../../RecurrencePattern/Api/queryOccurrences';
import { CommitBuilder } from '../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { RecurrencePattern } from '../../RecurrencePattern/Model/RecurrencePattern';
import { Occurrence } from '../../RecurrencePattern/Model/Occurrence';
import useLocalEntitySetting from '../../Setting/Api/useLocalEntitySetting';
import useLocalSetting from '../../Setting/Api/useLocalSetting';
import useSetting from '../../Setting/Api/useSetting';
import { SettingSource } from '../../Setting/SettingStore';
import { Setting as SettingCode } from '../../../../@Api/Settings/Setting';
import { useExpansion } from '../Selection/Api/useExpansion';
import EntityCalendarFilter from './EntityCalendarFilter/EntityCalendarFilter';
import { EntityPath } from '../Path/@Model/EntityPath';

export interface EntityCalendarProps
{
    viewType: CalendarViewType;
    height?: 'noscroll' | number;
    hideHeader?: boolean;
    hideNavigation?: boolean;
    hideDateLabel?: boolean;
    defaultEmployee?: Entity;
    settingStorageKey?: string;
}

const EntityCalendar: React.FC<EntityCalendarProps> =
    props =>
    {
        const currentUserStore = useContext(CurrentUserContext);
        const localizationStore = useContext(LocalizationContext);

        const types = useTypes();
        const [ query, setQuery ] = useState<EventQuery>();

        const [ showOnlyTeamCalendar ] =
            useSetting<boolean>(
                SettingSource.Organization,
                SettingCode.ShowOnlyTeamCalendar
            );

        const period =
            useMemo(
                () =>
                {
                    if (query)
                    {
                        return {
                            from: moment(query.start),
                            to: moment(query.end)
                        };
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    // otherwise in case of a time registration with no end date, we get an infinite loop
                    /* eslint-disable react-hooks/exhaustive-deps */
                    query && query.start && query.start.getTime(),
                    query && query.end && query.end.getTime()
                    /* eslint-enable react-hooks/exhaustive-deps */
                ]);

        const [ hideInactiveEmployees, setHideInactiveEmployees ] =
            useLocalSetting<boolean>(
                props.settingStorageKey
                    ? `${props.settingStorageKey}.HideInactiveEmployees`
                    : undefined,
                true
            );
        const [ hideWeekends, setHideWeekends ] =
            useLocalSetting<boolean>(
                    props.settingStorageKey
                    ? `${props.settingStorageKey}.HideWeekends`
                    : undefined,
                false
            );

        const employeePlanner = useEmployeePlanner();
        const [ selectedResourceSelectionIds, setSelectedResourceSelectionIds ] =
            useLocalSetting<string[]>(
                props.settingStorageKey
                    ? `${props.settingStorageKey}.SelectedResourceSelectionIds`
                    : undefined
            );
        const [ selectedResourceSelections, setSelectedResourceSelections ] =
            useState(
                selectedResourceSelectionIds
                    ? employeePlanner.resourceSelections
                        .filter(
                            selection =>
                                selectedResourceSelectionIds
                                    .includes(
                                        selection.parameter.id
                                    )
                        )
                    : employeePlanner.resourceSelections
            );
        const setResourceSelections =
            useCallback(
                (resourceSelections: ResourceSelection[]) =>
                {
                    setSelectedResourceSelections(resourceSelections);
                    setSelectedResourceSelectionIds(
                        employeePlanner.resourceSelections.length === resourceSelections.length
                            ? undefined
                            : resourceSelections
                                .map(
                                    resourceSelection =>
                                        resourceSelection.parameter.id
                                )
                    );
                },
                [
                    setSelectedResourceSelections,
                    setSelectedResourceSelectionIds,
                    employeePlanner.resourceSelections
                ]
            );
        const defaultEmployee =
            useMemo(
                () =>
                    props.defaultEmployee ?? currentUserStore.employeeEntity,
                [
                    props.defaultEmployee,
                    currentUserStore.employeeEntity
                ]
            );
        const [ employee, setEmployee, isLoadingEmployee ] =
            useLocalEntitySetting(
                props.settingStorageKey
                    ? `${props.settingStorageKey}.LastSelectedEmployeeId`
                    : undefined,
                types.Relationship.Person.Contact.Employee.Type,
                defaultEmployee
            );
        const onChangeEmployee =
            useCallback(
                (employee: Entity) =>
                {
                    setEmployee(
                        employee?.id === defaultEmployee?.id
                            ? defaultEmployee
                            : employee
                    );
                },
                [
                    setEmployee,
                    defaultEmployee,
                ]);
        const [ calendarViewType, setCalendarViewType ] =
            useLocalSetting(
                props.settingStorageKey
                    ? `${props.settingStorageKey}.ViewType`
                    : undefined,
                props.viewType
            );
        const onChangeCalendarViewType =
            useCallback(
                (viewType: CalendarViewType) =>
                {
                    setCalendarViewType(
                        viewType === props.viewType
                            ? undefined
                            : viewType
                    );
                },
                [
                    setCalendarViewType,
                    props.viewType,
                ]
            );
        const employeeSelections =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.Relationship.Person.Contact.Employee.Type)
                        .if(
                            () => hideInactiveEmployees,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.eq(
                                            EntityPath
                                                .fromEntityType(types.Relationship.Person.Contact.Employee.Type)
                                                .field(types.Relationship.Field.IsFormer),
                                            undefined,
                                            false
                                        )
                                )
                        )
                        .build()
                ],
                [
                    types,
                    hideInactiveEmployees
                ]);

        useExpansion(
            types.Team.Type,
            rootPath =>
                [
                    rootPath
                        .joinTo(
                            types.Team.RelationshipDefinition.Members,
                            false)
                ],
            () =>
                defaultEmployee.getRelatedEntitiesByDefinition(
                    true,
                    types.Team.RelationshipDefinition.Members
                ),
            [
                types,
                defaultEmployee
            ]);

        const employeeTeams =
            useComputed(
                () =>
                    defaultEmployee.getRelatedEntitiesByDefinition(
                        true,
                        types.Team.RelationshipDefinition.Members
                    )
                    .flatMap(team =>
                        team.getRelatedEntitiesByDefinition(
                            false,
                            types.Team.RelationshipDefinition.Members
                        )
                    )
                    .filter(
                        (value, index,array) =>
                            index === array.findIndex(
                            entity =>
                                value === entity
                            )
                    ),
            [
                defaultEmployee,
                types
            ]
        );

        const resourceSelectionTypes =
            useMemo(
                () =>
                    period && !isLoadingEmployee
                        ? selectedResourceSelections
                            .map(
                                selection =>
                                    selection.entityType)
                        :
                            [],
                [
                    selectedResourceSelections,
                    period,
                    isLoadingEmployee
                ]);

        const resultSets =
            useCombinedResults(
                resourceSelectionTypes,
                (idx, builder) =>
                {
                    const selection = selectedResourceSelections[idx];
                    const from = period.from.toDate();
                    const to = period.to.toDate();

                    builder
                        .join(selection.resourcePath)
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    selection.resourcePath,
                                    employee))
                        .if(
                            () =>
                                selection.recurrence !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.or(
                                            ob =>
                                                ob
                                                    .overlapsWith(
                                                        selection.startDateFieldPath,
                                                        selection.endDateFieldPath,
                                                        from,
                                                        to
                                                    )
                                                    .overlapsWithNullableEnd(
                                                        selection.recurrence.startDateFieldPath,
                                                        selection.recurrence.endDateFieldPath,
                                                        from,
                                                        to
                                                    )
                                        )
                                    )
                        )
                        .if(
                            () =>
                                selection.recurrence === undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.overlapsWith(
                                            selection.startDateFieldPath,
                                            selection.endDateFieldPath,
                                            from,
                                            to
                                        )
                                )
                        )
                        .if(
                            () =>
                                selection.filter !== undefined,
                            sb =>
                                sb.where(
                                    cb =>
                                        cb.filter(
                                            selection.filter,
                                            {
                                                parameter: selection.parameter,
                                            }
                                        )
                                )
                        )
                        .if(
                            () => true,
                            sb =>
                                selection.entityType
                                    .bespoke
                                    .getListDependencies()
                                    .forEach(
                                        dependencyPath =>
                                            sb.join(dependencyPath)
                                    )
                        )
                        .limit(500);
                },
                [
                    selectedResourceSelections,
                    employee,
                    period
                ]);

        const items =
            useComputed(
                () =>
                    (resultSets || [])
                        .filter(
                            (_, idx) =>
                                selectedResourceSelections[idx])
                        .map(
                            (resultSet, idx) =>
                                resultSet
                                    .map(
                                        result =>
                                            new Item(
                                                selectedResourceSelections[idx],
                                                result.entity,
                                                selectedResourceSelections[idx].resourcePath.traverseEntity(result.entity).find(() => true),
                                                {
                                                    from: moment(selectedResourceSelections[idx].startDateFieldPath.getObjectValue(result.entity) || new Date()),
                                                    to: moment(selectedResourceSelections[idx].endDateFieldPath.getObjectValue(result.entity) || new Date())
                                                })))
                        .reduce((a, b) => a.concat(b), []),
                [
                    resultSets,
                    selectedResourceSelections
                ]);

        const itemsById =
            useComputed(
                () =>
                    new Map(
                        items.map(
                            item => [
                                item.entity.uuid,
                                item
                            ])),
                [
                    items
                ]);

        const events: CalendarEvent[] =
            useComputed(
                () =>
                    items
                        .map(
                            item =>
                            {
                                const occurrences: Occurrence[] =
                                    item.selection.recurrence?.isRecurring(item.entity)
                                        ? queryOccurrences({
                                            pattern: item.selection.recurrence!.patternFieldPath.getObjectValue(item.entity),
                                            from: period.from.toDate(),
                                            to: period.to.toDate(),
                                            occurrenceDurationInMillis: item.period.to.toDate().getTime() - item.period.from.toDate().getTime(),
                                        })
                                        : [
                                            {
                                                idx: 0,
                                                originalStartDate: item.period.from.toDate(),
                                                startDate: item.period.from.toDate(),
                                                endDate: item.period.to.toDate(),
                                            }
                                        ];

                                const allDay =
                                    item.period.from.hours() === 0 && item.period.from.minutes() === 0 && item.period.from.seconds() === 0 &&
                                    item.period.to.hours() === 0 && item.period.to.minutes() === 0 && item.period.to.seconds() === 0;

                                const showPopup =
                                    item.entity.entityType.isA(types.Activity.Type)
                                    || item.entity.entityType.isA(types.CalendarItem.Type);

                                return occurrences.map(
                                    occurrence => ({
                                        id: item.entity.uuid,
                                        start: occurrence.startDate,
                                        end: occurrence.endDate,
                                        title: trimNewlines(item.entity.entityType.bespoke.getEventTitle(item.entity)),
                                        allDay: allDay,
                                        editable: !item.entity.getObjectValueByField(types.CalendarItem.Field.IsPrivate),
                                        backgroundColor: item.selection.color ?? item.entity.entityType.bespoke.getEntityColorForCalendar(item.entity),
                                        borderColor: item.selection.color ?? item.entity.entityType.bespoke.getEntityColorForCalendar(item.entity),
                                        occurrenceOriginalStartDate: occurrence.originalStartDate,
                                        showPopup: showPopup
                                    })
                                );
                            })
                        .reduce(
                            (a, b) =>
                                a.concat(b),
                            []
                        ),
                [
                    items
                ]);

        const fetchEvents =
            useCallback<EventFetcher>(
                (query) =>
                {
                    setQuery(query);

                    return Promise.resolve();
                },
                [
                    setQuery
                ]);

        const eventChanged =
            useCallback(
                async (changeEvent: CalendarChangeEvent) =>
                {
                    const item = itemsById.get(changeEvent.new.id);

                    if (item)
                    {
                        const start = changeEvent.new.start as Date;
                        let end = changeEvent.new.end as Date;

                        if (!end && changeEvent.new.allDay)
                        {
                            end = new Date(start.getTime());
                            end.setDate(start.getDate() + 1);
                        }
                        else if (!end && !changeEvent.new.allDay)
                        {
                            end = new Date(start.getTime());
                        }

                        if (item.selection.recurrence?.isRecurring(item.entity))
                        {
                            const recurrencePattern: RecurrencePattern = item.selection.recurrence.patternFieldPath.getObjectValue(item.entity);
                            const occurrenceOriginalStartDate = changeEvent.new.occurrenceOriginalStartDate;
                            const modifiedRecurrencePattern = {
                                ...recurrencePattern,
                                modifiedOccurrences: [
                                    ...recurrencePattern.modifiedOccurrences.filter(
                                        occurrence =>
                                            new Date(occurrence.originalStartDate).getTime() !== occurrenceOriginalStartDate.getTime()
                                    ),
                                    {
                                        originalStartDate: occurrenceOriginalStartDate,
                                        startDate: (changeEvent.new.start as Date).toISOString(),
                                        endDate: (changeEvent.new.end as Date).toISOString(),
                                    }
                                ]
                            };

                            await new CommitBuilder()
                                .setObjectValueInEntityByFieldPath(
                                    item.entity,
                                    item.selection.recurrence.patternFieldPath,
                                    modifiedRecurrencePattern
                                )
                                .commit();

                            return true;
                        }
                        else
                        {
                            await new CommitBuilder()
                                .setObjectValueInEntityByFieldPath(
                                    item.entity,
                                    item.selection.startDateFieldPath,
                                    start
                                )
                                .setObjectValueInEntityByFieldPath(
                                    item.entity,
                                    item.selection.endDateFieldPath,
                                    end
                                )
                                .commit();
                        }

                        return false;
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    itemsById
                ]);

        const eventSelected =
            useCallback(
                event =>
                {
                    const item = itemsById.get(event.id);
                    if (item)
                    {
                        const isPrivate = item.entity.getObjectValueByField(types.CalendarItem.Field.IsPrivate);
                        if (!isPrivate)
                        {
                            return openEntity(item.entity);
                        }
                    }
                },
                [
                    itemsById,
                    types
                ]);

        const selectItemSlot =
            useCallback(
                (slot: CalendarTimeSlot) =>
                {
                    openDialog(
                        close =>
                            <PlanDialog
                                resourceSelections={selectedResourceSelections}
                                parameterAssignment={new ParameterAssignment()}
                                onClose={close}
                                period={{
                                    from: moment(slot.start),
                                    to: moment(slot.end)
                                }}
                                resource={currentUserStore.employeeEntity}
                            />)
                },
                [
                    employeePlanner,
                    currentUserStore,
                    selectedResourceSelections
                ]);

        const { ref, width } = useResizeObserver<HTMLDivElement>();
        const isCompact = width < 550;

        const showTeamCalendar =
            useMemo(
                () =>
                    showOnlyTeamCalendar && (!currentUserStore.isSupport || !currentUserStore.isDeveloper),
                [
                    showOnlyTeamCalendar,
                    currentUserStore
                ]
            );

        return <div
            ref={ref}
            className={classNames(styles.root, isCompact && styles.compact)}
        >
            <div
                className={classNames(styles.resourceSelectionFilter, styles.withViewSwitcherHidden)}
            >
                <ViewGroup
                    orientation="horizontal"
                    spacing={15}
                    alignment="center"
                >
                    <ViewGroupItem>
                        {
                            !showTeamCalendar &&
                            <Selectbox
                                selections={employeeSelections}
                                value={employee}
                                onChange={onChangeEmployee}
                                hideCaptionInValue
                                clearable={false}
                            />
                        }
                        {
                            showTeamCalendar &&
                            <Selectbox
                                selections={employeeTeams}
                                value={employee}
                                onChange={onChangeEmployee}
                                hideCaptionInValue
                            />
                        }
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <EntityCalendarFilter
                            resourceSelections={employeePlanner.resourceSelections}
                            activeSelections={selectedResourceSelections}
                            onChangeResourceSelections={setResourceSelections}
                            hideInactiveEmployees={hideInactiveEmployees}
                            onChangeHideInactiveEmployees={setHideInactiveEmployees}
                            calendarViewType={calendarViewType}
                            onChangeCalendarViewType={onChangeCalendarViewType}
                            hideWeekends={hideWeekends}
                            onChangeHideWeekends={setHideWeekends}
                        />
                    </ViewGroupItem>
                </ViewGroup>
            </div>
            <Calendar
                locale={localizationStore.languageCode}
                type={calendarViewType}
                onChangeType={onChangeCalendarViewType}
                events={events}
                fetchEvents={fetchEvents}
                onSelectTimeslot={selectItemSlot}
                onSelectEvent={eventSelected}
                onChangeEvent={eventChanged}
                enableTypeSwitcher
                hideHeader={props.hideHeader}
                hideDateLabel={props.hideDateLabel}
                hideNavigation={props.hideNavigation}
                hideViewSwitcher
                hideTodayButton
                hideWeekends={hideWeekends}
                height={props.height}
                allDaySlot
            />
        </div>;
    };

export default observer(EntityCalendar);
