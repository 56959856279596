import { observable } from 'mobx';
import Computation from './Computation';
import ValueType from '../../Value/Type/ValueType';
import Value from '../../Value/Value';
import Dependency from '../../Parameter/Dependency';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import Validation from '../../Validation/Validation';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import FunctionContext from '../FunctionContext';
import safelyApplyFunction from '../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../Api/safelySynchronousApplyFunction';
import localizeText from '../../../Localization/localizeText';
import MapType from '../../Value/Type/MapType';
import MapValue from '../../Value/MapValue';

export default class ValueFromMapComputation extends Computation<ValueType<any>, Value<any, any>>
{
    // ------------------------- Properties -------------------------

    @observable.ref map: Computation<any, any>;
    @observable.ref key: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(map: Computation<any, any>,
                key: Computation<any, any>)
    {
        super();

        this.map = map;
        this.key = key;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getType(): ValueType<any>
    {
        return (this.map.getType() as MapType).valueType;
    }

    isAsync(): boolean
    {
        return this.map.isAsync()
            || this.key.isAsync();
    }

    async apply(context: FunctionContext): Promise<Value<any, any>>
    {
        const [ map, key ] =
            await Promise.all([
                safelyApplyFunction(this.map, context),
                safelyApplyFunction(this.key, context)
            ]);

        if (map instanceof MapValue)
        {
            return map.value.get(key.getId())?.value;
        }
        else
        {
            return undefined;
        }
    }

    synchronousApply(context: FunctionContext): Value<any, any>
    {
        const map = safelySynchronousApplyFunction(this.map, context);
        const key = safelySynchronousApplyFunction(this.key, context);

        if (map instanceof MapValue)
        {
            return map.value.get(key.getId())?.value;
        }
        else
        {
            return undefined;
        }
    }

    getName(): string
    {
        return `${this.map?.getName() || '...'}[${this.key?.getName() || '...'}]`;
    }

    validate(): Validation[]
    {
        if (!(this.map?.getType() instanceof MapType))
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.ValueFromMap.InvalidMap', 'Er is geen geldige maptype geselecteerd.'))
            ];
        }
        else if (!this.key)
        {
            return [
                new Validation(
                    'Error',
                    localizeText('Computation.ValueFromMap.InvalidKey', 'Er is geen geldige sleutelwaarde geselecteerd.'))
            ];
        }
        else
        {
            return [];
        }
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'ValueFromMap';
        descriptor.map = this.map.toDescriptor();
        descriptor.key = this.key.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return [
            ...this.map.getDependencies(),
            ...this.key.getDependencies()
        ];
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const [ map, key ] =
            await Promise.all([
                getComputationFromDescriptor(
                    descriptor.map,
                    dependencyContext),
                getComputationFromDescriptor(
                    descriptor.key,
                    dependencyContext)
            ]);

        const mapType = map.getType();

        if (mapType instanceof MapType)
        {
            return new ValueFromMapComputation(
                map,
                key);
        }
        else
        {
            throw new Error(`Expected map type, but got: ${mapType.id()}`)
        }
    }

    // ----------------------- Private logic ------------------------
}
