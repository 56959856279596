import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import ValueType from '../../../../../../../../@Api/Automation/Value/Type/ValueType';
import Computation from '../../../../../../../../@Api/Automation/Function/Computation/Computation';
import Value from '../../../../../../../../@Api/Automation/Value/Value';
import Menu from '../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import Parameter from '../../../../../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../../../../../@Api/Automation/Value/Type/EntityValueType';
import QueryComputation from '../../../../../../../../@Api/Automation/Function/Computation/QueryComputation';
import { ComputationEditorProps } from './ComputationEditor';
import TextComputation from '../../../../../../../../@Api/Automation/Function/Computation/TextComputation';
import MathematicalComputation from '../../../../../../../../@Api/Automation/Function/Computation/MathematicalComputation';
import { MathematicalOperator } from '../../../../../../DataObject/Model/MathematicalOperator';
import ListQuery from '../../../../../../../../@Api/Automation/Query/ListQuery';
import AggregateQuery from '../../../../../../../../@Api/Automation/Query/AggregateQuery';
import ConditionalComputation from '../../../../../../../../@Api/Automation/Function/Computation/ConditionalComputation';
import ConditionalInComputation from '../../../../../../../../@Api/Automation/Function/Computation/ConditionalInComputation';
import CompositePredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../DataObject/Model/LogicalOperator';
import RegExComputation from '../../../../../../../../@Api/Automation/Function/Computation/RegExComputation';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import DataSourceValueType from '../../../../../../../../@Api/Automation/Value/Type/DataSourceValueType';
import HtmlFromLayoutComputation from '../../../../../../../../@Api/Automation/Function/Computation/HtmlFromLayoutComputation';
import CompositeLayout from '../../../../../../../../@Api/Layout/Type/CompositeLayout';
import RenderOptions from '../../../../../../../../@Api/Layout/Style/RenderOptions';
import LocalizedComputation from '../../../../../../../../@Api/Automation/Function/Computation/LocalizedComputation';
import PdfComputation from '../../../../../../../../@Api/Automation/Function/Computation/PdfComputation';
import MergedPdfComputation from '../../../../../../../../@Api/Automation/Function/Computation/MergedPdfComputation';
import MergedPdfComputationPdf from '../../../../../../../../@Api/Automation/Function/Computation/MergedPdfComputationPdf';
import uuid from '../../../../../../../../@Util/Id/uuid';
import EmptyValue from '../../../../../../../../@Api/Automation/Value/EmptyValue';
import RangeCollectionComputation from '../../../../../../../../@Api/Automation/Function/Computation/RangeCollectionComputation';
import PortalContext from '../../../../../../../../@Api/Portal/Context/PortalContext';
import DataSourceValueByIdComputation from '../../../../../../../../@Api/Automation/Function/Computation/DataSourceValueByIdComputation';
import DynamicParameterAssignment from '../../../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import DataSourceListQueryComputation from '../../../../../../../../@Api/Automation/Function/Computation/DataSourceListQueryComputation';
import ValueFromDataSourceValueComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromDataSourceValueComputation';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { dividerColor } from '../../../../../../../../@Resource/Theme/Theme';
import { ComputationParameterSuppressionContext } from './Context/ComputationParameterSuppressionContext';
import { copyComputationToClipboard, getCopiedComputationFromClipboard, hasCopiedComputationInClipboard } from './Clipboard/ComputationClipboard';
import isComputationCompatibleWithParameterDictionary from './Api/isComputationCompatibleWithParameterDictionary';
import getComputationFromDescriptor from '../../../../../../../../@Api/Automation/Api/getComputationFromDescriptor';
import AutomationDependencyContext from '../../../../../../../../@Api/Automation/AutomationDependencyContext';
import CurrentDateComputation from '../../../../../../../../@Api/Automation/Function/Computation/CurrentDateComputation';
import CurrentDateTimeComputation from '../../../../../../../../@Api/Automation/Function/Computation/CurrentDateTimeComputation';
import EmptyValueType from '../../../../../../../../@Api/Automation/Value/Type/EmptyValueType';
import PeriodBetweenDatesComputation from '../../../../../../../../@Api/Automation/Function/Computation/PeriodBetweenDatesComputation';
import QueryFileExportComputation from '../../../../../../../../@Api/Automation/Function/Computation/QueryFileExportComputation';
import RoundedNumberComputation from '../../../../../../../../@Api/Automation/Function/Computation/RoundedNumberComputation';
import DataSourceAggregateQueryComputation from '../../../../../../../../@Api/Automation/Function/Computation/DataSourceAggregateQueryComputation';
import { Aggregate } from '../../../../../../DataObject/Model/Aggregate';
import { DataObject } from '../../../../../../DataObject/Model/DataObject';
import LocalizedTextComputation from '../../../../../../../../@Api/Automation/Function/Computation/LocalizedTextComputation';
import AiPromptComputation from '../../../../../../../../@Api/Automation/Function/Computation/AiPromptComputation';
import PrimitiveValueType from '../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { AiPromptComputationPart } from '../../../../../../../../@Api/Automation/Function/Computation/AiPromptComputationPart';
import AiAudioTranscriptionComputation from '../../../../../../../../@Api/Automation/Function/Computation/AiAudioTranscriptionComputation';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';

const useStyles = makeStyles({
    group: {
        width: 'min(50vw, max(15vw, 300px))'
    },
    groupName: {
        fontWeight: 800,
        padding: '10px 15px'
    },
    divider: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: 1,
        backgroundColor: dividerColor
    }
});

export interface ComputationConstructorProps<T extends ValueType<any> = ValueType<any>, C extends Computation<T, Value<any, any>> = Computation<T, Value<any, any>>> extends ComputationEditorProps<T, C>
{
    disallowStaticValue?: boolean;
}

interface ComputationConstructorGroup
{
    id: string;
    name: string;
    items: ComputationConstructorItem[];
}


export interface ComputationConstructorItem
{
    id: string;
    name: string;
    isActive: (value: Computation<any, any>) => boolean;
    construct: () => Promise<Computation<any, any>>;
}

const ComputationConstructor: React.FC<ComputationConstructorProps> =
    props =>
    {
        const classes = useStyles();
        const portalContext = useContext(PortalContext);
        const parameters =
            useComputed(
                () =>
                    Array.from(props.context.parameterDictionary.parameterById.values()),
                [
                    props.context
                ]);
        const suppressedParameters = useContext(ComputationParameterSuppressionContext);
        const currentUserStore = useContext(CurrentUserContext);

        const groupOfGroups =
            useMemo<ComputationConstructorGroup[][]>(
                () =>
                {
                    const staticGroup: ComputationConstructorGroup = {
                        id: 'Static',
                        name: localizeText('Generic.Static', 'Statisch'),
                        items: []
                    };

                    const parameterGroup: ComputationConstructorGroup = {
                        id: 'Parameters',
                        name: localizeText('Generic.Parameters', 'Parameters'),
                        items: []
                    };

                    const fieldOfParameterGroup: ComputationConstructorGroup = {
                        id: 'FieldOfParameters',
                        name: localizeText('Generic.FieldsOfParameters', 'Velden uit parameters'),
                        items: []
                    };

                    const queryGroup: ComputationConstructorGroup = {
                        id: 'Query',
                        name: localizeText('Generic.DataRetrieval', 'Data ophalen'),
                        items: []
                    };

                    const functionGroup: ComputationConstructorGroup = {
                        id: 'Functions',
                        name: localizeText('Generic.Functions', 'Functies'),
                        items: []
                    };

                    const aiGroup: ComputationConstructorGroup = {
                        id: 'AI',
                        name: localizeText('Generic.AI', 'AI'),
                        items: []
                    };

                    const clipboardGroup: ComputationConstructorGroup = {
                        id: 'Clipboard',
                        name: localizeText('Generic.Clipboard', 'Klembord'),
                        items: []
                    };

                    if (!props.disallowStaticValue)
                    {
                        staticGroup.items.push({
                            id: 'Value',
                            name: localizeText('Computation.StaticValue', 'Vaste waarde'),
                            isActive:
                                    value =>
                                        !value
                                        || (value instanceof Value && !value.getType().isA(EmptyValueType.instance)),
                            construct:
                                () =>
                                    undefined
                        });

                        staticGroup.items.push({
                            id: 'EmptyValue',
                            name: localizeText('Computation.EmptyValue', 'Géén waarde'),
                            isActive: value => value instanceof EmptyValue,
                            construct:
                                async () =>
                                    EmptyValue.instance
                        });
                    }

                    const unsuppressedParameters =
                        parameters.filter(
                            parameter =>
                                !suppressedParameters.hasParameter(parameter));

                    unsuppressedParameters
                        .forEach(
                            parameter =>
                                parameterGroup.items.push({
                                    id: `Parameter.${parameter.id}`,
                                    name: parameter.name,
                                    isActive:
                                        value =>
                                            value === parameter,
                                    construct: async () => parameter
                                }));

                    unsuppressedParameters
                        .filter(
                            parameter =>
                                parameter.type instanceof EntityValueType)
                        .forEach(
                            parameter =>
                                fieldOfParameterGroup.items.push({
                                    id: `ValueFromEntityParameter.${parameter.id}`,
                                    name:
                                        localizeText(
                                            'Computation.FieldFromEntityParameter',
                                            'Veld uit ${parameterName}',
                                            {
                                                parameterName: parameter.name.toLowerCase()
                                            }),
                                    isActive:
                                        value =>
                                            value instanceof ValueFromEntityComputation
                                            && value.entity instanceof Parameter
                                            && value.entity.id === parameter.id,
                                    construct:
                                        async () =>
                                            new ValueFromEntityComputation(
                                                parameter,
                                                undefined)
                                }));

                    unsuppressedParameters
                        .filter(
                            parameter =>
                                parameter.type instanceof DataSourceValueType)
                        .forEach(
                            parameter =>
                                fieldOfParameterGroup.items.push({
                                    id: `ValueFromDataSourceValueParameter.${parameter.id}`,
                                    name: localizeText(
                                        'ComputationFieldFromParameter',
                                        'Veld uit ${parameterName}',
                                        {
                                            parameterName: parameter.name.toLowerCase()
                                        }),
                                    isActive:
                                        value =>
                                            value instanceof ValueFromDataSourceValueComputation
                                            && value.value instanceof Parameter
                                            && value.value.id === parameter.id,
                                    construct:
                                        async () =>
                                            new ValueFromDataSourceValueComputation(
                                                parameter,
                                                undefined)
                                }));

                    functionGroup.items.push({
                        id: 'Text',
                        name: localizeText('Computation.Text', 'Text met variabelen'),
                        isActive:
                            value =>
                                value instanceof TextComputation && !value.isRichText,
                        construct:
                            async () =>
                                new TextComputation('', false, [])
                    });

                    functionGroup.items.push({
                        id: 'RichText',
                        name: localizeText('Computation.RichText', 'Text met variabelen en opmaak'),
                        isActive:
                            value =>
                                value instanceof TextComputation && value.isRichText,
                        construct:
                            async () =>
                                new TextComputation('', true, [])
                    });

                    functionGroup.items.push({
                        id: 'Localized',
                        name: localizeText('Computation.Localized', 'Gelokaliseerde berekening'),
                        isActive:
                            value =>
                                value instanceof LocalizedComputation,
                        construct:
                            async () =>
                                new LocalizedComputation([])
                    });

                    functionGroup.items.push({
                        id: 'LocalizedText',
                        name: DataObject.getTypeById('LocalizedText').name(),
                        isActive:
                            value =>
                                value instanceof LocalizedTextComputation,
                        construct:
                            async () =>
                                new LocalizedTextComputation([])
                    });

                    functionGroup.items.push({
                        id: 'CurrentDate',
                        name: localizeText('Generic.CurrentDate', 'Huidige datum'),
                        isActive:
                            value =>
                                value instanceof CurrentDateComputation,
                        construct:
                            async () =>
                                new CurrentDateComputation()
                    });

                    functionGroup.items.push({
                        id: 'CurrentDateTime',
                        name: localizeText('Generic.CurrentDateTime', 'Huidige datum & tijd'),
                        isActive:
                            value =>
                                value instanceof CurrentDateTimeComputation,
                        construct:
                            async () =>
                                new CurrentDateTimeComputation()
                    });

                    functionGroup.items.push({
                        id: 'PeriodBetweenDates',
                        name: localizeText('Generic.PeriodBetweenDates', 'Periode tussen datums...'),
                        isActive:
                            value =>
                                value instanceof PeriodBetweenDatesComputation,
                        construct:
                            async () =>
                                new PeriodBetweenDatesComputation(
                                    undefined,
                                    undefined,
                                    'Days'
                                )
                    });

                    queryGroup.items.push({
                        id: 'ListQuery',
                        name: localizeText('Computation.ListQuery', 'Query...'),
                        isActive:
                            value =>
                                value instanceof QueryComputation && (value._typeHint === 'List' || value.query instanceof ListQuery),
                        construct:
                            async () =>
                                new QueryComputation(undefined, 'List')
                    });

                    queryGroup.items.push({
                        id: 'AggregateQuery',
                        name: localizeText('Computation.AggregateQuery', 'Aggregatie query (aantal, som, gemiddelde, min, max)...'),
                        isActive:
                            value =>
                                value instanceof QueryComputation && (value._typeHint === 'Aggregate' || value.query instanceof AggregateQuery),
                        construct:
                            async () =>
                                new QueryComputation(undefined, 'Aggregate')
                    });

                    queryGroup.items.push({
                        id: 'QueryFileExport',
                        name: localizeText('Computation.QueryFileExport', 'Export query to file...'),
                        isActive:
                            value =>
                                value instanceof QueryFileExportComputation,
                        construct:
                            async () =>
                                new QueryFileExportComputation(
                                    undefined,
                                    new TextComputation(
                                        'export.csv',
                                        false,
                                        []
                                    ),
                                    []
                                )
                    });

                    if (portalContext)
                    {
                        queryGroup.items.push({
                            id: 'DataSourceValueById',
                            name: localizeText('Computation.DataSourceValueById', 'Databronwaarde met ID...'),
                            isActive:
                                value =>
                                    value instanceof DataSourceValueByIdComputation,
                            construct:
                                async () =>
                                    new DataSourceValueByIdComputation(
                                        props.type instanceof DataSourceValueType ? props.type.dataSourceSignature : undefined,
                                        new DynamicParameterAssignment(),
                                        undefined)
                        });

                        queryGroup.items.push({
                            id: 'DataSourceListQuery',
                            name: localizeText('Computation.DataSourceListQuery', 'Databron query...'),
                            isActive:
                                value =>
                                    value instanceof DataSourceListQueryComputation,
                            construct:
                                async () =>
                                    new DataSourceListQueryComputation(
                                        props.type instanceof DataSourceValueType
                                            ? props.type.dataSourceSignature
                                            : undefined,
                                        new DynamicParameterAssignment(),
                                        undefined,
                                        [],
                                        EmptyValue.instance,
                                        EmptyValue.instance
                                    )
                        });

                        queryGroup.items.push({
                            id: 'DataSourceAggregateQuery',
                            name: localizeText('Computation.DataSourceAggregateQuery', 'Databron aggregatie query...'),
                            isActive:
                                value =>
                                    value instanceof DataSourceAggregateQueryComputation,
                            construct:
                                async () =>
                                    new DataSourceAggregateQueryComputation(
                                        props.type instanceof DataSourceValueType
                                            ? props.type.dataSourceSignature
                                            : undefined,
                                        new DynamicParameterAssignment(),
                                        undefined,
                                        Aggregate.Count,
                                        EmptyValue.instance
                                    )
                        });
                    }

                    functionGroup.items.push({
                        id: 'MathematicalComputation',
                        name: localizeText('Computation.Mathematical', 'Berekening (+, -, x, :)'),
                        isActive:
                            value =>
                                value instanceof MathematicalComputation,
                        construct:
                            async () =>
                                new MathematicalComputation(
                                    undefined,
                                    MathematicalOperator.Add,
                                    undefined)
                    });

                    functionGroup.items.push({
                        id: 'ConditionalComputation',
                        name: localizeText('Computation.Conditional', 'Als ... dan ... of anders ...'),
                        isActive:
                            value =>
                                value instanceof ConditionalComputation,
                        construct:
                            async () =>
                                new ConditionalComputation(
                                    [
                                        new ConditionalInComputation(
                                            new CompositePredicate(
                                                LogicalOperator.And,
                                                []),
                                            undefined)
                                    ],
                                    undefined)
                    });

                    functionGroup.items.push({
                        id: 'RegEx',
                        name: localizeText('Computation.RegEx', 'Regular expression'),
                        isActive:
                            value =>
                                value instanceof RegExComputation,
                        construct:
                            async () =>
                                new RegExComputation(
                                    undefined,
                                    undefined)
                    });

                    functionGroup.items.push({
                        id: 'Rounding',
                        name: localizeText('Computation.Rounding', 'Afronden'),
                        isActive:
                            value =>
                                value instanceof RoundedNumberComputation,
                        construct:
                            async () =>
                                new RoundedNumberComputation(
                                    0,
                                    'HalfUp',
                                    EmptyValue.instance
                                )
                    });

                    functionGroup.items.push({
                        id: 'RangeCollection',
                        name: localizeText('Computation.RangeCollection', 'Collectie uit range'),
                        isActive:
                            value =>
                                value instanceof RangeCollectionComputation,
                        construct:
                            async () =>
                                new RangeCollectionComputation(
                                    EmptyValue.instance,
                                    EmptyValue.instance,
                                    EmptyValue.instance)
                    });

                    functionGroup.items.push({
                        id: 'HtmlFromLayout.Default',
                        name: localizeText('Computation.HtmlFromLayout.Default', 'Layout (standaard HTML)'),
                        isActive:
                            value =>
                                value instanceof HtmlFromLayoutComputation
                                    && value.renderOptions.outputType === 'Default',
                        construct:
                            async () =>
                                new HtmlFromLayoutComputation(
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0),
                                    new RenderOptions('Default'))
                    });

                    functionGroup.items.push({
                        id: 'HtmlFromLayout.Email',
                        name: localizeText('Computation.HtmlFromLayout.Email', 'Layout (email HTML)'),
                        isActive:
                            value =>
                                value instanceof HtmlFromLayoutComputation
                                    && value.renderOptions.outputType === 'Email',
                        construct:
                            async () =>
                                new HtmlFromLayoutComputation(
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0),
                                    new RenderOptions('Email'))
                    });

                    functionGroup.items.push({
                        id: 'Pdf',
                        name: localizeText('Computation.Pdf', 'PDF'),
                        isActive:
                            value =>
                                value instanceof PdfComputation,
                        construct:
                            async () =>
                                new PdfComputation(
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0),
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0),
                                    new CompositeLayout(
                                        'Vertical',
                                        [],
                                        0),
                                    30,
                                    20,
                                    20,
                                    20,
                                    new TextComputation('pdf', false, []))
                    });

                    functionGroup.items.push({
                        id: 'MergedPdf',
                        name: localizeText('Computation.MergedPdf', 'Samengevoegde PDF'),
                        isActive:
                            value =>
                                value instanceof MergedPdfComputation,
                        construct:
                            async () =>
                                new MergedPdfComputation(
                                    [
                                        new MergedPdfComputationPdf(
                                            uuid(),
                                            EmptyValue.instance)
                                    ],
                                    new TextComputation('pdf', false, []))
                    });

                    aiGroup.items.push({
                        id: 'AiPrompt',
                        name: localizeText('Computation.AiPrompt', 'AI prompt'),
                        isActive:
                            value =>
                                value instanceof AiPromptComputation,
                        construct:
                            async () =>
                                new AiPromptComputation(
                                    [
                                        new AiPromptComputationPart(
                                            uuid(),
                                            new TextComputation(
                                                '',
                                                false,
                                                []
                                            )
                                        )
                                    ],
                                    new PrimitiveValueType(
                                        DataObject.getTypeById('Text')
                                    )
                                )
                    });

                    aiGroup.items.push({
                        id: 'AiAudioTranscription',
                        name: localizeText('Computation.AiAudioTranscription', 'AI audio transcriptie'),
                        isActive:
                            value =>
                                value instanceof AiAudioTranscriptionComputation,
                        construct:
                            async () =>
                                new AiAudioTranscriptionComputation(EmptyValue.instance)
                    });

                    if (props.value)
                    {
                        clipboardGroup.items.push({
                            id: 'CopyToClipboard',
                            name: localizeText('Generic.CopyToClipboard', 'Kopieren naar klembord'),
                            isActive: () => false,
                            construct:
                                async () =>
                                {
                                    copyComputationToClipboard(props.value);

                                    return props.value;
                                }
                        });
                    }

                    if (hasCopiedComputationInClipboard()
                        && isComputationCompatibleWithParameterDictionary(
                            getCopiedComputationFromClipboard(),
                            props.context.parameterDictionary))
                    {
                        clipboardGroup.items.push({
                            id: 'CopyFromClipboard',
                            name: localizeText('Generic.PasteFromClipboard', 'Plakken vanuit klembord'),
                            isActive: () => false,
                            construct:
                                async () =>
                                {
                                    const computationDescriptor = getCopiedComputationFromClipboard().toDescriptor();
                                    return await getComputationFromDescriptor(
                                        computationDescriptor,
                                        new AutomationDependencyContext(props.context.parameterDictionary)
                                    );
                                }
                        });
                    }

                    return [
                        [
                            parameterGroup,
                            fieldOfParameterGroup,
                        ],
                        [
                            staticGroup,
                            queryGroup,
                            functionGroup,
                            ...currentUserStore.isDeveloper
                                ? [ aiGroup ]
                                : [],
                            clipboardGroup,
                        ]
                    ]
                        .map(
                            groupOfGroups =>
                                groupOfGroups.map(
                                    group => ({
                                        ...group,
                                        items:
                                            props.constructorItemFilter
                                                ? group.items.filter(item => props.constructorItemFilter(item))
                                                : group.items
                                    }))
                                    .filter(
                                        group =>
                                            group.items.length > 0))
                        .filter(
                            groupOfGroups =>
                                groupOfGroups.length > 0);
                },
                [
                    props.disallowStaticValue,
                    parameters,
                    props.constructorItemFilter,
                    props.type,
                    props.value,
                    props.context,
                    portalContext,
                    suppressedParameters,
                    currentUserStore.isDeveloper,
                ]);

        return <ViewGroup
            orientation="horizontal"
            spacing={0}
            glue={
                () =>
                    <div
                        className={classes.divider}
                    />
            }
        >
            {
                groupOfGroups.map(
                    (groupOfGroup, idx) =>
                        <ViewGroupItem
                            key={idx}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                className={classes.group}
                            >
                                {
                                    groupOfGroup.map(
                                        group =>
                                            <ViewGroupItem
                                                key={group.id}
                                            >
                                                <ViewGroup
                                                    orientation="vertical"
                                                    spacing={0}
                                                    className={classes.group}
                                                >
                                                    <ViewGroupItem
                                                        className={classes.groupName}
                                                    >
                                                        {group.name}
                                                    </ViewGroupItem>
                                                    <ViewGroupItem>
                                                        <Menu>
                                                            {
                                                                group.items.map(
                                                                    item =>
                                                                        <Item
                                                                            key={item.id}
                                                                            active={item.isActive(props.value)}
                                                                            name={item.name}
                                                                            onClick={
                                                                                async () =>
                                                                                {
                                                                                    const computation = await item.construct();

                                                                                    return props.onChange(computation);
                                                                                }
                                                                            }
                                                                        />)
                                                            }
                                                        </Menu>
                                                    </ViewGroupItem>
                                                </ViewGroup>
                                            </ViewGroupItem>
                                    )
                                }
                            </ViewGroup>
                        </ViewGroupItem>)
            }
        </ViewGroup>;
    };

export default observer(ComputationConstructor);
