import React from 'react';
import ViewGroup from '../../ViewGroup/ViewGroup';
import useIsMobile from '../../../../../@Util/Responsiveness/useIsMobile';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import ButtonGroup from './ButtonGroup';

export interface RightAlignedButtonGroupProps
{

}

const RightAlignedButtonGroup: React.FC<RightAlignedButtonGroupProps> =
    props =>
    {
        const isMobile = useIsMobile();

        if (isMobile)
        {
            return <ButtonGroup>
                {props.children}
            </ButtonGroup>;
        }
        else
        {
            return <ViewGroup
                orientation="horizontal"
                spacing={0}
            >
                <ViewGroupItem
                    ratio={1}
                />
                <ViewGroupItem>
                    <ButtonGroup>
                        {props.children}
                    </ButtonGroup>
                </ViewGroupItem>
            </ViewGroup>;
        }
    };

export default RightAlignedButtonGroup;
