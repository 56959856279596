import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import useIsNew from '../../../../../../../@Api/Entity/Bespoke/useIsNew';
import EntityTypeContext from '../../../../Type/EntityTypeContext';
import { Aggregate } from '../../../../../DataObject/Model/Aggregate';
import { EntitySelectionAggregateResult } from '../../../../Selection/Model/EntitySelectionAggregateResult';
import { useComputed } from 'mobx-react-lite';
import DataObjectContext from '../../../../../DataObject/DataObjectContext';
import { DataObject } from '../../../../../DataObject/Model/DataObject';
import useFilter from '../../List/Api/useFilter';
import useRootPath from '../../List/Api/useRootPath';
import NavigatorContext from '../../NavigatorContext/NavigatorContext';
import getViewParameters from '../../../../View/Api/getViewParameters';
import { ViewParams } from '../../../../View/Model/ViewParams';

export default function useCount(entity: Entity,
                                 path: EntityPath,
                                 onCount?: (count: number) => void)
{
    const [ result, setResult ] = useState<EntitySelectionAggregateResult>(undefined);
    const isNew = useIsNew(entity);
    const entityTypeStore = useContext(EntityTypeContext);
    const dataObjectStore = useContext(DataObjectContext);
    const rootPath = useRootPath(path);
    const viewParameters =
        useMemo(
            () =>
                getViewParameters(rootPath.entityType),
            [
                rootPath,
            ]
        );
    const viewParameter =
        useMemo(
            () =>
                viewParameters.getParameterById(ViewParams.Entity),
            [
                viewParameters
            ]
        );
    const filter =
        useFilter(
            viewParameter,
            rootPath
        );
    const navigatorContext = useContext(NavigatorContext);

    useEffect(
        () =>
        {
            if (isNew)
            {
                setResult(
                    new EntitySelectionAggregateResult(
                        undefined,
                        undefined,
                        undefined,
                        [
                            DataObject.constructFromTypeIdAndValue('Number', 0, dataObjectStore)
                        ],
                        undefined,
                        []));
            }
            else
            {
                new EntitySelectionBuilder(path.entityType)
                    .where(
                        cb =>
                            cb.relatedToEntity(
                                path.reverse(),
                                entity
                            )
                    )
                    .if(
                        () =>
                            filter !== undefined,
                        sb =>
                            sb.where(
                                cb =>
                                    cb.filter(
                                        filter,
                                        {
                                            parameter: viewParameter,
                                        }
                                    )
                            )
                    )
                    .aggregateOn(
                        rootPath.field(entityTypeStore.idField),
                        undefined,
                        Aggregate.Count
                    )
                    .selectAggregates()
                    .then(setResult);
            }
        },
        [
            entity,
            isNew,
            path,
            dataObjectStore,
            entityTypeStore.idField,
            rootPath,
            filter,
        ]);

    useEffect(
        () =>
        {
            if (navigatorContext)
            {
                navigatorContext.recordCount(
                    path,
                    result);
            }
        },
        [
            navigatorContext,
            path,
            result
        ]);

    const count =
        useComputed(
            () =>
                result
                && result.aggregates.length > 0
                && result.aggregates[0].value,
            [
                result
            ]);

    useEffect(
        () =>
        {
            if (onCount)
            {
                onCount(count);
            }
        },
        [
            onCount,
            count
        ]);

    return count;
}
