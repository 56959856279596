import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import useTypes from '../../../../Type/Api/useTypes';
import { Table } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { PortalUserFilterType, PortalUserListHead } from './PortalUserListHead';
import { SortExpression } from '../../../../../../../@Future/Component/Generic/Header/Header';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { PortalUserListRow } from './PortalUserListRow';
import HoverCardMiddle from '../../../../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import { ApiRequest, Method } from '../../../../../../../@Service/ApiClient/Model/ApiRequest';
import useApiClient from '../../../../../../../@Service/ApiClient/Hooks/useApiClient';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import Centered from '../../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../../@Future/Component/Generic/Loader/Loader';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import Icon from '../../../../../../../@Future/Component/Generic/Icon/Icon';
import CurrentUserContext from '../../../../../User/CurrentUserContext';
import constructEntity from '../../../../../../../@Api/Entity/constructEntity';
import Card from '../../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import { useNewCommitContext } from '../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import ComparisonPredicate from '../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../View/Model/ViewParams';
import { Comparator } from '../../../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../../../@Api/Automation/Value/EntityValue';
import getViewParameters from '../../../../View/Api/getViewParameters';


const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
    }
});

export interface PortalUserListProps
{
    portal: Entity;
}

export interface PortalUser
{
    id: number;
    emailAddress: string;
    hasTwoFactorAuthentication?: boolean;
    contact?: string;
    relationship: string;
    userId?: number;
    activationDate?: string;
    lastActivityDate?: string;
}

const PageSize = 15;

export const PortalUserList: React.FC<PortalUserListProps> =
    observer(
        ({
            portal
         }) =>
        {
            const types = useTypes();
            const classes = useStyles();
            const apiClient = useApiClient();
            const currentUserStore = useContext(CurrentUserContext);
            const commitContext = useNewCommitContext();

            const defaultSortExpression: SortExpression =
                useMemo(
                    () =>
                        ({
                            fieldPath: EntityPath.root(types.PortalUser.Type)
                                .field(types.PortalUser.Field.EmailAddress),
                            order: 'Ascending'
                        }),
                    [
                        types
                    ]
                );

            const [ sort, setSort ] = useState<SortExpression>(defaultSortExpression);
            const [ isLoading, setLoading ] = useState<boolean>(false);
            const [ portalUsers, setPortalUsers ] = useState<PortalUser[]>([]);
            const [ lastPage, setLastPage ] = useState<number>(0);
            const [ hasMore, setHasMore ] = useState<boolean>(false);
            const [ usernameFilter, setUsernameFilter ] = useState<string>();
            const [ contactFilter, setContactFilter ] = useState<string>();
            const [ relationshipFilter, setRelationshipFilter ] = useState<string>();

            const loadPage =
                useCallback(
                    async (
                        page: number = 0,
                        pageSize: number = PageSize
                    ) =>
                    {
                        setLoading(true);

                        await apiClient.request(
                            new ApiRequest<PortalUser[]>(
                                `/portals/${portal.id}/users`,
                                Method.Get,
                                {
                                    sortByField: (sort.fieldPath || defaultSortExpression.fieldPath).descriptor,
                                    sortAscending: sort?.order === 'Ascending',
                                    page,
                                    pageSize: pageSize,
                                    usernameFilter: usernameFilter,
                                    contactFilter: contactFilter,
                                    relationshipFilter: relationshipFilter,
                                }
                            )
                        ).then(
                            result => {
                                setPortalUsers(
                                    portalUsers =>
                                        page === 0
                                            ? result
                                            : portalUsers.concat(result)
                                );
                                setLastPage(page);
                                setHasMore(result.length === pageSize);
                                setLoading(false);
                            }
                        );
                    },
                    [
                        apiClient,
                        portal,
                        PageSize,
                        setLoading,
                        setPortalUsers,
                        setLastPage,
                        setHasMore,
                        usernameFilter,
                        contactFilter,
                        relationshipFilter,
                        sort,
                    ]
                );

            // Initial load
            useEffect(
                () => {
                    loadPage(0).finally();
                },
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [
                    usernameFilter,
                    contactFilter,
                    relationshipFilter,
                    sort
                ]
            );

            const filters =
                useMemo(
                    () =>
                        new Map<PortalUserFilterType, string>([
                            ['Username', usernameFilter],
                            ['Contact', contactFilter],
                            ['Relationship', relationshipFilter],
                        ]),
                    [
                        usernameFilter,
                        contactFilter,
                        relationshipFilter
                    ]
            );

            const onSort =
                useCallback(
                    (sortExpression: SortExpression | undefined) =>
                        setSort(
                            sortExpression && sortExpression.order
                                ? sortExpression
                                : defaultSortExpression
                        ),
                    [
                        setSort,
                        defaultSortExpression,
                    ]);

            const onFilterChange =
                useCallback(
                    (
                        type: PortalUserFilterType,
                        filter: string
                    ) =>
                    {
                        switch (type)
                        {
                            case 'Username':
                                setUsernameFilter(filter);
                                break;
                            case 'Contact':
                                setContactFilter(filter);
                                break;
                            case 'Relationship':
                                setRelationshipFilter(filter);
                                break;
                        }
                    },
                    [
                        setUsernameFilter,
                        setContactFilter,
                        setRelationshipFilter,
                    ]);

            const canCreate =
                useMemo(
                    () =>
                        currentUserStore.rightProfile.canCreateType(types.PortalUser.Type),
                    [
                        currentUserStore.rightProfile,
                        types,
                    ]
                );

            const onAdd =
                useCallback(
                    () =>
                        constructEntity(
                            types.PortalUser.Type,
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    getViewParameters(types.PortalUser.Type)
                                        .getParameterById(ViewParams.Entity),
                                    EntityPath.fromEntity(portal)
                                        .joinTo(
                                            types.Portal.RelationshipDefinition.Users,
                                            false
                                        ).reverse().field()),
                                Comparator.Equals,
                                new EntityValue(portal))
                        ),
                    [
                        types,
                        portal,
                        commitContext
                    ]);

            return <>
                {
                    canCreate &&
                    <Card>
                        <CardInset>
                            <RightAlignedButtonGroup>
                                <PrimaryButton
                                    label={
                                        <Icon
                                            icon="add"
                                            size="20px"
                                        />
                                    }
                                    tooltip={
                                        <LocalizedText
                                            code="Generic.AddEntityType"
                                            value="${entityTypeName} toevoegen"
                                            entityTypeName={types.PortalUser.Type.getName()}
                                        />
                                    }
                                    onClick={onAdd}
                                    size="sm"
                                />
                            </RightAlignedButtonGroup>
                        </CardInset>
                    </Card>
                }
                <Table
                    className={classes.root}
                >
                    <PortalUserListHead
                        sort={sort}
                        onSort={onSort}
                        filters={filters}
                        onFilterChange={onFilterChange}
                    />
                    <TableBody>
                        {
                            !isLoading &&
                            portalUsers?.map(
                                portalUser =>
                                     <PortalUserListRow
                                        key={portalUser.id}
                                        portalUser={portalUser}
                                     />
                            )
                        }
                    </TableBody>
                </Table>
                {
                    isLoading &&
                        <Centered
                            horizontal
                            vertical
                        >
                            <Loader />
                        </Centered>
                }
                {
                    hasMore &&
                        <HoverCardMiddle
                            onClick={() => loadPage(lastPage + 1)}
                            disabled={isLoading}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={0}
                                alignment="center"
                            >
                                <ViewGroupItem
                                    ratio={1}
                                >
                                    <LocalizedText
                                        code="Generic.LoadMore"
                                        value="Meer laden"
                                    />...
                                </ViewGroupItem>
                            </ViewGroup>
                        </HoverCardMiddle>
                }
            </>
        }
    );
