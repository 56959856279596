import Widget from '../../Widget';
import { observable } from 'mobx';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { loadModuleDirectly } from '../../../../../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { EntityTypeStore } from '../../../../Type/EntityTypeStore';
import { EmptyDisposer } from '../../../../../../../@Util/Disposable/EmptyDisposer';

export default class ViewsWidget extends Widget
{
    // ------------------------- Properties -------------------------

    @observable.ref entityType: EntityType;

    // ------------------------ Constructor -------------------------

    constructor(id: string,
                entityType: EntityType)
    {
        super(id);

        this.entityType = entityType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    async doInitialize(widgets: ViewsWidget[])
    {
        return EmptyDisposer;
    }

    static fromDescriptor(descriptor: any)
    {
        return new ViewsWidget(
            descriptor.id,
            loadModuleDirectly(EntityTypeStore).getTypeById(descriptor.entityTypeId));
    }

    toDescriptor()
    {
        return {
            ...super.toDescriptor(),
            type: 'Views',
            entityTypeId: this.entityType?.id
        }
    }

    // ----------------------- Private logic ------------------------
}
