import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useConstructorFieldPaths from '../Api/useConstructorFieldPaths';
import { ContentProps } from './Content';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import { useMemoizedArray } from '../../../../../@Util/Array/useMemoizedArray';
import Fields from '../../Fields/Fields';
import FieldInsetContext from '../../Fields/Context/FieldInsetContext';
import { EntityFieldPath } from '../../Path/@Model/EntityFieldPath';

export interface DefaultProps extends ContentProps
{
    appendix?: React.ReactNode;
    hideGroups?: boolean;
    expandedByDefault?: (code: string) => boolean;
}

const Default: React.FC<DefaultProps> =
    props =>
    {
        const fieldPaths =
            useConstructorFieldPaths(
                props.entity,
                props.fromFieldPath
            );
        const filteredPaths =
            useComputed(
                () =>
                    fieldPaths.filter(
                        fieldPath =>
                            !props.filter || props.filter(fieldPath)
                    ),
                [
                    fieldPaths,
                    props.filter
                ]
            );
        const memoizedFilteredPaths =
            useMemoizedArray(
                filteredPaths,
                (a, b) =>
                    a.id === b.id,
                []
            );
        const [ , isLoading ] =
            useAsyncResult(
                () =>
                    new EntityExpansionBuilder(
                        props.entity.entityType,
                        [ props.entity ],
                        memoizedFilteredPaths.map(fieldPath => fieldPath.path))
                        .expand(),
                [
                    props.entity,
                    ...memoizedFilteredPaths
                ]
            );
        const autoFocus =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    props.autoFocus && fieldPath.id === memoizedFilteredPaths[0].id,
                [
                    props.autoFocus,
                    memoizedFilteredPaths,
                ]
            );

        if (isLoading)
        {
            return <Centered
                horizontal
            >
                <Loader />
            </Centered>;
        }
        else
        {
            return <FieldInsetContext.Provider
                value={false}
            >
                <Fields
                    entity={props.entity}
                    fieldPaths={memoizedFilteredPaths}
                    commitContext={props.commitContext}
                    useProvidedCommitContext
                    required={props.required}
                    disabled={props.disabled}
                    autoFocus={autoFocus}
                    doAutoCommit={props.autoCommit}
                    touched={props.touched}
                    appendix={props.appendix}
                    hideGroups={props.hideGroups}
                    expandedByDefault={props.expandedByDefault}
                    hideBespokeGroups
                    noVirtualGroups
                    hideNoFields
                />
            </FieldInsetContext.Provider>;
        }
    };

export default observer(Default);
