import { ApiBaseType } from '../ApiBaseType';
import { injectWithQualifier, ModuleManager } from '../../../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../../../Entity/Type/EntityTypeStore';
import { DataObject } from '../../../Model/DataObject';
import Dictionary from 'typescript-collections/dist/lib/Dictionary';
import { DataObjectSpecification } from '../../../Model/DataObjectSpecification';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { EntitySelectionBuilder } from '../../../../Entity/Selection/Builder/EntitySelectionBuilder';
import { EntityController } from '../../../../../../@Api/Controller/Directory/EntityController';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import getEntitiesByIds from '../../../../../../@Api/Entity/Bespoke/getEntitiesByIds';
import localizeText from '../../../../../../@Api/Localization/localizeText';

export class EntityType extends ApiBaseType<Entity>
{
    @injectWithQualifier('EntityTypeStore') entityTypeStore: EntityTypeStore;
    @injectWithQualifier('EntityController') entityController: EntityController;
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Entity';
    }

    name()
    {
        return localizeText('DataObject.Type.Entity', 'Entiteit');
    }

    getInstanceId(instance: Entity): number
    {
        return instance.id;
    }

    getInstanceName(instance: Entity): string
    {
        return instance.name;
    }

    getInstanceDescription(instance: Entity): string
    {
        return instance.description;
    }

    getInstanceAvatarUrl(instance: Entity, moduleManager: ModuleManager): string
    {
        return null;
    }

    getInstances(ids: number[],
                 dataObjectById: Dictionary<number, DataObject>,
                 moduleManager: ModuleManager): Promise<Entity[]>
    {
        return getEntitiesByIds(this.entityTypeStore.bespoke.types.Entity.Type, ids)
            .then(
                result =>
                    Promise.resolve(result.value));
    }

    searchInstances(query: string, limit: number, dataObject: DataObject, moduleManager: ModuleManager): Promise<Entity[]>
    {
        const entityType =
            this.entityTypeStore.getTypeById(
                dataObject.specification.data.entityTypeId);

        if (entityType)
        {
            return new EntitySelectionBuilder(entityType)
                .select(0, limit)
                .then(results =>
                {
                    return Promise.resolve(
                        results.map(
                            result => result.entity));
                });
        }
        else
        {
            return Promise.resolve([]);
        }
    }

    getAllInstances(specification: DataObjectSpecification,
                    moduleManager: ModuleManager): Promise<Entity[]>
    {
        return Promise.resolve([]);
    }
}
