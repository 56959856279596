import ValueType from './ValueType';
import PortalDataSource from '../../../Portal/DataSource/PortalDataSource';
import { observable } from 'mobx';
import canGetPortalDataSourceById from '../../../Portal/DataSource/Api/canGetPortalDataSourceById';
import getPortalDataSourceById from '../../../Portal/DataSource/Api/getPortalDataSourceById';
import PortalDataSourceSignature from '../../../Portal/DataSource/PortalDataSourceSignature';
import getPortalDataSourceSignatureById from '../../../Portal/DataSource/Api/getPortalDataSourceSignatureById';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import localizeText from '../../../Localization/localizeText';

export default class DataSourceValueType extends ValueType<string>
{
    // ------------------------- Properties -------------------------

    @observable.ref dataSource: PortalDataSource;
    @observable.ref dataSourceSignature: PortalDataSourceSignature;

    // ------------------------ Constructor -------------------------

    constructor(type: string,
                dataSource?: PortalDataSource,
                dataSourceSignature?: PortalDataSourceSignature)
    {
        super(type);

        this.dataSource = dataSource;
        this.dataSourceSignature = dataSourceSignature;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    id(): string
    {
        return `DataSource(${this.type})`;
    }

    isA(type: ValueType<any>): boolean
    {
        if (type instanceof DataSourceValueType)
        {
            return this.type === type.type;
        }
        else
        {
            return false;
        }
    }

    getName(): string
    {
        if (this.dataSourceSignature)
        {
            return localizeText(
                'DataSourceValueFrom',
                'Databronwaarde uit ${name}',
                {
                    name: this.dataSourceSignature.name
                }
            );
        }
        else if (this.dataSource)
        {
            return localizeText(
                'DataSourceValueFrom',
                'Databronwaarde uit ${name}',
                {
                    name: this.dataSource.name
                }
            );
        }
        else
        {
            return this.type;
        }
    }

    toDescriptor()
    {
        return {
            type: 'DataSource',
            dataSourceId: this.type
        };
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        const dataSourceId = descriptor.dataSourceId;
        const dataSource =
            canGetPortalDataSourceById()
                ?
                    await getPortalDataSourceById(dataSourceId, dependencyContext)
                :
                    undefined;
        const dataSourceSignature =
            dataSource
                ?
                    dataSource.toSignature()
                :
                    await getPortalDataSourceSignatureById(dataSourceId);

        return new DataSourceValueType(
            dataSourceId,
            dataSource,
            dataSourceSignature);
    }

    // ----------------------- Private logic ------------------------
}
