import { action, computed } from 'mobx';
import { ToolbarElementCommandStore } from '../ToolbarElementCommandStore';
import { ToolbarFontSize } from './ToolbarFontSize';
import { ButtonStore } from '../../../../Button/ButtonStore';
import { ViewComponent } from '../../../../ViewStack/Model/ViewComponent';
import { MenuStore } from '../../../../Menu/MenuStore';
import { MenuItemStore } from '../../../../Menu/MenuItemStore';
import Menu from '../../../../Menu/Menu';
import { LocalizationStore } from '../../../../../../@Service/Localization/LocalizationStore';
import { injectWithQualifier } from '../../../../../../@Util/DependencyInjection/index';

export class ToolbarFontSizeStore extends ToolbarElementCommandStore
{
    // ------------------------ Dependencies ------------------------

    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(id: string | number,
                command: string)
    {
        super(id, ToolbarFontSize, command, true);

        this.collapsible = true;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    @computed
    get buttonStore(): ButtonStore
    {
        return new ButtonStore(
        {
            label: this.localizationStore.translate('GenericComponent.Toolbar.FontSize.Normal'),
            color: 'primary',
            backgroundColor: 'gray',
            size: 'small',
            onClick:
                buttonStore =>
                    buttonStore.openPopperView(
                        new ViewComponent(
                            Menu as any,
                            this.menuStore)),
            style:
                {
                    minHeight: 25,
                    height: 25,
                }
        });
    }

    @computed
    get menuStore(): MenuStore
    {
        return new MenuStore(
            {
                items:
                    [
                        new MenuItemStore<any>(
                            {
                                id: 'small',
                                name: this.localizationStore.translate('GenericComponent.Toolbar.FontSize.Small'), // Small
                                value: 'small'
                            }),
                        new MenuItemStore<any>(
                            {
                                id: 'normal',
                                name: this.localizationStore.translate('GenericComponent.Toolbar.FontSize.Normal'), // Normal
                                value: false
                            }),
                        new MenuItemStore<any>(
                            {
                                id: 'large',
                                name: this.localizationStore.translate('GenericComponent.Toolbar.FontSize.Large'), // Large
                                value: 'large'
                            }),
                        new MenuItemStore<any>(
                            {
                                id: 'huge',
                                name: this.localizationStore.translate('GenericComponent.Toolbar.FontSize.ExtraLarge'), // Extra large
                                value: 'huge'
                            })
                    ],
                onSelect:
                    itemStore =>
                    {
                        this.selectMenuItem(itemStore);
                        this.buttonStore.closePopperView();
                    }
            });
    }

    // -------------------------- Actions ---------------------------

    @action.bound
    selectMenuItem(itemStore: MenuItemStore)
    {
        this.setFormat(this.command, itemStore.value);
        this.setLabel(itemStore.name);
    }

    @action.bound
    setLabel(label: string)
    {
        this.buttonStore.props.label = label;
    }

    passEventInternal(event: any)
    {
        super.passEventInternal(event);

        // Change state
        if (event[this.command])
        {
            let menuItem = this.menuStore.itemStores
                .find(
                    item =>
                        item instanceof MenuItemStore
                            ?
                                item.value === event[this.command]
                            :
                                undefined);

            if (menuItem && menuItem instanceof MenuItemStore)
            {
                this.setLabel(menuItem.name);
            }
        }
        else
        {
            let menuItem = this.menuStore.itemStores
                .find(
                    item =>
                        item instanceof MenuItemStore
                            ?
                                item.value === false
                            :
                                undefined);
            if (menuItem && menuItem instanceof MenuItemStore)
            {
                this.setLabel(menuItem.name);
            }
        }
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
