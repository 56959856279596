import { useContext, useMemo } from 'react';
import CurrentUserContext from '../../User/CurrentUserContext';
import Feature from '../Model/Feature';

export default function useIsFeatureAllowed(feature: Feature)
{
    const currentUserStore = useContext(CurrentUserContext);

    return useMemo(
        () =>
            currentUserStore.rightProfile.role.isPermissionGrantedForFeature(feature),
        [
            currentUserStore,
            feature
        ]);
}
