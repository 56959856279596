import { DataObject } from '../../DataObject/Model/DataObject';
import getLocalSetting from './getLocalSetting';

export default async function getLocalPrimitiveSetting(code: string,
                                                       initialState?: DataObject): Promise<DataObject | undefined>
{
    const initialStateDescriptor = initialState?.descriptor();
    const descriptor = await getLocalSetting(code, initialStateDescriptor);

    if (descriptor)
    {
        return DataObject.constructFromDescriptor(descriptor);
    }
    else
    {
        return undefined;
    }
}
