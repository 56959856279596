import React, { useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntitySelectionAggregateResult } from '../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import List from './List/List';
import Selector from './Selector/Selector';
import { default as SegmentModel } from '../Model/Segment';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useCombinedPredicate from './Api/useCombinedPredicate';
import FilterContext from './FilterContext/FilterContext';
import CardInset from '../../../../../@Future/Component/Generic/Card/CardInset';
import FilterOption from './FilterOption/FilterOption';
import usePhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePhaseRelationshipDefinition';
import IdContext from '../Context/IdContext';
import PipelineSelector from './PipelineSelector/PipelineSelector';
import PipelineContext from '../Context/PipelineContext';
import usePipelineSetting from '../../Workflow/Api/usePipelineSetting';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import initializeFilterOptions from './FilterOption/Api/initializeFilterOptions';
import { EntityPath } from '../../Path/@Model/EntityPath';
import getPipelineRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPipelineRelationshipDefinition';
import useTypes from '../../Type/Api/useTypes';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { DataObject } from '../../../DataObject/Model/DataObject';
import CompositePredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../DataObject/Model/LogicalOperator';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import ValueFromEntityComputation from '../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import DatasetContext from '../Context/DatasetContext';
import ComparisonPredicate from '../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import PrimitiveValue from '../../../../../@Api/Automation/Value/PrimitiveValue';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import EmptyValue from '../../../../../@Api/Automation/Value/EmptyValue';
import { TextType } from '../../../DataObject/Type/Text/TextType';
import { default as Option } from '../Model/FilterOption';

export interface SegmentProps
{
    entityType: EntityType;
    segment?: SegmentModel;
    nextSegments: SegmentModel[];
    result?: EntitySelectionAggregateResult;
    name?: string;
    root?: boolean;
}

const Segment: React.FC<SegmentProps> =
    props =>
    {
        const types = useTypes();
        const phaseRelationshipDefinition = usePhaseRelationshipDefinition(props.entityType);
        const id = useContext(IdContext);
        const parentPipeline = useContext(PipelineContext);
        const [ pipeline, setPipeline, isLoadingPipeline ] =
            usePipelineSetting(
                `${id}.${props.segment?.id}.Pipeline`,
                props.entityType,
                phaseRelationshipDefinition !== undefined && parentPipeline === undefined);
        const datasetId = useContext(IdContext);
        const dataset = useContext(DatasetContext);
        const [ , isInitializingFilterOptions ] =
            useAsyncResult(
                () =>
                    initializeFilterOptions(
                        props.segment?.filterOptions || []
                    ),
                [
                    datasetId,
                    props.segment
                ]);

        const pipelineFilter =
            useMemo(
                () =>
                {
                    if (props.root)
                    {
                        const phasePipelineRelationshipDefinition = getPipelineRelationshipDefinition(props.entityType);

                        if (phasePipelineRelationshipDefinition)
                        {
                            if (pipeline)
                            {
                                return new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        dataset.parameter,
                                        EntityPath.fromEntityType(props.entityType)
                                            .joinTo(
                                                phasePipelineRelationshipDefinition,
                                                false)
                                            .field()),
                                    Comparator.Equals,
                                    new EntityValue(pipeline));
                            }
                            else
                            {
                                return new ComparisonPredicate(
                                    new ValueFromEntityComputation(
                                        dataset.parameter,
                                        EntityPath.fromEntityType(props.entityType)
                                            .joinTo(
                                                phasePipelineRelationshipDefinition,
                                                false)
                                            .field(types.Entity.Field.Id)),
                                    Comparator.IsNotDefined,
                                    EmptyValue.instance);
                            }
                        }
                        else
                        {
                            return undefined;
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    pipeline,
                    props.entityType,
                    types,
                    props.root,
                    dataset
                ]);

        const segmentFilterWithOptions =
            useComputed(
                () =>
                {
                    if (props.segment)
                    {
                        const filterOptionsToConsider =
                            props.segment.filterOptions
                                .filter(
                                    filterOption =>
                                        filterOption.value
                                        && !(filterOption.value instanceof DataObject && filterOption.value.isEmpty)
                                );

                        if (filterOptionsToConsider.length === 0)
                        {
                            return undefined;
                        }
                        else
                        {
                            return new CompositePredicate(
                                LogicalOperator.And,
                                filterOptionsToConsider
                                    .map(
                                        filterOption =>
                                        {
                                            if (filterOption.fieldPath.field)
                                            {

                                                const getComparatorForOption = (option: Option): Comparator =>
                                                {
                                                    if (option.isRange)
                                                    {
                                                        return Comparator.In
                                                    }
                                                    else if (option.fieldPath.field.dataObjectSpecification.type instanceof TextType)
                                                    {
                                                        return Comparator.Contains
                                                    }
                                                    else 
                                                    {
                                                        return Comparator.Equals
                                                    }
                                                }

                                                return new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        dataset.parameter,
                                                        filterOption.fieldPath
                                                    ),
                                                    getComparatorForOption(filterOption),
                                                    new PrimitiveValue((filterOption.value as DataObject).clone())); // clone to make this method reactive
                                            }
                                            else
                                            {
                                                return new ComparisonPredicate(
                                                    new ValueFromEntityComputation(
                                                        dataset.parameter,
                                                        filterOption.fieldPath.path.field()),
                                                    Comparator.Equals,
                                                    new EntityValue(filterOption.value as Entity)
                                                );
                                            }
                                        }
                                    )
                            );
                        }
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.segment,
                    dataset
                ]
            );


        const combinedFilter =
            useCombinedPredicate(
                segmentFilterWithOptions,
                pipelineFilter
            );

        if (isLoadingPipeline || isInitializingFilterOptions)
        {
            return null;
        }
        else if (props.segment)
        {
            return <PipelineContext.Provider
                value={parentPipeline || pipeline}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    {
                        (props.segment.filterOptions.length > 0 || phaseRelationshipDefinition) &&
                            <ViewGroupItem>
                                <CardInset
                                    vertical={false}
                                    left={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        />
                                        {
                                            phaseRelationshipDefinition && props.root &&
                                                <ViewGroupItem>
                                                    <PipelineSelector
                                                        entityType={props.entityType}
                                                        value={pipeline}
                                                        onChange={setPipeline}
                                                    />
                                                </ViewGroupItem>
                                        }
                                        {
                                            props.segment.filterOptions.map(
                                                filterOption =>
                                                    <ViewGroupItem
                                                        key={filterOption.id}
                                                    >
                                                        <FilterOption
                                                            filterOptionId={filterOption.id}
                                                            filterOption={filterOption}
                                                        />
                                                    </ViewGroupItem>)
                                        }
                                    </ViewGroup>
                                </CardInset>
                            </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <FilterContext.Provider
                            value={combinedFilter}
                        >
                            <Selector
                                entityType={props.entityType}
                                segment={props.segment}
                                nextSegments={props.nextSegments}
                                result={props.result}
                            />
                        </FilterContext.Provider>
                    </ViewGroupItem>
                </ViewGroup>
            </PipelineContext.Provider>;
        }
        else
        {
            return <List
                entityType={props.entityType}
                root={props.root}
            />;
        }
    };

export default observer(Segment);
