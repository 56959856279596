import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useNotifications from './Api/useNotifications';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MarkAllAsReadButton from './Notification/Buttons/MarkAllAsReadButton';
import useHasUnread from './Notification/Api/useHasUnread';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../@Future/Component/Generic/Loader/Loader';
import Notification from './Notification/Notification';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import RightDrawerContext from '../../../Generic/Drawer/RightDrawerContext';
import useTypes from '../../Entity/Type/Api/useTypes';
import { openEntity } from '../../Entity/@Util/openEntity';
import useDividerGlue from '../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import { default as GenericDrawer, DrawerProps as GenericDrawerProps } from '../../../../@Future/Component/Generic/Drawer/Drawer';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import useMarkAsRead from './Notification/Api/useMarkAsRead';
import HoverCardBottom from '../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import StaticSelectbox, { StaticOption } from '../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../../@Api/Localization/localizeText';

export interface NotificationDrawerProps extends GenericDrawerProps
{

}

const Drawer: React.FC<NotificationDrawerProps> =
    props =>
    {
        const [ filterOption, setFilterOption] = useState<string>('All');
        const filterOptions: StaticOption<string>[] =
            useMemo(
                () =>
                    [
                        { id: 'All', value: 'All', label: localizeText('Generic.All', 'Alles') },
                        { id: 'Unread', value: 'Unread', label: localizeText('Notification.Drawer.OptionUnread', 'Ongelezen') },
                        { id: 'Read', value: 'Read', label: localizeText('Notification.Drawer.OptionRead', 'Gelezen') }
                    ],
                []
            );

        const onlyRead: boolean =
            useMemo(
                () =>
                    filterOption === 'All'
                        ?  undefined
                        : filterOption === 'Read',
                [
                    filterOption
                ]
            );

        const [ pages, hasMore, loadMore, isLoading ] = useNotifications(onlyRead);
        const notifications =
            useComputed(
                () =>
                {
                    return pages.slice()
                        .map(page => page.slice())
                        .reduce((a, b) => a.concat(b), [])
                        .map(e => e.entity)
                },
                [
                    pages
                ]);
        const hasUnreadNotifications = useHasUnread();
        const rightDrawerStore = useContext(RightDrawerContext);
        const types = useTypes();

        const markAsRead = useMarkAsRead();
        const open =
            useCallback(
                async (notification: Entity) =>
                {
                    await markAsRead(notification, true);

                    const entity =
                        notification.getRelatedEntityByDefinition(
                            true,
                            types.Entity.RelationshipDefinition.Notifications
                        );

                    if (entity)
                    {
                        rightDrawerStore.clear();

                        return openEntity(entity);
                    }
                },
                [
                    markAsRead,
                    types,
                    rightDrawerStore,
                ]);

        const dividerGlue = useDividerGlue();

        return <GenericDrawer
            title={
                <LocalizedText
                    code="Notification.Drawer.Title"
                    value="Notificaties"
                />
            }
            {...props}
        >
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <CardInset>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={10}
                            alignment="center"
                            justification="end"
                        >
                            <ViewGroupItem>
                                <StaticSelectbox
                                    options={filterOptions}
                                    value={filterOption}
                                    onChange={setFilterOption}
                                />
                            </ViewGroupItem>
                            {
                            hasUnreadNotifications &&
                            <ViewGroupItem>
                                <MarkAllAsReadButton
                                    notifications={notifications}
                                />
                            </ViewGroupItem>}
                        </ViewGroup>
                    </CardInset>
                </ViewGroupItem>

                {
                    isLoading &&
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <CardInset>
                                    <Loader />
                                </CardInset>
                            </Centered>
                        </ViewGroupItem>
                }
                {
                    notifications &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="vertical"
                                spacing={0}
                                glue={dividerGlue}
                            >
                                {
                                    notifications
                                        ?.map(
                                            notification =>
                                                <ViewGroupItem
                                                    key={notification.uuid}
                                                >
                                                    <Notification
                                                        entity={notification}
                                                        onOpen={open}
                                                    />
                                                </ViewGroupItem>
                                        )
                                }
                            </ViewGroup>
                        </ViewGroupItem>
                }
                {
                    hasMore &&
                        <ViewGroupItem>
                            <HoverCardBottom
                                onClick={loadMore}
                                disabled={isLoading}
                            >
                                <LocalizedText
                                    code="Generic.LoadMore"
                                    value="Meer laden"
                                />...
                            </HoverCardBottom>
                        </ViewGroupItem>
                }
            </ViewGroup>
        </GenericDrawer>;
    };

export default observer(Drawer);
