import Layout from '../../../../../@Api/Layout/Layout';
import { observable, runInAction } from 'mobx';

const currentCopiedLayout = observable.box<Layout>();

export function copyLayoutToClipboard(layout: Layout)
{
    runInAction(
        () =>
            currentCopiedLayout.set(layout));
}

export function getCopiedLayoutFromClipboard()
{
    return currentCopiedLayout.get();
}

export function hasCopiedLayoutInClipboard()
{
    return getCopiedLayoutFromClipboard() !== undefined;
}
