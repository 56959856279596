import React, { useCallback, useContext, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Box, TableCell, TableRow } from '@material-ui/core';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import { getNumberOfVisibleColumnsInView } from '../../Api/getNumberOfVisibleColumnsInView';
import { GroupedList, GroupedListProps } from './GroupedList';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import NonGroupedList from '../NonGrouped/NonGroupedList';
import useCombinedPredicate from '../../../Dataset/Segment/Api/useCombinedPredicate';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import { backgroundColor, textSecondaryColor } from '../../../../../../@Resource/Theme/Theme';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import { useAndMaintainViewRoutingState } from '../../Api/useAndMaintainViewRoutingState';
import Chip from '@material-ui/core/Chip';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { ColorUtils } from '../../../../../../@Util/Color/ColorUtils';
import { TotalRow } from '../TotalRow/TotalRow';
import Column from '../../Model/Specification/Column';
import EmptyValue from '../../../../../../@Api/Automation/Value/EmptyValue';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';

export interface GroupedListGroupProps extends GroupedListProps
{
    visibleAggregatedColumns: Column[];
    result: EntitySelectionAggregateResult;
}

export const GroupedListGroup: React.FC<GroupedListGroupProps> =
    observer(
        props =>
        {
            const filter = useContext(FilterContext);
            const numberOfVisibleColumns =
                useComputed(
                    () =>
                        getNumberOfVisibleColumnsInView(
                            props.view,
                            props.sortable ?? false,
                            props.selectable ?? false,
                            props.isInEditMode ?? false
                        ),
                    [
                        props.view,
                        props.sortable,
                        props.selectable,
                        props.isInEditMode,
                    ]
                );
            const [
                ,
                ,
                lastOpenedGroups,
                openGroup,
                closeGroup
            ] = useAndMaintainViewRoutingState(
                props.saveViewState,
                props.view,
                props.searchQuery,
                undefined,
                undefined,
                props.openedGroup
            );
            const groupValue =
                useComputed(
                    () =>
                        props.result.getGroupValue(),
                    [props.result]
                );
            const groupResultFilter =
                useComputed(
                    () =>
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                props.view.parameter,
                                props.grouping.column.fieldPath
                            ),
                            Comparator.Equals,
                            groupValue
                        ),
                    [
                        props.view,
                        props.grouping,
                        groupValue,
                    ]
                );
            const childFilter =
                useCombinedPredicate(
                    filter,
                    groupResultFilter
                );
            const lastOpenedGroup =
                useMemo(
                    () =>
                        lastOpenedGroups
                            .find(
                                group =>
                                    group.groupId === props.grouping.column.id
                                    && group.valueId === groupValue.getId()
                            ),
                    [
                        lastOpenedGroups,
                        props.grouping,
                        groupValue,
                    ]
                );
            const group =
                useMemo(
                    () =>
                        lastOpenedGroup ?? {
                            groupId: props.grouping.column.id,
                            valueId: groupValue.getId(),
                            openedGroups: [],
                        },
                    [lastOpenedGroup, props.grouping, groupValue]
                );
            const [ isOpen, setOpen ] = useState(lastOpenedGroup !== undefined);
            const toggleOpen =
                useCallback(
                    () =>
                    {
                        if (isOpen)
                        {
                            setOpen(false);
                            closeGroup(group);
                        }
                        else
                        {
                            setOpen(true);
                            openGroup(group);
                        }
                    },
                    [isOpen, closeGroup, group, openGroup]
                );
            const spacer =
                <TableRow>
                    <TableCell
                        colSpan={numberOfVisibleColumns}
                        style={{
                            backgroundColor: backgroundColor,
                            border:
                                isOpen
                                    ? undefined
                                    : 'none',
                            height:
                                isOpen
                                    ? 16
                                    : 0,
                            transition: 'all 500ms',
                        }}
                        padding="none"
                    />
                </TableRow>;

            return <React.Fragment>
                {spacer}
                <TotalRow
                    view={props.view}
                    columns={props.view.specification.list!.columns}
                    result={props.result}
                    onClick={() => toggleOpen()}
                    color={ColorUtils.color(props.idx)[500]}
                    prefix={
                        <Box
                            paddingLeft={2 * props.idx}
                        >
                            <ViewGroup
                                orientation="horizontal"
                                spacing={10}
                                alignment="center"
                            >
                                {
                                    props.idx > 0 &&
                                    <ViewGroupItem>
                                        <Icon
                                            icon="subdirectory_arrow_right"
                                            color={textSecondaryColor}
                                        />
                                    </ViewGroupItem>
                                }
                                <ViewGroupItem>
                                    <Chip
                                        size="small"
                                        label={props.result.aggregates[0].toString()}
                                        style={{
                                            backgroundColor: ColorUtils.color(props.idx)[500],
                                            color: 'white',
                                        }}
                                    />
                                </ViewGroupItem>
                                <ViewGroupItem>
                                <span
                                    style={{
                                        fontWeight:
                                            isOpen
                                                ? 500
                                                : undefined,
                                        color:
                                            isOpen
                                                ? ColorUtils.color(props.idx)[500]
                                                : undefined,
                                    }}
                                >
                                    {
                                        groupValue instanceof EmptyValue
                                            ? <LocalizedText
                                                code="Generic.NoValue"
                                                value="Géén waarde"
                                            />
                                            : groupValue.getName() ?? '-'
                                    }
                                </span>
                                </ViewGroupItem>
                            </ViewGroup>

                        </Box>
                    }
                />
                {
                    isOpen &&
                    <FilterContext.Provider
                        value={childFilter}
                    >
                        {
                            props.remainingGroups.length > 0
                                ? <GroupedList
                                    {...props}
                                    root={false}
                                    idx={props.idx + 1}
                                    grouping={props.remainingGroups[0]}
                                    remainingGroups={props.remainingGroups.slice(1)}
                                    openedGroup={group}
                                />
                                : <NonGroupedList
                                    {...props}
                                    root={false}
                                    openedGroup={group}
                                />
                        }
                    </FilterContext.Provider>
                }
                {spacer}
            </React.Fragment>;
        }
    );
