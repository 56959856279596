import ValueType from './Type/ValueType';
import Computation from '../Function/Computation/Computation';
import FunctionContext from '../Function/FunctionContext';
import Dependency from '../Parameter/Dependency';
import Validation from '../Validation/Validation';
import { FileReporter } from '../../../@Component/Domain/DataObject/Model/DataDescriptor';
import ReactiveInterface from './ReactiveInterface';

export default abstract class Value<T, K extends ValueType<any>> extends Computation<K, Value<T, K>>
{
    // ------------------------- Properties -------------------------

    value: T;
    reactiveInterface?: ReactiveInterface;

    // ------------------------ Constructor -------------------------

    constructor(value: T)
    {
        super();

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getId(): string;

    abstract getType(): K;

    validate(): Validation[]
    {
        return [];
    }

    isAsync(): boolean
    {
        return false;
    }

    async apply(context: FunctionContext): Promise<Value<T, K>>
    {
        return this.synchronousApply(context);
    }

    synchronousApply(context: FunctionContext): Value<T, K>
    {
        return this;
    }

    abstract equals(otherValue: Value<any, any>);

    abstract clone(): Value<T, K>;

    getDependencies(): Dependency[]
    {
        return [];
    }

    toDescriptor(onFile?: FileReporter)
    {
        const descriptor = super.toDescriptor(onFile);
        descriptor.class = 'Value';

        return descriptor;
    }

    abstract augmentDescriptor(descriptor: any,
                               onFile?: FileReporter);

    // ----------------------- Private logic ------------------------
}
