import React from 'react';
import { observer } from 'mobx-react-lite';
import { ComputationEditorProps } from '../../ComputationEditor';
import ValueType from '../../../../../../../../../../@Api/Automation/Value/Type/ValueType';
import CurrentDateComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/CurrentDateComputation';
import LocalizedText from '../../../../../../../../Localization/LocalizedText/LocalizedText';

export interface CurrentDateComputationEditorProps extends ComputationEditorProps<ValueType<any>, CurrentDateComputation>
{

}

const CurrentDateComputationEditor: React.FC<CurrentDateComputationEditorProps> =
    props =>
    {
        return <LocalizedText
            code="Generic.CurrentDate"
            value="Huidige datum"
        />;
    };

export default observer(CurrentDateComputationEditor);
