import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEditorProps } from '../../Editor/LayoutEditor';
import ExpansionLayout from '../../../../../@Api/Layout/Type/ExpansionLayout';
import uuid from '../../../../../@Util/Id/uuid';
import Header from '../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import ExpansionPanel from '../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import ChildLayoutEditor from '../../Editor/Child/ChildLayoutEditor';
import Layout from '../../../../../@Api/Layout/Layout';
import { runInAction } from 'mobx';

export interface ExpansionLayoutEditorProps extends LayoutEditorProps<ExpansionLayout>
{

}

const ExpansionLayoutEditor: React.FC<ExpansionLayoutEditorProps> =
    props =>
    {
        const id = useMemo(() => uuid(), []);
        const setHeader =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.header = layout),
                [
                    props.layout
                ]);
        const setExpansion =
            useCallback(
                (layout: Layout) =>
                    runInAction(
                        () =>
                            props.layout.expansion = layout),
                [
                    props.layout
                ]);

        return <ExpansionPanel
            id={id}
            summary={
                <Header
                    title={
                        <ChildLayoutEditor
                            {...props}
                            childLayout={props.layout.header}
                            onChange={setHeader}
                        />
                    }
                    inset={props.layout.hasHeaderInset}
                />
            }
            expansion={
                <ChildLayoutEditor
                    {...props}
                    childLayout={props.layout.expansion}
                    onChange={setExpansion}
                />
            }
        />;
    };

export default observer(ExpansionLayoutEditor);
