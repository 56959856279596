import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as GenericInput } from '../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import ValueToEntitySourceFieldMapping from '../../../../Model/FieldMapping/ValueToEntity/ValueToEntitySourceFieldMapping';
import { default as ValueToEntityMappingModel } from '../../../../Model/FieldMapping/ValueToEntity/ValueToEntityMapping';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import useEntityByUuid from '../../../../../../../../@Api/Entity/Bespoke/useEntityByUuid';
import { runInAction } from 'mobx';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import Selectbox from '../../../../../Selectbox/Selectbox';
import ViewGroupItem from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ViewGroup from '../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import styles from './ValueToEntityMapping.module.scss';
import SourceValueLabel from '../SourceValueLabel/SourceValueLabel';

export interface ValueToEntityMappingProps
{
    fieldMapping: ValueToEntitySourceFieldMapping;
    valueMapping: ValueToEntityMappingModel;
}

const ValueToEntityMapping: React.FC<ValueToEntityMappingProps> =
    props =>
    {
        const [ entity, ] =
            useEntityByUuid(
                props.fieldMapping.targetFieldPath.path.entityType,
                props.valueMapping.targetId);

        const selections =
            useComputed(
                () => [
                    new EntitySelectionBuilder(props.fieldMapping.targetFieldPath.path.entityType)
                        .selection
                ],
                [
                    props.fieldMapping
                ]);

        const onChange =
            useCallback(
                (value?: Entity) =>
                    runInAction(
                        () =>
                        {
                            if (value)
                            {
                                props.valueMapping.targetId = value.uuid;
                            }
                            else
                            {
                                props.valueMapping.targetId = undefined;
                            }
                        }),
                [
                    props.valueMapping
                ]);

        return <GenericInput
            label={
                <SourceValueLabel
                    name={props.valueMapping.sourceValueName}
                    value={props.valueMapping.sourceValue}
                />
            }
            labelPosition="left"
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    <div
                        className={styles.editor}
                    >
                        <Selectbox
                            selections={selections}
                            onChange={onChange}
                            value={entity}
                        />
                    </div>
                </ViewGroupItem>
                <ViewGroupItem
                    ratio={1}
                />
            </ViewGroup>
        </GenericInput>;
    };

export default observer(ValueToEntityMapping);
