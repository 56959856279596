import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useSwitch from '../../../../../../../../@Util/Switch/useSwitch';
import { useEffect, useMemo } from 'react';
import { SelectboxProps } from '../../../../../Selectbox/Selectbox';
import Addressee from '../Model/Addressee';
import getPersonNameFromAddressee from './getPersonNameFromAddressee';
import getOrganizationNameFromAddressee from './getOrganizationNameFromAddressee';

export default function useRelationshipSelectorProps(relationship: Entity,
                                                     isLoadingRelationship: boolean,
                                                     addresseeToLookupRelationship?: Addressee)
{
    const relationshipName =
        useMemo(
            () =>
            {
                if (addresseeToLookupRelationship)
                {
                    return getPersonNameFromAddressee(addresseeToLookupRelationship)
                        || getOrganizationNameFromAddressee(addresseeToLookupRelationship);
                }
                else
                {
                    return undefined;
                }
            },
            [
                addresseeToLookupRelationship
            ]);

    const [ isRelationshipSelectorOpen, openRelationshipSelector, closeRelationshipSelector ] = useSwitch(false);

    const relationshipSelectorProps =
        useMemo<Partial<SelectboxProps>>(
            () => ({
                genericSelectboxProps: {
                    defaultInputValue: !isLoadingRelationship && !relationship ? relationshipName : undefined,
                    open: isRelationshipSelectorOpen,
                    onOpen: openRelationshipSelector,
                    onClose: closeRelationshipSelector
                }
            }),
            [
                isLoadingRelationship,
                relationship,
                relationshipName,
                isRelationshipSelectorOpen,
                openRelationshipSelector,
                closeRelationshipSelector
            ]);

    useEffect(
        () =>
        {
            if (relationship)
            {
                closeRelationshipSelector();
            }
            else
            {
                if (relationshipName)
                {
                    openRelationshipSelector();
                }
                else
                {
                    closeRelationshipSelector();
                }
            }
        },
        [
            relationshipName,
            relationship,
            openRelationshipSelector,
            closeRelationshipSelector
        ]);

    useEffect(
        () =>
        {
            if (relationship)
            {
                closeRelationshipSelector();
            }
        },
        [
            relationship,
            closeRelationshipSelector
        ]);

    return relationshipSelectorProps;
}
