import { DataObjectEditorType, DataObjectSpecificationType, DataObjectType, DataObjectViewerType } from '../../Model/DataObjectType';
import { Comparator } from '../../Model/Comparator';
import { Aggregate } from '../../Model/Aggregate';
import { MathematicalOperator } from '../../Model/MathematicalOperator';
import { DataObjectFunction } from '../../Model/DataObjectFunction';
import { DataObject } from '../../Model/DataObject';
import { injectWithQualifier, ModuleManager } from '../../../../../@Util/DependencyInjection/index';
import { BooleanView } from './BooleanView';
import { BooleanEditor } from './BooleanEditor';
import { BooleanSpecification } from './BooleanSpecification';
import { DataDescriptor } from '../../Model/DataDescriptor';
import { ComparatorDescriptor } from '../../Model/ComparatorDescriptor';
import { DataObjectRepresentation } from '../../Model/DataObjectRepresentation';
import { DataObjectOperatorOverload } from '../../Model/DataObjectOperatorOverload';
import { DataObjectContext } from '../../Model/DataObjectContext';
import { DataObjectComparatorOverload } from '../../Model/DataObjectComparatorOverload';
import { LocalizationStore } from '../../../../../@Service/Localization/LocalizationStore';
import { DataObjectOverloadType } from '../../Model/DataObjectOverloadType';
import { DataObjectSpecification } from '../../Model/DataObjectSpecification';
import localizeText from '../../../../../@Api/Localization/localizeText';

export class BooleanType implements DataObjectType<boolean>
{
    @injectWithQualifier('LocalizationStore') localizationStore: LocalizationStore;

    id(): string
    {
        return 'Boolean';
    }

    name()
    {
        return localizeText('DataObject.Type.Boolean', 'Ja/nee');
    }

    supportedComparators(): ComparatorDescriptor[]
    {
        return [
            {
                comparator: Comparator.Equals
            },
            {
                comparator: Comparator.NotEquals
            }
        ];
    }

    supportedAggregates(): Aggregate[]
    {
        return [];
    }

    supportedMathematicalOperators(): MathematicalOperator[]
    {
        return [];
    }

    supportedFunctions(): DataObjectFunction[]
    {
        return [];
    }

    view(): DataObjectViewerType
    {
        return BooleanView;
    }

    editor(): DataObjectEditorType
    {
        return BooleanEditor;
    }

    specification(): DataObjectSpecificationType
    {
        return BooleanSpecification;
    }

    initialize(dataObjects: DataObject[], moduleManager: ModuleManager): Promise<boolean>
    {
        return null;
    }

    requiresInitialization(value: any): boolean
    {
        return false;
    }

    getDataFromValue(value: any, specification: DataObjectSpecification): DataDescriptor
    {
        let data = new DataDescriptor();
        data.boolean1 = value;

        return data;
    }

    getUninitializedValueFromData(data: DataDescriptor, specification: DataObjectSpecification): any
    {
        return data.boolean1;
    }

    valueId(value: boolean): string
    {
        return value ? 'true' : 'false';
    }

    getString(value: boolean, representation: DataObjectRepresentation, context: DataObjectContext, dataObject: DataObject): string
    {
        if (value)
        {
            return '✓';
        }
        else
        {
            return '×';
        }
    }

    compare(lhs: DataObject, rhs: DataObject, comparator: Comparator): boolean
    {
        const lhsValue = lhs?.getValue() ?? false;
        const rhsValue = rhs?.getValue() ?? false;

        switch (comparator)
        {
            case Comparator.GreaterThan:
                return lhsValue > rhsValue;

            case Comparator.GreaterThanOrEqual:
                return lhsValue >= rhsValue;
        }
    }

    comparatorOverloads(): DataObjectComparatorOverload[]
    {
        return [
            new DataObjectComparatorOverload(
                this,
                Comparator.Equals,
                DataObjectOverloadType.Symmetric,
                type =>
                    type instanceof BooleanType
                        || type === undefined,
                (value, relatedValue, isThisLhs) =>
                {
                    const realValue = value?.value === undefined ? false : value.value;
                    const realRelatedValue = relatedValue?.value === undefined ? false : relatedValue.value;

                    return realValue === realRelatedValue;
                }
            )
        ];
    }

    compute(lhs: DataObject, rhs: DataObject, operator: MathematicalOperator): DataObject
    {
        return null;
    }

    operatorOverloads(): DataObjectOperatorOverload[]
    {
        return [];
    }

    isValid(dataObject: DataObject): boolean
    {
        return true;
    }

    invalidCause(dataObject: DataObject): string
    {
        return undefined;
    }

    hasBlurEvent(value: DataObject): boolean
    {
        return false;
    }

    hasSemanticValueWhenEmpty(): boolean
    {
        return true;
    }
}
