import React, { useCallback, useEffect } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import Selectbox from '../../../../../../../../@Future/Component/Generic/Input/Selectbox/Selectbox';
import { Field } from '../../Model/Field';
import { computed, runInAction } from 'mobx';
import { Cardinality } from '../../../../../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import localize from '../../../../../../../../@Api/Localization/localize';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';

export interface FieldItemProps
{
    entityType: EntityType;
    field: Field;
}

const CardinalitySelector: React.FC<FieldItemProps> =
    props =>
    {
        const loader =
            useCallback(
                () => Promise.resolve([
                    Cardinality.OneToOne,
                    Cardinality.OneToMany,
                    Cardinality.ManyToOne,
                    Cardinality.ManyToMany
                ]),
                [

                ]);

        const isBaseParent =
            useComputed(
                () =>
                    props.field.fieldPath ? !props.field.fieldPath.isParentRelationship : true,
                [
                    props.field
                ]);

        const formatter =
            useCallback(
                (type: Cardinality) =>
                {
                    const isRelatedPlural =
                        isBaseParent
                            ?
                                type === Cardinality.OneToMany
                                || type === Cardinality.ManyToMany
                            :
                                type === Cardinality.ManyToOne
                                || type === Cardinality.ManyToMany;

                    return `${isRelatedPlural ? localizeText('CardinalitySelector.Multiple', 'meerdere') : localizeText('CardinalitySelector.Singular', 'één')} ${props.field.dataType.getName(isRelatedPlural).toLowerCase()} ${type === Cardinality.OneToOne ? `(${localizeText('CardinalitySelector.OneToOne', 'één op één koppeling')})` : ''} ${type === Cardinality.ManyToMany ? `(${localizeText('CardinalitySelector.ManyToMany', 'méér op méér koppeling')})` : ''}`;
                },
                [
                    isBaseParent,
                    props.field
                ]);

        const onChange =
            useCallback(
                (cardinality: Cardinality) =>
                    runInAction(
                        () =>
                        {
                            props.field.cardinality = cardinality;

                            if (isBaseParent)
                            {
                                if (localize(props.field.name) === props.field.dataType.getName(false)
                                    || localize(props.field.name) === props.field.dataType.getName(true))
                                {
                                    props.field.name = props.field.dataType.getNameDataObject(cardinality === Cardinality.OneToMany || cardinality === Cardinality.ManyToMany).value;
                                }

                                if (localize(props.field.inverseName) === props.entityType.getName(false)
                                    || localize(props.field.inverseName) === props.entityType.getName(true))
                                {
                                    props.field.inverseName = props.entityType.getNameDataObject(cardinality === Cardinality.ManyToOne || cardinality === Cardinality.ManyToMany).value;
                                }
                            }
                            else
                            {
                                if (localize(props.field.name) === props.field.dataType.getName(false)
                                    || localize(props.field.name) === props.field.dataType.getName(true))
                                {
                                    props.field.name = props.field.dataType.getNameDataObject(cardinality === Cardinality.ManyToOne || cardinality === Cardinality.ManyToMany).value;
                                }

                                if (localize(props.field.inverseName) === props.entityType.getName(false)
                                    || localize(props.field.inverseName) === props.entityType.getName(true))
                                {
                                    props.field.inverseName = props.entityType.getNameDataObject(cardinality === Cardinality.OneToMany || cardinality === Cardinality.ManyToMany).value;
                                }
                            }
                        }),
                [
                    props.field,
                    props.entityType,
                    isBaseParent
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        props.field.dataType)
                    .observe(
                        () =>
                            onChange(Cardinality.ManyToOne)),
            [
                props.field,
                onChange
            ]);

        if (props.field.dataType)
        {
            if (props.field.isEditable && props.field.isNew)
            {
                return <Selectbox
                    load={loader}
                    onChange={onChange}
                    formatOption={formatter}
                    idResolver={formatter}
                    value={props.field.cardinality}
                    disabled={!props.field.isEditable}
                />;
            }
            else
            {
                return <div>
                    {formatter(props.field.cardinality)}
                </div>;
            }
        }
        else
        {
            return null;
        }
    };

export default observer(CardinalitySelector);
