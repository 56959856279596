import { ProductLineEditorInterface } from '../Hooks/useProductLine';
import React from 'react';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../../../../../Type/Api/useTypes';
import Input from '../../../../../../../Input/Input';

export interface ProductLineMilestoneProps
{
    productLine: ProductLineEditorInterface;
    disabled: boolean;
    autoCommit: boolean;
    showLabel?: boolean;
}

export const ProductLineMilestone: React.FC<ProductLineMilestoneProps> = observer(
    ({
         productLine,
         disabled,
         autoCommit,
         showLabel
     }) =>
    {
        const types = useTypes();

        return <Input
            entity={productLine.productLine}
            field={types.Milestone.RelationshipDefinition.ProductLines}
            parent
            labelPosition={ showLabel ? 'left' : 'none'}
            disabled={disabled}
            doAutocommit={autoCommit}
            commitContext={productLine.commitContext}
        />

    }
);