import React, { useMemo } from 'react';
import { EntitySelectionAggregateResult } from '../../../Selection/Model/EntitySelectionAggregateResult';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { observer } from 'mobx-react-lite';
import useResultPredicate from '../../../Dataset/Segment/Api/useResultPredicate';
import styles from './Column.module.scss';
import List from './List/List';
import useCombinedPredicate from '../../../Dataset/Segment/Api/useCombinedPredicate';
import FilterContext from '../../../Dataset/Segment/FilterContext/FilterContext';
import Header from './Header/Header';
import useIsDragging from '../../../../DragAndDrop/Api/useIsDragging';
import { classNames } from '../../../../../../@Future/Util/Class/classNames';
import Segment from '../../../Dataset/Model/Segment';
import View from '../../Model/View';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';

export interface ColumnProps
{
    view: View;
    entityType: EntityType;
    idx: number;
    segment: Segment;
    result: EntitySelectionAggregateResult;
    firstColumn?: boolean;
    lastColumn?: boolean;
    numberOfColumns: number;
    onOpen?: (entity: Entity) => void;
}

const Column: React.FC<ColumnProps> =
    props =>
    {
        const resultFilter =
            useResultPredicate(
                props.view.parameter,
                props.segment,
                props.result
            );
        const newFilter = useCombinedPredicate(resultFilter);
        const isDragging = useIsDragging();
        const listClassName =
            useMemo(
                () =>
                    classNames(
                        styles.list,
                        // When a vertical scrollbar is shown, drag & drop is slowed down (at least in Chrome)
                        // So we hide it when drag & dropping
                        isDragging && styles.dragging),
                [
                    isDragging
                ]);

        return <FilterContext.Provider
            value={newFilter}
        >
            <div
                className={styles.root}
                style={{
                    width: `${100 / props.numberOfColumns}%`
                }}
            >
                <div
                    className={styles.header}
                >
                    <Header
                        entityType={props.entityType}
                        idx={props.idx}
                        segment={props.segment}
                        result={props.result}
                        firstColumn={props.firstColumn}
                        lastColumn={props.lastColumn}
                    />
                </div>
                <div
                    className={listClassName}
                >
                    <List
                        view={props.view}
                        entityType={props.entityType}
                        idx={props.idx}
                        segment={props.segment}
                        result={props.result}
                        addButtonClassName={styles.add}
                        resultFilter={resultFilter}
                        ordering={props.view.specification.column.ordering}
                        onOpen={props.onOpen}
                    />
                </div>
            </div>
        </FilterContext.Provider>;
    };

export default observer(Column);
