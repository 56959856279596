import DataSeries from '../Model/DataSeries';
import ComputedSeries from '../Model/ComputedSeries';

export default function getSeriesFromDescriptor(descriptor: any)
{
    switch (descriptor.type)
    {
        case 'Data':
            return DataSeries.fromDescriptor(descriptor);

        case 'Computed':
            return ComputedSeries.fromDescriptor(descriptor);
    }
}
