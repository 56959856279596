import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import styles from './HighlightedEntityType.module.scss';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import ExpansionButton from '../../../../../../../@Future/Component/Generic/Button/Variant/Expansion/ExpansionButton';
import HighlightContext from '../../../HighlightContext/HighlightedEntityContext';
import Item from '../../../Item';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import NavigatorContext from '../../NavigatorContext/NavigatorContext';
import useSetContextExpansion from '../../Api/useSetContextExpansion';
import useIsContextExpanded from '../../Api/useIsContextExpanded';

export interface HighlightedEntityTypeProps
{
    entityType: EntityType;
    entities: Entity[];
    paths: EntityPath[];
}

const HighlightedEntityType: React.FC<HighlightedEntityTypeProps> =
    props =>
    {
        const path =
            useMemo(
                () =>
                    props.paths.find(
                        path =>
                            props.entities.every(
                                entity =>
                                    entity.entityType.isA(path.entityType))),
                [
                    props.paths,
                    props.entities
                ]);

        const noHighlightedEntities = useMemo(() => [], []);
        const context = useContext(NavigatorContext);
        const isContextExpanded = useIsContextExpanded();
        const setContextExpansion = useSetContextExpansion();

        const highlightCount =
            useComputed(
                () =>
                {
                    if (path)
                    {
                        let count: number = 0;

                        context.countByPath.forEach(
                            (result, path) =>
                            {
                                if (path.equals(path) && result)
                                {
                                    count = result.aggregates[0].value;
                                }
                            });

                        return count;
                    }
                    else
                    {
                        return 0;
                    }
                },
                [
                    context
                ]);

        const expansionLabel =
            useMemo(
                () =>
                    `alle ${highlightCount} ${props.entityType.getName(true).toLowerCase()} tonen`,
                [
                    highlightCount,
                    props.entityType
                ]);

        const highlightOnClick =
            useCallback(
                () =>
                {
                    setContextExpansion(path);
                },
                [
                    setContextExpansion,
                    path
                ]);

        const hasHighlight =
            useMemo(
                () =>
                    !isContextExpanded && path && props.entities.length > 0,
                [
                    isContextExpanded,
                    path,
                    props.entities
                ]);

        if (hasHighlight)
        {
            return <div>
                <div
                    className={styles.list}
                >
                    <CardInset
                        horizontal={true}
                        vertical={false}
                    >
                        {
                            path && highlightCount > props.entities.length &&
                                <div
                                    className={styles.showAllContainer}
                                >
                                    <ExpansionButton
                                        onClick={highlightOnClick}
                                        label={expansionLabel}
                                    />
                                </div>
                        }
                        <HighlightContext.Provider
                            value={noHighlightedEntities}
                        >
                            <ViewGroup
                                orientation="vertical"
                                spacing={8}
                            >
                                {
                                    props.entities.map(
                                        entity =>
                                            <ViewGroupItem
                                                key={entity.uuid}
                                            >
                                                <Item
                                                    entity={entity}
                                                />
                                            </ViewGroupItem>)
                                }
                            </ViewGroup>
                        </HighlightContext.Provider>
                    </CardInset>
                </div>
            </div>;
        }
        else
        {
            return null;
        }
    };

export default observer(HighlightedEntityType);
