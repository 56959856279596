import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import DocumentEditor, {
    DocumentDefinition, EntityImageBlockId, GutenbergBlockExpressionId, GutenbergBlockHtmlId, GutenbergBlockImageId, GutenbergBlockTemplateId, GutenbergBlockVideoId, LayoutBlockId, OrganizationLogoBlockId,
} from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentEditor';
import ExpressionEditorContext from '../../../../../../../../@Future/Component/Generic/Input/DocumentEditor/Blocks/Expression/ExpressionEditorContext';
import { EntityContext } from '../../../../../@Model/EntityContext';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import uuid from 'uuid/v4';
import { ExpressionContext } from '../../../../../../Expression/ExpressionContext';
import InteractiveLayoutContext from '../../../../../../Layout/InteractiveLayoutContext';

export interface ContentEditorProps
{
    entity: Entity;
    onChange: (definition: DocumentDefinition) => void;
    expressionContext?: ExpressionContext;
    definition?: DocumentDefinition;
    editLayoutMode?: boolean;
    hideEntityFieldSelector?: boolean;
    debounceDelay?: number;
}

const ContentEditor: React.FC<ContentEditorProps> =
    props =>
    {
        const types = useTypes();
        const entity = props.entity;
        const { onChange } = props;

        const definition =
            useMemo(
                () =>
                {
                    let def =
                        props.definition
                            ?
                                props.definition
                            :
                                props.entity.getObjectValueByField(types.Activity.Email.Field.Content);

                    if (!def)
                    {
                        def = {
                            type: 'gutenberg',
                            definition:
                            [
                                {
                                    clientId: uuid(),
                                    name: 'pv/expression',
                                    isValid: true,
                                    attributes: {},
                                    innerBlocks: []
                                }
                            ]
                        };
                    }

                    return def;
                },
                [
                    types,
                    props.definition,
                    props.entity
                ]);

        const onChangeCallback =
            useCallback(
                (definition: DocumentDefinition) =>
                {
                    if (onChange)
                    {
                        onChange(definition);
                    }
                    else
                    {
                        entity.setValueByField(
                            types.Activity.Email.Field.Content,
                            definition);
                    }
                },
                [
                    onChange,
                    entity,
                    types
                ]);

        const expressionContext =
            useMemo(
                () =>
                {
                    if (props.expressionContext)
                    {
                        return props.expressionContext;
                    }
                    else
                    {
                        return {
                            entityContext:
                                new EntityContext(
                                    [],
                                    EntityPath.fromEntityType(types.Activity.Email.Type))
                                    .setContext('Addressee', new EntityContext([], EntityPath.fromEntityType(types.Recipient.Type)))
                                    .setContext('Environment', new EntityContext([], EntityPath.fromEntityType(types.Relation.Organization.Environment.Type)))
                                    .setContext('Employee', new EntityContext([], EntityPath.fromEntityType(types.Relationship.Person.Contact.Employee.Type)))
                        }
                    }
                },
                [
                    props.expressionContext,
                    types
                ]);

        const blockTypes =
            useMemo(
                () => [
                    GutenbergBlockExpressionId,
                    GutenbergBlockImageId,
                    GutenbergBlockVideoId,
                    GutenbergBlockTemplateId,
                    OrganizationLogoBlockId,
                    EntityImageBlockId,
                    GutenbergBlockHtmlId,
                    LayoutBlockId,
                ],
                []);

        return <ExpressionEditorContext.Provider
            value={expressionContext}
        >
            <InteractiveLayoutContext.Provider
                value={false}
            >
                <DocumentEditor
                    type="gutenberg"
                    onChange={onChangeCallback}
                    definition={definition}
                    blockTypes={blockTypes}
                    editLayoutMode={props.editLayoutMode}
                    hideEntityFieldSelector={props.hideEntityFieldSelector}
                    debounceDelay={props.debounceDelay}
                />
            </InteractiveLayoutContext.Provider>
        </ExpressionEditorContext.Provider>;
    };

export default observer(ContentEditor);
