import Role, { AllPrivileges, Privilege } from '../../../Model/Role';
import { EntityType } from '../../../../../../@Api/Model/Implementation/EntityType';
import { resetFieldPermissions } from '../Field/resetFieldPermissions';
import { resetRelationshipDefinitionPermissions } from '../RelationshipDefinition/resetRelationshipDefinitionPermissions';

export function resetEntityTypeFieldsAndRelationshipsPermissions(
    role: Role,
    entityTypePrivileges: Privilege[],
    entityType: EntityType
)
{
    const privileges: Privilege[] =
        entityTypePrivileges.find(privilege => privilege === 'canRead')
            ? AllPrivileges
            : entityTypePrivileges.find(privilege => privilege === 'canUpdate')
                ? [ 'canCreate', 'canUpdate', 'canDelete', 'canExport' ]
                : [];

    resetFieldPermissions(
        role,
        privileges,
        entityType
    );

    resetRelationshipDefinitionPermissions(
        role,
        privileges,
        entityType
    )
}
