import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import { useComputed } from 'mobx-react-lite';

export default function useIsEditable(entity: Entity,
                                      allowEditMode: boolean)
{
    const avatarField =
        useComputed(
            () =>
                entity.entityType.getInheritedAvatarField(),
            [
                entity
            ]);

    return useComputed(
        () =>
            allowEditMode && avatarField !== undefined && !avatarField.isComputedField,
        [
            allowEditMode,
            avatarField,
            entity
        ]);
}