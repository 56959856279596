import isMutable from '../../../../../../../@Api/RightProfile/isMutable';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import { EntityType } from '../../../../../../../@Api/Model/Implementation/EntityType';
import getTypes from '../../../../../Entity/Type/Api/getTypes';
import equalsEntity from '../../../../../../../@Api/Entity/Bespoke/equalsEntity';

export function isEntityPackMutable(
    entity: Entity,
    ownedShareAndEnvironmentPacks: Entity[],
    entityTypesWithMutablePack: EntityType[]
)
{
    const types = getTypes();

    const entityPack =
        entity.getRelatedEntityByDefinition(
            true,
            types.Pack.RelationshipDefinition.Entities
        );

    return isMutable(entity)
        && ownedShareAndEnvironmentPacks.length > 1
        && entityTypesWithMutablePack
            .some(
                entityType =>
                    entity.entityType.isA(entityType))
        && entityPack
        && ownedShareAndEnvironmentPacks
            .some(
                pack =>
                    equalsEntity(pack, entityPack)
            );
}
